import { RadioGroupItem } from "../components/radio-group/radio-group-item";
import { PaymentType } from "./enumerations/payment-type";

export const PaymentTypeOptions: RadioGroupItem[] = [
    {
        value: PaymentType.PayPerUser,
        label: 'Pay Per User',
    },
    {
        value: PaymentType.FlatFee,
        label: 'Flat Fee',
    },
]