import React from "react";

export interface PillChartTopTextProps {
    index: number;
    value: number;
    forReport?: boolean;
}

const PillChartTopText: React.FC<PillChartTopTextProps> = (props) => {
    const {
        index,
        value,
        forReport,
    } = props;
    const x = forReport ? 11.5 : 17
    return (
        <text
            x={x + (index * 85)}
            y={10}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontWeight: "700", fontSize: "12px" }}
        >
            {value}
        </text>
    );
};



export default PillChartTopText;