import {
    forwardRef,
    useEffect,
    useState
} from "react";
import { default as DatePickerControl } from "react-datepicker";
import { ControllerWrappedInputProps } from "./_controller-wrapped-component.props";

/**
 * This is a private component for date-picker, it's meant to be wrapped
 * inside of a react-hook-form controller.
 * https://react-hook-form.com/api/usecontroller
 * @param {ControllerWrappedInputProps} props ControllerWrappedInputProps
 * @returns Opiniated DatePickerControl with custom picker button
 */
export const ControllerWrappedInput: React.FC<ControllerWrappedInputProps> = (props) => {
    // internal state
    const [date, setDate] = useState<Date | undefined>(undefined);

    // setup initial state
    // will fire on react-hook-form reset
    useEffect(() => {
        if (props.field.value) {
            setDate(new Date(props.field.value));
        }
    }, [props.field.value]);

    let datePickerAttributes = {
        id: props.id,
        name: props.id,
        label: props.label,
        isClearable: false,
    };

    const CustomButtonInput = forwardRef<any, any>(({ onClick, field }, ref,) => {
        return (
            <>
                <button
                    id={props.id}
                    className="c-button bg-white -secondary -outline -small min-w-[80%]"
                    onClick={(event) => {
                        // stop the form from submitting on button click
                        event.preventDefault();

                        // pass off back to the custom date UI component
                        onClick();
                    }} ref={ref}>
                    {date?.toDateString() ?? props.label}
                </button>
                {date &&
                    <button
                        type="button"
                        className="react-datepicker__close-icon"
                        aria-label="Close"
                        data-testid="clear-button"
                        title={`Clear ${props.label}`}
                        onClick={() => {
                            setDate(undefined);
                            field.onChange(undefined);
                        }}>
                    </button>
                }
            </>
        )
    });

    return (
        <div className={`date-picker-container ${props.className}`}>
            {props.label &&
                <label htmlFor={props.id}>
                    {props.label}
                </label>
            }
            <DatePickerControl
                {...datePickerAttributes}
                selected={date}
                value={props.field.value}
                onBlur={props.field.onBlur}
                customInput={<CustomButtonInput field={props.field} />}
                onChange={(date) => {
                    if (date) {
                        setDate(date);
                    }
                    props.field.onChange(date?.toISOString());
                }}
            />
            {props.fieldState.error &&
                <span className="error-style" role="alert">
                    {`${props.fieldState.error.message}`}
                </span>
            }
        </div>
    );
};
