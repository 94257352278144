import React from 'react';
import GroupList from "../../components/group-list/group-list";
import TagList from "../../components/tag-list/tag-list";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface GroupsPageProps { }

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const GroupsPage: React.FC<GroupsPageProps> = (props: GroupsPageProps) => {

    return (
        <div>
            <GroupList />
            <hr />
            <TagList />
        </div>
    );
}

export default GroupsPage;
