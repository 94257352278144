import { ReactComponent as CheckIcon } from "../../assets/icons/solid/check-circle.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/solid/cross-circle.svg";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";

interface PlansTableContentProps {
    carouselItemIndex: number;
    addOnPrices?: { user?: string, license?: string, organization?: string };
    isMobile?: boolean;
}

const PlansTableContent: React.FC<PlansTableContentProps> = ({ carouselItemIndex, isMobile, addOnPrices }) => {
    const COMPONENT_CLASS = "c-subscriptions";

    enum SubscriptionCarouselIndex {
        Starter = 0,
        Business = 1,
        Enterprise = 2,
    }

    const basicsInfo = [
        { name: 'Users', tooltip: "Grant access to assessments and team collaboration.", starter: UserLimit.Starter, business: UserLimit.Business, enterprise: 'Custom' },

        { name: 'Wellness Programs', tooltip: "Personalized health and wellness programs via our mobile app.", starter: LicenseLimit.Starter, business: LicenseLimit.Business, enterprise: 'Custom' },

        { name: 'Marketing Materials & Brochures', tooltip: "Use emails, flyers, and brochures to promote Symmio.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Sub-Organization Accounts', tooltip: "Create sub accounts under your main organization.", starter: <CrossIcon />, business: "Coming Soon", enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Priority Support', tooltip: "Get faster responses to all support inquiries.", starter: <CrossIcon />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Dedicated Customer Success Manager', tooltip: "Direct access to our specialized Success team for personalized guidance.", starter: <CrossIcon />, business: <CrossIcon />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },
    ];

    const adminFeaturesInfo = [
        { name: 'Wellness Management Platform', tooltip: "A comprehensive web platform for managing wellness.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Functional Wellness Assessment', tooltip: "Evaluate eight domains of wellness through our digital tools.", starter: "Unlimited", business: "Unlimited", enterprise: "Unlimited" },

        { name: 'Prebuilt Dashboard', tooltip: "Overview your organization's key data at a glance.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Structure Management', tooltip: "Optimize your organizational hierarchy for better data insight.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'User Profiles & Management', tooltip: "Comprehensive controls for user and team management.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Groups & Tags', tooltip: "Efficiently manage user groups and categorization.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Reporting', tooltip: "Generate detailed reports on compliance, summaries, and more.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'CSV Exports', tooltip: "Download report data in CSV format for external use.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Invite Links', tooltip: "Generate custom links with URL and QR code for easy app and assessment access.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },
    ];

    const userFeaturesInfo = [
        { name: 'Functional Wellness Assessment', tooltip: "Assess the 8 domains of functional wellness through Movement & lifestyle Assessments.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Wellness Score', tooltip: "Evidence-based score covering 8 domains of functional wellness.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Detailed Health Reports', tooltip: "Receive a PDF report detailing users wellness metrics.", starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: 'Symmio App', tooltip: "Personal health and wellness plans on our iOS or Android app.", starter: "With a Wellness Program", business: "With a Wellness Program", enterprise: "With a Wellness Program" },

        { name: 'Personalized Plans', tooltip: "Tailored plans delivered to enhance your users wellness.", starter: "With a Wellness Program", business: "With a Wellness Program", enterprise: "With a Wellness Program" },

        { name: 'Movement Corrective Exercises', tooltip: "Daily routines to improve mobility and overall wellness.", starter: "With a Wellness Program", business: "With a Wellness Program", enterprise: "With a Wellness Program" },

        { name: 'Health & Wellness Articles & Videos', tooltip: "Educational resources to foster better health habits.", starter: "With a Wellness Program", business: "With a Wellness Program", enterprise: "With a Wellness Program" },
    ];

    const addOnsInfo = [
        { name: 'Additional Users', tooltip: "Expand your team's limit with more user slots.", starter: <CrossIcon />, business: `Starting at $${addOnPrices?.user || "..."}/Month`, enterprise: "Custom Pricing" },

        { name: 'Additional Wellness Programs', tooltip: "Add extra Wellness Programs for your team and users.", starter: <CrossIcon />, business: `Starting at $${addOnPrices?.license || "..."}/Month`, enterprise: "Custom Pricing" },

        { name: 'Additional Sub-Organizations', tooltip: "Add more sub-organizations under your primary account.", starter: <CrossIcon />, business: "Coming Soon", enterprise: "Custom Pricing" },
    ];

    return (
        <>
            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>Basics</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {basicsInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>Add-Ons</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addOnsInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>Admin Features</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {adminFeaturesInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>User Features</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userFeaturesInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default PlansTableContent;