import React, { useEffect } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import OrganizationService from "../../utilities/services/organization-service";
import { useHistory } from 'react-router-dom';
import UserUtil from '../../utilities/user-util';
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import UserService from "../../utilities/services/user-service";

export interface DeactivatedAccountCheckProps {
}

/**
 * A modal that shows up when the user's subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const DeactivatedAccountCheck: React.FC<DeactivatedAccountCheckProps> = (props) => {
    const { state, setState } = useAuthState();
    const history = useHistory();

    useEffect(() => {
        if (history.location.pathname !== "/login") {
            const fetchOrganization = async () => {
                const isAccountHolder = UserUtil.isAccountHolder(state.user);
                if (state.authenticated && isAccountHolder && state.user?.organizationId) {
                    const org = await OrganizationService.get(state.user?.organizationId);

                    if (org && org.status === OrganizationStatus.Deactivated && (org.showDeactivatedAccountPlanModal || org.showDeactivatedAccountAddOnModal)) {
                        setState((state) => ({
                            ...state, ...{ organization: org }
                        }));
                        history.replace("/reactivate-subscription");
                    }
                }
            }

            const fetchUser = async () => {
                if (state.authenticated && state.user?.id) {
                    const user = await UserService.get(state.user.id);

                    if (user && user?.subscription && user?.subscription.didPaymentFail) {
                        setState((state) => ({
                            ...state, ...{ user: user }
                        }));
                        history.replace("/reactivate-user-subscription");
                    }
                }
            }

            if (state.user?.subscription && state.user.subscription.subscriptionId) {
                fetchUser();
            }

            fetchOrganization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>);
};

export default DeactivatedAccountCheck;