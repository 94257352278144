import dayjs from 'dayjs';
import getFirebase from './firebase';

export function getRemainingTimeUntilMsTimestamp(timestampMs: any) {
    const timestampDayJs = dayjs(timestampMs);
    const nowDayJs = dayjs();
    const { auth } = getFirebase();
    if (timestampDayJs.isBefore(nowDayJs)) {
        auth.signOut();
        return {
            seconds: '00',
            minutes: '00'
        }
    }
    return { 
        seconds : getRemainingSeconds(nowDayJs, timestampDayJs).toString(),
        minutes: getRemainingMinutes(nowDayJs, timestampDayJs).toString()
    };
}

function getRemainingSeconds(nowDayjs: any, timestampDayJs: any) {
    const seconds = timestampDayJs.diff(nowDayjs, 'seconds') % 60;
    return seconds;
}

function getRemainingMinutes(nowDayjs: any, timestampDayJs: any) {
    const minutes = timestampDayJs.diff(nowDayjs, 'minutes') % 60;
    return minutes;
}

