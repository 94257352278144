import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Organization } from "../../models/interfaces/organization";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "organizations";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new organization to the organizations collection
 * @param {Organization} organization - The organization that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Organization>} A promise to the newly added organization
 */
const add = async (organization: Organization, currentUser: User | null = null) => {
    return FirestoreService.add<Organization>(COLLECTION_NAME, organization, currentUser);
};

/**
 * Delete a organization collection by the Id
 * @param {string} id - The Id of the organization being deleted
 */
const deleteById = async (id: string) => {
    return FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific organization by the id
 * @param {string} id - The Id of the organization that we are retrieving
 * @returns {Promise<Organization>} A promise for the Organization we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Organization>(COLLECTION_NAME, id);
};

/**
 * Get all organizations by the specified conditions
 * @param {FirestoreCondition[]} conditions - The conditions
 * @param {FirestoreOrder[]} order - The order
 * @param {number} limit - The number of results to limit by
 * @returns {Promise<User>} A promise for the ORganizations we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<Organization>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the organizations stored in the database
 * @returns {Promise<Organization[]>} A promise for the collection of Organizations
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<Organization>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific organization to see when it changes
 * @param {string} id - Id of the organization document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<Organization>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the organizations to see when it changes
 * @param {string} id - Id of the organization document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<Organization>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified organization in the database
 * @param {Organization} organization - The organization that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Organization>} A promise for the organization that is being updated
 */
const save = async (organization: Organization, currentUser: User | null = null) => {
    return FirestoreService.save<Organization>(COLLECTION_NAME, organization, currentUser);
};

/**
 * Update the specified organization stored in the database
 * @param {Organization} organization - The organization that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Organization>} A promise for the organization that is being updated
 */
const update = async (organization: Organization, currentUser: User | null = null) => {
    return FirestoreService.update<Organization>(COLLECTION_NAME, organization, currentUser);
};

const isUserLimitReached = async (organization: Organization) => {
    if (organization?.userCount && organization?.userLimit && organization.userCount >= organization.userLimit) {
        return true;
    }
    else {
        return false;
    }
}

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const OrganizationService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
    isUserLimitReached,
};

export default OrganizationService;

// #endregion Exports