import type { Stripe } from 'stripe'
import OrganizationSubscriptionService from './organization-subscription-service';
import { PaymentCycle } from '../../models/enumerations/payment-cycle';
import FunctionService from './function-service';
import { User } from '../../models/interfaces/user';
import UserService from './user-service';
import { OrganizationSubscription } from '../../models/interfaces/organization-subscription';

const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

/**
 * Handles subscription downgrade/cancellation
 * @param {string} organizationId - organization ID to cancel subscription for
 * @param {User} user - user to send email to
 */
const handleCancelSubscription = async (organizationId: string | undefined, user: User) => {
    if (organizationId) {
        const orgSub = await OrganizationSubscriptionService.getByOrganizationId(organizationId);

        if (orgSub.length === 0 || !orgSub[0].stripePlanSubscription || !orgSub[0].renewDate) {
            console.error("Organization Subscription is not found");
            throw new Error("There was an error cancelling your subscription");
        }

        await stripe.subscriptions.update(
            `${orgSub[0].stripePlanSubscription}`,
            {
                cancel_at_period_end: true,
            }
        );
        const nextPaymentDate = new Date(orgSub[0].renewDate);
        await FunctionService.sendCancellationEmail(user, nextPaymentDate.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        }));
        if (orgSub[0].stripeAddOnSubscription && orgSub[0].paymentCycle?.value === PaymentCycle.Yearly) {
            // TODO: Send an email informing that they need to cancel the add-ons as well
        }
    }
    else {
        console.error("Organization ID is not provided");
        throw new Error("There was an error cancelling your subscription");
    }
}

/**
 * Handles subscription downgrade/cancellation
 * @param {string} organizationId - user ID to cancel subscription for
 * @param {User} user - user to send email to
 */
const handleCancelUserSubscription = async (userId: string | undefined, user: User) => {
    if (userId) {
        const user = await UserService.get(userId);

        if (!user || (user && !user.subscription?.subscriptionId)) {
            console.error("User Subscription is not found");
            throw new Error("There was an error cancelling your subscription");
        }

        await stripe.subscriptions.update(
            `${user.subscription?.subscriptionId}`,
            {
                cancel_at_period_end: true,
            }
        );

        await FunctionService.sendUserSubscriptionCancellationEmail(user);
    }
    else {
        console.error("User ID is not provided");
        throw new Error("There was an error cancelling your subscription");
    }
}

/**
 * Handles subscription reactivation
 * @param {string} email - User's email
 */
const handleSubscriptionReactivation = async (organizationId: string | undefined) => {
    if (organizationId) {
        const orgSub = await OrganizationSubscriptionService.getByOrganizationId(organizationId);

        if (orgSub.length === 0 || !orgSub[0].stripePlanSubscription) {
            console.error("Organization Subscription is not found");
            throw new Error("There was an error cancelling your subscription");
        }

        await stripe.subscriptions.update(
            `${orgSub[0].stripePlanSubscription}`,
            {
                cancel_at_period_end: false,
            }
        );
    }
    else {
        console.error("Organization ID is not provided");
        throw new Error("There was an error cancelling your subscription");
    }
}

/**
 * Handles subscription reactivation
 * @param {string} userId - User's ID
 */
const handleUserSubscriptionReactivation = async (userId: string | undefined) => {
    if (userId) {
        const userSub = await UserService.get(userId);

        if (!userSub || (userSub && !userSub.subscription?.subscriptionId)) {
            console.error("User Subscription is not found");
            throw new Error("There was an error cancelling your subscription");
        }

        await stripe.subscriptions.update(
            `${userSub.subscription?.subscriptionId}`,
            {
                cancel_at_period_end: false,
            }
        );
    }
    else {
        console.error("User ID is not provided");
        throw new Error("There was an error cancelling your subscription");
    }
}

const getCustomerPortalSession = async (user: User | null | undefined, orgSub: OrganizationSubscription | null | undefined, allowSubscriptionChange: boolean = true) => {
    if (user && orgSub) {
        let customers = await stripe.customers.list({
            limit: 1,
            email: `${user.email}`,
        })

        if (customers.data.length > 0) {
            const customer = customers.data[0];

            const product = orgSub.thirdPartyProductId || "";
            const prices = [orgSub.mySymmioMonthlyPriceId || "", orgSub.mySymmioYearlyPriceId || ""];

            const configuration = await stripe.billingPortal.configurations.create({
                business_profile: {
                    privacy_policy_url: 'https://example.com/privacy',
                    terms_of_service_url: 'https://example.com/terms',
                },
                features: {
                    customer_update: {
                        enabled: false,
                    },
                    invoice_history: {
                        enabled: true,
                    },
                    payment_method_update: {
                        enabled: true,
                    },
                    subscription_update:
                        allowSubscriptionChange
                            ?
                            {
                                default_allowed_updates: ["price"],
                                enabled: true,
                                proration_behavior: 'create_prorations',
                                products: [
                                    {
                                        product: product,
                                        prices: prices
                                    },
                                ],
                            }
                            :
                            undefined
                },
            });

            const session = await stripe.billingPortal.sessions.create({
                customer: customer.id,
                return_url: window.location.href,
                configuration: configuration.id,
            });

            window.location.href = session.url;
        }
    };
};

const SubscriptionService = {
    handleCancelSubscription,
    handleSubscriptionReactivation,
    handleUserSubscriptionReactivation,
    handleCancelUserSubscription,
    getCustomerPortalSession,
};

export default SubscriptionService;