import UserList from "../../components/user-list/user-list";

const UsersPage: React.FC = () => {

    return (
        <UserList />
    );
}

export default UsersPage;
