import React from "react";

export interface RequestModalTitleProps {
    newRequest: boolean,
}

/**
 * Represents the title that is displayed in the request modal.
 *
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RequestModalTitle: React.FC<RequestModalTitleProps> = (props) => {
    const { newRequest } = props;

    const title = newRequest
        ? 'Thank you!'
        : 'You have already submitted a request'

    return (
        <h1 className="block" style={{ padding: '105px 0px 48px 0px', textAlign: 'center' }}>{title}</h1>
    );
};

export default RequestModalTitle;