import { useState } from "react";
import FirebaseStorage from "../../utilities/firebase-storage";
import AppModal from "../app-modal/app-modal";

const COMPONENT_CLASS = "c-assessment-complete";

export interface MovementCompleteProps {
    errors?: string[];
}

const MovementComplete: React.FC<MovementCompleteProps> = (props) => {
    const [errors, setErrors] = useState<string[]>(props.errors ?? []);

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <AppModal
                isOpen={errors != null && errors.length >= 1}
                onDismiss={() => {
                    setErrors([]);
                }}>
                <div style={{ padding: "10px", color: "#990000" }}>
                    <div>
                        There were errors saving your Movement results.
                        <br />
                        Please wait a few minutes and try submitting again.
                        <br />
                        <br />
                    </div>
                    <div>{errors?.join("; ")}</div>
                </div>
            </AppModal>
            <div className={`${COMPONENT_CLASS}__image hide-on-mobile`}>
                <img
                    alt="Thank you"
                    src={new FirebaseStorage().getMediaUrl(
                        "public/complete/movement-complete@2x.png"
                    )}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__content`}>
                <h1>Thank You</h1>
                <p className={`${COMPONENT_CLASS}__description`}>
                    You've completed your Movement Assessment.
                </p>
            </div>
        </div>
    );
};

export default MovementComplete;
