import logoHeader from "../../assets/images/symmio-logos/symmio-logo-text-white-side-large.png"

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportGenericHeaderProps {
    companyName?: string;
    reportName: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportGenericHeader: React.FC<ReportGenericHeaderProps> = (props: ReportGenericHeaderProps) => {
    const { companyName, reportName } = props;
    const todayDate = (new Date()).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <div className={`${COMPONENT_CLASS}__header__wellness`}>
            <div className={`${COMPONENT_CLASS}__logo`}>
                <img src={logoHeader} alt="Symmio Logo" />
            </div>
            <div>
                <div className="text-sm font-light text-right">{reportName}</div>
                <div className="text-xs font-light text-right" style={{ color: "rgba(173, 223, 253, 1)" }}>{companyName || "Company Name Not Provided"} • {todayDate}</div>
            </div>
        </div>
    );
}

export default ReportGenericHeader;