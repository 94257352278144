import { useStoreAssessments } from "../../store/useStoreAssessments";

/*
-------------------------------------------------------------------------
Hook
-------------------------------------------------------------------------
*/

/**
 * Convenience wrapper to get a typed version of our global state
 * @param context
 */
function useGlobalState() {
    const globalState = useStoreAssessments((state) => ({
        currentIdentity: state.currentIdentity,
        isAuthenticated: state.currentIdentity?.isAuthenticated ?? false,
        isLoading: state.isLoading,
        notificationsEnabled: state.notificationsEnabled,
        errors: state.errors,
        hasMskScore: state.hasMskScore
    }));

    return {
        globalState,
        setGlobalErrors: useStoreAssessments((state) => state.setGlobalErrors),
        setGlobalError: useStoreAssessments((state) => state.setGlobalError),
        setIdentity: useStoreAssessments((state) => state.setIdentity),
        setNotifications: useStoreAssessments((state) => state.setNotifications),
        setHasMskScore: useStoreAssessments((state) => state.setHasMskScore),
    };
}

/*
-------------------------------------------------------------------------
Exports
-------------------------------------------------------------------------
*/

export { useGlobalState };
