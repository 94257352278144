import { DashboardData } from "../../models/interfaces/reports/dashboard-data";
import { User } from "../../models/interfaces/user";
import { httpsCallable } from "firebase/functions";
import getFirebase from "../firebase";
import { UniqueUserInviteLink } from "../../models/interfaces/unique-user-invite-link";
import { LeadLink } from "../../models/interfaces/lead-link";
import { AssessmentUtils } from "../assessments/assessment-utils";
import ReportService from "./report-service";
import { ReportType } from "../../models/enumerations/report-type";


const { functions } = getFirebase();

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------'

/**
 * @param {Group[]} groups
 * @param {string} organizationId
 */
const getDashboardData = async (
    groupIds: string[] | null,
    organizationId: string,
    includeInactiveUsers: boolean,

): Promise<DashboardData | null> => {
    if (organizationId == null) {
        return null;
    }
    const getDashboardData = httpsCallable(functions, "getDashboardData");
    const response = await getDashboardData({
        groupIds: groupIds,
        includeInactiveUsers: includeInactiveUsers,
        monthsAgo: 11,
        organizationId: organizationId
    });
    return response.data as DashboardData;
};

const getReportData = async (
    reportId: string,
    userId: string,
) => {
    if (reportId == null || userId == null) {
        return;
    }
    const test2 = await ReportService.get(reportId);
    if (test2 && test2.type === ReportType.Wellness) {
        const test = await AssessmentUtils.fetchAssessmentResponsesById(userId)
        const getReportData = httpsCallable(functions, "getReportData");
        const response = await getReportData({ reportId: reportId, userId: userId, assessmentResponses: test });
        return response.data;
    }
    console.log("do i ever get this");
    const getReportData = httpsCallable(functions, "getReportData");
    const response = await getReportData({ reportId: reportId, userId: userId, assessmentResponses: undefined });
    return response.data;
}

/**
 * @param {User} user
 */
const updateUserRoles = async (user: User) => {
    if (user?.id == null) {
        return;
    }
    const updateUserRoles = httpsCallable(functions, "updateUserRoles");
    return updateUserRoles({ uid: user.id });
};

const getTestStripeProducts = async () => {
    const getTestStripeProducts = httpsCallable(functions, "getTestStripeProducts");
    return getTestStripeProducts();
}

const updateOrganizationPlansToStarter = async () => {
    const updateOrganizationPlansToStarter = httpsCallable(functions, "updateOrganizationPlansToStarter");
    return updateOrganizationPlansToStarter();
}

const sendInviteEmail = async (user: User, uniqueUserInviteLink: UniqueUserInviteLink) => {
    const sendInviteEmail = httpsCallable(functions, "sendInviteEmail");
    let data = { user: user, uniqueUserInviteLink: uniqueUserInviteLink };
    return sendInviteEmail(data);
}

const sendCancellationEmail = async (user: User, nextPaymentDate: any) => {
    const sendCancellationEmail = httpsCallable(functions, "sendCancellationEmail");
    let data = { user: user, nextPaymentDate: nextPaymentDate };
    return sendCancellationEmail(data);
}

const sendAddOnLicenseConfirmationEmail = async (user: User, paymentInfo: any) => {
    const sendAddOnLicenseConfirmationEmail = httpsCallable(functions, "sendAddOnLicenseConfirmationEmail");
    let data = { user: user, paymentInfo: paymentInfo };
    return sendAddOnLicenseConfirmationEmail(data);
}

const sendAddOnUserConfirmationEmail = async (user: User, paymentInfo: any) => {
    const sendAddOnUserConfirmationEmail = httpsCallable(functions, "sendAddOnUserConfirmationEmail");
    let data = { user: user, paymentInfo: paymentInfo };
    return sendAddOnUserConfirmationEmail(data);
}

const sendAddOnLicenseRemovalEmail = async (user: User, addOnInfo: any) => {
    const sendAddOnLicenseRemovalEmail = httpsCallable(functions, "sendAddOnLicenseRemovalEmail");
    let data = { user: user, addOnInfo: addOnInfo };
    return sendAddOnLicenseRemovalEmail(data);
}

const sendAddOnUserRemovalEmail = async (user: User, addOnInfo: any) => {
    const sendAddOnUserRemovalEmail = httpsCallable(functions, "sendAddOnUserRemovalEmail");
    let data = { user: user, addOnInfo: addOnInfo };
    return sendAddOnUserRemovalEmail(data);
}

const sendMySymmioAccessAssigned = async (user: User, info: any) => {
    const sendMySymmioAccessAssigned = httpsCallable(functions, "sendMySymmioAccessAssigned");
    let data = { user: user, info: info };
    return sendMySymmioAccessAssigned(data);
}

const sendMySymmioAccessRemoved = async (user: User, info: any) => {
    const sendMySymmioAccessRemoved = httpsCallable(functions, "sendMySymmioAccessRemoved");
    let data = { user: user, info: info };
    return sendMySymmioAccessRemoved(data);
}

const sendBusinessMonthlyConfirmationEmail = async (user: User, paymentInfo: any, couponInfo: any | null) => {
    const sendBusinessMonthlyConfirmationEmail = httpsCallable(functions, "sendBusinessMonthlyConfirmationEmail");
    let data = { user: user, paymentInfo: paymentInfo, couponInfo: couponInfo };
    return sendBusinessMonthlyConfirmationEmail(data);
}

const sendBusinessYearlyConfirmationEmail = async (user: User, paymentInfo: any, couponInfo: any | null) => {
    const sendBusinessYearlyConfirmationEmail = httpsCallable(functions, "sendBusinessYearlyConfirmationEmail");
    let data = { user: user, paymentInfo: paymentInfo, couponInfo: couponInfo };
    return sendBusinessYearlyConfirmationEmail(data);
}

const sendSubscriptionReactivationEmail = async (email: string, subscriptionDetails: {}) => {
    const sendSubscriptionReactivationEmail = httpsCallable(functions, "sendSubscriptionReactivationEmail");
    let data = { email: email, subscriptionDetails: subscriptionDetails };
    return sendSubscriptionReactivationEmail(data);
}

const sendAccountOwnerWelcomeEmail = async (user: User) => {
    const sendAccountOwnerWelcomeEmail = httpsCallable(functions, "sendAccountOwnerWelcomeEmail");
    let data = { user: user };
    return sendAccountOwnerWelcomeEmail(data);
}

const sendUserSubscriptionConfirmationEmail = async (user: User) => {
    const sendUserSubscriptionConfirmationEmail = httpsCallable(functions, "sendUserSubscriptionConfirmationEmail");
    let data = { user: user };
    return sendUserSubscriptionConfirmationEmail(data);
}

const sendUserSubscriptionCancellationEmail = async (user: User) => {
    const sendUserSubscriptionCancellationEmail = httpsCallable(functions, "sendUserSubscriptionCancellationEmail");
    let data = { user: user };
    return sendUserSubscriptionCancellationEmail(data);
}

const generateDeeplinkUrl = async (id: string) => {
    const callableFunction = httpsCallable(functions, "generateDeeplinkUrl");
    return callableFunction({ id: id });
}

const createUserFromDeeplink = async (id: string, email: string) => {
    const callableFunction = httpsCallable(functions, "createUserFromDeeplink");

    await callableFunction({
        id: id,
        email: email
    });

    return true;
}

const updateLeadLink = async (leadLink: LeadLink) => {
    const updateLeadLink = httpsCallable(functions, "updateLeadLink");
    let data = { leadLink: leadLink };
    return updateLeadLink(data);
}

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const FunctionService = {
    getDashboardData,
    getReportData,
    getTestStripeProducts,
    updateUserRoles,
    sendInviteEmail,
    sendCancellationEmail,
    sendAddOnLicenseConfirmationEmail,
    sendAddOnUserConfirmationEmail,
    sendAddOnLicenseRemovalEmail,
    sendAddOnUserRemovalEmail,
    sendMySymmioAccessAssigned,
    sendMySymmioAccessRemoved,
    sendBusinessMonthlyConfirmationEmail,
    sendBusinessYearlyConfirmationEmail,
    sendSubscriptionReactivationEmail,
    sendAccountOwnerWelcomeEmail,
    sendUserSubscriptionConfirmationEmail,
    sendUserSubscriptionCancellationEmail,
    updateOrganizationPlansToStarter,
    generateDeeplinkUrl,
    createUserFromDeeplink,
    updateLeadLink,
};

export default FunctionService;
