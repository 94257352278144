import { useLocation } from 'react-router';
import ReportRunner from '../../components/report/report-runner';
import { ReportType } from '../../models/enumerations/report-type';
import { Utils } from '../../utilities/utils';

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface GenerateReportPageProps { }

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const GenerateReportPage: React.FC<GenerateReportPageProps> = (props: GenerateReportPageProps) => {
    const location = useLocation();

    const { reportId, type, userId } = Utils.parseQueryString(location.search);

    let reportType = ReportType.Compliance;

    switch (type) {
        case "0":
            reportType = ReportType.Compliance;
            break;
        case "1":
            reportType = ReportType.Detail;
            break;
        case "2":
            reportType = ReportType.Score;
            break;
        case "3":
            reportType = ReportType.Summary;
            break;
        case "4":
            reportType = ReportType.Assessment;
            break;
        case "5":
            reportType = ReportType.Wellness;
            break;
        case "6":
            reportType = ReportType.Movement;
            break;
    }

    return (
        <ReportRunner
            reportId={reportId}
            type={reportType}
            userId={userId} />
    );
}

export default GenerateReportPage;