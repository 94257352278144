import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Modal from "react-modal"

const COMPONENT_CLASS = "c-modal";

export interface AppModalProps {
    children?: React.ReactNode;
    cssClassName?: string;
    hideClose?: boolean;
    isOpen?: boolean;
    onDismiss: () => void;
}

const root = document.getElementById("root");
if (root) {
    Modal.setAppElement(root);
} else {
    Modal.setAppElement("*");
}

const AppModal: React.FC<AppModalProps> = (props) => {
    return (
        <Modal
            className={`${COMPONENT_CLASS} ${props.cssClassName != null ? props.cssClassName : ""
                }`}
            overlayClassName={`${COMPONENT_CLASS}__overlay`}
            isOpen={props.isOpen || false}
            onRequestClose={props.onDismiss}
            shouldCloseOnOverlayClick={true}>
            <>
                {
                    // if
                    props.hideClose !== true && (
                        <IonIcon
                            className={`${COMPONENT_CLASS}__close`}
                            icon={closeOutline}
                            onClick={props.onDismiss}
                        />
                    )
                }
                {props.children}
            </>
        </Modal>
    );
};

export default AppModal;
