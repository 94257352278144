import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { Loader } from "../loader/loader";
import ModalActions from "./modal-actions";

type ModalProps = {
    children: React.ReactNode;
    isOpen: boolean;
    isLoading: boolean;
    onClose?: (open: boolean) => void;
    title?: string;
    componentClass?: string;
    defaultModalActions?: boolean;
    defaultCenterModal?: boolean;
    submitDisabled?: boolean;
    onSubmit?: Function;
    onCancel?: Function;
    submitButtonText?: string;
    cancelButtonText?: string;
};

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
    const COMPONENT_CLASS = props.componentClass ?? "c-modal";

    return (
        <Transition.Root
            show={props.isOpen}
            as={Fragment}>
            <Dialog
                as="div"
                className={COMPONENT_CLASS}
                onClose={props.onClose || (() => { })}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={`${COMPONENT_CLASS}__overlay`} />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={`${COMPONENT_CLASS}__wrapper`}>
                            <Loader
                                isVisible={props.isLoading} />
                            <div className={`${COMPONENT_CLASS}__header ${props.defaultCenterModal ? "header-center" : ""}`}>
                                {props.onClose &&
                                    <div className={`${COMPONENT_CLASS}__header__container-close`}>
                                        <div
                                            className={`${COMPONENT_CLASS}__header__close`}
                                            onClick={() => {
                                                if (props && props.onClose) {
                                                    props.onClose(false)
                                                }
                                            }}>
                                            <XIcon aria-hidden="true" />
                                        </div>
                                    </div>
                                }
                                {
                                    // if
                                    props.title && (
                                        <div className={`${COMPONENT_CLASS}__header__title`}>
                                            <Dialog.Title
                                                as="h2">
                                                {props.title}
                                            </Dialog.Title>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={`${props.defaultModalActions ? `${COMPONENT_CLASS}__default-children` : `${props.defaultCenterModal ? `${COMPONENT_CLASS}__default-center-children` : `${COMPONENT_CLASS}__children`}`}`}>
                                {props.children}
                            </div>
                            {props.defaultModalActions && (props.onCancel || props.onSubmit) &&
                                <div className={`${COMPONENT_CLASS}__modal-actions`} >
                                    <ModalActions
                                        submitDisabled={props.submitDisabled}
                                        onCancel={props.onCancel}
                                        onSubmit={props.onSubmit}
                                        submitButtonText={props.submitButtonText}
                                        cancelButtonText={props.cancelButtonText}
                                        end={true} />
                                </div>
                            }
                            {props.defaultCenterModal && (props.onCancel || props.onSubmit) &&
                                <div className={`${COMPONENT_CLASS}__center-modal-actions`} >
                                    <ModalActions
                                        submitDisabled={props.submitDisabled}
                                        onCancel={props.onCancel}
                                        onSubmit={props.onSubmit}
                                        submitButtonText={props.submitButtonText}
                                        cancelButtonText={props.cancelButtonText}
                                        center={true} />
                                </div>
                            }
                        </div>
                    </Transition.Child>
                </div>
            </Dialog >
        </Transition.Root >
    );
}