import { create } from "zustand";
import produce from "immer";
import { immer } from 'zustand/middleware/immer';

interface QuillOptions {
    modules: any,
    formats: string[]
}

interface State {
    options: QuillOptions
}

interface Actions {
    addTextAlignmentControls: () => Promise<void>;
    addLinkAndCleanControls: () => Promise<void>;
    resetStoreState: () => void;
}

const defaultState: State = {
    options: {
        // https://quilljs.com/docs/modules/toolbar/
        modules: {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['video'],
            ]
        },
        // https://quilljs.com/docs/formats/
        formats: [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent', 'video'
        ]
    }
}

export const useQuillStore = create(
    immer<State & Actions>(
        (set) => ({
            ...defaultState,

            addTextAlignmentControls: async () => {
                set(produce((draft) => {
                    draft.options.modules.toolbar.push([{ 'align': [] }]);
                    draft.options.formats.push('align');
                }));
            },

            addLinkAndCleanControls: async () => {
                set(produce((draft) => {
                    draft.options.modules.toolbar.push(['link']);
                    draft.options.modules.toolbar.push(['clean']);
                    draft.options.formats.push('link');
                }))
            },

            resetStoreState: async () => {
                set(produce(() => {
                    return defaultState;
                }));
            }
        })
    )
);
