/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./checkout-form";
import { Modal } from "../modal/modal";
import OrganizationService from "../../utilities/services/organization-service";
import { PlanType } from "../../models/enumerations/plan-type";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import { UserLimit } from "../../models/enumerations/user-limit";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Organization } from "../../models/interfaces/organization";
import { User } from "../../models/interfaces/user";

interface StripePayProps {
  onSubmitClick: () => void;
  onBackClick?: () => void;
  onChange?: () => void;
  onInfoCreate: (user: User, organization: Organization) => Promise<string>;
}

const StripePay: React.FC<StripePayProps> = ({ onBackClick, onSubmitClick, onChange, onInfoCreate }) => {
  const db = getFirestore();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState<boolean>(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
  //const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const [paymentCycle, setPaymentCycle] = useState<PaymentCycle>(PaymentCycle.Monthly);
  const [startSubscription, setStartSubscription] = useState<number>(0);
  const [products, setProducts] = useState<any>();
  const { state } = useAuthState();

  useEffect(() => {
    if (state.temp.product) {
      setPaymentCycle(state.temp.product.interval);
    }
  }, [state]);

  useEffect(() => {
    const getProductsAndPrices = async () => {
      const products: any[] = [];
      const q = query(
        collection(db, 'products'),
        where('active', '==', true),
      );

      const querySnapshot = await getDocs(q);

      // for each product, get the product price info
      querySnapshot.docs.map(async (productDoc) => {

        let prices: any[] = [];

        // fetch prices subcollection per product
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        // loop through difference business prices
        priceQuerySnapshot.docs.forEach((item) => {

          prices.push({ data: item.data(), id: item.id });
        })
        products.push({
          product: { data: productDoc.data(), id: productDoc.id },
          prices: prices
        })
      });
      await setProducts(products);
    }
    getProductsAndPrices();
  }, [])

  const updateOrg = async () => {
    // Get Organization
    if (!state.temp.organization.id) {
      // Couldn't get organization id
      return;
    }
    const o = await OrganizationService.get(state.temp.organization.id);
    if (o) {
      // const u = await UserService.get(state.temp.user.id);
      // if (u) {
      //   u.organizationId = o.id;
      //   await UserService.save(u);
      // }
      // // Retrieve customer from stripe by Email
      // const customer = await stripe.customers.list({ limit: 1, email: `${state.temp.user.email}` })
      // // Update the subscription by the subscriptionId. Change the item belonging to the subscription
      // if (customer.data.length === 0 || !customer.data[0].id) {
      //   //TODO: Throw an error.
      //   return;
      // }
      o.plan = PlanType[PlanType.Business];
      o.userLimit = UserLimit.Business;
      o.organizationLimit = 1;
      o.userCount = 1;
      o.organizationLimit = 1;
      o.mySymmioLicense = LicenseLimit.Business;
      OrganizationService.save(o);
      onSubmitClick();
    }
  }

  useEffect(() => {
    const openModal = async () => {
      if (paymentSuccessful) {
        updateOrg();
        // setGetPayment(false);
      }
      else if (paymentFailed) {
        // setGetPayment(false);
        setPaymentFailedModalOpen(true);
      }
    }
    openModal();

  }, [paymentFailed, paymentSuccessful])

  const handleStartSubscription = () => {
    setStartSubscription(startSubscription + 1);
  }

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
          <CheckoutForm isCreating={true} products={products} paymentSuccessful={setPaymentSuccessful} paymentFailed={setPaymentFailed} onChangePlan={onChange} returnUrl={``} paymentCycle={paymentCycle} checkoutFlag={startSubscription} onInfoCreate={onInfoCreate} isValidating={setIsValidating} />
        </Elements>
      )}
      <BaseCardLayoutActions
        backButton={true}
        backDisabled={isValidating}
        submitDisabled={isValidating}
        submitButton={true}
        onSubmit={handleStartSubscription}
        submitButtonText="Start Subscription"
        onBack={onBackClick}
      />
      <Modal
        isOpen={paymentFailedModalOpen}
        isLoading={false}
        onClose={() => {
          setPaymentFailedModalOpen(false);
        }}
        title="Oops. Payment Declined"
        defaultCenterModal={true}
        submitButtonText="Continue"
        onSubmit={() => {
          setPaymentFailedModalOpen(false)
        }}>
        <div>
          <p><b>Your payment method was declined</b></p>
          <p>We tried to charge your card but, something went wrong.</p>
          <p>Please update your payment method.</p>
        </div>
      </Modal>
    </>
  );
}

export default StripePay;

