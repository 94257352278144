import React, { useEffect } from "react";
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart, LineElement, LinearScale, PointElement, Title } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

// const COMPONENT_CLASS = "c-line-chart";

export interface LineChartJSProps {
    data?: LineChartPoint[];
    title?: string;
    labels?: string[];
    height?: string;
    width?: string;
    responsive?: boolean;
    maintainAspectRatio?: boolean;
    borderRadius?: string;
    backgroundColor?: string;
}

const LineChartJS: React.FC<LineChartJSProps> = (props) => {
    const { labels, title, data, responsive, maintainAspectRatio, borderRadius, backgroundColor, height, width } = props
    // const plugin = {
    //     id: 'customCanvasBackgroundColor',
    //     beforeDraw: (chart: any, args: any, options: any) => {
    //         const { ctx } = chart;
    //         ctx.save();
    //         ctx.globalCompositeOperation = 'destination-over';
    //         ctx.fillStyle = options.color || '#99ffff';
    //         ctx.fillRect(0, 0, chart.width, chart.height);
    //         ctx.restore();
    //     }
    // };

    // Chart.defaults.font.family = "pragmatica-extended"

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        ChartDataLabels,
        // plugin,
    );
    const chartOptions = {
        responsive: responsive ?? true,
        maintainAspectRatio: maintainAspectRatio ?? true,
        devicePixelRatio: 4,
        layout: {
            // adding padding since custom scale is on the chart itself. We forced it's positioning to be 25 px below chart
            // but then need more padding to accommodate the new scale
            padding: {
                left: 10,
                right: 10,
                top: 5,
                bottom: 10,
            }
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    font: {
                        weight: 'bold' as const,
                        size: 6,
                        // family: 'pragmatica-extended' as const,
                        lineHeight: 0
                    },
                    color: "#12162280",
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                    border: {
                        display: false
                    }
                    // color: (context: any) => {
                    //     if (context.index === 0) {
                    //         return '';
                    //     }
                    //     else {
                    //         return 'rbga(102,102,102,0.2)'
                    //     }
                    // }
                }
            },
            y: {
                beginAtZero: true,
                suggestedMax: 100,
                // on the entire chart, there is a border on the Y axis, this removes the initial (0) scale line border
                border: {
                    display: false
                },
                // this removed the labels
                ticks: {
                    display: false,
                },
                grid: {
                    // Grid lines shoot out of the Y axis including the 0th index (0), we must remove border and this grid line at the
                    // 0th index to remove initial scale line border completely
                    color: (context: any) => {
                        const value = context.tick.value;
                        return typeof value !== 'undefined' && value === 0 ? 'transparent' : "#12162215";
                    },
                    lineWidth: 2 // increased the line width of those coming out of the Y axis
                }
            }
        },
        plugins: {
            datalabels: {
                anchor: 'end' as const,
                align: 'top' as const,
                offset: -6,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const,
                            size: 8,
                            // family: 'pragmatica-extended' as const
                        },
                        color: '#121622'
                    }
                }
            },
            // customCanvasBackgroundColor: {
            //     color: '#F7F9FC'
            //     // color: 'red'
            // },
            title: {
                display: true,
                text: title,
                align: 'start' as const,
                padding: {
                    top: 10,
                    bottom: 15
                },
                font: {
                    weight: 'bold' as const,
                    size: 10,
                    // family: 'pragmatica-extended' as const,
                },
                color: "#121622"
            }
        }

    }


    const chartData = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: data,
                borderColor: '#43B5F8', // border color of line
                backgroundColor: 'rgb(18, 22, 34)', // background color of data point
                pointBorderColor: '#F7F9FC', // point border color
                pointRadius: 4, // size of data point
                pointBorderWidth: 2, // border width for data points
                borderWidth: 3 //border width for line
            }
        ]
    }


    // const [data, setData] = useState<HTMLDivElement>();

    useEffect(() => {
    }, []);

    return (
        <div style={{ width: width, height: height, borderRadius: `${borderRadius ?? 0}`, backgroundColor: backgroundColor }}>
            <Line options={chartOptions} data={chartData} />
        </div>
    );
};



export default LineChartJS;