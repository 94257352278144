import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { SubscriptionLink } from "../../models/interfaces/subscription-link";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "subscriptionLinks";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

const add = async (leadLink: SubscriptionLink, currentUser: User | null = null) => {
    return FirestoreService.add<SubscriptionLink>(COLLECTION_NAME, leadLink, currentUser);
};

const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

const get = async (id: string) => {
    return FirestoreService.get<SubscriptionLink>(COLLECTION_NAME, id);
};

const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<SubscriptionLink>(COLLECTION_NAME, conditions, order, limit);
};

const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<SubscriptionLink>(COLLECTION_NAME, order);
};

const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<SubscriptionLink>(COLLECTION_NAME, id, listener);
};

const getSnapshotBy = (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    listener: Function
) => {
    return FirestoreService.getSnapshotBy<SubscriptionLink>(COLLECTION_NAME, conditions, order, -1, listener);
};

const save = async (leadLink: SubscriptionLink, currentUser: User | null = null) => {
    return FirestoreService.save<SubscriptionLink>(COLLECTION_NAME, leadLink, currentUser);
};

const update = async (leadLink: SubscriptionLink, currentUser: User | null = null) => {
    return FirestoreService.update<SubscriptionLink>(COLLECTION_NAME, leadLink, currentUser);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const SubscriptionLinkService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default SubscriptionLinkService;

// #endregion Exports
