import { GroupIds } from "../../models/enumerations/group-ids";
import { LifestyleInjuryHistoryScore } from "../../models/interfaces/injury-history-question-group-score";
import { MovementScore } from "../../models/interfaces/movement-score";
import { MskScore } from "../../models/interfaces/msk-score";
import { Score } from "../../models/interfaces/score";
import { User } from "../../models/interfaces/user";
import GroupUtil from "../../utilities/group-util";
import UserProfileCard from "../user-profile-card/user-profile-card";

const COMPONENT_CLASS = "c-user-profile-summary";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileSummaryProps {
    mskScore?: MskScore;
    user: User;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileSummary: React.FC<UserProfileSummaryProps> = (props: UserProfileSummaryProps) => {
    const mskScore = props.mskScore;
    const groupAverages = mskScore?.averageScores?.groupAverages;

    let movementScore: Score | undefined;
    if (mskScore?.movementScore != null)
    {
        movementScore = {
            groupId: GroupUtil.getName(GroupIds.MOVEMENT_HEALTH),
            percentage: mskScore.movementScore.percentage,
            resultDescription: mskScore.movementScore.resultDescription,
            resultLevel: mskScore.movementScore.resultLevel,
        };
    }

    const groups: any[] = [
        { groupId: GroupIds.MOVEMENT_HEALTH,    score: movementScore },
        { groupId: GroupIds.BEHAVIORAL_HEALTH,  score: mskScore?.lifestyleScore?.behavioralScore },
        { groupId: GroupIds.INJURY_HISTORY,     score: mskScore?.lifestyleScore?.injuryHistoryScore },
        { groupId: GroupIds.BREATHING,          score: mskScore?.lifestyleScore?.breathingScore },
        { groupId: GroupIds.SLEEP,              score: mskScore?.lifestyleScore?.sleepScore },
        { groupId: GroupIds.NUTRITION,          score: mskScore?.lifestyleScore?.nutritionScore },
        { groupId: GroupIds.PHYSICAL_ACTIVITY,  score: mskScore?.lifestyleScore?.physicalActivityScore },
        { groupId: GroupIds.BODY_COMPOSITION,   score: mskScore?.lifestyleScore?.bodyCompositionScore },
    ].filter(x => x.score != null);

    const getGroupAverage = (groupId: string): number | undefined => {
        if (groupAverages == null || groupAverages.length === 0) {
            return undefined;
        }
        const match = groupAverages.filter(x => x.groupId === groupId);
        if (match.length === 0) {
            return undefined;
        }
        return match[0].average;
    }

    const hasMovementPain = (movementScore?: MovementScore): boolean => {
        if (movementScore == null) {
            return false;
        }
        return movementScore.balanceScore?.score === 0
            || movementScore.rotationScore?.score === 0
            || movementScore.shoulderMobilityScore?.score === 0
            || movementScore.squatScore?.score === 0
            || movementScore.toeTouchScore?.score === 0;
    }

    return (
        <div className={`${COMPONENT_CLASS}__container`}>
            {groups.map((group: any, index) => {
                const average = getGroupAverage(group.groupId);
                let hasPain = false;

                if (group.groupId === GroupIds.MOVEMENT_HEALTH) {
                    hasPain = hasMovementPain(mskScore?.movementScore);
                } else if (group.groupId === GroupIds.INJURY_HISTORY) {
                    hasPain = ((group.score as LifestyleInjuryHistoryScore)?.riskFactors?.hadPain === true);
                }

                return (
                    <UserProfileCard
                        averageDescription={`average score for ${mskScore?.averageScores?.ageRange} ${mskScore?.averageScores?.isFemale ? "female" : "male"} is ${average}`}
                        averagePercent={average}
                        focusAreas={[]}
                        hasPain={hasPain}
                        key={`summary-${index}`}
                        score={group.score}
                        title={GroupUtil.getName(group.groupId)}/>
                )
            })}
        </div>
    );
}

export default UserProfileSummary;
