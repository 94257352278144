import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";

export interface LoginModalTitleProps {
    error: LoginError,
}

/**
 * Represents the title that is displayed in the login modal when a login error
 * occurs.
 *
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginModalTitle: React.FC<LoginModalTitleProps> = (props) => {
    const { error } = props;

    const title = (error === LoginError.AdminTrialExpired) || (error === LoginError.TrialExpired)
        ? 'Your Trial Has Expired'
        : 'Your Account Has Been Deactivated'

    return (
        <h1 className="block" style={{ padding: '105px 0px 48px 0px', textAlign: 'center' }}>{title}</h1>
    );
};

export default LoginModalTitle;