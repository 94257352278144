import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import MovementScreen from "../../components/movement-screen/movement-screen";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../../components/assessments-user-menu/assessments-user-menu";

const COMPONENT_CLASS = "p-movement-assessment";

const Movement: React.FC = () => {
    const history = useHistory();
    const [settings, setSettings] = useState<AssessmentSettings>({
        completedGroupIds: [],
        currentSlideIndex: 0,
        currentGroupIndex: 0,
        questionResponses: [],
        slideGroups: [],
    });
    const { state } = useAuthState();

    useEffect(() => {
        document.title = "Symmio Account";
    }, [])

    useEffect(() => {
        document.body.classList.add("-nav-hidden");

        return () => {
            document.body.classList.remove("-nav-hidden");
        };
    }, []);

    const onBackButtonClick = () => {
        if (AssessmentUtils.isFirstSlideAndFirstGroup(settings)) {
            return history.goBack();
        }

        setSettings(AssessmentUtils.moveToPreviousPage(settings));
    };

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }

    if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect to="/lc-assessment-results" />
    }

    return (
        <div className={COMPONENT_CLASS}>
            <div className={`user-menu hide-on-mobile`} style={{}}>
                <AssessmentsUserMenu profileImage={false} />
            </div>
            <BaseCardLayout>
                <MovementScreen
                    screenSettings={settings}
                    setScreenSettings={setSettings}
                    settingsOnBack={onBackButtonClick}
                />
            </BaseCardLayout>
        </div>

        // <IonPage>
        //     <Header
        //         isDark={darkHeader}
        //         isTransparent={invisibleHeader}
        //         onBackButtonClick={onBackButtonClick}
        //         backButtonType={BackButtonType.Wizard}
        //         title="Movement Assessment"
        //     />
        //     <IonContent fullscreen scrollY={false}>
        //         <MovementScreen
        //             screenSettings={settings}
        //             setScreenSettings={setSettings}
        //         />
        //     </IonContent>
        // </IonPage>
    );
};

export default Movement;
