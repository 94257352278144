import React, { useEffect, useState } from "react";
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Loader } from "../loader/loader";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import { WarningItem } from "../warning-item/warning-item";
import Stripe from "stripe";
import { Organization } from "../../models/interfaces/organization";
import { AddOnId } from "../../models/enumerations/add-on-id";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { PlanId } from "../../models/enumerations/plan-id";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import OrganizationService from "../../utilities/services/organization-service";
import { useHistory } from "react-router-dom";
import FunctionService from "../../utilities/services/function-service";

interface ReactivationDetails {
  planExists: boolean,
  planType: string;
  planCost: string;
  nextPaymentDate: string;
  paymentCycle: string;
  userAddonExists: boolean;
  userAddonCount: string;
  userAddonAmountCharged: string;
  licenseAddonExists: boolean;
  licenseAddonCount: string;
  licenseAddonAmountCharged: string;
  amountCharged: string;
}

interface ReactivateSubscriptionFormProps {
  isReactivatingPlan: boolean;
  isReactivatingAddOn: boolean;
  organization: Organization;
  returnUrl: string;
  products: any[];
  onBackButtonClick: Function;
}

const ReactivateSubscriptionForm: React.FC<ReactivateSubscriptionFormProps> = ({ isReactivatingPlan, isReactivatingAddOn, returnUrl, organization, products, onBackButtonClick }) => {
  const COMPONENT_CLASS = "c-checkoutform";
  const { state } = useAuthState();
  const stripe = useStripe();
  const stripe2: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const elements = useElements();
  const [paymentFailedBanner, setPaymentFailedBanner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationSubscription, setOrganizationSubscription] = useState<OrganizationSubscription>();
  const [stripePlanSubscription, setStripePlanSubscription] = useState<Stripe.Subscription>();
  const [stripeAddOnSubscription, setStripeAddOnSubscription] = useState<Stripe.Subscription>();
  const [planPriceInfo, setPlanPriceInfo] = useState<Stripe.Price.Recurring | null | undefined>();
  const [addOnPriceInfo, setAddOnPriceInfo] = useState<Stripe.Price.Recurring | null | undefined>();
  const [addOnItems, setAddOnItems] = useState<{ price: string, quantity: number }[]>();
  const [planPrice, setPlanPrice] = useState(0);
  const [addOnPrice, setAddOnPrice] = useState(0);
  const [subscriptionDetails, setSubscriptionDetails] = useState<ReactivationDetails>({
    planExists: false,
    planType: "",
    planCost: "",
    nextPaymentDate: "",
    paymentCycle: "",
    userAddonExists: false,
    userAddonCount: "",
    userAddonAmountCharged: "",
    licenseAddonExists: false,
    licenseAddonCount: "",
    licenseAddonAmountCharged: "",
    amountCharged: "",
  });
  const history = useHistory();

  const defaultAddOnItems = [
    {
      price: products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id,
      quantity: 0
    },
    {
      price: products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id,
      quantity: 0
    },
    {
      price: 'price_1OhEDKHoJbt1xrgTfMh1VaRo',
      quantity: 0
    }
  ];

  useEffect(() => {
    const calculateTotal = () => {
      let planCost = 0;
      let addOnCost = 0;

      if (isReactivatingPlan && stripePlanSubscription && stripePlanSubscription?.items.data) {
        for (const item of stripePlanSubscription?.items.data) {
          if (item.plan.amount && item.quantity) {
            planCost += item.plan.amount * item.quantity / 100;
          }
        }
      }

      if (isReactivatingAddOn && stripeAddOnSubscription && stripeAddOnSubscription?.items.data) {
        for (const item of stripeAddOnSubscription?.items.data) {
          if (item.plan.amount && item.quantity) {
            addOnCost += item.plan.amount * item.quantity / 100;
          }
        }
      }

      setSubscriptionDetails((subscriptionDetails) => ({
        ...subscriptionDetails, ...{
          amountCharged: (planCost + addOnCost).toFixed(2),
        }
      }));

      setPlanPrice(planCost);
      setAddOnPrice(addOnCost);
    }

    calculateTotal();
  }, [isReactivatingAddOn, isReactivatingPlan, stripeAddOnSubscription, stripePlanSubscription]);

  useEffect(() => {
    const fetchOrgSub = async () => {
      if (organization.id) {
        const orgSub = await OrganizationSubscriptionService.getByOrganizationId(organization.id);

        if (orgSub && orgSub.length > 0 && orgSub[0]) {
          setOrganizationSubscription(orgSub[0]);

          if (orgSub[0].stripePlanSubscription) {
            const stripePlanSub = await stripe2.subscriptions.retrieve(orgSub[0].stripePlanSubscription);
            setStripePlanSubscription(stripePlanSub);
          }

          if (orgSub[0].stripeAddOnSubscription) {
            const stripeAddOnSub = await stripe2.subscriptions.retrieve(orgSub[0].stripeAddOnSubscription);
            setStripeAddOnSubscription(stripeAddOnSub);

            if (stripeAddOnSub.items.data) {
              const addOnSubItems: { price: string, quantity: number }[] = [];

              stripeAddOnSub.items.data.forEach((item) => {
                const itemToAdd = {
                  price: item.price.id,
                  quantity: item.quantity || 0,
                };

                addOnSubItems.push(itemToAdd);
              })

              setAddOnItems(addOnSubItems);
            }
          }
        }
      }
    }

    fetchOrgSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stripePlanSubscription && isReactivatingPlan) {
      const temp = stripePlanSubscription.items.data.find((item) => item?.plan?.product === PlanId.Business)?.price?.recurring;
      setPlanPriceInfo(temp);
    }

    if (stripeAddOnSubscription && isReactivatingAddOn) {
      const temp = stripeAddOnSubscription.items.data.find((item) => item?.plan?.product === AddOnId.User.toString())?.price?.recurring;
      setAddOnPriceInfo(temp);
    }
  }, [stripePlanSubscription, stripeAddOnSubscription, isReactivatingPlan, isReactivatingAddOn]);

  const confirmIntent = async (clientSecret: string, customer: Stripe.Customer) => {
    setIsLoading(true);
    if (stripe && elements) {
      // Use the clientSecret and Elements instance to confirm the setup
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}${returnUrl}`,
        },
        redirect: "if_required"
      });
      console.error(error);

      if (error) {
        console.error(error);
        setPaymentFailedBanner(true);
      }
      else {
        // Update customer to use the new payment method as their default
        await stripe2.customers.update(
          customer.id,
          {
            invoice_settings: {
              default_payment_method: setupIntent.payment_method?.toString(),
            },
          }
        );

        if (isReactivatingPlan) {
          // Create Plan Subscription
          await stripe2.subscriptions.create({
            customer: customer.id,
            items: [
              {
                price: `${stripePlanSubscription ? stripePlanSubscription.items.data[0].price.id : products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval === "month").id}`,
              }
            ],
            payment_behavior: 'error_if_incomplete',
            payment_settings: { save_default_payment_method: 'on_subscription' },
            expand: ['latest_invoice.payment_intent'],
          }).then(async (data) => {
            // We separate subscriptions by plan & add-ons. We need to keep track of which subscription is which.
            // Therefore, we are going to save the ID of the subscriptions in their respective property.

            if (!organizationSubscription) {
              return;
            }

            organization.showDeactivatedAccountPlanModal = false;
            organizationSubscription.stripePlanSubscription = data.id;
            await OrganizationSubscriptionService.save(organizationSubscription);

            let paymentCycle = data.items.data.find((item) => item.plan.product === PlanId.Business)?.plan.interval;

            subscriptionDetails.planExists = true;
            subscriptionDetails.planType = "Business";
            subscriptionDetails.planCost = `$${planPrice.toFixed(2)}`;
            subscriptionDetails.nextPaymentDate = calculateNextPayment(planPriceInfo);

            if (paymentCycle) {
              if (paymentCycle === "year") {
                subscriptionDetails.paymentCycle = "Yearly"
              } else {
                subscriptionDetails.paymentCycle = "Monthly";
              }
            }
          }).catch((err: any) => {
            console.error(err);
          })
        }

        if (isReactivatingAddOn) {
          // Create Add-On Subscription
          await stripe2.subscriptions.create({
            customer: customer.id,
            items: addOnItems || defaultAddOnItems,
            payment_behavior: 'error_if_incomplete',
            payment_settings: { save_default_payment_method: 'on_subscription' },
            expand: ['latest_invoice.payment_intent'],
          }).then(async (data) => {
            if (!organizationSubscription) {
              return;
            }

            organization.showDeactivatedAccountAddOnModal = false;
            organizationSubscription.stripeAddOnSubscription = data.id;
            await OrganizationSubscriptionService.save(organizationSubscription);

            const userAddOn = data.items.data.find((item) => item.price.product === AddOnId.User.toString());
            if (userAddOn && userAddOn?.quantity && userAddOn?.quantity > 0) {
              subscriptionDetails.userAddonExists = true;
              subscriptionDetails.userAddonCount = `${userAddOn?.quantity || 0}`;
              subscriptionDetails.userAddonAmountCharged = `$${userAddOn.plan.amount && userAddOn.quantity && ((userAddOn.plan.amount * userAddOn.quantity / 100).toFixed(2) ?? 0)}`;
            }

            const licenseAddOn = data.items.data.find((item) => item.price.product === AddOnId.License.toString());
            if (licenseAddOn && licenseAddOn?.quantity && licenseAddOn?.quantity > 0) {
              subscriptionDetails.licenseAddonExists = true;
              subscriptionDetails.licenseAddonCount = `${licenseAddOn?.quantity || 0}`;
              subscriptionDetails.licenseAddonAmountCharged = `$${licenseAddOn.plan.amount && licenseAddOn.quantity && ((licenseAddOn.plan.amount * licenseAddOn.quantity / 100).toFixed(2) ?? 0)}`;
            }
          })
            .catch((err: any) => {
              console.error(err);
            })
        }

        setSubscriptionDetails(subscriptionDetails);

        organization.status = OrganizationStatus.Active;
        await OrganizationService.save(organization);
      }
    }
    setIsLoading(false);
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error(submitError)
      return;
    }

    setIsLoading(true);

    // Retrieve customer from stripe by Email
    let customer: any = await stripe2.customers.list({
      limit: 1,
      email: state.user?.email
    })

    // Check if a customer was found
    if (customer.data.length === 0) {
      customer = await stripe2.customers.create({
        email: state.user?.email,
        name: `${state.user?.firstName} ${state.user?.lastName}`,
      })
    }

    await stripe2.setupIntents.create({
      customer: customer.data[0].id,
      automatic_payment_methods: {
        enabled: true,
      },
    }).then(async (intent: any) => {
      await confirmIntent(intent.client_secret, customer.data[0]);
      if (state.user && state.user?.email) {
        console.log(subscriptionDetails)
        FunctionService.sendSubscriptionReactivationEmail(state.user.email, subscriptionDetails);
      }
      history.replace(returnUrl);
    });

    setIsLoading(false);
  }

  function calculateNextPayment(priceInfo: any) {
    const today = new Date();
    const nextBillingDay = new Date(today);
    if (priceInfo) {
      if (priceInfo.interval === 'month') {
        nextBillingDay.setMonth(today.getMonth() + priceInfo.interval_count);
      } else if (priceInfo.interval === 'year') {
        nextBillingDay.setFullYear(today.getFullYear() + priceInfo.interval_count);
      }
      else {
        nextBillingDay.setFullYear(today.getFullYear() + 1);
      }

      // This part adjusts the last day of the month if it doesn't exist in the next month
      if (priceInfo.interval === 'month') {
        while (nextBillingDay.getMonth() !== (today.getMonth() + priceInfo.interval_count) % 12) {
          nextBillingDay.setDate(nextBillingDay.getDate() - 1);
        }
      }
    }
    return priceInfo ? `${nextBillingDay.toLocaleString('default', { month: 'short' })} ${nextBillingDay.getDate()}, ${nextBillingDay.getFullYear()}` : '';
  }

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <Loader isVisible={isLoading} />
      <div className={`${COMPONENT_CLASS}__container pt-20 px-8 md:px-24`} style={{ margin: 0 }}>
        <div style={{ textAlign: "center" }}>
          <h1>Update Your Payment Details</h1>
          <p>Your Symmio subscription has been cancelled and your account has been deactivated due to a failed payment. Please update your payment details to reactivate your account.</p>
        </div>
        {paymentFailedBanner && (
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <WarningItem
              iconColor="#E7514F"
              onClose={() => { setPaymentFailedBanner(false); }}
              backgroundColor="#FFE9E5"
              title="Payment Failed"
              text="Make sure you’re using the email address you were invited to Symmio with. If you haven’t received an invitation yet, contact your administrator to request access to Symmio."
            />
          </div>
        )}
        <div className={`${COMPONENT_CLASS}__summary`}>
          <div className={`${COMPONENT_CLASS}_summary_line`}>
            <div style={{ fontSize: '20px' }}>
              {isReactivatingPlan && isReactivatingAddOn && "Symmio Business + Add-ons"}
              {isReactivatingPlan && !isReactivatingAddOn && "Symmio Business"}
              {!isReactivatingPlan && isReactivatingAddOn && "Add-ons"}
            </div>
          </div>
          {isReactivatingPlan && stripePlanSubscription && stripePlanSubscription?.items.data &&
            stripePlanSubscription.items.data.map((item) => {
              return (
                <div key={item.id} className={`${COMPONENT_CLASS}_summary_line`}>
                  <div style={{ fontWeight: 'bold' }}>{products && products.find((productItem) => productItem.product.id === item.plan.product)?.product?.data?.name}</div>
                  <div>${item.plan.amount && item.quantity && ((item.plan.amount * item.quantity / 100).toFixed(2) ?? '...')}</div>
                </div>
              )
            })
          }
          {isReactivatingAddOn && stripeAddOnSubscription && stripeAddOnSubscription?.items.data &&
            stripeAddOnSubscription.items.data.map((item) => {
              if (item.quantity && item.quantity > 0) {
                return (
                  <div key={item.id} className={`${COMPONENT_CLASS}_summary_line`}>
                    <div style={{ fontWeight: 'bold' }}>{item.quantity} {products && products.find((productItem) => productItem.product.id === item.plan.product)?.product?.data?.name}</div>
                    <div>${item.plan.amount && item.quantity && ((item.plan.amount * item.quantity / 100).toFixed(2) ?? '...')}</div>
                  </div>
                )
              }
              else {
                return <></>;
              }
            })
          }
          <hr className={`${COMPONENT_CLASS}_divider`} />
          <div className={`${COMPONENT_CLASS}_summary_line`}>
            <div style={{ fontWeight: 'bold' }}>Today you owe</div>
            <div>${(planPrice + addOnPrice).toFixed(2)}</div>
          </div>
          <hr className={`${COMPONENT_CLASS}_divider`} />
          {isReactivatingPlan &&
            <div className={`${COMPONENT_CLASS}_summary_line`}>
              <div style={{ fontWeight: 'bold' }}>Next Plan Payment on {planPriceInfo ? calculateNextPayment(planPriceInfo) : "..."}</div>
              <div>${planPrice.toFixed(2)}</div>
            </div>
          }
          {isReactivatingAddOn &&
            <div className={`${COMPONENT_CLASS}_summary_line`}>
              <div style={{ fontWeight: 'bold' }}>Next Add-on Payment on {addOnPriceInfo ? calculateNextPayment(addOnPriceInfo) : "..."}</div>
              <div>${addOnPrice.toFixed(2)}</div>
            </div>
          }
          <hr className={`${COMPONENT_CLASS}_divider`} />
          <div className={`${COMPONENT_CLASS}_summary_line`}>
            Your subscription will renew automatically on {(isReactivatingPlan && planPriceInfo) ? calculateNextPayment(planPriceInfo) : (isReactivatingAddOn && addOnPriceInfo ? calculateNextPayment(addOnPriceInfo) : "...")}. You can cancel or change your subscription in your account settings on the Symmio Portal.
          </div>

        </div>
        <form onSubmit={handleSubmit} className={`${COMPONENT_CLASS}__form`}>
          <PaymentElement />
        </form>
        <div style={{ fontSize: '12px', fontWeight: '300' }}>
          By selecting ‘Pay and Continue Subscription’, you agree that your subscription starts immediately and you allow Symmio to charge your card for this payment and future payments in accordance with Symmio’s terms and conditions. You can cancel or change your subscription at any time in your account settings on the Symmio Portal, effective at the end of your billing cycle.
          <a
            style={{ textDecoration: 'underline' }}
            type="button"
            href="https://www.functionalmovement.com/terms"
            target="_blank" rel="noreferrer">
            terms and conditions
          </a>. You can cancel your subscription at any time, effective at the end of your billing cycle.
        </div>
      </div>
      <BaseCardLayoutActions
        backButton={true}
        submitButton={true}
        onSubmit={handleSubmit}
        submitButtonText="Pay and Continue Subscription"
        onBack={onBackButtonClick}
        submitDisabled={isLoading}
        backDisabled={isLoading}
      />
    </div >
  );
}

export default ReactivateSubscriptionForm;

