import { Group } from "../models/interfaces/group";
import Hierarchy from "../models/interfaces/hierarchy";
import { ListOptions } from "../models/interfaces/list-options";
import { User } from "../models/interfaces/user";
import { UserClaims } from "../models/interfaces/user-claims";
import UserRoles from "../models/user-roles";
import GroupService from "./services/group-service";
import OrganizationService from "./services/organization-service";
import UserService from "./services/user-service";

export default class UserUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static async getOrganizationUsersOptions(orgId: string): Promise<ListOptions[]> {
        const users = await UserService.getAllByOrganizationId(orgId);

        if (!users || users.length === 0) {
            return [];
        }

        const options: ListOptions[] = [];

        users.forEach((u: User) => {
            const option = this.userToListOption(u);
            if (option !== null && option !== undefined) {
                options.push(option);
            }
        });

        return options;
    }

    /**
     * Get list options for all super admin users. Label is first and/or last
     * name or email. Value is id.
     * @returns {Promise<ListOptions[]>} A promise for super admin list options
     */
    public static async getSuperAdminDropdownOptions(): Promise<ListOptions[]> {
        const superAdmins = await this.getSuperAdmins();

        if (!superAdmins || superAdmins.length === 0) {
            return [];
        }

        const options: ListOptions[] = [];

        superAdmins.forEach((sa) => {
            const option = this.userToListOption(sa);
            if (option !== null && option !== undefined) {
                options.push(option);
            }
        })

        return options;
    }

    /**
     * Get all super admin users
     * @returns {Promise<User[]>} a promise for all super admin users
     */
    public static async getSuperAdmins(): Promise<User[]> {
        return UserService.getBy([{
            field: 'isSuperAdmin',
            operator: '==',
            value: true,
        }]);
    }

    /**
     * Determines if a given user is a developer based on the domain of their
     * email address.
     *
     * @param {User} u The user to check
     * @returns {boolean} True if the user is a developer, false if they are
     * not.
     */
    public static isDeveloperUser(u: User): boolean {
        if (u.email) {
            const atIndex = u.email.indexOf('@');
            const domain = u.email.substring(atIndex + 1);
            return domain === 'dstrat.com';
        }

        return false;
    }

    /**
     * User is a super admin
     */
    public static isSuperAdmin(userClaims: UserClaims | null | undefined): boolean {
        if (userClaims == null) {
            return false;
        }
        return userClaims.superAdmin === true;
    }

    /**
     * User is an account holder
     */
    public static isAccountHolder(user: User | UserClaims | null | undefined): boolean {
        return UserUtil.belongsToRole(user, UserRoles.ACCOUNT_HOLDER_ID);
    }

    /**
     * User is an account holder
     */
    public static isClient(user: User | UserClaims | null | undefined): boolean {
        return UserUtil.belongsToRole(user, UserRoles.CLIENT_ID);
    }

    /**
     * User is an admin
     */
    public static isAdmin(user: User | UserClaims | null | undefined): boolean {
        return UserUtil.belongsToRole(user, UserRoles.ADMIN_ID);
    }

    /**
     * User is a manager (and not an admin)
     */
    public static isManager(user: User | UserClaims | null | undefined): boolean {
        return !UserUtil.belongsToRole(user, UserRoles.ADMIN_ID)
            && UserUtil.belongsToRole(user, UserRoles.GROUP_MANAGER_ID);
    }

    /**
     * User is a manager or team member (and not an admin)
    */
    public static isManagerOrTeamMember(user: User | UserClaims | null | undefined): boolean {
        if (UserUtil.belongsToRole(user, UserRoles.ADMIN_ID)) {
            return false;
        }
        return UserUtil.belongsToRole(user, UserRoles.GROUP_MANAGER_ID)
            || UserUtil.belongsToRole(user, UserRoles.TEAM_MEMBER_ID);
    }

    /**
     * User is a team member (and not an admin nor manager)
     */
    public static isTeamMember(user: User | UserClaims | null | undefined): boolean {
        return !UserUtil.belongsToRole(user, UserRoles.ADMIN_ID)
            && !UserUtil.belongsToRole(user, UserRoles.GROUP_MANAGER_ID)
            && UserUtil.belongsToRole(user, UserRoles.TEAM_MEMBER_ID);

    }

    /**
     * Gets a count of all users in organizations administrated by an account holder.
     * 
     * @param {string | null | undefined} userId The account holder user.
     * @returns {promise} A promise for the number of users under the account holder
     */
    public static async getAccountHolderUserCount(userId: string | null | undefined, organizationId: string | null | undefined): Promise<number> {
        if (!userId || !organizationId) {
            return 0;
        }
        const holderOrgs = await OrganizationService.getBy([{
            field: 'accountHolderId',
            operator: '==',
            value: userId,
        }]);

        let currentUsers = 0;
        const promises: Promise<number>[] = [];

        holderOrgs.forEach(async (o) => {
            promises.push(this.getOrganizationUserCount(o.id));
        });

        await Promise.all(promises).then((r) => {
            r.forEach((c) => currentUsers += c);
        });

        return currentUsers;
    };

    public static async getOrganizationUserCount(organizationId: string | null | undefined): Promise<number> {
        if (!organizationId) {
            return 0;
        }
        const users = await UserService.getBy([{
            field: 'organizationId',
            operator: '==',
            value: organizationId,
        }]);

        return users.length;
    };

    /**
     * Gets a count of all organizations administrated by an account holder.
     * 
     * @param {User | null | undefined} user The account holder user.
     * @returns {promise} A promise for the number of users under the account holder
     */
    public static async getAccountHolderOrgCount(user: User | null | undefined): Promise<number> {
        if (!user) {
            return 0;
        }
        const holderOrgs = await OrganizationService.getBy([{
            field: 'accountHolderId',
            operator: '==',
            value: user?.id,
        }]);
        return holderOrgs.length;
    };

    /**
     * User is a manager (and not an admin)
     */
    public static getDefaultManageGroupId(user: User | null | undefined): string | null {
        if (user == null || !UserUtil.isManagerOrTeamMember(user)) {
            return null;
        }

        const hasManagedGroups = user?.managedGroupIds != null && user.managedGroupIds.length >= 1;
        if (!hasManagedGroups) {
            return null;
        }

        // user manages their group
        if (user.groupId != null && user.managedGroupIds!.indexOf(user.groupId) >= 0) {
            return user.groupId;
        }

        // first manage group
        return user.managedGroupIds![0];
    }

    /**
     * User has no role
     */
    public static hasNoRole(user: User | UserClaims | null | undefined): boolean {
        return user?.roles == null || user.roles.length === 0;
    }

    public static belongsToRole(
        user: User | UserClaims | null | undefined,
        roleId?: string
    ): boolean {
        if (user?.roles == null || user.roles.length === 0 || roleId == null) {
            return false;
        }
        return user.roles.indexOf(roleId) >= 0;
    }

    /**
     * Filters the list by groups that you manage
     */
    public static getManagedGroups(allGroups: Group[], user: User | null): Group[] {
        const isAdmin = UserUtil.belongsToRole(user, "admin");

        if (isAdmin) {
            return allGroups;
        }

        return allGroups.filter((group) => UserUtil.canManageGroup(user, group));
    }

    /**
     * Handled roles: ADMIN_ID and UserRoles.GROUP_MANAGER_ID
     */
    public static canManageGroup(
        user: User | null | undefined,
        group?: Hierarchy,
        allowEmptyGroupId: boolean = true
    ): boolean {
        if (user?.roles == null || user.roles.length === 0 || group?.id == null) {
            return false;
        }

        if (allowEmptyGroupId && group.id === "") {
            return true;
        }

        const isAdmin = this.belongsToRole(user, UserRoles.ADMIN_ID);
        if (isAdmin) {
            return true;
        }

        const isAccountHolder = this.isAccountHolder(user);
        if (isAccountHolder) {
            return true;
        }

        const isManager = this.isManagerOrTeamMember(user);
        if (!isManager) {
            return false;
        }

        if (user?.managedGroupIds == null || user.managedGroupIds.length === 0) {
            return false;
        }

        if (user.managedGroupIds.indexOf(group.id.toString()) >= 0) {
            return true;
        }
        if (group.parentIds != null && group.parentIds.length >= 0) {
            for (let i = 0; i < group.parentIds.length; i++) {
                if (user.managedGroupIds.indexOf(group.parentIds[i].toString()) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    public static async getAlgoliaAccountHolderOrganizationFilter(
        user: User | null | undefined,
    ): Promise<string> {
        if (!user || !user.organizationId) {
            return '';
        }
        const holderOrg = await OrganizationService.get(user.organizationId);

        let filterString = `id:${user.organizationId}`;

        if (!holderOrg || !holderOrg.subOrganizationIds || holderOrg.subOrganizationIds.length === 0) {
            return filterString;
        } else {
            holderOrg.subOrganizationIds.forEach((id) => {
                filterString = filterString + ` OR id:${id}`
            });
            return filterString;
        }
    };

    public static async getAlgoliaUserOrganizationAndGroupFilter(
        user: User | null | undefined,
        organizationId?: string
    ): Promise<string> {
        if (user == null) {
            return `organizationId:"NO_MATCH"`;
        }
        if (organizationId == null) {
            if (user.isSuperAdmin) {
                return `NOT roles:client`;
            }
            if (this.isAccountHolder(user)) {
                // get all the account holder's organizations
                const holderOrgs = await OrganizationService.getBy([{
                    field: 'accountHolderId',
                    operator: '==',
                    value: user.id
                }]);
                // if the account holder has no organizations, we don't want the
                // search to return anything
                if (holderOrgs.length === 0) {
                    return `organizationId:"NO_MATCH"`;
                }
                // otherwise, build a string to search on all of the account
                // holder's organization ids
                let filterString = `organizationId:${user.organizationId}`;
                holderOrgs.forEach((o) => {
                    filterString = filterString + ` OR organizationId:${o.id}`
                })
                return `(${filterString}) AND NOT roles:client`;
            }
            return `organizationId:"NO_MATCH"`;
        }

        if (UserUtil.belongsToRole(user, UserRoles.ADMIN_ID) || this.isAccountHolder(user)) {
            return `(organizationId:${organizationId}) AND NOT roles:client`;
        }

        if (UserUtil.belongsToRole(user, UserRoles.GROUP_MANAGER_ID)) {
            const managedGroupIds = await UserUtil.getHierarchicalGroupIds(user?.managedGroupIds);
            if (managedGroupIds.length === 0) {
                return `(organizationId:"${organizationId}" AND groupId:"NO_MATCH") AND NOT roles:client`;
            }

            // Generates: `groupId:"1" OR groupId:"2" OR groupId:"3"`
            const groupFilter = `groupId:"` + managedGroupIds.join(`" OR groupId:"`) + `"`;
            return `organizationId:"${organizationId}" AND (${groupFilter}) AND NOT roles:client`;
        }

        return `organizationId:"NO_MATCH"`;
    }

    public static async getAlgoliaUserOrganizationAndGroupFilterByClient(
        user: User | null | undefined,
        organizationId?: string
    ): Promise<string> {
        if (user == null) {
            return `organizationId:"NO_MATCH"`;
        }
        if (organizationId == null) {
            if (user.isSuperAdmin) {
                return `roles:client`;
            }
            if (this.isAccountHolder(user)) {
                // get all the account holder's organizations
                const holderOrgs = await OrganizationService.getBy([{
                    field: 'accountHolderId',
                    operator: '==',
                    value: user.id
                }]);
                // if the account holder has no organizations, we don't want the
                // search to return anything
                if (holderOrgs.length === 0) {
                    return `organizationId:"NO_MATCH"`;
                }
                // otherwise, build a string to search on all of the account
                // holder's organization ids
                let filterString = `organizationId:${user.organizationId}`;
                holderOrgs.forEach((o) => {
                    filterString = filterString + ` OR organizationId:${o.id}`
                })
                return `(${filterString}) AND roles:client`;
            }
            return `organizationId:"NO_MATCH"`;
        }

        if (UserUtil.belongsToRole(user, UserRoles.ADMIN_ID) || this.isAccountHolder(user)) {
            return `(organizationId:${organizationId}) AND roles:client`;
        }

        if (UserUtil.belongsToRole(user, UserRoles.GROUP_MANAGER_ID)) {
            const managedGroupIds = await UserUtil.getHierarchicalGroupIds(user?.managedGroupIds);
            if (managedGroupIds.length === 0) {
                return `(organizationId:"${organizationId}" AND groupId:"NO_MATCH") AND roles:client`;
            }

            // Generates: `groupId:"1" OR groupId:"2" OR groupId:"3"`
            const groupFilter = `groupId:"` + managedGroupIds.join(`" OR groupId:"`) + `"`;
            return `organizationId:"${organizationId}" AND (${groupFilter}) AND roles:client`;
        }

        return `organizationId:"NO_MATCH"`;
    }

    /**
     * Returns a list of all group IDs that are managed based on the parent group IDs, including the specified parent group IDs
     * @param parentGroupIds Parent level group IDs
     * @returns List of group IDs
     */
    public static async getHierarchicalGroupIds(parentGroupIds?: string[]): Promise<string[]> {
        const results: string[] = [];

        if (
            parentGroupIds == null ||
            !Array.isArray(parentGroupIds) ||
            parentGroupIds.length === 0
        ) {
            return results;
        }

        for (let i = 0; i < parentGroupIds.length; i++) {
            // skip if already in the collection
            const groupId = parentGroupIds[i];
            if (results.find((r) => r === groupId) != null) {
                continue;
            }

            // get child groups
            const parentGroups = await GroupService.getBy([
                {
                    field: "parentIds",
                    operator: "array-contains",
                    value: groupId,
                },
            ]);
            parentGroups.forEach((parentGroup) => {
                if (results.find((r) => r === parentGroup.id) == null) {
                    results.push(parentGroup.id);
                }
            });

            // get specific group
            if (results.find((r) => r === groupId) == null) {
                results.push(groupId);
            }
        }

        return results;
    }

    // #endregion Public Methods

    private static userToListOption = (user: User) => {
        if (user.id) {
            let label = '';

            // if the super admin has no first or last name, use email
            if (!user.firstName && !user.lastName && user.email) {
                label = user.email;
            }

            // if the super admin has a first or last name, use one or both
            if (user.firstName || user.lastName) {
                label = `${user.firstName ? user.firstName.concat(' ') : ''}${user.lastName ?? ''}`
            }

            if (label) {
                const option: ListOptions = {
                    value: user.id,
                    label,
                }
                return option;
            }

            return null;
        }
    };
}
