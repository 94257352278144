import { ReportWellnessData } from "../../models/interfaces/reports/report-wellness-data";
import DonutChart from "../charts/donut-chart";
import ReportWellnessHeader from "./report-wellness-header";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as PhysicalActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { ReactComponent as RoadToWellnessIcon } from "../../assets/icons/solid/road-to-wellness.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as ExclamationkIcon } from "../../assets/icons/solid/exclamation.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/symmio-icons/icon_logo.svg";
import { ReactComponent as ImprovementPlanIcon } from "../../assets/icons/solid/icon_checklist.svg";
import { ReactComponent as ProgressTrackingIcon } from "../../assets/icons/solid/icon_graph.svg";
import { ReactComponent as DailyTipsIcon } from "../../assets/icons/solid/icon_lightbulb.svg";
import { ReactComponent as CrossRedIcon } from "../../assets/icons/solid/icon_white-cross-red-background.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/outline/icon_clock.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/outline/icon_calendar.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/general-icons/close.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/solid/check.svg";
import { ReactComponent as ChartArrowUp } from "../../assets/icons/solid/statistics-arrow-up.svg";
import { ReactComponent as ChartArrowDown } from "../../assets/icons/solid/statistics-arrow-down.svg";
import { FocusArea } from "../../models/enumerations/focus-area";
import PillChart from "../charts/pill-chart/pill-chart";
import { ReportAnswer } from "../../models/enumerations/report-answer";
import { InjuryHistoryResponse } from "../../models/interfaces/reports/injury-history-response";
import { HighRiskType } from "../../models/enumerations/high-risk-type";
import { MovementFocusArea } from "../../models/enumerations/movement-focus-area";
import qrCodeImg from "../../assets/images/qr-code-mobile-stores.png";
import qrCodeBehavioralHealth from "../../assets/images/symmio_qr_blog_behaviorial_health.png";
import qrCodeBodyComposition from "../../assets/images/symmio_qr_blog_body_composition.png";
import qrCodeBreathingQuality from "../../assets/images/symmio_qr_blog_breathing_quality.png";
import qrCodeInjuryHistory from "../../assets/images/symmio_qr_blog_injury_history.png";
import qrCodeMovementHealth from "../../assets/images/symmio_qr_blog_movement_health.png";
import qrCodeNutritionalAwareness from "../../assets/images/symmio_qr_blog_nutritional_awareness.png";
import qrCodePhysicalActivity from "../../assets/images/symmio_qr_blog_physical_activity.png";
import qrCodeSleepQuality from "../../assets/images/symmio_qr_blog_sleep_quality.png";
import qrHighRisk from "../../assets/images/symmio_qr_app_high_risk.jpg";
import qrWebMySymmio from "../../assets/images/symmio_qr_web_my_symmio.jpg";
import googlePlayImg from "../../assets/images/google-play-button.png";
import appStoreImg from "../../assets/images/app-store-button.png";
import logoStackedImg from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import devicesImg from "../../assets/images/devices.png";

// -----------------------------------------------------------------------------------------
// #region Images for Daily Corrective Exercises
// -----------------------------------------------------------------------------------------

//Toe Touch
import assistedLegLoweringImg from "../../assets/images/focus-area-exercises/assisted-leg-lowering.png";
import singleLegBridgeImg from "../../assets/images/focus-area-exercises/single-leg-bridge.png";
import hipFlexorStretchImg from "../../assets/images/focus-area-exercises/hip-flexor-stretch.png";
import toeTouchProgressionImg from "../../assets/images/focus-area-exercises/toe-touch-progression-toe-touch.png";

// Shoulder Mobility
import ribGrabsImg from "../../assets/images/focus-area-exercises/rib-grabs.png";
import lumbarLockedRotationImg from "../../assets/images/focus-area-exercises/lumbar-locked-rotation.png";
import halfKneelingRotatonWideImg from "../../assets/images/focus-area-exercises/half-kneeling-rotaton-wide.png";
import singleArmToeTouchProgressionShoulderMobilityImg from "../../assets/images/focus-area-exercises/toe-touch-progression-single-arm-shoulder.png";

// Rotation
import brettzel2ElbowsToFloorImg from "../../assets/images/focus-area-exercises/brettzel-2-elbows-to-floor.png";
import halfKneelingRotationImg from "../../assets/images/focus-area-exercises/half-kneeling-rotation.png";
import singleArmToeTouchProgressionRotationImg from "../../assets/images/focus-area-exercises/toe-touch-progression-single-arm-rotation.png";

// Squat
import ankleMobilitySquatImg from "../../assets/images/focus-area-exercises/ankle-mobility-squat.png";
import brettzel3Img from "../../assets/images/focus-area-exercises/brettzel-3.png";
import plankShoulderTapsImg from "../../assets/images/focus-area-exercises/plank-shoulder-taps.png";
import toeTouchProgressionSquatImg from "../../assets/images/focus-area-exercises/toe-touch-progression-squat.png";

// Balance
import ankleMobilityBalanceImg from "../../assets/images/focus-area-exercises/ankle-mobility-balance.png";
import balanceWithRotationImg from "../../assets/images/focus-area-exercises/balance-with-rotation.png";
import straightLegBridgeImg from "../../assets/images/focus-area-exercises/straight-leg-bridge.png";
import splitStanceToeTouchProgressionImg from "../../assets/images/focus-area-exercises/split-stance-toe-touch-progression.png";

// Pain
import figure4LowerBodyImg from "../../assets/images/focus-area-exercises/figure-4-lower-body.png";
import figure4UpperBodyImg from "../../assets/images/focus-area-exercises/figure-4-upper-body.png";
import quadrupedRockImg from "../../assets/images/focus-area-exercises/quadruped-rock.png";
import catCamelImg from "../../assets/images/focus-area-exercises/cat-camel.png";

// Shoulder Clearing
import tallKneelingTurnsImg from "../../assets/images/focus-area-exercises/tall-kneeling-turns.png";

// Spine Clearing
import crocodileBreathingImg from "../../assets/images/focus-area-exercises/crocodie-breathing.png";
import backBreathingImg from "../../assets/images/focus-area-exercises/back-breathing.png";

// Road to Wellness
import lateralLungesImg from "../../assets/images/focus-area-exercises/lateral-lunges.png";
import bearCrawlingImg from "../../assets/images/focus-area-exercises/bear-crawling.png";
import plankWalkoutImg from "../../assets/images/focus-area-exercises/plank-walkout.png";
import splitSquatImg from "../../assets/images/focus-area-exercises/split-squat.png";
import legLoweringImg from "../../assets/images/focus-area-exercises/leg-lowering.png";

// #endregion Images for Daily Corrective Exercises

import LineChartJS from "../charts/line-chart-js";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportWellnessProps {
    data: ReportWellnessData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportWellness: React.FC<ReportWellnessProps> = (props: ReportWellnessProps) => {
    const { data } = props;
    const assessmentScores = [
        { percentage: data.behavioralHealth?.percentage ?? 0, title: "Behavioral Health", avg: data.behavioralHealth?.average },
        { percentage: data.movementHealth?.percentage ?? 0, title: "Movement Health", avg: data.movementHealth?.average },
        { percentage: data.injuryHistory?.percentage ?? 0, title: "Injury History", avg: data.injuryHistory?.average },
        { percentage: data.sleepWellness?.percentage ?? 0, title: "Sleep Wellness", avg: data.sleepWellness?.average },
        { percentage: data.nutritionalAwareness?.percentage ?? 0, title: "Nutritional Awareness", avg: data.nutritionalAwareness?.average },
        { percentage: data.bodyComposition?.percentage ?? 0, title: "Body Composition", avg: data.bodyComposition?.average },
        { percentage: data.breathingQuality?.percentage ?? 0, title: "Breathing Quality", avg: data.breathingQuality?.average },
        { percentage: data.physicalActivity?.percentage ?? 0, title: "Physical Activity", avg: data.physicalActivity?.average },
    ]
    assessmentScores.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0))

    let wellnessScoreLabel: "LOW" | "FAIR" | "GOOD" | "EXCELLENT" = "LOW";
    let summary = "";

    if (data.wellnessScore && data.wellnessScore <= 69) {
        wellnessScoreLabel = "LOW";
        summary = "With a <strong>Low Wellness Score (69 and below)</strong>, your current health and wellness assessment highlights significant areas for improvement. However, this also presents a crucial opportunity to enhance your overall health and quality of life.";
    }
    else if (data.wellnessScore && data.wellnessScore > 69 && data.wellnessScore < 80) {
        wellnessScoreLabel = "FAIR";
        summary = "With a <strong>Fair Wellness Score (70-79%)</strong>, you have some room for improvement in your overall wellness. This score provides a valuable opportunity to identify and strengthen areas that can be enhanced.";
    }
    else if (data.wellnessScore && data.wellnessScore >= 80 && data.wellnessScore < 90) {
        wellnessScoreLabel = "GOOD";
        summary = "With a <strong>Good Wellness Score (80-89%)</strong>, you are doing well in maintaining your health! Your program is specifically designed to fine-tune crucial areas, ensuring your overall well-being continues to flourish.";
    }
    else if (data.wellnessScore && data.wellnessScore >= 90) {
        wellnessScoreLabel = "EXCELLENT";
        summary = "With an <strong>Excellent Wellness Score (90-100%)</strong>, you have demonstrated outstanding health and wellness, meeting the highest standards of functional health. Keep up the great work!";
    }

    const getAssessmentScoreCard = (title: string, percentage: number = 0, average: number = 0) => {
        let isPercentageHigherThanAvg = percentage > average || percentage === 100;
        if (title === "Movement Health" && data.movementPain) {
            isPercentageHigherThanAvg = false;
        }
        const colorClassName = isPercentageHigherThanAvg ? "green" : "red";

        return (
            <div className={`${COMPONENT_CLASS}__assessment-score-card`}>
                <div className="flex flex-row justify-between items-center">
                    <span className={`text-07 ${colorClassName}`}>{title}</span>
                    <span className="icon icon__16px">
                        {isPercentageHigherThanAvg
                            ?
                            <CheckmarkIcon className="checkmark-icon" />
                            :
                            <ExclamationkIcon className="exclamation-icon" />
                        }
                    </span>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <span className="font-bold text-base">{percentage}%</span>
                    <span className="text-07 font-light">Avg: {average}</span>
                </div>
            </div>
        );
    };

    const getReportDateRange = () => {
        const startDate = new Date();

        const endDate = new Date();
        if (data.currentMonth) {
            startDate.setMonth(startDate.getMonth() - 5)
            return `${startDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })} - ${endDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })}`
        }
        else {
            startDate.setMonth(startDate.getMonth() - 6)
            endDate.setMonth(endDate.getMonth() - 1)
            return `${startDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })} - ${endDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })}`
        }
    }

    const getFocusAreaData = (focusArea: string) => {
        let focusAreaData = {
            icon: <></>,
            title: "",
            description: "",
            symmioHelpsText: "",
            statisticsHeadline: "",
            statisticsText: "",
        }

        switch (focusArea) {
            case FocusArea.MOVEMENT_HEALTH:
                focusAreaData.icon = <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Movement Health";
                focusAreaData.description = "Movement health makes sure everything is running smoothly so you can do everyday things like reach, twist, bend, and balance - without pain and limitations.";
                focusAreaData.symmioHelpsText = "Symmio will give you a series of targeted corrective exercises to help improve areas of your movement health and overall Wellness Score, and to get you moving freely and comfortably.";
                focusAreaData.statisticsHeadline = "80%";
                focusAreaData.statisticsText = "of chronic conditions can be prevented with lifestyle changes.";
                break;
            case FocusArea.BEHAVIORAL_HEALTH:
                focusAreaData.icon = <BehavioralHealthIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Behavioral Health";
                focusAreaData.description = "Touching so many aspects of life, stress, anxiety, and depression can all negatively impact your health and wellness. Integrating care of your mental health can lead to an overall improvement in your health and wellbeing.";
                focusAreaData.symmioHelpsText = "Symmio will explore different strategies with you for improving your Behavioral Health and improving your overall Wellness Score.";
                focusAreaData.statisticsHeadline = "25%";
                focusAreaData.statisticsText = "Individuals who actively manage their mental health experience a 25% increase in overall quality of life.";
                break;
            case FocusArea.INJURY_HISTORY:
                focusAreaData.icon = <InjuryHistoryIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Injury History";
                focusAreaData.description = "Injuries happen to almost everyone at some point and though you may feel recovered, research suggests you still be at a higher risk. Understanding your level of risk is crucial to staying healthy.";
                focusAreaData.symmioHelpsText = "Symmio will educate you on how to manage past injuries, what to do when an injury does occurs and most importantly how to stay healthy and pain free.";
                focusAreaData.statisticsHeadline = "#1";
                focusAreaData.statisticsText = "Previous injuries are the top predictors of future ones; managing your risk can greatly improve your health and wellness.";
                break;
            case FocusArea.BREATHING:
                focusAreaData.icon = <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Breathing Quality";
                focusAreaData.description = "Breathing assessment makes sure your diaphragm and breathing mechanics function the way they should and that they don't interfere with your core function and the way you move and go about your day.";
                focusAreaData.symmioHelpsText = "Symmio will provide specific exercises to help retain your breathing to restore your mechanics which will positively impact your sleep, exercises and stress.";
                focusAreaData.statisticsHeadline = "50-80%";
                focusAreaData.statisticsText = "Adults have some level of dysfunctional breathing that is linked to anxiety and lower back pain.";
                break;
            case FocusArea.SLEEP:
                focusAreaData.icon = <SleepIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Sleep Wellness";
                focusAreaData.description = "Despite its relationship to a healthier and more fulfilling life, we often don't get enough quality sleep we need to go about our daily life.";
                focusAreaData.symmioHelpsText = "Symmio will provide useful tips / strategies to help improve the quality of sleep you get with a goal to change your day / nighttime behaviors that will result in longer, healthier sleep. ";
                focusAreaData.statisticsHeadline = "1/3";
                focusAreaData.statisticsText = "More than one-third of adults sleep less than seven hours per night, on average.";
                break;
            case FocusArea.PHYSICAL_ACTIVITY:
                focusAreaData.icon = <PhysicalActivityIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Physical Activity";
                focusAreaData.description = "Physical activity and exercise can have immediate and long-term health benefits. Most importantly, regular activity can improve your quality of life and read your risk of developing several life-threatening diseases.";
                focusAreaData.symmioHelpsText = "Symmio will provide education and useful tips / strategies around the importance of physical activity and guide you to reach the goal of 150 min of moderate intensity exercises. ";
                focusAreaData.statisticsHeadline = "4th Leading Risk Factor";
                focusAreaData.statisticsText = "Insufficient physical activity is the fourth leading risk factor for mortality globally, resulting in approximately 3.2 million deaths.";
                break;
            case FocusArea.BODY_COMPOSITION:
                focusAreaData.icon = <BodyCompIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Body Composition";
                focusAreaData.description = "Knowing your body composition can help assess and manage your overall health and wellness and help avoid metabolic issues or even dysfunction of potential health problems.";
                focusAreaData.symmioHelpsText = "Symmio will provide education and useful tips / strategies about how to safely and effectively improve your body composition through good nutrition and exercises for long-term health and wellness.";
                focusAreaData.statisticsHeadline = "1 in 8";
                focusAreaData.statisticsText = "People in the world are living with obesity causing over 5 million premature deaths.";
                break;
            case FocusArea.NUTRITION:
                focusAreaData.icon = <NutritionIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Nutritional Awareness";
                focusAreaData.description = "The quality of what we eat impacts our physical, mental and emotional well-being. Understanding this Dynamic more clearly can help improve our overall health and wellness.";
                focusAreaData.symmioHelpsText = "Symmio will explore different strategies with you for improving your nutritional awareness and arm you with the skills to make healthier decisions around your food.";
                focusAreaData.statisticsHeadline = "#1";
                focusAreaData.statisticsText = "Diet is the leading factor affecting health, linked to issues such as obesity, coronary heart disease, diabetes, and cholesterol problems.";
                break;
            case FocusArea.MOVEMENT_HEALTH_BREATHING:
                focusAreaData.icon = (
                    <div className="flex flex-row">
                        <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
                            <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                        </div>
                        <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex", marginLeft: "-0.3rem" }}>
                            <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                        </div>
                    </div>
                );
                focusAreaData.title = "Movement Health & Breathing";
                focusAreaData.description = "Movement and breathing quality are closely connected; breathing issues can lead to movement problems, and vice versa. Both of these are essential for performing everyday activities like reaching, twisting, bending, and balancing without pain or limitations.";
                focusAreaData.symmioHelpsText = "Symmio will give you a series of targeted corrective exercises to help improve movement and breathing, to get you moving freely and comfortably.";
                focusAreaData.statisticsHeadline = "80%";
                focusAreaData.statisticsText = "of chronic conditions can be prevented with lifestyle changes.";
                break;
            case FocusArea.ROAD_TO_WELLNESS:
                focusAreaData.icon = <RoadToWellnessIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = "Road to Wellness";
                focusAreaData.description = "Congratulations! You have passed the functional wellness screen, indicating good movement and breathing, regular exercise, low stress and anxiety, healthy body composition, good rest and recovery, no past injuries affecting you, and a good knowledge of nutrition!";
                focusAreaData.symmioHelpsText = "Symmio will now provide you with an opportunity to challenge your fitness levels with a 30-day road to wellness program designed to get you moving and develop baseline strength and endurance.";
                focusAreaData.statisticsHeadline = "10%";
                focusAreaData.statisticsText = "Only 10% of users achieve the Road to Wellness program. You should be proud of your achievement.";
                break;
        }

        return focusAreaData;
    };

    const getFocusAreaSection = (focusArea: string, showBorder: boolean, showPain: boolean = false) => {
        const focusAreaData = getFocusAreaData(focusArea);

        return (
            <div className={`flex flex-row gap-2 pt-5 ${showBorder && "border-gray-blue border-b-2 pb-5"}`}>
                {focusArea === FocusArea.MOVEMENT_HEALTH_BREATHING
                    ?
                    focusAreaData.icon
                    :
                    <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
                        {focusAreaData.icon}
                    </div>
                }

                <div className={`${showPain ? "pt-1" : "pt-2"}`}>
                    <div className="flex flex-col gap-2 mb-4">
                        <div className="flex flex-row justify-between items-center">
                            <span className="text-07 font-medium">{focusAreaData.title}</span>
                            <span>{getPainBadge(showPain)}</span>
                        </div>
                        <p className="font-light">{focusAreaData.description}</p>
                    </div>

                    <div className="flex flex-row justify-between gap-3">
                        <div className="w-1/2">
                            <div className="text-06 font-medium flex flex-row items-center mb-1 gap-1"><span className="icon icon__12px"><LogoIcon className="icon__12px" /></span>HOW SYMMIO HELPS</div>
                            <p>{focusAreaData.symmioHelpsText}</p>
                        </div>
                        <div className="w-1/2 px-3 py-3 border-2 rounded-xl border-gray-blue">
                            <div className="text-xs font-bold mb-2">{focusAreaData.statisticsHeadline}</div>
                            <p>{focusAreaData.statisticsText}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getCardNumber = (numberToShow: number, color: "red" | "blue") => {
        return (
            <div><span className={`${COMPONENT_CLASS}__card-number ${color}`}>{numberToShow}</span></div>
        );
    };

    const getProfessionalConsulationCard = (highRiskType: string) => {
        let text = "";
        let qrCodeSrc = "";

        if (highRiskType === HighRiskType.PAIN) {
            text = "Based on your assessment, we recommend that you consult with a professional regarding your pain.\n\nStudies show individuals who get help early tend to lead healthier, happier lives and have a lower chance of getting injured again. If pain Is ignored, or masked by regularly taking medication, the root cause could eventually go beyond just pain and lead to more severe health and mobility problems in the future.";
            qrCodeSrc = qrHighRisk;
        }
        else if (highRiskType === HighRiskType.BEHAVIORAL) {
            text = "Based on your assessment, we recommend that you consult with a professional regarding your mental health and well-being.\n\nMental health significantly impacts physical health and vice versa. Addressing mental health proactively supports holistic health and can improve overall quality of life. Find out how we can help you get the help you need, when you need it.";
            qrCodeSrc = qrHighRisk;
        }
        else if (highRiskType === HighRiskType.BEHAVIORAL_AND_PAIN) {
            text = "Based on your assessment, we recommend that you consult with a professional regarding your mental health and pain.\n\nAlthough you are in pain, mental health significantly impacts physical health and vice versa. Addressing mental health proactively supports holistic health and can improve overall quality of life. Find out how we can help you get the help you need, when you need it.";
            qrCodeSrc = qrHighRisk;
        }

        return (
            <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                {getCardNumber(1, "red")}
                <div className="pr-11">
                    <h2>Professional Consultation</h2>
                    <p className="whitespace-pre-line pt-2">
                        {text}
                    </p>
                </div>
                <div className="qr-code-container">
                    <div className="text-05 font-medium">VIEW YOUR RECOMMENDATIONS</div>
                    <div className="qr-code-wrapper">
                        <img src={qrCodeSrc} alt="QR Code" />
                    </div>
                </div>
            </div>
        );
    };

    const getExercises = () => {
        if (data.painCorrective || data.movementFocusArea || data.roadToWellnessFocusArea) {
            let text = <p></p>;
            let time = "";
            let cards: {
                imageSrc: string;
                title: string;
                time: string;
            }[] = [];

            if (data.painCorrective) {
                text = <p>It's essential to get you out of <strong>pain</strong> early before other problems arise. Pain is a signal telling your body that something is wrong. Your personalized program is aimed at providing simple exercises that are going to help slowly decrease pain and tightness. By consistently performing these exercises, you'll notice significant improvements but we recommend that you schedule an appointment with a healthcare professional to diagnose and treat this pain.</p>;
                time = "10 - 15 minutes";

                cards.push(
                    {
                        imageSrc: figure4LowerBodyImg,
                        title: "Figure 4 - Lower Body",
                        time: "1 min each side",
                    },
                    {
                        imageSrc: figure4UpperBodyImg,
                        title: "Figure 4 - Upper Body",
                        time: "1 min each side",
                    },
                    {
                        imageSrc: quadrupedRockImg,
                        title: "Quadruped Rock",
                        time: "1 min",
                    },
                    {
                        imageSrc: catCamelImg,
                        title: "Cat/Camel",
                        time: "1 min",
                    },);
            }
            else if (data.roadToWellnessFocusArea) {
                text = <p>Congratulations! You have passed the functional wellness screen and are now ready to make strides towards developing good strength, endurance and challenging your body. You will have the opportunity to start the 30-day road to wellness challenge. This program includes a full array of body weight exercises designed to challenge and develop baseline fitness.</p>;

                time = "10 minutes";

                cards.push(
                    {
                        imageSrc: legLoweringImg,
                        title: "Leg Lowering Stretch",
                        time: "2 min",
                    },
                    {
                        imageSrc: splitSquatImg,
                        title: "Split Squat",
                        time: "2 min",
                    },
                    {
                        imageSrc: plankWalkoutImg,
                        title: "Plank Walkout",
                        time: "2 min",
                    },
                    {
                        imageSrc: bearCrawlingImg,
                        title: "Bear Crawling",
                        time: "1 min",
                    },
                    {
                        imageSrc: lateralLungesImg,
                        title: "Lateral Lunges",
                        time: "2 min",
                    },);
            }
            else {
                switch (data.movementFocusArea) {
                    case MovementFocusArea.BALANCE:
                        text =
                            <p>
                                It's essential to improve your <b>balance</b> as being able to balance well on each leg individually gives us the foundation and confidence in our ability to accomplish different activities. You've been prescribed a personalized program aimed at improving your ankle mobility and dynamic balance. By consistently performing these exercises, you'll notice significant improvements in all aspects of life.
                            </p>;

                        time = "10 - 15 minutes";

                        cards.push(
                            {
                                imageSrc: ankleMobilityBalanceImg,
                                title: "Ankle Mobility",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: balanceWithRotationImg,
                                title: "Balance with Rotation",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: straightLegBridgeImg,
                                title: "Straight Leg Bridge",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: splitStanceToeTouchProgressionImg,
                                title: "Split Stance-Toe Touch Progression",
                                time: "2 min each side",
                            },);
                        break;
                    case MovementFocusArea.ROTATION:
                        text =
                            <p>
                                It's essential to enhance <b>rotation</b>, which is vital for both the upper and lower body to move together, as well as independently. You've been prescribed a personalized program aimed at increasing flexibility and range of motion in your shoulders, upper back, and hips. By consistently performing these exercises, you'll be able to enjoy leisure activities such as golf and tennis more fully. Improved rotation helps you regain balance quicker and control your body better, allowing you to react and respond more effectively.
                            </p>;

                        time = "10 - 15 minutes";

                        cards.push(
                            {
                                imageSrc: brettzel2ElbowsToFloorImg,
                                title: "Brettzel 2.0 Elbows to Floor",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: halfKneelingRotationImg,
                                title: "Half Kneeling Rotation",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionRotationImg,
                                title: "Single Arm - Toe Touch Progression",
                                time: "1 min each side",
                            },);
                        break;
                    case MovementFocusArea.SHOULDER_CLEARING:
                        text =
                            <p>
                                It's essential to <b>address pain early</b> before other problems arise. Pain signals that something is wrong. Your personalized program includes simple exercises designed to improve mobility and reduce pain. Consistently performing these exercises will lead to significant improvements, but we recommend scheduling an appointment with a healthcare professional for a thorough diagnosis and treatment.
                            </p>;

                        time = "10 - 12 minutes";

                        cards.push(
                            {
                                imageSrc: ribGrabsImg,
                                title: "Rib Grabs",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: tallKneelingTurnsImg,
                                title: "Tall Kneeling Turns",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionShoulderMobilityImg,
                                title: "Single Arm - Toe Touch Progression",
                                time: "1 min each side",
                            },);
                        break;
                    case MovementFocusArea.SHOULDER_MOBILITY:
                        text =
                            <p>
                                It's essential to focus on improving your <b>shoulder mobility</b>. You've been prescribed a personalized program designed to increase the flexibility and range of motion in your shoulders and upper back. By consistently performing these exercises, you can reduce neck and back stiffness, ultimately leading to improved posture. This improved posture, in turn, can have a positive impact on various aspects of your life, including daily activities, exercise routines, and decrease in pain/stiffness.
                            </p>;

                        time = "10 - 15 minutes";

                        cards.push(
                            {
                                imageSrc: ribGrabsImg,
                                title: "Rib Grabs",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: lumbarLockedRotationImg,
                                title: "Lumbar Locked Rotation",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: halfKneelingRotatonWideImg,
                                title: "Half Kneeling Rotation - Wide Base",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionShoulderMobilityImg,
                                title: "Single Arm - Toe Touch Progression",
                                time: "2 min each side",
                            },);
                        break;
                    case MovementFocusArea.SPINE_CLEARING:
                        text =
                            <p>
                                It's essential to <b>address pain early</b> before other problems arise. Pain signals that something is wrong. Your personalized program includes simple exercises designed to improve mobility and reduce pain. Consistently performing these exercises will lead to significant improvements, but we recommend scheduling an appointment with a healthcare professional for a thorough diagnosis and treatment.
                            </p>;

                        time = "10 minutes";

                        cards.push(
                            {
                                imageSrc: crocodileBreathingImg,
                                title: "Crocodile Breathing",
                                time: "2 min",
                            },
                            {
                                imageSrc: quadrupedRockImg,
                                title: "Quadruped Rock",
                                time: "1 min",
                            },
                            {
                                imageSrc: backBreathingImg,
                                title: "Back Breathing",
                                time: "1 min",
                            },);
                        break;
                    case MovementFocusArea.SQUAT:
                        text =
                            <p>
                                It's essential to improve the way you <b>squat</b>, as it's one of the most fundamental things we do and lose the quickest.  You've been prescribed a personalized program aimed at increasing flexibility and range of motion in your ankle, shoulders, upper back, and hips. By consistently performing these exercises, you'll notice significant improvements in all aspects of life from the way you move and feel. It's a basic, essential movement that all of us should be able to perform in order to enjoy our everyday lives without aches and pains.
                            </p>;

                        time = "10 - 15 minutes";

                        cards.push(
                            {
                                imageSrc: ankleMobilitySquatImg,
                                title: "Ankle Mobility",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: brettzel3Img,
                                title: "Brettzel 3.0",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: plankShoulderTapsImg,
                                title: "Plank Shoulder Taps",
                                time: "30 sec",
                            },
                            {
                                imageSrc: toeTouchProgressionSquatImg,
                                title: "Toe Touch Progression",
                                time: "2 min each side",
                            },);
                        break;
                    case MovementFocusArea.TOE_TOUCH:
                        text =
                            <p>
                                It's essential to focus on improving your <b>Toe Touch</b>. You've been prescribed a personalized program designed to increase the mobility in your hips and lower body.  By consistently performing these exercises, you can hip and lower back stiffness, ultimately leading to improved movement. This improved movement, in turn, can have a positive impact on various aspects of your life, including daily activities, exercise routines, and decrease in pain/stiffness.
                            </p>;

                        time = "10 - 15 minutes";

                        cards.push(
                            {
                                imageSrc: assistedLegLoweringImg,
                                title: "Assisted Leg Lowering",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: singleLegBridgeImg,
                                title: "Single Leg Bridge",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: hipFlexorStretchImg,
                                title: "Hip Flexor Stretch",
                                time: "1 min each side",
                            },
                            {
                                imageSrc: toeTouchProgressionImg,
                                title: "Toe Touch Progression",
                                time: "2 min each side",
                            },);
                        break;
                }
            }

            return (
                <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                    {getCardNumber(data.highRisk ? 3 : 2, "blue")}
                    <div>
                        <div className="flex flex-row justify-between items-center">
                            <h2 className="mb-2">Preview of Your Daily At-Home Exercises</h2>
                            <span className="text-07 font-medium flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__16px" /></span>{time}</span>
                            {data.movementFocusAreaDuration ?
                                <span className="text-07 font-medium flex flex-row gap-1 items-center"><span><CalendarIcon className="icon icon__16px" /></span>Days {data.movementFocusAreaDuration}</span> : ""
                            }

                        </div>
                        <p className="text-08"><span className="font-medium">Exercise Focus:</span> {data.readableMovementFocusArea}</p>
                        <br />
                        {text}
                        <br />

                        <div className="text-07 font-medium mb-3">Perform these exercises daily to feel better and stronger:</div>

                        <div className="flex flex-row flex-wrap gap-4 items-center">
                            {cards.map((card) => {
                                return (
                                    <div className={`${COMPONENT_CLASS}__exercise-card`}>
                                        <div className="w-32" style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                                            <img src={card.imageSrc} className="rounded-md" alt="Daily Exercise" />
                                        </div>
                                        <div className="flex flex-col gap-3">
                                            <span className="text-07 font-medium">{card.title}</span>
                                            <span className="text-06 font-light flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__12px" /></span>{card.time}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div >
            );
        }
    };

    let indexForSvg = 0;

    const getDomainOverviewSection = (icon: JSX.Element, title: string, description: string, whyImportantText: string, qrCode: string, percentage: number = 0, average: number = 0) => {
        indexForSvg += 1;
        let isPercentageHigherThanAvg = percentage > average || percentage === 100;
        if (title === "Movement Health" && data.movementPain) {
            isPercentageHigherThanAvg = false;
        }

        return (
            <div className="flex flex-row gap-4">
                <div>
                    <div className="flex flex-col mb-4">
                        <div className="flex flex-row gap-3">
                            <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__36px`} style={{ backgroundColor: "#ADDFFD", padding: "0.35rem" }}>
                                {icon}
                            </div>
                            <h1 className="font-medium">{title}</h1>
                        </div>
                        <p className="font-light">{description}</p>
                    </div>

                    <div className="flex flex-row justify-between gap-6">
                        <div>
                            <div className="text-07 font-medium flex flex-row items-center mb-1 gap-1">Why is this important?</div>
                            <p>{whyImportantText}</p>
                        </div>
                        <div>
                            <div className="flex flex-row gap-2 items-center border-gray-blue border-2 rounded-xl p-2">
                                <div className="w-16 text-06">More About {title}</div>
                                <div className="w-16 h-16">
                                    <img src={qrCode} alt="QR Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center ml-2 background-gray-blue rounded-xl p-5">
                    <div className="flex flex-row items-center justify-center gap-1">
                        {isPercentageHigherThanAvg
                            ?
                            <>
                                <span className="icon icon__16px">
                                    <CheckmarkIcon className="checkmark-icon" />
                                </span>
                                <span className="green-text font-bold text-base">{percentage}%</span>
                            </>
                            :
                            <>
                                <span className="icon icon__16px">
                                    <CrossRedIcon />
                                </span>
                                <span className="red-text font-bold text-base">{percentage}%</span>
                            </>
                        }
                    </div>
                    <div className="max-h-full" style={{ minWidth: "1.25rem", maxWidth: "1.25rem" }}>
                        <svg width="100%" height="100%" viewBox="0 0 34 280" className=" overflow-visible">
                            <PillChart
                                key={indexForSvg}
                                uniqueId={indexForSvg.toString()}
                                index={0}
                                percentage={percentage}
                                linePercentage={average}
                                lineText={`AVG ${average}`}
                                heightPixels={250}
                            />
                        </svg>
                    </div>
                </div>

            </div>
        );
    };

    const getAssessmentBreakdownCell = (text: string, showCheckMark: boolean) => {
        return (
            <span className="flex flex-row" style={{ gap: "2px" }}>
                <span>
                    {showCheckMark
                        ? <CheckIcon className="icon icon__8px" />
                        : <CrossIcon className="icon icon__8px" />}
                </span>
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </span>
        );
    };

    const getAssessmentBreakdownColor = (score: number | undefined, goalScore: number) => {
        if (score !== undefined && score >= goalScore) {
            return "background-green";
        }
        else {
            return "background-red";
        }
    }

    const getPainBadge = (painAnswer: string | boolean) => {
        if (painAnswer && painAnswer !== ReportAnswer.A) {
            let text = "";

            switch (painAnswer) {
                case true:
                    text = "PAIN";
                    break;
                case ReportAnswer.B:
                    text = "MILD PAIN";
                    break;
                case ReportAnswer.C:
                    text = "MODERATE PAIN";
                    break;
                case ReportAnswer.D:
                    text = "SEVERE PAIN";
                    break;
            }

            return (
                <div className="pain-badge">{text}</div>
            );
        }
    };

    const getClearingPainBadge = (painAnswer: string | boolean, type: number) => {
        if (painAnswer && painAnswer !== ReportAnswer.A) {
            let text = "";
            if (type === 1) {
                text = "PAIN - SHOULDER CLEARING"
                return (
                    <div className="pain-badge">{text}</div>
                );
            }
            else if (type === 2) {
                text = "PAIN - SPINE CLEARING"
                return (
                    <div className="pain-badge">{text}</div>
                );
            }
        }

    };

    const getChartBadge = (oldScore: number, currentScore: number) => {
        const scoreDifference = currentScore - oldScore;
        let icon: JSX.Element = <></>;
        let text: string = "";
        let badgeColor: string = "";

        if (scoreDifference > 0) {
            icon = <ChartArrowUp className="w-3 h-auto" />;
            text = scoreDifference.toString();
            badgeColor = "green";
        }
        else if (scoreDifference === 0) {
            icon = <></>;
            text = "-";
            badgeColor = "gray";
        }
        else {
            icon = <ChartArrowDown className="w-3 h-auto" />;
            text = (scoreDifference * -1).toString();
            badgeColor = "red";
        }

        return (
            <div className={`chart-badge ${badgeColor} absolute top-5 right-5`}>
                {icon}
                {text}
            </div>
        );
    };

    const get2ColoredCells = (title: string, redMain: string, greenMain: string, isGreen: boolean) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1 h-8">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light"></span>
                        </div>
                        {isGreen &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1 h-8">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light"></span>
                        </div>
                        {!isGreen &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3ColoredCells = (title: string, redMain: string, redSub: string, orangeMain: string, orangeSub: string, greenMain: string, greenSub: string, reportAnswer: ReportAnswer | undefined) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>


                    <div className="w-1/3 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3ColoredCellsReversed = (title: string, greenMain: string, greenSub: string, orangeMain: string, orangeSub: string, redMain: string, redSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3GreyedCells = (title: string, firstMain: string, firstSub: string, secondMain: string, secondSub: string, thirdMain: string, thirdSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{firstMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{firstSub}</span>
                        </div>
                    </div>

                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{secondMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{secondSub}</span>
                        </div>
                    </div>

                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{thirdMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{thirdSub}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const get4ColoredCells = (title: string, redMain: string, redSub: string, orangeMain: string, orangeSub: string, greenMain: string, greenSub: string, blueMain: string, blueSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/4 relative">
                        <div className="background-light-blue flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{blueMain}</span>
                            <span className="text-07 font-light">{blueSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/4 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>


                    <div className="w-1/4 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>

                    <div className="w-1/4 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.D &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                You
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const getInjuryHistoryCells = (injuryHistory: InjuryHistoryResponse) => {
        return (
            <div className="mt-3">
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">Previous Injury</span></div>
                        <div className={`${injuryHistory.injuryHistoryInjury ? "background-red" : "background-green"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryInjury ? "Yes" : "No"}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">Current Pain</span></div>
                        <div className={`${injuryHistory.injuryHistoryPain ? "background-red" : "background-green"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryPain ? "Yes" : "No"}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">Seen a Physician</span></div>
                        <div className={`${injuryHistory.injuryHistoryPainPhysician ? "background-green" : "background-red"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryPainPhysician ? "Yes" : "No"}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">Recovery Score</span></div>
                        <div className={`${injuryHistory.injuryHistorySaneScore >= 88 ? "background-green" : "background-red"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistorySaneScore}%</span>
                        </div>
                    </div>
                </div>

                <div className="text-07 pt-4">
                    <div className="font-medium">
                        CURRENT PAIN / INJURY LOCATION
                    </div>
                    <div className="font-light">
                        {injuryHistory.injuryHistoryPainAreas}
                    </div>
                </div>
            </div>
        );
    };

    // const getReportGraphs = () => {
    //     <div>
    //         <div className="flex flex-row justify-between">
    //             <h1>Your Progress</h1>
    //             <div className="text-xs font-light" style={{ color: "rgba(173, 223, 253, 1)" }}>November 2023 - April 2024</div>
    //         </div>
    //         <div className="flex flex-col mt-3">
    //             <div className="flex flex-row">
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.wellnessGraphData?.data[4] ? data.wellnessGraphData?.data[4] : data.wellnessGraphData?.data[5], data.wellnessGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS labels={data.graphLabels} data={data.wellnessGraphData?.data} title={data.wellnessGraphData?.title} />
    //                 </div>
    //             </div>
    //             <div className="flex flex-row">
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.movementHealthGraphData?.data[4] ? data.movementHealthGraphData?.data[4] : data.movementHealthGraphData?.data[5], data.movementHealthGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS labels={data.graphLabels} data={data.movementHealthGraphData?.data} title={data.movementHealthGraphData?.title} />
    //                 </div>
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.physicalActivityGraphData?.data[4] ? data.physicalActivityGraphData?.data[4] : data.physicalActivityGraphData?.data[5], data.physicalActivityGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS labels={data.graphLabels} data={data.physicalActivityGraphData?.data} title={data.physicalActivityGraphData?.title} />
    //                 </div>
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS labels={data.graphLabels} data={data.bodyCompositionGraphData?.data} title={data.bodyCompositionGraphData?.title} />
    //                 <LineChartJS labels={data.graphLabels} data={data.injuryHistoryGraphData?.data} title={data.injuryHistoryGraphData?.title} />
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS labels={data.graphLabels} data={data.nutritionAwarenessGraphData?.data} title={data.nutritionAwarenessGraphData?.title} />
    //                 <LineChartJS labels={data.graphLabels} data={data.behavioralHealthGraphData?.data} title={data.behavioralHealthGraphData?.title} />
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS labels={data.graphLabels} data={data.sleepWellnessGraphData?.data} title={data.sleepWellnessGraphData?.title} />
    //                 <LineChartJS labels={data.graphLabels} data={data.breathingQualityGraphData?.data} title={data.breathingQualityGraphData?.title} />
    //             </div>
    //         </div>
    //     </div>
    // }

    const titleCase = (str: string) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    return (
        <div className={`${COMPONENT_CLASS} -wellness`}>
            <ReportWellnessHeader name={data.userFullName} isFemale={data.isFemale} age={data.age} />
            <div className="flex gap-6 background-gray-blue pt-6 pb-4 px-10">
                <div className="w-4/5">
                    <h1>Your Summary</h1>
                    <p>
                        The Wellness Score assesses how healthy you are by measuring your overall health, movement, and lifestyle behaviors.
                        <br />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: summary }}></span>
                        <br />
                        <br />
                        Your Functional Wellness Report will guide you through the aspects that influence your Wellness Score the most, helping you focus your efforts for a healthier and happier lifestyle.
                    </p>
                </div>
                <div className="wellness-score-donut-chart-container">
                    <DonutChart
                        percentage={data.wellnessScore}
                        title="Wellness Score"
                        badgeText={wellnessScoreLabel}
                    />
                    <div className="text-06 font-normal text-gray">Goal Wellness Score: 80-100</div>
                </div>
            </div>

            <div className="flex justify-between gap-10 pt-5 pl-10">
                {data.focusAreas &&
                    <div className="w-3/4 pb-5">
                        <h2 className="mb-3">Your Focuses</h2>
                        <p>Based on your assessments, improving your&nbsp;
                            {data.focusAreas.map((focusArea, index) => {
                                if (!data.focusAreas) {
                                    return undefined;
                                }
                                if (focusArea === FocusArea.MOVEMENT_HEALTH_BREATHING) {
                                    return (<><strong>Movement Health/Breathing</strong> {index + 1 === data.focusAreas.length ? "" : "and "}</>)
                                }
                                else {
                                    let formattedString = focusArea.replace("_", " ");
                                    formattedString = formattedString.toLowerCase();
                                    formattedString = titleCase(formattedString)
                                    return (<><strong>{formattedString}</strong> {index + 1 === data.focusAreas.length ? "" : "and "}</>)

                                }

                            })}
                            will have the greatest positive impact on your overall health and wellness, leading to a higher quality of life.</p>

                        {data.focusAreas.map((focusArea, index) => {
                            return getFocusAreaSection(focusArea, index === 0, focusArea === FocusArea.MOVEMENT_HEALTH && data.movementPain); //show bottom border only on the 1st element
                        })}
                    </div>
                }

                {data.behavioralHealth && data.movementHealth && data.injuryHistory && data.sleepWellness && data.nutritionalAwareness && data.bodyComposition && data.breathingQuality && data.physicalActivity &&
                    <div className="flex flex-col gap-2 background-gray-blue p-4 rounded-xl">
                        <h2 className="text-center">Your Assessment Scores</h2>
                        {assessmentScores.map((a) => {
                            return getAssessmentScoreCard(a.title, a.percentage, a.avg)
                        })}
                        {/* {getAssessmentScoreCard("Behavioral Health", data.behavioralHealth.percentage, data.behavioralHealth.average)}
                        {getAssessmentScoreCard("Movement Health", data.movementHealth.percentage, data.movementHealth.average)}
                        {getAssessmentScoreCard("Injury History", data.injuryHistory.percentage, data.injuryHistory.average)}
                        {getAssessmentScoreCard("Sleep Wellness", data.sleepWellness.percentage, data.sleepWellness.average)}
                        {getAssessmentScoreCard("Nutritional Awareness", data.nutritionalAwareness.percentage, data.nutritionalAwareness.average)}
                        {getAssessmentScoreCard("Body Composition", data.bodyComposition.percentage, data.bodyComposition.average)}
                        {getAssessmentScoreCard("Breathing Quality", data.breathingQuality.percentage, data.breathingQuality.average)}
                        {getAssessmentScoreCard("Physical Activity", data.physicalActivity.percentage, data.physicalActivity.average)} */}
                    </div>
                }
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                <h1>Your Next Steps</h1>
                <div className="flex flex-col gap-3">
                    {data.highRisk && data.highRiskType &&
                        getProfessionalConsulationCard(data.highRiskType)
                    }
                    {data.symmioAccess
                        ?
                        <>
                            <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                                {getCardNumber(data.highRisk ? 2 : 1, "blue")}
                                <div>
                                    <h2>Access Your Full Personalized Program</h2>
                                    <p className="py-1">
                                        Download the <strong>Symmio App</strong> to get your full personalized improvement plan and guided corrective exercises, made just for you based on your MSK assessment results and focuses.
                                    </p>
                                    <div className="flex flex-row gap-3 items-center bg-white p-5 mt-4 rounded-xl">
                                        <div className="qr-code-wrapper">
                                            <img src={qrCodeImg} alt="QR Code" />
                                        </div>
                                        <div>
                                            <p>
                                                <b>
                                                    Scan this code with your device's camera to download the app, or go to your device's app store and search for Symmio.
                                                </b>
                                            </p>
                                            <p className="pt-2">
                                                <b>Username:</b> {data.userEmail}
                                            </p>
                                            <div className="mobile-stores pt-2">
                                                <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStoreImg} alt="App Store" className="app-store-button" /></a>
                                                <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlayImg} alt="Google Play" className="google-play-button" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {getExercises()}
                        </>
                        :
                        <div className="flex flex-col gap-3 p-4 background-gray-blue rounded-xl">
                            <div className="flex flex-row gap-2">
                                {getCardNumber(data.highRisk ? 2 : 1, "blue")}
                                <div className="pr-6">
                                    <h2>Move and feel better daily with Symmio.</h2>
                                    <p className="pt-2">
                                        <b>
                                            Symmio is your guide to better health and wellness by providing you with your greatest opportunities for improvement.
                                        </b>
                                        <br />
                                        <br />
                                        Optimize your overall well-being and reduce your risk of potential injury. Symmio promotes balance across your mental, emotional, nutritional, and physical health, empowering you to achieve a higher quality of life.
                                    </p>
                                </div>
                                <div className="qr-code-container">
                                    <div className="text-05 font-medium">LEARN MORE ABOUT SYMMIO</div>
                                    <div className="qr-code-wrapper">
                                        <img src={qrWebMySymmio} alt="QR Code" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-2 p-4 justify-center">
                                <div>
                                    <div className="icon icon__24px">
                                        <ImprovementPlanIcon />
                                    </div>
                                    <span className="text-07">Improvement Plans</span>
                                    <p>
                                        Get personalized improvement plans, including areas of focus and guided corrective exercises, made just for you based on your assessment.
                                    </p>
                                </div>

                                <div>
                                    <div className="icon icon__24px">
                                        <ProgressTrackingIcon />
                                    </div>
                                    <span className="text-07">Progress Tracking</span>
                                    <p>
                                        Track your progress over time and see how your overall health and wellness has improved, and which areas still need attention.
                                    </p>
                                </div>

                                <div>
                                    <div className="icon icon__24px">
                                        <DailyTipsIcon />
                                    </div>
                                    <span className="text-07">Education & Daily Tips</span>
                                    <p>
                                        Get daily insightful content and targeted suggestions related to your focus areas to motivate you on your journey of self-improvement.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <div className="flex flex-row justify-center gap-5 bg-white rounded-xl p-4 w-fit">
                                    <div className="w-56">
                                        <img src={devicesImg} alt="Symmio Devices" />
                                    </div>
                                    <div className="flex flex-col justify-center items-center text-center gap-2">
                                        <div className="w-14">
                                            <img src={logoStackedImg} alt="Symmio Logo" />
                                        </div>
                                        <div className="text-08 font-medium text-blue-dark">
                                            Ask your administrator<br />about Symmio:
                                        </div>
                                        <div className="text-08 font-light">
                                            {data.accountHolderEmail}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                {data.movementHealth &&
                    <div>
                        {getDomainOverviewSection(
                            <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Movement Health",
                            "The movement assessment evaluates 7 fundamental movement patterns that are essential for living an active, pain-free life. Our research indicates that a score between 75-100 signifies that you meet the minimum standard for movement, which improves your overall health and wellness.",
                            "Evaluating fundamental movement patterns is essential for reducing injury risk, enhancing performance, managing pain, and promoting long-term health. Proper movement improves daily efficiency, boosts athletic performance, reduces chronic pain, and enhances quality of life.",
                            qrCodeMovementHealth,
                            data.movementHealth.percentage,
                            data.movementHealth.average,
                        )}

                        <div>
                            <h2 className="mt-4 mb-4">Your Assessment Breakdown</h2>
                            <table className="text-07 assessment-breakdown">
                                <thead>
                                    <tr>
                                        <th className="text-start">ASSESSMENT</th>
                                        <th className="text-start">GOAL</th>
                                        <th className="text-start">YOUR SCORE</th>
                                        <th className="text-start">PAIN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Toe Touch</td>
                                        <td>75 - 100</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.toeTouchScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.toeTouchScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">LEFT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Front Toe", data.movementHealth.toeTouchReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell("Back Toe", data.movementHealth.toeTouchReachLeft.itemTwo)}

                                                    <span className="font-medium">RIGHT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Front Toe", data.movementHealth.toeTouchReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell("Back Toe", data.movementHealth.toeTouchReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.toeTouchQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>Shoulder Mobility</td>
                                        <td>75 - 100</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.shoulderMobilityScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.shoulderMobilityScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">LEFT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Paper Pass", data.movementHealth.shoulderMobilityReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell("Fingertips Touch", data.movementHealth.shoulderMobilityReachLeft.itemTwo)}

                                                    <span className="font-medium">RIGHT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Paper Pass", data.movementHealth.shoulderMobilityReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell("Fingertips Touch", data.movementHealth.shoulderMobilityReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        {(data.movementHealth.shoulderMobilityQPain && data.movementHealth.shoulderMobilityQPain !== ReportAnswer.A) ?
                                            <td>{getPainBadge(data.movementHealth.shoulderMobilityQPain)}</td> : <td>{getClearingPainBadge(data.movementHealth.shoulderClearingQPain, 1)}</td>}
                                    </tr>

                                    <tr>
                                        <td>Rotation</td>
                                        <td>75 - 100</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.rotationScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.rotationScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">LEFT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Feet Together", data.movementHealth.rotationReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell("Right Foot Back", data.movementHealth.rotationReachLeft.itemTwo)}

                                                    <span className="font-medium">RIGHT SIDE:</span>
                                                    {getAssessmentBreakdownCell("Feet Together", data.movementHealth.rotationReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell("Right Foot Back", data.movementHealth.rotationReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        {(data.movementHealth.rotationQPain && data.movementHealth.rotationQPain !== ReportAnswer.A) ? <td>{getPainBadge(data.movementHealth.rotationQPain)}</td> : <td>{getClearingPainBadge(data.movementHealth.spineClearingQPain, 2)}</td>}

                                    </tr>

                                    <tr>
                                        <td>Squat</td>
                                        <td>50 - 100</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.squatScore, 50)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.squatScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    {getAssessmentBreakdownCell("Squat", data.movementHealth.squatLow.itemOne)}
                                                    {getAssessmentBreakdownCell("Fingertips", data.movementHealth.squatLow.itemTwo)}
                                                    {getAssessmentBreakdownCell("Fists", data.movementHealth.squatLow.itemThree ?? true)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.squatQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>Balance & Reach</td>
                                        <td>75 - 100</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.balanceScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.balanceScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">1 FOOT:</span>
                                                    {getAssessmentBreakdownCell("Right", data.movementHealth.firstBalanceReach.itemOne)}
                                                    {getAssessmentBreakdownCell("Left", data.movementHealth.firstBalanceReach.itemTwo)}

                                                    <span className="font-medium">2 FEET:</span>
                                                    {getAssessmentBreakdownCell(`Right: <b>${data.movementHealth.secondBalanceReachLeft.attempts}</b> Attempts`, data.movementHealth.secondBalanceReachLeft.success)}
                                                    {getAssessmentBreakdownCell(`Left: <b>${data.movementHealth.secondBalanceReachRight.attempts}</b> Attempts`, data.movementHealth.secondBalanceReachRight.success)}

                                                    <span className="font-medium">2.5 FEET:</span>
                                                    {getAssessmentBreakdownCell(`Right: <b>${data.movementHealth.thirdBalanceReachLeft.attempts}</b> Attempts`, data.movementHealth.thirdBalanceReachLeft.success)}
                                                    {getAssessmentBreakdownCell(`Left: <b>${data.movementHealth.thirdBalanceReachRight.attempts}</b> Attempts`, data.movementHealth.thirdBalanceReachRight.success)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.balanceQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>Shoulder Clearing</td>
                                        <td>Pass (No Pain)</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-center gap-4 items-center py-2 px-5 w-4/5 ${data.movementHealth.shoulderClearingScore ? "background-green" : "background-red"}`}>
                                                <span>
                                                    {data.movementHealth.shoulderClearingScore ? "Pass" : "Fail"}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.shoulderClearingQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>Spine Clearing</td>
                                        <td>Pass (No Pain)</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-center gap-4 items-center py-2 px-5 w-4/5 ${data.movementHealth.spineClearingScore ? "background-green" : "background-red"}`}>
                                                <span>
                                                    {data.movementHealth.spineClearingScore ? "Pass" : "Fail"}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.spineClearingQPain)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <div className="break-after-page"></div>

                {data.physicalActivity &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <PhysicalActivityIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Physical Activity",
                            "We screen physical activity using the American College of Sports Medicine's recommendations for healthy individuals. Scoring 100% indicates you are getting the recommended amount of daily physical activity to improve / maintain a healthy lifestyle.  The lower the score indicates lack of physical activity.",
                            "Physical activity is crucial for health, helping prevent chronic diseases like heart disease and diabetes. It encourages healthier behaviors, enhances quality of life, fosters independence, and supports mental health.",
                            qrCodePhysicalActivity,
                            data.physicalActivity.percentage,
                            data.physicalActivity.average,
                        )}

                        {get3ColoredCells(
                            "MODERATE ACTIVITY (PER WEEK)",
                            "Little Activity",
                            "0 - 99 minutes",
                            "Some Activity",
                            "100 - 149 minutes",
                            "Recommended Activity",
                            "150+ minutes",
                            data.physicalActivity.physicalActivityModerate,
                        )}

                        <br />

                        {get3ColoredCells(
                            "VIGOROUS ACTIVITY (PER WEEK)",
                            "Little Activity",
                            "0 - 99 minutes",
                            "Some Activity",
                            "100 - 149 minutes",
                            "Recommended Activity",
                            "150+ minutes",
                            data.physicalActivity.physicalActivityVigorous,
                        )}

                        <br />

                        {get3ColoredCells(
                            "MUSCLE STRENGTH TRAINING",
                            "No Strength Training",
                            "0 days a week",
                            "Some Strength Training",
                            "1 day a week",
                            "Recommended Strength Training",
                            "2 days a week",
                            data.physicalActivity.physicaActivityStrengthNum,
                        )}
                    </div>
                }

                <hr />

                {data.bodyComposition &&
                    <div>
                        {getDomainOverviewSection(
                            <BodyCompIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Body Composition",
                            "We assess your body composition by measuring your BMI and hip-to-waist ratio against established healthy norms for your age and sex. Achieving a score of 100% signifies that your measurements fall within the optimal health ranges for your age, height, and weight.",
                            "Excess body weight strains all bodily systems, increasing musculoskeletal and metabolic disorders. Addressing obesity, affecting nearly 75% of the U.S. population, is crucial for prevention and management.",
                            qrCodeBodyComposition,
                            data.bodyComposition.percentage,
                            data.bodyComposition.average,
                        )}

                        {get4ColoredCells(
                            "BMI (BODY MASS INDEX)",
                            "Obese",
                            "30.0 and Above",
                            "Overweight",
                            "25.0 - 29.9",
                            "Healthy Weight",
                            "18.5 - 24.9",
                            "Underweight",
                            "Below 18.5",
                            data.bodyComposition.bmi,
                        )}

                        <br />

                        {data.bodyComposition.hipToWaistRatio &&
                            data.bodyComposition.hipToWaistRatio === ReportAnswer.E
                            ? get3GreyedCells(
                                "HIP-TO-WAIST RATIO",
                                "Low Health Risk",
                                data.isFemale ? "Below 0.80" : "Below 0.95",
                                "Moderate Health Risk",
                                data.isFemale ? "0.81 - 0.85" : "0.96 - 1.0",
                                "High Health Risk",
                                data.isFemale ? "0.85 or Above" : "1.0 or Above",
                                data.bodyComposition.hipToWaistRatio,
                            )
                            : get3ColoredCellsReversed(
                                "HIP-TO-WAIST RATIO",
                                "Low Health Risk",
                                data.isFemale ? "Below 0.80" : "Below 0.95",
                                "Moderate Health Risk",
                                data.isFemale ? "0.81 - 0.85" : "0.96 - 1.0",
                                "High Health Risk",
                                data.isFemale ? "0.85 or Above" : "1.0 or Above",
                                data.bodyComposition.hipToWaistRatio as ReportAnswer,
                            )
                        }

                    </div>
                }
                <div className="break-after-page"></div>

                {data.injuryHistory &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <InjuryHistoryIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Injury History",
                            "We screen your injury history using an evidence-based injury prediction algorithm to categorize injury risk. Scoring between 88-100 indicates you don't have any previous injury history or current risk factors that could negatively impact your health and wellness.",
                            "Understanding your injury history is crucial for health and wellness. Though often overlooked, it significantly influences future injury risk and health decline, playing a key role in your wellness journey.",
                            qrCodeInjuryHistory,
                            data.injuryHistory.percentage,
                            data.injuryHistory.average,
                        )}

                        {getInjuryHistoryCells(data.injuryHistory)}
                    </div>
                }

                <hr />

                {data.nutritionalAwareness &&
                    <div>
                        {getDomainOverviewSection(
                            <NutritionIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Nutritional Awareness",
                            "We evaluate your nutritional awareness using a nutrition quiz designed to test your basic knowledge of nutrition and health. Scoring 100% indicates that you have a sufficient understanding of nutritional principles to make informed choices about your diet, which is essential for maintaining health.",
                            "Nutritional awareness is essential for making healthy dietary choices. It helps prevent chronic diseases like obesity, diabetes, and heart disease while boosting mental clarity, energy levels, and overall well-being.",
                            qrCodeNutritionalAwareness,
                            data.nutritionalAwareness.percentage,
                            data.nutritionalAwareness.average,
                        )}
                    </div>
                }

                <hr />

                {data.behavioralHealth &&
                    <div>
                        {getDomainOverviewSection(
                            <BehavioralHealthIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Behavioral Health",
                            "We screen your behavioral health using two evidence-based tests used by behavioral health specialists. Scoring between 80-100 indicates you don't have any alarming issues in your mental well-being. Whereas scoring lower may indicate possible.",
                            "Mental and physical health are interconnected. Proactively addressing mental health supports overall well-being and quality of life. Early identification of mental health issues allows for more effective interventions and prevents conditions from worsening.",
                            qrCodeBehavioralHealth,
                            data.behavioralHealth.percentage,
                            data.behavioralHealth.average,
                        )}

                    </div>
                }
                <div className="break-after-page"></div>

                {data.sleepWellness &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <SleepIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Sleep Wellness",
                            "We evaluate your sleep wellness using the Pittsburgh Sleep Quality Index. This assessment assesses sleep quality over a 1-month time interval.  Scoring 100% indicates that you experience great sleep hygiene, whereas a lower score indicates poor sleep. ",
                            "Sleep is vital for recovery, immune function, heart health, memory, mood, stress management, weight control, and musculoskeletal health. Prioritizing sleep improves daily functioning, quality of life, and overall well-being.",
                            qrCodeSleepQuality,
                            data.sleepWellness.percentage,
                            data.sleepWellness.average,
                        )}
                        {data.sleepWellness.sleepPSQI === ReportAnswer.E
                            ? get3GreyedCells(
                                "SLEEP QUALITY (PSQI)",
                                "Good",
                                "0 - 4",
                                "Okay",
                                "5 - 9",
                                "Poor",
                                "10 - 21",
                                data.sleepWellness.sleepPSQI
                            )
                            : get3ColoredCellsReversed(
                                "SLEEP QUALITY (PSQI)",
                                "Good",
                                "0 - 4",
                                "Okay",
                                "5 - 9",
                                "Poor",
                                "10 - 21",
                                data.sleepWellness.sleepPSQI,
                            )
                        }
                    </div>
                }

                <hr />

                {data.breathingQuality &&
                    <div>
                        {getDomainOverviewSection(
                            <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            "Breathing Quality",
                            "We test your breathing quality using an evidence-based breath hold time and questionnaire. Scoring between 80-100 indicates you may not experience a breathing dysfunction. The lower the score indicates possible breathing issues that need to be addressed.",
                            "Early detection prevents issues from worsening, improving overall health. Addressing breathing problems alleviates physical discomfort, reduces mental health symptoms, and enhances quality of life.",
                            qrCodeBreathingQuality,
                            data.breathingQuality.percentage,
                            data.breathingQuality.average,
                        )}

                        {get3ColoredCells(
                            "BREATH HOLD TIME",
                            "Poor",
                            "0 - 10 seconds",
                            "Needs Work",
                            "11 - 19 seconds",
                            "Good",
                            "20+ seconds",
                            data.breathingQuality.breathBreath,
                        )}

                        <br />

                        {get3ColoredCells(
                            "BREATHING QUESTIONNAIRE",
                            "Poor",
                            "2 - 3+",
                            "Needs Work",
                            "1",
                            "Good",
                            "0",
                            data.breathingQuality.breathingQuestionnaire,
                        )}

                        <br />
                        {get2ColoredCells(
                            "DO YOU USE TOBACCO / VAPING PRODUCTS",
                            "Yes",
                            "No",
                            data.breathingQuality.breathingSmokeNow,
                        )}
                    </div>
                }
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                <div className="relative">
                    <div>
                        <div className="flex flex-row justify-between">
                            <h1>Your Progress</h1>
                            <div className="text-xs font-light">{getReportDateRange()}</div>
                        </div>
                        <div className="flex flex-col mt-3 gap-4">
                            <div>
                                <div style={{ position: "absolute", top: "52px", right: "0px" }}>
                                    {getChartBadge(data.wellnessGraphData?.data[4] ? data.wellnessGraphData?.data[4] : data.wellnessGraphData?.data[5], data.wellnessGraphData?.data[5])}
                                </div>
                                <LineChartJS labels={data.graphLabels}
                                    data={data.wellnessGraphData?.data}
                                    title={data.wellnessGraphData?.title}
                                    height="200px"
                                    width="650px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />

                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "268px", right: "0px" }}>
                                    {getChartBadge(data.physicalActivityGraphData?.data[4] ? data.physicalActivityGraphData?.data[4] : data.physicalActivityGraphData?.data[5], data.physicalActivityGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "268px", left: "324px" }}>
                                    {getChartBadge(data.movementHealthGraphData?.data[4] ? data.movementHealthGraphData?.data[4] : data.movementHealthGraphData?.data[5], data.movementHealthGraphData?.data[5])}
                                </div>
                                <LineChartJS labels={data.graphLabels} data={data.movementHealthGraphData?.data} title={data.movementHealthGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                                <LineChartJS labels={data.graphLabels} data={data.physicalActivityGraphData?.data} title={data.physicalActivityGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "434px", right: "0px" }}>
                                    {getChartBadge(data.injuryHistoryGraphData?.data[4] ? data.injuryHistoryGraphData?.data[4] : data.injuryHistoryGraphData?.data[5], data.injuryHistoryGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "434px", left: "324px" }}>
                                    {getChartBadge(data.bodyCompositionGraphData?.data[4] ? data.bodyCompositionGraphData?.data[4] : data.bodyCompositionGraphData?.data[5], data.bodyCompositionGraphData?.data[5])}
                                </div>
                                <LineChartJS labels={data.graphLabels} data={data.bodyCompositionGraphData?.data} title={data.bodyCompositionGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                                <LineChartJS labels={data.graphLabels} data={data.injuryHistoryGraphData?.data} title={data.injuryHistoryGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "600px", right: "0px" }}>
                                    {getChartBadge(data.behavioralHealthGraphData?.data[4] ? data.behavioralHealthGraphData?.data[4] : data.behavioralHealthGraphData?.data[5], data.behavioralHealthGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "600px", left: "324px" }}>
                                    {getChartBadge(data.nutritionAwarenessGraphData?.data[4] ? data.nutritionAwarenessGraphData?.data[4] : data.nutritionAwarenessGraphData?.data[5], data.nutritionAwarenessGraphData?.data[5])}
                                </div>
                                <LineChartJS labels={data.graphLabels} data={data.nutritionAwarenessGraphData?.data} title={data.nutritionAwarenessGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                                <LineChartJS labels={data.graphLabels} data={data.behavioralHealthGraphData?.data} title={data.behavioralHealthGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "766px", right: "0px" }}>
                                    {getChartBadge(data.breathingQualityGraphData?.data[4] ? data.breathingQualityGraphData?.data[4] : data.breathingQualityGraphData?.data[5], data.breathingQualityGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "766px", left: "324px" }}>
                                    {getChartBadge(data.sleepWellnessGraphData?.data[4] ? data.sleepWellnessGraphData?.data[4] : data.sleepWellnessGraphData?.data[5], data.sleepWellnessGraphData?.data[5])}
                                </div>
                                <LineChartJS labels={data.graphLabels} data={data.sleepWellnessGraphData?.data} title={data.sleepWellnessGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                                <LineChartJS labels={data.graphLabels}
                                    data={data.breathingQualityGraphData?.data}
                                    title={data.breathingQualityGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportWellness;
