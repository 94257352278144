import { AppContentType } from "../models/enumerations/app-content-type";
import { GroupIds } from "../models/enumerations/group-ids";
import { Group } from "../models/interfaces/group";
import { Organization } from "../models/interfaces/organization";
import { User } from "../models/interfaces/user";
import GroupService from "./services/group-service";
import OrganizationService from "./services/organization-service";

/**
 * Gets the HTML layout from app content
 * @param {string} videoUrl - Video URL
 * @param {string} headerText - Header Text
 * @param {string} appContent - App Content
 * @param {string} buttonText - Button Text
 * @param {string} buttonLink - Button Link
 * @returns {string} App Content HTML
 */
const getEmbedCode = (videoUrl: string, headerText: string, appContent: string, buttonText: string, buttonLink: string): string => {
    const videoUrlHtml = getVideoEmbedCode(videoUrl);
    const headerTextHtml = getHeaderTextCode(headerText);
    const buttonHtml = getButtonCode(buttonText, buttonLink);

    if (!appContent) {
        appContent = "";
    }

    const appContentContainer = `<div class="app-content-container">${videoUrlHtml + headerTextHtml + appContent + buttonHtml}</div>`;

    return (appContentContainer);
}

/**
 * Gets the code to embed a video
 * @param {string} videoUrl - Video URL
 * @returns {string} Embed Code
 */
const getVideoEmbedCode = (videoUrl: string | undefined | null): string => {
    let embedCode = "";
    if (videoUrl) {
        if (videoUrl.toLowerCase().includes('youtube')) {
            embedCode = `<iframe class="ql-video embedded-video" src=${parseYoutubeUrl(videoUrl)} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        }
        else if (videoUrl.toLowerCase().includes('vimeo')) {
            embedCode = `<iframe class="ql-video embedded-video" src="${parseVimeoUrl(videoUrl)}" frameborder="0" allowfullscreen></iframe>`;
        }
        else if (videoUrl.toLowerCase().includes('wistia')) {
            embedCode = `<iframe class="ql-video embedded-video" src="${parseWistiaUrl(videoUrl)}" frameborder="0" allowfullscreen></iframe>`;
        }
    }

    return embedCode;
};

/**
 * Gets the code to add header text
 * @param {string} headerText - Header Text
 * @returns {string} H1 Tag
 */
const getHeaderTextCode = (headerText: string): string => {
    let headerHtml = "";

    if (headerText) {
        headerHtml = `<h1 class="app-content-header">${headerText}</h1>`
    }

    return headerHtml;
};

const getButtonCode = (buttonText: string, buttonLink: string): string => {
    let buttonHtml = "";

    if (buttonText && buttonLink) {
        buttonHtml = `<div class="flex justify-center"><a href="${buttonLink}" class="app-content-button" target="_blank">${buttonText}</a></div>`
    }

    return buttonHtml;
};

function parseYoutubeUrl(videoUrl: string) {
    const videoId = /^https?:\/\/(www\.)?youtu\.be/.test(videoUrl) ? videoUrl.replace(/^https?:\/\/(www\.)?youtu\.be\/([\w-]{11}).*/, "$2") : videoUrl.replace(/.*\?v=([\w-]{11}).*/, "$1");

    return `https://www.youtube.com/embed/${videoId}`;
};

function parseVimeoUrl(videoUrl: string) {
    var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    var parsed = videoUrl.match(vimeoRegex) || "";

    return `https://player.vimeo.com/video/${parsed[1]}`;
};

function parseWistiaUrl(videoUrl: string) {
    const videoId = videoUrl.split('medias/')[1];

    return `https://fast.wistia.net/embed/iframe/${videoId}`;
};

function getAppContentFromOrgOrGroup(orgOrGroup: Organization | Group | null, type: AppContentType) {
    if (!orgOrGroup) return "";

    const videoUrlToEmbed = orgOrGroup.appContent ? orgOrGroup.appContent[type].videoUrl : "";
    const headerTextToEmbed = orgOrGroup.appContent ? orgOrGroup.appContent[type].headerText : "";
    const appContentToEmbed = orgOrGroup.appContent ? orgOrGroup.appContent[type].content : "";
    const buttonTextToEmbed = orgOrGroup.appContent ? orgOrGroup.appContent[type].buttonText : "";
    const buttonLinkToEmbed = orgOrGroup.appContent ? orgOrGroup.appContent[type].buttonLink : "";

    const embedHtml = AppContentUtil.getEmbedCode(videoUrlToEmbed, headerTextToEmbed, appContentToEmbed, buttonTextToEmbed, buttonLinkToEmbed);

    return embedHtml;
}

// function getDefaultAppContent(type: AppContentType) {
//     let videoUrlToEmbed = "";
//     let headerTextToEmbed = "";
//     let appContentToEmbed = "";
//     let buttonTextToEmbed = "";
//     let buttonLinkToEmbed = "";

//     if (type === AppContentType.PAIN) {
//         videoUrlToEmbed = "";
//         headerTextToEmbed = "Prioritizing Your Health";
//         appContentToEmbed = "<p class='ql-align-center'>Pain is more than just a nuisance; it's a signal. It's your body's way of alerting you that something needs attention. Remember, your health is your most precious asset. Let us assist you in protecting it and guide you back to feeling great.<br/><br/>Start by finding a certified healthcare provider near you.</p>";
//         buttonTextToEmbed = "Find a Certified Pro";
//         buttonLinkToEmbed = "https://www.functionalmovement.com/members";
//     }
//     else if (type === AppContentType.BEHAVIORAL_HEALTH) {
//         videoUrlToEmbed = "";
//         headerTextToEmbed = "Prioritizing Your Mental Health";
//         appContentToEmbed = "<p class='ql-align-center'>Mental health is as crucial as physical health, yet it's often harder to recognize the signs. Just like physical pain, mental distress is a signal, indicating a need for care and attention. Your mental well-being is invaluable, and we're here to support you in nurturing it.<br/><br/>To begin your journey towards improved mental health, click the button below to explore your options.</p>";
//         buttonTextToEmbed = "Find Help";
//         buttonLinkToEmbed = "https://www.symmio.com/behavioralhealth-support";
//     }

//     const embedHtml = AppContentUtil.getEmbedCode(videoUrlToEmbed, headerTextToEmbed, appContentToEmbed, buttonTextToEmbed, buttonLinkToEmbed);

//     return embedHtml;
// };

//check if Group has non-empty values in app content (except for video source)
function checkIfAppContentExists(orgOrGroup: Organization | Group | null, type: AppContentType) {
    return (
        orgOrGroup &&
        orgOrGroup.appContent &&
        Object.entries(orgOrGroup.appContent[type]).some(
            ([k, v]: [string, any]) => {
                // default React Quill string (empty)
                if (v === "<p><br></p>") {
                    return false;
                }

                if (k !== 'videoSource') {
                    return !!v;
                }

                return false;
            }
        ))
}

async function composeAppContentForDisplay(user: User | null | undefined) {
    if (!user || !user.organizationId || !user?.id) return;

    const organization = await OrganizationService.get(user.organizationId);
    const group = user.groupId ? await GroupService.get(user.groupId) : null;

    let embedHtml = "";

    const showMentalHealthCard = user.importantMessages?.some(msg => msg.groupId === GroupIds.BEHAVIORAL_HEALTH) || false;
    const showPainCard = user.importantMessages?.some(msg => msg.groupId === GroupIds.PAIN) || false;

    if (showMentalHealthCard) {
        if (checkIfAppContentExists(group, AppContentType.BEHAVIORAL_HEALTH)) {
            embedHtml = getAppContentFromOrgOrGroup(group, AppContentType.BEHAVIORAL_HEALTH);
        }
        else if (checkIfAppContentExists(organization, AppContentType.BEHAVIORAL_HEALTH)) {
            embedHtml = getAppContentFromOrgOrGroup(organization, AppContentType.BEHAVIORAL_HEALTH);
        }
        // else {
        //     embedHtml = getDefaultAppContent(AppContentType.BEHAVIORAL_HEALTH);
        // }
    }
    else if (showPainCard) {
        if (checkIfAppContentExists(group, AppContentType.PAIN)) {
            embedHtml = getAppContentFromOrgOrGroup(group, AppContentType.PAIN);
        }
        else if (checkIfAppContentExists(organization, AppContentType.PAIN)) {
            embedHtml = getAppContentFromOrgOrGroup(organization, AppContentType.PAIN);
        }
        // else {
        //     embedHtml = getDefaultAppContent(AppContentType.PAIN);
        // }
    }

    return embedHtml;
}

const AppContentUtil = {
    getEmbedCode,
    getVideoEmbedCode,
    composeAppContentForDisplay,
};

export default AppContentUtil;