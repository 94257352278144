import { StateCreator } from "zustand";
import EntityNotFoundException from "../models/exceptions/entity-not-found";
import { DropdownSelectOption } from "../models/interfaces/dropdown-select-option";
import FirestoreCondition from "../models/interfaces/firestore-condition";
import FirestoreOrder from "../models/interfaces/firestore-order";
import { Group } from "../models/interfaces/group";
import { User } from "../models/interfaces/user";
import FirestoreService from "../utilities/services/firestore-service";
import UserUtil from "../utilities/user-util";
import { UnifiedStore, useStore } from "./useStore";

const COLLECTION_NAME = "groups";

const AscendingByName: FirestoreOrder[] = [{
    field: "name",
    direction: "asc",
}];

export interface GroupSliceState {
    groupIsLoaded: boolean;
    groups: Group[];
    groupsByUserLoaded: boolean;
    groupsByUser: Group[];
    groupsByOrg: Group[];
    groupsByOrgLoaded: boolean;
    groupsDropdownSelectOption: DropdownSelectOption[]
}

const initialState = {
    groupIsLoaded: false,
    groups: [],
    groupsByUserLoaded: false,
    groupsByUser: [],
    groupsByOrg: [],
    groupsByOrgLoaded: false,
    groupsDropdownSelectOption: []
}

export interface GroupSlice extends GroupSliceState {
    getGroupsByOrg: (orgId: string) => Promise<Group[]>;

    /**
     * Gets all groups that the User has authority to manage.
     * @returns {Group[]}
     */
    getGroupsByUser: (user: User) => Promise<Group[]>;

    groupGetById: (user: User, id: string) => Promise<Group>;

    getGroupsDropDownSelectOptions: (user: User) => Promise<DropdownSelectOption[]>;

    getGroupsDropDownSelectOptionsByOrg: (orgId: string) => Promise<DropdownSelectOption[]>

    markGroupsStale: () => Promise<void>;
}

export const createGroupSlice: StateCreator<
    UnifiedStore,
    [],
    [],
    GroupSlice
> = (set, get) => ({
    ...initialState,

    get: async (): Promise<Group[]> => {
        // const { groupIsLoaded, groups } = get();

        // if (groupIsLoaded) {
        //     return groups;
        // }

        const firestoreGroups = await FirestoreService.getAll<Group>(
            COLLECTION_NAME
        );

        set({
            groups: firestoreGroups,
            // groupIsLoaded: true
        });

        return firestoreGroups;
    },

    getGroupsByUser: async (user: User): Promise<Group[]> => {
        // const { groupsByUserLoaded, groupsByUser } = get();
        const orgId = useStore.getState().organization?.id;

        // if (groupsByUserLoaded) {
        //     return groupsByUser;
        // }

        if (!orgId) {
            throw Error("A organization is necessary to use the getGroupsByUser call");
        }

        let conditions: FirestoreCondition[] = [
            {
                field: "organizationId",
                operator: "==",
                value: orgId
            }
        ];

        if (UserUtil.isManager(user)) {
            const groupIds = await UserUtil.getHierarchicalGroupIds(user.managedGroupIds);
            conditions.push({
                field: "id",
                operator: "in",
                value: groupIds,
            });
        }

        const firestoreGroups = await FirestoreService.getBy<Group>(
            COLLECTION_NAME,
            conditions,
            AscendingByName
        );

        set({
            groupsByUser: firestoreGroups,
            // groupsByUserLoaded: true
        });

        return firestoreGroups;
    },

    getGroupsByOrg: async (orgId: string): Promise<Group[]> => {
        // const { groupsByOrgLoaded, groupsByOrg } = get();

        // if (groupsByOrgLoaded) {
        //     return groupsByOrg;
        // }

        let conditions: FirestoreCondition[] = [
            {
                field: "organizationId",
                operator: "==",
                value: orgId
            }
        ];

        const firestoreGroups = await FirestoreService.getBy<Group>(
            COLLECTION_NAME,
            conditions,
            AscendingByName
        );

        set({
            groupsByOrg: firestoreGroups,
            // groupsByOrgLoaded: true
        });

        return firestoreGroups;
    },

    groupGetById: async (user: User, id: string): Promise<Group> => {
        // const { groupsByUserLoaded, getGroupsByUser } = get();
        let { groupsByUser } = get();

        // if (!groupsByUserLoaded) {
        //     groupsByUser = await getGroupsByUser(user);
        // }

        const entity = groupsByUser.find(x => x.id === id);

        if (!entity) {
            throw EntityNotFoundException;
        }

        return entity;
    },

    // Retrieves all groups as Drop Down Select Options
    getGroupsDropDownSelectOptions: async (user: User): Promise<DropdownSelectOption[]> => {
        const {
            // groupsDropdownSelectOption,
            // groupIsLoaded,
            getGroupsByUser
        } = get();

        // if (groupIsLoaded) {
        //     return groupsDropdownSelectOption;
        // }

        const returnValue = (await getGroupsByUser(user))
            .map((group): DropdownSelectOption => ({
                label: group.name, value: group.id
            }));

        set({
            groupsDropdownSelectOption: returnValue
        });

        return returnValue;
    },

    getGroupsDropDownSelectOptionsByOrg: async (orgId: string): Promise<DropdownSelectOption[]> => {
        const {
            // groupsDropdownSelectOption,
            // groupIsLoaded,
            getGroupsByOrg
        } = get();

        // if (groupIsLoaded) {
        //     return groupsDropdownSelectOption;
        // }

        const returnValue = (await getGroupsByOrg(orgId))
            .map((group): DropdownSelectOption => ({
                label: group.name, value: group.id
            }));

        set({
            groupsDropdownSelectOption: returnValue
        });

        return returnValue;
    },

    markGroupsStale: async (): Promise<void> => set({
        ...initialState
    })
});
