import React from "react";
import { TextTypeInputProps } from "./text-type-input.props";
import "./text-type-input.styles.scss";

export const TextTypeInput: React.FC<TextTypeInputProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let inputAttributes = {
        id: sanitizedId,
        placeholder: props.label,
        type: props.type ?? "text"
    }

    if (props.readonly) {
        inputAttributes = {
            ...inputAttributes,
            ...{
                readOnly: true,
                tabIndex: -1
            }
        }
    }

    // wire up our input to react-hook-form
    // https://react-hook-form.com/get-started
    if (props.registerHook) {
        if (props.registerOptions) {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId, props.registerOptions),
                ...(props.registerOptions.maxLength && { maxLength: props.registerOptions.maxLength }),
                ...(props.registerOptions.value && { value: props.registerOptions.value })
            };
        } else {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId)
            };
        }
    }

    return (
        <div className={`text-type-container ${props.className}`}>
            {(props.label && !props.hideLabel) &&
                <label htmlFor={sanitizedId}>
                    {props.label}
                </label>
            }
            {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
            <input
                {...inputAttributes}
                className={`${props.errorState && "field-error"} ${props.inputClassName}`}
                aria-invalid={props.errorState ? "true" : "false"}
                data-testid="input-test"
                style={props.style} />
        </div>
    )
};
