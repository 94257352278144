import { useState } from 'react';
import { useForm } from "react-hook-form";
import { EmailTypeInput } from "../../forms/email-type-input";
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
import BaseCardLayout from "../../base-card-layout/base-card-layout";
import BaseCardLayoutActions from "../../base-card-layout/base-card-layout-actions";
import ErrorIcon from '@mui/icons-material/Error';

const COMPONENT_CLASS = "p-reset-password";

export interface ResetPasswordProps {
    onReset: Function,
    onCancelReset: Function,
}

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const { onReset, onCancelReset } = props;
    const [, setSubmitting] = useState<boolean>(false);
    const [invalidEmailError, setInvalidEmailError] = useState<boolean>(false);
    // const formRef = useRef();

    const onSubmit = async (data: any) => {
        setSubmitting(true);
        const auth = getAuth();
        const email = data.email;
        sendPasswordResetEmail(auth, email)
            .then((r) => {
                reset();
                setSubmitting(false);
                onReset();
            })
            .catch((error) => {
                console.log(error);
                setInvalidEmailError(true);
                setSubmitting(false);
            });
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ height: "550px" }}>
                <div className={`${COMPONENT_CLASS}`}>
                    {
                        // if
                        invalidEmailError && (
                            <div className={`${COMPONENT_CLASS}__email-error`}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <ErrorIcon color="error"></ErrorIcon>
                                    <h4 style={{
                                        paddingTop: "3px",
                                        marginLeft: "5px"
                                    }}>Invalid Email Address</h4>
                                </div>
                                <p>Make sure you’re using the email address you were invited to Symmio with. If you haven’t receive an invitation yet, contact your administrator to request access to Symmio.</p>
                            </div>
                        )
                    }
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <form className={"login-form"}
                            onSubmit={handleSubmit(onSubmit)}>
                            <p className={`${COMPONENT_CLASS}__title`}>Forgot your password, huh?</p>

                            <p className={`${COMPONENT_CLASS}__subtitle`}>No worries. Just enter your email and follow the instructions to create a new one.</p>
                            <div className={`${COMPONENT_CLASS}__field-container`}>
                                <EmailTypeInput
                                    id="email"
                                    label="Email"
                                    hideLabel
                                    registerHook={register}
                                    errorState={errors.email}
                                    noMatchEmail={invalidEmailError} />
                            </div>
                            <div {...register("formErrors")}>
                                {errors.formErrors &&
                                    <span className="error-style" role="alert">
                                        {`${errors.formErrors.message}`}
                                    </span>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "1.8%", width: "100%" }}>
                <BaseCardLayoutActions backButton={true} submitButton={true} onSubmit={handleSubmit(onSubmit)} onBack={() => { onCancelReset() }} submitButtonText="Reset Password"></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
};

export default ResetPassword;
