import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { AddOnRemoval } from "../../models/interfaces/add-on-removal";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "addOnRemovals";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new AddOnRemoval to the AddOnRemovals collection
 * @param {AddOnRemoval} AddOnRemoval - The AddOnRemoval that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<AddOnRemoval>} A promise to the newly added AddOnRemoval
 */
const add = async (AddOnRemoval: AddOnRemoval, currentUser: User | null = null) => {
    return FirestoreService.add<AddOnRemoval>(COLLECTION_NAME, AddOnRemoval, currentUser);
};

/**
 * Delete a AddOnRemoval collection by the Id
 * @param {string} id - The Id of the AddOnRemoval being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific AddOnRemoval by the id
 * @param {string} id - The Id of the AddOnRemoval that we are retrieving
 * @returns {Promise<AddOnRemoval>} A promise for the AddOnRemoval we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<AddOnRemoval>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<AddOnRemoval>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<AddOnRemoval>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the AddOnRemovals stored in the database
 * @returns {Promise<AddOnRemoval[]>} A promise for the collection of AddOnRemovals
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<AddOnRemoval>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific AddOnRemoval to see when it changes
 * @param {string} id - Id of the AddOnRemoval document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<AddOnRemoval>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the AddOnRemovals to see when it changes
 * @param {string} id - Id of the AddOnRemoval document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<AddOnRemoval>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified AddOnRemoval in the database
 * @param {AddOnRemoval} AddOnRemoval - The AddOnRemoval that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<AddOnRemoval>} A promise for the AddOnRemoval that is being updated
 */
const save = async (AddOnRemoval: AddOnRemoval, currentUser: User | null = null) => {
    return FirestoreService.save<AddOnRemoval>(COLLECTION_NAME, AddOnRemoval, currentUser);
};

/**
 * Update the specified AddOnRemoval stored in the database
 * @param {AddOnRemoval} AddOnRemoval - The AddOnRemoval that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<AddOnRemoval>} A promise for the AddOnRemoval that is being updated
 */
const update = async (AddOnRemoval: AddOnRemoval, currentUser: User | null = null) => {
    return FirestoreService.update<AddOnRemoval>(COLLECTION_NAME, AddOnRemoval, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const AddOnRemovalsService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default AddOnRemovalsService;

// #endregion Exports