import React from "react";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DialogTitleProps {
    text: string,
    onClose: Function,
    hideButton?: boolean,
}

/**
 * A select form control that provides a drop down of
 * label value pairs of which one is selectable.
 */
export const DialogBoxTitle: React.FC<DialogTitleProps> = (props) => {
    const {
        text,
        onClose,
        hideButton,
    } = props;

    const handleClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        onClose();
    };

    return (
        <DialogTitle
            id="dialogTitle"
            sx={{
                fontFamily: 'pragmatica-extended',
                fontSize: '36px',
                fontWeight: 300,
                lineHeight: '22px',
                paddingBottom: '40px',
                paddingTop: '26px',
            }}
        >
            {text}
            {
                (onClose && !hideButton) ? (
                    <IconButton
                        onClick={handleClose}
                        size="large"
                        sx={{
                            position: 'absolute',
                            right: 28,
                            top: 25,
                        }}
                    >
                        <CloseIcon
                            sx={{ width: '28px', height: '28px' }}
                        />
                    </IconButton>
                ) : null
            }
        </DialogTitle>
    );
};
