import FirestoreServiceAssessments from "../firestore-service-assessments";
import { IUser } from "../../../models/interfaces/assessment-user";
import { ArticlesGroup } from "../../../models/interfaces/articles/articles-group";
import FirestoreCondition from "../../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../../models/interfaces/firestore-order";

const COLLECTION_NAME = "articlesGroups";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Updates an existing record, or adds a record
 * @param {ArticlesGroup} articlesGroup - The score that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<ArticlesGroup>} A promise to the newly added record
 */
const addOrUpdate = async (
    articlesGroup: ArticlesGroup,
    currentUser?: IUser
): Promise<ArticlesGroup> => {
    if (articlesGroup?.groupId == null || articlesGroup.articles == null) {
        throw new Error("Inputs were invalid");
    }

    const existingArticleGroup = await getForGroupId(articlesGroup.groupId);

    // add item
    if (existingArticleGroup == null) {
        return FirestoreServiceAssessments.add<ArticlesGroup>(
            COLLECTION_NAME,
            articlesGroup,
            currentUser
        );
    }

    // update item
    existingArticleGroup.articles = articlesGroup.articles;
    existingArticleGroup.expirationMs = articlesGroup.expirationMs;
    return FirestoreServiceAssessments.update<ArticlesGroup>(
        COLLECTION_NAME,
        existingArticleGroup,
        currentUser
    );
};

/**
 * Find the specific record based on the group id
 * @param {string} groupId - The Id of the group record that we are retrieving
 * @returns {Promise<ArticlesGroup>} A promise for the record we are retrieving
 */
const getForGroupId = async (
    groupId: string
): Promise<ArticlesGroup | null> => {
    const conditions: FirestoreCondition[] = [
        {
            field: "groupId",
            operator: "==",
            value: groupId,
        },
    ];
    const order: FirestoreOrder[] = [
        {
            field: "expirationMs",
            direction: "desc",
        },
    ];

    // load item
    const articlesGroups = await FirestoreServiceAssessments.getBy<ArticlesGroup>(
        COLLECTION_NAME,
        conditions,
        order
    );
    const articlesGroup = articlesGroups[0];
    if (articlesGroup == null) {
        return null;
    }

    // delete extra items (they shouldn't exist)
    for (let i = 1; i < articlesGroups.length; i++) {
        FirestoreServiceAssessments.deleteById(COLLECTION_NAME, articlesGroups[i].id!);
    }

    return articlesGroup;
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ArticlesGroupService = {
    addOrUpdate,
    getForGroupId,
};

export default ArticlesGroupService;

// #endregion Exports
