const COMPONENT_CLASS = "c-portal-progress-bar";

export interface PortalProgressBarProps {
  total: number;
  currentProgress: number;
  headerText: string;
  link?: string;
  linkText?: string
}

const PortalProgressBar: React.FC<PortalProgressBarProps> = (props) => {
  const {
    total,
    currentProgress,
    headerText,
    link,
    linkText,
  } = props;

  const calculatePercentage = () => {
    return (currentProgress / total) * 100 > 100 ? 100 : (currentProgress / total) * 100;
  };


  const getProgressBarColor = () => {
    var percentage = calculatePercentage();
    if (percentage >= 0 && percentage < 75) {
      return '#00DFBC';
    } else if (percentage >= 75 && percentage < 89) {
      return '#FFC79C ';
    } else if (percentage >= 90) {
      return '#E7514F';
    }
    return "white";
  }


  const progressBarStyle: React.CSSProperties = {
    width: `${calculatePercentage()}%`,
    backgroundColor: getProgressBarColor(),
  };

  return (
    <div className={`${COMPONENT_CLASS}__box`}>
      <div className={`${COMPONENT_CLASS}__header-wrapper`}>
        <p className={`${COMPONENT_CLASS}__header`}>{headerText}</p>
        {link && linkText &&
          <a className={`${COMPONENT_CLASS}__link`} href={link} target="_blank" rel="noreferrer">{linkText}</a>
        }
      </div>
      <div className={`${COMPONENT_CLASS}__container`}>
        <div className={`${COMPONENT_CLASS}__progress`} style={progressBarStyle}></div>
      </div>
    </div>
  );
};

export default PortalProgressBar;
