import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/button/button";
import EmailList from "../../components/email-list/email-list";
import { Loader } from "../../components/loader/loader";
import RichTextEditor from "../../components/rich-text-editor/rich-text-editor";
import { Tag } from "../../components/tag/tag";
import { Group } from "../../models/interfaces/group";
import { User } from "../../models/interfaces/user";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import GroupService from "../../utilities/services/group-service";
import UserService from "../../utilities/services/user-service";
import StringUtil from "../../utilities/string-util";
import { Utils } from "../../utilities/utils";
import { enqueueSnackbar } from "notistack";
import { AppContentType } from "../../models/enumerations/app-content-type"
import { AppContent } from "../../models/interfaces/app-content";
import UserUtil from "../../utilities/user-util";
import { TextTypeInput } from "../../components/forms";
import { useForm } from "react-hook-form";

interface GroupEditPageParams {
    organizationId: string;
    id: string;
}

const GroupEditPage: React.FC = () => {
    const COMPONENT_CLASS = "p-group-edit";
    const history = useHistory();
    const { organizationId, id } = useParams<GroupEditPageParams>();
    const [isNewGroup, setIsNewGroup] = useState(id === undefined);
    const [isLoading, setIsLoading] = useState((isNewGroup ? false : true));
    const [group, setGroup] = useState<Group>({
        organizationId: organizationId,
        id: id
    } as Group);
    const [initialGroup, setInitialGroup] = useState<Group>({
        organizationId: organizationId,
        id: id
    } as Group);
    const [users, setUsers] = useState<User[]>({} as User[]);
    const [pendingGroupEmails, setPendingGroupEmails] = useState([] as string[]);
    const [emailErrorMessages, setEmailErrorMessages] = useState([] as string[]);
    const [nameErrorMessages, setNameErrorMessages] = useState([] as string[]);
    const { state, setState } = useAuthState();

    const [isEditingContact, setIsEditingContact] = useState<boolean>(false);
    const [appContentType, setAppContentType] = useState<AppContentType>(AppContentType.PAIN);

    const [appContentPainContent, setAppContentPainContent] = useState<string>("");
    const [appContentPainVideoUrl, setAppContentPainVideoUrl] = useState<string>("");
    const [appContentPainHeaderText, setAppContentPainHeaderText] = useState<string>("");
    const [appContentPainVideoSource, setAppContentPainVideoSource] = useState<string>("");
    const [appContentPainButtonText, setAppContentPainButtonText] = useState<string>("");
    const [appContentPainButtonLink, setAppContentPainButtonLink] = useState<string>("");

    const [appContentBehavioralContent, setAppContentBehavioralContent] = useState<string>("");
    const [appContentBehavioralVideoUrl, setAppContentBehavioralVideoUrl] = useState<string>("");
    const [appContentBehavioralHeaderText, setAppContentBehavioralHeaderText] = useState<string>("");
    const [appContentBehavioralVideoSource, setAppContentBehavioralVideoSource] = useState<string>("");
    const [appContentBehavioralButtonText, setAppContentBehavioralButtonText] = useState<string>("");
    const [appContentBehavioralButtonLink, setAppContentBehavioralButtonLink] = useState<string>("");

    const methods = useForm<any>();

    const {
        register,
    } = methods;

    const [activeTab, setActiveTab] = useState<string>("Group Details");
    const tabs = [
        "Group Details",
        (isNewGroup ? null : "Customization"),
    ]
    const labelRefs: { [key: string]: React.RefObject<HTMLLabelElement | null> } = {};

    useEffect(() => {
        const loadGroup = async () => {
            GroupService.get(id)
                .then((groupEntity) => {
                    if (groupEntity) {
                        setGroup(groupEntity);
                        setInitialGroup(groupEntity);
                        loadUsers()
                            .then(() => setIsLoading(false));
                    }
                })
        };

        const loadUsers = async () => {
            UserService.getBy([{
                field: "groupId",
                operator: "==",
                value: id,
            }]).then((groupUsers: User[]) => {
                setUsers(groupUsers);
            })
        }

        if (id) {
            loadGroup();
        }
    }, [id]);

    useEffect(() => {
        setDefaultAppContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group]);

    const setDefaultAppContent = () => {
        const appContentPain = group?.appContent ? group?.appContent[AppContentType.PAIN] : undefined;
        const appContentBehavioral = group?.appContent ? group?.appContent[AppContentType.BEHAVIORAL_HEALTH] : undefined;

        setAppContentPainContent(appContentPain ? appContentPain.content : "");
        setAppContentPainVideoUrl(appContentPain ? appContentPain.videoUrl : "");
        setAppContentPainHeaderText(appContentPain ? appContentPain.headerText : "");
        setAppContentPainVideoSource(appContentPain ? appContentPain.videoSource : "");
        setAppContentPainButtonText(appContentPain ? appContentPain.buttonText : "");
        setAppContentPainButtonLink(appContentPain ? appContentPain.buttonLink : "");

        setAppContentBehavioralContent(appContentBehavioral ? appContentBehavioral.content : "");
        setAppContentBehavioralVideoUrl(appContentBehavioral ? appContentBehavioral.videoUrl : "");
        setAppContentBehavioralHeaderText(appContentBehavioral ? appContentBehavioral.headerText : "");
        setAppContentBehavioralVideoSource(appContentBehavioral ? appContentBehavioral.videoSource : "");
        setAppContentBehavioralButtonText(appContentBehavioral ? appContentBehavioral.buttonText : "");
        setAppContentBehavioralButtonLink(appContentBehavioral ? appContentBehavioral.buttonLink : "");
    };

    const handleChange = (fieldName: keyof Group) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const target = event.currentTarget;

            setGroup({
                ...group,
                [fieldName]: target.value
            } as Group);
        };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        let errorFlag = false;

        // TODO : reactive validation
        if (!group.name || group.name.length === 0) {
            setNameErrorMessages(['No Name Provided']);
            errorFlag = true;
        }

        const createdGroup = await GroupService.save({
            ...group,
        }, state.user);

        if (pendingGroupEmails != null && pendingGroupEmails.length > 0) {
            const emails = pendingGroupEmails.filter((email) => email.trim().length > 0 && Utils.validateEmailAddress(email.trim()));

            for (const email of emails) {
                const user = await UserService.findFirstBy([{
                    field: "email",
                    operator: "==",
                    value: email,
                }]);

                if (user == null) {
                    continue;
                }

                await UserService.update({
                    ...user,
                    groupId: createdGroup.id,
                    groupName: createdGroup.name
                }, state.user);
            }
        }

        if (state.user && UserUtil.isManager(state.user)) {
            let updatedManager = state.user;

            if (state.user?.managedGroupIds && state.user?.managedGroupIds.length > 0) {
                if (!state.user?.managedGroupIds.includes(createdGroup.id)) {
                    updatedManager.managedGroupIds?.push(createdGroup.id);
                }
            }
            else {
                updatedManager.managedGroupIds = [createdGroup.id];
            }

            updatedManager = await UserService.update(updatedManager);
            setState((state) => ({
                ...state, ...{ user: updatedManager }
            }));
        }

        if (errorFlag) {
            return;
        } else {
            if (isNewGroup) {
                enqueueSnackbar("Group Created!", { variant: "toast", width: "450px" });
                setGroup(createdGroup);
                setIsNewGroup(false);
            }
            else {
                enqueueSnackbar("Group Updated!", { variant: "toast", width: "450px" });
            }

            history.push("/groups");
            setIsEditingContact(false);
        }
    };

    const handleSubmitRecommendations = async (event: React.FormEvent) => {
        event.preventDefault();

        const updatedGroup = await GroupService.save({
            ...initialGroup,
            appContent: {
                [AppContentType.PAIN]: {
                    content: appContentPainContent,
                    videoUrl: appContentPainVideoUrl,
                    videoSource: appContentPainVideoSource,
                    headerText: appContentPainHeaderText,
                    buttonText: appContentPainButtonText,
                    buttonLink: appContentPainButtonLink,
                } as AppContent,
                [AppContentType.BEHAVIORAL_HEALTH]: {
                    content: appContentBehavioralContent,
                    videoUrl: appContentBehavioralVideoUrl,
                    videoSource: appContentBehavioralVideoSource,
                    headerText: appContentBehavioralHeaderText,
                    buttonText: appContentBehavioralButtonText,
                    buttonLink: appContentBehavioralButtonLink,
                } as AppContent,
            }
        }, state.user);

        setGroup({
            ...group,
            appContent: updatedGroup.appContent,
        })
        enqueueSnackbar("High-Risk Recommendations Updated!", { variant: "toast", width: "450px" });
        setIsEditingContact(false);
    };

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    }
    console.log(group.name)
    return (
        <>
            <h2>{(isNewGroup) ? "Add" : "Edit"} Group</h2>
            {!isEditingContact &&
                <div className={`${COMPONENT_CLASS}__tabs-container`}>
                    <div className={`${COMPONENT_CLASS}__tabs`}>
                        {tabs.map((tab) => (
                            typeof tab === "string" &&
                            (<div
                                key={tab}
                                className={`${COMPONENT_CLASS}__tab-label ${activeTab === tab ? 'active' : ""}`}
                                onClick={() => handleTabClick(tab)}
                            > <label ref={(ref) => (labelRefs[tab] = { current: ref })} id={tab}>{tab}</label> </div>)
                        ))}
                    </div>
                </div>
            }

            <form className={COMPONENT_CLASS} onSubmit={handleSubmit}>
                <Loader isVisible={isLoading} />

                {activeTab === "Group Details" &&
                    <>
                        <div className={nameErrorMessages.length > 0 ? `${COMPONENT_CLASS}__fieldgroup-error` : `${COMPONENT_CLASS}__fieldgroup`}>
                            {/*Need to change to TextTypeInput*/}
                            <TextTypeInput
                                id='name'
                                type="text"
                                label="Name"
                                registerHook={register}
                                registerOptions={{
                                    value: group?.name,
                                    onChange: handleChange("name"),
                                }} />
                        </div>
                        { // if
                            nameErrorMessages.length > 0 &&
                            <div className={`${COMPONENT_CLASS}__email-errors`}>
                                {nameErrorMessages.map((error, i) => <div className={`${COMPONENT_CLASS}__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                            </div>
                        }


                        <div className={emailErrorMessages.length > 0 ? `${COMPONENT_CLASS}__fieldgroup-error` : `${COMPONENT_CLASS}__fieldgroup`}>
                            <label className="text-black">Add Users To This Group</label>
                            <EmailList
                                existingUsers={users && users.length > 0 ? users.filter(user => user.id != null).map(user => user.id!) : undefined}
                                onEmailsAdded={(emails) => {
                                    emails = StringUtil.getUniqueValues(emails, true);

                                    let pendingEmails = [...new Set(pendingGroupEmails.concat(emails))];
                                    if (users && users.length > 0) {
                                        const groupEmails = users.map(user => user.email).filter(email => email && email.trim().length > 0);
                                        pendingEmails = pendingEmails.filter(email => !StringUtil.existsInArray(email, groupEmails, true));
                                    }

                                    setPendingGroupEmails(pendingEmails);
                                }}
                                onEmailsError={(errors) => { emailErrorMessages.length = 0; emailErrorMessages.push(...new Set(emailErrorMessages.concat(errors))); setEmailErrorMessages(emailErrorMessages); }}
                                enableSuggestions
                            />
                        </div>
                        { // if
                            emailErrorMessages.length > 0 &&
                            <div className={`${COMPONENT_CLASS}__email-errors`}>
                                {emailErrorMessages.map((error, i) => <div className={`${COMPONENT_CLASS}__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                            </div>
                        }
                        <div className="mb-1">
                            {pendingGroupEmails != null && pendingGroupEmails.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__email-invites`}>
                                    <div>
                                        {pendingGroupEmails.map((email, i) => (<Tag
                                            id={i.toString()}
                                            onCloseClick={(index) => {
                                                const emails = [...pendingGroupEmails];
                                                emails.splice(parseInt(index), 1);
                                                setPendingGroupEmails(emails);
                                            }}
                                            key={`email-${i}`}
                                        >{email}</Tag>))}
                                    </div>
                                </div>
                            }
                        </div>

                        {(group?.userCount || 0) > 0 &&
                            <div className={`${COMPONENT_CLASS}__table-container`}>
                                <label>Users In Group</label>
                                <table
                                    className="light"
                                    cellSpacing={0}
                                    cellPadding={0}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Roles</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.length > 0 &&
                                            users.map((user, index) => {
                                                return (
                                                    <tr key={index} onClick={() => history.push(`/users/${user.id}`)}>
                                                        <td>{`${user.firstName} ${user.lastName}`}</td>
                                                        <td>
                                                            {(user.roles) &&
                                                                user.roles.map((role, index) => (
                                                                    <Tag key={index} id={index.toString()}>{
                                                                        Utils.getRoleNameFromValue(role)}
                                                                    </Tag>
                                                                ))
                                                            }
                                                        </td>
                                                        <td>{user.email}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <Button
                                type="default"
                                buttonStyle="white"
                                buttonText="Cancel"
                                onClick={(event) => {
                                    event.preventDefault();
                                    history.push("/groups");
                                }} />
                            <Button
                                type="default"
                                buttonText={`${isNewGroup ? 'Add Group' : 'Save Changes'}`}
                                onClick={(event) => handleSubmit(event)} />
                        </div>
                    </>
                }

                {activeTab === "Customization" &&
                    <>
                        <h6>High-Risk Recommendations</h6>
                        <div className={`${COMPONENT_CLASS}__secondary`}>
                            <div className={`${COMPONENT_CLASS}__disclaimer`}>
                                <p>
                                    The Group-Specific recommendations allows you to manage health risks at the group level. This flexible tool allows you to provide essential information and advice tailored to the needs of specific groups within your organization, focusing on those who require individualized  care to reduce their health risk.
                                </p>
                            </div>
                        </div>
                        <div>
                            <RichTextEditor
                                appContentType={appContentType}
                                setAppContentType={setAppContentType}

                                appContentPainContent={appContentPainContent}
                                appContentPainVideoUrl={appContentPainVideoUrl}
                                appContentPainHeaderText={appContentPainHeaderText}
                                appContentPainVideoSource={appContentPainVideoSource}
                                appContentPainButtonText={appContentPainButtonText}
                                appContentPainButtonLink={appContentPainButtonLink}

                                appContentBehavioralContent={appContentBehavioralContent}
                                appContentBehavioralVideoUrl={appContentBehavioralVideoUrl}
                                appContentBehavioralHeaderText={appContentBehavioralHeaderText}
                                appContentBehavioralVideoSource={appContentBehavioralVideoSource}
                                appContentBehavioralButtonText={appContentBehavioralButtonText}
                                appContentBehavioralButtonLink={appContentBehavioralButtonLink}

                                setAppContentPainContent={setAppContentPainContent}
                                setAppContentPainVideoUrl={setAppContentPainVideoUrl}
                                setAppContentPainHeaderText={setAppContentPainHeaderText}
                                setAppContentPainVideoSource={setAppContentPainVideoSource}
                                setAppContentPainButtonText={setAppContentPainButtonText}
                                setAppContentPainButtonLink={setAppContentPainButtonLink}

                                setAppContentBehavioralContent={setAppContentBehavioralContent}
                                setAppContentBehavioralVideoUrl={setAppContentBehavioralVideoUrl}
                                setAppContentBehavioralHeaderText={setAppContentBehavioralHeaderText}
                                setAppContentBehavioralVideoSource={setAppContentBehavioralVideoSource}
                                setAppContentBehavioralButtonText={setAppContentBehavioralButtonText}
                                setAppContentBehavioralButtonLink={setAppContentBehavioralButtonLink}

                                includeTextAlignment={true}
                                showEdit={true}
                                showPreview={false}
                                isEditingContact={isEditingContact}
                                setIsEditingContact={setIsEditingContact}
                            />
                        </div>
                        {isEditingContact &&
                            <div className={`${COMPONENT_CLASS}__actions`}>
                                <Button
                                    type="default"
                                    buttonStyle="white"
                                    buttonText="Cancel"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setIsEditingContact(false);
                                        setDefaultAppContent();
                                    }} />
                                <Button
                                    type="default"
                                    buttonText='Save Recommendations'
                                    onClick={(event) => handleSubmitRecommendations(event)} />
                            </div>
                        }
                    </>
                }
            </form >
        </>
    );
}

export default GroupEditPage;
