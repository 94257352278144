import { useParams } from "react-router";
import UserProfile from "../../components/user-profile/user-profile";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfilePageProps { }

interface UserProfilePageParams {
    id: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfilePage: React.FC<UserProfilePageProps> = (props: UserProfilePageProps) => {
    const { id } = useParams<UserProfilePageParams>();

    return (
        <UserProfile
            userId={id} />
    );
}

export default UserProfilePage;