import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";

const COLLECTION_NAME = "organizationSubscriptions";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new organization to the organizations collection
 * @param {OrganizationSubscription} orgSub - The organization that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<OrganizationSubscription>} A promise to the newly added organization
 */
const add = async (orgSub: OrganizationSubscription, currentUser: User | null = null) => {
    return FirestoreService.add<OrganizationSubscription>(COLLECTION_NAME, orgSub, currentUser);
};

/**
 * Delete a organization collection by the Id
 * @param {string} id - The Id of the organization being deleted
 */
const deleteById = async (id: string) => {
    return FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific organization by the id
 * @param {string} id - The Id of the organization that we are retrieving
 * @returns {Promise<OrganizationSubscription>} A promise for the Organization we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<OrganizationSubscription>(COLLECTION_NAME, id);
};

/**
 * Get all organizations by the specified conditions
 * @param {FirestoreCondition[]} conditions - The conditions
 * @param {FirestoreOrder[]} order - The order
 * @param {number} limit - The number of results to limit by
 * @returns {Promise<OrganizationSubscription>} A promise for the ORganizations we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<OrganizationSubscription>(COLLECTION_NAME, conditions, order, limit);
};

const getByOrganizationId = async(orgId: string) => {
    return getBy(
        [{
            field: 'organizationId',
            operator: '==',
            value: orgId,
        }],
        [],
        1
    );
}

/**
 * Get all of the organizations stored in the database
 * @returns {Promise<OrganizationSubscription[]>} A promise for the collection of Organizations
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<OrganizationSubscription>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific organization to see when it changes
 * @param {string} id - Id of the organization document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) =>  {
    return FirestoreService.getSnapshot<OrganizationSubscription>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the organizations to see when it changes
 * @param {string} id - Id of the organization document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<OrganizationSubscription>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified organization in the database
 * @param {OrganizationSubscription} orgSub - The organization that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<OrganizationSubscription>} A promise for the organization that is being updated
 */
const save = async (orgSub: OrganizationSubscription, currentUser: User | null = null) => {
    return FirestoreService.save<OrganizationSubscription>(COLLECTION_NAME, orgSub, currentUser);
};

/**
 * Update the specified organization stored in the database
 * @param {OrganizationSubscription} orgSub - The organization that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<OrganizationSubscription>} A promise for the organization that is being updated
 */
const update = async (orgSub: OrganizationSubscription, currentUser: User | null = null) => {
    return FirestoreService.update<OrganizationSubscription>(COLLECTION_NAME, orgSub, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const OrganizationSubscriptionService = {
    add,
    deleteById,
    get,
    getBy,
    getByOrganizationId,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update
};

export default OrganizationSubscriptionService;

// #endregion Exports