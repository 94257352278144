import React from "react";
import { OrganizationStatus } from "../../models/enumerations/organization-status";

interface OrganizationStatusBadgeProps {
    status?: OrganizationStatus,
    expired?: boolean,
}

/**
 * A small badge representing the status of an organization.
 */
export const OrganizationStatusBadge: React.FC<OrganizationStatusBadgeProps> = (props) => {
    const {
        expired,
        status,
    } = props;

    const commonStyles = {
        fontSize: '10px',
        lineHeight: '11px',
        width: 'fit-content',
        padding: '6px 8px',
        borderRadius: '4px'
    }

    const activeBadge = (
        <span
            style={{
                background: 'rgba(0, 223, 188, 1)',
                ...commonStyles,
            }}
        >
            ACTIVE
        </span>
    );

    const expiredBadge = (
        <span
            style={{
                background: 'rgba(231, 81, 79, 1)',
                color: 'white',
                ...commonStyles,
            }}
        >
            EXPIRED
        </span>
    );

    const deactivatedBadge = (
        <span
            style={{
                background: 'rgba(18, 22, 34, 1)',
                color: 'white',
                ...commonStyles,
            }}
        >
            DEACTIVATED
        </span>
    );

    if (status === OrganizationStatus.Deactivated) {
        return deactivatedBadge;
    }

    if (status === OrganizationStatus.Active) {
        return activeBadge;
    }

    if (status === OrganizationStatus.Expired || expired === true) {
        return expiredBadge;
    }

    return null;
};
