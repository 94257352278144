import { IUser, User } from "../models/interfaces/assessment-user";
import GlobalStateError from "../models/interfaces/global-state-error";
import DateUtil from "../utilities/date-util";
import GlobalStateUtil from "../utilities/global-state-util";
import { StateCreator } from "zustand";
import { Timestamp } from "firebase/firestore";
import UserService from "../utilities/services/user-service-assessments";
import { ActivityType } from "../models/enumerations/activity-type";
import { FirebaseUtils } from "../utilities/firebase-utils";

export interface GlobalSliceState {
    currentIdentity?: IUser;
    isLoading: boolean;
    notificationsEnabled: boolean;
    errors: GlobalStateError[];
    hasMskScore: boolean;
}

export interface GlobalSlice extends GlobalSliceState {
    clearIdentity(): void;
    setGlobalErrors(...error: GlobalStateError[]): void;
    setGlobalError(description: string, error: any, url?: string): void;
    setIdentity(identity: Partial<IUser>): void;
    setIsLoading(isLoading: boolean): void;
    setNotifications(notificationsEnabled: boolean): void;
    setHasMskScore(hasMskScore: boolean): void;
    setLastActivityCompleted(date: Date, activityType: ActivityType): void;
    resetLastActivityDate(date: Date): void;
    hydrateIdentity(): void;
}

export const createGlobalSlice: StateCreator<GlobalSliceState, [], [], GlobalSlice> = (
    set,
    get
) => ({
    hasMskScore: true,
    isLoading: true,
    errors: [],
    notificationsEnabled: false,
    // TODO : refactor identity into it's own slice ?
    clearIdentity() {
        set({
            currentIdentity: undefined,
        });
    },
    setIdentity(user: Partial<IUser> = {}) {
        set(() => {
            return { currentIdentity: new User(user) };
        });
    },
    /**
     * Sets a users Last Activity Completed with a date and determines if the day streak
     * needs to also be updated. The activity type completed and changed data is
     * persisted with firestore.
     * @param {date} date The date time of the activity completion
     * @param {ActivityType} activityType The type of activity completed
     */
    setLastActivityCompleted: async (date: Date, activityType: ActivityType) => {
        set((prev) => {
            let updatedIdentity = prev.currentIdentity;

            if (updatedIdentity === undefined) {
                throw new Error(
                    "Identity cannot be undefined when setting last activity completed date."
                );
            }

            const prevActivityCompletedDate = new Date(
                updatedIdentity.lastActivityCompleted.seconds * 1000
            );

            if (!isToday(prevActivityCompletedDate)) {
                updatedIdentity.dayStreak = ++updatedIdentity.dayStreak;
            }

            updatedIdentity.lastActivityCompleted = Timestamp.fromDate(date);

            // UserService.updateFromUserClass(updatedIdentity);

            // update firestore
            UserService.updateFields(
                updatedIdentity,
                {
                    dayStreak: updatedIdentity.dayStreak,
                    lastActivityCompleted: updatedIdentity.lastActivityCompleted
                },
                updatedIdentity
            );

            // capture activity type
            FirebaseUtils.logUserActivity(updatedIdentity.id!, activityType);

            return { currentIdentity: updatedIdentity };
        });
    },
    resetLastActivityDate: async (date: Date) => {
        set((prev) => {
            let currentIdentity = prev.currentIdentity;

            if (currentIdentity === undefined) {
                throw new Error("Current Identity cannot be null when using resetLastActivityDate");
            }

            currentIdentity.lastActivityCompleted = Timestamp.fromDate(date);

            return { currentIdentity: currentIdentity };
        });
    },

    /**
     * Updates an identity's state on app open.
     */
    hydrateIdentity() {
        const { currentIdentity } = get();

        if (!currentIdentity) {
            return;
        }
    },
    setGlobalErrors(...errors: GlobalStateError[]) {
        set((prev) => ({ errors: organizeErrors(prev.errors, errors) }));
    },
    setGlobalError(description: string, error: any, url?: string) {
        set((prev) => ({
            errors: organizeErrors(prev.errors, [
                GlobalStateUtil.createError(description, error, url),
            ]),
        }));
    },
    setIsLoading(isLoading: boolean) {
        set({
            isLoading,
        });
    },
    setNotifications(notificationsEnabled: boolean) {
        set({
            notificationsEnabled,
        });
    },
    setHasMskScore(hasMskScore: boolean) {
        set({
            hasMskScore,
        });
    },
});

export function onDeserializeGlobalSlice(state: GlobalSlice): Partial<GlobalSlice> {
    return {
        currentIdentity: new User(state.currentIdentity),
        notificationsEnabled: state.notificationsEnabled ?? false,
    };
}

export function onPartializeGlobalSlice(state: GlobalSlice): Partial<GlobalSlice> {
    return {
        currentIdentity: state.currentIdentity,
        notificationsEnabled: state.notificationsEnabled,
    };
}

export function onRehydratedGlobalSlice(state: GlobalSlice) {
    state.setIsLoading(false);
}

/*
-------------------------------------------------------------------------
Private Methods
-------------------------------------------------------------------------
*/

function organizeErrors(
    previousErrors: GlobalStateError[],
    errors: GlobalStateError[]
): GlobalStateError[] {
    const newErrors = [...previousErrors, ...errors].filter((error) => {
        // errors from last 24 hours
        return error.dateMs >= DateUtil.getCurrentMsDate() - 24 * DateUtil.HOUR_AS_MS;
    });

    return newErrors.sort(function (a, b) {
        // sort newest first
        return b.dateMs - a.dateMs;
    });
}

function isToday(date: Date): boolean {
    const today = new Date();

    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
}
