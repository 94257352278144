import React from "react";

export interface PillChartTitleProps {
    forReport?: boolean;
    text?: string;
}

const PillChartTitle: React.FC<PillChartTitleProps> = (props) => {
    const {
        forReport,
        text,
    } = props;

    let titleText = text ? text : "Score Breakdowns Across Organization";

    if (forReport) titleText = "Current Score Breakdown";

    if (forReport === true) {
        return (
            <h3
                style={{
                    textAlign: 'left',
                    marginBottom: '0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: '300',
                }}
            >
                {titleText}
            </h3>
        );
    }

    return (
        <h3
        >
            {titleText}
        </h3>
    );
};



export default PillChartTitle;