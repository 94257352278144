import { VideoSize } from "../../../models/enumerations/video-size";
import { CorrectiveExercise } from "../../../models/interfaces/corrective-exercises/corrective-exercise";
import { CorrectiveRoutineInput } from "../../../models/interfaces/corrective-exercises/corrective-routine-input";
import { CorrectiveRoutineResult } from "../../../models/interfaces/corrective-exercises/corrective-routine-result";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CorrectiveGetExerciseParams {
    routineId: string;
    day: number;
    videoSize: VideoSize;
}

interface GetByIdParams {
    id: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/corrective-exercises`;
const findRoutineEndpoint = `${baseEndpoint}/find-routine`;
const findExtraRoutineEndpoint = `${baseEndpoint}/find-extra-routine`;
const getExerciseEndpoint = `${baseEndpoint}/get-exercise`;
const defaultExpiration = 86400 * 1000; // one day in milliseconds

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CorrectiveExercisesService = {
    /**
     * Finds a routine matching input
     */
    findRoutine: ServiceBase.post<
        CorrectiveRoutineResult,
        CorrectiveRoutineInput
    >(findRoutineEndpoint),

    /**
     * Finds a routine matching input
     */
    findExtraRoutine: ServiceBase.post<
        CorrectiveRoutineResult,
        CorrectiveRoutineInput
    >(findExtraRoutineEndpoint),

    /**
     * Gets the exercise for a certain day
     */
    getExercise: ServiceBase.list<
        CorrectiveExercise,
        CorrectiveGetExerciseParams
    >(getExerciseEndpoint),

    getById: ServiceBase.get<CorrectiveExercise, GetByIdParams>(
        baseEndpoint + "/:id",
        defaultExpiration
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CorrectiveExercisesService;

// #endregion Exports
