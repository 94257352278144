import { Preferences, PreferencesPlugin } from "@capacitor/preferences";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type StorageProvider = Pick<
    PreferencesPlugin,
    "get" | "set" | "clear" | "remove" | "configure"
>;

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface LocalStorageUtilsImpl {
    /**
     * Request string value from local storage as generic
     * @param  {string} key
     * @param  {function} ctor Constructor to be used on record objects.
     * @returns Promise<T | undefined>
     */
    get<T>(key: string, ctor?: new (value: any) => T): Promise<T | undefined>;

    /**
     * Request an Array of Records from Local Storage.
     * @param  {string} key
     * @param  {new(value:any} ctor
     * @returns Promise<T[] | undefined>
     */
    getArray<T>(
        key: string,
        ctor: new (value: any) => T
    ): Promise<T[] | undefined>;

    /**
     * Remove a key value pair from Local storage
     * @param  {string} key
     * @returns Promise<void>
     */
    remove(key: string): Promise<void>;

    /**
     * Cache key value pair to local storage as strings
     * @param  {string} key
     * @param  {T} value
     * @param  {number} expires The TTL for the stored value in milliseconds
     * @returns Promise<void>
     */
    set<T>(key: string, value: T, expires?: number): Promise<void>;

    /**
     * Clear the cache
     * @returns Promise<void>
     */
    clearCache(): Promise<void>;
}

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ttlSuffix = "_ttl";

// -----------------------------------------------------------------------------------------
// #region Class Definition
// -----------------------------------------------------------------------------------------

class LocalStorageUtils implements LocalStorageUtilsImpl {
    constructor(private storage: StorageProvider) {}

    // -------------------------------------------------------------------------------------
    // #region Static Methods
    // -------------------------------------------------------------------------------------

    private static readonly Default: LocalStorageUtilsImpl =
        new LocalStorageUtils(Preferences);

    static get = LocalStorageUtils.Default.get.bind(LocalStorageUtils.Default);
    static getArray = LocalStorageUtils.Default.getArray.bind(
        LocalStorageUtils.Default
    );
    static set = LocalStorageUtils.Default.set.bind(LocalStorageUtils.Default);
    static remove = LocalStorageUtils.Default.remove.bind(
        LocalStorageUtils.Default
    );
    static clearCache = LocalStorageUtils.Default.clearCache.bind(
        LocalStorageUtils.Default
    );

    // -------------------------------------------------------------------------------------
    // #region Private Fields
    // -------------------------------------------------------------------------------------

    private _cachedConfigurePromise?: Promise<void>;

    // -------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------

    async get<T>(
        key: string,
        ctor?: new (value: any) => T
    ): Promise<T | undefined> {
        await this._configurePreferences();

        const { value: expiresAt } = await this.storage.get({
            key: key + ttlSuffix,
        });

        if (expiresAt != null) {
            // this should always be a number
            let expiresAtSeconds = parseInt(expiresAt);

            let expiredDate = new Date(expiresAtSeconds);
            if (expiredDate < new Date()) {
                await Promise.allSettled([
                    this.storage.remove({ key }),
                    this.storage.remove({ key: key + ttlSuffix }),
                ]);

                return undefined;
            }
        }

        const { value: storedValue } = await this.storage.get({ key });

        if (storedValue == null) {
            return undefined;
        }

        const value = JSON.parse(storedValue);
        if (ctor != null) {
            return new ctor(value);
        }

        return value as T;
    }

    async getArray<T>(
        key: string,
        ctor: new (value: any) => T
    ): Promise<T[] | undefined> {
        await this._configurePreferences();
        const { value: storedValue } = await this.storage.get({ key });

        if (storedValue == null) {
            return undefined;
        }

        const value = JSON.parse(storedValue);
        const records: T[] = new Array<T>();
        if (Array.isArray(value)) {
            value.forEach((r) => {
                records.push(new ctor(r));
            });
        }
        return records;
    }

    async remove(key: string): Promise<void> {
        await this._configurePreferences();
        await this.storage.remove({ key });
    }

    async set<T>(key: string, value: T, expires?: number): Promise<void> {
        await this._configurePreferences();
        await this.storage.set({
            key,
            value: JSON.stringify(value),
        });

        // cache forever
        if (expires === undefined) {
            return;
        }

        // make sure it's a positive number
        expires = Math.abs(expires);
        var expiredDate = Date.now() + expires;

        await this.storage.set({
            key: key + ttlSuffix,
            value: JSON.stringify(expiredDate),
        });
    }

    async clearCache(): Promise<void> {
        await this._configurePreferences();
        await this.storage.clear();
    }

    // -------------------------------------------------------------------------------------
    // #region Private Methods
    // -------------------------------------------------------------------------------------

    private async _configurePreferences(): Promise<void> {
        const configurePromise =
            this._cachedConfigurePromise ??
            this.storage.configure({
                group: "symmio",
            });

        await configurePromise;

        this._cachedConfigurePromise = configurePromise;
    }
}

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { LocalStorageUtils };

// #endregion Exports
