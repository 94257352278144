import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserStatus } from "../models/enumerations/user-status";
import FirestoreOrder from "../models/interfaces/firestore-order";
import { MskScore } from "../models/interfaces/msk-score";
import { Organization } from "../models/interfaces/organization";
import { User } from "../models/interfaces/user";
import { useAuthState } from "../utilities/contexts/auth-state-context";
import FirestoreService from "../utilities/services/firestore-service";
import OrganizationService from "../utilities/services/organization-service";
import UserService from "../utilities/services/user-service";
import UserUtil from "../utilities/user-util";
import { Utils } from "../utilities/utils";

const DebugPage: React.FC = () => {
    const location = useLocation();
    let { organizationId } = Utils.parseQueryString(location.search);
    const [users, setUsers] = useState<User[]>([]);
    const [organization, setOrganization] = useState<Organization>();
    const { state } = useAuthState();

    organizationId = organizationId ?? "BMjCr3Zq9m0f6qTdvGEd"; // FMS
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);


    // #region useEffect

    useEffect(() => {
        const loadOrg = async () => {
            const org = await OrganizationService.get(organizationId);
            setOrganization(org!);
        };

        const loadUsers = async () => {
            UserService.getBy([{
                field: "organizationId",
                operator: "==",
                value: organizationId,
            }]).then((users: User[]) => {
                users = users.sort((a: User, b: User) => {
                    if (a.status < b.status) {
                        return -1;
                    } else if (a.status > b.status) {
                        return 1;
                    }
                    if ((a.firstName?.toLocaleLowerCase() ?? "zzz") < (b.firstName?.toLocaleLowerCase() ?? "zzz")) {
                        return -1;
                    } else if ((a.firstName?.toLocaleLowerCase() ?? "zzz") > (b.firstName?.toLocaleLowerCase() ?? "zzz")) {
                        return 1;
                    }
                    if ((a.lastName?.toLocaleLowerCase() ?? "zzz") < (b.lastName?.toLocaleLowerCase() ?? "zzz")) {
                        return -1;
                    } else if ((a.lastName?.toLocaleLowerCase() ?? "zzz") > (b.lastName?.toLocaleLowerCase() ?? "zzz")) {
                        return 1;
                    }
                    if (a.id! < b.id!) {
                        return -1;
                    } else if (a.id! > b.id!) {
                        return 1;
                    }
                    return 0;
                });

                loadMsks(users);
            })
        }

        const loadMsks = async (users: User[]): Promise<void> => {
            const userIds = users.map((x) => x.id);
            const msks: MskScore[] = [];

            while (userIds.length) {
                const batchIds = userIds.splice(0, 10);
                const batchResult = await FirestoreService.getBy<MskScore>("userLatestMskScores", [{
                    field: "userId",
                    operator: "in",
                    value: batchIds,
                }]);
                batchResult.forEach((x) => msks.push(x));
            }

            users.forEach((user) => {
                (user as any).mskScore = msks.find((x) => x.userId === user.id);
            });

            loadAdditional(users, msks);
        }

        const loadAdditional = async (users: User[], msks: MskScore[]): Promise<void> => {
            const userIds = users.map((x) => x.id);
            const todays: any[] = [];
            const userActivities: any[] = [];
            const userActivitiesMinDate = DateTime.now().plus({ days: -7 }).toISODate();
            const dateDescSort: FirestoreOrder[] = [{
                field: "date",
                direction: "desc",
            }];

            while (userIds.length) {
                const batchIds = userIds.splice(0, 10);

                const todayResult = await FirestoreService.getBy<any>("today", [{
                    field: "userId",
                    operator: "in",
                    value: batchIds,
                }], dateDescSort);
                todayResult.forEach((x) => todays.push(x));

                const userActivityResult = await FirestoreService.getBy<any>("userActivity", [{
                    field: "userId",
                    operator: "in",
                    value: batchIds,
                }, {
                    field: "date",
                    operator: ">=",
                    value: userActivitiesMinDate,
                }], dateDescSort);
                userActivityResult.forEach((x) => userActivities.push(x));
            }

            users.forEach((user) => {
                (user as any).todays = todays.filter((x) => x.userId === user.id);
                (user as any).userActivities = userActivities.filter((x) => x.userId === user.id);
            });
            (window as any).debugData = {
                users,
                userCreateds: users.map((x) => { return { created: x.created, id: x.id, name: x.firstName + " " + x.lastName ?? "", email: x.email }; }),
                msks,
                mskCreateds: msks.map((x) => { return { created: x.created, id: x.id, percentage: x.percentage }; }),
                todays,
                userActivities,
            };

            setUsers(users);
            console.log("window.debugData:", (window as any).debugData);
        }

        loadOrg();
        loadUsers();
    }, [organizationId]);

    // #endregion useEffect


    // #region get data

    const getUserActivities = (user: User): string => {
        const userActivities: any[] = (user as any).userActivities;
        if (userActivities == null || userActivities.length === 0) {
            return "(none)";
        }

        const activityTypes = userActivities.map(x => x.type);
        const distinct = activityTypes
            .filter((value, index, array) => array.indexOf(value) === index) // distinct!
            .sort()
            .map(x => {
                const num = activityTypes.filter(type => type === x).length;
                return x + (num === 1 ? "" : ` (${num})`);
            });
        return distinct.join(", ");
    }

    const getDate = (value: any): Date | null => {
        if (value == null) {
            return null;
        }

        if (value.seconds) {
            return new Date(value.seconds * 1000);
        }

        const timestamp: Timestamp = value as Timestamp;
        if (timestamp.toMillis != null) {
            return new Date(timestamp.toMillis());
        }

        return null;
    }

    const getDateStr = (value: any, defaultValue: string = "--"): string => {
        const date = getDate(value);

        if (date == null) {
            return defaultValue;
        }

        let result = date.toLocaleString();
        const seconds = date.getSeconds();
        const secondsPrefix = seconds < 10 ? "0" : "";
        result = result.replace(`:${secondsPrefix}${seconds} `, "");
        return result;
    }

    const getLastActivityDate = (user: User): string => {
        return getDateStr((user as any).lastActivityCompleted);
    }

    const getHealthRiskName = (riskCode?: string): string | undefined => {
        if (riskCode == null) {
            return undefined;
        }

        let index: number;
        if ((index = riskCode.indexOf("-")) >= 0) {
            riskCode = riskCode.substring(0, index);
        }

        return Utils.getHealthRiskName(riskCode);
    }

    const getCreatedDate = (user: User): string => {
        return getDateStr((user as any).created);
    }

    const getUpdatedDate = (user: User): string => {
        return getDateStr((user as any).updated);
    }

    const getMskDate = (mskScore: MskScore): string => {
        if (mskScore == null) {
            return "--";
        }
        const date = getDate(mskScore.updated ?? mskScore.created);
        if (date == null) {
            return "--";
        }
        return date.toLocaleDateString();
    }

    const getStringState = (value?: string | null): string => {
        if (value == null) {
            return "(null)";
        }

        if (value.length === 0) {
            return "(empty string)";
        }

        return "(set)";
    }

    const getAuthState = (user: User): string => {
        return getStringState(user.authenticationId);
    }

    const getGroupIdState = (user: User): string => {
        return getStringState(user.groupId);
    }

    // #endregion get data


    if (!isSuperAdmin) {
        return <h1>Super Admins Only</h1>;
    }

    const activeCount = users.filter((x) => x.status === UserStatus.Active).length;
    const inactiveCount = users.filter((x) => x.status === UserStatus.Inactive).length;
    const userMsks = users.map((x) => (x as any).mskScore as MskScore).filter((x) => x != null);
    const userMsksActive = users.filter((x) => x.status === UserStatus.Active).map((x) => (x as any).mskScore as MskScore).filter((x) => x != null);

    return (
        <div style={{ "padding": "20px" }}>
            <h1>Debugging: {organization?.name}</h1>
            <div>{organization?.id}</div>

            <div>
                <b>User Counts:</b> &nbsp;
                <label>Users: {users.length}</label> &nbsp;
                <label>Active: {activeCount}</label> &nbsp;
                <label>Inactive: {inactiveCount}</label> &nbsp;
                <label>Disabled: {users.filter((x) => x.status === UserStatus.Disabled).length}</label> &nbsp;
            </div>
            <div>
                <b>AuthenticationId:</b> &nbsp;
                <label>Not Null: {users.filter((x) => x.authenticationId != null).length}</label> &nbsp;
                <label>Truthy: {users.filter((x) => x.authenticationId).length}</label> &nbsp;
                <label>Falsey: {users.filter((x) => !x.authenticationId).length}</label> &nbsp;
                <label>Set: {users.filter((x) => x.authenticationId != null && x.authenticationId.length >= 1).length}</label> &nbsp;
                <label style={{ "color": "#900", "fontWeight": "bold" }}>Empty: {users.filter((x) => x.authenticationId != null && x.authenticationId.length === 0).length}</label> &nbsp;
                <label>Null: {users.filter((x) => x.authenticationId == null).length}</label> &nbsp;
            </div>
            <div>
                <b>Assessment Counts:</b> &nbsp;
                <label>Movement: {users.filter((x) => (x as any).mskScore?.movementScore?.percentage != null).length}</label> &nbsp;
                <label>Lifestyle: {users.filter((x) => (x as any).mskScore?.lifestyleScore?.percentage != null).length}</label> &nbsp;
                <label>MSK: {users.filter((x) => (x as any).mskScore?.percentage != null).length}</label> &nbsp;
            </div>
            <div>
                <b>Health Risk Counts (all):</b> &nbsp;
                <ul>
                    <li>* High: {userMsks.filter((x) => x.riskFactor?.riskCode?.indexOf("Health-") === 0).length} - "Health-" prefix</li>
                    <li>* Moderate: {userMsks.filter((x) => x.riskFactor?.riskCode?.indexOf("Wellness-") === 0).length} - "Wellness-" prefix</li>
                    <li>* Slight: {userMsks.filter((x) => x.riskFactor?.riskCode?.indexOf("Fitness-") === 0).length} - "Fitness-" prefix</li>
                    <li>* Low: {userMsks.filter((x) => x.riskFactor?.riskCode?.indexOf("Performance") === 0).length} - "Performance" value</li>
                </ul>
            </div>
            <div>
                <b>Health Risk Counts (active only):</b> &nbsp;
                <ul>
                    <li>* High: {userMsksActive.filter((x) => x.riskFactor?.riskCode?.indexOf("Health-") === 0).length}</li>
                    <li>* Moderate: {userMsksActive.filter((x) => x.riskFactor?.riskCode?.indexOf("Wellness-") === 0).length}</li>
                    <li>* Slight: {userMsksActive.filter((x) => x.riskFactor?.riskCode?.indexOf("Fitness-") === 0).length}</li>
                    <li>* Low: {userMsksActive.filter((x) => x.riskFactor?.riskCode?.indexOf("Performance") === 0).length}</li>
                </ul>
            </div>
            <div>
                <b>Dashboard:</b> &nbsp;
                <div>Based on "getDashboardDataHandler"</div>
                <ul>
                    <li>* Active: {activeCount}&nbsp;
                        - Active status</li>
                    <li style={{ "color": "#999", "textDecoration": "line-through" }}>
                        * Inactive: {inactiveCount}&nbsp;
                        - OLD: Inactive status (was not deployed)
                    </li>
                    <li style={{ "color": "#999", "textDecoration": "line-through" }}>
                        * Pending: {users.filter((x) => x.status == null).length}&nbsp;
                        - OLD: status is null (was not deployed)
                    </li>
                    <li>
                        * Inactive: {users.filter((x) => x.status === UserStatus.Inactive && x.authenticationId != null).length}&nbsp;
                        - NEW: Inactive status; AuthenticationId not null
                    </li>
                    <li>
                        * Pending: {users.filter((x) => x.status !== UserStatus.Disabled && x.authenticationId == null).length}&nbsp;
                        - NEW: not Disabled; AuthenticationId is null
                    </li>
                </ul>
            </div>
            <div>
                <b>Compliance Report:</b> &nbsp;
                <div>Based on "getReportComplianceData"</div>
                <ul>
                    <li>* Active: {users.filter((x) => x.status === UserStatus.Active && x.authenticationId != null).length}&nbsp;
                        - Active status; AuthenticationId not null</li>
                    <li>* Inactive: {users.filter((x) => x.status === UserStatus.Inactive && x.authenticationId != null).length}&nbsp;
                        - Inactive status; AuthenticationId not null</li>
                    <li style={{ "color": "#999", "textDecoration": "line-through" }}>
                        * Pending: {users.filter((x) => x.authenticationId == null && x.email).length}&nbsp;
                        - OLD: AuthenticationId is null; has email</li>
                    <li style={{ "color": "#999", "textDecoration": "line-through" }}>
                        * Pending Initial: {users.filter((x) => x.authenticationId && x.firstName && (x as any).mskScore == null).length}&nbsp;
                        - OLD: AuthenticationId truthy; has first name; no MSK score (excludes movement-only scores)</li>
                    <li>* Pending: {users.filter((x) => x.authenticationId == null && x.status !== UserStatus.Disabled).length}&nbsp;
                        - NEW: AuthenticationId is null; not Disabled</li>
                    <li>* Pending Initial: {users.filter((x) => x.authenticationId != null && (x as any).mskScore?.percentage == null).length}&nbsp;
                        - NEW: AuthenticationId not null; not Disabled; no MSK score w/ percent</li>
                </ul>
            </div>
            <div>
                <b>Summary Report:</b> &nbsp;
                <div>Based on "getReportSummaryData"</div>
                <ul>
                    <li>* Total Users: {users.filter((x) => x.created != null
                        && (x.created as Timestamp).seconds != null
                        && (x.created as Timestamp).seconds < Math.ceil(Date.parse("3/31/2023") / 1000) + 86400
                    ).length}&nbsp;
                        - Timestamp created date before 3/31
                    </li>
                </ul>
            </div>
            <div>
                <b>Detail / Score Report:</b> &nbsp;
                <div>Based on "getReportDetailData"</div>
                <ul>
                    <li style={{ "color": "#999", "textDecoration": "line-through" }}>
                        * Users: {users.filter((x) => x.authenticationId && (x as any).mskScore?.percentage != null).length}&nbsp;
                        - OLD: AuthenticationId truthy; has MSK score w/ percent
                    </li>
                    <li>* Users: {users.filter((x) => x.authenticationId != null && (x as any).mskScore?.percentage != null).length}&nbsp;
                        - NEW: AuthenticationId is not null; has MSK score w/ percent</li>
                </ul>
            </div>

            <div style={{ "paddingTop": "20px" }}>
                <table
                    className="light"
                    cellSpacing={0}
                    cellPadding={0}>
                    <thead>
                        <tr>
                            <th>Name / Email / ID</th>
                            <th>Status</th>
                            <th>Streak</th>
                            <th style={{ "whiteSpace": "nowrap" }}>Created / Updated<br />/ Last Activity</th>
                            <th>
                                <span style={{ "whiteSpace": "nowrap" }}>
                                    Move / Life / MSK
                                </span>
                                <br />
                                MSK Updated
                            </th>
                            <th>Risk Code / Level</th>
                            <th>Has Authen</th>
                            <th>Group Name / ID</th>
                            <th>Tags</th>
                            <th style={{ "whiteSpace": "nowrap" }}>Activities (7 days)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users &&
                            users.map((user, index) => {
                                const msk: MskScore = (user as any).mskScore;
                                const authState = getAuthState(user);

                                return (
                                    <tr key={index}>
                                        <td>
                                            { // if
                                                user.firstName &&
                                                <>
                                                    {`${user.firstName ?? ""} ${user.lastName ?? ""}`}<br />
                                                </>
                                            }
                                            {user.email}<br />
                                            {user.id}
                                        </td>
                                        <td>{user.status} - {UserStatus[user.status]}</td>
                                        <td>{user.dayStreak}</td>
                                        <td>
                                            {getCreatedDate(user)}<br />
                                            {getUpdatedDate(user)}<br />
                                            {getLastActivityDate(user)}
                                        </td>
                                        <td>
                                            <span style={{ "whiteSpace": "nowrap" }}>
                                                {msk?.movementScore?.percentage ?? "--"} / {msk?.lifestyleScore?.percentage ?? "--"} / {msk?.percentage ?? "--"}
                                            </span>
                                            <br />
                                            {getMskDate(msk)}
                                        </td>
                                        <td>{msk?.riskFactor?.riskCode}<br />{getHealthRiskName(msk?.riskFactor?.riskCode)}</td>
                                        <td>{authState}</td>
                                        <td>{user.groupName ?? "--"}<br />{getGroupIdState(user)}</td>
                                        <td>{user.tags == null ? "(none)" : user.tags.map((x) => x.name).join("; ")}</td>
                                        <td>{getUserActivities(user)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DebugPage;
