import { create } from "zustand";
import { createDeeplinkSlice, DeeplinkSlice, DeeplinkSliceState } from "./deeplinkSlice";
import { createGlobalSlice, GlobalSlice, GlobalSliceState } from "./globalSlice";
import { createGroupSlice, GroupSlice, GroupSliceState } from "./groupSlice";
import { createTagSlice, TagSlice, TagSliceState } from "./tagSlice";

export type UnifiedStore = DeeplinkSlice &
    GroupSlice &
    TagSlice &
    GlobalSlice;

export type UnifiedStoreState = DeeplinkSliceState &
    GroupSliceState &
    TagSliceState &
    GlobalSliceState;

export const useStore = create<UnifiedStore>()((...a) => ({
    ...createDeeplinkSlice(...a),
    ...createGroupSlice(...a),
    ...createTagSlice(...a),
    ...createGlobalSlice(...a)
}));
