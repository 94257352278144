import { Redirect, useHistory } from "react-router";
import { Button } from "../../components/button/button";
import SubscriptionLinksList from "../../components/subscription-links/subscription-link-list";
import { useAuthState } from "../../utilities/contexts/auth-state-context";

const COMPONENT_CLASS = "c-lead-links-list";

const SubscriptionLinksPage: React.FC = () => {
    const { state } = useAuthState();
    const history = useHistory();

    if (!state.user) {
        throw new Error("You must be a super admin");
    }

    const handleAddLink = () => {
        history.push('/subscription-link');
    }

    if (state.organization.plan !== "Third-Party Subscription" || !state.user.isSuperAdmin) {
        return <Redirect to="/" />
    }

    return (
        <>
            <div className={`${COMPONENT_CLASS}`} data-testid={'subscription-links-list'}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>Subscription Links</h1>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            type="default"
                            buttonText="+ Add Subscription Link"
                            onClick={handleAddLink}
                        />
                    </div>
                </div>

                <SubscriptionLinksList />

            </div>
        </>
    );
}

export default SubscriptionLinksPage;
