import React from "react";
import { Dialog } from "@headlessui/react";
import InactivityMessage from "./inactivity-modal-message";
import InactivityModalTitle from "./inactivity-modal-title";
import InactivityModalButtons from "./inactivity-modal-buttons";

export interface InactivityModalProps {
    onClose: Function,
    onRecentlyInactive: Function,
    open: boolean,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityModal: React.FC<InactivityModalProps> = (props) => {
    const {
        onClose,
        onRecentlyInactive,
        open,
    } = props;

    const handleClose = () => {
        onRecentlyInactive();
        onClose(false);
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{ width: '978px', borderRadius: '30px' }}>
                        <div className="flex-block justify-center" >
                            <InactivityModalTitle />
                            <InactivityMessage onCountdownExpired={handleClose}
                            />
                            <InactivityModalButtons
                                onFirstButtonClick={onClose}
                                onSecondButtonClick={onClose}
                            />
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default InactivityModal;