import { Report } from "../../../models/interfaces/report";
import FirestoreService from "../firestore-service";

const COLLECTION_NAME = "reports";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Find the specific report by the id
 * @param {string} id - The Id of the report that we are retrieving
 * @return {Promise<Report>} A promise for the report we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Report>(COLLECTION_NAME, id);
};

/**
 * Get a snapshot of the specific group to see when it changes
 * @param {string} id - Id of the group document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<Report>(COLLECTION_NAME, id, listener);
};

/**
 * Save the specified report stored in the database
 * @param {Report} report - The report that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Today>} A promise for the report that is being updated
 */
const save = async (report: Report, currentUser: any | null = null) => {
    return FirestoreService.save<Report>(COLLECTION_NAME, report, currentUser);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ReportService = {
    get,
    getSnapshot,
    save,
};

export default ReportService;

// #endregion Exports
