import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Group } from "../../models/interfaces/group";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "groups";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new group to the groups collection
 * @param {Group} group - The group that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Group>} A promise to the newly added group
 */
const add = async (group: Group, currentUser: User | null = null) => {
    return FirestoreService.add<Group>(COLLECTION_NAME, group, currentUser);
};

/**
 * Delete a group collection by the Id
 * @param {string} id - The Id of the group being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific group by the id
 * @param {string} id - The Id of the group that we are retrieving
 * @returns {Promise<Group>} A promise for the Group we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Group>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<Group>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the groups stored in the database
 * @returns {Promise<Group[]>} A promise for the collection of Groups
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<Group>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific group to see when it changes
 * @param {string} id - Id of the group document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<Group>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the groups to see when it changes
 * @param {string} id - Id of the group document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<Group>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified group in the database
 * @param {Group} group - The group that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Group>} A promise for the group that is being updated
 */
const save = async (group: Group, currentUser: User | null = null) => {
    return FirestoreService.save<Group>(COLLECTION_NAME, group, currentUser);
};

/**
 * Update the specified group stored in the database
 * @param {Group} group - The group that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Group>} A promise for the group that is being updated
 */
const update = async (group: Group, currentUser: User | null = null) => {
    return FirestoreService.update<Group>(COLLECTION_NAME, group, currentUser);
};

/**
 * Update the specified group stored in the database
 * @param {Group} group - The group that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Group>} A promise for the group that is being updated
 */
const modifyUserCountByGroupId = async (id: string, changedCount: number, currentUser: User | null = null) => {
    const group = await get(id);
    if (group == null) {
        return;
    }
    if (!group.userCount) {
        group.userCount = 0;
    }
    group.userCount += changedCount;
    return FirestoreService.update<Group>(COLLECTION_NAME, group, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const GroupService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
    modifyUserCountByGroupId,
};

export default GroupService;

// #endregion Exports