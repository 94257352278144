import { DateTime } from "luxon";
import ReportAssessment from "../../components/report/report-assessment";
import { ReportAssessmentData } from "../../models/interfaces/reports/report-assessment-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Redirect } from 'react-router-dom';

const ReportAssessmentTestPage: React.FC = () => {
    const { state } = useAuthState();
    if (!state.claims.superAdmin) {
        return <Redirect push to="/" />;
    }

    const data: ReportAssessmentData = {
        // ReportAssessmentData fields:
        averageAgeRange: "20-37",
        dob: "1991-01-01",
        history: [
            { date: DateTime.local().plus({ days: -1 }).toISODate(), percent: 67 },
            { date: DateTime.local().plus({ days: -4 }).toISODate(), percent: 70 },
            { date: DateTime.local().plus({ days: -11 }).toISODate(), percent: 64 },
            { date: DateTime.local().plus({ days: -23 }).toISODate(), percent: 61 },
            { date: DateTime.local().plus({ days: -150 }).toISODate(), percent: 57 },
            { date: "2021-12-05T01:13:00.000Z", percent: 55 }, // local should be 12/4/2021
        ],
        isFemale: true,
        tags: [],

        balanceScore: 21,
        rotationScore: 32,
        shoulderMobilityHasPain: true,
        shoulderMobilityScore: 43,
        squatScore: 54,
        toeTouchScore: 65,
        didPassShoulderClearing: undefined,
        didPassSpineClearing: false,

        // ReportUserItem fields:
        focusAreas: ["Movement Health", "Behavioral Health", "Breathing Quality"],
        groupName: "",
        healthCategory: "Wellness",
        inPain: true,
        mskScore: 67,
        name: "Assessment Test Name",
        email: "assessment@test.com",

        movementAverage: 60,
        movementDescription: "Based on your assessment results, your primary area of focus is <strong>Toe Touch</strong>. From here, you'll receive a series of corrective exercises to help improve your <strong>Toe Touch</strong> and your overall MSK score, and to help get you moving without pain or limitations.",
        movementScore: 93,

        bodyCompAverage: 60,
        bodyCompDescription: "This is the <b>test</b> description for ",
        bodyCompScore: 82,

        activityAverage: 60,
        activityDescription: "This is the <b>test</b> description for ",
        activityScore: 71,

        injuryHistoryAverage: 60,
        injuryHistoryDescription: "This is the <b>test</b> description for ",
        injuryHistoryHadPain: true,
        injuryHistoryScore: 60,

        breathingAverage: 60,
        breathingDescription: "Based on your assessment results, you scored well in <strong>Breathing Quality</strong>, but you should still keep an eye on how well you're breathing and moving.",
        breathingScore: 100,

        sleepAverage: 65,
        sleepDescription: "This is the <b>test</b> description for ",
        sleepScore: 70,

        nutritionAverage: 70,
        nutritionDescription: "This is the <b>test</b> description for ",
        nutritionScore: undefined,

        behavioralAverage: 70,
        behavioralDescription: "Based on your assessment results, your primary area of focus is <strong>Behavioral Health</strong>. Touching so many aspects of life, we'll look at different strategies for improving your <strong>Behavioral Health</strong> and your overall MSK score.",
        behavioralScore: 0,

        // BaseReportData fields:
        groupNames: [],
        organizationName: "Org Name that can be Longer",
        reportDate: "2022-05-06",
        tagNames: [],
        totalUsers: 1,
    }

    return <ReportAssessment data={data} />;
}

export default ReportAssessmentTestPage;
