/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import { Question } from "../../models/interfaces/questions/question";
import { QuestionGroup } from "../../models/interfaces/questions/question-group";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import useLinkAccountSetupQuestions from "../../utilities/hooks/use-account-setup-questions";
import ScreenSlide from "../screen-slide/screen-slide";
import { User } from "../../models/interfaces/user";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import LinkMovementScreen from "../link-movement-screen/link-movement-screen";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-account-setup-screen";

const setupQuestions: QuestionGroup[] = [
    {
        groupId: GroupIds.SETUP,
        groupSort: 0,
        questions: [
            {
                questionTitleId: "biological-sex-question",
                questionId: "biologicalSex",
                questionText: "What is your biological sex?",
                sort: 1,
                answerFormat: AnswerFormats.OPTIONS,
                answerDetailsId: "biological-sex-info-text",
                answerDetails:
                    "We love and respect everyone but we need a baseline for your body’s biological composition, so we can assess where you are on your wellness journey.",
                answerOptions: [
                    {
                        id: "biological-sex-female-button",
                        answerId: "FEMALE",
                        answerText: "Female",
                    },
                    {
                        id: "biological-sex-male-button",
                        answerId: "MALE",
                        answerText: "Male",
                    },
                ],
            },
            {
                questionId: "birthdate",
                questionText: "Your birthdate:",
                sort: 2,
                answerFormat: AnswerFormats.DATE,
            },
        ],
    },
];

export interface LinkAccountSetupScreenProps {
    user: User;
}

const LinkAccountSetupScreen: React.FC<LinkAccountSetupScreenProps> = (props) => {
    const questionGroups = useMemo<QuestionGroup[]>(() => setupQuestions, []);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [questionResponses, setQuestionResponses] = useState<
        QuestionResponse[]
    >([]);
    const slides = useLinkAccountSetupQuestions(questionGroups);
    const [isCompleted, setIsCompleted] = useState(false);
    const [settings, setSettings] = useState<AssessmentSettings>({
        completedGroupIds: [],
        currentSlideIndex: 0,
        currentGroupIndex: 0,
        questionResponses: [],
        slideGroups: [],
    });

    const [updatedUser, setUpdatedUser] = useState(props.user);

    useEffect(() => {
        document.body.classList.add("-nav-hidden");

        return () => {
            document.body.classList.remove("-nav-hidden");
        };
    }, []);

    useEffect(() => {
        const slide = slides[currentSlideIndex];
        if (!slide?.questions) {
            return;
        }
        const question = slide.questions![0];
        if (question.questionId === "biologicalSex") {
            getNextPage();
        }
    }, [questionResponses]);

    const getNextPage = () => {
        var slide = slides[currentSlideIndex];
        if (slide.slideType === SlideType.Question && slide.questions) {
            for (const question of slide.questions) {
                const questionResponse = questionResponses.find(
                    (x) => x.questionId === question.questionId
                );

                // validate has answer
                if (questionResponse == null) {
                    return;
                }
                if (
                    questionResponse.answerId == null &&
                    questionResponse.answerResponse == null
                ) {
                    return;
                }
                if (
                    questionResponse.answerResponse != null &&
                    questionResponse.answerResponse.length === 0
                ) {
                    return;
                }
            }
        }
        setCurrentSlideIndex(currentSlideIndex + 1);
    };

    const submitProfile = async () => {

        let updatedUserTemp = updatedUser;

        questionResponses.forEach((response) => {
            switch (response.questionId) {
                case "biologicalSex":
                    updatedUserTemp.biologicalSex = response.answerResponse;
                    break;

                case "birthdate":
                    updatedUserTemp.birthdate = response.answerResponse ? Utils.convertToISO(response.answerResponse) : response.answerResponse;
                    break;
            }
        });
        if (updatedUserTemp.birthdate) {
            setUpdatedUser(updatedUserTemp);
            setIsCompleted(true);
        }
    };

    const recordAnswer = (selectedAnswer: string, question: Question) => {
        if (
            questionResponses.find((x) => x.questionId === question.questionId)
        ) {
            // if response already exists
            const editingIndex = questionResponses.findIndex(
                (x) => x.questionId === question.questionId
            );
            questionResponses[editingIndex].answerResponse = selectedAnswer;
            setQuestionResponses(questionResponses);
        } else {
            // response does not already exist
            const selectedAnswerValue =
                question.answerOptions?.find(
                    (x) => x.answerId === selectedAnswer
                )!.answerText ?? selectedAnswer;
            const answer = {
                categoryId: questionGroups[0].categoryId!,
                groupId: questionGroups[0].groupId,
                answerId: selectedAnswer,
                questionId: question.questionId,
                answerResponse: selectedAnswerValue,
            };
            setQuestionResponses([...questionResponses, answer]);
        }
    };

    const onBackButtonClick = () => {
        if (AssessmentUtils.isFirstSlideAndFirstGroup(settings)) {
            setIsCompleted(false);
        }
        setSettings(AssessmentUtils.moveToPreviousPage(settings));
    };

    return (
        <>
            {
                // if
                isCompleted && (
                    <LinkMovementScreen
                        screenSettings={settings}
                        setScreenSettings={setSettings}
                        settingsOnBack={onBackButtonClick}
                        user={updatedUser}
                    />
                )
            }
            {
                // if
                !isCompleted && (
                    <BaseCardLayout>
                        <div style={{ width: "100%" }}>
                            <div className={`${COMPONENT_CLASS} h-full`}>
                                {
                                    // if
                                    questionGroups && (
                                        <div
                                            className={`${COMPONENT_CLASS}__content h-full ${currentSlideIndex + 1 === slides.length
                                                ? "has-button"
                                                : ""
                                                }`}>
                                            {
                                                // if
                                                slides[currentSlideIndex] && (
                                                    <ScreenSlide
                                                        recordedAnswers={questionResponses}
                                                        recordAnswer={recordAnswer}
                                                        page={slides[currentSlideIndex]}
                                                        setupSubmit={submitProfile}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    // if
                                    !questionGroups && (
                                        <IonItem>
                                            <IonLabel>
                                                Loading Onboarding questions....
                                            </IonLabel>
                                        </IonItem>
                                    )
                                }
                            </div>
                        </div>
                    </BaseCardLayout>
                )
            }

        </>
    );
};

export default LinkAccountSetupScreen;
