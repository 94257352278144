import { GroupIds } from "../models/enumerations/group-ids";
import { SlideType } from "../models/enumerations/slide-types";
import { Question } from "../models/interfaces/questions/question";
import { QuestionGroup } from "../models/interfaces/questions/question-group";
import { QuestionResponse } from "../models/interfaces/questions/question-response";
import { Slide } from "../models/interfaces/slide";

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

/**
 * Create questions for screens
 * @param  {QuestionGroup} Group of Questions
 * @returns {Slide[]} Collection of Slides
 */
function _createQuestionSlides(questionGroup: QuestionGroup): Slide[] {
    let slides: Slide[] = [];

    const noIntroGroupIds = [
        GroupIds.SETUP.toString(),
        GroupIds.BEHAVIORAL_QUESTIONNAIRE.toString(),
        GroupIds.NUTRITION_QUESTIONNAIRE.toString(),
        GroupIds.SLEEP_PSQI.toString(),
    ];
    if (noIntroGroupIds.indexOf(questionGroup.groupId) === -1) {
        slides.push({
            slideType: SlideType.GroupIntro,
            categoryId: questionGroup.categoryId,
            groupId: questionGroup.groupId,
            groupName: questionGroup.groupName,
            description: questionGroup.description,
        });
    }

    for (let i = 0; i < questionGroup.questions.length; i++) {
        const question = questionGroup.questions[i];
        const hasVideoSource =
            question.videoId != null || question.videoUrl != null;

        if (hasVideoSource) {
            slides.push({
                slideType: SlideType.Video,
                categoryId: questionGroup.categoryId,
                groupId: questionGroup.groupId,
                groupName: questionGroup.groupName,
                instructions: question.instructions,
                questions: [question],
                video: {
                    videoId: question.videoId,
                    videoUrl: question.videoUrl,
                },
            });
        }

        const questions = [question];

        if (question.canGroupWithNextQuestion) {
            questions.push(questionGroup.questions[i + 1]);
            i++;
        }

        slides.push({
            slideType: SlideType.Question,
            questions: questions,
            categoryId: questionGroup.categoryId,
            groupId: questionGroup.groupId,
            groupName: questionGroup.groupName,
        });
    }

    slides[slides.length - 1].isLastInGroup = true;
    slides[slides.length - 1].nextGroupIdDeterminedByApi =
        questionGroup.nextGroupIdDeterminedByApi;
    return slides;
}

/**
 * Determines if a question can be skipped
 * @param {Questions} Question[]
 * @param {QuestionResponse[]} Responses to questions
 * @returns {boolean} Returns true if the question can be skipped
 */
function _shouldSkipAllQuestions(
    questions: Question[],
    questionResponses: QuestionResponse[]
): boolean {
    if (questions == null || questions.length === 0) {
        // incorrectly called method should show
        return false;
    }

    for (var i = 0; i < questions.length; i++) {
        if (!_shouldSkipQuestion(questions[i], questionResponses)) {
            return false;
        }
    }

    return true;
}

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function _shouldSkipQuestion(
    question: Question,
    questionResponses: QuestionResponse[]
): boolean {
    const numShowWhen =
        question.showWhen == null ? 0 : question.showWhen.length;
    const numSkipWhen =
        question.skipWhen == null ? 0 : question.skipWhen.length;
    if (numShowWhen === 0 && numSkipWhen === 0) {
        // no show/skip conditionals will show
        return false;
    }

    if (numSkipWhen >= 1) {
        for (let i = 0; i < question.skipWhen!.length; i++) {
            const skipWhen = question.skipWhen![i];
            const match = questionResponses.find(
                (x) =>
                    x.questionId === skipWhen.matchQuestionId &&
                    x.answerId === skipWhen.matchQuestionAnswerId
            );
            if (match != null) {
                // skip when matches skip condition
                //console.log(`SKIP QUESTION: ${question.questionText}\n- match questionId=${skipWhen.matchQuestionId} and answerId=${skipWhen.matchQuestionAnswerId}`);
                return true;
            }
        }
    }

    if (numShowWhen >= 1) {
        for (let i = 0; i < question.showWhen!.length; i++) {
            const showWhen = question.showWhen![i];
            const match = questionResponses.find(
                (x) =>
                    x.questionId === showWhen.matchQuestionId &&
                    x.answerId === showWhen.matchQuestionAnswerId
            );
            if (match != null) {
                // show when matches show condition
                //console.log(`SHOW QUESTION: ${question.questionText}\n- match questionId=${showWhen.matchQuestionId} and answerId=${showWhen.matchQuestionAnswerId}`);
                return false;
            }
        }
        // defaults to skipping when no show matches
        //console.log(`SKIP QUESTION: ${question.questionText}\n- no matches`, showWhenList);
        return true;
    }

    return false;
}

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const QuestionUtils = {
    shouldSkipAllQuestions: _shouldSkipAllQuestions,
    createQuestionSlides: _createQuestionSlides,
};

// #endregion Exports
