import { useStore } from "../../store/useStore";

export const useDeeplinks = () => {
    return useStore((state) => ({
        fetchDeeplinks: state.fetchDeeplinks,
        deleteDeeplink: state.deleteDeeplink,
        deeplinks: state.deeplinks,
        deeplinksIsLoaded: state.deeplinksIsLoaded,
        groupsDropDownOptions: state.groupsDropdownSelectOption,
        getGroupsDropDownOptions: state.getGroupsDropDownSelectOptions,
        groupGetById: state.groupGetById,
        getTagsDropDownSelectOptions: state.getTagsDropDownSelectOptions,
        tagsDropDownOptions: state.tagsDropdownSelectOptions,
        addDeeplink: state.addDeeplink,
        getDeeplinkById: state.getDeeplinkById,
        updateDeeplink: state.updateDeeplink,
        getTagById: state.getTagById,
    }));
};
