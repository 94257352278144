import { useState } from 'react';
import { useLocation, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form";
import { TextTypeInput } from "../../components/forms";
import { getAuth, confirmPasswordReset } from "@firebase/auth";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import BaseCardLayoutActions from "../../components/base-card-layout/base-card-layout-actions";
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { enqueueSnackbar } from "notistack";

const COMPONENT_CLASS = "p-reset-password";

export interface ResetPasswordPageProps {
}

const ResetPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm();

    const useQuery = () => {
        const location = useLocation();
        return new URLSearchParams(location.search);
    }

    const query = useQuery();

    const [, setSubmitting] = useState<boolean>(false);
    const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
    const [isPasswordInputType, setIsPasswordInputType] = useState<boolean>(true);
    const history = useHistory();
    const email = query.get("email") || "";

    const visibilityIconStyles = {
        visibility: isPasswordInputType ? "visible" : "hidden",
        position: "absolute",
        marginTop: "11px",
        right: "18px",
        cursor: "pointer",
    }

    const visibilityOffIconStyles = {
        visibility: isPasswordInputType ? "hidden" : "visible",
        position: "absolute",
        marginTop: "11px",
        right: "18px",
        cursor: "pointer",
    }

    const passwordsMatch = (newPassword: string, confirmPassword: string) => {
        return newPassword.localeCompare(confirmPassword) === 0
    }

    const resetPassword = (newPassword: string) => {
        const auth = getAuth();
        const oobCode = query.get("oobCode") || "";
        return confirmPasswordReset(auth, oobCode, newPassword);
    }

    const handleError = (error: any) => {
        if (error?.code == null) {
            setError("formErrors", {
                type: "custom",
                message: "Something went wrong. We are working on fixing the problem. Please try again later."
            });
        }

        switch (error.code) {
            case "auth/weak-password":
                setError("formErrors", {
                    type: "custom",
                    message: "Password should be at least 6 characters."
                });
                break;
            case "auth/internal-error":
                setError("formErrors", {
                    type: "custom",
                    message: "An error occurred. Please try again later."
                });
                break;
            default:
                setError("formErrors", {
                    type: "custom",
                    message: "Something went wrong. We are working on fixing the problem. Please try again later."
                });
                break;
        }
    }

    const onSubmit = async (data: any) => {
        if (passwordsMatch(data.newPassword, data.confirmPassword)) {
            setPasswordMatch(true);
            setSubmitting(true);
            resetPassword(data.newPassword)
                .then((r: any) => {
                    reset();
                    setSubmitting(false);
                    enqueueSnackbar("Your account password has been reset", { variant: "toast", width: "450px", autoHideDuration: 5000 });
                    history.push("/login");
                })
                .catch((error: any) => {
                    setSubmitting(false);
                    handleError(error);
                });
        }
        else {
            setSubmitting(false);
            setPasswordMatch(false);
        }
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ height: "550px" }}>
                <div className={`${COMPONENT_CLASS}`}>
                    {
                        !passwordMatch && (
                            <div className={`${COMPONENT_CLASS}__password-error`}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <ErrorIcon color="error"></ErrorIcon>
                                    <h4 style={{
                                        paddingTop: "3px",
                                        marginLeft: "5px"
                                    }}>Passwords Must Match</h4>
                                </div>
                            </div>
                        )
                    }
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <form className={"login-form"}
                            onSubmit={handleSubmit(onSubmit)}>
                            <h4 className={`${COMPONENT_CLASS}__title`} style={{ fontWeight: "700" }}> Reset Your Password</h4>
                            <p className={`${COMPONENT_CLASS}__title`} style={{ marginBottom: "50px", fontWeight: "300" }}>Enter a new password for {email}.</p>
                            <div className={`${COMPONENT_CLASS}__field-container`} style={{ position: "relative" }}>
                                <VisibilityIcon sx={visibilityIconStyles} onClick={() => setIsPasswordInputType(false)} />
                                <VisibilityOffIcon sx={visibilityOffIconStyles} onClick={() => setIsPasswordInputType(true)} />

                                <TextTypeInput
                                    hideLabel
                                    id="newPassword"
                                    label="New Password"
                                    type={isPasswordInputType ? "password" : "text"}
                                    registerHook={register}
                                    errorState={errors.password}
                                    registerOptions={{ required: "Enter your new password" }}
                                    style={{ border: "2px solid #D0D0D3" }}
                                />

                                <TextTypeInput
                                    hideLabel
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    type={isPasswordInputType ? "password" : "text"}
                                    registerHook={register}
                                    errorState={errors.password}
                                    registerOptions={{ required: "Confirm your password" }}
                                    style={{ border: "2px solid #D0D0D3" }}
                                />
                            </div>
                            <div {...register("formErrors")}>
                                {errors.formErrors &&
                                    <span className="error-style" role="alert">
                                        {`${errors.formErrors.message}`}
                                    </span>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "1.8%", width: "100%" }}>
                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText="Reset Password"></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
};

export default ResetPasswordPage;
