/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dialog } from '@headlessui/react';
import {
    PaymentElement
} from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Button } from "../button/button";

interface SavePaymentMethodFormProps {
    isCreating?: boolean;
    isUpgrading?: boolean;
    isRetrying?: boolean;
    returnUrl: string;
    updateFailed: Function;
    updateSuccessful: Function;
    setOpenEdit: Function;
}

const COMPONENT_CLASS = "c-save-payment-form";
const SavePaymentMethodForm: React.FC<SavePaymentMethodFormProps> = ({ isCreating, isUpgrading, isRetrying, updateFailed, updateSuccessful, returnUrl, setOpenEdit }) => {
    const { state } = useAuthState();
    const stripe = useStripe();
    const stripe2 = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const [clientSecret, setClientSecret] = useState('');
    const elements = useElements();
    const [customer, setCustomer] = useState<any>();


    //const stripeEl = new Stripe('sk_test_51Ildh6HoJbt1xrgTackwpgLScozEdruu3qOmzOCebF4LQkQGbSfHs8RYh6xdjr5ORh6BBgeUOpffif21je8smjOM00PLz4GXdQ');
    // const elementsStripe = stripe!.elements({ clientSecret, appearance });
    // const paymentElement = elementsStripe.create("payment", options);
    // paymentElement.mount('#payment-element');
    const [, setIsLoading] = useState(false);

    useEffect(() => {
        const confirmIntent = (async () => {
            if (stripe && elements) {
                // Use the clientSecret and Elements instance to confirm the setup
                const { error, setupIntent } = await stripe.confirmSetup({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${window.location.origin}${returnUrl}`,
                    },
                    redirect: "if_required"
                });

                if (error) {
                    console.log(error);
                    updateFailed(true);
                }
                else if (customer) {

                    // Update customer to use the new payment method as their default
                    await stripe2.customers.update(
                        customer.data ? customer.data[0].id : customer.id,
                        {
                            invoice_settings: {
                                default_payment_method: setupIntent.payment_method,
                            },
                        }
                    );
                    updateSuccessful(true);
                }

            }
        })
        if (clientSecret) {
            confirmIntent();
        }

    }, [clientSecret])
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            console.log(submitError)
            // handleError(submitError);
            return;
        }

        setIsLoading(true);

        // Retrieve customer from stripe by Email. Expand for Subscription
        const customer = await stripe2.customers.list({
            limit: 1,
            email: `${state.user?.email}`,
            expand: ['data.subscriptions']
        })
        setCustomer(customer);

        // Check if a customer was found
        if (customer.data.length === 0 || !customer.data[0].id) {
            //TODO: Throw an error.
            return;
        }

        await stripe2.setupIntents.create({
            customer: customer.data[0].id,
            // In the latest version of the API, specifying the `automatic_payment_methods` parameter is optional because Stripe enables its functionality by default.
            automatic_payment_methods: {
                enabled: true,
            },
        }).then((intent: any) => {
            setClientSecret(intent.client_secret);
        });

        setIsLoading(false);
    }



    return (
        <form onSubmit={handleSubmit} className="w-3/4 md:w-2/3 lg:w-1/2 m-auto">
            <div className={`${COMPONENT_CLASS}__modal-title-container`}>
                <Dialog.Title
                    as="h3" className={`${COMPONENT_CLASS}__modal-title`}>
                    Edit Payment Details
                </Dialog.Title>
            </div>
            <PaymentElement />
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "4rem", gap: "50px" }}>
                <Button
                    type="link"
                    buttonText="Cancel"
                    onClick={() => { setOpenEdit(false) }} />
                <Button
                    buttonType="submit"
                    buttonText="Save"></Button>
            </div>
        </form>
    );
}

export default SavePaymentMethodForm;

