import React from 'react';
import { Redirect } from 'react-router-dom';
import OrganizationList from '../../components/organization-list/organization-list';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import UserUtil from '../../utilities/user-util';


const OrganizationsPage: React.FC = () => {
    const { state } = useAuthState();

    if (!state.claims.superAdmin && !UserUtil.isAccountHolder(state.user)) {
        return <Redirect push to="/" />;
    }

    return (
        <OrganizationList />
    );
}

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OrganizationsPage;

// #endregion Exports