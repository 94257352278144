import { ListOptions } from "./interfaces/list-options";

export const FocusGroupOptions: ListOptions[] = [
    // {
    //     value: "ACTIVITY_LEVEL",
    //     label: "Activity Level",
    // },
    {
        value: "BEHAVIORAL_HEALTH",
        label: "Behavioral Health",
    }, {
        value: "BODY_COMPOSITION",
        label: "Body Composition",
    }, {
        value: "BREATHING",
        label: "Breathing",
    }, {
        value: "INJURY_HISTORY",
        label: "Injury History",
    }, {
        value: "MOVEMENT_HEALTH",
        label: "Movement Health",
    }, {
        value: "NUTRITION",
        label: "Nutrition Awareness",
    }, 
    {
        value: "PHYSICAL_ACTIVITY",
        label: "Physical Activity",
    }, {
        value: "SLEEP",
        label: "Sleep Wellness",
    }
];