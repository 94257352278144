import React from "react";
import PillChartBottomText from "./pill-chart-bottom-text";
import PillChartTopText from "./pill-chart-top-text";

export interface PillChartProps {
    forReport?: boolean;
    index: number;
    percentage: number;
    range?: string;
    value?: number;
    heightPixels?: number;
    uniqueId?: string;
    linePercentage?: number; // For now, overflow: visible is needed on the SVG to show the line
    lineText?: string;
}

const PillChart: React.FC<PillChartProps> = (props) => {
    const {
        forReport,
        index,
        percentage,
        range,
        value,
        heightPixels,
        uniqueId,
        linePercentage,
        lineText,
    } = props;

    const height = heightPixels !== undefined ? heightPixels : 160;
    const pixelPercentage = height - ((percentage / 100) * height);
    const uniqueIdGenerator = uniqueId || index.toString();

    let linePixelPercentage: number | null = null;

    if (linePercentage !== undefined) {
        linePixelPercentage = height - ((linePercentage / 100) * height);
    }

    const clipPathId1 = `clip${uniqueIdGenerator}a`;
    const clipPathId2 = `clip${uniqueIdGenerator}b`;
    const clipUrl1 = `url(#${clipPathId1})`;
    const clipUrl2 = `url(#${clipPathId2})`;
    const rectId1 = `rectEmpty${uniqueIdGenerator}`;
    const rectId2 = `rectFilled${uniqueIdGenerator}`;
    const lineId = `line${uniqueIdGenerator}`;
    const rectHref1 = `#${rectId1}`;
    const rectHref2 = `#${rectId2}`;

    const fillColor = forReport ? 'black' : "#121622";
    const emptyColor = forReport ? "#F3F3F4" : "#d1d5db"
    const width = forReport ? 24 : 34
    let pill: JSX.Element = (
        <>
            <clipPath id={clipPathId1}>
                <rect x={(index * 85) - 2} y="18" width="38" height={pixelPercentage + 2} />
            </clipPath>
            <clipPath id={clipPathId2}>
                <rect x={(index * 85) - 2} y={pixelPercentage + 20} width="38" height={height + 20 - pixelPercentage} />
            </clipPath>
            <rect id={rectId1} x={index * 85} y="20" rx="17" ry="17" width={width} height={height} />
            <rect id={rectId2} x={index * 85} y="20" rx="17" ry="17" width={width} height={height} />
            <use clip-path={clipUrl1} href={rectHref1} fill={emptyColor} stroke={emptyColor} />
            <use clip-path={clipUrl2} href={rectHref2} fill={fillColor} stroke={fillColor} />
            {linePixelPercentage !== null &&
                <>
                    <line id={lineId} x1="35" y1={linePixelPercentage + 20} x2="48" y2={linePixelPercentage + 20} style={{ stroke: "#121622", strokeWidth: "3" }} />

                    {(lineText || linePercentage !== undefined) &&
                        <text x="50" y={linePixelPercentage + 24} stroke="#121622" fontSize="12" fontWeight="200">{lineText || linePercentage}</text>
                    }
                </>
            }
        </>
    );

    if (percentage === 100) {
        pill = <rect x={index * 85} y="20" rx="17" ry="17" width={width} height={height} fill={fillColor} stroke={fillColor} />;
    }

    if (percentage === 0) {
        pill = <rect x={index * 85} y="20" rx="17" ry="17" width={width} height={height} fill={emptyColor} stroke={emptyColor} />;
    }

    return (
        <>
            {value !== undefined &&
                <PillChartTopText value={value} index={index} forReport={forReport} />
            }

            {pill}

            {range &&
                <PillChartBottomText range={range} index={index} forReport={forReport} />
            }
        </>
    );
};



export default PillChart;