import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle"

SwiperCore.use([Pagination, Navigation]);

const COMPONENT_CLASS = "c-movement-tips";

const MovementTips: React.FC = () => {
    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__header`}>Helpful tips:</div>
            <Swiper
                spaceBetween={-100}
                centeredSlides={true}
                pagination={{ clickable: true }}
                navigation
                slidesPerView={1}>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        Make sure your device is not on mute.
                    </div>
                </SwiperSlide>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        Wear comfortable clothing that won't restrict your
                        movement.
                    </div>
                </SwiperSlide>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        Feel free to use a towel or yoga mat for comfort.
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default MovementTips;
