import React, { ReactNode } from "react";
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    styled,
    SelectChangeEvent,
    Radio,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RadioGroupItem } from "../radio-group/radio-group-item";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(0,0,0,0.6)'
        }
    }
})

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
    height: '46px',
    borderRadius: '10px',
    padding: '0 10px 0 10px',
    width: '200px',
    margin: '0 46px 0 0',
    '& .MuiInputBase-input': {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    '& .MuiTypography-root': {
        fontFamily: 'pragmatica-extended',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '22px',
    },
}));

const StyledSelectedLabel = styled(FormControlLabel)(({ theme }) => ({
    height: '46px',
    borderRadius: '10px',
    padding: '0 10px 0 10px',
    width: '200px',
    margin: '0 46px 0 0',
    background: 'rgba(18, 22, 34, 0.05)',
    '& .MuiInputBase-input': {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    '& .MuiTypography-root': {
        fontFamily: 'pragmatica-extended',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '22px',
    }
}));

interface UnboundRadioGroupProps {
    ariaLabel: string,
    buttonSpacing?: string,
    buttonWidth?: string,
    hidden?: boolean,
    items: RadioGroupItem[],
    label?: string,
    name: string,
    onChange: Function,
    value: RadioGroupItem | undefined,
}

/**
 * A radio group.
 */
export const UnboundRadioGroup: React.FC<UnboundRadioGroupProps> = (props) => {
    const {
        ariaLabel,
        buttonSpacing,
        buttonWidth,
        hidden,
        items,
        label,
        name,
        onChange,
        value,
    } = props;

    const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        const item: RadioGroupItem | undefined = items.find((i) => i.value.toString() === event.target.value);
        onChange(item);
    }

    return (
        <div style={{ display: 'block' }}>
            <span hidden={hidden}>
                <FormControl>
                    <label
                        id={name}
                        htmlFor={ariaLabel}
                        style={{
                            fontSize: '12px',
                            lineHeight: '22px',
                            marginBottom: '4px',
                            fontWeight: 500,
                        }}
                    >
                        {label}
                    </label>
                    <RadioGroup
                        aria-labelledby={ariaLabel}
                        name={name}
                        value={value?.value}
                        onChange={handleChange}
                    >
                        <div style={{ display: 'inline-block' }}>
                            <ThemeProvider theme={theme}>
                                {
                                    items.map((item: RadioGroupItem, index: number) => {
                                        const final = index + 1 === items.length;
                                        // console.log(index, final, item)
                                        if (item.value === value?.value) {
                                            return (
                                                <StyledSelectedLabel
                                                    key={`${name}-${item.label}`}
                                                    sx={{ width: buttonWidth, marginRight: final ? '0px' : buttonSpacing }}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            );
                                        } else {
                                            return (
                                                <StyledLabel
                                                    key={`${name}-${item.label}`}
                                                    sx={{ width: buttonWidth, marginRight: final ? '0px' : buttonSpacing }}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            );
                                        }
                                    })
                                }
                            </ThemeProvider>
                        </div>
                    </RadioGroup>
                </FormControl>
            </span>
        </div>
    );
};
