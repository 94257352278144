/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";


import { Loader } from "../loader/loader";
import Billing from "../billing/billing";
import AddOns from "./add-ons";
import Account from "./account/account-page";
import HighRiskRecommendations from "./high-risk-recommendations";
import OrganizationService from "../../utilities/services/organization-service";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { PlanType } from "../../models/enumerations/plan-type";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import UserUtil from "../../utilities/user-util";
import { useIntercom } from "react-use-intercom";
import Notifications from "./notifications";
import { PageTabs } from "../page-tabs/page-tabs";
import { TabsObject } from "../../models/interfaces/tabs-object";
// import { Button } from "../button/button";

const COMPONENT_CLASS = "c-settings";

const Settings: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const active = params.get("active");
    const { state } = useAuthState();
    const [products, setProducts] = useState<any>();
    const [isLoading] = useState(false);
    const [initialTab, setInitialTab] = useState(active ? active : "Account");
    const [tabsArray, setTabsArray] = useState<TabsObject<any>[]>([{ tab: "Account", component: Account },
    { tab: "High-Risk Recommendations", component: HighRiskRecommendations }])
    const db = getFirestore();

    // Intercom stuff
    const { boot, shutdown } = useIntercom();
    var CryptoJS = require("crypto-js");

    useEffect(() => {
        if (state.authenticated) {
            const customAttributes: any = {}
            if (state.user?.roles && state.user.roles.length > 0) {
                customAttributes["Symmio Role"] = state.user.roles[0];
            }
            if (state.organization.plan) {
                customAttributes["Plan Type"] = state.organization.plan
            }
            const hmac = generateHmac();
            boot({
                email: state.user?.email,
                userHash: hmac,
                customAttributes: customAttributes,
            })
        }
        else {
            shutdown()
        }
    }, [state.authenticated])

    const generateHmac = () => {

        // Ensure message and secretKey are not empty
        if (!state.user?.email || !process.env.REACT_APP_INTERCOM_VERIFICATION) {
            console.log("no user or secret key");
            return;
        }

        // Calculate HMAC using CryptoJS
        const hmac = CryptoJS.HmacSHA256(state.user.email, process.env.REACT_APP_INTERCOM_VERIFICATION).toString();

        return hmac;
    };

    // Get products/prices
    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, 'products'),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {
                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })
            });
            console.log("prod", products)
            setProducts(products);
        }
        getProductsAndPrices();
    }, [])

    useEffect(() => {
        const setNewTabs = async () => {
            const org = await OrganizationService.get(state.organization.id!);
            // if (org?.plan === PlanType[PlanType.Business]) {
            //     if (UserUtil.isAccountHolder(state.user) || UserUtil.isSuperAdmin(state.claims)) {
            //         setTabs([
            //             "Account",
            //             "Billing",
            //             "Add-Ons",
            //             "High-Risk Recommendations",
            //             "Notifications",
            //         ])
            //     } else if (UserUtil.isManager(state.user)) {
            //         setTabs([
            //             "Account",
            //             "Billing",
            //             "High-Risk Recommendations",
            //             "Notifications",
            //         ])
            //     }
            // }/
            // else 
            if (UserUtil.isAccountHolder(state.user) || UserUtil.isSuperAdmin(state.claims)) {
                if (org?.plan === PlanType[PlanType.Business]) {
                    setTabsArray([{ tab: "Account", component: Account },
                    { tab: "Billing", component: Billing, props: { products: products } },
                    { tab: "Add-Ons", component: AddOns, props: { products: products } },
                    { tab: "High-Risk Recommendations", component: HighRiskRecommendations },
                    { tab: "Notifications", component: Notifications }])
                } else {
                    setTabsArray([{ tab: "Account", component: Account },
                    { tab: "Billing", component: Billing, props: { products: products } },
                    { tab: "High-Risk Recommendations", component: HighRiskRecommendations },
                    { tab: "Notifications", component: Notifications }])
                }
            }
            else if (UserUtil.isAccountHolder(state.user) || UserUtil.isSuperAdmin(state.claims)) {
                setTabsArray([{ tab: "Account", component: Account },
                { tab: "Billing", component: Billing, props: { products: products } },
                { tab: "High-Risk Recommendations", component: HighRiskRecommendations },
                { tab: "Notifications", component: Notifications }])

                setInitialTab("Account");
            }
            else if ((UserUtil.isAccountHolder(state.user) || UserUtil.isAdmin(state.user) || UserUtil.isManager(state.user))) {
                setTabsArray([{ tab: "Account", component: Account },
                { tab: "High-Risk Recommendations", component: HighRiskRecommendations },
                { tab: "Notifications", component: Notifications }])

                setInitialTab("Account");
            }
            else {
                setTabsArray([{ tab: "Account", component: Account },
                { tab: "High-Risk Recommendations", component: HighRiskRecommendations }])

                setInitialTab("Account");
            }
        }
        setNewTabs()
    }, [state.organization.id, products])

    console.log(tabsArray);

    return (
        <div className={COMPONENT_CLASS}>
            {/* <h1>YOYOYO</h1>
            <p className="data-large">This is a data large</p>
            <div style={{ backgroundColor: "#000000" }}>
                <Button buttonStyle="white" buttonText="Switch Organization" />
            </div>
            <Button buttonStyle="primary" buttonText="Switch Organization" />
            <Button buttonStyle="secondary" buttonText="Switch Organization" />
            <Button buttonStyle="primary" buttonText="Switch Organization" disabled={true} />
            <Button type="back" buttonText="Back" />
            <Button type="back" buttonText="Back" buttonStyle="secondary" />
            <Button type="plus" buttonText="Add" />
            <Button buttonStyle="primary" buttonText="Switch Organization" disabled={true} />
            <Button buttonStyle="primary" buttonText="Switch Organization" disabled={true} />
            <Button type="link" buttonText="Cancel" />
            <Button
                buttonText="Get Started for Free"
                buttonStyle="blue" />
            <p style={{ fontSize: "24px" }}>tetetete</p>
            <h1 style={{ fontSize: "15px" }}>yoyoyoyoyo</h1>
            <h1>YOYOYO</h1> */}
            <Loader
                isVisible={isLoading} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>Settings</h1>
            </div>
            {products && (
                <PageTabs components={tabsArray} initialTab={initialTab} />
            )}
        </div>
    );

}

export default Settings;
