import { DateTime } from "luxon";
import { ReportSummaryData } from "../../models/interfaces/reports/report-summary-data";
import PillChartArray from "../charts/pill-chart/pill-chart-array";
import ReportGenericHeader from "./report-generic-header";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportSummaryProps {
    data: ReportSummaryData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportSummary: React.FC<ReportSummaryProps> = (props: ReportSummaryProps) => {
    const { data } = props;

    const riskSummaries = [{
        name: "Health",
        displayAs: "High",
        description: "Users in this category exhibit multiple or significant musculoskeletal health risk factors and need to seek a professional referral to a healthcare provider or a behavioral health specialist.",
    }, {
        name: "Wellness",
        displayAs: "Moderate",
        description: "Users in this category exhibit manageable musculoskeletal health risk factors and may need to be under the supervision of fitness, heathcare or nutritional specialists while improving their movement quality."
    }, {
        name: "Fitness",
        displayAs: "Slight",
        description: "Users in this category exhibit minimal musculoskeletal health risk factors, do not currently require professional guidance and should follow Symmio suggestions to progress toward individualized goals."
    }, {
        name: "Performance",
        displayAs: "Low",
        description: "Users in this category exhibit no current musculoskeletal health risk factors and should be encouraged to continue to monitor their health and wellness."
    }]

    return (
        <div className={`${COMPONENT_CLASS} -summary`}>
            <ReportGenericHeader companyName={data.organizationName} reportName="Summary Report" />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>USERS IN REPORT:</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>GROUPS:</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>TAGS:</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>

            </div>
            <div className={`${COMPONENT_CLASS}__history ${COMPONENT_CLASS}__section`}>
                <h2>Average Wellness Score History (Last 9 Months)</h2>
                <div className={`${COMPONENT_CLASS}__history__wrapper`}>
                    {data.history.map((item, index) => {
                        let className = `${COMPONENT_CLASS}__history__item `;
                        const date = DateTime.fromISO(item.date);
                        if (index === 0 && data.history.length > 2) {
                            const diff = item.percent - data.history[1].percent;
                            className += diff > 0 ? "positive" : diff < 0 ? "negative" : "neutral";
                        }
                        if (item.percent <= 0) {
                            className += " not-available";
                        }

                        return (
                            <div
                                className={className}
                                key={`history-${index}`}>
                                <div className={`${COMPONENT_CLASS}__history__item__score`}>{item.percent > 0 ? item.percent : "N/A"}</div>
                                <div className={`${COMPONENT_CLASS}__history__item__date`}>{index === 0 && date.diffNow("month").months > -1 ? "CURRENT" : date.toFormat("MMM").toUpperCase()}</div>
                            </div>
                        );
                    })}
                </div>
                <PillChartArray
                    forReport
                    data={data?.scoreBreakdown.map((score) => ({ range: `${score.name}`, value: score.count }))}
                />
            </div>
            <hr />
            <div className={`${COMPONENT_CLASS}__risk ${COMPONENT_CLASS}__section`}>
                <h2>Injury Risk Category Breakdown</h2>
                <div className={`${COMPONENT_CLASS}__risk__wrapper`}>
                    {data.riskFactorCodeCounts.map((risk, index) => (
                        <div
                            className={`${COMPONENT_CLASS}__risk__item ${risk.name.toLowerCase()}`}
                            key={`risk-${index}`}>
                            <div className={`${COMPONENT_CLASS}__risk__item__count`}>{risk.count}</div>
                            <div className={`${COMPONENT_CLASS}__risk__item__name`}>
                                <p>
                                    {riskSummaries.find((r) => r.name === risk.name)?.displayAs ?? risk.name}
                                </p>
                            </div>
                            <div className={`${COMPONENT_CLASS}__risk__item__description`}>
                                <p>
                                    {riskSummaries.find((r) => r.name === risk.name)?.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <div className={`${COMPONENT_CLASS}__focus ${COMPONENT_CLASS}__section`}>
                <div>
                    <h2>Focus Areas</h2>
                    <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                        {data.focusAreas.map((focus, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__focus__item`}
                                key={`focus-${index}`}>
                                <div className={`${COMPONENT_CLASS}__focus__item__count`}>{focus.count}</div>
                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{focus.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <h2>Movement Focus</h2>
                    <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                        {data.movementFocusAreas.map((focus, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__focus__item`}
                                key={`focus-${index}`}>
                                <div className={`${COMPONENT_CLASS}__focus__item__count`}>{focus.count}</div>
                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{focus.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportSummary;