import { DateTime } from "luxon";
import ReportSummary from "../../components/report/report-summary";
import { ReportSummaryData } from "../../models/interfaces/reports/report-summary-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Redirect } from 'react-router-dom';

const ReportSummaryTestPage: React.FC = () => {
    const { state } = useAuthState();
    if (!state.claims.superAdmin) {
        return <Redirect push to="/" />;
    }

    const data: ReportSummaryData = {
        // ReportAssessmentData fields:
        focusAreas: [
            {
                name: "Breathing Quality",
                count: 11,
            },
            {
                name: "Movement Health",
                count: 10,
            },
            {
                name: "Behavioral Health",
                count: 7,
            },
            {
                name: "Injury History",
                count: 6,
            },
            {
                name: "Physical Activity",
                count: 3,
            },
            {
                name: "Sleep Wellness",
                count: 3,
            },
            {
                name: "Body Composition",
                count: 2,
            },
            {
                name: "Nutrition Awareness",
                count: 1,
            },
        ],
        movementFocusAreas: [
            {
                name: "Toe Touch",
                count: 11,
            },
            {
                name: "Balance",
                count: 10,
            },
            {
                name: "Shoulder Mobility",
                count: 7,
            },
            {
                name: "Shoulder Clearing",
                count: 6,
            },
            {
                name: "Rotation",
                count: 3,
            },
            {
                name: "Spine Clearing",
                count: 3,
            },
            {
                name: "Squat",
                count: 2,
            },
            {
                name: "Pain",
                count: 1,
            },
        ],
        history: [
            { date: DateTime.local().plus({ months: -0 }).toISODate(), percent: 89 },
            { date: DateTime.local().plus({ months: -1 }).toISODate(), percent: 82 },
            { date: DateTime.local().plus({ months: -2 }).toISODate(), percent: 77 },
            { date: DateTime.local().plus({ months: -3 }).toISODate(), percent: 75 },
            { date: DateTime.local().plus({ months: -4 }).toISODate(), percent: 76 },
            { date: DateTime.local().plus({ months: -5 }).toISODate(), percent: 68 },
            { date: DateTime.local().plus({ months: -6 }).toISODate(), percent: 0 },
            { date: DateTime.local().plus({ months: -7 }).toISODate(), percent: -1 },
            { date: DateTime.local().plus({ months: -8 }).toISODate(), percent: -1 },
        ],
        pendingUsers: 2,
        riskFactorCodeCounts: [
            { count: 7, name: "Health", },
            { count: 12, name: "Wellness", },
            { count: 22, name: "Fitness", },
            { count: 5, name: "Performance", },
        ],
        scoreBreakdown: [
            { name: "0-9", count: 1 },
            { name: "10-19", count: 2 },
            { name: "20-29", count: 3 },
            { name: "30-39", count: 4 },
            { name: "40-49", count: 0 },
            { name: "50-59", count: 5 },
            { name: "60-69", count: 16 },
            { name: "70-79", count: 7 },
            { name: "80-89", count: 8 },
            { name: "90+", count: 10 },
        ],

        // BaseReportData fields:
        groupNames: [],
        organizationName: "Org Name that can be Longer",
        reportDate: "2022-05-06",
        tagNames: ["test1", "test2", "test3"],
        totalUsers: 46,
    }

    return <ReportSummary data={data} />;
}

export default ReportSummaryTestPage;
