import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";
import { Dialog } from "@headlessui/react";
import LoginMessage from "./login-modal-message";
import LoginModalTitle from "./login-modal-title";
import logo from "../../../assets/images/symmio-logos/symmio-icon-logo-light.png";
import LoginModalButtons from "./login-modal-buttons";
import LoginModalExtraMessage from "./login-modal-extra-message";
import { useHistory } from "react-router-dom";

export interface LoginModalProps {
    error: LoginError,
    onClose: Function,
    open: boolean,
    organization: string
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents the modal that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginModal: React.FC<LoginModalProps> = (props) => {
    const {
        error,
        onClose,
        open,
        organization,
    } = props;

    const history = useHistory();

    const handleClose = () => {
        onClose(false);
    };

    const handleFirstButtonClick = () => {
        if (error === LoginError.TrialExpired) {
            onClose(true);
        }
        if (error === LoginError.Deactivated) {
            onClose(true);
        }
        if (error === LoginError.AdminDeactivated) {
            onClose(true);
        }
        if (error === LoginError.AdminTrialExpired) {
            onClose(true);
        }
        if (error === LoginError.AdminDeactivatedBilling) {
            onClose(true);
        }
        if (error === LoginError.SubscriptionPaymentFailed) {
            onClose(false);
            history.replace("/reactivate-subscription");
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{ width: '978px', borderRadius: '30px' }}>
                        <div className={`${COMPONENT_CLASS}__header`} >
                            <img src={logo} alt="Logo" className={`${COMPONENT_CLASS}__header-image`} />
                        </div>
                        <div className="flex-block justify-center" >
                            <LoginModalTitle error={error} />
                            <LoginMessage
                                organization={organization}
                                error={error}
                            />
                            <LoginModalButtons
                                error={error}
                                onFirstButtonClick={handleFirstButtonClick}
                                onSecondButtonClick={() => onClose(false)}
                            />
                            <LoginModalExtraMessage error={error} />
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default LoginModal;