import React from "react";

const COMPONENT_CLASS = "c-inline-radio-input-option";

export interface InlineRadioInputOptionProps {
    onChange: (newAnswer: string) => void;
    number: string;
    name?: string;
    selected?: string;
}

const InlineRadioInputOption: React.FC<InlineRadioInputOptionProps> = (
    props
) => {
    return (
        <div className={`${COMPONENT_CLASS}`}>

            <input
                name={props.name}
                type="radio"
                id={`radio-${props.name}-${props.number}`}
                value={props.number}
                onChange={(e: any) => {
                    props.onChange(e.target.value);
                }}
                checked={(props.number === props.selected)}
            />
            {/* <label className={`${props.number === "0" ? "__radio-none" : "__radio"}`} htmlFor={`radio-${props.name}-${props.number}`}>{props.number === "0" ? "None" : props.number}</label> */}

            <label className="__radio" htmlFor={`radio-${props.name}-${props.number}`}>{props.number}</label>


            {/* <IonLabel>{props.number === "0" ? "None" : props.number}</IonLabel> */}
        </div>
    );
};

export default InlineRadioInputOption;
