import { HubspotSubCancellationData } from "../../models/interfaces/hubspot-sub-cancellation-data";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "hubspotSubCancellationData";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

const add = async (cancellationData: HubspotSubCancellationData, currentUser: User | null = null) => {
    return FirestoreService.add<HubspotSubCancellationData>(COLLECTION_NAME, cancellationData, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const HubspotSubCancellationDataService = {
    add,
};

export default HubspotSubCancellationDataService;

// #endregion Exports