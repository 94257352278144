import React from "react";
import { NumberTypeInputProps } from "./number-type-input.props";
import "./number-type-input.styles.scss";

export const NumberTypeInput: React.FC<NumberTypeInputProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let inputAttributes = {
        id: sanitizedId,
        placeholder: props.label,
        type: "number"
    }

    if (props.readonly) {
        inputAttributes = {
            ...inputAttributes,
            ...{
                readOnly: true,
                tabIndex: -1
            }
        }
    }

    // wire up our input to react-hook-form
    // https://react-hook-form.com/get-started
    if (props.registerHook) {
        if (props.registerOptions) {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId, props.registerOptions),
                ...(props.registerOptions.value && { value: props.registerOptions.value }),

            };
        } else {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId)
            };
        }
    }

    const preventMinus = (e: any) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e: any) => {
        const clipboardData = e.clipboardData || window.Clipboard;
        const pastedData = parseFloat(clipboardData.getData('text'));
        if (pastedData < 0 || isNaN(pastedData)) {
            e.preventDefault();
        }
    };

    return (
        <div className={`number-type-container ${props.className}`}>
            {(props.label && !props.hideLabel) &&
                <label htmlFor={sanitizedId}>
                    {props.label}
                </label>
            }
            {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
            <input
                {...inputAttributes}
                className={`${props.errorState && "field-error"} ${props.inputClassName}`}
                aria-invalid={props.errorState ? "true" : "false"}
                data-testid="input-test"
                style={props.style}
                {...(props.defaultValue !== undefined ? { defaultValue: props.defaultValue } : {})}
                onKeyDown={preventMinus}
                onPaste={preventPasteNegative}
                {...(props.customKey !== undefined ? { key: props.customKey } : {})}
            />
        </div>
    )
};
