const COMPONENT_CLASS = "c-lifestyle-tips";

export interface LifestyleIntroProps {
    questionGroupNames?: string[];
}

const ScreenTests: React.FC<LifestyleIntroProps> = (props) => {
    return (
        <div className={`${COMPONENT_CLASS} scroll-container`}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h3>Assessment Areas:</h3>
            </div>
            {props.questionGroupNames &&
                props.questionGroupNames.map((group, index) => (
                    <div className={`${COMPONENT_CLASS}__item`} key={index}>
                        {group}
                    </div>
                ))}
        </div>
    );
};

export default ScreenTests;
