/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Question } from "../../../models/interfaces/questions/question";
import List from "@mui/material/List/List";

const COMPONENT_CLASS = "c-multiple-choice";

export interface MultipleChoiceProps {
    initialAnswer?: string;
    handleAnswerChange: (selected: string, question: Question) => void;
    question: Question;
}

interface Option {
    value: string;
    label: string;
    isSelected: boolean;
}

const MultipleChoice: React.FC<MultipleChoiceProps> = (props) => {
    const [options, setOptions] = useState<Option[]>([]);
    const CLASS_LAYOUT =
        options.length > 80 ? "" : `${COMPONENT_CLASS}__horizontal`;

    useEffect(() => {
        setOptions(getOptions());
    }, []);

    const getOptions = (): Option[] => {
        const results: Option[] = [];
        const initialAnswers: string[] =
            props.initialAnswer == null ? [] : props.initialAnswer.split(",");

        props.question?.answerOptions?.map((answerOption) => {
            results.push({
                value: answerOption.answerId,
                label: answerOption.answerText,
                isSelected:
                    initialAnswers.filter((x) => x === answerOption.answerId)
                        .length >= 1,
            });
        });

        return results;
    };

    const handleChange = (option: Option, event: any): void => {
        let selectedOption = options.filter((x) => x.value === option.value)[0];
        if (selectedOption.isSelected) {
            selectedOption.isSelected = false;
        }
        else {
            selectedOption.isSelected = true;
        }
        setOptions(options);
        props.handleAnswerChange(getAnswer(), props.question);
    };

    const getAnswer = (): string => {
        const selectedValues = options
            .filter((x) => x.isSelected)
            .map((x) => x.value);
        return selectedValues.join(",");
    };
    return (
        <div
            className={`${COMPONENT_CLASS}`}
            data-testid="multiple-choice-wrapper">
            <div className={`${CLASS_LAYOUT}`}>
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 400,
                        textAlign: "center",
                        '& ul': { padding: 0 },
                    }}>
                    {
                        // if
                        options && options.map((option, index) => {
                            return (
                                <>
                                    <input style={{ display: "none" }} type="checkbox" className={`${COMPONENT_CLASS}__checkbox`}
                                        name={option.value}
                                        value={option.value}
                                        checked={option.isSelected}

                                    />
                                    <label onClick={(e) => handleChange(option, e)} className={`${COMPONENT_CLASS}__checkbox-label`} htmlFor={`${option.value}`}>{option.label}</label>

                                </>

                            );
                        })

                    }
                </List >
                {/* {
                    // if
                    options &&
                    options.length >= 1 &&
                    options.map((option, index) => {
                        return (
                            <input
                                type="checkbox"
                                className={`${COMPONENT_CLASS}__checkbox`}
                                value={option.value}
                                checked={option.isSelected}
                                onClick={(e) => handleChange(option, e)}
                            />
                            // <IonItem
                            //     lines="none"
                            //     data-testid="multiple-choice"
                            //     key={index}>
                            //     <IonLabel
                            //         className="ion-text-wrap"
                            //         style={{
                            //             color:
                            //                 option.isSelected &&
                            //                 "rgba(255, 255, 255, 0.8)",
                            //         }}>
                            //         {option.label}
                            //     </IonLabel>
                            //     <IonCheckbox
                            //         className={`${COMPONENT_CLASS}__checkbox`}
                            //         value={option.value}
                            //         checked={option.isSelected}
                            //         onClick={(e) => handleChange(option, e)}
                            //     />
                            // </IonItem>
                        );
                    })
                } */}
            </div>
        </div>
    );
};

export default MultipleChoice;
