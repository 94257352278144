import GlobalStateError from "../models/interfaces/global-state-error";
import DateUtil from "./date-util";

export default class GlobalStateUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static createError(
        description: string,
        error: any,
        url?: string
    ): GlobalStateError {
        return {
            description: description,
            dateMs: DateUtil.getCurrentMsDate(),
            error: error,
            errorCode: error?.code,
            message: error?.message,
            url: url ?? error?.request?.responseURL,
        };
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    // #endregion Private Methods
}
