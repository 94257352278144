import React, { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import ReactivatePlanContent from "../../components/deactivated-account-page/reactivate-plan-content";
import ReactivateAddOnContent from "../../components/deactivated-account-page/reactivate-add-on-content";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import UserUtil from "../../utilities/user-util";
import { Redirect } from "react-router-dom";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import ReactivateUserSubscription from "../../components/deactivated-account-page/reactivate-user-subscription";

export interface DeactivatedAccountPageProps {
}

const COMPONENT_CLASS = 'p-deactivated-account';

/**
 * A modal that shows up when the user's subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const DeactivatedAccountPage: React.FC<DeactivatedAccountPageProps> = (props) => {
    const db = getFirestore();
    const { state } = useAuthState();
    const [products, setProducts] = useState<any>();
    const organization = state.organization;
    const user = state.user;
    const showCancelPlanModal = organization?.showDeactivatedAccountPlanModal || false;
    const showCancelAddOnModal = organization?.showDeactivatedAccountAddOnModal || false;
    const isAccountHolder = UserUtil.isAccountHolder(user);
    const environment = process.env.NODE_ENV as string;
    const isUserSubscriptionFlow = user && user.subscription && user.subscription.didPaymentFail;

    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, (environment === "production" ? "products" : "productsStaging")),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {
                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })
            });
            await setProducts(products);
        }

        if (!isUserSubscriptionFlow) {
            getProductsAndPrices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if ((!state.authenticated || !organization || !isAccountHolder) && !isUserSubscriptionFlow) {
        return <Redirect to="/login" />;
    }

    return (
        <BaseCardLayout>
            {isUserSubscriptionFlow
                ?
                <div className={COMPONENT_CLASS}>
                    <ReactivateUserSubscription COMPONENT_CLASS={COMPONENT_CLASS} user={user} />
                </div>
                :
                <div className={COMPONENT_CLASS}>
                    {showCancelPlanModal
                        ?
                        <ReactivatePlanContent
                            COMPONENT_CLASS={COMPONENT_CLASS}
                            organization={organization}
                            wasAddOnCancelled={showCancelAddOnModal}
                            products={products}
                        />
                        :
                        (showCancelAddOnModal
                            ?
                            <ReactivateAddOnContent
                                COMPONENT_CLASS={COMPONENT_CLASS}
                                organization={organization}
                                products={products}
                            />
                            :
                            <></>)
                    }
                </div>
            }
        </BaseCardLayout>
    );
};

export default DeactivatedAccountPage;