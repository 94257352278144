/* eslint-disable no-unused-vars */
import React, { ChangeEventHandler, FocusEventHandler, ChangeEvent } from "react";
import {
    FormControl,
    styled,
} from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

interface UnboundTextFieldProps {
    disabled?: boolean,
    error?: boolean | undefined,
    helperText?: string | undefined,
    hidden?: boolean,
    label?: string,
    name: string,
    onBlur?: Function,
    onChange: Function,
    readOnly?: boolean,
    required?: boolean,
    value: string | number | undefined,
    rows?: number,
    startAdornment?: string,
}


const StyledTextField = styled(MuiTextField)(({ theme, error }) => ({
    fontFamily: 'pragmatica-extended',
    height: '46px',
    borderRadius: '30px',
    '& .MuiInputBase-root': {
        fontFamily: 'pragmatica-extended',
        height: '46px',
        borderRadius: '30px',
        fontWeight: 300,
        fontSize: '14px',
    },
    '& .MuiInputBase-input': {
        paddingLeft: '20px',
        paddingRight: '20px',
    }
}));

const StyledAdornedTextField = styled(MuiTextField)(({ theme, error }) => ({
    fontFamily: 'pragmatica-extended',
    height: '46px',
    borderRadius: '30px',
    '& .MuiInputBase-root': {
        fontFamily: 'pragmatica-extended',
        height: '46px',
        borderRadius: '30px',
        fontWeight: 300,
        fontSize: '14px',
    },
    '& .MuiInputBase-input': {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    '& .MuiInputBase-adornedStart': {
        paddingLeft: '20px'
    }
}));

/**
 * A text field unbound from any context.
 */
export const UnboundTextField: React.FC<UnboundTextFieldProps> = (props) => {
    const {
        disabled,
        error,
        helperText,
        hidden,
        label,
        name,
        onBlur,
        onChange,
        readOnly,
        required,
        rows,
        startAdornment,
        value,
    } = props;

    const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
    }

    const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e: React.ElementRef<any>) => {
        if (e && onBlur) {
            onBlur(e);
        }
        return;
    }

    return (
        <>
            <span hidden={hidden}>
                <FormControl
                    fullWidth
                    error={error}
                    required={required}
                    disabled={disabled}
                >
                    <label
                        id={`${name}-label`}
                        htmlFor={name}
                        style={{
                            fontSize: '12px',
                            lineHeight: '22px',
                            marginBottom: '4px',
                            fontWeight: 500,
                        }}
                    >
                        {`${label}${required ? ' *' : ''}`}
                    </label>
                    {
                        startAdornment ? (
                            <StyledAdornedTextField
                                id={name}
                                disabled={disabled}
                                required={required}
                                fullWidth
                                multiline={(rows && rows > 1) ? true : false}
                                rows={rows ?? 1}
                                error={error}
                                name={name}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={helperText}
                                value={value ?? ''}
                                InputProps={{
                                    startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                                }}
                                inputProps={{ readOnly }}
                            />
                        ) : (
                            <StyledTextField
                                id={name}
                                disabled={disabled}
                                required={required}
                                fullWidth
                                multiline={(rows && rows > 1) ? true : false}
                                rows={rows ?? 1}
                                error={error}
                                name={name}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={helperText}
                                value={value ?? ''}
                                inputProps={{ readOnly }}
                            />
                        )
                    }
                </FormControl>
            </span>
        </>
    );
};
