import React from "react";

const COMPONENT_CLASS = "c-assessment-bar-chart";

export interface AssessmentBarChartProps {
    percentage: number;
    oldPercentage?: number;
    averagePercentage?: number;
}

const AssessmentBarChart: React.FC<AssessmentBarChartProps> = (props) => {
    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__bar`}>
                <div className={`${COMPONENT_CLASS}__bar-fill`} style={{ width: `${props.percentage + 1}%` }}></div>
            </div>
            {/* <div className={`${COMPONENT_CLASS}__marker -average`} style={{ left: `${props.averagePercentage}%` }}></div> */}
            <div className={`${COMPONENT_CLASS}__marker`} style={{ left: `${props.percentage}%` }}></div>
        </div>
    );
};

export default AssessmentBarChart;