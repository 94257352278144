import { Timestamp, doc, updateDoc } from "firebase/firestore";
import getFirebase from "../firebase";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import { IUser, User } from "../../models/interfaces/assessment-user";
import { User as PortalUser } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "users";
const { db } = getFirebase();

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new user to the users collection
 * @param {IUser} user - The user that is being added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<IUser>} A promise to the newly added user
 */
const add = async (user: IUser, currentUser: any | null = null) => {
    return FirestoreService.add<IUser>(COLLECTION_NAME, user, currentUser);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<IUser>(COLLECTION_NAME, id);
};

const getBy = async (conditions: FirestoreCondition[]) => {
    return FirestoreService.getBy<IUser>(COLLECTION_NAME, conditions);
};

/**
 * Get all of the users stored in the database
 * @returns {Promise<IUser[]>} A promise for the collection of users
 */
const getAll = async () => {
    return FirestoreService.getAll<IUser>(COLLECTION_NAME);
};

/**
 * Get a snapshot of the specific user to see when it changes
 * @param {string} id - Id of the user document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<IUser>(COLLECTION_NAME, id, listener);
};

/**
 * Update the specified user stored in the database
 * @param {IUser} user - The user that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<IUser>} A promise for the user that is being updated
 */
const update = async (user: IUser, currentUser: any | null = null) => {
    return FirestoreService.update<IUser>(
        COLLECTION_NAME,
        JSON.parse(JSON.stringify(user, currentUser))
    );
};

/**
 * Update the specified user stored in the database
 * @param {IUser} user - The user that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<IUser>} A promise for the user that is being updated
 */
const updateByPortalUser = async (user: PortalUser, currentUser: any | null = null) => {
    return FirestoreService.update<IUser>(
        COLLECTION_NAME,
        JSON.parse(JSON.stringify(user, currentUser))
    );
};

/**
 * Update the specified field(s) of a user stored in the database. This method
 * is used to prevent overwriting information that was changed in the admin
 * portal. For example, if a user's organization is switched in the admin
 * portal, we don't want to overwrite that by updating the user with the
 * UserService.update method.
 * @param {IUser} user - The user that is being updated
 * @param {object} fields - The fields to be updated
 * @param {firebase.User} currentUser - The user that is logged into the
 * application
 * @returns {Promise<IUser>} A promise for the user that is being updated
 */
const updateFields = async (user: IUser, fields: object, currentUser: any | null = null) => {
    const docRef = doc(db, COLLECTION_NAME, user.id!);
    const updateData = {
        ...fields,
        updated: Timestamp.now(),
        updatedBy: currentUser.id ?? '',
    };
    
    updateDoc(
        docRef,
        updateData
    );
};

/**
 * Updates the specified user stored in the database from a User class
 * @param user A fully hydrated User object
 * @param currentUser The user that is logged into the application
 */
const updateFromUserClass = async (
    user: User,
    currentUser: any | null = null
) => {
    const userObject = Object.assign({}, user);
    return FirestoreService.update<IUser>(
        COLLECTION_NAME,
        userObject,
        currentUser
    );
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UserService = {
    add,
    deleteById,
    get,
    getAll,
    getBy,
    getSnapshot,
    update,
    updateByPortalUser,
    updateFields,
    updateFromUserClass,
};

export default UserService;

// #endregion Exports
