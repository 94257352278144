// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Creates an array of integers from the inclusive min to max
 * @param  {number} min
 * @param  {number} max
 * @returns number
 */
function _createRangeList(min: number, max: number): number[] {
    const results: number[] = [];

    for (let i = min; i <= max; i++) {
        results.push(i);
    }

    return results;
}

/**
 * Returns the highest non-null number (if none, returns undefined)
 * @param  {number[]} numbers
 * @returns number
 */
function _highestNumber(numbers: number[]): number {
    const sortedNumbers = numbers.filter((n) => n != null).sort();
    return sortedNumbers[sortedNumbers.length - 1];
}

/**
 * Returns the lowest non-null number (if none, returns undefined)
 * @param  {number[]} numbers
 * @returns number
 */
function _lowestNumber(numbers: number[]): number {
    const sortedNumbers = numbers.filter((n) => n != null).sort();
    return sortedNumbers[0];
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const NumberUtils = {
    createRangeList: _createRangeList,
    highestNumber: _highestNumber,
    lowestNumber: _lowestNumber,
};

// #endregion Exports
