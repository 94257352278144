/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { User } from '../../models/interfaces/user';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import LinkAccountSetupScreen from '../../components/link-account-setup-screen/link-account-setup-screen';
import { DateTime } from 'luxon';
import { LeadLink } from '../../models/interfaces/lead-link';
import { DeeplinkStatus } from '../../models/enumerations/deeplink-status';
import LeadLinksService from '../../utilities/services/lead-links-service';
import errorMessageLogo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import FunctionService from '../../utilities/services/function-service';
import { collection, doc, getFirestore } from 'firebase/firestore';
import { TextTypeInput } from '../../components/forms';
import { SubscriptionLink } from '../../models/interfaces/subscription-link';
import SubscriptionLinkService from '../../utilities/services/subscription-link-service';

const COMPONENT_CLASS = "p-link-account-creation";
const LEAD_CAPTURE_LINK_TYPE = "leadCapture";
const SUBSCRIPTION_LINK_TYPE = "subscriptionLink";

interface LinkAccountCreationParams {
    id: string;
    type?: string;
}

const LinkAccountCreation: React.FC = () => {
    const db = getFirestore();
    const { id, type } = useParams<LinkAccountCreationParams>();
    const isLeadCaptureLink = type === LEAD_CAPTURE_LINK_TYPE;
    const isSubscriptionLink = type === SUBSCRIPTION_LINK_TYPE;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, setState } = useAuthState();
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    let history = useHistory();
    const [user, setUser] = useState<User>();
    const [linkErrorMessageHeader, setLinkErrorMessageHeader] = useState("Invalid Link");
    const [linkErrorMessageBody, setLinkErrorMessageBody] = useState("The link is not valid.");
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (state.authenticated) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getLeadCaptureLink = async () => {
            let fetchedLink: LeadLink | null = null;

            try {
                fetchedLink = await LeadLinksService.get(id);
                if (fetchedLink) {
                    fetchedLink.clicksTotalCount !== undefined ? (fetchedLink.clicksTotalCount += 1) : (fetchedLink.clicksTotalCount = 1);

                    const currentYear = (new Date()).getUTCFullYear();
                    const currentMonthIndex = (new Date()).getUTCMonth();

                    if (fetchedLink.performance && fetchedLink.performance[currentYear][currentMonthIndex]) {
                        fetchedLink.performance[currentYear][currentMonthIndex].clicksCount += 1;
                    }
                    else {
                        fetchedLink.performance = {
                            [currentYear]: {
                                [currentMonthIndex]: {
                                    clicksCount: 1,
                                    leadsCount: 0,
                                    starterCount: 0,
                                    businessCount: 0,
                                    enterpriseCount: 0,
                                }
                            }
                        }
                    }

                    await FunctionService.updateLeadLink(fetchedLink);
                }
            } catch (err) {
                console.error("Cannot fetch the lead capture link ID", err);
                setShowErrorModal(true);
            } finally {
                await checkLeadLinkValidity(fetchedLink);
            }
        }

        const getSubscriptionLink = async () => {
            let fetchedLink: SubscriptionLink | null = null;

            try {
                fetchedLink = await SubscriptionLinkService.get(id);
                if (fetchedLink) {
                    await FunctionService.updateLeadLink(fetchedLink);
                }
            } catch (err) {
                console.error("Cannot fetch the subscription link ID", err);
                setShowErrorModal(true);
            } finally {
                await checkLeadLinkValidity(fetchedLink);
            }
        }

        if (isLeadCaptureLink) {
            getLeadCaptureLink();
        }
        else if (isSubscriptionLink) {
            getSubscriptionLink();
        }
    }, [id, isLeadCaptureLink, isSubscriptionLink])

    const checkLeadLinkValidity = async (link: LeadLink | SubscriptionLink | null) => {
        const nowUtc = DateTime.now().toUTC();

        if (!link) {
            setShowErrorModal(true);
            return;
        }

        const startDate = link.startDate ? DateTime.fromISO(link.startDate) : null;
        const endDate = link.endDate ? DateTime.fromISO(link.endDate) : null;

        const errorFutureHeader = "Opening Soon";
        const errorFutureBody = `The enrollment date is set to start ${startDate?.toLocaleString(DateTime.DATE_FULL)}.\nPlease return at a later date to perform your functional wellness assessment.`;

        const errorExpiredHeader = "Link Expired";
        const errorExpiredBody = "The enrollment date has passed.\nPlease contact your Symmio administrator to request a new link.";

        const errorInactiveHeader = "Link Deactivated";
        const errorInactiveBody = "The enrollment link has been deactivated.\nPlease contact your Symmio administrator to request a new link.";

        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setLinkErrorMessageHeader(errorFutureHeader);
            setLinkErrorMessageBody(errorFutureBody);
            setShowErrorModal(true);
            return;
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setLinkErrorMessageHeader(errorExpiredHeader);
            setLinkErrorMessageBody(errorExpiredBody);
            setShowErrorModal(true);
            return;
        }

        // If the lead capture link is not active
        else if (link.status !== DeeplinkStatus.Active) {
            setLinkErrorMessageHeader(errorInactiveHeader);
            setLinkErrorMessageBody(errorInactiveBody);
            setShowErrorModal(true);
            return;
        }
    }

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let { firstName, lastName } = data;

        // Capitalize first letter
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
        let user = {
            id: doc(collection(db, 'users')).id,
            firstName: firstName,
            lastName: lastName,
        } as User;

        setUser(user);

        setIsCompleted(true);
    }

    const errorMessage = (
        <BaseCardLayout>
            <div className="p-landing__error">
                <div className="p-landing__error-content-container">
                    <div className="p-landing__error-image-container">
                        <img src={errorMessageLogo} alt="Symmio Logo" />
                    </div>

                    <div className="p-landing__error-message-container">
                        <h1>{linkErrorMessageHeader}</h1>
                        <p className="whitespace-pre-line">{linkErrorMessageBody}</p>
                    </div>
                </div>
            </div>
        </BaseCardLayout>
    );

    return (
        <>
            {
                // if
                isCompleted && (
                    <LinkAccountSetupScreen user={user!} />
                )
            }
            {
                // if
                !isCompleted && (
                    showErrorModal
                        ?
                        errorMessage
                        :
                        <BaseCardLayout>
                            <div className="flex justify-center" style={{ marginTop: "2.3%", marginBottom: "14rem" }}>
                                <div className={`${COMPONENT_CLASS}`}>

                                    <h4 className="-light">Get Started</h4>
                                    <p>Welcome to Symmio! Let’s set up your profile.</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className={`${COMPONENT_CLASS}__content`}>
                                            <div className={`${COMPONENT_CLASS}__field`}>
                                                {errors.firstName && (
                                                    <div
                                                        role="alert"
                                                        className={`${COMPONENT_CLASS} -error`}>
                                                        First name is required
                                                    </div>
                                                )}
                                                <TextTypeInput
                                                    id='firstName'
                                                    label='First Name'
                                                    hideLabel={true}
                                                    type='text'
                                                    inputClassName={`c-input__input -displaytext ${errors.firstName &&
                                                        `${COMPONENT_CLASS} -showerror`
                                                        }`}
                                                    registerHook={register}
                                                    registerOptions={{
                                                        required: true
                                                    }}
                                                    errorState={errors.firstName} />
                                            </div>
                                            <div className={`${COMPONENT_CLASS}__field`}>
                                                {errors.lastName && (
                                                    <div
                                                        role="alert"
                                                        className={`${COMPONENT_CLASS} -error`}>
                                                        Last name is required
                                                    </div>
                                                )}
                                                <TextTypeInput
                                                    id='lastName'
                                                    label='Last Name'
                                                    hideLabel={true}
                                                    type='text'
                                                    inputClassName={`c-input__input -displaytext ${errors.lastName &&
                                                        `${COMPONENT_CLASS} -showerror`
                                                        }`}
                                                    registerHook={register}
                                                    registerOptions={{
                                                        required: true
                                                    }}
                                                    errorState={errors.lastName} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div style={{ marginTop: "5%", width: "100%" }}>
                                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText="Next"></BaseCardLayoutActions>
                            </div>
                        </BaseCardLayout>
                )
            }
        </>
    );
}
export default LinkAccountCreation;