import ReportScore from "../../components/report/report-score";
import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Redirect } from 'react-router-dom';

const ReportScoreTestPage: React.FC = () => {
    const { state } = useAuthState();
    if (!state.claims.superAdmin) {
        return <Redirect push to="/" />;
    }

    const data: ReportDetailData = {
        // ReportDetailData fields:
        focusAreas: ["Movement Health", "Behavioral Health"],
        healthCategories: ["Wellness", "Health"],
        isInPain: true,
        users: [
            { name: "first3 last3", email: "assessment@test.com", tags: [], inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65 },
            { name: "First2 Last2", email: "assessment@test.com", tags: [], inPain: false, movementScore: 55, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 55 },
            { name: "FIRST4 LAST4", email: "assessment@test.com", tags: [], inPain: true, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65 },
            { name: "First5 Last5", email: "assessment@test.com", tags: [], inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75 },
            { name: "First1 Last1", email: "assessment@test.com", tags: [], inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60 },
        ],

        // BaseReportData fields:
        groupNames: ["Group Name"],
        organizationName: "Org Name that can be Longer",
        reportDate: "2022-05-11",
        tagNames: ["Tag Name"],
        totalUsers: 20,
    }

    return <ReportScore data={data} />;
}

export default ReportScoreTestPage;
