import { Button } from "../button/button";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as ImportantIcon } from "../../assets/icons/nav/icon_important.svg";

const COMPONENT_CLASS = "c-billing";

export interface WarningItemProps {
  title: string;
  text: string;
  textColor?: string;
  iconColor: string;
  backgroundColor: string;
  onClose?: any;
  buttonText?: string;
  onButtonClick?: any;
  marginBottom?: string;
  isButtonDisabled?: boolean;
}

const WarningItem: React.FC<WarningItemProps> = (props) => {
  const warningMessageStyle: React.CSSProperties = {
    width: '100%',
    backgroundColor: props.backgroundColor,
    height: 'auto',
    marginTop: '1rem',
    marginBottom: props.marginBottom ? props.marginBottom : '2rem',
    padding: '1rem',
    flexDirection: 'column',
    borderRadius: '10px',
    display: 'flex'
  };

  return (
    <div style={warningMessageStyle}>
      <div className={`${COMPONENT_CLASS}__warning_header`} >
        <div>
          <ImportantIcon className={`${COMPONENT_CLASS}__warning_header__icon`} style={{ fill: props.iconColor }} />
        </div>
        <h5 style={{ color: props.textColor ?? props.iconColor }}>
          {props.title}
        </h5>
        {
          props.onClose && (
            <div className={`${COMPONENT_CLASS}__warning_header__close`} onClick={props.onClose}>
              <XIcon />
            </div>
          )
        }
      </div>
      <div className={`${COMPONENT_CLASS}__warning_content`}>
        <h5 >
          {props.text}
        </h5>
        {props.buttonText && props.onButtonClick &&
          <div className={`${COMPONENT_CLASS}__warning_content_button`}>
            <Button
              disabled={props.isButtonDisabled}
              type="default"
              buttonText={props.buttonText}
              onClick={props.onButtonClick} />
          </div>
        }
      </div>
    </div>
  );
}

export { WarningItem };
