
/**
     * Converts a string into a valid file name that can be used in most OS's. Any special characters not valid for use in a filename 
     * will be substituted with an underscore "_". Any filename that can't be made valid will return as "Symmio-file-download"
     * This will not validate if the file extension is valid
     * 
     * @param {string} fileName - The desired fileName
     * @returns {string} -  a valid fileName
     */

export const getValidFileName = (fileName: string) => {
    const regex = /^[a-zA-Z0-9_\-.]+(\.[a-zA-Z0-9]+)?$/;
    const isValid = regex.test(fileName);

    if (fileName == null) {
        return 'symmio-download';
    }

    if (isValid) {
        return fileName;
    }

    // Invalid characters found, replace them with underscores
    fileName = fileName.replace(/[^a-zA-Z0-9_\-.]/g, '_');

    //replace any double unederscores with single underscores
    fileName = fileName.replace(/_{2,}/g, '_');

    return fileName;
}
