import React, { FocusEventHandler, ReactNode } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    styled,
    SelectChangeEvent,
} from '@mui/material';
import { NumericListOptions } from "../../models/interfaces/numeric-list-options";
import { ListOptions } from "../../models/interfaces/list-options";

const StyledSelect = styled(Select)(({ theme, error }) => ({
    fontFamily: 'pragmatica-extended',
    fontWeight: 300,
    height: '46px',
    borderRadius: '30px',
    fontSize: '14px',
    lineHeight: '22px',
    '& .MuiInputBase-input': {
        paddingLeft: '20px',
        paddingRight: '20px',
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontWeight: 300,
}));


interface SelectProps {
    disabled?: boolean,
    error?: boolean | undefined,
    helperText?: string,
    hidden?: boolean,
    items: NumericListOptions[] | ListOptions[],
    label?: string,
    name: string,
    onBlur?: Function,
    includeNone?: boolean,
    onChange: Function,
    readOnly?: boolean,
    required?: boolean,
    value: NumericListOptions | ListOptions | undefined,
}

/**
 * A select form control that provides a drop down of
 * label value pairs of which one is selectable.
 */
export const UnboundSelectField: React.FC<SelectProps> = (props) => {
    const {
        disabled,
        error,
        helperText,
        hidden,
        items,
        label,
        name,
        onBlur,
        includeNone,
        onChange,
        readOnly,
        required,
        value,
    } = props;

    const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        if (includeNone && event.target.value === 'nothingOption') {
            onChange(null);
        }

        if (typeof event.target.value === 'string') {
            const tempItems = items as ListOptions[];
            const item: ListOptions | undefined = tempItems.find((i: ListOptions) => i.value === event.target.value)
            onChange(item);
        }

        if (typeof event.target.value === 'number') {
            const tempItems = items as NumericListOptions[];
            const item: NumericListOptions | undefined = tempItems.find((i: NumericListOptions) => i.value === event.target.value)
            onChange(item);
        }
    }

    const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e: React.ElementRef<any>) => {
        if (e && onBlur) {
            onBlur(e);
        }
    }

    return (
        <>
            <span hidden={hidden}>
                <FormControl
                    fullWidth
                    error={error}
                    required={required}
                    disabled={disabled}
                >
                    <label
                        id={name}
                        style={{
                            fontSize: '12px',
                            lineHeight: '22px',
                            marginBottom: '4px',
                        }}
                    >
                        {`${label}${required ? ' *' : ''}`}
                    </label>
                    <StyledSelect
                        readOnly={readOnly}
                        labelId={name}
                        id={name}
                        value={value ? value.value : 'nothingOption'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {
                            (includeNone && items.length >= 1) ? (
                                <StyledMenuItem value="nothingOption" key={Math.random().toString()}>
                                    <em style={{ color: 'rgba(18, 22, 34, 0.4)', fontWeight: 300 }}>Select one...</em>
                                </StyledMenuItem>
                            ) : null
                        }
                        {
                            items && items.map((x) => (
                                <StyledMenuItem
                                    value={x.value}
                                    key={x.label}
                                >
                                    {x.label}
                                </StyledMenuItem>
                            ))
                        }
                    </StyledSelect>
                    <FormHelperText sx={{ height: 0 }}>{helperText ?? ''}</FormHelperText>
                </FormControl>
            </span>
        </>
    );
};
