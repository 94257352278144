import React from "react";

export interface RequestModalMessageProps {
    newRequest: boolean,
}

/**
 * Represents the message that is shown in the request modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RequestModalMessage: React.FC<RequestModalMessageProps> = (props) => {
    const { newRequest } = props;

    let message = '';

    if (newRequest) {
        message = 'Your request has been sent. A Symmio account specialist will be in touch shortly.';
    } else {
        message = 'Your request has been received and your account rep will contact you shortly.'
    }

    return (
        <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}</p>
    );
};

export default RequestModalMessage;