import React from 'react';
import { ReactComponent as XIcon } from "../../assets/icons/nav/icon_x_whitebg.svg";


const COMPONENT_CLASS = "c-tag";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface TagProps {
    children: React.ReactNode;
    id: string;
    onCloseClick?: (id: string) => void;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Tag: React.FC<TagProps> = (props: TagProps) => {
    return (
        <div
            className={`${COMPONENT_CLASS} ${props.onCloseClick == null ? "-small" : ""}`}
            onClick={(e) => {
                if (props.onCloseClick) {
                    props.onCloseClick(props.id);
                }
            }}>
            <div className={`${COMPONENT_CLASS}__content`}>
                {props.children}
            </div>
            {props.onCloseClick &&
                <XIcon className={`${COMPONENT_CLASS}__close-icon`} />
            }
        </div>
    );
}

export { Tag };
