import { CalculateLifestyleAssessment } from "../../../models/interfaces/scores/calculate-lifestyle-assessment";
import { LifestyleAssessmentResponse } from "../../../models/interfaces/scores/lifestyle-assessment-response";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalculateLifestyleQueryParams {}

export interface CalculateLifestylePathParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/calculator`;
const assessmentEndpoint = `${baseEndpoint}/lifestyle-assessment`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CalculateLifestyleService = {
    /**
     * Calculate a total lifestyle assessment
     */
    assessment: ServiceBase.post<
        LifestyleAssessmentResponse,
        CalculateLifestyleAssessment
    >(assessmentEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CalculateLifestyleService;

// #endregion Exports
