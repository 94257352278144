import React from 'react';

const COMPONENT_CLASS = "c-loader";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface LoaderProps {
    className?: string;
    isVisible: boolean;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
    return (
        <div className={`${COMPONENT_CLASS} ${props.className || ''} ${props.isVisible ? '-active' : ''}`}>
            <div className={`${COMPONENT_CLASS}__overlay`}></div>
            <div className={`${COMPONENT_CLASS}__content`}>
            </div>
        </div>
    );
}

export { Loader };
