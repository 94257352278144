import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useGlobalState } from "../../utilities/hooks/use-global-state";
import { CategoryIds } from "../../models/enumerations/category-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import GlobalStateError from "../../models/interfaces/global-state-error";
import { Question } from "../../models/interfaces/questions/question";
import { CalculateMovementScore } from "../../models/interfaces/scores/calculate-movement-score";
import { MovementScore } from "../../models/interfaces/scores/movement-score";
import { MskScore } from "../../models/interfaces/scores/msk-score";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import { CorrectiveExerciseUtils } from "../../utilities/corrective-exercise-utils";
import GlobalStateUtil from "../../utilities/global-state-util";
import MskScoreUtil from "../../utilities/msk-score-util";
import CalculateMovementService from "../../utilities/services/calculate/calculate-movement-service";
import MovementScoreService from "../../utilities/services/movement-score-service/movement-score-service";
import MskScoreServiceAssessments from "../../utilities/services/msk-score-service-assessments/msk-score-service-assessments";
import UserMskFocusArticlesUtil from "../../utilities/user-msk-focus-articles-util";
import UserUtilAssessments from "../../utilities/user-util-assessments";
import LoaderAssessments from "../loader-assessments/loader-assessments";
import ScreenSlide from "../screen-slide/screen-slide";
import { useStoreAssessments } from "../../store/useStoreAssessments";
// import TodayService from "../../../utilities/services/today-service/today-service";
import { DateTime } from "luxon";
import ReportService from "../../utilities/services/report-service-assessments/report-service-assessments";
import UserService from "../../utilities/services/user-service-assessments";
import { Report } from "../../models/interfaces/report";
import { ReportType } from "../../models/enumerations/report-type";
import UserCorrectiveExercisesService from "../../utilities/services/corrective-exercises-service/user-corrective-exercises-service";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import UserActivity from "../../models/interfaces/user-activity";
import UserActivityService from "../../utilities/services/user-activity-service";
import { ActivityType } from "../../models/enumerations/activity-type";
import { useIntercom } from "react-use-intercom";

const COMPONENT_CLASS = "c-movement-screen";

export interface MovementScreenProps {
    screenSettings: AssessmentSettings;
    setScreenSettings: React.Dispatch<AssessmentSettings>;
    settingsOnBack?: Function;
}

const MovementScreen: React.FC<MovementScreenProps> = (props) => {
    const { trackEvent } = useIntercom();
    const { screenSettings, setScreenSettings } = props;
    const { globalState, setGlobalError, setGlobalErrors, setHasMskScore } = useGlobalState();
    const { state } = useAuthState();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [movementScore, setMovementScore] = useState<
        MovementScore | undefined | null
    >(undefined);
    const [errors, setErrors] = useState<string[]>([]);
    //const { currentIdentity } = useStoreAssessments((state) => ({
    //     currentIdentity: state.currentIdentity,
    //     setIdentity: state.setIdentity,
    // }));
    const setLastActivityCompletedDate = useStoreAssessments(
        (state) => state.setLastActivityCompleted
    );


    useEffect(() => {
        if (!state.user || !state.user.id) {
            new Error("Please log in.");
            return;
        }

        AssessmentUtils.fetchQuestions(
            screenSettings,
            CategoryIds.MOVEMENT
        ).then((settings) => {
            setScreenSettings(settings);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNextPage = async () => {
        setErrors([]);
        setScreenSettings(await AssessmentUtils.moveToNextPage(screenSettings));
    };

    const recordAnswer = async (selectedAnswer: string, question: Question) => {
        let settings = AssessmentUtils.recordAnswer(
            screenSettings,
            question,
            selectedAnswer
        );
        if (errors.length !== 0) {
            setErrors([]);
        }

        if (
            slide?.slideType === SlideType.Question &&
            slide.questions != null
        ) {
            settings = await AssessmentUtils.moveToNextPage(settings);
        }
        setScreenSettings(settings);
    };

    const submit = async (attemptIndex: number = 0) => {
        setIsLoading(true);
        const queryParams = new URLSearchParams(window.location.search);
        if (!state.user || !state.user.id) {
            return;
        }
        const userId = state.user.id;
        const mskScore = await MskScoreUtil.getLatest(userId);
        const userInfo = await UserUtilAssessments.getUserInfo(userId);
        const calcScore: CalculateMovementScore = {
            userInfo: userInfo,
            movementResponses: AssessmentUtils.getAllResponses(screenSettings),
            existingMskScore: mskScore,
        };
        let newMskScore: MskScore | null = null;
        try {
            newMskScore = await CalculateMovementService.score(calcScore);
        } catch (err) {
            console.log("err", err);
            if (attemptIndex === 0) {
                setGlobalError("Movement: Calculate", err);
            }
            const retryMs: number[] = [500, 1000, 1500, 3000, 5000];
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return submit(attemptIndex + 1);
                }, retryMs[attemptIndex]);
                return;
            }
        }
        if (!mskScore?.percentage && newMskScore?.percentage) {
            trackEvent('first-time-assessments-completed');
        }

        if (newMskScore == null) {
            setErrors(["Movement MSK Score could not be calculated"]);
            setIsLoading(false);
            return;
        }
        setMovementScore(newMskScore.movementScore);
        setErrors(newMskScore.errors);

        const userActivity: UserActivity = {
            date: DateTime.now().toISODate(),
            type: ActivityType.WebMovementAssessment,
            userId: state.user?.id ?? ""
        }

        await UserActivityService.add(userActivity, state.user)
        setLastActivityCompletedDate(
            new Date(),
            ActivityType.MovementAssessment
        );
        await recordResults(newMskScore);

        // TODO : refactor into zustand
        // await TodayService.deleteToday(globalState.currentIdentity?.id!);
        // TodayService.deleteTodayCache();

        await UserCorrectiveExercisesService.deleteByUser(userId);

        // If both assessments aren't completed
        if (newMskScore?.percentage == null) {
            history.push("/wellness-assessment");
        }
        // If the user is in the Retake Assessments Flow
        else if (queryParams.get("retake") === "true") {
            history.push("/lifestyle?retake=true");
        }
        // Else, this is the user's 2nd and last assessment and they are done.
        else {
            handleGenerateReport(true);
            // TODO: Where should they go when both assessments are completed
            history.push("/wellness-assessment");
        }

        // TODO: Somehow send that we came from RetakeAssessments and history.push("/lifestyle")
    };

    /**
     * Record Scores
     * @returns True if succeeeded, false if there was an error
     */
    const recordResults = async (newMskScore: MskScore): Promise<boolean> => {
        if (
            !state.user || state.user.id == null ||
            newMskScore.movementScore == null
        ) {
            return true;
        }


        const userId = state.user.id;
        const newErrors: GlobalStateError[] = [];

        try {
            await MskScoreServiceAssessments.addOrUpdateRecentById(
                newMskScore,
                userId
            ).then(() => {
                if (userId) {
                    MskScoreUtil.getLatest(userId).then((response) => {
                        if (response?.lifestyleScore?.percentage != null &&
                            response.movementScore?.percentage != null) {
                            setHasMskScore(true);
                        }
                    });
                }
            });
        } catch (err) {
            newErrors.push(
                GlobalStateUtil.createError("Movement MSK was not saved", err)
            );
        }
        try {
            await MovementScoreService.addOrUpdateRecentById(
                newMskScore.movementScore!,
                state.user.id
            );
        } catch (err) {
            newErrors.push(
                GlobalStateUtil.createError("Movement score was not saved", err)
            );
        }
        try {
            await CorrectiveExerciseUtils.handleNewMovementScoreById(
                newMskScore.movementScore,
                state.user.id
            );
        } catch (err) {
            newErrors.push(
                GlobalStateUtil.createError(
                    "Movement score could not be handled",
                    err
                )
            );
        }
        try {
            await UserUtilAssessments.handleNewMskScore(userId, {
                mskScore: newMskScore,
                wasMovementRetake: true,
            });
        } catch (err) {
            newErrors.push(
                GlobalStateUtil.createError(
                    "Movement retake state wasn't updated",
                    err
                )
            );
        }
        try {
            await UserMskFocusArticlesUtil.handleFocusChanges(
                globalState.currentIdentity!.id!,
                newMskScore
            );
        } catch (err) {
            newErrors.push(
                GlobalStateUtil.createError(
                    "Movement focus wasn't updated",
                    err
                )
            );
        }

        if (newErrors.length >= 1) {
            // set errors
            setErrors(newErrors.map((x) => x.description));

            // update global state with errors
            setGlobalErrors(...newErrors);
            return false;
        }
        return true;
    };

    const groups = AssessmentUtils.getAssessmentGroups(screenSettings);
    const currentGroup =
        screenSettings?.slideGroups == null
            ? null
            : screenSettings.slideGroups[screenSettings.currentGroupIndex];
    const slide = AssessmentUtils.getSlide(screenSettings);
    const actionsModifier =
        slide?.slideType === SlideType.Video ? "-video" : "";

    let hasContinueButton =
        AssessmentUtils.hasData(screenSettings) &&
        movementScore == null &&
        (!AssessmentUtils.isLastGroup(screenSettings) ||
            !AssessmentUtils.isLastSlide(screenSettings));

    if (
        hasContinueButton &&
        slide &&
        slide?.slideType === SlideType.Question &&
        slide.questions != null
    ) {
        hasContinueButton = false;
    }

    let continueButtonText = "Continue";
    if (hasContinueButton && slide != null) {
        switch (slide.slideType) {
            case SlideType.AssessmentIntro:
            case SlideType.Tests:
                continueButtonText = "Let's Do It";
                break;
            case SlideType.Tips:
                continueButtonText = "Got It";
                break;
            case SlideType.GroupIntro:
                continueButtonText = "Start";
                break;
            case SlideType.Video:
                if (slide.video) {
                    continueButtonText = "Enter Results";
                }
                break;
        }
    }

    const hasSubmitButtton =
        AssessmentUtils.isLastGroup(screenSettings) &&
        AssessmentUtils.isLastSlide(screenSettings);

    const noProgressBarSlideTypes = [
        SlideType.AssessmentIntro,
        SlideType.Complete,
        SlideType.GroupIntro,
        SlideType.Tests,
        SlideType.Tips,
    ];
    const hasProgressBar =
        slide == null ||
        noProgressBarSlideTypes.indexOf(slide.slideType) === -1;
    const questionGroupNames = AssessmentUtils.getAssessmentGroups(
        screenSettings
    ).map((group) => group.groupName!);

    const handleGenerateReport = async (emailResults: boolean) => {
        if (!globalState.currentIdentity?.id) return;

        // we need to get the user from the firebase in case their organization
        // was switched (in the portal) and no longer matches the data in the app.
        const userInFirebase = await UserService.get(globalState.currentIdentity?.id);

        if (!userInFirebase || !userInFirebase.organizationId) return;

        const latestOrgId = userInFirebase.organizationId;

        //setIsLoading(true);

        const date = DateTime.now().toISODate();
        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: emailResults,
            organizationId: latestOrgId,
            status: "scheduled",
            type: ReportType.Wellness,
            userId: globalState.currentIdentity!.id!,
        };

        const identity = globalState.currentIdentity;

        if (identity.organizationId !== latestOrgId) {
            identity.organizationId = latestOrgId;
        }

        const scheduledReport = await ReportService.save(
            report,
            identity
        );

        if (!scheduledReport.id) {
            // setIsLoading(false);
            // setReportError(
            //     "Report was not saved successfully. Please try again."
            // );
            return;
        }

        if (scheduledReport.emailResults === true) {
            // setShowReportEmailConfirmation(true);
            // setIsLoading(false);
            return;
        }

        // const reportUnsubscribe = ReportService.getSnapshot(
        //     scheduledReport.id,
        //     (r: Report) => {
        //         if (r.status === "complete" && r.url) {
        //             setIsLoading(false);
        //             reportUnsubscribe();

        //             //Loads in the System browser
        //             window.open(r.url, "_self");
        //         }
        //         if (r.status === "error") {
        //             setReportError(r.errorMessage);
        //             setIsLoading(false);
        //             reportUnsubscribe();
        //         }
        //     }
        // );
    };

    return (
        <div className={`${COMPONENT_CLASS} -movement`}>
            <LoaderAssessments isLoading={isLoading} />
            {
                // if
                !isLoading && currentGroup != null && (
                    <div
                        className={`${COMPONENT_CLASS}__wrapper ${slide?.slideType === SlideType.GroupIntro
                            ? "-intro"
                            : ""
                            }`}>

                        {
                            // if
                            AssessmentUtils.hasData(screenSettings) &&
                            movementScore == null && (
                                <div
                                    className={`${COMPONENT_CLASS}__content ${hasContinueButton ||
                                        hasSubmitButtton
                                        ? "has-button"
                                        : ""
                                        }`}>
                                    {
                                        // if
                                        slide != null && (
                                            <ScreenSlide
                                                questionGroupNames={
                                                    questionGroupNames
                                                }
                                                recordedAnswers={AssessmentUtils.getAllResponses(
                                                    screenSettings
                                                )}
                                                recordAnswer={recordAnswer}
                                                page={slide}
                                                errors={errors}
                                                totalProgress={
                                                    groups.length
                                                }
                                                currentProgress={
                                                    groups.indexOf(
                                                        currentGroup
                                                    ) + 1
                                                }
                                                hasProgressBar={hasProgressBar}
                                                screenSettings={screenSettings}
                                                settingsOnBack={props.settingsOnBack}
                                                isActionsWithContinue={hasContinueButton}
                                                skipButton={slide?.questions &&
                                                    slide.questions.length >
                                                    0 &&
                                                    slide.questions[0]
                                                        .optional === true}
                                                onSkip={getNextPage}
                                                onBack={props.settingsOnBack}
                                                onSubmit={getNextPage}
                                                submitButtonText={continueButtonText}
                                                headerText="Movement Assessment"
                                            />
                                        )
                                    }

                                    <div
                                        className={`${COMPONENT_CLASS}__actions ${actionsModifier} ion-margin-horizontal`}>
                                        {/* {
                                            // if
                                            hasContinueButton && (
                                                <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={getNextPage} submitButtonText={continueButtonText}></BaseCardLayoutActions>
                                            )
                                        } */}
                                        {
                                            // if
                                            hasSubmitButtton && (
                                                <div className={`c-screen-slide__actions`}>
                                                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={submit} submitButtonText={errors.length === 0
                                                        ? "Submit Results"
                                                        : "Resubmit"}></BaseCardLayoutActions>
                                                </div>
                                                // <IonButton
                                                //     color="primary"
                                                //     expand="block"
                                                //     onClick={() =>
                                                //         submit()
                                                //     }>
                                                //     {errors.length === 0
                                                //         ? "Submit Results"
                                                //         : "Resubmit"}
                                                // </IonButton>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default MovementScreen;
