import Article from "../../../models/interfaces/articles/article";
import { ArticlesGroup } from "../../../models/interfaces/articles/articles-group";
import { Assessment } from "../../../models/interfaces/scores/assessment";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/articles`;
const getArticlesGroupEndpoint = `${baseEndpoint}/get-articles`;
const getByFmsArticleId = `${baseEndpoint}/byFmsArticleId/:id`;
const defaultExpiration = 86400 * 1000; // one day in milliseconds

interface GetByFmsArticleIdParams {
    id: string;
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ArticleApiService = {
    /**
     * Loads articles for the assessment group
     */
    getArticlesGroup: ServiceBase.post<ArticlesGroup, Assessment>(
        getArticlesGroupEndpoint
    ),
    getByFmsArticleId: ServiceBase.get<Article, GetByFmsArticleIdParams>(
        getByFmsArticleId,
        defaultExpiration
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ArticleApiService;

// #endregion Exports
