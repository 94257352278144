import { UserMskFocusArticles } from "../models/interfaces/articles/user-msk-focus-articles";
import { UserMskFocusStatus } from "../models/interfaces/articles/user-msk-focus-status";
import FirestoreCondition from "../models/interfaces/firestore-condition";
import FirestoreOrder from "../models/interfaces/firestore-order";
import { MskScore } from "../models/interfaces/scores/msk-score";
import ArticleUtils from "./article-utils";
import UserMskFocusArticlesService from "./services/article-service/user-msk-articles-service";

export default class UserMskFocusArticlesUtil {
    /**
     * Find the specific user by the id
     * @param {string} userId - The Id of the user that we are retrieving
     * @returns {Promise<User>} A promise for the user we are retrieving
     */
    public static async getCurrentByUserId(
        userId: string
    ): Promise<UserMskFocusArticles | null> {
        const conditions: FirestoreCondition[] = [
            {
                field: "userId",
                operator: "==",
                value: userId,
            },
            {
                field: "isCurrent",
                operator: "==",
                value: true,
            },
        ];
        const order: FirestoreOrder[] = [
            {
                field: "created",
                direction: "desc",
            },
        ];

        const records = await UserMskFocusArticlesService.getBy(
            conditions,
            order,
            1
        );
        if (records == null || records.length === 0) {
            return null;
        }

        return records[0];
    }

    public static async handleFocusChanges(
        userId: string,
        mskScore: MskScore | undefined
    ): Promise<void> {
        if (userId == null || userId.length === 0) {
            _log("handleFocusChanges(): userId is not valid");
            return;
        }
        if (mskScore?.focusAreas == null || mskScore.focusAreas.length === 0) {
            _log(
                "handleFocusChanges(): focus areas don't exist; no changes necessary"
            );
            return;
        }

        const existingFocusArticles =
            await UserMskFocusArticlesUtil.getCurrentByUserId(userId);
        if (existingFocusArticles?.focusStatuses == null) {
            _log(
                `handleFocusChanges(): no UserMskFocusArticles for "${userId}", nothing to do: existingFocusArticles:`,
                existingFocusArticles
            );
            return;
        }

        let replaceRecord = false;
        const newFocusArticles: UserMskFocusArticles = {
            userId: userId,
            isCurrent: true,
            focusStatuses: [],
        };

        // map new focuses to articles
        for (let i = 0; i < mskScore.focusAreas.length; i++) {
            const newFocusArea = mskScore.focusAreas[i];
            const match = existingFocusArticles.focusStatuses.find(
                (x) => x.mskFocusAreaGroupId === newFocusArea.groupId
            );
            _log(
                `handleFocusChanges(): match for "${newFocusArea.groupId}":`,
                match
            );

            if (match != null) {
                _log(`handleFocusChanges(): exists: ${newFocusArea.groupId}`);
                newFocusArticles.focusStatuses.push(match);
                continue;
            }

            const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                newFocusArea.groupId!
            );
            if (
                articlesGroup?.articles == null ||
                articlesGroup.articles.length === 0
            ) {
                console.warn(
                    `handleFocusChanges(): no articles for ${newFocusArea.groupId}`
                );
                continue;
            }

            const focusStatus: UserMskFocusStatus = {
                completedArticles: [],
                readAllArticles: false,
                mskFocusAreaGroupId: newFocusArea.groupId!,
                mskFocusAreaGroupName: newFocusArea.groupName!,
                numArticles: articlesGroup.articles.length,
            };
            newFocusArticles.focusStatuses.push(focusStatus);
            replaceRecord = true;
            _log(`handleFocusChanges(): adding:`, focusStatus);
        }

        // determine if articles should be removed
        if (
            !replaceRecord &&
            mskScore.focusAreas.length !==
                existingFocusArticles.focusStatuses.length
        ) {
            replaceRecord = true;
        }
        if (!replaceRecord) {
            existingFocusArticles.focusStatuses.forEach((existingFocus) => {
                const match = mskScore.focusAreas!.find(
                    (newFocus) =>
                        newFocus.groupId === existingFocus.mskFocusAreaGroupId
                );
                if (match == null) {
                    _log(
                        `handleFocusChanges(): removing ${existingFocus.mskFocusAreaGroupId}`
                    );
                    replaceRecord = true;
                }
            });
        }

        // replace records
        if (replaceRecord) {
            existingFocusArticles.isCurrent = false;
            await UserMskFocusArticlesService.update(existingFocusArticles);
            await UserMskFocusArticlesService.add(newFocusArticles);
            _log(
                `handleFocusChanges(): replaceRecord:`,
                existingFocusArticles,
                "with:",
                newFocusArticles
            );
        }
    }
}

const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        console.log(message, ...optionalParams);
    }
};
