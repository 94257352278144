import React from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { DropdownSelectProps } from "./dropdown-select.props";
import "./dropdown-select.styles.scss";
import { ReactComponent as QuestionMarkIcon } from "../../../assets/icons/solid/question-mark.svg";

/**
 * A select form control that provides a drop down of
 * label value pairs of which one is selectable.
 */
export const DropdownSelect: React.FC<DropdownSelectProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let selectAttributes = {
        id: sanitizedId,
        name: props.id,
        label: props.label,
        placeholder: props.label,
        isMulti: false,
        isClearable: true,
    }

    return (
        <>
            {(props.label || (props.includeQuestionMarkIcon && props.onQuestionMarkClick)) &&
                <div className="flex items-center gap-3">
                    <label htmlFor={sanitizedId}>
                        {props.label}
                    </label>
                    {props.includeQuestionMarkIcon &&
                        <div className='cursor-pointer mb-3' onClick={() => {
                            if (props.onQuestionMarkClick) {
                                props.onQuestionMarkClick();
                            }
                        }}><QuestionMarkIcon /></div>
                    }
                </div>
            }
            <Controller
                name={sanitizedId}
                control={props.control}
                data-testId="select-test"
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                    return (
                        <ReactSelect
                            {...selectAttributes}
                            aria-labelledby={sanitizedId}
                            onBlur={onBlur}
                            value={value}
                            options={props.optionsArray}
                            onChange={(val) => {
                                onChange(val);
                            }} />
                    )
                }}
            />
            {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
        </>
    );
};
