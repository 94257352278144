import { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import getFirebase from '../../utilities/firebase';
import { SwitchOrganizationModal } from '../switch-organization-modal/switch-organization-modal'
import { Loader } from '../loader/loader';
import UserUtil from '../../utilities/user-util';
import { useHistory } from 'react-router-dom';

const { auth } = getFirebase();

const COMPONENT_CLASS = "c-user-menu";

export interface UserMenuProps {
    profileImage?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = (props) => {
    const { state, setState } = useAuthState();
    const [isSwitching, setIsSwitching] = useState(false);
    const [open, setOpen] = useState(false);
    const isSuperAdmin = state?.claims?.superAdmin === true;
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const useOrganizationHeader = isSuperAdmin || isAccountHolder;
    const history = useHistory();
    const [mouseOverButton, setMouseOverButton] = useState(false)
    const [mouseOverMenu, setMouseOverMenu] = useState(false)

    const handleSignoutClick = () => {
        auth.signOut();
        setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));
    }

    const timeoutDuration = 500
    let timeoutButton: string | number | NodeJS.Timeout | undefined;
    let timeoutMenu: string | number | NodeJS.Timeout | undefined;

    const onMouseEnterButton = () => {
        clearTimeout(timeoutButton)
        setMouseOverButton(true)
    }
    const onMouseLeaveButton = () => {
        timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
    }

    const onMouseEnterMenu = () => {
        clearTimeout(timeoutMenu)
        setMouseOverMenu(true)
    }
    const onMouseLeaveMenu = () => {
        timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
    }
    const show = (mouseOverMenu || mouseOverButton)

    return (
        <div className={`${COMPONENT_CLASS} white-on-mobile`}>
            <Loader
                isVisible={isSwitching} />
            <Menu as="div">
                {({ open }) => (
                    <>
                        <div onMouseEnter={onMouseEnterButton}
                            onMouseLeave={onMouseLeaveButton}>
                            <Menu.Button className={`${COMPONENT_CLASS}__button`}>

                                <span className="sr-only">Open user menu</span>
                                <div className={`${COMPONENT_CLASS}__user hide-on-mobile`}>
                                    <div>{state.user?.firstName} {state.user?.lastName}</div>
                                    <ChevronDownIcon />
                                </div>
                                { // if
                                    state.user?.profileImage && (props.profileImage === undefined || props.profileImage === true) &&
                                    <img src={state.user.profileImage} alt="User Profile" />
                                }
                                { // if
                                    !state.user?.profileImage && (props.profileImage === undefined || props.profileImage === true) &&
                                    < UserCircleIcon aria-hidden="true" />
                                }
                            </Menu.Button>
                        </div>
                        <Transition
                            show={show}
                        // enter="transition ease-out duration-100"
                        // enterFrom="transform opacity-0 scale-95"
                        // enterTo="transform opacity-100 scale-100"
                        // leave="transition ease-in duration-75"
                        // leaveFrom="transform opacity-100 scale-100"
                        // leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`${COMPONENT_CLASS}__menu intercom-test-selectors`}
                                onMouseEnter={onMouseEnterMenu}
                                onMouseLeave={onMouseLeaveMenu}
                            >
                                <Menu.Item>
                                    <div
                                        className={`${COMPONENT_CLASS}__item show-on-mobile`}
                                        style={{ border: 'solid #121622', borderWidth: '0 0 1px 0' }}>
                                        <strong>
                                            {state.user?.firstName} {state.user?.lastName}
                                        </strong>
                                    </div>
                                </Menu.Item>
                                <Menu.Item>
                                    <button
                                        onClick={() => window.open("https://help.symmio.com/en/", '_blank')}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        Help Center
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button
                                        onClick={() => handleSignoutClick()}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        Log Out
                                    </button>
                                </Menu.Item>
                                {useOrganizationHeader &&
                                    <Menu.Item>
                                        <button
                                            onClick={() => setOpen(true)}
                                            className={`${COMPONENT_CLASS}__item show-on-mobile`}>
                                            Switch Organization
                                        </button>
                                    </Menu.Item>
                                }
                                <Menu.Item>
                                    <button className={`${COMPONENT_CLASS}__toggle_item`}>
                                        <div onClick={() => history.push("/wellness-assessment")} style={{ transform: "rotate(90deg)" }} className={`${COMPONENT_CLASS}__toggle__wrapper -unchecked`}>
                                            <input
                                                type="checkbox"
                                                id="wellnessToggle"
                                                className="sr-only"
                                                checked={false}
                                            />
                                            <div className={`${COMPONENT_CLASS}__toggle__background`}></div>
                                            <div className={`${COMPONENT_CLASS}__toggle__dot`}></div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginRight: '0.1rem', marginTop: '0.3rem' }}>
                                            <div
                                                onClick={() => history.push("/wellness-assessment")}
                                            >
                                                Wellness Platform
                                            </div>
                                            <div
                                                onClick={() => history.push("/wellness-assessment")}
                                            >
                                                My Assessment
                                            </div>
                                        </div>

                                    </button>
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
            <SwitchOrganizationModal
                open={open}
                setOpen={setOpen}
                setIsSwitching={setIsSwitching} />
        </div >
    );
}

export { UserMenu };
