import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { ReactivationRequest } from "../../models/interfaces/reactivation-request";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "reactivationRequests";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new ReactivationRequest to the ReactivationRequests collection
 * @param {ReactivationRequest} ReactivationRequest - The ReactivationRequest that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ReactivationRequest>} A promise to the newly added ReactivationRequest
 */
const add = async (reactivationRequest: ReactivationRequest, currentUser: User | null = null) => {
    return FirestoreService.add<ReactivationRequest>(COLLECTION_NAME, reactivationRequest, currentUser);
};

/**
 * Delete a ReactivationRequest collection by the Id
 * @param {string} id - The Id of the ReactivationRequest being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific ReactivationRequest by the id
 * @param {string} id - The Id of the ReactivationRequest that we are retrieving
 * @returns {Promise<ReactivationRequest>} A promise for the ReactivationRequest we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<ReactivationRequest>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<ReactivationRequest>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the ReactivationRequests stored in the database
 * @returns {Promise<ReactivationRequest[]>} A promise for the collection of ReactivationRequests
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<ReactivationRequest>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific ReactivationRequest to see when it changes
 * @param {string} id - Id of the ReactivationRequest document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<ReactivationRequest>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the ReactivationRequests to see when it changes
 * @param {string} id - Id of the ReactivationRequest document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<ReactivationRequest>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified ReactivationRequest in the database
 * @param {ReactivationRequest} reactivationRequest - The ReactivationRequest that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ReactivationRequest>} A promise for the ReactivationRequest that is being updated
 */
const save = async (reactivationRequest: ReactivationRequest, currentUser: User | null = null) => {
    return FirestoreService.save<ReactivationRequest>(COLLECTION_NAME, reactivationRequest, currentUser);
};

/**
 * Update the specified ReactivationRequest stored in the database
 * @param {ReactivationRequest} reactivationRequest - The ReactivationRequest that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ReactivationRequest>} A promise for the ReactivationRequest that is being updated
 */
const update = async (reactivationRequest: ReactivationRequest, currentUser: User | null = null) => {
    return FirestoreService.update<ReactivationRequest>(COLLECTION_NAME, reactivationRequest, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ReactivationRequestsService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default ReactivationRequestsService;

// #endregion Exports