import DeeplinksList from "../../components/deeplinks-list/deeplinks-list";
import { useHistory } from "react-router";
import { Button } from "../../components/button/button";
import "./deeplinks-page.styles.scss";

const COMPONENT_CLASS = "c-deep-links-list";

const DeeplinksPage: React.FC = () => {
    const history = useHistory();

    const handleAddLink = () => {
        history.push('/invitelink');
    }

    return (
        <>
            <div className={`${COMPONENT_CLASS}`} data-testid={'deep-links-list'}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>Links</h1>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            type="default"
                            buttonText="+ Add Link"
                            onClick={handleAddLink}
                        />
                    </div>
                </div>

                <DeeplinksList />

            </div>
        </>
    );
}

export default DeeplinksPage;
