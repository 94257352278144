import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import OrganizationService from '../../../utilities/services/organization-service';
import { enqueueSnackbar } from "notistack";
import { TextTypeInput } from '../../forms';
import { Button } from '../../button/button';

interface EditOrganizationProps {
  onClose: any,
}
const COMPONENT_CLASS = "c-subscriptions";
const FORM_COMPONENT_CLASS = "p-link-create-account";

const EditOrganization: React.FC<EditOrganizationProps> = (props: EditOrganizationProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [, setSubmitting] = useState<boolean>(false);
  const { state, setState } = useAuthState();

  const onSubmit = async (data: any) => {
    if (data.organizationName && state.organization.id) {
      setSubmitting(true);
      const org = await OrganizationService.get(state.organization.id);
      if (org) {
        org.name = data.organizationName.trim();
        await OrganizationService.update(org, state.user).then(() => {
          const stateOrg = state.organization;
          stateOrg.name = data.organizationName;
          setState((state) => ({
            ...state, ...{ organization: stateOrg }
          }));
          reset();
          setSubmitting(false);
          enqueueSnackbar("Your organization has been updated.", { variant: "toast", width: "450px", autoHideDuration: 5000 });
          props.onClose();
        });
      }
    }
    else {
      setSubmitting(false);
    }
  }
  return (
    <div className={`${COMPONENT_CLASS}`}>
      <div className={`${COMPONENT_CLASS}__wrapper`}>
        <div style={{ width: '90%', marginTop: '2rem' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${FORM_COMPONENT_CLASS}__field`}>
              {errors.organizationName && (
                <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                  Please confirm your new organization name.
                </div>
              )}
              <TextTypeInput
                type='text'
                id='organizationName'
                label='Organization Name'
                hideLabel={true}
                style={{
                  marginLeft: '0',
                  width: '90%',
                  marginBottom: '1.25rem'
                }}
                registerHook={register}
                registerOptions={{
                  value: state.organization.name,
                  required: true,
                }}
                errorState={errors.organizationName} />
            </div>
          </form>
        </div>
        <div className={'c-login-modal__button-area'} style={{ gap: "50px" }}>
          <Button
            type='link'
            buttonText='Cancel'
            onClick={props.onClose} />
          <Button
            buttonStyle='primary'
            onClick={handleSubmit(onSubmit)}
            buttonText='Save Changes' />
        </div>
      </div>
    </div >
  );
}

export default EditOrganization;
