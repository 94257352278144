/* eslint-disable jsx-a11y/img-redundant-alt */
import FirebaseStorage from "../../utilities/firebase-storage";
const COMPONENT_CLASS = "c-assessment-intro";

export interface LifestyleIntroProps { }

const LifestyleIntro: React.FC<LifestyleIntroProps> = (props) => {
    return (
        <div className={`${COMPONENT_CLASS} scroll-container`}>
            <div className={`${COMPONENT_CLASS}__image`}>
                <img
                    alt="Movement Image"
                    src={new FirebaseStorage().getMediaUrl(
                        "public/lifestyle/lifestyle-assessment@2x.png"
                    )}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__content-container`}>
                <h1>Lifestyle Assessment.</h1>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <p className={`${COMPONENT_CLASS}__description`}>
                        Your body is unique. So are your past experiences, current
                        daily habits, and general mental and emotional states. And
                        all of that contributes to your overall health. Through this
                        quick assessment, we’ll figure out what’s working, what
                        isn’t, and what we can do about it.
                    </p>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            Duration:
                        </p>
                        <p>About 15 minutes</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            Equipment:
                        </p>
                        <p>Cloth tape measure or long piece of string</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifestyleIntro;
