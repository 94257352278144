import logoHeader from "../../assets/images/symmio-logos/symmio-logo-text-white-side-large.png"

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportWellnessHeaderProps {
    name?: string;
    isFemale?: boolean;
    age?: number;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportWellnessHeader: React.FC<ReportWellnessHeaderProps> = (props: ReportWellnessHeaderProps) => {
    const { name, isFemale, age } = props;
    const todayDate = (new Date()).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <div className={`${COMPONENT_CLASS}__header__wellness`}>
            <div className={`${COMPONENT_CLASS}__logo`}>
                <img src={logoHeader} alt="Symmio Logo" />
            </div>
            <div>
                <div className="text-sm font-light text-right">Functional Wellness Report</div>
                <div className="text-xs font-light text-right" style={{ color: "rgba(173, 223, 253, 1)" }}>{name || "Name Not Provided"} • {isFemale ? "Female" : "Male"} • {age} yrs • {todayDate}</div>
            </div>
        </div>
    );
}

export default ReportWellnessHeader;