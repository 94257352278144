import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { User } from "../../models/interfaces/user";
import { Deeplink } from "../../models/interfaces/deeplink";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "deeplinks";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new deeplink to the deeplinks collection
 * @param {DeepLink} deeplink - The deeplink that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<DeepLink>} A promise to the newly added deeplink
 */
const add = async (deeplink: Deeplink, currentUser: User | null = null) => {
    return FirestoreService.add<Deeplink>(
        COLLECTION_NAME,
        deeplink,
        currentUser
    );
};

/**
 * Delete a deeplink by the Id
 * @param {string} id - The Id of the deeplink being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific deeplink by id
 * @param {string} id - The Id of the deeplink that we are retrieving
 * @returns {Promise<Deeplink>} A promise for the Deeplink we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Deeplink>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<Deeplink>(
        COLLECTION_NAME,
        conditions,
        order,
        limit
    );
};

/**
 * Get all of the deeplinks stored in the database
 * @returns {Promise<Deeplink[]>} A promise for the collection of Deeplinks
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<Deeplink>(COLLECTION_NAME, order);
};

/**
 * Save the specified deeplink in the database
 * @param {Deeplink} deeplink - The deeplink that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Deeplink>} A promise for the deeplink that is being updated
 */
const save = async (deeplink: Deeplink, currentUser: User | null = null) => {
    return FirestoreService.save<Deeplink>(
        COLLECTION_NAME,
        deeplink,
        currentUser
    );
};

/**
 * Update the specified deeplink stored in the database
 * @param {Deeplink} deeplink - The deeplink that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Deeplink>} A promise for the deeplink that is being updated
 */
const update = async (deeplink: Deeplink, currentUser: User | null = null) => {
    return FirestoreService.update<Deeplink>(
        COLLECTION_NAME,
        deeplink,
        currentUser
    );
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const DeeplinkService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    save,
    update,
};

export default DeeplinkService;

// #endregion Exports\
