import React from "react";
import { MultiSelectProps } from "./multi-select.props";
import "./multi-select.styles.scss";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";

/**
 * A select form control that provides a drop down of
 * label value pairs of which one is selectable.
 */
export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let selectAttributes: any = {
        id: sanitizedId,
        isMulti: true,
        label: props.label,
        placeholder: props.label
    }

    if (props.label) {
        selectAttributes.placeholder = props.label;
    }

    const handleMultiChange = (selectedOption: any) => {
        props.setValue(sanitizedId, selectedOption.value);
    };

    return (
        <>
            {props.label &&
                <label id={props.id}>
                    {props.label}
                </label>
            }
            <Controller
                name={sanitizedId}
                control={props.control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                    return (
                        <ReactSelect
                            {...selectAttributes}
                            aria-labelledby={sanitizedId}
                            onBlur={onBlur}
                            isClearable
                            value={value}
                            options={props.optionsArray}
                            onChange={(val) => {
                                handleMultiChange(val);
                                onChange(val);
                            }} />
                    )
                }}
            />
            {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
        </>
    );
};
