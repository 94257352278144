import React from "react";
import { EmailTypeInputProps } from "./email-type-input.props";
import "./email-type-input.styles.scss";

export const EmailTypeInput: React.FC<EmailTypeInputProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    const emailValidation = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
    );

    let inputAttributes = {
        id: sanitizedId,
        placeholder: props.label,
        type: "text"
    }

    // wire up our input to react-hook-form
    // https://react-hook-form.com/get-started
    if (props.registerOptions) {
        inputAttributes = {
            ...inputAttributes,
            ...props.registerHook(sanitizedId, {
                ...props.registerOptions,
                ...!props.readOnly ? { required: "Enter an email address" } : {},
                pattern: {
                    value: emailValidation,
                    message: "Invalid email address"
                },
            }),
            ...(props.registerOptions.value && { value: props.registerOptions.value })
        };
    }
    else {
        inputAttributes = {
            ...inputAttributes,
            ...props.registerHook(sanitizedId, {
                required: "Enter an email address",
                pattern: {
                    value: emailValidation,
                    message: "Invalid email address"
                }
            })
        };
    }


    return (
        <div className="email-type-container">
            {(props.label && props.hideLabel !== true) &&
                <label htmlFor={sanitizedId}>
                    {props.label}
                </label>
            }
            {props.errorState && props.errorState.message &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
            <input
                {...inputAttributes}
                className={`${(props.errorState || props.noMatchEmail) && "field-error"} ${props.inputClassName}`}
                aria-invalid={props.errorState ? "true" : "false"}
                data-testid="input-test"
                style={props.style}
                readOnly={props.readOnly ?? false} />
        </div>
    )
};
