import { GroupIds } from "./../models/enumerations/group-ids";
import { Score } from "../models/interfaces/score";
import { LifestyleScore } from "../models/interfaces/lifestyle-score";
import { MskScore } from "../models/interfaces/msk-score";
import { Assessment } from "../models/interfaces/assessment";

// Based on https://docs.google.com/document/d/1__3InQ3W3gx6f36CAmUHYNTH1GlMOSiO/edit

type FocusDescription = {
    [key: string]: string;
    Low: string;
    Mid: string;
};

interface AdminFocusDescription {
    [key: string]: FocusDescription;
}

const adminFocusDescription: AdminFocusDescription = {
    SLEEP: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Through useful instruction, we’ll adjust sleep patterns and behaviors to improve their [XYZ] and overall MSK score.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Through useful instruction, we'll adjust sleep patterns and behaviors in order to improve their [XYZ] and their overall MSK score.",
    },
    BREATHING: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Symmio will assign exercises to improve their [XYZ] and overall MSK score.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Together, we'll work on exercises and behaviors to improve their [XYZ] and their overall MSK score.",
    },
    INJURY_HISTORY: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Whether old or recent, injuries are tough to recover from, but ensure their current injury status is not affecting their health and wellness.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Whether old or recent, injuries are tough to recover from, but we'll work through some corrective exercises to improve their [XYZ] and their overall MSK score.",
    },
    NUTRITION: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Symmio will explore ways to improve their dietary habits to improve [XYZ] and positively impact their health and wellness.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Together, we'll explore ways to improve their dietary habits in order to improve their [XYZ] and their overall MSK score.",
    },
    BEHAVIORAL_HEALTH: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Touching so many aspects of life, Symmio will explore different strategies for improving their [XYZ]. If they notice negative behavior, it is recommended to seek professional guidance.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Touching so many aspects of life, we'll look at different strategies for improving their [XYZ] and their overall MSK score.",
    },
    PHYSICAL_ACTIVITY: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. It can be tough to start, but through simple exercises and useful information, work to improve their [XYZ] and their overall MSK score.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. It can be tough to start, but through simple exercises and useful information, we'll work to improve their [XYZ] and their overall MSK score.",
    },
    BODY_COMPOSITION: {
        Low: "Based on the assessment results, their primary area of focus is [XYZ]. Together, we’ll explore different strategies for improving their [XYZ] and  overall MSK score.",
        Mid: "Based on the assessment results, another area of focus for them is [XYZ]. Together, we'll explore different strategies for improving their [XYZ] and their overall MSK score.",
    },
};

const getAssessmentResultLevel = (
    assessment: Assessment,
    mskScore: MskScore
): string | undefined => {
    const lifestyleScore: LifestyleScore = mskScore.lifestyleScore!;
    let assessmentScore: string | undefined;

    if (assessment?.groupId === GroupIds?.MOVEMENT_HEALTH) {
        return mskScore?.movementScore?.resultLevel;
    }

    for (const scoreGroup in lifestyleScore) {
        const questionGroupScore = lifestyleScore[scoreGroup as keyof LifestyleScore] as Score;

        const currentGroupId = questionGroupScore.groupId;

        if (currentGroupId === assessment.groupId) {
            assessmentScore = questionGroupScore.resultLevel;
            break;
        }
    }

    return assessmentScore;
};

const getAdminFocusDescription = (
    assessment: Assessment,
    lifestyleScore: string | undefined
): string | undefined => {
    let groupId = assessment.groupId;

    if (!lifestyleScore || !groupId) {
        return;
    }

    if (!adminFocusDescription[groupId] || !adminFocusDescription[groupId][lifestyleScore]) {
        return;
    }

    return adminFocusDescription[groupId][lifestyleScore];
};

const markupGroupName = (assessment: Assessment, description: string): string => {
    let markedUpDescription;
    const groupName = assessment.groupName;

    markedUpDescription = description.replace(/\[XYZ\]/g, `<strong>${groupName}</strong>`);

    return markedUpDescription;
};

const replacePronouns = (description: string): string => {
    const newDescription = description
        .replace(/get you/gi, "get them")
        .replace(/for you/gi, "for them")
        .replace(/your/gi, "their")
        .replace(/you're/gi, "they are")
        .replace(/you/gi, "they");
    return newDescription;
};

const getOriginalDescription = (assessment: Assessment, mskScore: MskScore): string => {
    const lifestyleScore: LifestyleScore = mskScore.lifestyleScore!;
    let originalDescription: string = "";

    if (assessment.groupId === GroupIds.MOVEMENT_HEALTH) {
        return mskScore?.movementScore?.resultDescription ?? "";
    }

    for (const scoreGroup in lifestyleScore) {
        const questionGroupScore = lifestyleScore[scoreGroup as keyof LifestyleScore] as Score;

        const currentGroupId = questionGroupScore.groupId;

        if (currentGroupId === assessment.groupId) {
            originalDescription = questionGroupScore.resultDescription;
            break;
        }
    }

    return originalDescription;
};

const capitalizeSentences = (str: string): string => {
    let sentences: string[] = str.split(". ");
    let capitalizedSentences: string[] = [];

    for (let i = 0; i < sentences.length; i++) {
        let sentence: string = sentences[i];
        let capitalizedSentence: string = sentence.charAt(0).toUpperCase() + sentence.substring(1);
        capitalizedSentences.push(capitalizedSentence);
    }

    return capitalizedSentences.join(". ");
};

export const getFocusDescription = (
    mskScore: MskScore | undefined,
    assessment: Assessment
): string | undefined => {
    if (!mskScore?.lifestyleScore || !assessment.groupId) {
        return "";
    }

    const currentAssessmentScore = getAssessmentResultLevel(assessment, mskScore);
    const adminFocusDescription = getAdminFocusDescription(assessment, currentAssessmentScore);

    if (!adminFocusDescription || !assessment.groupId) {
        const originalDescription = getOriginalDescription(assessment, mskScore);

        const descriptionWithUpdatedPronouns = replacePronouns(originalDescription);

        return capitalizeSentences(descriptionWithUpdatedPronouns);
    }

    return markupGroupName(assessment, adminFocusDescription);
};
