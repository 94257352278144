import AssessmentResponse from "../../../models/interfaces/assessment-response.entity";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AssessmentResponsePathParams {}

export interface AssessmentResponseQueryParams {
    userId: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/assessment/response`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AssessmentResponseService = {
    get: ServiceBase.list<AssessmentResponse[], AssessmentResponseQueryParams>(
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AssessmentResponseService;

// #endregion Exports
