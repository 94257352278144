import React from "react";
import { UserStatus } from "../../models/enumerations/user-status";

const COMPONENT_CLASS = "c-user-status-badge";

interface UsersBadgeProps {
    status?: UserStatus,
}

/**
 * A small badge representing the status of an organization.
 */
export const UserStatusBadge: React.FC<UsersBadgeProps> = (props) => {
    const {
        status,
    } = props;

    const activeBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge active`}
        >
            ACTIVE
        </span>
    );

    const inactiveBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge inactive`}
        >
            INACTIVE
        </span>
    );

    const disabledBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge deactivated`}
        >
            DEACTIVATED
        </span>
    );

    if (status === UserStatus.Active) {
        return activeBadge;
    }

    if (status === UserStatus.Inactive) {
        return inactiveBadge;
    }

    if (status === UserStatus.Disabled) {
        return disabledBadge;
    }

    return null;
};
