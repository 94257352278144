
import { useRef, useState } from "react";

const COMPONENT_CLASS = "c-input-option";

export interface WeightInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
}

interface WeightValues {
    weightEntered: string;
    weightLbs: number;
    unitsSelected: "lbs" | "kgs";
}

const WeightInputOption: React.FC<WeightInputOptionProps> = (props) => {
    const weightRef = useRef(null) as any;
    const CONVERSION = 2.2046;

    const getInitialWeight = (): WeightValues => {
        const weightAnswer = props.answer ?? "";
        const weightLbs = !isNaN(parseFloat(weightAnswer))
            ? parseFloat(weightAnswer)
            : 0;
        const unitsSelected = weightLbs % 1 === 0 ? "lbs" : "kgs";

        let weightEntered = "";
        if (weightLbs > 0) {
            if (unitsSelected === "lbs") {
                weightEntered = weightLbs.toString();
            } else {
                weightEntered = Math.round(weightLbs / CONVERSION).toString();
            }
        }

        return {
            weightEntered: weightEntered,
            weightLbs: weightLbs,
            unitsSelected: unitsSelected,
        };
    };
    const [weight, setWeight] = useState(getInitialWeight());

    const handleUnitChange = (units: "lbs" | "kgs") => {
        if (units === "kgs" && weight.unitsSelected !== "kgs") {
            weight.unitsSelected = units;
            weight.weightLbs = roundTo2Digits(
                parseFloat(weight.weightEntered) * CONVERSION
            );
            setWeight(weight);
            props.onChange(weight.weightLbs.toString());
        } else if (units === "lbs" && weight.unitsSelected !== "lbs") {
            weight.unitsSelected = units;
            weight.weightLbs = parseInt(weight.weightEntered);
            setWeight(weight);
            props.onChange(weight.weightLbs.toString());
        }
    };

    const handleInputChange = (input: string) => {
        let value = parseInt(input);
        if (input.length >= 4) {
            input = input.substring(0, 3).replace(/[^0-9]/g, "");
            value = parseInt(input);
            props.onChange("");
        }

        if (isNaN(value)) {
            weight.weightEntered = input;
            weight.weightLbs = 0;
            setWeight(weight);
            props.onChange("");
            return;
        }

        if (weight.unitsSelected === "kgs") {
            weight.weightEntered = input;
            weight.weightLbs = roundTo2Digits(parseInt(input) * CONVERSION);
        } else {
            weight.weightEntered = input;
            weight.weightLbs = parseInt(input);
        }
        setWeight(weight);
        if (weight.weightLbs <= 0) {
            props.onChange("");
            return;
        }
        props.onChange(weight.weightLbs.toString());
    };

    const roundTo2Digits = (num: number) => {
        return Math.round(100 * num) / 100;
    };

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div className={`${COMPONENT_CLASS} -weight`}>
            <div className={`${COMPONENT_CLASS}__container`}>
                <div className={`${COMPONENT_CLASS}__col ${COMPONENT_CLASS}__weight-col`}>
                    <div className={`${COMPONENT_CLASS}__weight-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            ref={weightRef}
                            onChange={(e: any) => handleInputChange(
                                e.target.value == null
                                    ? ""
                                    : e.target.value.toString()
                            )}
                            type="number"
                            value={weight.weightEntered}
                            maxLength={3}
                            min="0"
                            onKeyPress={integerOnlyValidation}>

                        </input>
                    </div>
                    <div className={`${COMPONENT_CLASS}__weight-types`}>
                        <input
                            name="weight-type"
                            type="radio"
                            id="KG"
                            value="KG"
                            checked={weight.unitsSelected === "kgs"}
                            onClick={() => handleUnitChange("kgs")}
                        />
                        <label className={`kgs-radio-label __radio ${COMPONENT_CLASS}__weight-button ${weight.unitsSelected === "kgs" ? "-active" : ""
                            }`} htmlFor="KG" >KG</label>
                        <input
                            name="weight-type"
                            type="radio"
                            id="LBS"
                            value="LBS"
                            checked={weight.unitsSelected === "lbs"}
                            onClick={() =>
                                handleUnitChange("lbs")
                            }
                        />
                        <label className={`kgs-radio-label __radio ${COMPONENT_CLASS}__weight-button ${weight.unitsSelected === "lbs" ? "-active" : ""
                            }`} htmlFor="LBS">LBS</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeightInputOption;
