import { useEffect, useState } from "react";
import { ReportType } from "../../models/enumerations/report-type";
import { ReportAssessmentData } from "../../models/interfaces/reports/report-assessment-data";
import { ReportComplianceData } from "../../models/interfaces/reports/report-compliance-data";
import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import { ReportSummaryData } from "../../models/interfaces/reports/report-summary-data";
import FunctionService from "../../utilities/services/function-service";
import { Loader } from "../loader/loader";
import ReportAssessment from "./report-assessment";
import ReportCompliance from "./report-compliance";
import ReportDetail from "./report-detail";
import ReportScore from "./report-score";
import ReportSummary from "./report-summary";
import { ReportWellnessData } from "../../models/interfaces/reports/report-wellness-data";
import ReportWellness from "./report-wellness";
import ReportMovement from "./report-movement";
import { ReportMovementData } from "../../models/interfaces/reports/report-movement-data";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportProps {
    reportId: string;
    type: ReportType;
    userId: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportRunner: React.FC<ReportProps> = (props: ReportProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState<any>();

    useEffect(() => {
        if (props.reportId == null || props.userId == null) {
            return;
        }

        FunctionService.getReportData(
            props.reportId,
            props.userId,
        ).then(data => {
            setReportData(data);
            setIsLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let reportComponent = null;

    if (reportData) {
        switch (props.type) {
            case ReportType.Compliance:
                reportComponent = <ReportCompliance
                    data={reportData as ReportComplianceData} />
                break;
            case ReportType.Detail:
                reportComponent = <ReportDetail
                    data={reportData as ReportDetailData} />
                break;
            case ReportType.Score:
                reportComponent = <ReportScore
                    data={reportData as ReportDetailData} />
                break;
            case ReportType.Summary:
                reportComponent = <ReportSummary
                    data={reportData as ReportSummaryData} />
                break;
            case ReportType.Assessment:
                reportComponent = <ReportAssessment
                    data={reportData as ReportAssessmentData} />
                break;
            case ReportType.Wellness:
                reportComponent = <ReportWellness
                    data={reportData as ReportWellnessData} />
                break;
            case ReportType.Movement:
                reportComponent = <ReportMovement
                    data={reportData as ReportMovementData} />
                break;
        }
    }

    return (
        <div>
            <Loader
                isVisible={isLoading} />
            {reportComponent}
        </div>
    );
}

export default ReportRunner;