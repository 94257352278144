import { IUser } from "../../../models/interfaces/assessment-user";
import { LifestyleScore } from "../../../models/interfaces/scores/lifestyle-score";
import FirestoreServiceAssessments from "../firestore-service-assessments";
import FirestoreCondition from "../../../models/interfaces/firestore-condition";

const COLLECTION_NAME = "lifestyleScores";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Update a recent record, or add a new score to the collection
 * @param {LifestyleScore} score - The user that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<LifestyleScore>} A promise to the newly added lifestyle score
 */
const addOrUpdateRecent = async (score: LifestyleScore, currentUser: IUser) => {
    // save only partial data
    score = {
        percentage: score.percentage,
        userId: currentUser.id!,
    };

    // overwrite existing record
    const recents = await FirestoreServiceAssessments.getRecent<LifestyleScore>(
        COLLECTION_NAME,
        currentUser
    );
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.update<LifestyleScore>(
            COLLECTION_NAME,
            score,
            currentUser
        );
    }

    // create record
    score.userId = currentUser.id!;
    return FirestoreServiceAssessments.add<LifestyleScore>(COLLECTION_NAME, score);
};

/**
 * Update a recent record, or add a new score to the collection
 * @param {LifestyleScore} score - The user that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<LifestyleScore>} A promise to the newly added lifestyle score
 */
const addOrUpdateRecentByUserId = async (score: LifestyleScore, currentUserId: string) => {
    // save only partial data
    score = {
        percentage: score.percentage,
        userId: currentUserId,
    };

    // overwrite existing record
    const recents = await FirestoreServiceAssessments.getRecentById<LifestyleScore>(
        COLLECTION_NAME,
        currentUserId
    );
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.updateById<LifestyleScore>(
            COLLECTION_NAME,
            score,
            currentUserId
        );
    }

    // create record
    score.userId = currentUserId;
    return FirestoreServiceAssessments.add<LifestyleScore>(COLLECTION_NAME, score);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreServiceAssessments.deleteById(COLLECTION_NAME, id);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];
    const records = await FirestoreServiceAssessments.getBy(COLLECTION_NAME, conditions);

    records.forEach((record) => {
        FirestoreServiceAssessments.deleteById(COLLECTION_NAME, record.id!);
    });
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const get = async (id: string) => {
    return FirestoreServiceAssessments.get<LifestyleScore>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} userId - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const getByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    return FirestoreServiceAssessments.getBy<LifestyleScore>(COLLECTION_NAME, conditions, [
        {
            field: "created",
            direction: "desc",
        },
    ]);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const getMostRecentByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    return FirestoreServiceAssessments.getBy<LifestyleScore>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "desc",
            },
        ],
        1
    );
};

/**
 * Get all of the users stored in the database
 * @returns {Promise<IUser[]>} A promise for the collection of users
 */
const getAll = async () => {
    return FirestoreServiceAssessments.getAll<LifestyleScore>(COLLECTION_NAME);
};

/**
 * Find most recent records by user id
 * @param {string} userId - The Id of the user that we are retrieving records for
 * @param {number} count - Number of records to return
 * @returns {Promise<MovementScore>} A promise for the MSK Score we are retrieving
 */
const getFirstCompletedDateMs = async (
    userId: string
): Promise<number | undefined> => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    const lifestyleScores = await FirestoreServiceAssessments.getBy<LifestyleScore>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "asc",
            },
        ],
        1
    );
    if (lifestyleScores == null || lifestyleScores.length === 0) {
        return undefined;
    }

    if (lifestyleScores[0].created == null) {
        return 0;
    }
    return;
    // TODO: 
    // return lifestyleScores[0].created.toMillis();
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const LifestyleScoreService = {
    addOrUpdateRecent,
    addOrUpdateRecentByUserId,
    deleteById,
    deleteByUser,
    get,
    getByUserId,
    getFirstCompletedDateMs,
    getMostRecentByUserId,
    getAll,
};

export default LifestyleScoreService;

// #endregion Exports
