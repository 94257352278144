import React, { useEffect } from 'react';
import { getAuth, signInWithPopup, SAMLAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import UserService from '../../utilities/services/user-service';
import OrganizationService from '../../utilities/services/organization-service';
import { UserStatus } from '../../models/enumerations/user-status';
import { useHistory } from 'react-router';
import { useAuthState } from '../../utilities/contexts/auth-state-context';

// const COMPONENT_CLASS = "c-okta-login";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface OktaLoginProps {

}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OktaLogin: React.FC<OktaLoginProps> = (props: OktaLoginProps) => {
    const auth = getAuth();
    const history = useHistory();
    const { setState } = useAuthState();
    const queryParams = new URLSearchParams(window.location.search);

    const oktaAuthenticate = (() => {
        const provider = new SAMLAuthProvider("saml.okta");

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // Check if user exists
                const existingUser = await UserService.getBy([{
                    field: "email",
                    operator: "==",
                    value: result.user.email
                }])

                if (existingUser.length > 0) {
                    const oktaTile = queryParams.get("oktaTile");

                    if (oktaTile === "admin" && existingUser[0].roles && existingUser[0].roles?.length > 0) {
                        history.push("/dashboard");
                        return;
                    }

                    history.push("/wellness-assessment");
                }
                else {
                    const userDetails = getAdditionalUserInfo(result);

                    if (userDetails && userDetails.profile) {
                        const org = await OrganizationService.getBy([
                            {
                                field: "name",
                                operator: "==",
                                value: "Coral Springs", // hard-coded the organization name, because we only have Coral Springs as a client who uses OKTA
                            }
                        ])

                        if (org.length === 0) {
                            // return error
                        }

                        const user = await UserService.add({
                            email: result.user.email as string,
                            groupId: null,
                            groupName: null,
                            firstName: userDetails.profile.firstName as string,
                            lastName: userDetails.profile.lastName as string,
                            organizationId: org[0].id,
                            roles: [],
                            status: UserStatus.Inactive,
                            isSuperAdmin: false,
                            tags: [],
                        });

                        setState((state) => ({
                            ...state, ...{ organization: org[0], user: user }
                        }));
                    }
                    else {
                        // return error saying couldn't retrieve user details
                    }
                    history.push("/wellness-assessment");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    })

    useEffect(() => {
        oktaAuthenticate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
        </div>
    );
}

export { OktaLogin };
