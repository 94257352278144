import { CollectionUtils } from "andculturecode-javascript-core";
import { QuestionGroup } from "../../models/interfaces/questions/question-group";
import { Slide } from "../../models/interfaces/slide";
import { QuestionUtils } from "../question-utils";

export default function useAccountSetupQuestions(
    questionGroups: QuestionGroup[]
): Slide[] {
    let slides = [] as Slide[];

    if (CollectionUtils.isEmpty(questionGroups)) {
        return slides;
    }

    questionGroups?.forEach((questionGroup) => {
        const questionSlides =
            QuestionUtils.createQuestionSlides(questionGroup);

        slides.push(...questionSlides);
    });

    return slides;
}
