import React, { useEffect, useState } from 'react';
import { EditOrganizationForm } from '../organization-form/edit-organization-form';
import { DialogBox } from '../../dialog-box/dialog-box';
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import UserUtil from '../../../utilities/user-util';
import OrganizationService from '../../../utilities/services/organization-service';
import { EditSubOrganizationForm } from '../edit-sub-organization-form/edit-sub-organization-form';

type EditOrganizationModalProps = {
    organizationId: string | undefined,
    open: boolean,
    onClose: Function,
};

export const EditOrganizationModal: React.FC<EditOrganizationModalProps> = (props: EditOrganizationModalProps) => {
    const {
        organizationId,
        open,
        onClose,
    } = props;

    const { state } = useAuthState();

    const [isSubOrg, setIsSubOrg] = useState<boolean>();

    const isAccountHolder = UserUtil.isAccountHolder(state.user);

    useEffect(() => {
        if (organizationId) {
            OrganizationService.get(organizationId).then((response) => {
                setIsSubOrg(response?.isSubOrganization ?? false);
            });
        } else {
            setIsSubOrg(false);
        }
    }, [organizationId])

    if (isSubOrg === undefined) {
        return null;
    }

    return (
        <>
            <DialogBox
                maxWidth="lg"
                fullWidth
                open={!!organizationId && open}
                onClose={onClose}
                sx={{
                    maxWidth: isSubOrg || isAccountHolder ? '875px' : '1400px',
                    height: '1200px',
                    margin: 'auto',
                    maxHeight: '99ch',
                }}
            >
                {
                    (isSubOrg || isAccountHolder)
                        ? (
                            <EditSubOrganizationForm
                                onSave={onClose}
                                onCancel={onClose}
                                organizationId={organizationId}
                            />
                        )
                        : (
                            <EditOrganizationForm
                                onSave={onClose}
                                onCancel={onClose}
                                organizationId={organizationId}
                            />
                        )
                }
            </DialogBox>
        </>
    );
}