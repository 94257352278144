import { CalculateNextAssessment } from "../../../models/interfaces/api/calculateNextAssessment";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalculateQueryParams {}

export interface CalculatePathParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/calculator`;
const nextAssessmentEndpoint = `${baseEndpoint}/next-assessment`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CalculateService = {
    /**
     * Determines the next group to use
     */
    nextAssessment: ServiceBase.post<string, CalculateNextAssessment>(
        nextAssessmentEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CalculateService;

// #endregion Exports
