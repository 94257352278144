export default class RiskFactorUtil {

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Takes in a risk factor percent (100 = high risk, 0 = low risk) and returns the right position of a pointer.
     */
    public static convertRiskFactorToDisplayPercent(percent: number | undefined | null): number | null {
        if (percent == null || percent < 0 || percent > 100) {
            return null;
        }

        // low risk / performance: 0 -> middle of 0-25
        if (percent === 0) {
            return 12.5;
        }

        const riskRanges = [
            { min: 1, max: 27 }, // slight risk
            { min: 28, max: 72 }, // moderate risk
            { min: 73, max: 100 }, // high risk
        ];
        for (let i=0; i<riskRanges.length; i++) {
            if (percent <= riskRanges[i].max) {
                const riskRange = riskRanges[i];
                const rangeDiff = riskRange.max - riskRange.min;
                const startOffset = 1 + (i+1) * 25;
                return Math.round(((percent - riskRange.min) / rangeDiff * 23) + startOffset);
            }
        }

        return null;
    }

    // #endregion Public Methods

}
