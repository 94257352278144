import { ReactComponent as NotificationAccount } from "../../assets/icons/solid/notification-account.svg";
import { ReactComponent as NotificationInactivity } from "../../assets/icons/solid/notification-inactivity.svg";
import { ReactComponent as NotificationHighRisk } from "../../assets/icons/solid/notification-high-risk.svg";
import { ReactComponent as NotificationMovement } from "../../assets/icons/solid/notification-movement.svg";
import { ReactComponent as NotificationDayStreak } from "../../assets/icons/solid/notification-day-streak.svg";
import { ReactComponent as NotificationCompletedTasks } from "../../assets/icons/solid/notification-completed-tasks.svg";
import { ReactComponent as NotificationBehavioralHealth } from "../../assets/icons/general-icons/behavioral-health.svg";
import { ReactComponent as NotificationSleepWellness } from "../../assets/icons/general-icons/sleep-wellness.svg";
import { ReactComponent as NotificationNutritionAwareness } from "../../assets/icons/general-icons/nutrition-awareness.svg";
import { ReactComponent as NotificationPhysicalActivity } from "../../assets/icons/general-icons/physical-activity.svg";
import { ReactComponent as NotificationBodyComposition } from "../../assets/icons/general-icons/body-composition.svg";
import { ReactComponent as NotificationBreathingQuality } from "../../assets/icons/general-icons/breathing-quality.svg";
import { ReactComponent as NotificationInjuryHistory } from "../../assets/icons/general-icons/injury-history.svg";
import { ReactComponent as NotificationMovementHealth } from "../../assets/icons/general-icons/movement-health.svg";

// import { ReactComponent as ArrowUp } from "../../assets/icons/solid/arrow-up.svg";
// import { ReactComponent as ArrowDown } from "../../assets/icons/solid/arrow-down.svg";

import { ReactComponent as NotificationClose } from "../../assets/icons/solid/notification-close.svg";
import { Notification } from '../../models/interfaces/notification';
import { NotificationType } from '../../models/enumerations/notification-type';
import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";

const COMPONENT_CLASS = "c-notification-menu";

export interface NotificationItemProps {
  notification?: Notification;
  empty?: boolean;
  onDelete?: Function;
  onRead?: Function;
}

const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  const getNotificationIcon = () => {
    switch (props.notification?.notificationType) {
      case NotificationType.NewAccount:
        return <NotificationAccount />;
      case NotificationType.HighRiskUser:
        return <NotificationHighRisk />;
      case NotificationType.CompletedTasks:
        return <NotificationCompletedTasks />;
      case NotificationType.InactiveAccount:
        return <NotificationInactivity />
      case NotificationType.DayStreak:
        return <NotificationDayStreak />;
      case NotificationType.CompletedAssessment:
        return <NotificationMovement />;
      case NotificationType.UpdatedAssessment:
        return <NotificationMovement />;
      case NotificationType.BehavioralHealth:
        return <NotificationBehavioralHealth />;
      case NotificationType.BodyComposition:
        return <NotificationBodyComposition />;
      case NotificationType.BreathingQuality:
        return <NotificationBreathingQuality />;
      case NotificationType.InjuryHistory:
        return <NotificationInjuryHistory />;
      case NotificationType.MovementHeath:
        return <NotificationMovementHealth />;
      case NotificationType.NutritionAwareness:
        return <NotificationNutritionAwareness />;
      case NotificationType.PhysicalActivity:
        return <NotificationPhysicalActivity />;
      case NotificationType.SleepWellness:
        return <NotificationSleepWellness />;
      default:
        return <NotificationAccount />;
    }
  }

  const getDate = (notificationDate: Timestamp | undefined) => {
    if (notificationDate) {
      return format(notificationDate.toDate(), "MMMM d, h:mm a")
    }
    return;
  }

  return (
    <>
      {props.empty && (
        <div className={`${COMPONENT_CLASS}__item`}>
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', fontWeight: '300', color: '#888A90' }}>
            No notifications
          </div>
        </div>
      )}
      {!props.empty && (
        <div
          style={{ width: '400px', height: '110px' }}
          className={`${COMPONENT_CLASS}__item`}>
          <div className={`${COMPONENT_CLASS}__item__info`} onClick={() => {
            if (props.onRead)
              props.onRead(props.notification!.id)
          }}>
            <div style={{ width: '6px' }}>
              {!props.notification?.read && (
                <div style={{ width: '6px', height: '6px', backgroundColor: '#E7514F', borderRadius: '50%' }}></div>
              )}
            </div>
            <div style={{ width: '40px', marginLeft: '0.875rem' }}>
              {getNotificationIcon()}
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.875rem', fontSize: '12px' }}>
                <p>{props.notification?.userName} {props.notification?.notificationText}</p>
                {props.notification?.info?.assessmentScore !== undefined && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p>New Score: <strong>{props.notification?.info?.assessmentScore}</strong></p>
                    {/* {props.notification?.info?.assessmentScoreIncrease && (
                      <ArrowUp style={{ marginLeft: '0.5rem', marginRight: '0.25rem' }} />
                    )}
                    {props.notification?.info?.assessmentScoreDecrease && (
                      <ArrowDown style={{ marginLeft: '0.5rem', marginRight: '0.25rem' }} />
                    )}
                    <p style={{ color: props.notification?.info?.assessmentScoreIncrease ? '#00DFBC' : '#E7514F' }}>{props.notification?.info?.assessmentScoreIncrease ?? props.notification.info.assessmentScoreDecrease}pts</p> */}
                  </div>
                )}
                <div style={{ fontWeight: '300', color: '#888A90', marginTop: '0.25rem' }}>{props.notification?.organizationName} • {getDate(props.notification?.created)}</div>
              </div>
            </div>
          </div>
          <div className={`${COMPONENT_CLASS}__item__clear`} onClick={() => {
            if (props.onDelete)
              props.onDelete(props.notification!.id)
          }}>
            <NotificationClose />
          </div>
        </div>
      )}

    </>

  );
}

export { NotificationItem };
