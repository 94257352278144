import { ListOptions } from "./interfaces/list-options";

export const MskHealthRiskOptions: ListOptions[] = [
    {
        value: "Health",
        label: "High",
    }, {
        value: "Wellness",
        label: "Moderate",
    }, {
        value: "Fitness",
        label: "Slight",
    }, {
        value: "Performance",
        label: "Low",
    }
];
