export default class StringUtil {

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * True if the value exists in the array
     */
    public static existsInArray(value: string, data: (string | undefined)[], compareLowerCase: boolean = false): boolean {
        if (data == null || data.length === 0) {
            return false;
        }

        if (compareLowerCase === true) {
            const dataLower = data.map((item) => item == null ? item : item.toLocaleLowerCase());
            return this._existsInLowerCaseArray(value, dataLower);
        }

        return data.indexOf(value) >= 0;
    }

    /**
     * Generates array of non-nulll, non-empty, case-insensitive unique values
     */
    public static getUniqueValues(data: (string | undefined)[], forceLowerCase: boolean = false): string[] {
        const result: string[] = [];
        if (data == null || data.length === 0) {
            return result;
        }

        const validItems: string[] = data.filter((item) => item != null && item.trim().length !== 0).map((item) => item!);
        validItems.forEach((item) => {
            if (forceLowerCase) {
                if (!this._existsInLowerCaseArray(item, result)) {
                    result.push(item.toLocaleLowerCase());
                }
            } else if (!this.existsInArray(item, result, forceLowerCase)) {
                result.push(item);
            }
        });

        return result;
    }

    // #endregion Public Methods


    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    /**
     * True if the value exists in the array
     */
    private static _existsInLowerCaseArray(value: string, lowerCaseData: (string | undefined)[]): boolean {
        value = (value == null ? value : value.toLocaleLowerCase());
        return lowerCaseData.indexOf(value) >= 0;
    }

    // #endregion Private Methods

}
