import BaseError from "./base-error";

export default class ArticleError extends BaseError {
    constructor(error: Error, methodCalled: string, payload: any = null) {
        super(error, methodCalled, payload);
    }

    public getSourceName(): string {
        return "ArticleError";
    }
}
