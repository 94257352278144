import { CategoryIds } from "../../models/enumerations/category-ids";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import { QuestionGroup } from "../../models/interfaces/questions/question-group";
import { SlideGroup } from "../../models/interfaces/slide-group";
import { QuestionUtils } from "../question-utils";

export default function ScreenQuestionsAsGroup(
    questionGroups: QuestionGroup[]
): SlideGroup[] {
    const slideGroups = [] as SlideGroup[];
    const isFullAssessment = questionGroups.length >= 3;

    if (isFullAssessment) {
        const categoryId =
            questionGroups.length === 0
                ? undefined
                : questionGroups[0].categoryId;
        const groupId =
            questionGroups.length === 0 ? undefined : questionGroups[0].groupId;
        const hasTips = categoryId === CategoryIds.MOVEMENT;

        slideGroups.push({
            categoryId: categoryId,
            groupId: GroupIds.ASSESSMENT_INTRO_SLIDE_GROUP_ID,
            nextGroupId: hasTips
                ? GroupIds.TIPS_SLIDE_GROUP_ID
                : GroupIds.TESTS_SLIDE_GROUP_ID,
            slides: [
                {
                    slideType: SlideType.AssessmentIntro,
                    categoryId: categoryId,
                    groupId: groupId,
                },
            ],
        });
        if (hasTips) {
            slideGroups.push({
                categoryId: categoryId,
                groupId: GroupIds.TIPS_SLIDE_GROUP_ID,
                nextGroupId: GroupIds.TESTS_SLIDE_GROUP_ID,
                slides: [
                    {
                        slideType: SlideType.Tips,
                        categoryId: categoryId,
                        groupId: groupId,
                    },
                ],
            });
        }
        slideGroups.push({
            categoryId: categoryId,
            groupId: GroupIds.TESTS_SLIDE_GROUP_ID,
            nextGroupId:
                questionGroups.length === 0
                    ? GroupIds.COMPLETE_SLIDE_GROUP_ID
                    : questionGroups[0].nextGroupId,
            slides: [
                {
                    slideType: SlideType.Tests,
                    categoryId: categoryId,
                    groupId: groupId,
                },
            ],
        });
    }

    // loop groups
    questionGroups.forEach((questionGroup) => {
        const slides = QuestionUtils.createQuestionSlides(questionGroup);
        slideGroups.push({
            categoryId: questionGroup.categoryId,
            groupId: questionGroup.groupId,
            groupName: questionGroup.groupName,
            description: questionGroup.description,
            nextGroupId: questionGroup.nextGroupId,
            nextGroupIdDeterminedByApi:
                questionGroup.nextGroupIdDeterminedByApi,
            slides: slides,
        });
    });
    if (slideGroups.length !== 0) {
        slideGroups[slideGroups.length - 1].nextGroupId =
            GroupIds.COMPLETE_SLIDE_GROUP_ID;
    }

    // complete
    let categoryId =
        questionGroups.length === 0 ? undefined : questionGroups[0].categoryId;
    if (categoryId == null) {
        categoryId =
            slideGroups.length === 0 ? undefined : slideGroups[0].categoryId;
    }
    slideGroups.push({
        categoryId: categoryId,
        groupId: GroupIds.COMPLETE_SLIDE_GROUP_ID,
        slides: [
            {
                categoryId: categoryId,
                slideType: SlideType.Complete,
            },
        ],
    });

    return slideGroups;
}
