import { useHistory, Redirect } from "react-router-dom";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { Button } from '../../components/button/button';
import logoImage from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import orgStructureImage from "../../assets/images/lead-capture-org-structure.png"
import insightsImage from "../../assets/images/lead-capture-insights.png"
import reportsImage from "../../assets/images/lead-capture-reports.png"
import mobileAppImage from "../../assets/images/lead-capture-mobile-app.png"
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../../components/assessments-user-menu/assessments-user-menu";
import LeadLinksService from "../../utilities/services/lead-links-service";
import { LeadLink } from "../../models/interfaces/lead-link";
import AppContentUtil from "../../utilities/app-content-util";
import { enqueueSnackbar } from "notistack";
import ReportService from "../../utilities/services/report-service";
import UserService from "../../utilities/services/user-service-assessments";
import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";

const LEAD_CAPTURE_LINK_TYPE = "leadCapture";

export interface LeadCaptureResultsPageProps {

}

const COMPONENT_CLASS = "p-lead-capture-results";

const LeadCaptureResultsPage: React.FunctionComponent<LeadCaptureResultsPageProps> = () => {
    const { state } = useAuthState();
    const history = useHistory();

    const [leadLink, setLeadLink] = useState<LeadLink | null>();

    useEffect(() => {
        const getLeadCaptureLink = async () => {
            let fetchedLink: LeadLink | null = null;

            try {
                fetchedLink = await LeadLinksService.get(state.user?.leadLinkId!);
                setLeadLink(fetchedLink);
            } catch (err) {
                console.error("Cannot fetch the lead link ID");
            }
        }

        getLeadCaptureLink();
    }, [state.user?.leadLinkId]);

    const handleDownloadReport = async () => {
        try {
            if (!state.user?.id) return;
            const userInFirebase = await UserService.get(state.user.id);
            if (!userInFirebase || !userInFirebase.organizationId) return;

            const latestOrgId = userInFirebase.organizationId;
            const userId = userInFirebase.id;

            if (!userId) {
                return;
            }
            const date = DateTime.utc().toISO();
            const report = await ReportService.getBy(
                [
                    {
                        field: "userId",
                        operator: "==",
                        value: userId,
                    },
                    {
                        field: "organizationId",
                        operator: "==",
                        value: latestOrgId,
                    },
                    {
                        field: "status",
                        operator: "==",
                        value: "complete",
                    },
                    {
                        field: "expiration",
                        operator: ">",
                        value: date,
                    },
                ], [
                {
                    field: "expiration",
                    direction: "desc",
                },
                {
                    field: "updated",
                    direction: "desc",
                }]);
            if (report.length <= 0) {
                enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "warning" });
                handleGenerateReport(userId, latestOrgId);
                return;
            }

            if (!report[0].id) {
                return;
            }

            if (report[0].emailResults === true) {
                return;
            }

            const reportUnsubscribe = ReportService.getSnapshot(
                report[0].id,
                (r: Report) => {
                    if (r.status === "complete" && r.url) {
                        reportUnsubscribe();

                        window.open(r.url);
                    }
                    if (r.status === "error") {
                        reportUnsubscribe();
                    }
                }
            );
        } catch (err: any) {
            console.error("An error occurred while generating a report: ", err);
            enqueueSnackbar(err);
        }
    };

    const handleGenerateReport = async (userId: string, organizationId: string) => {
        const date = DateTime.now().toISODate();
        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: ReportType.Wellness,
            userId: userId,
        };

        const scheduledReport = await ReportService.save(
            report,
            state.user
        );

        if (!scheduledReport.id) {
            return;
        }

        if (scheduledReport.emailResults === true) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    reportUnsubscribe();
                }
            }
        );
    };

    const downloadReportButton = (
        <Button
            type="default"
            buttonText="Download Your Report"
            onClick={() => handleDownloadReport()} />
    );

    const getStartedButton = (isBlue: boolean = false) => (
        <Button
            type="default"
            buttonText="Get Started for Free"
            buttonStyle={isBlue ? "blue" : undefined}
            onClick={() => history.push(`/lc-pricing/${state.user?.leadLinkId}/${LEAD_CAPTURE_LINK_TYPE}/${state.user?.id}`)} />
    );

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }

    if (!state.user || !state.user?.leadLinkId || state.user.organizationId !== process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect push to="/" />
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className="user-menu">
                <AssessmentsUserMenu profileImage={false} />
            </div>
            <BaseCardLayout>
                <header>
                    <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                        <h1>Hi {state.user?.firstName ?? "..."},</h1>
                        <p>
                            Thank you for completing your assessments! Download your report to see your results.
                        </p>
                        {downloadReportButton}
                        <p className="italic text-center" style={{ fontSize: "12px" }}>Please disable popup blocker to download your report.</p>
                    </div>
                </header>
                <main>
                    <div className={`${COMPONENT_CLASS}__video-container`}>
                        <div className={`${COMPONENT_CLASS}__logo`}>
                            <img src={logoImage} alt="Symmio Logo" />
                        </div>
                        <div>
                            <span className={`${COMPONENT_CLASS}__big-paragraph text-white`}>
                                Meet your organization’s new health and wellness manager.
                            </span>
                            <br />
                            <br />
                            <p className="text-white">
                                Take the first step towards improving the overall health and wellness of your users with Symmio - an easy-to-use wellness platform.
                            </p>
                        </div>
                        <div className={`${COMPONENT_CLASS}__video-wrapper`}>
                            {leadLink && leadLink?.videoUrl
                                ? <div className={`${COMPONENT_CLASS}__modal-info-container`} dangerouslySetInnerHTML={{ __html: AppContentUtil.getVideoEmbedCode(leadLink?.videoUrl) }}></div>
                                : <></>}
                        </div>
                        <div>
                            {getStartedButton(true)}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__cards-container`}>
                        <div className={`${COMPONENT_CLASS}__card`}>
                            <img className={`${COMPONENT_CLASS}__card-image`} src={orgStructureImage} alt="MSK Score" />
                            <div className={`${COMPONENT_CLASS}__card-text`}>
                                <h4>Manage Your Team & Organization Structure</h4>
                                <p>Add groups and tags to your organization structure to organize your users and team.</p>
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__card right`}>
                            <div className={`${COMPONENT_CLASS}__card-text`}>
                                <h4>Gain User Insights in Minutes</h4>
                                <p>Check-in on users to see where they are on their overall health and wellness journey. Track progress over time and see how their wellness has improved, and which areas still need attention.</p>
                            </div>
                            <img className={`${COMPONENT_CLASS}__card-image`} src={insightsImage} alt="Assessment Score" />
                        </div>
                        <div className={`${COMPONENT_CLASS}__card`}>
                            <img className={`${COMPONENT_CLASS}__card-image`} src={reportsImage} alt="Focus Areas" />
                            <div className={`${COMPONENT_CLASS}__card-text`}>
                                <h4>Run Reports & Track Compliance</h4>
                                <p>Get a summary of scores and compliance across your organization, or dig deeper into the details of specific focus areas.</p>
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__card right`}>
                            <div className={`${COMPONENT_CLASS}__card-text`}>
                                <h4>Automate Your Health & Wellness Efforts</h4>
                                <p>Give your users access to the Symmio app, where they’ll get personalized improvement plans and guided corrective exercises based on their assessment results.</p>
                            </div>
                            <img className={`${COMPONENT_CLASS}__card-image`} src={mobileAppImage} alt="Assessment Score" />
                        </div>
                    </div>
                </main>
                <footer>
                    <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                        <div className={`${COMPONENT_CLASS}__big-paragraph`}>
                            Bring Symmio to your organization today.
                        </div>
                        <div>
                            {getStartedButton()}
                        </div>
                    </div>
                </footer>
            </BaseCardLayout >
        </div >
    );
};

export default LeadCaptureResultsPage;