export default class EntityNotFoundException extends Error {
    public static message = "An entity with that Id was not found";

    constructor(message?: string) {
        super(message ?? "An entity with that Id was not found");
        this.name = "EntityNotFoundException";
        // restore prototype chain
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
