import React from "react";
// import { enUS } from "@mui/x-date-pickers/locales";
import 'dayjs/locale/en';
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const COMPONENT_CLASS = "c-account-information-date-input-option";

export interface DateInputOptionProps {
    style?: any;
    onChange: (newAnswer: string) => void;
    answer?: Dayjs | null | string;
    defaultBirthdate?: string;
    onInvalidDate?: () => void;
}

const DateInputOption: React.FC<DateInputOptionProps> = (props) => {
    // TODO: Complaining about changing from controlled to uncontrolled

    const minDate = dayjs(new Date(1920, 0, 1)); // Kyle requested this min date
    const maxDate = dayjs(new Date());

    const handleOnInvalidDate = () => {
        if (props.onInvalidDate) {
            props.onInvalidDate();
        }
    }

    const isDateValid = (date: Dayjs): boolean => {
        if ((date.isAfter(minDate) || date.isSame(minDate)) && (date.isBefore(maxDate) || date.isSame(maxDate))) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <div className={`${COMPONENT_CLASS} w-full`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}

            >
                <DatePicker
                    sx={props.style ?? {

                        borderRadius: "10px",
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '1.5rem',
                            width: '100%',
                            height: '45.6px',
                            fontFamily: 'pragmatica-extended, sans-serif',
                            '& fieldset': {
                                border: '1px solid rgba(18, 22, 34, 0.20)',
                            },
                        },
                    }}
                    className="DatePicker w-full"
                    value={props.answer}
                    format="MM/DD/YYYY"
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={(d) => {
                        if (isDateValid(dayjs(d))) {
                            let date = dayjs(d).format('YYYY-MM-DD')
                            props.onChange(date)
                        }
                        else {
                            handleOnInvalidDate();
                        }
                    }}
                    slotProps={{ inputAdornment: { style: { display: 'none' } } }}
                />

            </LocalizationProvider>
            {/* <input id="dateInput" type="date" onClick={() => {
                let date = document.getElementById("dateInput") as HTMLInputElement;
                if (date) {
                    date.showPicker();
                }

            }} placeholder="dd-mm-yyyy" max={today}></input>

            <input id="dateInput" type="text" placeholder="MM/DD/YYYY" max={today} value={props.answer}></input> */}

        </div >
    );
};

export default DateInputOption;
