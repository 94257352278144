import React, { useEffect, useState } from 'react';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';
import SubscriptionService from '../../utilities/services/subscription-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import HubspotSubCancellationDataService from '../../utilities/services/hubspot-sub-cancellation-data-service';
import { HubspotSubCancellationData } from '../../models/interfaces/hubspot-sub-cancellation-data';
import { enqueueSnackbar } from 'notistack';
import { format } from "date-fns";
import { ReactComponent as CrossIcon } from "../../assets/icons/solid/red-cross.svg";
import BaseCardLayoutActions from '../base-card-layout/base-card-layout-actions';
import Stripe from "stripe";
import { useHistory } from 'react-router-dom';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import OrganizationSubscriptionService from '../../utilities/services/organization-subscription-service';

interface ManageUserCancellationFormProps {
    clearFields?: boolean;
    hideCoupon?: boolean;
    onCancel?: Function;
    hideBackButton?: boolean;
    skipConfirmation?: boolean;
}

const COMPONENT_CLASS = "c-cancellation-management";
const MODAL_COMPONENT_CLASS = "c-modal";

const ManageUserCancellationForm: React.FC<ManageUserCancellationFormProps> = (props) => {
    const {
        clearFields,
        hideCoupon,
        onCancel,
        hideBackButton,
        skipConfirmation,
    } = props;

    const { state } = useAuthState();
    const [openCouponOffer, setOpenCouponOffer] = useState(false);
    const [selectedReason, setSelectedReason] = useState<number | null>(null);
    const [details, setDetails] = useState('');
    const [intervalStripe, setIntervalStripe] = useState(''); // Can't name it setInterval since it's a name taken by a global built-in function
    const [nextPaymentDate, setNextPaymentDate] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState('');

    const [openBenefitsPage, setOpenBenefitsPage] = useState<boolean>(false);
    const [openConfirmationPage, setOpenConfirmationPage] = useState<boolean>(false);
    const [couponExists, setCouponExists] = useState<boolean>(false);
    const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const commonStyles = {
        fontSize: '10px',
        fontWeight: 'bold',
        marginBottom: '1rem',
        color: 'black',
        width: 'fit-content',
        height: 'fit-content',
        padding: '6px 8px',
        borderRadius: '4px'
    };

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    useEffect(() => {
        if (state.user && state.user.id) {
            UserService.getSnapshot(state.user.id, async (u: User) => {
                // await stripe.customers.list({
                //   limit: 1,
                //   email: `${u?.email}`,
                //   expand: ['data.subscriptions']
                // }).then(async (customer: any) => {

                // })
                if (u && u.subscription && u.subscription.subscriptionId) {
                    await stripe.subscriptions.retrieve(
                        u?.subscription?.subscriptionId
                    ).then(async (subscription) => {
                        if (subscription.discount && subscription.discount.coupon && !subscription.cancel_at_period_end) {
                            setCouponExists(true);
                        }
                        if (subscription.items.data[0].plan.interval === "year") {
                            setIntervalStripe('year');
                        }
                        else {
                            setIntervalStripe('month');
                        }
                        const end = new Date(subscription.current_period_end * 1000);
                        setNextPaymentDate(format(end, "MMMM dd, yyyy"));
                    });
                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideCoupon]);

    const reasons = [
        { value: 'I intend to use a different tool' },
        { value: 'I need features Symmio doesn’t have' },
        { value: 'Symmio is too expensive' },
        { value: 'Other' }];

    const benefits = [
        { value: 'Unlimited Wellness Assessments' },
        { value: 'Comprehensive Wellness Reports' },
        { value: 'The Symmio App' },
        { value: 'Wellness plans and corrective exercises' },
        { value: 'Health and wellness progress tracking' },
        { value: 'Education and daily tips' }
    ];

    const handleRadioChange = (reason: number) => {
        setSelectedReason((prev) => prev === reason ? null : reason);
    };

    const handleCancellation = async () => {
        if (state.user) {
            if (onCancel) {
                onCancel();
            }
            else {
                await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
            }

            const hubspotSubCanellationData: HubspotSubCancellationData = {
                organizationName: state.organization.name,
                accountHolderEmail: state.user?.email,
                accountHolderName: `${(state.user?.firstName || "")} ${(state.user?.lastName || "")}`,
                response: selectedReason !== null ? reasons[selectedReason].value : undefined,
                details: details || undefined,
                isUserSubscription: true,
            }

            await HubspotSubCancellationDataService.add(hubspotSubCanellationData, state.user);
        }
    };

    const getCouponPrices = async () => {
        if (state.user) {
            const orgSub = await OrganizationSubscriptionService.getBy([{
                field: "organizationId",
                operator: "==",
                value: state.user.organizationId
            }]);

            if (intervalStripe === 'year') {
                if (orgSub.length > 0 && orgSub[0].mySymmioYearlyPrice) {
                    setOriginalPrice(USDollar.format(orgSub[0].mySymmioYearlyPrice));
                    setDiscountedPrice(USDollar.format(orgSub[0].mySymmioYearlyPrice * 0.7));
                }
            }
            else {
                if (orgSub.length > 0 && orgSub[0].mySymmioMonthlyPrice) {
                    setOriginalPrice(USDollar.format(orgSub[0].mySymmioMonthlyPrice));
                    setDiscountedPrice(USDollar.format(orgSub[0].mySymmioMonthlyPrice * 0.5));
                }
            }
        }

    }

    useEffect(() => {
        console.log(selectedReason);

        if (selectedReason !== null)
            console.log(reasons[selectedReason].value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReason])

    // Modal closes - reset everything;
    useEffect(() => {
        if (clearFields) {
            setSelectedReason(null);
            setDetails('');
            setOpenBenefitsPage(false);
            setOpenConfirmationPage(false);
        }
    }, [clearFields]);

    const history = useHistory();

    const couponOffer = (
        <Modal
            isOpen={openCouponOffer}
            isLoading={false}
            onClose={(closeModal) => {
                setOpenCouponOffer(closeModal);
            }}>
            <div className={`${COMPONENT_CLASS}__coupon-box`}>
                <span
                    style={{
                        background: 'rgba(0, 223, 188, 1)',
                        ...commonStyles,
                    }}
                >
                    LIMITED-TIME OFFER
                </span>
                {intervalStripe === 'year' ?
                    <><h3>Stay to get 30% off for the next year!</h3><div className={`${COMPONENT_CLASS}__coupon-box_offer`}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '1rem' }}>30% off for 1 year</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                            <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{discountedPrice}</h1>
                            <h1 style={{ fontSize: '13px', color: '#82848B' }}>/ year</h1>
                        </div>
                        <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '14px', color: 'var(--ion-color-secondary)' }}>
                            {originalPrice} / year
                        </p>
                        <Button
                            //type="large"
                            style={{ marginTop: '1rem' }}
                            buttonText="Claim Offer"
                            onClick={async () => {
                                if (state.user && state.user.subscription?.subscriptionId) {
                                    await stripe.subscriptions.update(
                                        `${state.user.subscription?.subscriptionId}`,
                                        {
                                            coupon: 'CpM7B9OB',
                                        }
                                    );
                                    enqueueSnackbar("Subscription Updated", { variant: "toast", width: "450px", autoHideDuration: 5000 });
                                    history.push("/plans-and-payment");
                                }
                            }} />
                        <p style={{ fontWeight: '300', fontSize: '12px', marginTop: '1.5rem' }}>
                            Effective at the end of your current billing cycle on {nextPaymentDate}.
                        </p>
                    </div><div className={`${MODAL_COMPONENT_CLASS}__actions`}>
                            <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className={`${MODAL_COMPONENT_CLASS}__actions__cancel`}
                                onClick={async () => {
                                    // if (state.user) {
                                    //   await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
                                    //    history.push("/plans-and-payment");
                                    // }
                                    setOpenBenefitsPage(true);
                                    setOpenCouponOffer(false);
                                }}>
                                Decline Offer, Continue to Cancel
                            </button>

                        </div></>
                    :
                    <><h3>Stay to get 50% off for the next 6 months!</h3><div className={`${COMPONENT_CLASS}__coupon-box_offer`}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '1rem' }}>50% off for 6 months</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                            <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{discountedPrice}</h1>
                            <h1 style={{ fontSize: '13px', color: '#82848B' }}>/ month</h1>
                        </div>
                        <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '14px', color: 'var(--ion-color-secondary)' }}>
                            {originalPrice} / month
                        </p>
                        <Button
                            //type="large"
                            style={{ marginTop: '1rem' }}
                            buttonText="Claim Offer"
                            onClick={async () => {
                                if (state.user && state.user.subscription?.subscriptionId) {
                                    await stripe.subscriptions.update(
                                        `${state.user.subscription?.subscriptionId}`,
                                        {
                                            coupon: 'DdJdKkSV',
                                        }
                                    );
                                    enqueueSnackbar("Subscription Updated", { variant: "toast", width: "450px", autoHideDuration: 5000 });
                                    history.push("/plans-and-payment");
                                }
                            }} />
                        <p style={{ fontWeight: '300', fontSize: '12px', marginTop: '1.5rem' }}>
                            Effective at the end of your current billing cycle on {nextPaymentDate}.
                        </p>
                    </div><div className={`${MODAL_COMPONENT_CLASS}__actions`}>
                            <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className={`${MODAL_COMPONENT_CLASS}__actions__cancel`}
                                onClick={async () => {
                                    // if (state.user) {
                                    //   await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
                                    //    history.push("/plans-and-payment");
                                    // }
                                    setOpenBenefitsPage(true);
                                    setOpenCouponOffer(false);
                                }}>
                                Decline Offer, Continue to Cancel
                            </button>

                        </div></>
                }
            </div>
        </Modal>
    );

    const reasonsPage = (
        <div className={COMPONENT_CLASS}>
            <div style={{ fontSize: '14px', fontWeight: '300' }} className='my-8'>We’re sad to see you go. Let us know why you’re cancelling so we can continue improving your health and wellness.</div>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F3F4', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>

                <div className={`${COMPONENT_CLASS}__reasons`}>
                    <strong>What’s your primary reason for cancelling?</strong>
                    {reasons.map((reason, index) => (
                        <div key={index}>
                            <div className={`reason ${selectedReason === index ? 'visible' : ''}`} onClick={() => handleRadioChange(index)} >
                                <div>
                                    <input type='radio' name="cancellationReason" value={reason.value} checked={selectedReason === index} onChange={e => { }} />
                                </div>
                                <label>{reason.value}</label>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', backgroundColor: '#F3F3F4', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>
                <div className={`${COMPONENT_CLASS}__cancellation-details`}>
                    <strong>Please let us know how we can improve.</strong>
                    <textarea onChange={event => setDetails(event.target.value)} value={details} placeholder="Enter Comments" />
                </div>
            </div>
        </div>
    );

    const benefitsPage = (
        <div className={`${COMPONENT_CLASS} my-8 mx-20 gap-8 flex flex-col`}>
            <div style={{ fontSize: '14px', fontWeight: '300' }}>You’re about to cancel your Symmio subscription. If you cancel, once your billing cycle ends on <strong>{nextPaymentDate}</strong>, you’ll lose access to:</div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', fontSize: '14px', fontWeight: '300' }}>
                {benefits.map((benefit, index) => (
                    <div style={{ display: 'flex' }}>
                        <div>
                            <CrossIcon />
                        </div>
                        <div style={{ marginLeft: '1rem' }}>{benefit.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );

    const confirmationPage = (
        <div className={COMPONENT_CLASS}>
            <div style={{ fontSize: '14px', fontWeight: '300' }} className='my-8 mx-20'>
                Your Symmio subscription has been cancelled. You can continue using Symmio until your billing cycle ends on <strong>{nextPaymentDate}</strong>. Once your billing cycle ends you will not be able to use Symmio.
                <br />
                <br />
                You will receive an email confirmation shortly.
            </div>
        </div>
    );

    return (
        <>
            {openCouponOffer && couponOffer}
            <div className={`${COMPONENT_CLASS}`}>
                <div className={`${COMPONENT_CLASS}__wrapper`}>
                    <div style={{ marginTop: '2rem' }} className={`c-subscriptions__header`}>
                        Cancel Subscription
                    </div>
                    <div className="px-4">
                        {openBenefitsPage ? benefitsPage :
                            (openConfirmationPage ? confirmationPage : reasonsPage)}
                    </div>
                </div>
            </div>
            <div className='w-full content-end'>
                <BaseCardLayoutActions
                    backButton={!openConfirmationPage || hideBackButton}
                    submitButton={true}
                    onSubmit={async () => {
                        if (openConfirmationPage) {
                            history.push("/plans-and-payment");
                        }
                        else if (!openBenefitsPage && !couponExists && !hideCoupon) {
                            getCouponPrices();
                            setOpenCouponOffer(true)
                        } else if (!openBenefitsPage && (couponExists || hideCoupon)) {
                            setOpenBenefitsPage(true);
                        } else if (openBenefitsPage) {
                            setOpenBenefitsPage(false);
                            setOpenConfirmationPage(true);
                            await handleCancellation();

                            if (skipConfirmation) {
                                history.push("/plans-and-payment");
                            }
                        } else {
                            history.push("/plans-and-payment");
                        }
                    }}
                    submitButtonText={openBenefitsPage ? "Confirm and Cancel Subscription" :
                        (openConfirmationPage ? "Continue" : "Next")}
                    onBack={() => history.push("/plans-and-payment")}
                    submitDisabled={selectedReason === null}
                />
            </div>
        </>
    );

};

export default ManageUserCancellationForm;