import React, { useEffect, useState } from "react";
import rd3 from 'react-d3-library';
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { ChartUtils } from "../../utilities/chart-utils";
const RD3Component = rd3.Component;

const COMPONENT_CLASS = "c-line-chart";

export interface LineChartProps {
    axisFormat?: string;
    data: LineChartPoint[];
    title?: string;
}

const LineChart: React.FC<LineChartProps> = (props) => {
    const [data, setData] = useState<HTMLDivElement>();

    useEffect(() => {
        setData(ChartUtils.drawLineChart(props.data, props.axisFormat));
    }, [props.axisFormat, props.data]);

    return (
        <div className={`${COMPONENT_CLASS}__wrapper`}>
            <div className={`${COMPONENT_CLASS}`}>
                <div
                    className={`${COMPONENT_CLASS}__content`}>
                    <RD3Component data={data} />
                </div>
            </div>
        </div>
    );
};



export default LineChart;