export default class DuplicateEntityFoundException extends Error {
    public readonly message: string =
        "More than one entity was found for this query";
    public readonly name: string = "DuplicateEntityFoundException";

    constructor(message?: string) {
        super();

        if (message) {
            this.message = message;
        }
    }
}
