import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { ReportAssessmentData } from "../../models/interfaces/reports/report-assessment-data";
import DonutChart from "../charts/donut-chart";
import ReportHeader from "./report-header";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as ActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { GroupIds } from "../../models/enumerations/group-ids";
import GroupUtil from "../../utilities/group-util";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportAssessmentProps {
    data: ReportAssessmentData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Enumeration
// -----------------------------------------------------------------------------------------

enum FocusAreaGroupName {
    ACTIVITY_LEVEL = "Physical Activity",
    BEHAVIORAL_HEALTH = "Behavioral Health",
    BODY_COMPOSITION = "Body Composition",
    BREATHING = "Breathing Quality",
    INJURY_HISTORY = "Injury History",
    MOVEMENT_HEALTH = "Movement Health",
    NUTRITION_AWARENESS = "Nutrition Awareness",
    SLEEP_WELLNESS = "Sleep Wellness"
}

// #endregion Enumeration

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportAssessment: React.FC<ReportAssessmentProps> = (props: ReportAssessmentProps) => {
    const { data } = props;

    let history = data.history;
    if (!history || history.length < 9) {
        history = history.concat(Array.from(Array(9 - (history.length || 0)).map((a) => ({} as any))));
    }

    let iconModifier = '';

    let scoreRange = "";
    let scoreRangeDescription = "";
    if (data.mskScore != null && data.mskScore >= 0) {
        switch (true) {
            case (data.mskScore >= 90):
                scoreRange = "90 - 100 Great";
                scoreRangeDescription = "Your musculoskeletal health is excellent and you have minimal to no risk factors that could compromise your health and well-being. Keep up the hard work!";
                break;
            case (data.mskScore >= 80):
                scoreRange = "80 - 89 Good";
                scoreRangeDescription = "Your musculoskeletal health is within a reasonable range and you have minimal to no risk factors that could compromise your health and well-being. Focus on improving!";
                break;
            case (data.mskScore >= 70):
                scoreRange = "70 - 79 Okay";
                scoreRangeDescription = "Your musculoskeletal health score is below the healthy range, indicating that you have risk factors that could compromise your health and well-being.";
                break;
            default:
                scoreRange = "69 or Below Poor";
                scoreRangeDescription = "Your musculoskeletal health score needs to be addressed, indicating you are at high risk of experiencing a decreased quality of life and risk of developing a musculoskeletal condition.";
                break;
        }
    }

    const formatScore = (percentage?: number): string => {
        if (percentage == null) {
            return "N/A";
        }
        return `${percentage}%`;
    }

    const formatScoreAndPain = (percentage?: number, hasPain?: boolean) => {
        if (hasPain) {
            return <>
                {formatScore(percentage)}
                <span className={`${COMPONENT_CLASS}__current__item__group__pain`}>PAIN</span>
            </>;
        }

        return `${formatScore(percentage)}`;
    }

    const getIndicatorWidth = (percent?: number, isLabel: boolean = false): string => {
        let width: number = percent ?? 0;
        if (isLabel) {
            width += 5;
        }
        return `${width}%`;
    }

    const getFocusIcon = (focusGroupName: string): any => {
        switch (focusGroupName) {
            case FocusAreaGroupName.ACTIVITY_LEVEL:
                return (<ActivityIcon style={{ backgroundColor: "#FFA998" }} />);
            case FocusAreaGroupName.BEHAVIORAL_HEALTH:
                return (<BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />);
            case FocusAreaGroupName.BODY_COMPOSITION:
                return (<BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />);
            case FocusAreaGroupName.BREATHING:
                return (<BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />);
            case FocusAreaGroupName.INJURY_HISTORY:
                return (<InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />);
            case FocusAreaGroupName.MOVEMENT_HEALTH:
                return (<MovementIcon style={{ backgroundColor: "#ADDFFD" }} />);
            case FocusAreaGroupName.NUTRITION_AWARENESS:
                return (<NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />);
            case FocusAreaGroupName.SLEEP_WELLNESS:
                return (<SleepIcon style={{ backgroundColor: "#CEBAF8" }} />);
            default:
                return (<div></div>);
        }
    }

    const getFocusDescription = (focusGroupName: string): string => {
        switch (focusGroupName) {
            case FocusAreaGroupName.ACTIVITY_LEVEL:
                return data.activityDescription ?? "";
            case FocusAreaGroupName.BEHAVIORAL_HEALTH:
                return data.behavioralDescription ?? "";
            case FocusAreaGroupName.BODY_COMPOSITION:
                return data.bodyCompDescription ?? "";
            case FocusAreaGroupName.BREATHING:
                return data.breathingDescription ?? "";
            case FocusAreaGroupName.INJURY_HISTORY:
                return data.injuryHistoryDescription ?? "";
            case FocusAreaGroupName.MOVEMENT_HEALTH:
                return data.movementDescription ?? "";
            case FocusAreaGroupName.NUTRITION_AWARENESS:
                return data.nutritionDescription ?? "";
            case FocusAreaGroupName.SLEEP_WELLNESS:
                return data.sleepDescription ?? "";
            default:
                return "";
        }
    }

    const hasAnyMovementPain = data.toeTouchHasPain
        || data.balanceHasPain
        || data.shoulderMobilityHasPain
        || data.squatHasPain
        || data.rotationHasPain
        || data.didPassShoulderClearing === false
        || data.didPassSpineClearing === false;
    const assessmentResults: any[] = [
        { groupId: GroupIds.MOVEMENT_HEALTH, score: data.movementScore, average: data.movementAverage, hasPain: hasAnyMovementPain },
        { groupId: GroupIds.NUTRITION, score: data.nutritionScore, average: data.nutritionAverage },
        { groupId: GroupIds.BREATHING, score: data.breathingScore, average: data.breathingAverage },
        { groupId: GroupIds.BEHAVIORAL_HEALTH, score: data.behavioralScore, average: data.behavioralAverage },
        { groupId: GroupIds.INJURY_HISTORY, score: data.injuryHistoryScore, average: data.injuryHistoryAverage, hasPain: data.injuryHistoryHadPain },
        { groupId: GroupIds.PHYSICAL_ACTIVITY, score: data.activityScore, average: data.activityAverage },
        { groupId: GroupIds.SLEEP, score: data.sleepScore, average: data.sleepAverage },
        { groupId: GroupIds.BODY_COMPOSITION, score: data.bodyCompScore, average: data.bodyCompAverage },
    ];

    return (
        <div className={`${COMPONENT_CLASS} -assessment`}>
            <ReportHeader
                data={data}
                name={data.name}
                dob={data.dob ? DateTime.fromISO(data.dob).toFormat("M/d/yyyy") : "N/A"}
                reportDateOverride={(data.history && data.history.length > 0) ? DateTime.fromISO(data.history[0].date).toFormat("M/d/yyyy") : "N/A"}
                reportType={ReportType.Assessment} />
            <div className={`${COMPONENT_CLASS}__current ${COMPONENT_CLASS}__section`}>
                <h2>Your Current Scores</h2>
                <div className={`${COMPONENT_CLASS}__current__wrapper`}>
                    <DonutChart
                        percentage={Math.round(data.mskScore || 0)}
                        showTotal={false}
                        title="Your Wellness Score"
                    />
                    <div className={`${COMPONENT_CLASS}__current__inner`}>
                        {assessmentResults.map((assessmentResult, index) => {
                            return (
                                <div className={`${COMPONENT_CLASS}__current__item`} key={`assessment-summary-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__current__item__group`}>{GroupUtil.getName(assessmentResult.groupId)} <span>{formatScore(assessmentResult.score)}</span></div>
                                    <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                                        <div style={{ "width": `${assessmentResult.score || 0}%` }}></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className={`${COMPONENT_CLASS}__review ${COMPONENT_CLASS}__section`}>
                <div className={`${COMPONENT_CLASS}__review__icon`}>
                    <div className={`${COMPONENT_CLASS}__review__icon text`}>{scoreRange}</div>
                </div>
                <div className={`${COMPONENT_CLASS}__review__description`}>
                    {scoreRangeDescription}
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__history ${COMPONENT_CLASS}__section`}>
                <h2>Your Score History (Last 9 Months)</h2>
                <div className={`${COMPONENT_CLASS}__history__wrapper`}>
                    {history.map((item, index) => {
                        if (!item) {
                            return (
                                <div
                                    className={`${COMPONENT_CLASS}__history__item not-available`}
                                    key={`history-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__history__item__score`}>N/A</div>
                                    <div className={`${COMPONENT_CLASS}__history__item__date`}></div>
                                </div>
                            );
                        }

                        const date = DateTime.fromISO(item.date);
                        return (
                            <div
                                className={`${COMPONENT_CLASS}__history__item ${item.percent <= 0 ? "not-available" : ""} ${index === 0 ? "current" : ""}`}
                                key={`history-${index}`}>
                                <div className={`${COMPONENT_CLASS}__history__item__score`}>{formatScore(item.percent)}</div>
                                <div className={`${COMPONENT_CLASS}__history__item__date`}>{index === 0 ? "Currently" : date.toFormat(`M/d${date.year !== DateTime.local().year ? "/yyyy" : ""}`)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {data.focusAreas &&
                <div className={`${COMPONENT_CLASS}__focus ${COMPONENT_CLASS}__section`}>
                    <h2>Your Focuses</h2>
                    {data.focusAreas &&
                        <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                            {data.focusAreas.map((focusGroupName, index) => {
                                iconModifier = focusGroupName.replace(/\s+/g, '-').toLowerCase();
                                return (
                                    <div
                                        className={`${COMPONENT_CLASS}__focus__item`}
                                        key={`focus-${index}`}>
                                        <div className={`${COMPONENT_CLASS}__focus__item__wrapper`}>
                                            <div className={`${COMPONENT_CLASS}__focus__item__icon-container -${iconModifier}`}>
                                                {getFocusIcon(focusGroupName)}
                                            </div>
                                            <div>
                                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{focusGroupName}</div>
                                                <div className={`${COMPONENT_CLASS}__focus__item__description`} dangerouslySetInnerHTML={{ __html: getFocusDescription(focusGroupName) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            }
            <div className={`${COMPONENT_CLASS}__section ${COMPONENT_CLASS}__current`}>
                <h2>Movement Assessment Details</h2>
                <p>Good movement health is essential to perform basic everyday movements like carrying groceries, reaching for something from the top shelf, lifting up your child, picking something up from the floor, or more intense activities such as running. Your movement quality also directly relates to your overall musculoskeletal health.</p>
                <div className={`${COMPONENT_CLASS}__current__wrapper`}>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>Toe Touch <span>{formatScoreAndPain(data.toeTouchScore, data.toeTouchHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.toeTouchScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>Balance &amp; Reach <span>{formatScoreAndPain(data.balanceScore, data.balanceHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.balanceScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group ${data.didPassShoulderClearing === false ? "--pain" : ""}`}>Shoulder Clearing: <span>{data.didPassShoulderClearing == null ? "N/A" : data.didPassShoulderClearing ? "PASS" : "PAIN"}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__group ${data.didPassSpineClearing === false ? "--pain" : ""}`}>Spine Clearing: <span>{data.didPassSpineClearing == null ? "N/A" : data.didPassSpineClearing ? "PASS" : "PAIN"}</span></div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>Shoulder Mobility <span>{formatScoreAndPain(data.shoulderMobilityScore, data.shoulderMobilityHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.shoulderMobilityScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>Squat <span>{formatScoreAndPain(data.squatScore, data.squatHasPain || data.didPassSpineClearing === false)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.squatScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div></div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>Rotation <span>{formatScoreAndPain(data.rotationScore, data.rotationHasPain || data.didPassShoulderClearing === false)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.rotationScore || 0}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${COMPONENT_CLASS}__section ${COMPONENT_CLASS}__current`}>
                <h2>Functional Wellness Breakdown</h2>
                <p>In addition to movement health, other lifestyle behaviors can adversely affect your musculoskeletal health. Studies have shown if one or a combination of these behaviors are not within the normal ranges can increase your risk of developing a musculoskeletal condition. </p>
                <div className={`${COMPONENT_CLASS}__current__wrapper start`}>
                    {assessmentResults.map((assessmentResult, index) => {
                        return (
                            <div className={`${COMPONENT_CLASS}__current__item`} key={`assessment-detail-${index}`}>
                                <div className={`${COMPONENT_CLASS}__current__item__group`}>{GroupUtil.getName(assessmentResult.groupId)} <span>{formatScoreAndPain(assessmentResult.score, assessmentResult.hasPain)}</span></div>
                                <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                                    <div style={{ "width": `${assessmentResult.score ?? 0}%` }}></div>
                                </div>
                                <div>
                                    <div className={`${COMPONENT_CLASS}__current__item indicator`} style={{ "width": getIndicatorWidth(assessmentResult.average) }}></div>
                                    <div className={`${COMPONENT_CLASS}__current__item indicator-text`} style={{ "width": getIndicatorWidth(assessmentResult.average, true) }}>AVG {assessmentResult.average}</div>
                                </div>
                                <p>
                                    {GroupUtil.getDescription(assessmentResult.groupId)}
                                </p>
                            </div>
                        );
                    })}
                    { // if
                        data.averageAgeRange != null &&
                        <div className={`${COMPONENT_CLASS}__current__item average`}>
                            <p>
                                Your averages are of {data.averageAgeRange} year old {data.isFemale ? "females" : "males"}.
                            </p>
                            <p>
                                Your results are shown in comparison to other Symmio users who share your age and gender.
                                See how you measure up.
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ReportAssessment;
