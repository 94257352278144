import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikErrors, useFormikContext } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import styled from '@mui/material/styles/styled';
import { Moment } from 'moment';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiInputBase-input': {
        paddingLeft: '20px',
    },
    '& .MuiInputBase-root': {
        borderRadius: '30px',
        fontFamily: 'pragmatica-extended',
        fontWeight: 300,
        height: '46px',
        fontSize: '14px',
        lineHeight: '22px',
    },
}));

interface FormikDateProps {
    disabled?: boolean,
    disableFuture?: boolean,
    disablePast?: boolean,
    label?: string,
    name: string,
    readOnly?: boolean,
    required?: boolean,
    minDate?: Moment,
}

/**
 * A date field hooked up to formik context.
 */
export const FormikDateField: React.FC<FormikDateProps> = (props) => {
    const {
        disabled,
        disableFuture,
        disablePast,
        label,
        name,
        minDate,
        readOnly,
        required,
    } = props;

    const formik = useFormikContext<any>();

    const {
        errors,
        setFieldValue,
        values,
        validateField,
    } = formik;

    return (
        <>
            {
                label && (
                    <label
                        htmlFor={name}
                        style={{
                            fontSize: '12px',
                            lineHeight: '22px',
                            marginBottom: '4px',
                            fontWeight: '500'
                        }}
                    >
                        {`${label}${required ? ' *' : ''}`}
                    </label>
                )
            }
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledDatePicker
                    minDate={minDate}
                    readOnly={readOnly}
                    disabled={disabled}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    format="MM/DD/YYYY"
                    onChange={async (date) => {
                        // if the date is null as a result of the field being cleared (backspaced),
                        // it must be set to undefined or else yup validation will see it as an
                        // invalid date
                        if (!date) {
                            await setFieldValue(name, undefined, false);
                        } else {
                            // Eliminates the time portion of the date. Without this, dates
                            // entered or selected will be stored in local time and when the data
                            // is saved, the value will contain the local time. There should not
                            // be any time portion when using the date field component, which is
                            // why it is removed.
                            await setFieldValue(name, date, false);
                        }
                        validateField(name);
                    }}
                    value={values[name as keyof FormikErrors<unknown>] ?? null}
                    slotProps={{
                        textField: {
                            helperText: errors[name] ? errors[name]?.toString() : '',
                            error: !!errors[name],
                        },
                    }}
                />
            </LocalizationProvider>
        </>
    );
}