import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { BaseReportData } from "../../models/interfaces/reports/base-report-data";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportHeaderProps {
    data: BaseReportData;
    dateFormat?: string;
    focusAreas?: string[];
    healthCategories?: string[];
    isInPain?: boolean;
    name?: string;
    dob?: string;
    reportDateOverride?: string;
    reportType: ReportType;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportHeader: React.FC<ReportHeaderProps> = (props: ReportHeaderProps) => {
    const { data } = props;

    const dateFormat = props.dateFormat || "MMMM yyyy"
    const reportDate = DateTime.fromISO(data.reportDate);
    let reportName = ""
    switch (props.reportType) {
        case ReportType.Compliance:
            reportName = "Compliance Report";
            break;
        case ReportType.Detail:
            reportName = "Detail Report";
            break;
        case ReportType.Score:
            reportName = "Score Report";
            break;
        case ReportType.Summary:
            reportName = "Summary Report";
            break;
        case ReportType.Assessment:
            reportName = "Assessment Report";
            break;
    }

    return (
        <div>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>symmio</h1>
                <div className={`${COMPONENT_CLASS}__header__type`}>
                    {reportName}
                </div>
                <div className={`${COMPONENT_CLASS}__header__wrapper`}>
                    {props.reportType === ReportType.Assessment &&
                        <div>
                            <div className={`${COMPONENT_CLASS}__header__col`}>
                                <div className={`${COMPONENT_CLASS}__header__item`}>Name: {props.name}</div>
                                <div className={`${COMPONENT_CLASS}__header__item`}>Date of Birth: {props.dob}</div>
                                <div className={`${COMPONENT_CLASS}__header__item`}>Date of Last Assessment: {props.reportDateOverride}</div>
                            </div>
                        </div>
                    }
                    {props.reportType !== ReportType.Assessment &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            <div className={`${COMPONENT_CLASS}__header__item`}>Company Name: {data.organizationName}</div>
                            {props.reportDateOverride &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{props.reportDateOverride}</div>
                            }
                            {!props.reportDateOverride &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>Monthly Summary: {reportDate.toFormat(dateFormat)}</div>
                            }
                            <div className={`${COMPONENT_CLASS}__header__item`}>Total Report Users: {data.totalUsers}</div>
                        </div>
                    }
                    {props.reportType !== ReportType.Assessment &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            <div className={`${COMPONENT_CLASS}__header__item`}>Report Date: {DateTime.now().toFormat("MMMM d, yyyy")}</div>
                            {data.groupNames && data.groupNames.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>Groups: {data.groupNames.join(", ")}</div>
                            }
                            {data.tagNames && data.tagNames.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>Tags: {data.tagNames.join(", ")}</div>
                            }
                        </div>
                    }
                    {(props.reportType === ReportType.Detail || props.reportType === ReportType.Score) &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            {props.healthCategories && props.healthCategories.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>MSK Health Risk: {Utils.getHealthRiskNames(props.healthCategories).join(", ")}</div>
                            }
                            {props.isInPain &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>Movement Pain?: {props.isInPain ? "Yes" : "No"}</div>
                            }
                            {props.focusAreas && props.focusAreas.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>Focus Areas: {props.focusAreas.join(", ")}</div>
                            }
                        </div>
                    }
                </div>
            </div>
            <hr />
        </div>
    );
}

export default ReportHeader;