import { NumericListOptions } from "../../models/interfaces/numeric-list-options";

export const TrialLengthItems: NumericListOptions[] = [
    {
        value: 7,
        label: '7 days',
    },
    {
        value: 14,
        label: '14 days',
    },
    {
        value: 30,
        label: '30 days',
    },
    {
        value: 60,
        label: '60 days',
    },
    {
        value: 0,
        label: 'Custom',
    }
];