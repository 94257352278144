import { FolderIcon } from "@heroicons/react/outline";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";
import React from "react";
import { GroupCustomData } from "../../models/interfaces/group-custom-data";

const COMPONENT_CLASS = "c-tree__drag";

type Props = {
    monitorProps: DragLayerMonitorProps<GroupCustomData>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
    const item = props.monitorProps.item;

    return (
        <div className={`${COMPONENT_CLASS}__container`}>
            <div className={`${COMPONENT_CLASS}__icon`}>
                <FolderIcon className={COMPONENT_CLASS} />
            </div>
            <div className={`${COMPONENT_CLASS}__label`}>{item.text}</div>
        </div>
    );
};
