import { ListOptions } from "./interfaces/list-options";

export const MovementFocusOptions: ListOptions[] = [
    {
        value: "TOE_TOUCH",
        label: "Toe Touch",
        shortLabel: "T. Touch",
    }, {
        value: "SQUAT",
        label: "Squat",
        shortLabel: "Squat",
    }, {
        value: "SPINE_CLEARING",
        label: "Spine Clearing",
        shortLabel: "Spine C."
    }, {
        value: "SHOULDER_MOBILITY",
        label: "Shoulder Mobility",
        shortLabel: "Shoulder M."
    }, {
        value: "SHOULDER_CLEARING",
        label: "Shoulder Clearing",
        shortLabel: "Shoulder C."
    }, {
        value: "ROTATION",
        label: "Rotation",
        shortLabel: "Rotation"
    }, {
        value: "BALANCE",
        label: "Balance",
        shortLabel: "Balance"
    }
];