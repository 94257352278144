import React from "react";
import { RadioTypeInputProps } from "./radio-type-input.props";
import "./radio-type-input.styles.scss";

const RadioTypeInput: React.FC<RadioTypeInputProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let inputAttributes = {
        id: sanitizedId,
        type: "radio"
    }


    // wire up our input to react-hook-form
    // https://react-hook-form.com/get-started
    if (props.registerHook) {
        if (props.registerOptions) {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId, props.registerOptions),
                ...(props.registerOptions.value && { value: props.registerOptions.value })
            };
        } else {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId)
            };
        }
    }

    return (
        <div className={`radio-type-container ${props.className}`}>

            {/* {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            } */}
            <input
                {...inputAttributes}
                className={`${props.errorState && "field-error"} ${props.inputClassName}`}
                aria-invalid={props.errorState ? "true" : "false"}
                data-testid="input-test"
                style={props.style}
                {...(props.checked !== undefined ? { checked: props.checked } : {})}
            />
            {(props.label) &&
                <label htmlFor={sanitizedId}>
                    {props.label}
                </label>
            }
        </div>
    )
};
export default RadioTypeInput;
