import React from "react";

const COMPONENT_CLASS = "c-day-input-option";

export interface DayInputOptionProps {
    onChange: (newAnswer: string) => void;
    number: string;
    selected?: string;
}

const DayInputOption: React.FC<DayInputOptionProps> = (props) => {
    return (
        <div className={`${COMPONENT_CLASS}`}>
            <input
                name={props.number}
                type="radio"
                id={`radio-${props.number}`}
                value={props.number}
                onChange={(e: any) => {
                    props.onChange(e.target.value);
                }}
                checked={(props.number === props.selected)}
            />
            {/* <label className={`${props.number === "0" ? "__radio-none" : "__radio"}`} htmlFor={`radio-${props.number}`}>{props.number === "0" ? "None" : props.number}</label>
             */}

            <label className="__radio" htmlFor={`radio-${props.number}`}>{props.number}</label>

            {/* <IonRadio
                value={props.number}
                onClick={(e: any) => props.onChange(e.target.value)}
            />
            <IonLabel>{props.number}</IonLabel> */}
        </div>
    );
};

export default DayInputOption;
