import React from "react";

interface PriceCardProps {
    isChecked: boolean;
    topText: string;
    price: string;
    paymentCycle: string;
    subText: string;
    onClick: () => void;
    isHighlighted?: boolean
    badgeText?: string;
}

const COMPONENT_CLASS = "c-price-card";

const PriceCard: React.FC<PriceCardProps> = (props) => {
    const {
        isChecked,
        topText,
        price,
        paymentCycle,
        subText,
        onClick,
        isHighlighted,
        badgeText,
    } = props;

    return (
        <div className={`${COMPONENT_CLASS} ${isHighlighted ? "highlight" : ""}`} onClick={onClick}>
            <div>
                <div className={`${COMPONENT_CLASS}__top-text`}>
                    {topText}
                    {badgeText &&
                        <div className={`${COMPONENT_CLASS}__badge`}>{badgeText}</div>
                    }
                </div>
                <div>
                    <span className={`${COMPONENT_CLASS}__price`}>${price}</span>
                    {' '}/{' '}
                    <span className={`${COMPONENT_CLASS}__payment-cycle`}>{paymentCycle}</span>
                </div>
                <div className={`${COMPONENT_CLASS}__sub-text`}>{subText}</div>
            </div>

            <div>
                <div className={`${COMPONENT_CLASS}__round-checkbox`}>
                    <input type="checkbox" checked={isChecked} onChange={() => { }} />
                    <label></label>
                </div>
            </div>
        </div>
    );
}

export default PriceCard;