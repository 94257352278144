import getFirebase from "./firebase";

const { firebaseApp } = getFirebase();

export default class FirebaseStorage {
    /**
     * Folder within storage; this should contain a trailing slash ("/")
     */
    public storageFolder?: string;

    private storageDomain: string = "https://firebasestorage.googleapis.com";
    private storageBucket: string = firebaseApp.options.storageBucket!;
    private storagePath: string = `${this.storageDomain}/v0/b/${this.storageBucket}/o/`;

    constructor(storageFolder?: string) {
        this.storageFolder = storageFolder;
    }

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getMediaUrl(fileName: string): string {
        // if this is a URL, just leave it be
        // this tests strings beginning with http or https
        if (/^https?/.test(fileName)) {
            return fileName;
        }

        return `${this.getUrl(fileName)}?alt=media`;
    }

    public getUrl(fileName: string): string {
        if (fileName == null) {
            return fileName;
        }

        const hasStorageFolder =
            this.storageFolder != null && this.storageFolder.length >= 1;
        if (!hasStorageFolder && fileName.indexOf("/") === -1) {
            throw new Error("Storage Folder is required");
        }

        if (hasStorageFolder) {
            fileName = this.storageFolder + fileName;
        }
        return `${this.storagePath}${encodeURIComponent(fileName)}`;
    }

    // #endregion Public Methods
}
