import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { MskScore } from "../../models/interfaces/msk-score";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "mskScores";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------


/**
 * Find the specific mskScore by the id
 * @param {string} id - The Id of the mskScore that we are retrieving
 * @returns {Promise<MskScore>} A promise for the MskScore we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<MskScore>(COLLECTION_NAME, id);
};

/**
 * Find the specific mskScore by the id
 * @param {string} id - The Id of the mskScore that we are retrieving
 * @returns {Promise<MskScore>} A promise for the MskScore we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<MskScore>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Find the specific mskScore by the id
 * @param {string} id - The Id of the mskScore that we are retrieving
 * @returns {Promise<MskScore>} A promise for the MskScore we are retrieving
 */
const getCountBy = async (conditions: FirestoreCondition[]) => {
    return FirestoreService.getCountBy(COLLECTION_NAME, conditions);
};

/**
 * Get all of the mskScores stored in the database
 * @returns {Promise<MskScore[]>} A promise for the collection of MskScores
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<MskScore>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific mskScore to see when it changes
 * @param {string} id - Id of the mskScore document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<MskScore>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the mskScores to see when it changes
 * @param {string} id - Id of the mskScore document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit: number, listener: Function) => {
    return FirestoreService.getSnapshotBy<MskScore>(COLLECTION_NAME, conditions, order, limit, listener);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const MskScoreService = {
    get,
    getBy,
    getCountBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
};

export default MskScoreService;

// #endregion Exports