import { DragLayerMonitorProps, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Group } from '../../models/interfaces/group';
import { GroupCustomData } from '../../models/interfaces/group-custom-data';
import { Organization } from '../../models/interfaces/organization';
import UserRoles from '../../models/user-roles';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import GroupService from '../../utilities/services/group-service';
import OrganizationService from '../../utilities/services/organization-service';
import UserService from '../../utilities/services/user-service';
import UserUtil from '../../utilities/user-util';
import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { Modal } from '../modal/modal';
import { CustomDragPreview } from './custom-drag-preview';
import { CustomNode } from './custom-node';
import { enqueueSnackbar } from 'notistack';

const COMPONENT_CLASS = "c-group-list";

interface GroupListProps { }

const GroupList: React.FC<GroupListProps> = (props: GroupListProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [treeData, setTreeData] = useState<NodeModel[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [managedGroups, setManagedGroups] = useState<Group[]>([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalUsersInGroups, setTotalUsersInGroups] = useState(0);
    const [groupToDelete, setGroupToDelete] = useState<Group>();
    const { state } = useAuthState();
    const history = useHistory();

    const isAccountHolder = UserUtil.isAccountHolder(state.user);

    const handleDeleteGroupClick = async () => {
        if (groupToDelete?.id == null) {
            return;
        }

        setIsSaving(true);

        const usersToMove = await UserService.getBy([{
            field: "groupId",
            operator: "==",
            value: groupToDelete.id,
        }]);

        // get parent group
        let parentGroup = null;
        if (groupToDelete.parentId) {
            parentGroup = groups.find(g => g.id === groupToDelete.parentId);
        }

        // move users to parent group (or no group)
        for (const user of usersToMove) {
            user.groupId = (parentGroup?.id || null) as any;
            user.groupName = (parentGroup?.name || null) as any;

            await UserService.update(user, state.user);
        }

        // update parent group user count
        if (parentGroup) {
            parentGroup.userCount = await UserService.getCountBy([{
                field: "groupId",
                operator: "==",
                value: parentGroup.id,
            }]);
            await GroupService.save(parentGroup, state.user);
        }

        // change child group parent IDs
        const childGroups = groups.filter(g => g.parentIds != null && g.parentIds.indexOf(groupToDelete.id) !== -1);
        for (const childGroup of childGroups) {
            if (childGroup.parentIds == null) {
                continue;
            }
            const parentIds = [...childGroup.parentIds];
            parentIds.splice(parentIds.indexOf(groupToDelete.id), 1);
            childGroup.parentIds = parentIds;
            if (childGroup.parentId === groupToDelete.id) {
                childGroup.parentId = parentGroup?.id ?? null;
            }
            await GroupService.save(childGroup, state.user);
        }

        await GroupService.deleteById(groupToDelete.id);

        enqueueSnackbar("Group deleted successfully!", { variant: "toast", width: "450px" });
        setOpenDelete(false);
        setGroupToDelete(undefined);
        setIsSaving(false);
    }

    const handleDrop = (newTree: NodeModel[], { dragSourceId, dropTargetId, dragSource, dropTarget }: any) => {
        GroupService.update({
            id: dragSourceId,
            parentId: dropTargetId === 0 ? null : dropTargetId,
            parentIds: getParentIds(dropTargetId),
        } as Group);
    };

    useEffect(() => {
        if (!state.organization?.id) {
            return;
        }

        GroupService.getSnapshotBy([{
            field: "organizationId",
            operator: "==",
            value: state.organization?.id,
        }], [{
            field: "name",
            direction: "asc"
        }], (groups: Group[]) => {
            const managedGroups = UserUtil.getManagedGroups(groups, state?.user);
            const managedGroupIds = managedGroups.map((g) => g.id);

            const tree = groups.map((g) => {
                const isManaged = managedGroupIds.indexOf(g.id) >= 0;
                const parentFound = g.parentId != null && groups.find((x) => x.id === g.parentId) != null;

                return {
                    id: g.id,
                    parent: parentFound ? g.parentId! : 0,
                    parentIds: g.parentIds,
                    droppable: true,
                    text: g.name,
                    isManaged: isManaged,
                    isTopManagedLevel: isManaged && g.parentId == null,
                    data: {
                        isGroup: true,
                        userCount: g.userCount || 0,
                    }
                }
            });

            tree.forEach((item) => {
                if (item.isManaged && item.parent !== 0) {
                    const parent = tree.find((t) => t.id === item.parent);
                    if (parent == null || !parent.isManaged) {
                        item.isTopManagedLevel = true;
                    }
                }
            });

            setGroups(groups);
            setManagedGroups(managedGroups);
            setTreeData(tree);
            setTotalUsersInGroups(groups.reduce((acc, g) => acc + (g.userCount || 0), 0));
            setIsLoading(false);
        });

        OrganizationService.getSnapshot(state.organization?.id, (organization: Organization) => {
            setTotalUsers(organization.userCount || 0);
        })
    }, [state, setTreeData]);

    useEffect(() => {
        if (!openDelete) {
            setGroupToDelete(undefined);
        }
    }, [openDelete]);

    const getParentIds = (parentId: any): string[] | null => {
        if (parentId == null || parentId === 0) {
            return null;
        }

        const parentGroup = groups.find(g => g.id === parentId);
        if (parentGroup?.id != null) {
            return (parentGroup?.parentIds || []).concat(parentGroup.id);
        }

        return null;
    }

    const getManagedParentCount = (parentIds: string[] | undefined): number => {
        if (parentIds == null) {
            return 0;
        }

        let result = 0;
        parentIds.forEach((parentId) => {
            if (managedGroups.find((group) => group.id === parentId) != null) {
                result++;
            }
        });
        return result;
    }

    const isAdmin = UserUtil.belongsToRole(state?.user, UserRoles.ADMIN_ID) || isAccountHolder;
    const canAddGroup = isAdmin || managedGroups?.length > 0 || isAccountHolder;

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoading} />
            <div className={`${COMPONENT_CLASS}__header`}>
                {canAddGroup &&
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            type="default"
                            buttonText="+ Add Group"
                            onClick={() => history.push(`group/${state.organization.id}/`)} />
                    </div>
                }
                <h1>Organization Structure</h1>
            </div>
            <div className={`${COMPONENT_CLASS}__list`}>
                <h3>Groups</h3>
                <p className="opacity-60 mb-8 font-light text-sm">Hierarchical structure within the organization. Used to organize users under managers and to assist in report breakdowns. Users can only exist in one group.</p>
                {!isLoading &&
                    <div className={`${COMPONENT_CLASS}__organization`}>
                        <div className={`${COMPONENT_CLASS}__organization__title`}>
                            {state.organization?.name}
                        </div>
                        <div className={`${COMPONENT_CLASS}__organization__data`}>
                            {`${totalUsersInGroups}/${totalUsers} User${totalUsers === 1 ? "" : "s"}`}
                        </div>
                    </div>
                }
                {treeData != null && treeData.length > 0 &&
                    <Tree
                        classes={{
                            root: "c-tree__container",
                            draggingSource: "c-tree__dragging",
                            dropTarget: "c-tree__drop"
                        }}
                        initialOpen={!isAdmin}
                        tree={treeData as any}
                        rootId={0}
                        render={(groupNode: NodeModel<GroupCustomData>, { depth, isOpen, onToggle }) => {
                            const canManage = UserUtil.canManageGroup(state.user, groupNode) || isAccountHolder;
                            const isVisible = isAdmin || canManage || isAccountHolder;
                            const group: Group | undefined = groups.find(x => x.id === groupNode.id);
                            const displayDepth: number = isAdmin || isAccountHolder ? depth : getManagedParentCount(group?.parentIds);

                            return (
                                <CustomNode
                                    node={groupNode}
                                    canDelete={isAdmin || group?.createdBy === state.user?.id}
                                    canManage={canManage}
                                    depth={displayDepth}
                                    hasChildren={treeData.some(n => n.parent === groupNode.id)}
                                    isAdmin={isAdmin}
                                    isOpen={isOpen}
                                    isVisible={isVisible}
                                    managedGroupIds={state.user?.managedGroupIds ?? []}
                                    onToggle={onToggle}
                                    onEdit={(id) => history.push(`group/${state.organization.id}/${id}`)}
                                    onDelete={(id) => {
                                        const group = groups.find(d => d.id === id);
                                        if (group) {
                                            setGroupToDelete(group);
                                            setOpenDelete(true);
                                        }
                                    }} />
                            )
                        }}
                        dragPreviewRender={(
                            monitorProps: DragLayerMonitorProps<GroupCustomData>
                        ) => <CustomDragPreview monitorProps={monitorProps} />}
                        onDrop={handleDrop}
                    />
                }
            </div>

            <Modal
                isOpen={openDelete}
                isLoading={isSaving}
                onClose={setOpenDelete}
                title="Delete Group"
                defaultModalActions={true}
                onSubmit={() => handleDeleteGroupClick()}
                onCancel={() => setOpenDelete(false)}
                submitButtonText="Yes, delete group">
                <div>
                    <p>
                        Please confirm that you want to delete this group. All users in this group will be put into the parent group of the one you are deleting.
                    </p>
                </div>
            </Modal>
        </div>
    );
}

export default GroupList;
