import React, { useState } from "react";
import checkmark from "../../assets/images/checkmark.png";
import { SnackbarContent, useSnackbar } from "notistack";

export interface ToastProps {
    onClose: Function,
    message: string,
    width: string
}

const COMPONENT_CLASS = 'c-user-list';

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const Toast: React.FC<ToastProps> = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    const {
        message,
        width,
    } = props;

    const { closeSnackbar } = useSnackbar();

    const [, setFade] = useState<boolean>(true);


    const handleXClick = () => {
        setFade(false);
        closeSnackbar();
    }

    // <div className={`px-4 py-3 ${COMPONENT_CLASS} rounded-lg relative alert-toast transition-all duration-200	 ${fade ? "opacity-100" : "opacity-0"
    //}`} style={{ zIndex: 99, position: "fixed", bottom: "3.5%", left: "40%", right: "40%", display: "flex", backgroundColor: "#DFF5F1", paddingTop: "1.5rem", paddingBottom: "1.5rem" }} role="alert" onClick={handleClose}>

    return (

        <SnackbarContent ref={ref} className={`px-4 py-3 ${COMPONENT_CLASS} rounded-lg relative `} style={{ width: `${width}`, backgroundColor: "#DFF5F1", paddingTop: "1.5rem", paddingBottom: "1.5rem", whiteSpace: 'pre-line' }} onClick={handleXClick}>

            <img src={checkmark} alt="checkmark" style={{ width: "20px", height: "20px", marginRight: "3%" }} />
            <strong className="font-bold">{message}</strong>
            <span className="absolute right-0 px-4" style={{ cursor: "pointer" }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </span>
        </SnackbarContent >


    );
});

export default Toast;