/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { UnboundSelectField } from "../unbound-fields/unbound-select-field";
import { useFormikContext } from "formik";
import { NumericListOptions } from "../../models/interfaces/numeric-list-options";
import { ListOptions } from "../../models/interfaces/list-options";

interface FormikSelectProps {
    disabled?: boolean,
    error?: boolean,
    helperText?: string,
    hidden?: boolean,
    items: NumericListOptions[] | ListOptions[],
    label?: string,
    name: string,
    includeNone?: boolean,
    readOnly?: boolean,
    required?: boolean,
    value?: NumericListOptions | ListOptions | undefined,
    onChange?: Function,
}

/**
 * A select field hooked up to formik context.
 */
export const FormikSelectField: React.FC<FormikSelectProps> = (props) => {
    const {
        disabled,
        hidden,
        items,
        label,
        name,
        includeNone,
        readOnly,
        required,
        onChange,
    } = props;

    const formik = useFormikContext<any>();

    const {
        errors,
        setFieldValue,
        handleBlur,
        values,
        validateField,
    } = formik;

    useEffect(() => {
        if (items.length === 1 && items[0] !== undefined) {
            setFieldValue(name, items[0], false);
        }
    }, [items]);

    const determineValue = () => {
        if (includeNone && !values[name]) {
            return null;
        }

        if (items.length === 1 && items[0] !== undefined) {
            return items[0];
        }

        return values[name ?? '']
    };

    return (
        <UnboundSelectField
            hidden={hidden}
            disabled={disabled}
            required={required}
            includeNone={includeNone}
            error={errors ? Boolean(errors[name]) : undefined}
            name={name}
            label={label}
            readOnly={readOnly}
            onChange={async (e: NumericListOptions) => {
                await setFieldValue(name, e);
                validateField(name);
                if (onChange) onChange(e);
            }}
            onBlur={handleBlur}
            value={determineValue()}
            helperText={errors[name]?.toString()}
            items={items}
        />
    );
};
