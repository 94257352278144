import { MskScore } from "../models/interfaces/scores/msk-score";
import MskScoreServiceAssessments from "./services/msk-score-service-assessments/msk-score-service-assessments";

export default class MskScoreUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static async getLatest(
        userId: string
    ): Promise<MskScore | undefined> {
        const mskScores = await MskScoreServiceAssessments.getBy(
            // filter
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
            ],
            // order
            [
                {
                    field: "created",
                    direction: "desc",
                },
            ],
            // limit
            1
        );

        if (mskScores == null || mskScores.length === 0) {
            return undefined;
        }

        return mskScores[0];
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    // #endregion Private Methods
}
