export enum VideoSize {
    LANDSCAPE_180P = "180p",
    LANDSCAPE_270P = "270p",
    LANDSCAPE_406P = "406p",
    LANDSCAPE_720P = "720p",
    LANDSCAPE_1080P = "1080p",

    PORTRAIT_568P = "568p",
    PORTRAIT_854P = "854p",
    PORTRAIT_1280P = "1280p",
}
