import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from "../../assets/icons/general-icons/icon_add.svg";
import { ReactComponent as LeadCaptureIcon } from "../../assets/icons/nav/icon_lead-capture.svg";
import { ReactComponent as SubscriptionLinkIcon } from "../../assets/icons/nav/icon_recurring-subscription.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/nav/icon_export.svg";
import { useAuthState } from '../../utilities/contexts/auth-state-context';

const COMPONENT_CLASS = "organization-sub-menu";

type OrganizationSubMenuProps = {
};

export const OrganizationSubMenu: React.FC<OrganizationSubMenuProps> = (props: OrganizationSubMenuProps) => {
    const { state } = useAuthState();

    return (
        <div className={COMPONENT_CLASS}>
            <div className='hover-buffer'></div>
            <div className="item-container">
                <Link
                    className='item'
                    to="/organization">
                    <AddIcon /> Add Organization
                </Link>

                <Link
                    className='item'
                    to="/lead-capture">
                    <LeadCaptureIcon style={{ color: "#121622" }} /> Lead Capture
                </Link>

                {state.organization.plan === "Third-Party Subscription" &&
                    <Link
                        className='item'
                        to="/subscription-links">
                        <SubscriptionLinkIcon style={{ maxWidth: "17px", minWidth: "17px", height: "auto", color: "#121622" }} /> Subscription Links
                    </Link>
                }

                <Link
                    className='item'
                    to="/data-export">
                    <ExportIcon /> Export Users
                </Link>
            </div>
        </div>

    );
}