import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { TrialUpgradeRequest } from "../../models/interfaces/trial-upgrade-request";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "trialUpgradeRequests";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new TrialUpgradeRequest to the TrialUpgradeRequests collection
 * @param {TrialUpgradeRequest} trialUpgradeRequest - The TrialUpgradeRequest that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<TrialUpgradeRequest>} A promise to the newly added TrialUpgradeRequest
 */
const add = async (trialUpgradeRequest: TrialUpgradeRequest, currentUser: User | null = null) => {
    return FirestoreService.add<TrialUpgradeRequest>(COLLECTION_NAME, trialUpgradeRequest, currentUser);
};

/**
 * Delete a TrialUpgradeRequest collection by the Id
 * @param {string} id - The Id of the TrialUpgradeRequest being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific TrialUpgradeRequest by the id
 * @param {string} id - The Id of the TrialUpgradeRequest that we are retrieving
 * @returns {Promise<TrialUpgradeRequest>} A promise for the TrialUpgradeRequest we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<TrialUpgradeRequest>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<TrialUpgradeRequest>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the TrialUpgradeRequests stored in the database
 * @returns {Promise<TrialUpgradeRequest[]>} A promise for the collection of TrialUpgradeRequests
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<TrialUpgradeRequest>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific TrialUpgradeRequest to see when it changes
 * @param {string} id - Id of the TrialUpgradeRequest document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<TrialUpgradeRequest>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the TrialUpgradeRequests to see when it changes
 * @param {string} id - Id of the TrialUpgradeRequest document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<TrialUpgradeRequest>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified TrialUpgradeRequest in the database
 * @param {TrialUpgradeRequest} trialUpgradeRequest - The TrialUpgradeRequest that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<TrialUpgradeRequest>} A promise for the TrialUpgradeRequest that is being updated
 */
const save = async (trialUpgradeRequest: TrialUpgradeRequest, currentUser: User | null = null) => {
    return FirestoreService.save<TrialUpgradeRequest>(COLLECTION_NAME, trialUpgradeRequest, currentUser);
};

/**
 * Update the specified TrialUpgradeRequest stored in the database
 * @param {TrialUpgradeRequest} trialUpgradeRequest - The TrialUpgradeRequest that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<TrialUpgradeRequest>} A promise for the TrialUpgradeRequest that is being updated
 */
const update = async (trialUpgradeRequest: TrialUpgradeRequest, currentUser: User | null = null) => {
    return FirestoreService.update<TrialUpgradeRequest>(COLLECTION_NAME, trialUpgradeRequest, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const TrialUpgradeRequestsService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default TrialUpgradeRequestsService;

// #endregion Exports