import { useParams } from "react-router";
import ReportViewer from "../../components/report/report-viewer";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportViewerPageProps { }

interface ReportViewerPageParams {
    id: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportViewerPage: React.FC<ReportViewerPageProps> = (props: ReportViewerPageProps) => {
    const { id } = useParams<ReportViewerPageParams>();

    return (
        <ReportViewer
            reportId={id} />
    );
}

export default ReportViewerPage;