import React from 'react';
import logo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';


const COMPONENT_CLASS = "p-create-account-expiration";

const CreateAccountExpirationPage: React.FC = () => {

    const onClick = () => {
        // TODO -> Where does this take you?
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center">
                <div className={`${COMPONENT_CLASS}`}>
                    <img src={logo} alt="Logo" />
                    <h4 className={`${COMPONENT_CLASS}__header`}>Invitation Expired</h4>
                    <div className={`${COMPONENT_CLASS}__informative`}>
                        <p>To protect your account, invite links expire after 30 days.<br></br> Please contact your Symmio administration to request a new invite.</p>
                    </div>
                </div>
            </div>
            <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={onClick} submitButtonText='Symmio Home'></BaseCardLayoutActions>
        </BaseCardLayout>
    );
}
export default CreateAccountExpirationPage;