import { useState } from "react";
import { UserStatus } from "../../models/enumerations/user-status";
import { User } from "../../models/interfaces/user";
import { SearchIndexesType } from "../../utilities/search-client";
import { Utils } from "../../utilities/utils";
import { Button } from "../button/button";
import { SortIcons } from "../sort-icons/sort-icons";
import { Tag as TagComponent } from "../tag/tag";
import { useHistory } from "react-router-dom";
import UserUtil from "../../utilities/user-util";
import { ReactComponent as UserCircleIcon } from "../../assets/icons/solid/icon_default-profile.svg";
import FunctionService from "../../utilities/services/function-service";
import UniqueUserInviteLinksService from '../../utilities/services/unique-user-invite-link-service';
import { Timestamp } from "firebase/firestore";
import { UniqueUserInviteLink } from "../../models/interfaces/unique-user-invite-link";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { enqueueSnackbar } from "notistack";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as XIcon } from "../../assets/icons/nav/icon_x.svg";



interface SearchState {
    sortBy: string;
}

type SearchHitsProps = {
    setSearchState: (searchState: SearchState) => void;
    searchState: SearchState;
    hits: any;
    SearchIndexes: SearchIndexesType;
    setUserToDelete: (user: undefined | User) => void;
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isManager: boolean;
    isAccountHolder: boolean;
    setOpenDelete: (d: boolean) => void;
    COMPONENT_CLASS: string;
    isTeam: boolean;
};

export const SearchHits = (props: SearchHitsProps) => {
    const [resentInvitations, setResentInvitations] = useState<string[]>([]);
    const history = useHistory();
    const {
        setSearchState,
        searchState,
        hits,
        isSuperAdmin,
        isAdmin,
        isManager,
        isAccountHolder,
        setUserToDelete,
        setOpenDelete,
        SearchIndexes,
        COMPONENT_CLASS,
    } = props;

    const { state } = useAuthState();

    const handleResendInvitationClick = async (e: Event, user: User) => {
        // Call function
        // Check inside function which invite to send
        // Send invite, if role invite, set updated on the invite
        e.stopPropagation();
        if (user && user.email) {
            const uniqueUserInviteLinkList = await UniqueUserInviteLinksService.getBy([
                {
                    field: "userId",
                    operator: "==",
                    value: user.id,
                },
            ])

            // if uniqueUserInviteLinkList is null, it is an old invite.
            if (!uniqueUserInviteLinkList[0]) {
                const uniqueInviteLink = {
                    userId: user.id,
                    senderEmail: state.user?.email,
                    organizationId: state.organization.id,
                } as UniqueUserInviteLink

                const invite = await UniqueUserInviteLinksService.save(uniqueInviteLink, state.user);
                FunctionService.sendInviteEmail(user, invite);
                setResentInvitations([...resentInvitations, user.email]);
            }
            else {
                uniqueUserInviteLinkList[0].updated = Timestamp.now();
                const invite = await UniqueUserInviteLinksService.update(uniqueUserInviteLinkList[0]);
                FunctionService.sendInviteEmail(user, invite);
                setResentInvitations([...resentInvitations, user.email]);
            }


        }

        enqueueSnackbar("Invitation resent!", { variant: "toast", width: "450px" });
    };




    const handleDeleteUserClick = (e: Event, user: User) => {
        setUserToDelete(user);
        setOpenDelete(true);
        e.preventDefault();
        e.stopPropagation();
    };

    const updateSort = (sortBy: string | undefined) => {
        const state = Object.assign({}, searchState);
        state.sortBy = sortBy!;
        setSearchState(state);
    };

    return (
        <div className={`list__table-container`}>
            <table className={`${COMPONENT_CLASS}__list__table`}>
                <thead>
                    <tr className="rounded-lg text-sm font-medium text-gray-700 text-left">
                        <th></th>
                        <th className="py-2">
                            <div className="sort-icons-container">
                                <span>NAME</span>
                                <SortIcons
                                    ascField={SearchIndexes.usersByNameAsc}
                                    descField={SearchIndexes.usersByNameDesc}
                                    currentSort={searchState.sortBy}
                                    onSortClick={(sortBy) => updateSort(sortBy)}
                                />
                            </div>
                        </th>
                        <th className="empty-cell"></th>
                        <th className="py-2">
                            <div className="sort-icons-container">
                                <span>GROUP</span>
                                <SortIcons
                                    ascField={SearchIndexes.usersByGroupAsc}
                                    descField={SearchIndexes.usersByGroupDesc}
                                    currentSort={searchState.sortBy}
                                    onSortClick={(sortBy) => updateSort(sortBy)}
                                />
                            </div>
                        </th>
                        <th className="empty-cell"></th>
                        <th className="py-2">
                            <div className="sort-icons-container">
                                <span>STATUS</span>
                                <SortIcons
                                    ascField={SearchIndexes.usersByStatusAsc}
                                    descField={SearchIndexes.usersByStatusDesc}
                                    currentSort={searchState.sortBy}
                                    onSortClick={(sortBy) => updateSort(sortBy)}
                                />
                            </div>
                        </th>
                        <th className="empty-cell"></th>
                        <th className="py-2">
                            <div className="sort-icons-container">
                                <span>WELLNESS PROGRAM</span>
                                <SortIcons
                                    ascField={SearchIndexes.usersBySymmioAccessAsc}
                                    descField={SearchIndexes.usersBySymmioAccessDesc}
                                    currentSort={searchState.sortBy}
                                    onSortClick={(sortBy) => updateSort(sortBy)}
                                />
                            </div>
                        </th>
                        {
                            // if 
                            props.isTeam && (
                                <>
                                    <th className="empty-cell"></th>
                                    <th className="py-2">
                                        <div className="sort-icons-container">
                                            <span>ROLE</span>
                                            <SortIcons
                                                ascField={SearchIndexes.usersByRolesAsc}
                                                descField={SearchIndexes.usersByRolesDesc}
                                                currentSort={searchState.sortBy}
                                                onSortClick={(sortBy) => updateSort(sortBy)}
                                            />
                                        </div>
                                    </th>
                                </>
                            )
                        }

                        <th></th>
                    </tr>
                </thead>
                <tbody className="text-sm font-normal text-gray-700">
                    {hits.map((user: any, index: number) => {
                        const userInvitationIndex = resentInvitations.indexOf(
                            user.email
                        );
                        const resendButtonText =
                            userInvitationIndex === -1
                                ? "Resend Invite"
                                : "Invite Sent";
                        const status =
                            user.status === UserStatus.Disabled ? "Deactivated"
                                : (user.authenticationId == null ? "Pending"
                                    : (user.status === UserStatus.Active ? "Active" : "Inactive"))
                        const canDelete =
                            isSuperAdmin
                            || (isAccountHolder &&
                                !user.isSuperAdmin &&
                                !UserUtil.isAccountHolder(user))
                            || (isAdmin &&
                                !user.isSuperAdmin &&
                                !UserUtil.isAccountHolder(user) &&
                                !UserUtil.isAdmin(user))
                            || (isManager &&
                                !user.isSuperAdmin &&
                                !UserUtil.isAccountHolder(user) &&
                                !UserUtil.isAdmin(user) &&
                                !UserUtil.isManager(user));

                        return (
                            <tr
                                className={`${COMPONENT_CLASS}__item -${status.toLowerCase()}`}
                                key={`user-list-${index}`}
                                onClick={(e) => {
                                    // console.log("hm", document.getElementsByClassName("c-button    -secondary"))
                                    // console.log("test", e.target);
                                    // if (e.target === document.getElementsByClassName("c-button    -secondary")[0]) {
                                    //     console.log("test2");
                                    //     return;
                                    // }
                                    // else {
                                    //     console.log("???", e.target)
                                    history.push(`/users/${user.objectID}`);
                                    // }
                                }

                                }
                            >
                                <td className={`${COMPONENT_CLASS}__item__photo`}>
                                    {user.profileImage && (
                                        <img
                                            src={user.profileImage}
                                            alt={`${user.firstName} ${user.lastName}`}
                                        />
                                    )}
                                    {!user.profileImage && (
                                        <UserCircleIcon aria-hidden="true" />
                                    )}
                                </td>
                                {user.firstName != null && user.lastName != null && (
                                    <td>
                                        <span>
                                            <span className="font-bold">{`${user.firstName} ${user.lastName}`}</span>
                                            <br />
                                            <span>{user.email}</span>
                                        </span>
                                    </td>
                                )}
                                {(user.firstName == null ||
                                    user.lastName == null) && <td>{user.email}</td>}
                                <td className="empty-cell"></td>
                                <td>
                                    <strong className="show-on-mobile mr-1">GROUP:</strong>{user.groupName || "No Group"}
                                </td>
                                <td className="empty-cell"></td>
                                <td>
                                    <strong className="show-on-mobile mr-1">STATUS:</strong>{status}
                                </td>
                                <td className="empty-cell"></td>
                                <td>
                                    <strong className="show-on-mobile mr-1">WELLNESS PROGRAM:</strong>
                                    <span style={{ display: "flex", flexDirection: "row" }}>
                                        {// if
                                            user.symmioAccess === SymmioAccessType.AppLicense && (
                                                <CheckMarkIcon />
                                            )
                                        }
                                        {// if
                                            user.symmioAccess !== SymmioAccessType.AppLicense && (
                                                <XIcon />
                                            )
                                        }
                                    </span>
                                </td>
                                {
                                    // if
                                    props.isTeam && (
                                        <>
                                            <td className="empty-cell"></td>
                                            <td>{user.roles?.map(
                                                (role: string, tagIndex: number) => (
                                                    <>
                                                        {role &&
                                                            <TagComponent
                                                                id={tagIndex.toString()}
                                                                key={`role-${index}-${tagIndex}`}
                                                            >
                                                                {Utils.getRoleNameFromValue(role)}
                                                            </TagComponent>
                                                        }
                                                    </>
                                                )
                                            )}</td>
                                        </>
                                    )
                                }
                                <td className={`${COMPONENT_CLASS}__item__actions`}>
                                    {
                                        // if
                                        canDelete && (
                                            <Button
                                                type="table-action"
                                                buttonText="Delete"
                                                buttonStyle="white"
                                                onClick={(e) => {
                                                    handleDeleteUserClick(e, user);
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        // if
                                        user.authenticationId == null && (
                                            <Button
                                                type="table-action"
                                                buttonStyle="white"
                                                buttonText={
                                                    `${resendButtonText}` as string
                                                }
                                                onClick={(e) => {
                                                    handleResendInvitationClick(
                                                        e,
                                                        user
                                                    );
                                                }}
                                            />
                                        )
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
