/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { QuestionInformation } from "../../models/interfaces/questions/question-information";
import AssessmentHelpModal from "../assessment-help-modal/assessment-help-modal";

const COMPONENT_CLASS = "c-input-option";

export interface InchInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
    questionInfo?: QuestionInformation;
    open?: boolean;
    closeParent?: Function;
}

const InchInputOption: React.FC<InchInputOptionProps> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inchRef = useRef(null) as any;
    useEffect(() => {
        setTimeout(() => {
            if (inchRef?.current) {
                inchRef.current.setFocus();
            }
        }, 100);
    }, [props.questionInfo]);


    useEffect(() => {
        if (props.open === true) {
            handlePopupOpen();
        }
    }, [props.open]);

    const handlePopupOpen = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const handlePopupClose = () => {
        if (isOpen && props.closeParent) {
            props.closeParent();
            setIsOpen(false);
        }
    };

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <button
                color="primary"
                onClick={handlePopupOpen}
                className={`${COMPONENT_CLASS}__pop-button`}>
                i
            </button>
            <AssessmentHelpModal
                open={isOpen}
                questionInfo={props.questionInfo}
                onClose={handlePopupClose} />


            {/* <PopupScreen isOpen={isOpen} handleClick={handlePopupClick}>
                {
                    //if
                    props.questionInfo?.imageStoragePath != null && (
                        <img
                            src={`${storageUtil.getMediaUrl(
                                props.questionInfo.imageStoragePath
                            )}`}
                            alt={props.questionInfo?.title}
                        />
                    )
                }
                <h4>
                    {props.questionInfo?.subtitle}
                </h4>
                <p>{props.questionInfo?.content}</p>
                <button
                    onClick={handlePopupClick}>
                    Got it, thanks
                </button>
            </PopupScreen> */}
            <div className={`${COMPONENT_CLASS} -inches`}>
                <div className={`${COMPONENT_CLASS}__inches-input-container`}>
                    <div className={`${COMPONENT_CLASS}__inches-input`}>

                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            onChange={(e: any) => {
                                let inchStr = e.target.value ?? "0";
                                if (inchStr.length > 2) {
                                    inchStr = inchStr.substring(0, 2);
                                    props.onChange(inchStr);
                                    return;
                                }
                                const input = parseInt(
                                    e.target.value ?? "0"
                                );
                                if (isNaN(input) || input <= 0) {
                                    props.onChange("");
                                    return;
                                }
                                props.onChange(inchStr);
                            }}
                            type="number"
                            value={props.answer ? props.answer : ""}
                            maxLength={2}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}
                        >

                        </input>
                    </div>
                    <span className={`${COMPONENT_CLASS}__span`}>IN</span>
                </div>
            </div>
        </div>
    );
};

export default InchInputOption;
