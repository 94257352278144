import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const COMPONENT_CLASS = "c-sort-icons";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SortIconsProps {
    onSortClick?: (sortBy: string | undefined) => void;
    ascField?: string;
    descField?: string;
    currentSort?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export const SortIcons: React.FC<SortIconsProps> = (props) => {
    return (
        <div
            className={`${COMPONENT_CLASS}`}
            onClick={() => {
                if (props?.onSortClick == null) {
                    return;
                }
                if (props.currentSort === props.ascField) {
                    props.onSortClick(props.descField);
                    return;
                }
                props.onSortClick(props.ascField);
            }}
        >
            <ChevronUpIcon
                className={`${COMPONENT_CLASS}__icon icon__asc ${
                    props.currentSort === props.ascField ? "-active" : ""
                }`}
            />
            <ChevronDownIcon
                className={`${COMPONENT_CLASS}__icon icon__desc ${
                    props.currentSort === props.descField ? "-active" : ""
                }`}
            />
        </div>
    );
};
