import ReportDetail from "../../components/report/report-detail";
import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Redirect } from 'react-router-dom';

const ReportDetailTestPage: React.FC = () => {
    const { state } = useAuthState();
    if (!state.claims.superAdmin) {
        return <Redirect push to="/" />;
    }

    const data: ReportDetailData = {
        // ReportDetailData fields:
        focusAreas: ["Movement Health", "Behavioral Health"],
        healthCategories: ["Wellness", "Health"],
        isInPain: true,
        users: [
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
            { name: "First3 Last3", email: "assessment3@test.com", tags: [], dob: "1978-12-26", healthCategory: "Wellness", inPain: true, movementScore: 65, breathingScore: 70, injuryHistoryScore: 75, behavioralScore: 80, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First1 Last1", email: "assessment1@test.com", tags: [], dob: "1954-01-18", healthCategory: "Health", inPain: true, movementScore: 60, breathingScore: 65, injuryHistoryScore: 70, behavioralScore: 75, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 50, mskScore: 60, focusAreas: ["Movement Health", "Injury History"], groupName: "Test Group" },
            { name: "FIRST4 LAST4", email: "assessment4@test.com", tags: [], dob: "1985-07-19", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 55, mskScore: 65, focusAreas: ["Movement Health"] },
            { name: "First5 Last5", email: "assessment5@test.com", tags: [], dob: "1991-06-13", healthCategory: "Wellness", inPain: false, movementScore: 70, breathingScore: 75, injuryHistoryScore: 80, behavioralScore: 85, activityScore: 79, bodyCompScore: 99, sleepScore: 99, nutritionScore: 70, mskScore: 75, focusAreas: ["Behavioral Health", "Breathing Quality", "Movement Health"] },
            { name: "first2 last2", email: "assessment2@test.com", tags: [], dob: "2000-10-10", healthCategory: "Performance", inPain: false, movementScore: 95, breathingScore: 60, injuryHistoryScore: 65, behavioralScore: 70, activityScore: 69, bodyCompScore: 89, sleepScore: 99, nutritionScore: 45, mskScore: 95, focusAreas: ["Balance"] },
        ],

        // BaseReportData fields:
        groupNames: ["Group Name", "test", "test2"],
        organizationName: "Org Name that can be Longer",
        reportDate: "2022-05-11",
        tagNames: ["Tag Name", "test", "test2"],
        totalUsers: 20,
    }

    return <ReportDetail data={data} />;
}

export default ReportDetailTestPage;
