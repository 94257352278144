import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import {
    getFirestore,
    // connectFirestoreEmulator,
    Firestore,
    initializeFirestore,
} from "firebase/firestore";
import {
    getFunctions,
    // connectFunctionsEmulator,
    Functions,
} from "firebase/functions";
import {
    getStorage,
    FirebaseStorage,
    // connectStorageEmulator,
} from "firebase/storage";
import {
    getAuth,
    // connectAuthEmulator,
    Auth,
} from "firebase/auth";

// this is being done purposefuly instead of using node-config-ts and an
// app config. Create React App doesn't support the node-config-ts package.
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderID: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const initialize = () => {
    const firebaseApp = initializeApp(config);
    const auth = getAuth(firebaseApp);

    initializeFirestore(firebaseApp, {
        ignoreUndefinedProperties: true,
    });

    const db = getFirestore(firebaseApp);
    const functions = getFunctions(firebaseApp);
    const storage = getStorage(firebaseApp);

    return { firebaseApp, auth, db, functions, storage };
};

type ConnectToEmulators = {
    firebaseApp: FirebaseApp;
    auth: Auth;
    db: Firestore;
    functions: Functions;
    storage: FirebaseStorage;
};

const connect = ({
    firebaseApp,
    auth,
    db,
    functions,
    storage,
}: ConnectToEmulators) => {
    // TODO : make app seed and enable emulators in fresh environment

    // don't use the emulators if node_env isn't development
    const useEmulator = process.env.NODE_ENV === "development";
    if (useEmulator) {
        // connectAuthEmulator(auth, "http://localhost:9099", {
        //     disableWarnings: true,
        // });
        // connectFirestoreEmulator(db, "localhost", 8080);
        // connectStorageEmulator(storage, "localhost", 9199);
        // connectFunctionsEmulator(functions, "localhost", 5001);
    }

    return { firebaseApp, auth, db, functions, storage };
};

export default function getFirebase() {
    const existingApp = getApps()[0];
    if (existingApp) return initialize();
    return connect(initialize());
}



