import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DropdownSelect } from "../../components/forms/dropdown-select/dropdown-select";
import { MultiSelect, TextTypeInput, ToggleButton } from "../../components/forms";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { LeadLink } from "../../models/interfaces/lead-link";
import { DropdownSelectOption } from "../../models/interfaces/dropdown-select-option";
import { DatePicker } from "../../components/forms/date-picker";
import { Loader } from "../../components/loader/loader";
import { DateTime } from "luxon";
import { useStore } from "../../store/useStore";
import { enqueueSnackbar } from "notistack";
import { GroupOptions } from "../../models/interfaces/group-options";
import { DeeplinkStatus } from "../../models/enumerations/deeplink-status";
import LeadLinksService from "../../utilities/services/lead-links-service";
import { Button } from "../../components/button/button";

const LEAD_CAPTURE_LINK_TYPE = "leadCapture";

interface LeadLinksAddEditPageParams {
    id: string;
}

interface AddEditFormData {
    name: string;
    group?: GroupOptions;
    tags?: DropdownSelectOption[];
    startDate?: string;
    endDate?: string;
    active: boolean;
    videoUrl?: string;
}

const LeadLinkAddEditPage: React.FC<LeadLinksAddEditPageParams> =
    (props: LeadLinksAddEditPageParams) => {
        const leadCaptureOrgId = process.env.REACT_APP_LEAD_CAPTURE_ORG_ID;
        const { id } = useParams<LeadLinksAddEditPageParams>();
        const { state } = useAuthState();
        const history = useHistory();
        const [isLoading, setIsLoading] = useState<boolean>(true);

        if (!state.user || !state.user?.isSuperAdmin) {
            throw new Error("You must be a super admin");
        }

        const methods = useForm<AddEditFormData>();

        const {
            getGroupsDropDownSelectOptionsByOrg,
            groupsDropdownSelectOption,
            getTagsDropDownSelectOptions,
            tagsDropdownSelectOptions,
            getTagById,
        } = useStore();

        const {
            register,
            handleSubmit,
            formState: { errors },
            setValue,
            reset,
            control,
            setError
        } = methods;

        useEffect(() => {
            const loadDependencies = async () => {
                if (leadCaptureOrgId) {
                    await getGroupsDropDownSelectOptionsByOrg(leadCaptureOrgId);
                    await getTagsDropDownSelectOptions(leadCaptureOrgId);
                }
            };

            const loadLeadLink = async () => {
                const leadLink = await LeadLinksService.get(id);

                if (!leadLink) {
                    return;
                }

                let formData: AddEditFormData = {
                    name: leadLink.name,
                    active: leadLink.status === DeeplinkStatus.Active,
                    videoUrl: leadLink.videoUrl
                };

                formData = {
                    ...formData, group: leadLink.group,
                }

                if (leadLink.tags) {
                    formData.tags = await Promise.all(leadLink.tags
                        .map(async (tag) => {
                            try {
                                return {
                                    label: tag.name,
                                    value: tag.id
                                } as DropdownSelectOption;
                            } catch (error: any) {
                                console.log(error, tag);
                                throw error;
                            }
                        }));
                }

                reset({
                    name: formData.name,
                    group: formData.group,
                    active: formData.active,
                    tags: formData.tags,
                    startDate: leadLink.startDate,
                    endDate: leadLink.endDate,
                    videoUrl: leadLink.videoUrl,
                });

                setIsLoading(false);
            };

            loadDependencies();

            if (id) {
                loadLeadLink();
            } else {
                reset({
                    active: true
                });
                setIsLoading(false);
            }
        }, [getGroupsDropDownSelectOptionsByOrg, getTagsDropDownSelectOptions, id, reset, getTagById, setValue, leadCaptureOrgId]);

        const isFormValid = (formData: AddEditFormData) => {
            if (!formData.startDate && !formData.endDate) {
                return true;
            }

            const start = DateTime.fromISO(formData.startDate!);
            const end = DateTime.fromISO(formData.endDate!);

            if (end < start) {
                setError("endDate",
                    {
                        type: "custom",
                        message: "The end date cannot be before the start date"
                    });
                return false;
            }

            return true;
        }

        const onSubmit = async (data: any) => {
            setIsLoading(true);

            // typecasting for use
            const formData = data as AddEditFormData;

            if (!isFormValid(formData)) {
                setIsLoading(false);
                return;
            }

            var entity = {
                name: formData.name,
                tags: [],
                status: formData.active ? DeeplinkStatus.Active : DeeplinkStatus.Inactive,
                organizationId: leadCaptureOrgId
            } as LeadLink;

            if (formData.tags) {
                formData.tags.forEach((tag) => {
                    entity?.tags?.push({
                        id: tag.value,
                        name: tag.label
                    })
                });
            }

            entity.group = formData.group;
            entity.startDate = formData.startDate;
            entity.endDate = formData.endDate;
            entity.videoUrl = formData.videoUrl;

            if (id) {
                entity.id = id;

                try {
                    await LeadLinksService.update(entity);
                } catch (err) {
                    console.error('Failed to update the lead link');
                }
            } else {
                try {
                    entity.clicksTotalCount = 0;
                    entity.leadsTotalCount = 0;
                    entity.starterTotalCount = 0;
                    entity.businessTotalCount = 0;
                    entity.enterpriseTotalCount = 0;

                    const createdLeadLink = await LeadLinksService.add(entity);
                    const baseUrl = process.env.REACT_APP_SYMMIO_PORTAL_URL;

                    if (baseUrl && createdLeadLink.id) {
                        createdLeadLink.url = `${baseUrl}/link-account-creation/${createdLeadLink.id}/${LEAD_CAPTURE_LINK_TYPE}`;
                        await LeadLinksService.update(createdLeadLink);
                    }

                } catch (err: any) {
                    console.error('Failed to create a lead link');
                    enqueueSnackbar(err);
                }
            }
            id ? enqueueSnackbar("Link updated!", { variant: "toast", width: "450px" }) : enqueueSnackbar("Link created!", { variant: "toast", width: "450px" });
            history.goBack();
        };

        return (
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 max-w-screen-md">
                    <h2>{(!id) ? "Add Lead Capture Link" : "Edit Lead Capture Link"}</h2>
                    <Loader isVisible={isLoading} />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                                <TextTypeInput id="name"
                                    className="col-span-1"
                                    label="Link Name"
                                    registerHook={register}
                                    registerOptions={{ required: "A link must have a name" }}
                                    errorState={errors.name} />
                            </div>
                            <DropdownSelect id="group"
                                label="Group"
                                optionsArray={groupsDropdownSelectOption}
                                control={control}
                                errorState={errors.group} />
                            <MultiSelect id="tags"
                                label="Tags"
                                control={control}
                                optionsArray={tagsDropdownSelectOptions}
                                setValue={setValue}
                                errorState={errors.tags} />
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                                <TextTypeInput id="videoUrl"
                                    className="col-span-1"
                                    label="Video Url"
                                    registerHook={register}
                                    errorState={errors.videoUrl} />
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <DatePicker id="startDate"
                                    className="col-span-1"
                                    label="Start Date"
                                    control={control} />
                                <DatePicker id="endDate"
                                    className="col-span-1"
                                    label="End Date"
                                    control={control} />
                            </div>
                            {id &&
                                <ToggleButton id="active" register={register} mainLabelText="Active" />
                            }
                            <div className={'actions-container'} style={{ gap: "50px", marginTop: "16px" }}>
                                <Button
                                    type="link"
                                    buttonText="Cancel"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        history.goBack();
                                    }} />
                                <Button
                                    buttonType="submit"
                                    buttonText={id ? "Save Changes" : "Add Link"} />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        );
    };

export default LeadLinkAddEditPage;
