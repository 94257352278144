import create from "zustand";
import { persist } from "zustand/middleware";
import {
    createGlobalSlice,
    GlobalSlice,
    onDeserializeGlobalSlice,
    onRehydratedGlobalSlice,
    onPartializeGlobalSlice,
    GlobalSliceState,
} from "./globalSliceAssessments";
// import {
//     createBookmarkSlice,
//     BookmarkSlice,
//     BookmarkSliceState,
// } from "./bookmarkSlice";
// import {
//     createDiscoverSlice,
//     DiscoverSlice,
//     DiscoverSliceState,
// } from "./discoverSlice";
// import {
//     createCollectionSlice,
//     CollectionSlice,
//     CollectionSliceState,
// } from "./collectionSlice";
// import { createTopicSlice, TopicSlice, TopicSliceState } from "./topicSlice";
import { persistStoreConfig } from "./persist-store-config";
// import {
//     createDiscoverArticlesSlice,
//     DiscoverArticlesSlice,
//     DiscoverArticlesSliceState,
// } from "./discover-articles-slice";

/* A single bound store is created here from individual slices. This is using
 a slices pattern
 slices can update states contained in other slices
 ref: https://docs.pmnd.rs/zustand/guides/typescript#slices-pattern
 */

export type UnifiedStore = GlobalSlice;
    // BookmarkSlice &
    // DiscoverSlice &
    // DiscoverArticlesSlice &
    // CollectionSlice &
    // TopicSlice;
export type UnifiedStoreState = GlobalSliceState;
    // BookmarkSliceState &
    // DiscoverSliceState &
    // DiscoverArticlesSliceState &
    // CollectionSliceState &
    // TopicSliceState;

export const useStoreAssessments = create<
    UnifiedStore,
    [["zustand/persist", UnifiedStore]]
>(
    persist(
        (...a) => ({
            ...createGlobalSlice(...a),
            // ...createBookmarkSlice(...a),
            // ...createDiscoverSlice(...a),
            // ...createDiscoverArticlesSlice(...a),
            // ...createCollectionSlice(...a),
            // ...createTopicSlice(...a),
        }),
        persistStoreConfig<UnifiedStore>({
            name: "persisted",
            deserialize: [onDeserializeGlobalSlice],
            onRehydrateStorage: [onRehydratedGlobalSlice],
            partialize: [onPartializeGlobalSlice],
        })
    )
);

/* USAGE from inside component
  import { useStoreAssessments } from '../../store/useStoreAssessments';
  import { BookmarkSliceType } from '../../store/bookmarkSlice';

  const { bookmarksIsLoaded, fetchBookmarks } = useStoreAssessments((state) => ({
        bookmarksIsLoaded: state.bookmarksIsLoaded,
        fetchBookmarks: state.fetchBookmarks,
      }));

   OR if you're only going to subscribe to one hook
    const bookmarks = useStoreAssessments((state) => state.bookmarks);
*/
