import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { DateTime } from "luxon";
import { ActivityType } from "../models/enumerations/activity-type";
import FirestoreCondition from "../models/interfaces/firestore-condition";
import { GlobalSliceState } from "../store/globalSlice";
import getFirebase from "./firebase";
import UserActivityService from "./services/user-activity-service";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Remove a key value pair from Local storage
 * @param  {string} key
 * @returns void
 */
function createFirebaseConditions(
    field: string,
    operator:
        | "<"
        | "<="
        | "=="
        | "!="
        | ">="
        | ">"
        | "array-contains"
        | "in"
        | "array-contains-any"
        | "not-in",
    value: string
): FirestoreCondition[] {
    return [createFirebaseCondition(field, operator, value)];
}

/**
 * Remove a key value pair from Local storage
 * @param  {string} key
 * @returns void
 */
function createFirebaseCondition(
    field: string,
    operator:
        | "<"
        | "<="
        | "=="
        | "!="
        | ">="
        | ">"
        | "array-contains"
        | "in"
        | "array-contains-any"
        | "not-in",
    value: any
): FirestoreCondition {
    const condition: FirestoreCondition = {
        field: field,
        operator: operator,
        value: value,
    };
    return condition;
}

async function logDeviceActive(globalState: GlobalSliceState): Promise<void> {
    const userId = globalState.user?.id;
    if (userId == null || userId.length === 0) {
        return;
    }

    const date = DateTime.now().toISODate();

    const currentActivities = await UserActivityService.getBy(
        [
            {
                field: "userId",
                operator: "==",
                value: userId,
            },
            {
                field: "date",
                operator: "==",
                value: date,
            },
            {
                field: "type",
                operator: "==",
                value: "open",
            },
        ],
        [],
        1
    );

    if (currentActivities.length > 0) {
        return;
    }

    await UserActivityService.add({
        date: date,
        type: ActivityType.Open,
        userId: userId,
    });
}

async function logUserActivity(
    userId: string,
    type: ActivityType = ActivityType.Open
): Promise<void> {
    if (userId == null || userId.length === 0) {
        return;
    }

    const date = DateTime.now().toISODate();

    const currentActivities = await UserActivityService.getBy(
        [
            {
                field: "userId",
                operator: "==",
                value: userId,
            },
            {
                field: "date",
                operator: "==",
                value: date,
            },
            {
                field: "type",
                operator: "==",
                value: type,
            },
        ],
        [],
        1
    );

    if (currentActivities.length > 0) {
        return;
    }

    await UserActivityService.add({
        date: date,
        type: type,
        userId: userId,
    });

    return;
}

async function uploadFile(
    bucket: string,
    destination: string,
    file: Blob
): Promise<string> {
    const { storage } = getFirebase();
    const resource = ref(storage, `${bucket}/${destination}`);
    await uploadBytes(resource, file);
    return await getDownloadURL(resource);
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const FirebaseUtils = {
    createFirebaseCondition,
    createFirebaseConditions,
    logDeviceActive,
    logUserActivity,
    uploadFile,
};

// #endregion Exports
