import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import SubscriptionLinkService from "../../utilities/services/subscription-link-service";
import AssessmentResults from "../../components/assessment-results/assessment-results";

export interface SubscriptionLinkResultsPageProps {

}

const COMPONENT_CLASS = "p-subscription-link-results";

const SubscriptionLinkResultsPage: React.FunctionComponent<SubscriptionLinkResultsPageProps> = () => {
    const { state } = useAuthState();

    const [linkVideoUrl, setLinkVideoUrl] = useState<string | undefined>();

    useEffect(() => {
        const getSubscriptionLink = async () => {
            try {
                const fetchedLink = await SubscriptionLinkService.get(state.user?.subscriptionLinkId!);
                setLinkVideoUrl(fetchedLink?.videoUrl);
            } catch (err) {
                console.error("Cannot fetch the subscription link ID");
            }
        }

        getSubscriptionLink();
    }, [state.user?.subscriptionLinkId]);

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }
    if (state.user && !state.user.isAccountSetup) {
        return <Redirect to="/setup" />;
    }
    if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect to="/lc-assessment-results" />
    }
    if (state.user && !state.user.subscriptionLinkId) {
        return <Redirect to="/" />
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <AssessmentResults secondScreenVideoUrl={linkVideoUrl} secondScreenButtonText="Start Your Program" showJoinNowButton={true} showYourWellnessProgramSectionWithoutLicense={true} yourWellnessProgramText="You've unlocked access to your personalized Wellness Program – your personalized gateway to wellness. Discover your full potential while keeping focused on meeting your wellness goals in an easy-to-use app available on any mobile device." />
        </div>
    );
};

export default SubscriptionLinkResultsPage;