import AccountSetupScreen from "../../components/account-setup-screen/account-setup-screen";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";

const AccountSetup: React.FC = () => {
    return (
        <BaseCardLayout>
            <div style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem"
            }}>
                <h4><strong>Get Started</strong></h4>
            </div>
            <AccountSetupScreen />
        </BaseCardLayout>
    );
};

export default AccountSetup;
