import { UserStatus } from "../models/enumerations/user-status";
import { Organization } from "../models/interfaces/organization";
import { User } from "../models/interfaces/user";
import OrganizationService from "./services/organization-service";
import UserService from "./services/user-service";

export default class OrganizationUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns an up-to-date count of the users in an organization (optionally
     * including all the sub-organizations of that organization). This is more
     * accurate than the userCount property, which is only updated once a day
     * via firebase function.
     *
     * @param {Organization | null | undefined} org The organization to get user
     * count for. 
     * @param {boolean} includeSubOrgs Whether to include sub organizations in
     * the count (true) or not (false). 
     * @returns {Promise<number>} A promise for the user count.
     */
    public static async getOrganizationUserCount(org: Organization | null | undefined, includeSubOrgs?: boolean): Promise<number> {
        if (!org) {
            return 0;
        }

        let userCount = 0;

        const mainOrgUserCount = await UserService.getCountBy(
            [{
                field: 'organizationId',
                operator: '==',
                value: org.id,
            }, {
                field: "status",
                operator: "!=",
                value: UserStatus.Disabled,
            }],
        );

        userCount += mainOrgUserCount;

        if (org.subOrganizationIds && org.subOrganizationIds.length > 0 && includeSubOrgs) {
            const promises: Promise<number>[] = [];
            org.subOrganizationIds.forEach((o) => {
                promises.push(
                    UserService.getCountBy(
                        [{
                            field: 'organizationId',
                            operator: '==',
                            value: o,
                        }, {
                            field: "status",
                            operator: "!=",
                            value: UserStatus.Disabled,
                        }],
                    )
                        .then((r) => userCount += r)
                )
            });

            await Promise.all(promises);
        }

        return userCount;
    };

    /**
     * Updates the subOrganizationId property of a parent organization by
     * querying the firestore for all organizations with a matching
     * parentOrganizationId.
     *
     * @param {string | null | undefined} orgId The id of the organization to be
     * updated .
     * @param {User | null | undefined} user The user performing the update. 
     */
    public static async updateSubOrganizations(orgId: string | null | undefined, user: User | null | undefined) {
        if (!orgId) {
            return;
        }

        const parentOrg = await OrganizationService.get(orgId);

        if (!parentOrg || parentOrg.isSubOrganization) {
            return;
        }

        const subOrgs = await OrganizationService.getBy(
            [{
                field: 'parentOrganizationId',
                operator: '==',
                value: orgId,
            }]
        );

        const subOrgIds: string[] = subOrgs.map((o) => o.id ?? '');


        parentOrg.subOrganizationIds = subOrgIds;

        const updatedOrg = await OrganizationService.save(parentOrg, user);
        return updatedOrg;
    }


    // #endregion Public Methods
}
