import { PersistOptions } from "zustand/middleware";
import { LocalStorageUtils } from "../utilities/local-storage-utils";

type StorageValue<S> = {
    state: S;
    version?: number;
};

export function persistStoreConfig<T>({
    name,
    version = 1,
    deserialize = [],
    onRehydrateStorage = [],
    partialize = [],
}: {
    name: string;
    version?: number;
    deserialize?: Array<(state: T) => Partial<T>>;
    onRehydrateStorage?: Array<(state: T) => void>;
    partialize?: Array<(state: T) => Partial<T>>;
}): PersistOptions<T, T> {
    return {
        name,
        version,
        getStorage() {
            return {
                async getItem(key: string) {
                    const storedValue = await LocalStorageUtils.get<string>(
                        key
                    );

                    if (storedValue == null) {
                        return null;
                    }

                    return storedValue;
                },
                async setItem(name: string, value: string) {
                    await LocalStorageUtils.set(name, value);
                },
                async removeItem(key: string) {
                    await LocalStorageUtils.remove(key);
                },
            };
        },
        partialize(state) {
            return aggregateResults(partialize, state) as T;
        },
        deserialize(storedValue: string) {
            const globalState = JSON.parse(storedValue) as StorageValue<T>;

            return {
                ...globalState,
                state: {
                    ...globalState.state,
                    ...aggregateResults(deserialize, globalState.state),
                },
            };
        },
        onRehydrateStorage: () => {
            return (state, error) => {
                if (error) {
                    console.error("an error happened during hydration", error);
                }

                if (state != null) {
                    onRehydrateStorage.forEach((onRehydrated) =>
                        onRehydrated(state)
                    );
                }
            };
        },
    };
}

function aggregateResults<T>(arr: Array<(state: T) => Partial<T>>, state: T) {
    return arr.reduce(
        (prev, curr) => ({
            ...prev,
            ...curr(state),
        }),
        {}
    );
}
