import { useEffect, useState } from "react";
import {
    Configure,
    connectHits,
    InstantSearch,
    Pagination,
    SearchBox,
    SortBy,
} from "react-instantsearch-dom";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import searchClient, {
    SearchIndexes,
} from "../../utilities/search-client";
import OrganizationService from "../../utilities/services/organization-service";
import { Button } from "../button/button";
import { Loader } from "../loader/loader";
import { Modal } from "../modal/modal";
import { SortIcons } from "../sort-icons/sort-icons";
import { useHistory } from "react-router";
import UserUtil from "../../utilities/user-util";
import OrganizationUtil from "../../utilities/organization-util";
import { EditOrganizationModal } from "../organization-management/edit-organization-modal/edit-organization-modal";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { ViewOrganizationModal } from "../organization-management/view-organization-modal/view-organization-modal";
import { useSnackbar } from "notistack";

const COMPONENT_CLASS = "c-organization-list";

const OrganizationList: React.FC = () => {
    // const [editOrganization, setEditOrganization] = useState<Organization>();
    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState<any>({});
    // const [errorMessages, setErrorMessages] = useState([] as string[]);
    const [searchState, setSearchState] = useState({
        sortBy: SearchIndexes.organizationsByNameAsc,
    });
    const [algoliaFilters, setAlgoliaFilters] = useState<string>(`organizationId:"NO_MATCH"`);
    const [accHolderLimitReached, setAccHolderLimitReached] = useState<boolean>(false);
    const [editOrgId, setEditOrgId] = useState<string>();
    const [viewOrgId, setViewOrgId] = useState<string>();
    const [viewOpen, setViewOpen] = useState<boolean>(false);

    const { state, setState } = useAuthState();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);

    useEffect(() => {
        const checkLimit = async () => {
            if (isAccountHolder && state.user && state.user.organizationId) {
                let parentOrg;

                if (state.user.organizationId === state.organization.id) {
                    parentOrg = await OrganizationService.get(state.organization.id);
                } else if (state.organization.parentOrganizationId) {
                    parentOrg = await OrganizationService.get(state.organization.parentOrganizationId);
                } else {
                    return;
                }

                if (parentOrg && parentOrg.organizationLimit) {
                    if (parentOrg.subOrganizationIds) {
                        setAccHolderLimitReached(parentOrg.subOrganizationIds.length + 1 >= parentOrg.organizationLimit);
                    } else {
                        setAccHolderLimitReached(parentOrg.organizationLimit === 1);
                    }
                } else {
                    setAccHolderLimitReached(false);
                }
            } else {
                setAccHolderLimitReached(false);
            }
        };

        checkLimit();
    }, [isAccountHolder, state.organization.id, state.organization.parentOrganizationId, state.user]);

    const clearSearchCache = async () => {
        return Promise.resolve(
            setSearchState({
                sortBy: SearchIndexes.organizationsByNameAsc,
            })
        );
    };

    const handleDeleteOrganizationClick = async () => {
        if (organizationToDelete?.objectID == null) {
            return;
        }

        setIsSaving(true);

        OrganizationService.deleteById(organizationToDelete.objectID).then(
            async () => {
                // if the deleted org has a subscription, we want to delete it
                const subResponse = await OrganizationSubscriptionService.getByOrganizationId(organizationToDelete.id);
                if (subResponse && subResponse.length > 0 && subResponse[0].id) {
                    await OrganizationSubscriptionService.deleteById(subResponse[0].id);
                }

                // if the deleted org has a parent organization, we want to
                // update that organization to remove the deleted org from its
                // subOrganizationIds array
                if (organizationToDelete.parentOrganizationId) {
                    const updatedParentOrg = await OrganizationUtil.updateSubOrganizations(
                        organizationToDelete.parentOrganizationId, state.user
                    );
                    // if the current organization in state is the parent
                    // organization, we want to update the state to reflect
                    // this change
                    if (state.organization.id === organizationToDelete.parentOrganizationId && updatedParentOrg) {
                        if (updatedParentOrg && updatedParentOrg.organizationLimit) {
                            if (updatedParentOrg.subOrganizationIds) {
                                setAccHolderLimitReached(updatedParentOrg.subOrganizationIds.length + 1 >= updatedParentOrg.organizationLimit);
                            } else {
                                setAccHolderLimitReached(updatedParentOrg.organizationLimit === 1);
                            }
                        }
                        setState((state) => ({
                            ...state, ...{ organization: updatedParentOrg }
                        }));
                    }
                }
                setOpenDelete(false);
                setOrganizationToDelete(undefined);
                clearSearchCache().then(() => setIsSaving(false));
                enqueueSnackbar("Organization deleted successfully!", { variant: "toast", width: "450px" });
            }
        );
    };

    useEffect(() => {
        clearSearchCache().then(() => setIsSaving(false));
    }, []);

    // const handleSaveOrganizationClick = async () => {
    //     if (editOrganization == null) {
    //         return;
    //     }

    //     setIsSaving(true);

    //     try {
    //         await OrganizationService.save(editOrganization, state.user);
    //     } catch (err: any) {
    //         setErrorMessages([err.toString()]);
    //         setIsSaving(false);
    //     }

    //     setTimeout(() => {
    //         setOpen(false);
    //         setIsSaving(false);
    //         searchClient.clearCache();
    //     }, 3000);
    // };

    // useEffect(() => {
    //     if (!open) {
    //         setEditOrganization(undefined);
    //     }
    // }, [open]);

    useEffect(() => {
        if (!openDelete) {
            setOrganizationToDelete(undefined);
        }
    }, [openDelete]);

    useEffect(() => {
        const getFilters = async () => {
            if (!isAccountHolder) {
                setAlgoliaFilters('');
            }
            if (isAccountHolder) {
                setAlgoliaFilters(await UserUtil.getAlgoliaAccountHolderOrganizationFilter(state.user));
            }
        };
        getFilters();
    }, [state.user, isAccountHolder]);

    const updateSort = (sortBy: string | undefined) => {
        const state = Object.assign({}, searchState);
        state.sortBy = sortBy!;
        setSearchState(state);
    };

    const isAccountHoldersOriginalOrg = (id: string): boolean => {
        if (UserUtil.isAccountHolder(state.user)) {
            if (id === state.user?.organizationId) {
                return true;
            }
        }
        return false;
    };

    const openViewModal = (id: string) => {
        setViewOpen(true);
        setViewOrgId(id);
    }

    const customHits = ({ hits }: any) => {
        return (
            <div className={`list__table-container`}>
                <table className={`${COMPONENT_CLASS}__list__table`}>
                    <thead>
                        <tr>
                            <th className="py-2">
                                <div className="sort-icons-container">
                                    <span>Name</span>
                                    <SortIcons
                                        ascField={SearchIndexes.organizationsByNameAsc}
                                        descField={SearchIndexes.organizationsByNameDesc}
                                        currentSort={searchState.sortBy}
                                        onSortClick={(sortBy) => updateSort(sortBy)}
                                    />
                                </div>
                            </th>
                            <th className="py-2">
                                <div className="sort-icons-container">
                                    <span>Users</span>
                                    <SortIcons
                                        ascField={SearchIndexes.organizationsByUsersAsc}
                                        descField={SearchIndexes.organizationsByUsersDesc}
                                        currentSort={searchState.sortBy}
                                        onSortClick={(sortBy) => updateSort(sortBy)}
                                    />
                                </div>
                            </th>
                            {isSuperAdmin &&
                                <>
                                    <th className="py-2">
                                        <div className="sort-icons-container">
                                            <span>Plan</span>
                                            <SortIcons
                                                ascField={SearchIndexes.organizationsByPlanAsc}
                                                descField={SearchIndexes.organizationsByPlanDesc}
                                                currentSort={searchState.sortBy}
                                                onSortClick={(sortBy) => updateSort(sortBy)}
                                            />
                                        </div>
                                    </th>
                                    <th className="py-2">
                                        <div className="sort-icons-container">
                                            <span>Status</span>
                                            <SortIcons
                                                ascField={SearchIndexes.organizationsByStatusAsc}
                                                descField={SearchIndexes.organizationsByStatusDesc}
                                                currentSort={searchState.sortBy}
                                                onSortClick={(sortBy) => updateSort(sortBy)}
                                            />
                                        </div>
                                    </th>
                                    <th className="py-2">
                                        <div className="sort-icons-container">
                                            <span>Account Manager</span>
                                            <SortIcons
                                                ascField={SearchIndexes.organizationsByAccountManagerAsc}
                                                descField={SearchIndexes.organizationsByAccountManagerDesc}
                                                currentSort={searchState.sortBy}
                                                onSortClick={(sortBy) => updateSort(sortBy)}
                                            />
                                        </div>
                                    </th>
                                </>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {hits.map((h: any, i: number) => (
                            <tr
                                className={`${COMPONENT_CLASS}__item -${typeof OrganizationStatus[h.status] === 'string' ? OrganizationStatus[
                                    h.status
                                ].toLowerCase() : "undefined"}`}
                                key={`user-list-${i}`}
                                onClick={() => { isSuperAdmin && openViewModal(h.id) }}
                            >
                                <td><div>{h.name}</div></td>
                                <td className={`users-cell ${(h.userCount / h.userLimit >= 0.90 && h.userLimit > 0) ? 'red' : ''}`}>
                                    <div className="show-on-mobile mr-1"><strong>USERS:</strong></div>
                                    <div>{h.userCount || "0"}{h.userLimit > 0 ? `/${h.userLimit}` : "/no limit"}</div>
                                </td>
                                {isSuperAdmin &&
                                    <>
                                        <td>
                                            <div className="show-on-mobile mr-1"><strong>PLAN:</strong></div>
                                            <div>{h.plan ? h.plan : ""}</div>
                                        </td>
                                        <td
                                            className="status-cell"><div>{OrganizationStatus[h.status]}</div>
                                        </td>
                                        <td>
                                            {h.accountManager && h.accountManager.label &&
                                                <>
                                                    <div className="show-on-mobile mr-1"><strong>ACCOUNT MANAGER:</strong></div>
                                                    <div>{h.accountManager.label}</div>
                                                </>
                                            }
                                        </td>
                                    </>
                                }
                                <td>
                                    <Button
                                        type="table-action"
                                        buttonText={isAccountHoldersOriginalOrg(h.id) ? 'View' : 'Edit'}
                                        buttonStyle="white"
                                        onClick={() => {
                                            // history.push(`/organization/${h.id}`)
                                            setEditOrgId(h.id);
                                            setOpen(true);
                                        }}
                                    />
                                    <Button
                                        type="table-action"
                                        buttonText="Delete"
                                        buttonStyle="white"
                                        onClick={(e) => {
                                            setOrganizationToDelete(h);
                                            setOpenDelete(true);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        disabled={isAccountHoldersOriginalOrg(h.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const CustomHits = connectHits(customHits);

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader isVisible={isSaving} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <Button
                        type="default"
                        onClick={() => history.push("/organization/")}
                        buttonText={accHolderLimitReached ? "Organization limit reached" : "+ Add Organization"}
                        disabled={accHolderLimitReached}
                    />
                </div>
                <h1>Account Management</h1>
            </div>
            <InstantSearch
                indexName={SearchIndexes.organizationsByNameAsc}
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={setSearchState}
            >
                <Configure
                    filters={algoliaFilters}
                    hitsPerPage={50}
                />
                <SortBy
                    items={[
                        {
                            label: "Name",
                            value: SearchIndexes.organizationsByNameAsc,
                        },
                        {
                            label: "Name (desc)",
                            value: SearchIndexes.organizationsByNameDesc,
                        },
                        {
                            label: "User Count (asc)",
                            value: SearchIndexes.organizationsByUsersAsc,
                        },
                        {
                            label: "User Count (desc)",
                            value: SearchIndexes.organizationsByUsersDesc,
                        },
                        {
                            label: "Plan (asc)",
                            value: SearchIndexes.organizationsByPlanAsc,
                        },
                        {
                            label: "Plan (desc)",
                            value: SearchIndexes.organizationsByPlanDesc,
                        },
                        {
                            label: "Status (asc)",
                            value: SearchIndexes.organizationsByStatusAsc,
                        },
                        {
                            label: "Status (desc)",
                            value: SearchIndexes.organizationsByStatusDesc,
                        },
                    ]}
                    defaultRefinement="organizations"
                />
                <SearchBox
                    autoFocus
                    className={`${COMPONENT_CLASS}__search`}
                    searchAsYouType={true}
                    showLoadingIndicator={true}
                    loadingIndicator={<Loader isVisible={true} />}
                />
                <CustomHits />
                <Pagination className={`${COMPONENT_CLASS}__pagination`} />
            </InstantSearch>
            <EditOrganizationModal
                open={open}
                onClose={() => setOpen(false)}
                organizationId={editOrgId}
            />
            <ViewOrganizationModal
                open={viewOpen}
                onClose={() => setViewOpen(false)}
                organizationId={viewOrgId}
                onEdit={() => {
                    setViewOpen(false);
                    setOpen(true);
                    setEditOrgId(viewOrgId);
                }}
            />
            <Modal
                isOpen={openDelete}
                isLoading={isSaving}
                onClose={setOpenDelete}
                title="Delete Organization"
                defaultModalActions={true}
                onSubmit={() => handleDeleteOrganizationClick()}
                onCancel={() => setOpenDelete(false)}
                submitButtonText="Yes, delete organization"
            >
                <div>
                    <p>
                        Please confirm that you want to delete organization{" "}
                        {organizationToDelete?.name}.
                    </p>
                </div>

            </Modal>
        </div>
    );
};

export default OrganizationList;
