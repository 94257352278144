/* eslint-disable jsx-a11y/img-redundant-alt */
import FirebaseStorage from "../../utilities/firebase-storage";

const COMPONENT_CLASS = "c-assessment-intro";

export interface MovementIntroProps { }

const MovementIntro: React.FC<MovementIntroProps> = (props) => {
    return (
        <div className={`${COMPONENT_CLASS} -movement scroll-container`}>
            <div className={`${COMPONENT_CLASS}__image`}>
                <img
                    alt="Movement Image"
                    src={new FirebaseStorage().getMediaUrl(
                        "public/movement/movement-assessment@2x.png"
                    )}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__content-container`}>
                <h1>Movement Assessment.</h1>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <p className={`${COMPONENT_CLASS}__description`}>
                        Standing, sitting, reaching, twisting. Movement has a huge
                        impact on our everyday lives. Through this quick assessment,
                        we'll determine strengths and weaknesses in your movement,
                        and keep you moving on the path toward wellness.
                    </p>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            Duration:
                        </p>
                        <p>About 15 minutes</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            Equipment:
                        </p>
                        <p>A sheet of paper</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovementIntro;
