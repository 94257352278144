import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { AnswerUnits } from "../../models/enumerations/answer-units";
import { CategoryIds } from "../../models/enumerations/category-ids";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import { VideoSize } from "../../models/enumerations/video-size";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { Question } from "../../models/interfaces/questions/question";
import { QuestionGroup } from "../../models/interfaces/questions/question-group";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import { Assessment } from "../../models/interfaces/scores/assessment";
import { CalculateLifestyleAssessment } from "../../models/interfaces/scores/calculate-lifestyle-assessment";
import { LifestyleAssessmentResponse } from "../../models/interfaces/scores/lifestyle-assessment-response";
import { MskScore } from "../../models/interfaces/scores/msk-score";
import { UserInfo } from "../../models/interfaces/scores/user-info";
import { Slide } from "../../models/interfaces/slide";
import { SlideGroup } from "../../models/interfaces/slide-group";
import { IUser } from "../../models/interfaces/assessment-user";
import FirebaseStorage from "../firebase-storage";
import MskScoreUtil from "../msk-score-util";
import { QuestionUtils } from "../question-utils";
import CalculateLifestyleService from "../services/calculate/calculate-lifestyle-service";
import CalculateService from "../services/calculate/calculate-serivce";
import LifestyleAssessmentService from "../services/lifestyle-assessment-service/lifestyle-assessment-service";
import LifestyleQuestionsService from "../services/lifestyle-questions/lifestyle-questions-serivce";
import LifestyleScoreService from "../services/lifestyle-score-service/lifestyle-score-service";
import MovementQuestionsService from "../services/movement-questions/movement-questions-serivce";
import MskScoreService from "../services/msk-score-service-assessments/msk-score-service-assessments";
// import TodayService from "../services/today-service/today-service";
import ScreenQuestionsAsGroup from "./screen-questions-as-group";
import { User } from "../../models/interfaces/user";
import AssessmentResponseService from "../services/assessment-response-service/assessment-response-service";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";

// -----------------------------------------------------------------------------------------
// #region Public & Private Methods
// -----------------------------------------------------------------------------------------

const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        // toggle this to add/remove logging
        console.log(message, ...optionalParams);
    }
};

const hasData = (assessmentSettings: AssessmentSettings): boolean => {
    return (
        assessmentSettings.slideGroups != null &&
        assessmentSettings.slideGroups.length >= 1
    );
};

const getCurrentGroupIndex = (
    assessmentSettings: AssessmentSettings
): number => {
    return assessmentSettings.currentGroupIndex;
};

const getCurrentSlideIndex = (
    assessmentSettings: AssessmentSettings
): number => {
    return assessmentSettings.currentSlideIndex;
};

const getFocusAreas = (mskScore: MskScore | undefined): Assessment[] => {
    let focusAreas: Assessment[] = [];
    if (mskScore?.focusAreas != null) {
        mskScore.focusAreas.forEach((x) => focusAreas.push(x));
    }
    return focusAreas;
};

const setQuestionGroups = (
    assessmentSettings: AssessmentSettings,
    questionGroups: QuestionGroup[]
): AssessmentSettings => {
    const settings = Object.assign({}, assessmentSettings);
    if (questionGroups == null || questionGroups.length === 0) {
        settings.slideGroups = [];
        return settings;
    }
    settings.slideGroups = ScreenQuestionsAsGroup(questionGroups);
    return settings;
};

const getSlides = (assessmentSettings: AssessmentSettings): Slide[] => {
    if (
        assessmentSettings.slideGroups == null ||
        assessmentSettings.slideGroups.length === 0
    ) {
        return [];
    }
    if (
        assessmentSettings.currentGroupIndex >=
        assessmentSettings.slideGroups.length
    ) {
        throw new Error(
            `Slides Group Index is not valid: ${assessmentSettings.currentGroupIndex}`
        );
    }

    return assessmentSettings.slideGroups[assessmentSettings.currentGroupIndex]
        .slides;
};

const getSlide = (assessmentSettings: AssessmentSettings): Slide | null => {
    const slides = getSlides(assessmentSettings);
    if (slides.length === 0) {
        return null;
    }

    if (slides == null || slides.length === 0) {
        return null;
    }
    if (assessmentSettings.currentSlideIndex >= slides.length) {
        const error = `Slides Index ${assessmentSettings.currentSlideIndex} is not valid for group ${assessmentSettings.currentGroupIndex}`;
        _log(
            `getSlide(): ${error} - slideGroups: `,
            assessmentSettings.slideGroups
        );
        throw new Error(error);
    }

    return slides[assessmentSettings.currentSlideIndex];
};

const isFirstSlideAndFirstGroup = (
    assessmentSettings: AssessmentSettings
): boolean => {
    return (
        assessmentSettings.currentGroupIndex === 0 &&
        assessmentSettings.currentSlideIndex === 0
    );
};

const isFirstGroup = (assessmentSettings: AssessmentSettings): boolean => {
    return assessmentSettings.currentGroupIndex === 0;
};
const isFirstSlide = (assessmentSettings: AssessmentSettings): boolean => {
    return assessmentSettings.currentSlideIndex === 0;
};
const isLastGroup = (assessmentSettings: AssessmentSettings): boolean => {
    if (assessmentSettings?.slideGroups == null) {
        return true;
    }
    return (
        assessmentSettings.currentGroupIndex + 1 ===
        assessmentSettings.slideGroups.length
    );
};
const isLastSlide = (assessmentSettings: AssessmentSettings): boolean => {
    return (
        assessmentSettings.currentSlideIndex + 1 ===
        getSlides(assessmentSettings).length
    );
};

const moveToPreviousPage = (
    assessmentSettings: AssessmentSettings
): AssessmentSettings => {
    // already at start
    if (isFirstGroup(assessmentSettings) && isFirstSlide(assessmentSettings)) {
        return assessmentSettings;
    }

    const settings = Object.assign({}, assessmentSettings);
    let moveToPrevGroup = !isFirstGroup(settings) && isFirstSlide(settings);

    // move to previous slide
    settings.currentSlideIndex--;
    while (_shouldSkipSlide(settings, settings.currentSlideIndex)) {
        // previous slide
        settings.currentSlideIndex--;

        // move to previous group
        if (isFirstSlide(settings)) {
            moveToPrevGroup = true;
            break;
        }
    }

    // move to previous group
    if (moveToPrevGroup) {
        const groupId = settings.completedGroupIds.pop();
        return _setGroup(settings, groupId!, false);
    }

    return settings;
};

const moveToNextPage = async (
    assessmentSettings: AssessmentSettings
): Promise<AssessmentSettings> => {
    const slide = getSlide(assessmentSettings);

    // validate ability to move forward
    if (assessmentSettings.slideGroups.length === 0) {
        _log("moveToNextPage(): RETURN: no slide groups");
        return assessmentSettings;
    }
    if (isLastGroup(assessmentSettings) && isLastSlide(assessmentSettings)) {
        _log("moveToNextPage(): RETURN: last slide");
        return assessmentSettings;
    }

    // validate has answer
    if (
        slide != null &&
        slide.slideType === SlideType.Question &&
        slide.questions
    ) {
        _log(`moveToNextPage(): slide questions:`, slide.questions);
        for (const question of slide.questions) {
            const questionResponse = assessmentSettings.questionResponses.find(
                (x) => x.questionId === question.questionId
            );

            if (question.optional !== true) {
                if (questionResponse == null) {
                    _log(
                        `moveToNextPage(): RETURN: null response: QuestionID=${question.questionId}; questionResponses:`,
                        assessmentSettings.questionResponses
                    );
                    return assessmentSettings;
                }
                if (
                    questionResponse.answerId == null &&
                    questionResponse.answerResponse == null
                ) {
                    _log("moveToNextPage(): RETURN: null ID and response");
                    return assessmentSettings;
                }
                if (
                    questionResponse.answerResponse != null &&
                    questionResponse.answerResponse.length === 0
                ) {
                    _log("moveToNextPage(): RETURN: empty response");
                    return assessmentSettings;
                }
            }
        }
    }

    let settings = Object.assign({}, assessmentSettings);
    let moveToNextGroup = !isLastGroup(settings) && isLastSlide(settings);

    // move to next slide
    if (!moveToNextGroup) {
        settings.currentSlideIndex++;
        while (_shouldSkipSlide(settings, settings.currentSlideIndex)) {
            // delete answers for skipped
            const slide = getSlides(settings)[settings.currentSlideIndex];
            settings = _deleteAnswerForSlide(settings, slide);

            // move to next group
            if (isLastSlide(settings)) {
                moveToNextGroup = true;
                break;
            }

            // next slide
            settings.currentSlideIndex++;
        }
    }

    // move to next group
    if (moveToNextGroup) {
        const currentGroupId =
            settings.slideGroups[settings.currentGroupIndex].groupId;
        if (settings.completedGroupIds.indexOf(currentGroupId) === -1) {
            settings.completedGroupIds.push(currentGroupId);
        }
        return _moveToNextGroup(settings);
    }

    return settings;
};

const _deleteAnswerForSlide = (
    assessmentSettings: AssessmentSettings,
    slide: Slide
): AssessmentSettings => {
    if (
        slide == null ||
        slide.slideType !== SlideType.Question ||
        !slide.questions
    ) {
        return assessmentSettings;
    }

    const settings = Object.assign({}, assessmentSettings);

    const origNumResponses = settings.questionResponses.length;
    const questionIdsToDelete = slide.questions.map((x) => x.questionId);
    settings.questionResponses = settings.questionResponses.filter(
        (x) => questionIdsToDelete.indexOf(x.questionId) === -1
    );
    if (origNumResponses !== settings.questionResponses.length) {
        _log(
            `_deleteAnswerForSlide(): deleted ${origNumResponses - settings.questionResponses.length
            } answer`
        );
    }
    return settings;
};

const _deleteAnswersForGroup = (
    assessmentSettings: AssessmentSettings,
    groupId: string
): AssessmentSettings => {
    if (groupId == null) {
        return assessmentSettings;
    }

    const settings = Object.assign({}, assessmentSettings);

    const origNumResponses = settings.questionResponses.length;
    settings.questionResponses = settings.questionResponses.filter(
        (x) => x.groupId !== groupId
    );
    if (origNumResponses !== settings.questionResponses.length) {
        _log(
            `_deleteAnswersForGroup(): deleted ${origNumResponses - settings.questionResponses.length
            } answer(s)`
        );
    }
    return settings;
};

const _moveToNextGroup = async (
    assessmentSettings: AssessmentSettings
): Promise<AssessmentSettings> => {
    const settings = Object.assign({}, assessmentSettings);

    _log(
        `moveToNextGroup(): currentGroupIndex=${assessmentSettings.currentGroupIndex} currentSlideIndex=${assessmentSettings.currentSlideIndex} this:`,
        this
    );
    const currentGroupId =
        settings.slideGroups[settings.currentGroupIndex].groupId;

    if (
        settings.slideGroups[settings.currentGroupIndex]
            .nextGroupIdDeterminedByApi === true
    ) {
        return _determineAndMoveToNextGroup(settings, currentGroupId);
    }

    settings.currentSlideIndex = 0;
    settings.currentGroupIndex = settings.currentGroupIndex + 1;
    return settings;
};

const _determineAndMoveToNextGroup = async (
    assessmentSettings: AssessmentSettings,
    currentGroupId: string
): Promise<AssessmentSettings> => {
    let settings = Object.assign({}, assessmentSettings);

    const defaultNextGroupId =
        settings.slideGroups[settings.currentGroupIndex + 1].groupId;
    let actualNextGroupId = await CalculateService.nextAssessment({
        questionGroupId: currentGroupId,
        groupResponses: getResponses(settings, currentGroupId),
    });

    // handle skipping section
    const skippingNextGroupId = actualNextGroupId !== defaultNextGroupId;
    if (skippingNextGroupId) {
        _log(
            `_determineAndMoveToNextGroup(): SKIP SECTION: ${defaultNextGroupId}`
        );
        settings = _deleteAnswersForGroup(settings, defaultNextGroupId);
    }

    // when re-assessing specific and done, jump to end
    if (
        assessmentSettings.specificGroupId != null &&
        settings.slideGroups.find((x) => x.groupId === actualNextGroupId) ==
        null
    ) {
        actualNextGroupId = GroupIds.COMPLETE_SLIDE_GROUP_ID;
    }

    return _setGroup(settings, actualNextGroupId, true);
};

const _setGroup = (
    assessmentSettings: AssessmentSettings,
    groupId: string,
    useFirstSlide: boolean
): AssessmentSettings => {
    const settings = Object.assign({}, assessmentSettings);

    for (
        let groupIndex = 0;
        groupIndex < settings.slideGroups.length;
        groupIndex++
    ) {
        if (settings.slideGroups[groupIndex].groupId !== groupId) {
            continue;
        }

        // set new group
        settings.currentGroupIndex = groupIndex;

        // first index
        if (useFirstSlide) {
            settings.currentSlideIndex = 0;
            return settings;
        }

        // skip questions
        settings.currentSlideIndex =
            settings.slideGroups[groupIndex].slides.length - 1;
        while (_shouldSkipSlide(settings, settings.currentSlideIndex)) {
            settings.currentSlideIndex--;
        }
        return settings;
    }

    console.error(
        `ERROR: _setGroup(): Slide Group was not found: ${groupId}`,
        settings.slideGroups
    );
    return settings;
};

const getAllResponses = (
    assessmentSettings: AssessmentSettings
): QuestionResponse[] => {
    return assessmentSettings.questionResponses;
};

const getAssessmentGroups = (
    assessmentSettings: AssessmentSettings
): SlideGroup[] => {
    if (assessmentSettings?.slideGroups == null) {
        return [];
    }
    const groupsToExclude = [
        GroupIds.ASSESSMENT_INTRO_SLIDE_GROUP_ID.toString(),
        GroupIds.TESTS_SLIDE_GROUP_ID.toString(),
        GroupIds.TIPS_SLIDE_GROUP_ID.toString(),
        GroupIds.COMPLETE_SLIDE_GROUP_ID.toString(),
    ];
    return assessmentSettings.slideGroups.filter(
        (group) => groupsToExclude.indexOf(group.groupId) === -1
    );
};

const getResponses = (
    assessmentSettings: AssessmentSettings,
    questionGroupId: string
): QuestionResponse[] => {
    const result: QuestionResponse[] = [];
    if (
        assessmentSettings.questionResponses == null ||
        assessmentSettings.questionResponses.length === 0
    ) {
        console.error(
            "getResponses(): no questionResponses were found; assessmentSettings:",
            assessmentSettings
        );
        return result;
    }

    assessmentSettings.questionResponses.forEach((questionResponse) => {
        if (questionResponse.groupId === questionGroupId) {
            result.push(questionResponse);
        }
    });

    return result;
};

const _shouldSkipSlide = (
    assessmentSettings: AssessmentSettings,
    slideIndex: number
): boolean => {
    const slides = getSlides(assessmentSettings);
    if (slideIndex <= 0 || slideIndex >= slides.length) {
        // first and last slides can't be skipped
        return false;
    }

    const slide = slides[slideIndex];
    if (slide.slideType !== SlideType.Question || !slide.questions) {
        // only questions can be skipped
        return false;
    }

    return QuestionUtils.shouldSkipAllQuestions(
        slide.questions,
        assessmentSettings.questionResponses
    );
};

const recordAnswer = (
    assessmentSettings: AssessmentSettings,
    question: Question,
    selectedAnswer: string
): AssessmentSettings => {
    const questionId: string = question.questionId;
    const slide = getSlide(assessmentSettings);

    if (slide == null) {
        console.error("ERROR: recordAnswer(): Slide is not set:", slide);
        return assessmentSettings;
    }

    const newResponse = {
        categoryId: slide.categoryId!,
        groupId: slide.groupId!,
        questionId: questionId,
        answerId:
            question.answerFormat === AnswerFormats.OPTIONS
                ? selectedAnswer
                : null,
        answerResponse:
            question.answerFormat !== AnswerFormats.OPTIONS
                ? selectedAnswer
                : null,
    } as QuestionResponse;

    const settings = Object.assign({}, assessmentSettings);
    const answer = settings.questionResponses.find(
        (x) => x.questionId === questionId
    );

    if (answer) {
        // if question has already been answered replace existing answer with new answer
        const otherResponses = settings.questionResponses.filter(
            (x) => x.questionId !== questionId
        );
        settings.questionResponses = [...otherResponses, newResponse];
    } else {
        // question has not been answered yet add new answer to array
        settings.questionResponses = [
            ...settings.questionResponses,
            newResponse,
        ];
    }

    _log(`recordAnswer():`, {
        questionId: questionId,
        newAnswer: selectedAnswer,
        newResponse: newResponse,
        status: answer == null ? "CREATE" : "UPDATE",
        existingAnswer: answer,
        questionResponses: settings.questionResponses,
    });
    return settings;
};

const fetchQuestions = async (
    screenSettings: AssessmentSettings,
    screenType: CategoryIds,
    groupId: string | null = null
): Promise<AssessmentSettings> => {
    let questionGroups = await (screenType === CategoryIds.MOVEMENT
        ? MovementQuestionsService.list({ videoSize: VideoSize.PORTRAIT_854P })
        : LifestyleQuestionsService.list({
            videoSize: VideoSize.PORTRAIT_854P,
            groupId: groupId,
        }));
    questionGroups = _setQuestionAnswerImageUrls(questionGroups);

    const settings = AssessmentUtils.setQuestionGroups(
        screenSettings,
        questionGroups
    );

    return AssessmentUtils.setQuestionGroups(settings, questionGroups);
};

const fetchAssessmentResponses = async (
    user: User,
): Promise<AssessmentResponse[]> => {
    const responses = await AssessmentResponseService.get({userId: user.id!})
    return responses;
};

const fetchAssessmentResponsesById = async (
    userId: string,
): Promise<AssessmentResponse[]> => {
    const responses = await AssessmentResponseService.get({userId: userId})
    return responses;
};

const _setQuestionAnswerImageUrls = (
    questionGroups: QuestionGroup[]
): QuestionGroup[] => {
    if (questionGroups == null || questionGroups.length === 0) {
        return questionGroups;
    }

    const questionIdsWithImageUrls = [
        "BALANCE_REACH_1",
        "ROTATION_REACH_LEFT",
        "ROTATION_REACH_RIGHT",
        "SHOULDER_MOBILITY_REACH_LEFT",
        "SHOULDER_MOBILITY_REACH_RIGHT",
        "SQUAT_LOW",
        "TOE_TOUCH_REACH_LEFT",
        "TOE_TOUCH_REACH_RIGHT",
    ];

    const answerOptionsStorage = new FirebaseStorage("answerOptions/");

    questionGroups.forEach((questionGroup) => {
        for (let j = 0; j < questionGroup.questions.length; j++) {
            const question = questionGroup.questions[j];
            if (
                question.answerOptions == null ||
                question.answerOptions.length === 0
            ) {
                continue;
            }
            const match = questionIdsWithImageUrls.find(
                (questionId) => questionId === question.questionId
            );
            if (match == null) {
                continue;
            }
            question.answerOptions.forEach((answerOption) => {
                const fileName = `${question.questionId}__${answerOption.answerId}.jpg`;
                answerOption.answerImageUrl =
                    answerOptionsStorage.getMediaUrl(fileName);
            });
        }
    });

    return questionGroups;
};

const shouldScoreGroup = (
    screenSettings: AssessmentSettings,
    previousGroupIndex: number,
    currentGroupIndex: number
): boolean => {
    // validation
    if (currentGroupIndex <= previousGroupIndex) {
        _log(
            `shouldScoreGroup() RETURN: currentGroupIndex=${currentGroupIndex} previousGroupIndex=${previousGroupIndex}`
        );
        return false;
    }
    if (screenSettings?.slideGroups == null) {
        return false;
    }
    const previousGroupId =
        screenSettings.slideGroups[previousGroupIndex]?.groupId;
    const skipGroupIds = [
        GroupIds.ASSESSMENT_INTRO_SLIDE_GROUP_ID.toString(),
        GroupIds.TESTS_SLIDE_GROUP_ID.toString(),
        GroupIds.TIPS_SLIDE_GROUP_ID.toString(),
        GroupIds.COMPLETE_SLIDE_GROUP_ID.toString(),
    ];
    if (previousGroupId == null || skipGroupIds.indexOf(previousGroupId) >= 0) {
        _log(`shouldScoreGroup() RETURN: previousGroupId=${previousGroupId}`);
        return false;
    }

    return true;
};

const scoreGroup = async (
    userInfo: UserInfo,
    screenSettings: AssessmentSettings,
    groupIndex: number,
    mskScore?: MskScore
): Promise<LifestyleAssessmentResponse | undefined> => {
    const groupId = screenSettings.slideGroups[groupIndex]?.groupId;

    const lifestyleAssessment: CalculateLifestyleAssessment = {
        userInfo: userInfo,
        lifestyleQuestionGroupId: groupId,
        groupResponses: AssessmentUtils.getResponses(screenSettings, groupId),
        existingMskScore: mskScore,
    };

    const result = await CalculateLifestyleService.assessment(
        lifestyleAssessment
    );
    return result;
};

const saveGroup = async (
    assessmentResponse: LifestyleAssessmentResponse,
    currentIdentity: IUser,
    updateGlobalState: Function
): Promise<MskScore | undefined> => {
    const newMskScore = assessmentResponse?.mskScore;

    // handle MSK score on user account
    // const userUpdated = await UserUtilAssessments.handleNewMskScore(userId, {
    //     mskScore: newMskScore,
    //     lifestyleGroupId: assessmentResponse.assessmentScore?.groupId!,
    // });

    // save responses
    if (newMskScore != null) {
        MskScoreService.addOrUpdateRecent(newMskScore, currentIdentity).then(() => {
            if (currentIdentity?.id)
                MskScoreUtil.getLatest(currentIdentity?.id).then((response) => {
                    if (response?.lifestyleScore?.percentage != null &&
                        response.movementScore?.percentage != null) {
                        updateGlobalState(true);
                    }
                });
        });
    }
    if (assessmentResponse?.assessmentScore != null) {
        LifestyleAssessmentService.addOrUpdateRecent(
            assessmentResponse.assessmentScore!,
            currentIdentity
        );
    }
    if (newMskScore?.lifestyleScore?.percentage != null) {
        LifestyleScoreService.addOrUpdateRecent(
            newMskScore.lifestyleScore,
            currentIdentity
        );
    }

    // clear today cache
    // if (userUpdated) {
    //     TodayService.deleteToday(userId);
    // }

    return newMskScore;
};

const saveGroupByUserId = async (
    assessmentResponse: LifestyleAssessmentResponse,
    currentUserId: string,
    updateGlobalState: Function
): Promise<MskScore | undefined> => {
    const userId = currentUserId;
    const newMskScore = assessmentResponse?.mskScore;

    // save responses
    if (newMskScore != null) {
        MskScoreService.addOrUpdateRecentById(newMskScore, userId).then(() => {
            if (userId)
                MskScoreUtil.getLatest(userId).then((response) => {
                    if (response?.lifestyleScore?.percentage != null &&
                        response.movementScore?.percentage != null) {
                        updateGlobalState(true);
                    }
                });
        });
    }
    if (assessmentResponse?.assessmentScore != null) {
        LifestyleAssessmentService.addOrUpdateRecentByUserId(
            assessmentResponse.assessmentScore!,
            userId
        );
    }
    if (newMskScore?.lifestyleScore?.percentage != null) {
        LifestyleScoreService.addOrUpdateRecentByUserId(
            newMskScore.lifestyleScore,
            userId
        );
    }

    // clear today cache
    // if (userUpdated) {
    //     TodayService.deleteToday(userId);
    // }

    return newMskScore;
};

const saveGroupForLinks = async (
    assessmentResponse: LifestyleAssessmentResponse,
): Promise<MskScore | undefined> => {
    const newMskScore = assessmentResponse?.mskScore;

    return newMskScore;
};

const progressIgnoredGroupIds = [
    // conditional
    GroupIds.BEHAVIORAL_QUESTIONNAIRE.toString(),
    GroupIds.NUTRITION_QUESTIONNAIRE.toString(),
    GroupIds.SLEEP_PSQI.toString(),
    // extras
    GroupIds.ASSESSMENT_INTRO_SLIDE_GROUP_ID.toString(),
    GroupIds.COMPLETE_SLIDE_GROUP_ID.toString(),
    GroupIds.TESTS_SLIDE_GROUP_ID.toString(),
    GroupIds.TIPS_SLIDE_GROUP_ID.toString(),
];

const getProcessCurrent = (settings: AssessmentSettings): number => {
    if (settings?.slideGroups == null || settings.slideGroups.length === 0) {
        return 0;
    }

    const groupId = getSlide(settings)?.groupId!;
    const baseGroups = settings.slideGroups.filter((x) => {
        return (
            x.groupId === groupId ||
            progressIgnoredGroupIds.indexOf(x.groupId) === -1
        );
    });
    const baseGroupIds = baseGroups.map((x) => x.groupId);
    const currentGroupIsIgnored = progressIgnoredGroupIds.indexOf(groupId) >= 0;

    return baseGroupIds.indexOf(groupId) + (currentGroupIsIgnored ? 0 : 1);
};

const getProcessTotal = (settings: AssessmentSettings): number => {
    if (settings?.slideGroups == null || settings.slideGroups.length === 0) {
        return 0;
    }

    const baseGroups = settings.slideGroups.filter((x) => {
        return progressIgnoredGroupIds.indexOf(x.groupId) === -1;
    });

    return baseGroups.length;
};

const autoSubmitQuestion = (question: Question): boolean => {
    if (question == null) {
        return false;
    }
    if (
        question.answerFormat === AnswerFormats.INTEGER &&
        question.answerUnits === AnswerUnits.SECONDS
    ) {
        return true;
    }

    switch (question.answerFormat) {
        case AnswerFormats.OPTIONS:
            return true;
        case AnswerFormats.INTEGER:
            return renderIntegerAsOptions(question);
        default:
            return false;
    }
};

const renderIntegerAsOptions = (question: Question): boolean => {
    if (question == null) {
        return false;
    }
    return (
        question.answerFormat === AnswerFormats.INTEGER &&
        question.integerMin != null &&
        question.integerMax != null &&
        question.integerMax - question.integerMin <= 10
    );
};

// #endregion Public & Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const AssessmentUtils = {
    _setQuestionAnswerImageUrls,
    autoSubmitQuestion,
    fetchQuestions,
    fetchAssessmentResponses,
    fetchAssessmentResponsesById,
    getAllResponses,
    getAssessmentGroups,
    getCurrentGroupIndex,
    getCurrentSlideIndex,
    getFocusAreas,
    getProcessCurrent,
    getProcessTotal,
    getResponses,
    getSlide,
    getSlides,
    hasData,
    isFirstGroup,
    isFirstSlide,
    isFirstSlideAndFirstGroup,
    isLastGroup,
    isLastSlide,
    moveToNextPage,
    moveToPreviousPage,
    recordAnswer,
    renderIntegerAsOptions,
    saveGroup,
    saveGroupForLinks,
    saveGroupByUserId,
    scoreGroup,
    setQuestionGroups,
    shouldScoreGroup,
};

// #endregion Exports
