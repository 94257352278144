import { Controller } from "react-hook-form";
import { DatePickerProps } from "./date-picker.props";
import { ControllerWrappedInput } from "./_controller-wrapped-component";
import "./date-picker.styles.scss";

/**
 * A date form control that provides a pop-up control for
 * selecting the date.
 * 
 * @returns {Date.toISOString} A Date in ISO string format.
 * 
 * ```2023-02-23T05:00:00.000Z```
 */
export const DatePicker: React.FC<DatePickerProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    return (
        <Controller
            name={sanitizedId}
            control={props.control}
            render={({ field, fieldState }) =>
                <ControllerWrappedInput
                    id={sanitizedId}
                    label={props.label}
                    className={props.className}
                    field={field}
                    fieldState={fieldState} />
            }
        />
    );
};
