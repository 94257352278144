/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { UnboundRadioGroup } from "../unbound-fields/unbound-radio-group";
import { RadioGroupItem } from "../radio-group/radio-group-item";

interface FormikRadioGroupProps {
    buttonWidth?: string,
    buttonSpacing?: string,
    items: RadioGroupItem[],
    hidden?: boolean,
    label?: string,
    name: string,
    value?: RadioGroupItem | undefined,
    onChange?: Function,
}

/**
 * A text field hooked up to formik context.
 */
export const FormikRadioGroup: React.FC<FormikRadioGroupProps> = (props) => {
    const {
        buttonWidth,
        buttonSpacing,
        hidden,
        label,
        name,
        items,
        value,
        onChange,
    } = props;

    const formik = useFormikContext<any>();

    const {
        values,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (value) setFieldValue(name, value, false);
    }, []);

    return (
        <UnboundRadioGroup
            ariaLabel={`${label} radio group`}
            hidden={hidden}
            label={label}
            name={name}
            items={items}
            onChange={async (e: RadioGroupItem) => {
                await setFieldValue(name, e, false);
                if (onChange) onChange(e);
            }}
            value={value ?? values[name] ?? items[0]}
            buttonWidth={buttonWidth}
            buttonSpacing={buttonSpacing}
        />
    );
};
