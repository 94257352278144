/**
 * Checks if an object, string or array is empty
 */
function isEmpty(object: any): boolean {
    if (Array.isArray(object)) {
        if (object.length > 0) {
            return false;
        }
    } else {
        if (Object.keys(object).length > 0) {
            return false;
        }
    }
    return true;
}

export { isEmpty };
