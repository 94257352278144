import React from "react";
import RiskFactorUtil from "../../utilities/risk-factor-util";

const COMPONENT_CLASS = "c-risk-chart-vertical";

export interface RiskChartVerticalProps {
    riskFactorPercentage: number | undefined;
}

const RiskChartVertical: React.FC<RiskChartVerticalProps> = (props) => {
    const {
        riskFactorPercentage
    } = props;

    const heightPercent = RiskFactorUtil.convertRiskFactorToDisplayPercent(riskFactorPercentage);

    return (
        <div className={`${COMPONENT_CLASS}`}>

            <div className="flex gap-4 w-full h-full justify-center">
                <div className="w-1/4 h-full relative">
                    {heightPercent !== null &&
                        <div className={`${COMPONENT_CLASS}__bar`} style={{ height: `${100 - heightPercent}%` }}>
                        </div>
                    }
                    <div className={`${COMPONENT_CLASS}__segment optimal`}>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment acceptable`}>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment low`}>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment very-low`}>
                    </div>
                </div>
                <div className="h-full">
                    <div className={`${COMPONENT_CLASS}__segment`}>
                        <div className={`${COMPONENT_CLASS}__${(riskFactorPercentage !== undefined && riskFactorPercentage === 0) ? "highlighted" : ""}`}>Optimal</div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment`}>
                        <div className={`${COMPONENT_CLASS}__${(riskFactorPercentage !== undefined && riskFactorPercentage >= 1 && riskFactorPercentage <= 27) ? "highlighted" : ""}`}>Acceptable</div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment`}>
                        <div className={`${COMPONENT_CLASS}__${(riskFactorPercentage !== undefined && riskFactorPercentage >= 28 && riskFactorPercentage <= 72) ? "highlighted" : ""}`}>Low</div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__segment`}>
                        <div className={`${COMPONENT_CLASS}__${(riskFactorPercentage !== undefined && riskFactorPercentage >= 73 && riskFactorPercentage <= 100) ? "highlighted" : ""}`}>Very Low</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskChartVertical;
