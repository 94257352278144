import { MskScore } from "../../../models/interfaces/scores/msk-score";
import FirestoreServiceAssessments from "../firestore-service-assessments";
import { IUser } from "../../../models/interfaces/assessment-user";
import FirestoreCondition from "../../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../../models/interfaces/firestore-order";

const COLLECTION_NAME = "mskScores";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Update a recent record, or add a new score to the collection
 * @param {MskScore} score - The score that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<LifestyleScore>} A promise to the newly added lifestyle score
 */
const addOrUpdateRecent = async (score: MskScore, currentUser: IUser) => {
    // overwrite existing record
    const recents = await FirestoreServiceAssessments.getRecent<MskScore>(
        COLLECTION_NAME,
        currentUser
    );
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.update<MskScore>(
            COLLECTION_NAME,
            score,
            currentUser
        );
    }

    

    // create record
    score.userId = currentUser.id!;
    return FirestoreServiceAssessments.add<MskScore>(COLLECTION_NAME, score, currentUser);
};

   /**
 * Update a recent record, or add a new score to the collection
 * @param {MskScore} score - The score that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<LifestyleScore>} A promise to the newly added lifestyle score
 */
const addOrUpdateRecentById = async (score: MskScore, currentUserId: string) => {
    // overwrite existing record
    const recents = await FirestoreServiceAssessments.getRecentById<MskScore>(
        COLLECTION_NAME,
        currentUserId
    );
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.updateById<MskScore>(
            COLLECTION_NAME,
            score,
            currentUserId,
        );
    }

    // create record
    score.userId = currentUserId;
    return FirestoreServiceAssessments.addById<MskScore>(COLLECTION_NAME, score, currentUserId);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreServiceAssessments.deleteById(COLLECTION_NAME, id);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    const records = await FirestoreServiceAssessments.getBy(COLLECTION_NAME, conditions);

    records.forEach((record) => {
        FirestoreServiceAssessments.deleteById(COLLECTION_NAME, record.id!);
    });
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const get = async (id: string) => {
    return FirestoreServiceAssessments.get<MskScore>(COLLECTION_NAME, id);
};

const getBy = async (
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
) => {
    return FirestoreServiceAssessments.getBy<MskScore>(
        COLLECTION_NAME,
        conditions,
        orderValue,
        limitValue
    );
};

/**
 * Find the specific user by the id
 * @param {string} userId - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const getByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    return FirestoreServiceAssessments.getBy<MskScore>(COLLECTION_NAME, conditions, [
        {
            field: "created",
            direction: "desc",
        },
    ]);
};

/**
 * Get all of the users stored in the database
 * @returns {Promise<IUser[]>} A promise for the collection of users
 */
const getAll = async () => {
    return FirestoreServiceAssessments.getAll<MskScore>(COLLECTION_NAME);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const MskScoreServiceAssessments = {
    addOrUpdateRecent,
    addOrUpdateRecentById,
    deleteById,
    deleteByUser,
    get,
    getBy,
    getByUserId,
    getAll,
};

export default MskScoreServiceAssessments;

// #endregion Exports
