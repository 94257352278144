export enum FocusArea {
    PHYSICAL_ACTIVITY = "PHYSICAL_ACTIVITY",
    SLEEP = "SLEEP",
    BODY_COMPOSITION = "BODY_COMPOSITION",
    MOVEMENT_HEALTH = "MOVEMENT_HEALTH",
    NUTRITION = "NUTRITION",
    BEHAVIORAL_HEALTH = "BEHAVIORAL_HEALTH",
    BREATHING = "BREATHING",
    INJURY_HISTORY = "INJURY_HISTORY",
    ROAD_TO_WELLNESS = "ROAD_TO_WELLNESS",
    MOVEMENT_HEALTH_BREATHING = "MOVEMENT_HEALTH_BREATHING"
};
