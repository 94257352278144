import React, { useEffect, useState } from 'react';
import { Modal } from '../modal/modal';
import ManageUserCancellationForm from '../manage-account/manage-cancellation-form';

interface CancellationUserSubscriptionModalProps {
    isOpen: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    isLoading?: boolean;
    cancelButtonAction?: Function;
}

const COMPONENT_CLASS = "c-cancellation-dialog-box";

const CancellationUserSubscriptionModal: React.FC<CancellationUserSubscriptionModalProps> = (props) => {
    const {
        isOpen,
        onClose,
        cancelButtonAction
    } = props;

    const [clearFields, setClearFields] = useState(false);

    // Modal closes - reset everything;
    useEffect(() => {
        if (!isOpen) {
            setClearFields(true)
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            isLoading={false}
            onClose={onClose}
            title=""
        >
            <div className={COMPONENT_CLASS}>
                <ManageUserCancellationForm clearFields={clearFields} hideCoupon={true} onCancel={cancelButtonAction} hideBackButton={true} skipConfirmation={true} />
            </div>
        </Modal>
    );

};

export default CancellationUserSubscriptionModal;