import React, { useEffect, useState } from "react";
import { ControlBar, Player } from "video-react";
import { VideoSize } from "../../models/enumerations/video-size";
import { Video } from "../../models/interfaces/video";
import VideoService from "../../utilities/services/video-service/video-service";

const COMPONENT_CLASS = "c-fullscreen-video";

export interface FullscreenVideoProps {
    instructions?: string[];
    video?: Video;
}

const FullscreenVideo: React.FC<FullscreenVideoProps> = (props) => {
    const [videoUrl, setVideoUrl] = useState<string | null>(
        props.video?.videoUrl || null
    );

    useEffect(() => {
        const fetchVideoUrl = async () => {
            if (videoUrl != null) {
                return;
            } else if (props.video?.videoId != null) {
                const url = await VideoService.getUrlFromId({
                    videoId: props.video.videoId,
                    videoSize: VideoSize.PORTRAIT_854P,
                });
                setVideoUrl(url);
            }
        };

        fetchVideoUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${COMPONENT_CLASS}`} data-testid="fullscreen-video">
            {/* <link
                rel="stylesheet"
                href="https://video-react.github.io/assets/video-react.css"
            /> */}
            {
                // if
                videoUrl != null && (
                    <Player
                        autoPlay
                        playsInline
                        aspectRatio={"1:1"}>
                        <source src={videoUrl} type="video/mp4" />
                        <source src={videoUrl} type="video/ogg" />
                        <ControlBar autoHide={false} />
                        <link
                            rel="stylesheet"
                            href="https://video-react.github.io/assets/video-react.css"
                        />
                    </Player>
                )
            }
            {
                // if
                props.instructions != null &&
                props.instructions.length >= 1 && (
                    <div
                        className={`${COMPONENT_CLASS}__video-instruction scroll-container`}>
                        <ul>
                            {props.instructions.map(
                                (instruction, index) => (
                                    <li key={`instruction-${index}`}>
                                        {instruction}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

export default FullscreenVideo;
