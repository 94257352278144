import { Redirect } from 'react-router';
import Dashboard from '../../components/dashboard/dashboard';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import UserRoles from '../../models/user-roles';

function DashboardPage() {

    const { state } = useAuthState();
    if (!state.authenticated) {
        return <Redirect push to="/login" />;
    }

    if (state.user && state.user.roles && state.user.roles.find((role) => role === UserRoles.CLIENT_ID)) {
        if (!state.user.isAccountSetup) {
            return <Redirect push to="/setup" />
        }
        return <Redirect push to="/wellness-assessment" />
    }

    return (
        <Dashboard />
    );
}

export default DashboardPage;