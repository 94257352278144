import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import OrganizationService from '../../utilities/services/organization-service';
import { Organization } from '../../models/interfaces/organization';
import { OrganizationStatus } from '../../models/enumerations/organization-status';
import { AppContent } from '../../models/interfaces/app-content';
import { AppContentType } from '../../models/enumerations/app-content-type';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { useParams } from 'react-router-dom';
import { TextTypeInput } from '../forms';

interface CreateOrganizationProps {
  onBackClick: () => void;
  onSubmitClick: () => void;
  onOrganizationCreate: (data: any, product: any) => void;
}
interface LinkPaymentParams {
  id: string;
  userId: string;
  type?: string;
}
const CreateOrganization: React.FC<CreateOrganizationProps> = ({ onSubmitClick, onBackClick, onOrganizationCreate }) => {
  const COMPONENT_CLASS = "c-subscriptions";
  const FORM_COMPONENT_CLASS = "p-link-create-account";
  const [organizationError, setOrganizationError] = useState("");
  const { id } = useParams<LinkPaymentParams>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onSubmit' });
  const { state } = useAuthState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const onSubmit = async (data: any) => {
    const { organizationName, industry, otherIndustry } = data;
    if (organizationName && industry) {
      const existingOrganization = await OrganizationService.getBy([{ field: "name", operator: "==", value: organizationName }])
      if (existingOrganization.length > 0) {
        setOrganizationError("Organization with this name already exists. Please enter a different name.");
        return;
      }
      setOrganizationError('');
      let org: Organization;
      org = {
        appContent: {
          [AppContentType.PAIN]: {} as AppContent,
          [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
        },
        status: OrganizationStatus.Active,
        name: organizationName,
        leadLinkId: id ? id : '',
        industry: industry === "Other" ? otherIndustry : industry
      };
      onOrganizationCreate(org, state.temp.product);
    } else {
      setOrganizationError("Please enter the name of your organization.");
    }
  }
  const selectedOption = watch("industry");

  const getIndustryValue = (industry: any) => {
    const values = ["Athletic & Sports Organizations", "Corporate / Employee Wellness", "Fire / Law Enforcement", "Health & Fitness", "Occupational Health and Safety", "Rehabilitation / Physical Therapy"]
    const industryExists = values.find((value) => value === industry);
    return industryExists ? industry : "Other";
  }

  return (
    <>
      <div className={`${COMPONENT_CLASS}__header`}>
        Create Organization
      </div>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem', textAlign: "center" }}>
        Enter the name of your business, organization, team or corporation
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${FORM_COMPONENT_CLASS}__sign-up-content`} style={{ height: '345px' }}>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.organizationName && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                Please enter the name of your organization.
              </div>
            )}
            {organizationError && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {organizationError}
              </div>
            )}
            <TextTypeInput
              id='organizationName'
              label='Organization Name'
              hideLabel={true}
              inputClassName={`c-input__input ${errors.organizationName && `${FORM_COMPONENT_CLASS} -showerror`}`}
              type='text'
              registerHook={register}
              registerOptions={{
                value: state.temp.organization ? state.temp.organization.name : "",
                required: true
              }}
              errorState={errors.organizationName}
            />
          </div>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.industry && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                Please select your industry.
              </div>
            )}
            <select id="industry"
              className={`c-input__input ${errors.industry && `${FORM_COMPONENT_CLASS} -showerror`}`}
              {...register("industry", {
                required: true,
                value: state.temp.organization ? getIndustryValue(state.temp.organization.industry) : 'Athletic & Sports Organizations'
              })}
              style={{ display: 'flex' }}
            >
              <option value="Athletic & Sports Organizations">Athletic & Sports Organizations</option>
              <option value="Corporate / Employee Wellness">Corporate / Employee Wellness</option>
              <option value="Fire / Law Enforcement">Fire / Law Enforcement</option>
              <option value="Health & Fitness">Health & Fitness</option>
              <option value="Occupational Health and Safety">Occupational Health and Safety</option>
              <option value="Rehabilitation / Physical Therapy">Rehabilitation / Physical Therapy</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {selectedOption === "Other" && (
            <div className={`${FORM_COMPONENT_CLASS}__field`}>
              {errors.otherIndustry && (
                <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                  Please enter the industry.
                </div>
              )}
              <TextTypeInput
                id='otherIndustry'
                label='Industry'
                hideLabel={true}
                inputClassName={`c-input__input ${errors.otherIndustry && `${FORM_COMPONENT_CLASS} -showerror`}`}
                registerHook={register}
                registerOptions={{
                  required: true,
                  value: state.temp.organization ? state.temp.organization.industry : ''
                }}
                errorState={errors.otherIndustry} />
            </div>
          )}

        </div>
      </form>
      <BaseCardLayoutActions submitButton={true} backButton={true} onBack={onBackClick} onSubmit={handleSubmit(onSubmit)} submitButtonText={state.temp.product === 'STARTER' ? "Create Account" : "Continue"}></BaseCardLayoutActions>
    </>
  );
}

export default CreateOrganization;