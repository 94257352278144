import { ListOptions } from "./interfaces/list-options";

export const BiologicalSexOptions: ListOptions[] = [
    {
        value: "Female",
        label: "Female",
    },
    {
        value: "Male",
        label: "Male",
    },
]
