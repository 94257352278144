/* eslint-disable react-hooks/exhaustive-deps */

import { Button } from "../button/button";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import { useEffect, useState } from "react";
import { query, collection, where, getDocs, getFirestore } from 'firebase/firestore';
import { Modal } from "../modal/modal";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Organization } from "../../models/interfaces/organization";
import OrganizationService from "../../utilities/services/organization-service";
import CheckoutForm from "./checkout-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { PlanType } from "../../models/enumerations/plan-type";
import SubscriptionService from "../../utilities/services/subscription-service";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import { Carousel } from "react-responsive-carousel";
import { PlanId } from "../../models/enumerations/plan-id";
import { PlanPrice } from "../../models/enumerations/plan-price";
import PlansTableContent from "./plans-table-content";
import { AddOnId } from "../../models/enumerations/add-on-id";

interface ChangePlanProps {
    onSubmitClick: () => void;
}


const ChangePlan: React.FC<ChangePlanProps> = ({ onSubmitClick }) => {
    const COMPONENT_CLASS = "c-change-plan";

    const [paymentCycle, setPaymentCycle] = useState<PaymentCycle>(PaymentCycle.Monthly);
    const { state } = useAuthState();
    const [organization, setOrganization] = useState<Organization>();
    const [, setIsValidating] = useState<boolean>(false);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
    const [downgradeModalOpen, setDowngradeModalOpen] = useState<boolean>(false);
    const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState<boolean>(false);
    const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState<boolean>(false);
    const [isUpgrading] = useState(false);
    const [isDowngrading] = useState(false);
    const [products, setProducts] = useState<any>();
    const db = getFirestore();

    enum SubscriptionCarouselIndex {
        Starter = 0,
        Business = 1,
        Enterprise = 2,
    }
    const [carouselItemIndex, setCarouselItemIndex] = useState(SubscriptionCarouselIndex.Starter);
    const [isPaymentSuccess] = useState(false);
    const [isPaymentFail] = useState(false);
    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
    const history = useHistory();
    const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
    const [addOnPrices, setAddOnPrices] = useState<{ user?: string, license?: string, organization?: string }>();

    const commonStyles = {
        fontSize: '12px',
        fontWeight: 'bold',
        marginLeft: '0.5rem',
        color: 'black',
        width: 'fit-content',
        padding: '6px 8px',
        borderRadius: '4px'
    };

    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, 'products'),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {

                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })

                const priceDoc = priceQuerySnapshot.docs[0];

                if (productDoc.id === AddOnId.User.toString() || productDoc.id === AddOnId.License.toString() || productDoc.id === AddOnId.Organization.toString()) {
                    const addOnPrice = (priceDoc.data().unit_amount / 100).toFixed(2);

                    if (productDoc.id === AddOnId.User.toString()) {
                        setAddOnPrices((prices) => ({ ...prices, ...{ user: addOnPrice } }));
                    }
                    else if (productDoc.id === AddOnId.License.toString()) {
                        setAddOnPrices((prices) => ({ ...prices, ...{ license: addOnPrice } }));
                    }
                    else if (productDoc.id === AddOnId.Organization.toString()) {
                        setAddOnPrices((prices) => ({ ...prices, ...{ organization: addOnPrice } }));
                    }
                }
            });
            await setProducts(products);
        }
        getProductsAndPrices();
    }, [])
    const stuff = async () => {
        const db = getFirestore();
        // create a query object
        const q = query(
            collection(db, 'products'),
            where('active', '==', true)
        );

        const querySnapshot = await getDocs(q);

        // for each product, get the product price info
        const productsPromises = querySnapshot.docs.map(async (productDoc) => {
            let productInfo = productDoc.data();

            // fetch prices subcollection per product
            const pricesCollection = collection(productDoc.ref, 'prices');
            const priceQuerySnapshot = await getDocs(pricesCollection);

            // assume there is only one price per product
            const priceDoc = priceQuerySnapshot.docs[0];
            productInfo['priceId'] = priceDoc.id;
            productInfo['priceInfo'] = priceDoc.data();
            return productInfo;
        });

        // 'products' is an array of products (including price info)
        await Promise.all(productsPromises);


    }

    stuff();

    const onCarouselChange = (index: number, item: React.ReactNode) => {
        setCarouselItemIndex(index);
    };

    const updateOrg = async () => {
        // Get Organization
        if (!state.organization.id) {
            // Couldn't get organization id
            return;
        }
        const o = await OrganizationService.get(state.organization.id);
        const temp = await OrganizationSubscriptionService.getByOrganizationId(state.organization.id);
        const oSub = temp[0];

        if (o && oSub) {
            const subscription = await stripe.subscriptions.retrieve(oSub.stripePlanSubscription);

            // Hardcoded check if the current plan is Business (monthly)
            if (subscription.plan.id === products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval === "month").id) {
                o.plan = PlanType[PlanType.Business];
                o.userLimit = UserLimit.Business;
                o.mySymmioLicense = LicenseLimit.Business;
                OrganizationService.save(o);
                oSub.userLimit = UserLimit.Business;
                oSub.price = PlanPrice.BusinessMonthly;
                oSub.plan!.label = PlanType[PlanType.Business];
                oSub.plan!.value = PlanType.Business;
                oSub.paymentCycle!.label = PaymentCycle[PaymentCycle.Monthly];
                oSub.paymentCycle!.value = PaymentCycle.Monthly;
                oSub.mySymmioLicense = LicenseLimit.Business
                await OrganizationSubscriptionService.save(oSub);
            }
            // Hardcoded check if the current plan is Business (yearly)
            else if (subscription.plan.id === products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval !== "month").id) {
                o.plan = PlanType[PlanType.Business];
                o.userLimit = UserLimit.Business;
                o.mySymmioLicense = LicenseLimit.Business;
                OrganizationService.save(o);
                oSub.userLimit = UserLimit.Business;
                oSub.price = PlanPrice.BusinessYearly;
                oSub.plan!.label = PlanType[PlanType.Business];
                oSub.plan!.value = PlanType.Business;
                oSub.paymentCycle!.label = PaymentCycle[PaymentCycle.Yearly];
                oSub.paymentCycle!.value = PaymentCycle.Yearly;
                oSub.mySymmioLicense = LicenseLimit.Business
                await OrganizationSubscriptionService.save(oSub);
            }
        }

    }

    useEffect(() => {
        const openModal = async () => {
            if (paymentSuccessful) {
                await updateOrg();
                setUpgradeModalOpen(false);
                setPaymentSuccessModalOpen(true);
                setPaymentSuccessful(false);
            }
            else if (paymentFailed) {
                // setUpgradeModalOpen(false);
                // setGetPayment(false);
                setPaymentFailedModalOpen(true);
                setPaymentFailed(false);
            }
        }
        openModal();

    }, [paymentFailed, paymentSuccessful])

    useEffect(() => {
        if (state.organization?.id == null) {
            return;
        }

        OrganizationService.getSnapshot(state.organization?.id, (organization: Organization) => {
            setOrganization(organization);
            // setIsLoading(false);

        });

    }, [state.organization?.id]);

    const starterCard = (
        <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#FFF4E4' }}>
            <h4 className={`${COMPONENT_CLASS}__title`}>Starter</h4>
            <h5 style={{ textAlign: "center", height: '8rem' }}>For businesses looking to demo our health & wellness solution.</h5>
            <div className={`${COMPONENT_CLASS}__header card`}>
                <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
                    Free
                </div>
                <div className={`${organization?.plan !== "Starter" ? `${COMPONENT_CLASS}__transparent` : ""}`}>
                    <Button
                        buttonText={`${organization?.plan === "Starter" ? "Current Plan" : `${organization?.plan === "Enterprise" ? "Contact Us" : "Change Plan"}`}`}
                        disabled={true}
                        onClick={() => {
                            // Can only ever downgrade
                            if (organization?.plan === PlanType[PlanType.Enterprise]) {
                                history.push("/settings/change-plan/contact-us?downgrade=true");
                            }
                            else {
                                setDowngradeModalOpen(true);
                            }

                        }} />
                </div>
            </div>
        </div>
    );

    const businessCard = (
        <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#DFF5F1' }}>
            <h4 className={`${COMPONENT_CLASS}__title`}>Business</h4>
            <h5 style={{ textAlign: "center", height: '8rem' }}>Built for businesses looking to scale their health & wellness offerings.</h5>
            <div className={`${COMPONENT_CLASS}__header card`}>
                {paymentCycle === PaymentCycle.Monthly ? (
                    <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
                        ${PlanPrice.BusinessMonthly}
                        <p style={{ fontWeight: '100', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}> / Month </p>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '6rem' }}>
                        <div style={{ display: 'flex' }}>
                            ${PlanPrice.BusinessYearly}
                            <p style={{ fontWeight: '100', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}> / Year </p>
                        </div>
                        <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}>
                            ${PlanPrice.BusinessYearly / (1 - 0.15)} / Year
                        </p>
                    </div>
                )}
                <Button
                    buttonText={`${organization?.plan === "Business" ? "Current Plan" : `${organization?.plan === "Enterprise" ? "Contact Us" : "Upgrade"}`}`}
                    disabled={organization?.plan === PlanType[PlanType.Business]}
                    onClick={() => {
                        if (organization?.plan === PlanType[PlanType.Starter]) {
                            setUpgradeModalOpen(true)
                        }
                        else {
                            // This is when they are enterprise and want to downgrade. We will have to use the "Contact Us" option
                            history.push("/settings/change-plan/contact-us?downgrade=true");
                        }
                    }} />
            </div>
        </div>
    );

    const enterpriseCard = (
        <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#DEF3FF' }}>
            <h4 className={`${COMPONENT_CLASS}__title`}>Enterprise</h4>
            <h5 style={{ textAlign: "center", height: '8rem' }}>Tailored health & wellness solution with custom pricing.</h5>
            <div className={`${COMPONENT_CLASS}__header card`}>
                <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
                    Let's Talk
                </div>
                <Button
                    buttonText={`${organization?.plan === "Enterprise" ? "Current Plan" : "Contact Us"}`}
                    disabled={organization?.plan === "Enterprise"}
                    onClick={() => {
                        // We need to know the logic for when the contact us is clicked
                        history.push("/settings/change-plan/contact-us?upgrade=true");
                    }} />
            </div>
        </div>
    );

    return (
        <>
            <Modal
                isOpen={paymentSuccessModalOpen}
                isLoading={isPaymentSuccess}
                onClose={() => {
                    setPaymentSuccessModalOpen(false);
                    history.push('/settings?active=Billing');
                }}
                defaultCenterModal={true}
                submitButtonText="Continue"
                onSubmit={() => {
                    setPaymentSuccessModalOpen(false);
                    history.push('/settings?active=Billing');
                }}
                title="Plan Upgraded">
                <div className={`${COMPONENT_CLASS}__modal-wrapper`}>
                    <div className={`${COMPONENT_CLASS}__modal-content`}>
                        <p><b>Thank you upgrading to the Symmio Business Plan!</b></p>
                        <p>You will receive an email confirmation shortly.</p>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={upgradeModalOpen}
                isLoading={isUpgrading}
                onClose={() => {
                    setUpgradeModalOpen(false);
                }}
                title="Upgrade Your Plan">
                {stripePromise &&
                    <>
                        <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
                            <CheckoutForm isValidating={setIsValidating} setUpgradeModalOpen={setUpgradeModalOpen} isUpgrading={true} paymentSuccessful={setPaymentSuccessful} paymentFailed={setPaymentFailed} onChangePlan={() => { setUpgradeModalOpen(false); }} returnUrl={`/settings/upgrade/${state.organization.id}`} paymentCycle={paymentCycle} purchasePrice={paymentCycle === PaymentCycle.Yearly ? PlanPrice.BusinessYearly : PlanPrice.BusinessMonthly} products={products} />
                        </Elements>
                        <Modal
                            isOpen={paymentFailedModalOpen}
                            isLoading={isPaymentFail}
                            onClose={() => {
                                setPaymentFailedModalOpen(false);
                            }}
                            title="Oops. Payment Decline"
                            defaultCenterModal={true}
                            submitButtonText="Continue"
                            onSubmit={() => {
                                setPaymentFailedModalOpen(false);
                            }}>
                            <div className={`${COMPONENT_CLASS}__modal-wrapper`}>
                                <div className={`${COMPONENT_CLASS}__modal-content`}>
                                    <p><b>Your payment method was declined</b></p>
                                    <p>We tried to charge your card but, something went wrong.</p>
                                    <p>Please update your payment method.</p>

                                </div>
                            </div>
                        </Modal>
                    </>
                }

            </Modal>

            <Modal
                isOpen={downgradeModalOpen}
                isLoading={isDowngrading}
                onClose={() => {
                    setDowngradeModalOpen(false);
                }}
                title="Downgrade Plan"
                defaultModalActions={true}
                onSubmit={async () => {
                    if (state.user)
                        await SubscriptionService.handleCancelSubscription(state.user?.organizationId, state.user);
                }}
                onCancel={() => {
                    setDowngradeModalOpen(false);
                }}
                submitButtonText="Confirm and Cancel Plan">
                <div>
                    <div>
                        <p>Are you sure you want to cancel your <b>Business</b> subscription plan?</p>
                    </div>
                    <br></br>
                    <div>
                        <p>How your account will change:</p>
                        <ul style={{ listStyle: "disc", paddingLeft: "35px" }}>
                            <li>Your user limit will be reduced from 50 to 5 users.</li>
                            <li>Your next billing cycle on September 4th, 2023 will be cancelled</li>
                        </ul>
                    </div>
                </div>
            </Modal>
            <div className={`grid place-items-center h-screen c-base-card-layout`}>
                <div className={`${COMPONENT_CLASS}__card-content-container`}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        Select your plan
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="c-payment-cycle-toggle">
                            <button type="button" onClick={() => setPaymentCycle(PaymentCycle.Monthly)} className={`c-payment-cycle-toggle__payment-cycle-monthly ${paymentCycle === PaymentCycle.Monthly ? "active" : ""}`}>
                                Monthly
                            </button>
                            <button type="button" onClick={() => setPaymentCycle(PaymentCycle.Yearly)} className={`c-payment-cycle-toggle__payment-cycle-yearly ${paymentCycle === PaymentCycle.Yearly ? "active" : ""}`}>
                                Yearly
                                <span
                                    style={{
                                        background: 'rgba(0, 223, 188, 1)',
                                        ...commonStyles,
                                    }}
                                >
                                    15% OFF
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__plans-info-desktop`}>
                        <div className={`${COMPONENT_CLASS}__container`}>
                            {starterCard}
                            {businessCard}
                            {enterpriseCard}
                        </div>
                        <PlansTableContent
                            carouselItemIndex={carouselItemIndex}
                            addOnPrices={addOnPrices}
                            isMobile={false}
                        />
                    </div>
                    <div className={`${COMPONENT_CLASS}__plans-info-mobile`}>
                        <Carousel showArrows={true} showStatus={false} onChange={onCarouselChange}>
                            <div className={`${COMPONENT_CLASS}__card-wrapper`}>
                                {starterCard}
                            </div>
                            <div className={`${COMPONENT_CLASS}__card-wrapper`}>
                                {businessCard}
                            </div>
                            <div className={`${COMPONENT_CLASS}__card-wrapper`}>
                                {enterpriseCard}
                            </div>
                        </Carousel>

                        <PlansTableContent
                            carouselItemIndex={carouselItemIndex}
                            addOnPrices={addOnPrices}
                            isMobile={true}
                        />
                    </div >
                </div>
            </div>
        </>
    );
}

export default ChangePlan;

