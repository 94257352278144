import { RouteUtils } from "andculturecode-javascript-core";
import axios from "axios";
import { isEmpty } from "../generic-utils";
import { LocalStorageUtils } from "../local-storage-utils";

const { REACT_APP_API_URL, REACT_APP_API_API_KEY } = process.env;

if (!REACT_APP_API_URL || REACT_APP_API_URL === "") {
    throw new Error("The REACT_APP_API_URL needs to be set in the .env file");
}

let host: string = REACT_APP_API_URL;

let config = {
    headers: {
        "x-api-key": REACT_APP_API_API_KEY,
    },
};

// ---------------------------------------------------------------------------------------------
// #region Public Functions
// ---------------------------------------------------------------------------------------------

const ServiceBase = {
    /**
     * Sets the base url used to make API calls
     *
     * @param baseUrl The base url to use for axios calls, do not end with a /
     */
    setBaseUrl(baseUrl: string) {
        host = baseUrl;
    },

    /**
     * Calls the Symmio API via the GET verb and returns a serialized object
     *
     * @param {string} resourceEndpoint The complete resource endpoint not including the base Url
     * @param {number | undefined} expiration The number in milliseconds that we should expire this entry from cache.
     * If not provided, this call will not be cached.
     * @returns The object type defined in TRecord
     */
    get<TRecord, TPathParams = undefined, TQueryParams = undefined>(
        resourceEndpoint: string,
        expiration: number | undefined = undefined
    ) {
        return async (pathParams?: TPathParams, queryParams?: TQueryParams) => {
            const endpointTransform = RouteUtils.getUrlFromPath(
                resourceEndpoint,
                pathParams,
                queryParams
            );

            // check cache
            if (expiration) {
                var cache = await LocalStorageUtils.get<TRecord>(
                    endpointTransform
                );
                if (cache) {
                    return cache;
                }
            }

            var response = await _get<TRecord, TPathParams, TQueryParams>(
                host + endpointTransform,
                pathParams,
                queryParams
            );

            // set cache
            if (expiration && !isEmpty(response)) {
                LocalStorageUtils.set<TRecord>(
                    endpointTransform,
                    response,
                    expiration
                );
            }

            return response;
        };
    },

    /*
        TRecord: response type
    */
    list<TRecord, TQueryParams = null>(resourceEndpoint: string) {
        const url = `${host}${resourceEndpoint}`;
        return async (queryParams?: TQueryParams) =>
            await _list<TRecord>(url, null, queryParams);
    },

    /*
        TRecord: response type
        TData: data type (defaults to TRecord)
    */
    post<TRecord, TData, TQueryParams = unknown>(resourceEndpoint: string) {
        const url = `${host}${resourceEndpoint}`;
        return async (data?: TData, queryParams?: TQueryParams) =>
            await _post<TRecord, TData>(url, data, queryParams);
    },
};

// #endregion Public Functions

// ---------------------------------------------------------------------------------------------
// #region Private Functions
// ---------------------------------------------------------------------------------------------

const _get = async function <
    TRecord,
    TPathParams = undefined,
    TQueryParams = undefined
>(
    resourceEndpoint: string,
    pathParams?: TPathParams,
    queryParams?: TQueryParams
) {
    const result = await axios.get(resourceEndpoint, config);
    return result.data as TRecord;
};

const _list = async function <TRecord>(
    baseEndpoint: string,
    pathParams?: any,
    queryParams?: any
) {
    const url = RouteUtils.getUrlFromPath(
        baseEndpoint,
        pathParams,
        queryParams
    );
    const result = await axios.get(url, config);
    return result.data as TRecord;
};

const _post = async function <TRecord, TData>(
    resourceEndpoint: string,
    data?: TData,
    pathParams?: any,
    queryParams?: any
) {
    const url = RouteUtils.getUrlFromPath(
        resourceEndpoint,
        pathParams,
        queryParams
    );
    const result = await axios.post(url, data, config);
    return result.data as TRecord;
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ServiceBase };

// #endregion Exports
