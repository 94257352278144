import { Button } from "../button/button";
import { useState } from "react";
import { Deeplink } from "../../models/interfaces/deeplink";
import {
    CheckCircleIcon,
    ClipboardIcon,
} from '@heroicons/react/outline';
import { Modal } from "../modal/modal";
import './deeplink-search-hits.styles.scss';
import { UserTag } from "../../models/interfaces/user-tag";
import LinkInfo from "../lead-links/lead-link-view";
import { SymmioAccessLinkTypeLabels } from "../../models/symmio-access-types";

interface DeeplinksSearchHitsProps {
    deeplinks: Deeplink[];
    editClickHandler: (deeplink: Deeplink) => void;
    deleteClickHandler: (deeplink: Deeplink) => void;
}

const DeeplinksSearchHits: React.FC<DeeplinksSearchHitsProps> = (props) => {

    const { deeplinks, editClickHandler, deleteClickHandler } = props;
    const [openQRModal, setOpenQRModal] = useState(false);
    const [copiedLinkId, setCopiedLinkId] = useState('');
    const [modalDeeplink, setModalDeeplink] = useState<Deeplink | undefined>();

    const handleQRClick = (deeplink: Deeplink) => {
        setModalDeeplink(deeplink);
        setOpenQRModal(true);
    }

    const handleLinkCopy = (deeplink: Deeplink | undefined) => {

        if (!deeplink?.url || !deeplink?.id) {
            console.error('Copy link failed. No id or URL available');
            return;
        }
        // TODO : Global exception handling
        // https://app.clickup.com/t/2219993/FMS-1236

        navigator.clipboard.writeText(deeplink.url);
        setCopiedLinkId(deeplink.id);
        setTimeout(() => {
            setCopiedLinkId('');
        }, 2000);
    }


    function tagsToMarkup(tags: UserTag[]) {
        let tagArray: string[] = [];

        for (var i = 0; i < tags.length; i++) {
            tagArray.push(tags[i].name);
        }

        let tagString = tagArray.join(", ");

        return (
            <>
                {tagString}
            </>
        );
    }

    return deeplinks.length ? (
        <>
            {deeplinks.map((deeplink, index) => {
                const usersCreated = deeplink.used ?? 0;
                const className = (deeplink.status === 'active') ? '' : 'disabled';
                const linkLimit = deeplink.limit ?? "unlimited";

                return (
                    <tr className={`${className} table-row-container`} onClick={() => { handleQRClick(deeplink) }} key={`deeplink_${index}`}>
                        <td>
                            <div className={'icon-container'} onClick={(event) => {
                                event.stopPropagation();
                                handleLinkCopy(deeplink);
                            }}>
                                {(copiedLinkId === deeplink.id) ?
                                    <CheckCircleIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                    : <ClipboardIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                }
                            </div>
                        </td>
                        <td><strong>{deeplink.name}</strong></td>
                        <td><strong>{deeplink.linkType ? SymmioAccessLinkTypeLabels[deeplink.linkType] : ""}</strong></td>
                        <td><strong className="show-on-mobile mr-1">GROUP:</strong>{deeplink.groupName}</td>
                        <td>{// if
                            (deeplink.tags && deeplink.tags.length > 0) &&
                            <>
                                <strong className="show-on-mobile mr-1">TAGS:</strong>
                                {tagsToMarkup(deeplink.tags)}
                            </>
                        }</td>
                        <td><strong className="show-on-mobile mr-1">USED/LIMIT:</strong>{`${usersCreated}/${linkLimit}`}</td>
                        <td>
                            <div className='buttons-container'>
                                <Button
                                    type="table-action"
                                    buttonText="Edit"
                                    buttonStyle="white"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        editClickHandler(deeplink);
                                    }}
                                />
                                <Button
                                    type="table-action"
                                    buttonText="Delete"
                                    buttonStyle="white" onClick={(event) => {
                                        event.stopPropagation();
                                        deleteClickHandler(deeplink)
                                    }} />
                            </div>
                        </td>
                    </tr>
                )
            })}
            <script>
                <Modal
                    isOpen={openQRModal}
                    isLoading={false}
                    onClose={() => { setOpenQRModal(false) }}
                    title="QR Code">
                    <LinkInfo linkData={modalDeeplink}></LinkInfo>
                </Modal>
            </script>
        </>
    ) : (
        <td className={`pt-6`} colSpan={8}>
            <div className="flex justify-center items-center h-full">
                <span className="text-center">
                    No invite links created, to create a link, click "+Add Link"
                </span>
            </div>
        </td>
    )
}

export default DeeplinksSearchHits;
