/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Loader } from "../loader/loader";
import { Team } from "./team";
import { Clients } from "./clients";
import { Organization } from "../../models/interfaces/organization";
import OrganizationService from "../../utilities/services/organization-service";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";

const COMPONENT_CLASS = "c-user-list";

const UserList: React.FC = () => {
    const db = getFirestore();
    const [products, setProducts] = useState<any>();
    const [isLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("Users");
    const [organization, setOrganization] = useState<Organization>();
    const { state } = useAuthState();
    const tabs = [
        "Users",
        "Team",
        // "In-App Contact Content"
    ];


    const labelRefs: { [key: string]: React.RefObject<HTMLLabelElement | null> } = {};
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLineWidth = () => {
        const tabLabel = labelRefs[activeTab]?.current;
        const lineWidth = tabLabel ? tabLabel.getBoundingClientRect().width : 0;
        const leftPosition = tabLabel ? tabLabel.offsetLeft : 0;
        const highlightLine = document.getElementById('highlight-line');
        if (highlightLine) {
            highlightLine.style.width = `${lineWidth}px`;
            highlightLine.style.left = `${leftPosition}px`;
        }
    }

    // Get products/prices
    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, 'products'),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {
                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })
            });
            setProducts(products);
        }
        getProductsAndPrices();
    }, [])

    useEffect(() => {

        getOrganization();
    }, [])

    useEffect(() => {
        updateLineWidth();
    }, [activeTab, updateLineWidth]);

    const getOrganization = async () => {
        if (state.organization.id) {
            await OrganizationService.get(state.organization.id).then((org) => {
                if (org) {
                    setOrganization(org);
                }
            })
        }
    }

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoading} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>Users</h1>
            </div>
            <div className="c-tabs-container">
                <div className="c-tabs">
                    {tabs.map((tab) => (
                        <div
                            key={tab}
                            className={`c-tab-label ${activeTab === tab ? 'active' : ""}`}
                            onClick={() => handleTabClick(tab)}
                        > <label ref={(ref) => (labelRefs[tab] = { current: ref })} id={tab}>{tab}</label> </div>
                    ))}
                </div>

                <div className="c-highlight-container">
                    <div className="c-subline"></div>
                    <div className="c-highlight-line" id="highlight-line"></div>
                </div>
            </div>

            <div className="c-tab-content">
                {activeTab === "Users" && organization && <Clients organization={organization} setOrganization={getOrganization} products={products} />}
                {activeTab === "Team" && organization && <Team organization={organization} setOrganization={getOrganization} products={products} />}

            </div>
        </div>);
}

export default UserList;
