import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
);

export default searchClient;

export const SearchIndexes = {
    usersByNameAsc: "users",
    usersByNameDesc: "users_name_desc",
    usersByGroupAsc: "users_group_asc",
    usersByGroupDesc: "users_group_desc",
    usersByRolesAsc: "users_roles_asc",
    usersByRolesDesc: "users_roles_desc",
    usersByStatusAsc: "users_status_asc",
    usersByStatusDesc: "users_status_desc",
    usersBySymmioAccessAsc: "users_symmio_access_asc",
    usersBySymmioAccessDesc: "users_symmio_access_desc",
    organizationsByNameAsc: "organizations",
    organizationsByNameDesc: "organizations_name_desc",
    organizationsByUsersAsc: "organizations_users_asc",
    organizationsByUsersDesc: "organizations_users_desc",
    organizationsByStatusAsc: "organizations_status_asc",
    organizationsByStatusDesc: "organizations_status_desc",
    organizationsByPlanAsc: "organizations_plan_asc",
    organizationsByPlanDesc: "organizations_plan_desc",
    organizationsByAccountManagerAsc: "organizations_account_manager_asc",
    organizationsByAccountManagerDesc: "organizations_account_manager_desc",
};

export type SearchIndexesType = {
    usersByNameAsc: string;
    usersByNameDesc: string;
    usersByGroupAsc: string;
    usersByGroupDesc: string;
    usersByRolesAsc: string;
    usersByRolesDesc: string;
    usersByStatusAsc: string;
    usersByStatusDesc: string;
    usersBySymmioAccessAsc: string;
    usersBySymmioAccessDesc: string;
    organizationsByNameAsc: string;
    organizationsByNameDesc: string;
    organizationsByUsersAsc: string;
    organizationsByUsersDesc: string;
    organizationsByStatusAsc: string;
    organizationsByStatusDesc: string;
    organizationsByPlanAsc: string;
    organizationsByPlanDesc: string;
    organizationsByAccountManagerAsc: string;
    organizationsByAccountManagerDesc: string;
};
