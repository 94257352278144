import { Organization } from "../models/interfaces/organization";
import { User } from "../models/interfaces/user";

interface hubspotUserDataRow {
    firstName: string,
    lastName: string,
    emailAddress: string,
    symmioRole: string,
    orgName: string,
}

export default class DataExportUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    private static getUserRole = (roles: string[] | null | undefined): string => {
        if (roles && roles.length > 0) {
            if (roles[0] !== null && roles[0] !== '') {
                return roles[0];
            }
        }
        return 'none';
    };

    private static capitalizeName = (name: string): string => {
        if (name === '') {
            return '';
        }

        let capped = name.charAt(0).toUpperCase();
        capped += name.substring(1);
        return capped;
    }

    public static getHubspotUserDataRows(users: User[], orgs: Organization[], onlyRoles: boolean): hubspotUserDataRow[] {
        const rows: hubspotUserDataRow[] = [];

        users.forEach((u) => {
            if (u.organizationId && u.email) {
                const org = orgs.find((o) => o.id === u.organizationId);
                
                if (org?.testingOrg) {
                    return;
                }

                if (org) {
                    const role = this.getUserRole(u.roles);
                    const hubspotUser: hubspotUserDataRow = {
                        firstName: this.capitalizeName(u.firstName ?? ''),
                        lastName: this.capitalizeName(u.lastName ?? ''),
                        emailAddress: u.email,
                        symmioRole: this.getUserRole(u.roles),
                        orgName: org.name ?? '',
                    }
                    if (onlyRoles) {
                        if (role !== 'none') {
                            rows.push(hubspotUser);
                        }
                    } else {
                        if (role === 'none') {
                            rows.push(hubspotUser);
                        }
                    }
                }
            }
        });

        return rows;
    };

    public static generateHubspotUserCsv(rows: hubspotUserDataRow[]): string {
        let csv = '';

        const HEADERS = 'firstName,lastName,emailAddress,symmioRole,orgName\n';

        csv += HEADERS;

        rows.forEach((r) => {
            let row = '';
            row += r.firstName + ',';
            row += r.lastName + ',';
            row += r.emailAddress + ',';
            row += r.symmioRole + ',';
            row += r.orgName + '\n';
            csv += row;
        });
        
        return csv;
    }

    // #endregion Public Methods
}
