export default class BaseError extends Error {
    private _payloads: any[] | null = null;
    private _methodsCalled: string[] | null = null;
    private _sourceBaseError: BaseError | null = null;
    private _sourceErrorIsBaseError: boolean = false;

    constructor(error: Error, methodCalled: string, payload: any = null) {
        super(error?.message ?? "(undefined error)");

        this._sourceErrorIsBaseError =
            error != null && error instanceof BaseError;
        if (this._sourceErrorIsBaseError) {
            this._sourceBaseError = error as BaseError;
        }

        if (methodCalled != null && methodCalled.length > 0) {
            this.addToMethodsCalled(methodCalled);
        }

        if (payload != null) {
            this.addToPayloads(payload);
        }
    }

    public getMethodsCalled(): string[] {
        return this._methodsCalled ?? [];
    }

    public getPayloads(): any[] {
        if (this._payloads == null) {
            return [];
        }

        // get unique values
        return this._payloads.filter((value, index, array) => array.indexOf(value) === index);
    }

    public getSourceName(): string {
        return "BaseError";
    }

    public toString(): string {
        return `${this.getSourceName()}: ${
            this.message
        }\nMethods: ${this.getMethodsCalled().join(
            "\nfrom "
        )}\nData: ${JSON.stringify(this.getPayloads())}`;
    }

    public toObject(): any {
        return {
            message: this.message,
            source: this.getSourceName(),
            methods: this.getMethodsCalled() ?? [],
            payloads: this.getPayloads() ?? [],
        };
    }

    private addToMethodsCalled(methodCalled: string) {
        if (this._methodsCalled == null) {
            const methodsCalled = this._sourceBaseError?.getMethodsCalled();
            if (methodsCalled != null) {
                this._methodsCalled = Object.assign([], methodsCalled);
            } else {
                this._methodsCalled = [];
            }
        }
        this._methodsCalled.push(methodCalled);
    }

    private addToPayloads(msg: string) {
        if (this._payloads == null) {
            const payloads = this._sourceBaseError?.getPayloads();
            if (payloads != null) {
                this._payloads = Object.assign([], payloads);
            } else {
                this._payloads = [];
            }
        }
        this._payloads.push(msg);
    }
}
