import { SymmioAccessType } from "./enumerations/symmio-access-type";

export interface SymmioAccessTypeOptionsInterface {
    value: SymmioAccessType;
    label: string;
}

export const SymmioAccessTypeOptions: SymmioAccessTypeOptionsInterface[] = [
    {
        value: SymmioAccessType.WebAccess,
        label: "No",
    }, {
        value: SymmioAccessType.AppLicense,
        label: "Yes",
    }
]

export const SymmioAccessLinkTypeOptions: SymmioAccessTypeOptionsInterface[] = [
    {
        value: SymmioAccessType.WebAccess,
        label: "Assessment Only",
    }, {
        value: SymmioAccessType.AppLicense,
        label: "Assessment + Wellness Program",
    }
]

export const SymmioAccessLinkTypeLabels = {
    [SymmioAccessType.WebAccess]: "Web Assessment Only",
    [SymmioAccessType.AppLicense]: "Wellness Program",
}