import { SetStateAction } from "react";
import { AppContentType } from "../../models/enumerations/app-content-type";

const COMPONENT_CLASS = "c-app-content-type-toggle";

interface AppContentTypeToggleProps {
    appContentType: AppContentType;
    setAppContentType: React.Dispatch<SetStateAction<AppContentType>>;
}

const AppContentTypeToggle: React.FC<AppContentTypeToggleProps> = (props: AppContentTypeToggleProps) => {
    const {
        appContentType,
        setAppContentType,
    } = props;

    return (
        <div className={COMPONENT_CLASS}>
            <button type="button" onClick={() => setAppContentType(AppContentType.PAIN)} className={`${COMPONENT_CLASS}__app-content-pain ${appContentType === AppContentType.PAIN ? "active" : ""}`}>
                Healthcare (Pain)
            </button>
            <button type="button" onClick={() => setAppContentType(AppContentType.BEHAVIORAL_HEALTH)} className={`${COMPONENT_CLASS}__app-content-behavioral ${appContentType === AppContentType.BEHAVIORAL_HEALTH ? "active" : ""}`}>
                Behavioral Health
            </button>
        </div>
    )
};

export default AppContentTypeToggle;