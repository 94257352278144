import React from "react";
import { Dialog } from "@headlessui/react";
import logo from "../../assets/images/symmio-logos/symmio-icon-logo-light.png";

export interface ModalProps {
    onClose: Function,
    open: boolean,
    children: React.ReactElement,
    message: string,
    title: string,
    showHeader: boolean,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents the modal that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const Modal: React.FC<ModalProps> = (props) => {
    const {
        onClose,
        open,
        children,
        message,
        title,
        showHeader,
    } = props;

    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{ width: '978px', borderRadius: '30px' }}>
                        <div className={`${COMPONENT_CLASS}__header`} style={{ display: showHeader ? 'auto' : 'none' }}>
                            <img src={logo} alt="Logo" className={`${COMPONENT_CLASS}__header-image`} />
                        </div>
                        <div className="flex-block justify-center" style={{ margin: '3em' }} >
                            {title && <h1 className="block" style={{ padding: '105px 0px 48px 0px', textAlign: 'center' }}>{title}</h1>}
                            {message && <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}</p>}
                            <div style={{ marginTop: '2em' }}>
                                {children}
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default Modal;