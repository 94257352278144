
export default class InactivityTimer {
    timeout: number;
    onTimeout: Function;
    onExpired: Function;
    timeoutTracker: NodeJS.Timeout | undefined;
    eventHandler: any;
    interval: any;

    constructor( timeout: number, onTimeout: Function, onExpired: Function ) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;
        this.onExpired = onExpired;
    
        const expiredTime = parseInt(localStorage.getItem("_expiredTime") || "0", 10);
        if (expiredTime > 0 && expiredTime < Date.now()) {
          onExpired();
          return;
        }
    
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
      }

      startInterval() {
        this.updateExpiredTime();
    
        this.interval = setInterval(() => {
          const expiredTime = parseInt(
            localStorage.getItem("_expiredTime") || "0",
            10
          );
          // If we've passed the expired time
          if (expiredTime < Date.now()) {
            if (this.onTimeout) {
              // Call onTimeout and open the modal.
              this.onTimeout();
              this.cleanUp();
            }
          }
        }, 1000);
      }
    
      updateExpiredTime() {
        if (this.timeoutTracker) {
          clearTimeout(this.timeoutTracker);
        }
        localStorage.setItem("_expiredTime", (Date.now() + this.timeout * 1000).toString());
        
      }
    
      tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
      }
    
      cleanUp() {
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
      }

}