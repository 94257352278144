import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { UserStatus } from "../../models/enumerations/user-status";
import DuplicateEntityFoundException from "../../models/exceptions/duplicate-entity-found";
import EntityNotFoundException from "../../models/exceptions/entity-not-found";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Organization } from "../../models/interfaces/organization";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";
import TagService from "./tag-service";
import UniqueUserInviteLinksService from "./unique-user-invite-link-service";
const COLLECTION_NAME = "users";


// -----------------------------------------------------------------------------------------
// #region Notifications Methods
// -----------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------
// #region Notifications Methods
// -----------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new user to the users collection
 * @param {User} user - The user that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<User>} A promise to the newly added user
 */
const add = async (user: User, currentUser: User | null = null) => {
    return FirestoreService.add<User>(COLLECTION_NAME, user, currentUser);
};
 
/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the first user found by condition and sort order
 * @param {FirestoreCondition[]} conditions
 * @param {FirestoreOrder[]} order
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const findFirstBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = []) => {
    const results = await FirestoreService.getBy<User>(COLLECTION_NAME, conditions, order, 1);

    if (results.length >= 1) {
        return results[0];
    }

    return undefined;
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<User>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<User>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getCountBy = async (conditions: FirestoreCondition[]) => {
    return FirestoreService.getCountBy(COLLECTION_NAME, conditions);
};

/**
 * Get all of the users stored in the database
 * @returns {Promise<User[]>} A promise for the collection of Users
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<User>(COLLECTION_NAME, order);
};

const getAllByOrganizationId = async (id: string) => {
    return getBy(
        [{
            field: 'organizationId',
            operator: '==',
            value: id,
        }],
        []
    );
};

const getAllSuperAdmins = async () => {
    return getBy(
        [{
            field: 'isSuperAdmin',
            operator: '==',
            value: true,
        }],
        []
    );
}

/**
 * Get a snapshot of the specific user to see when it changes
 * @param {string} id - Id of the user document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<User>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the users to see when it changes
 * @param {string} id - Id of the user document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    listener: Function
) => {
    return FirestoreService.getSnapshotBy<User>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified user stored in the database
 * @param {User} user - The user that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<User>} A promise for the user that is being updated
 */
const save = async (user: User, currentUser: User | null = null) => {
    return FirestoreService.save<User>(COLLECTION_NAME, user, currentUser);
};

/**
 * Update the specified user stored in the database
 * @param {User} user - The user that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<User>} A promise for the user that is being updated
 */
const update = async (user: User, currentUser: User | null = null) => {
    return FirestoreService.update<User>(COLLECTION_NAME, user, currentUser);
};

const fixTagIds = async (): Promise<number> => {
    let count = 0;

    // get all users
    const users = await getBy([
        {
            field: "organizationId",
            operator: "!=",
            value: "",
        },
    ]);

    // loop all users
    for (let i = 0; i < users.length; i++) {
        // requery to get current record
        const user = users[i];
        const userMatches = await getBy([{ field: "email", operator: "==", value: user.email }]);
        if (userMatches.length === 0) {
            continue;
        }

        // no tags
        const userMatch = userMatches[0];
        if ((userMatch.tagIds ?? []).length === 0 && (userMatch.tags ?? []).length === 0) {
            continue;
        }

        // update tagIds
        userMatch.tagIds = userMatch.tags?.map((t) => t.id) || [];
        update(userMatch);
        count++;
    }

    // loop all tags
    const tags = await TagService.getAll();
    for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];

        // get counts
        const oldCount = tag.userCount;
        tag.userCount = await getCountBy([
            {
                field: "tagIds",
                operator: "array-contains",
                value: tag.id,
            },
        ]);

        // save count
        const sameCount = (oldCount ?? 0) === tag.userCount;
        if (!sameCount) {
            await TagService.save(tag);
        }
    }

    return count;
};

/**
 * Gets single user matching the email
 * @param {string} email - Email to search
 * @returns {Promise<IUser>}
 * @throws {EntityNotFoundException} An entity with that email was not found
 * @throws {DuplicateEntityFoundException} More than one entity was found for this query
 */
const getByEmail = async (email: string, inviteId: string) => {
    const users = await UserService.getBy(
        [
            {
                field: "email",
                operator: "==",
                value: email.toLowerCase(),
            },
        ]
    );

    if (users.length === 0) {
        throw new EntityNotFoundException(
            "An entity with that email was not found"
        );
    }

    if (users.length >= 2) {
        throw new DuplicateEntityFoundException();
    }

    const invite = await UniqueUserInviteLinksService.getBy([
        {
            field: "id",
            operator: "==",
            value: inviteId,
        },
    ]);

    if ((invite && invite.length > 0) && users[0].id !== invite[0].userId) {
        throw new EntityNotFoundException();
    }

    return users[0];
}

const deactivateUsersById = async (userIdsToDeactivate: string[], currentUser: User | null = null) => {
    for (const userId of userIdsToDeactivate) {
        const user = await get(userId);
        if (user) {
            user.status = UserStatus.Disabled;
            user.symmioAccess = SymmioAccessType.WebAccess;

            await update(user, currentUser);
        }
    }
}

const deactivateUsers = async (usersToDeactivate: User[], currentUser: User | null = null) => {
    for (const user of usersToDeactivate) {
        user.status = UserStatus.Disabled;
        user.symmioAccess = SymmioAccessType.WebAccess;
        await update(user, currentUser);
    }
}

const deactivateAllUsers = async (organization: Organization, currentUser: User | null = null) => {
    if (organization && organization.id) {
        const users = await UserService.getAllByOrganizationId(organization.id);
        const usersToDeactivate = users.filter(user => user.id !== organization.accountHolderId);
        await deactivateUsers(usersToDeactivate, currentUser);
    }
}

const removeLicenses = async (userIdsToRemoveLicenses: string[], currentUser: User | null) => {
    for (const userId of userIdsToRemoveLicenses) {
        const user = await get(userId);
        if (user) {
            user.symmioAccess = SymmioAccessType.WebAccess;
            await update(user, currentUser);
        }
    }
}

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UserService = {
    add,
    deleteById,
    findFirstBy,
    fixTagIds,
    get,
    getBy,
    getByEmail,
    getCountBy,
    getAll,
    getAllByOrganizationId,
    getAllSuperAdmins,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
    deactivateUsersById,
    deactivateUsers,
    deactivateAllUsers,
    removeLicenses,
    // notificationInactiveUser,
    // notificationCompletedTasks,
    // notificationDayStreakMilestone,
    // notificationHighRiskUser,
    // notificationNewAccount,
    // notificationCompletedBaselineAssessments,
};

export default UserService;

// #endregion Exports
