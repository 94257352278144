import { PaymentCycle } from "./enumerations/payment-cycle";
import { NumericListOptions } from "./interfaces/numeric-list-options";

export const PaymentCycleOptions: NumericListOptions[] = [
    {
        value: PaymentCycle.Monthly,
        label: PaymentCycle[PaymentCycle.Monthly],
    },
    {
        value: PaymentCycle.Yearly,
        label: PaymentCycle[PaymentCycle.Yearly],
    },
]

export const PaymentCycleEnterpriseOptions: NumericListOptions[] = [
    {
        value: PaymentCycle.Monthly,
        label: PaymentCycle[PaymentCycle.Monthly],
    },
    {
        value: PaymentCycle.Quarterly,
        label: PaymentCycle[PaymentCycle.Quarterly],
    },
    {
        value: PaymentCycle.Yearly,
        label: PaymentCycle[PaymentCycle.Yearly],
    },
]