import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";

export interface LoginMessageProps {
    error: LoginError,
    organization: string
}

/**
 * Represents the message that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginMessage: React.FC<LoginMessageProps> = (props) => {
    const { error, organization } = props;

    let message = '';

    switch (error) {
        case LoginError.AdminDeactivated:
            message = `The account ${organization} was paused for all users (admins, managers and team members). Please contact your Symmio Account Specialist to reactivate the account.`
            break;
        case LoginError.AdminDeactivatedBilling:
            message = `The account ${organization} was paused for all users (admins, managers and team members). Please provide new valid payment details to reactivate the account.`
            break;
        case LoginError.AdminTrialExpired:
            message = 'But it\'s not too late to take the next step. Upgrade to a paid plan to keep using Symmio to manage musculoskeletal health and wellness.'
            break;
        case LoginError.TrialExpired:
            message = 'Please contact your account owner to upgrade to a paid plan to keep using Symmio to manage musculoskeletal health and wellness.'
            break;
        case LoginError.SubscriptionPaymentFailed:
            message = `All accounts for ${organization} have been deactivated. Please update your payment method with valid payment details to reactivate your account.`
            break;
        default:
            message = `The account ${organization} was paused for all users. Please contact your Administrator for next steps to reactivate the account.`;
    }

    return (
        <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}</p>
    );
};

export default LoginMessage;