import { DateTime } from "luxon";
import { MskHealthRiskOptions } from "../models/msk-health-risk";
import UserRoles from "../models/user-roles";
import { OrganizationSubscription } from "../models/interfaces/organization-subscription";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function capitalizeFirstLetter(input: string): string {
    if (input == null || input.length === 0) {
        return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

function getAge(dob: string | undefined): number | undefined {
    if (dob == null || dob.length === 0) {
        return undefined;
    }

    const years = DateTime.fromISO(dob).diffNow("years").years;
    return Math.floor(Math.abs(years)); // convert -68.814 to 68
}

function getRoleNameFromValue(input: string): string {
    if (input == null || input.length === 0) {
        return "";
    }
    return UserRoles.ALL_ROLES.find((r) => r.value === input)?.label || "";
}

function getHealthRiskName(riskCategory: string | undefined): string | undefined {
    return MskHealthRiskOptions.find((x) => x.value === riskCategory)?.label;
}

function getHealthRiskNames(riskCategories: string[]): string[] {
    if (riskCategories == null || riskCategories.length === 0) {
        return [];
    }
    return riskCategories.map((x) => {
        return getHealthRiskName(x)!;
    });
}

function parseQueryString(querystring: string) {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj: any = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}

function validateEmailAddress(address: string): boolean {
    const regex = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        "i"
    );
    return regex.test(address);
}

// Used only for dates where the timezone doesn't matter (e.g. get only the yyyy-mm-dd part of a birth date)
// Since it doesn't account for the time zone offset DO NOT use in situations where the time matters (e.g. subscription start date/time)
function convertToISO(dateString: string) {
    const [month, day, year] = dateString.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toISOString().split('T')[0];
}

function getDiscountBadgeText(orgSub: OrganizationSubscription | undefined | null) {
    let badgeText = "";

    if (orgSub && orgSub.mySymmioMonthlyPrice !== undefined && orgSub.mySymmioYearlyPrice !== undefined) {
        const percentageDiff = (orgSub.mySymmioMonthlyPrice * 12 - orgSub.mySymmioYearlyPrice) / (orgSub.mySymmioMonthlyPrice * 12) * 100;

        if (percentageDiff >= 1) {
            const percentageDiffStr = percentageDiff.toFixed(0);
            badgeText = `Save ${percentageDiffStr}%`;
        }
    }

    return badgeText;
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const Utils = {
    capitalizeFirstLetter,
    getAge,
    getHealthRiskName,
    getHealthRiskNames,
    getRoleNameFromValue,
    parseQueryString,
    validateEmailAddress,
    convertToISO,
    getDiscountBadgeText,
};

// #endregion Exports
