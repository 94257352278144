import React from "react";
import "./checkbox-type-input.styles.scss";
import { CheckboxTypeInputProps } from "./checkbox-type-input.props";

export const CheckboxTypeInput: React.FC<CheckboxTypeInputProps> = (props) => {
    // remove whitespace for ID
    const sanitizedId = props.id
        .replace(/ /g, "");

    let inputAttributes = {
        id: sanitizedId,
        placeholder: props.label,
        type: "checkbox"
    }

    if (props.readonly) {
        inputAttributes = {
            ...inputAttributes,
            ...{
                readOnly: true,
                tabIndex: -1
            }
        }
    }

    // wire up our input to react-hook-form
    // https://react-hook-form.com/get-started
    if (props.registerHook) {
        if (props.registerOptions) {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId, props.registerOptions),
                ...(props.registerOptions.maxLength && { maxLength: props.registerOptions.maxLength }),
                ...(props.registerOptions.value && { value: props.registerOptions.value })
            };
        } else {
            inputAttributes = {
                ...inputAttributes,
                ...props.registerHook(sanitizedId)
            };
        }
    }

    return (
        <div className={`checkbox-type-container ${props.className}`}>
            {props.errorState &&
                <span className="error-style" role="alert">
                    {`${props.errorState.message}`}
                </span>
            }
            {props.checked ?
                <input
                    {...inputAttributes}
                    className={`${props.errorState && "field-error"} ${props.inputClassName}`}
                    aria-invalid={props.errorState ? "true" : "false"}
                    data-testid="input-test"
                    style={props.style}
                    checked
                />
                :
                <input
                    {...inputAttributes}
                    className={`${props.errorState && "field-error"} ${props.inputClassName}`}
                    aria-invalid={props.errorState ? "true" : "false"}
                    data-testid="input-test"
                    style={props.style}
                    checked={false}
                />
            }

            {props.label && (
                <div className={`label`}>{props.label}</div>
            )}

        </div>
    )
};
