import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Organization } from "../../models/interfaces/organization";
import { StateOptions } from "../../models/states";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import OrganizationService from "../../utilities/services/organization-service";
import { Button } from "../button/button";
import { Modal } from "../modal/modal";
import RichTextEditor from "../rich-text-editor/rich-text-editor";
import { enqueueSnackbar } from "notistack";
import { AppContentType } from "../../models/enumerations/app-content-type";
import { AppContent } from "../../models/interfaces/app-content";
import { VideoEmbedSource } from "../../models/enumerations/video-embed-source";
import { TextTypeInput } from "../forms";
import { useForm } from "react-hook-form";

const COMPONENT_CLASS = "c-settings";

const HighRiskRecommendations: React.FC = () => {
    const [isEditingOrganization, setIsEditingOrganization] = useState(false);
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [editOrganization, setEditOrganization] = useState<Organization>();
    const [organization, setOrganization] = useState<Organization>();

    const [appContentType, setAppContentType] = useState<AppContentType>(AppContentType.PAIN);

    const [appContentPainContent, setAppContentPainContent] = useState<string>("");
    const [appContentPainVideoUrl, setAppContentPainVideoUrl] = useState<string>("");
    const [appContentPainHeaderText, setAppContentPainHeaderText] = useState<string>("");
    const [appContentPainVideoSource, setAppContentPainVideoSource] = useState<string>(VideoEmbedSource.YOUTUBE);
    const [appContentPainButtonText, setAppContentPainButtonText] = useState<string>("");
    const [appContentPainButtonLink, setAppContentPainButtonLink] = useState<string>("");

    const [appContentBehavioralContent, setAppContentBehavioralContent] = useState<string>("");
    const [appContentBehavioralVideoUrl, setAppContentBehavioralVideoUrl] = useState<string>("");
    const [appContentBehavioralHeaderText, setAppContentBehavioralHeaderText] = useState<string>("");
    const [appContentBehavioralVideoSource, setAppContentBehavioralVideoSource] = useState<string>(VideoEmbedSource.YOUTUBE);
    const [appContentBehavioralButtonText, setAppContentBehavioralButtonText] = useState<string>("");
    const [appContentBehavioralButtonLink, setAppContentBehavioralButtonLink] = useState<string>("");

    const { state } = useAuthState();

    const methods = useForm<any>();

    const {
        register,
    } = methods;

    useEffect(() => {
        if (editOrganization?.id !== organization?.id) {
            setEditOrganization(organization);
        }
    }, [editOrganization, organization]);

    useEffect(() => {
        if (state.organization?.id == null) {
            return;
        }

        OrganizationService.getSnapshot(state.organization?.id, (organization: Organization) => {
            setOrganization(organization);
            setIsLoading(false);
        });
    }, [state.organization?.id]);

    useEffect(() => {
        if (!isEditingOrganization) {
            setEditOrganization(organization);
        }
    }, [isEditingOrganization, organization]);

    useEffect(() => {
        if (!isEditingContact) {
            setEditOrganization(organization);
        }
    }, [isEditingContact, organization]);

    const handleSaveSettingsClick = async (inAppContactInformation: boolean) => {
        if (editOrganization == null) {
            return;
        }
        setIsSaving(true);

        await OrganizationService.update({
            ...editOrganization,
            appContent: {
                [AppContentType.PAIN]: {
                    content: appContentPainContent,
                    videoUrl: appContentPainVideoUrl,
                    videoSource: appContentPainVideoSource,
                    headerText: appContentPainHeaderText,
                    buttonText: appContentPainButtonText,
                    buttonLink: appContentPainButtonLink,
                } as AppContent,
                [AppContentType.BEHAVIORAL_HEALTH]: {
                    content: appContentBehavioralContent,
                    videoUrl: appContentBehavioralVideoUrl,
                    videoSource: appContentBehavioralVideoSource,
                    headerText: appContentBehavioralHeaderText,
                    buttonText: appContentBehavioralButtonText,
                    buttonLink: appContentBehavioralButtonLink,
                } as AppContent,
            }
        }, state.user);

        setIsEditingContact(false);
        setIsEditingOrganization(false);
        setIsSaving(false);
        if (inAppContactInformation) {
            enqueueSnackbar("High-Risk Recommendations Saved!", { variant: "toast", width: "450px" });
        }
        else {
            enqueueSnackbar("Billing Info Saved!", { variant: "toast", width: "450px" });
        }
    }

    useEffect(() => {
        const appContentPain = editOrganization?.appContent ? editOrganization?.appContent[AppContentType.PAIN] : undefined;
        const appContentBehavioral = editOrganization?.appContent ? editOrganization?.appContent[AppContentType.BEHAVIORAL_HEALTH] : undefined;

        setAppContentPainContent(appContentPain ? appContentPain.content : "");
        setAppContentPainVideoUrl(appContentPain ? appContentPain.videoUrl : "");
        setAppContentPainHeaderText(appContentPain ? appContentPain.headerText : "");
        setAppContentPainVideoSource(appContentPain ? appContentPain.videoSource : "")
        setAppContentPainButtonText(appContentPain ? appContentPain.buttonText : "");
        setAppContentPainButtonLink(appContentPain ? appContentPain.buttonLink : "");

        setAppContentBehavioralContent(appContentBehavioral ? appContentBehavioral.content : "");
        setAppContentBehavioralVideoUrl(appContentBehavioral ? appContentBehavioral.videoUrl : "");
        setAppContentBehavioralHeaderText(appContentBehavioral ? appContentBehavioral.headerText : "");
        setAppContentBehavioralVideoSource(appContentBehavioral ? appContentBehavioral.videoSource : "");
        setAppContentBehavioralButtonText(appContentBehavioral ? appContentBehavioral.buttonText : "");
        setAppContentBehavioralButtonLink(appContentBehavioral ? appContentBehavioral.buttonLink : "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editOrganization, isEditingContact]);

    return (
        <><div className={COMPONENT_CLASS}>
            {/* <div>
        <div className={`${COMPONENT_CLASS}__main`}>
            <div className={`${COMPONENT_CLASS}__account-info__row-1`}>
                <h6>Account Information</h6>
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <Button
                        buttonText="Edit"
                        type="default"
                        onClick={() => setIsEditingOrganization(true)} />
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__field-container`}>
                <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                    <label>Organization Name</label>
                    <p>{organization?.name}</p>
                </div>
                <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                    <label>Billing Address</label>
                    {
                        organization?.address != null &&
                        <span>
                            <p>{organization.address.line1 || ""}</p>
                            {
                                organization.address.line2 &&
                                <p>{organization.address.line2}</p>
                            }
                            <p>{`${organization.address.city || ""}, ${organization.address.state || ""} ${organization.address.postalCode || ""}`}</p>
                        </span>
                    }
                </div>
                <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                    <label>Billing Contact</label>
                    {organization?.billingContact != null &&
                        <span>
                            <p>{organization?.billingContact?.firstName || ""} {organization?.billingContact?.lastName || ""}</p>
                            <p>{organization?.billingContact?.email || ""}</p>
                            <p>{organization?.billingContact?.phoneNumber || ""}</p>
                        </span>
                    }
                </div>
            </div>
        </div>
    </div> */}
            <div className={`c-billing`}>
                <h2>High-Risk Recommendations</h2>
            </div>
            <div className={`${COMPONENT_CLASS}__disclaimer`}>
                <p>
                    The High-Risk Recommendations is a key feature on Symmio designed to assist you in effectively managing the health risks of your users. This tool empowers you to provide critical information and guidance for individuals who require a professional's recommendations to address and reduce their health risks.
                </p>
            </div>

            <div className={`${COMPONENT_CLASS}__edit -content`}>
                <RichTextEditor
                    appContentType={appContentType}
                    setAppContentType={setAppContentType}

                    appContentPainContent={appContentPainContent}
                    appContentPainVideoUrl={appContentPainVideoUrl}
                    appContentPainHeaderText={appContentPainHeaderText}
                    appContentPainVideoSource={appContentPainVideoSource}
                    appContentPainButtonText={appContentPainButtonText}
                    appContentPainButtonLink={appContentPainButtonLink}

                    appContentBehavioralContent={appContentBehavioralContent}
                    appContentBehavioralVideoUrl={appContentBehavioralVideoUrl}
                    appContentBehavioralHeaderText={appContentBehavioralHeaderText}
                    appContentBehavioralVideoSource={appContentBehavioralVideoSource}
                    appContentBehavioralButtonText={appContentBehavioralButtonText}
                    appContentBehavioralButtonLink={appContentBehavioralButtonLink}

                    setAppContentPainContent={setAppContentPainContent}
                    setAppContentPainVideoUrl={setAppContentPainVideoUrl}
                    setAppContentPainHeaderText={setAppContentPainHeaderText}
                    setAppContentPainVideoSource={setAppContentPainVideoSource}
                    setAppContentPainButtonText={setAppContentPainButtonText}
                    setAppContentPainButtonLink={setAppContentPainButtonLink}

                    setAppContentBehavioralContent={setAppContentBehavioralContent}
                    setAppContentBehavioralVideoUrl={setAppContentBehavioralVideoUrl}
                    setAppContentBehavioralHeaderText={setAppContentBehavioralHeaderText}
                    setAppContentBehavioralVideoSource={setAppContentBehavioralVideoSource}
                    setAppContentBehavioralButtonText={setAppContentBehavioralButtonText}
                    setAppContentBehavioralButtonLink={setAppContentBehavioralButtonLink}

                    includeTextAlignment={true}
                    showEdit={true}
                    showPreview={false}
                    isEditingContact={isEditingContact}
                    setIsEditingContact={setIsEditingContact} />
            </div>
            <div className={`${COMPONENT_CLASS}__actions left`}>
                <div style={{ display: !isEditingContact ? "none" : "flex", gap: "0 20px" }}>
                    <Button
                        type="link"
                        buttonText="Cancel"
                        onClick={(event) => {
                            event.preventDefault();
                            setIsEditingOrganization(false);
                            setIsEditingContact(false);
                        }} />
                    <Button
                        type="default"
                        buttonText="Save Changes"
                        onClick={() => handleSaveSettingsClick(true)} />
                </div>
            </div>
        </div><Modal
            isOpen={isEditingOrganization}
            isLoading={isSaving}
            onClose={setIsEditingOrganization}
            title="Organization Information"
            defaultModalActions={true}
            onSubmit={() => handleSaveSettingsClick(false)}
            onCancel={() => setIsEditingOrganization(false)}
            submitButtonText="Save Changes">
                <Dialog.Title
                    as="h6">
                    Organization Name
                </Dialog.Title>
                <div className={`${COMPONENT_CLASS}__edit -name`}>
                    <div>
                        <TextTypeInput
                            id="orgName"
                            type="text"
                            label="Organization Name*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.name,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { name: e.target.value })),
                            }} />
                    </div>
                </div>
                <Dialog.Title
                    as="h6">
                    Billing Address
                </Dialog.Title>
                <div className={`${COMPONENT_CLASS}__edit -address`}>
                    <div>
                        <TextTypeInput
                            id="line1"
                            type="text"
                            label="Street Address*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.address?.line1,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { address: Object.assign({}, editOrganization?.address, { line1: e.target.value }) })),
                            }} />
                    </div>
                    <div>
                        <TextTypeInput
                            id="line2"
                            type="text"
                            label="Address Line 2"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.address?.line2,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { address: Object.assign({}, editOrganization?.address, { line2: e.target.value }) })),
                            }} />
                    </div>
                    <div className={`${COMPONENT_CLASS}__city`}>
                        <TextTypeInput
                            id="city"
                            type="text"
                            label="City*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.address?.city,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { address: Object.assign({}, editOrganization?.address, { city: e.target.value }) })),
                            }} />
                        <Select
                            id="state"
                            name="state"
                            classNamePrefix={COMPONENT_CLASS}
                            placeholder="State*"
                            options={StateOptions}
                            onChange={(newValue: any, actionMeta: any) => setEditOrganization(Object.assign({}, editOrganization, { address: Object.assign({}, editOrganization?.address, { state: newValue.value }) }))}
                            value={StateOptions.find(o => o.value === editOrganization?.address?.state)} />
                        <TextTypeInput
                            id="postalCode"
                            type="text"
                            label="Zip Code"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.address?.postalCode,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { address: Object.assign({}, editOrganization?.address, { postalCode: e.target.value }) })),
                            }} />
                    </div>
                </div>
                <Dialog.Title
                    as="h6">
                    Billing Contact
                </Dialog.Title>
                <div className={`${COMPONENT_CLASS}__edit -billing`}>
                    <div className={`${COMPONENT_CLASS}__user-name`}>
                        <TextTypeInput
                            id="firstName"
                            type="text"
                            label="First Name*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.billingContact?.firstName,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { billingContact: Object.assign({}, editOrganization?.billingContact, { firstName: e.target.value }) })),
                            }} />
                        <TextTypeInput
                            id="lastName"
                            type="text"
                            label="Last Name*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.billingContact?.lastName,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { billingContact: Object.assign({}, editOrganization?.billingContact, { lastName: e.target.value }) })),
                            }} />
                    </div>
                    <div className={`${COMPONENT_CLASS}__user-details`}>
                        <TextTypeInput
                            id="email"
                            type="text"
                            label="Email*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.billingContact?.email,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { billingContact: Object.assign({}, editOrganization?.billingContact, { email: e.target.value }) })),
                            }} />
                        <TextTypeInput
                            id="phone"
                            type="text"
                            label="Phone*"
                            hideLabel={true}
                            registerHook={register}
                            registerOptions={{
                                value: editOrganization?.billingContact?.phoneNumber,
                                onChange: (e) => setEditOrganization(Object.assign({}, editOrganization, { billingContact: Object.assign({}, editOrganization?.billingContact, { phoneNumber: e.target.value }) })),
                            }} />
                    </div>
                </div>

            </Modal></>
    );
}

export default HighRiskRecommendations;
