export interface FaqAccordionProps {
    faqs: {
        question: string;
        answer: string;
        isOpen: boolean;
    }[];
    setFaqs: Function;
}

const COMPONENT_CLASS = "c-faq-accordion";

const FaqAccordion: React.FunctionComponent<FaqAccordionProps> = (props) => {
    const {
        faqs,
        setFaqs,
    } = props

    const toggleFaq = (index: number) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.isOpen = !faq.isOpen;
                } else {
                    faq.isOpen = false;
                }

                return faq;
            })
        );
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {
                faqs.map((faq, index) => (
                    <div className={`${COMPONENT_CLASS}__faq ${faq.isOpen ? "open" : ""}`}
                        key={index}
                        onClick={() => toggleFaq(index)}
                    >
                        <div className="faq-question">{faq.question}</div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))
            }
        </div>
    );
};

export default FaqAccordion;