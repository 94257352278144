import React, { useEffect, useState } from "react";
import Modal from "../symmio-modal/modal";
import { User } from "../../models/interfaces/user";
import searchClient, { SearchIndexes } from '../../utilities/search-client';
import { Configure, connectInfiniteHits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { Loader } from '../loader/loader'
import OrganizationService from "../../utilities/services/organization-service";
import { Organization } from "../../models/interfaces/organization";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import UserRoles from "../../models/user-roles";
import UserService from "../../utilities/services/user-service";
import { Button } from "../button/button";

export interface ModalProps {
    onClose: Function,
    open: boolean,
    accountHolder: User | undefined,
    organization: Organization | undefined,
}

const COMPONENT_CLASS = "c-application-header";
const MODAL_COMPONENT_CLASS = "c-modal";

/**
 * Represents the modal that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RemoveAccountHolderModal: React.FC<ModalProps> = (props) => {
    const {
        onClose,
        open,
        accountHolder,
        organization,
    } = props;

    const { state } = useAuthState();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [searchState, setSearchState] = useState({
        sortBy: SearchIndexes.usersByNameAsc,
    });

    const title = `You are about to change the Account Holder for ${organization?.name ?? '[organization name not found]'}!`;
    const message = `Select a user to be the new Account Holder:`;
    const algoliaFilters = organization?.id ? `organizationId:${organization?.id}` : 'organizationId:"NO_MATCH"';

    const handleClose = (save: boolean) => {
        onClose(save);
    };

    const handleAccountHolderChange = async () => {
        if (!selectedUserId || selectedUserId === '' || !state.user) {
            return;
        }

        // get all orgs assigned to the current account holder
        const holderOrgs = await OrganizationService.getBy([{
            field: 'accountHolderId',
            operator: '==',
            value: accountHolder?.id,
        }]);


        // reassign the orgs to the new account holder
        const promises: Promise<Organization | User>[] = [];

        holderOrgs.forEach((o) => {
            o.accountHolderId = selectedUserId;
            promises.push(OrganizationService.update(o, state.user));
        });

        // get the new account holder from the firestore so we can update them
        const updatedSelectedUser = await UserService.get(selectedUserId);

        if (!updatedSelectedUser) {
            return;
        }

        // transfer limits to new account holder
        updatedSelectedUser.usersLimit = accountHolder?.usersLimit;
        updatedSelectedUser.organizationsLimit = accountHolder?.organizationsLimit;
        updatedSelectedUser.roles = [UserRoles.ACCOUNT_HOLDER_ID];
        promises.push(UserService.update(updatedSelectedUser, state.user))


        setIsSaving(true);

        await Promise.all(promises)
            .then(() => {
                handleClose(true);
                setIsSaving(false);
            });
    };

    const getRoleLabel = (role: string) => {
        const roleObj = UserRoles.ALL_ROLES.find(r => r.value === role);
        const label = roleObj ? roleObj.label : '';
        return label;
    };

    useEffect(() => {
    }, [selectedUserId]);


    const customHits = ({
        hits,
    }: any) => {
        return (
            <div className={`${COMPONENT_CLASS}__organization-list__wrapper`}>
                <ul>
                    {hits.map((h: any, i: number) => (
                        <li
                            className={`${COMPONENT_CLASS}__organization-list__item ${selectedUserId === h.id ? "-selected" : ""}`}
                            onClick={() => setSelectedUserId(h.id)}
                            key={`organization-${i}`}>
                            <div className={`${COMPONENT_CLASS}__organization-list__item__30-col`}>
                                {`${h.firstName ?? ''} ${h.lastName ?? ''}`}
                            </div>
                            <div className={`${COMPONENT_CLASS}__organization-list__item__40-col`}>
                                {h.email}
                            </div>
                            <div className={`${COMPONENT_CLASS}__organization-list__item__30-col`}>
                                {h.roles && h.roles.length > 0 ? getRoleLabel(h.roles[0]) : ''}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const CustomHits = connectInfiniteHits(customHits);

    const children = (
        <>
            <div className={`${COMPONENT_CLASS}__organization-list`}>
                <InstantSearch
                    indexName={SearchIndexes.usersByNameAsc}
                    searchClient={searchClient}
                    searchState={searchState}
                    onSearchStateChange={searchState => {
                        setSearchState(searchState);
                    }}>
                    <Configure
                        filters={algoliaFilters}
                        hitsPerPage={10}
                    />
                    <SearchBox
                        autoFocus
                        className={`${MODAL_COMPONENT_CLASS}__search`}
                        searchAsYouType={true}
                        showLoadingIndicator={true}
                        loadingIndicator={<Loader isVisible={true} />}
                    />
                    <CustomHits />
                </InstantSearch>
                <div className={'c-login-modal__button-area'}>
                    <Button
                        type="link"
                        onClick={() => handleClose(false)}
                        buttonText="Cancel" />
                    <div style={{ width: '4em' }} />
                    <Button
                        disabled={!selectedUserId || isSaving}
                        onClick={handleAccountHolderChange}
                        buttonText="Save Changes" />
                </div>
            </div>
        </>
    );

    return (
        <Modal
            onClose={handleClose}
            open={open}
            children={children}
            message={message}
            title={title}
            showHeader={false}
        />
    );
};

export default RemoveAccountHolderModal;