import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { LeadLink } from "../../models/interfaces/lead-link";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "leadLinks";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

const add = async (leadLink: LeadLink, currentUser: User | null = null) => {
    return FirestoreService.add<LeadLink>(COLLECTION_NAME, leadLink, currentUser);
};

const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

const get = async (id: string) => {
    return FirestoreService.get<LeadLink>(COLLECTION_NAME, id);
};

const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<LeadLink>(COLLECTION_NAME, conditions, order, limit);
};

const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<LeadLink>(COLLECTION_NAME, order);
};

const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<LeadLink>(COLLECTION_NAME, id, listener);
};

const getSnapshotBy = (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    listener: Function
) => {
    return FirestoreService.getSnapshotBy<LeadLink>(COLLECTION_NAME, conditions, order, -1, listener);
};

const save = async (leadLink: LeadLink, currentUser: User | null = null) => {
    return FirestoreService.save<LeadLink>(COLLECTION_NAME, leadLink, currentUser);
};

const update = async (leadLink: LeadLink, currentUser: User | null = null) => {
    return FirestoreService.update<LeadLink>(COLLECTION_NAME, leadLink, currentUser);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const LeadLinksService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default LeadLinksService;

// #endregion Exports
