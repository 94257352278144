import Slider from "@mui/material/Slider/Slider";
import React, { useState } from "react";

const COMPONENT_CLASS = "c-slider-input-option";

export interface SliderInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
}

const SliderInputOption: React.FC<SliderInputOptionProps> = (props) => {
    const [value, setValue] = useState<number>(props.answer ? parseInt(props.answer) : 30);

    const marks = [
        {
            value: 0,
            label: '0',
        },

        {
            value: 100,
            label: '100',
        },
    ];

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__integer-wrapper`}>
                <div className={`${COMPONENT_CLASS}__integer-options`}>
                    <input className={`${COMPONENT_CLASS}__input`}
                        onChange={(e) => {
                            if (parseInt(e.target.value) > 100) {
                                e.target.value = "100"
                            }
                            props.onChange(
                                e.target.value == null
                                    ? ""
                                    : e.target.value!.toString()
                            );
                            setValue(Number(e.target.value));
                        }
                        }
                        type="number"
                        value={props.answer ? props.answer : value}
                        defaultValue="30"
                        min="0"
                        max="100">
                    </input>
                </div>
                <span>%</span>
            </div>
            <div className={`${COMPONENT_CLASS}__slider-wrapper`}>
                <Slider
                    sx={{
                        height: "10px",
                        borderRadius: "5px",
                    }}
                    marks={marks} value={value}
                    onChange={(e, val) => {
                        props.onChange(
                            val === null
                                ? ""
                                : val.toString()
                        );
                        setValue(Number(val));
                    }} />
                {/* <IonRange
                    min={0}
                    max={100}
                    value={currentValue}
                    onIonChange={(e) =>
                        props.onChange(
                            e.detail.value == null
                                ? ""
                                : e.detail.value!.toString()
                        )
                    }></IonRange> */}
                {/* <div className={`${COMPONENT_CLASS}__range-wrapper`}>
                    <IonLabel>0</IonLabel>
                    <IonLabel>100</IonLabel>
                </div> */}
            </div>
        </div>
    );
};

export default SliderInputOption;
