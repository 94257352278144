import { ReportType } from "./enumerations/report-type";
import { ListOptions } from "./interfaces/list-options";

export default class ReportTypes {
    public static ALL_TYPES: ListOptions[] = [
        {
            value: ReportType.Compliance.toString(),
            label: ReportType[ReportType.Compliance],
        },
        {
            value: ReportType.Detail.toString(),
            label: ReportType[ReportType.Detail],
        },
        {
            value: ReportType.Score.toString(),
            label: ReportType[ReportType.Score],
        },
        {
            value: ReportType.Summary.toString(),
            label: ReportType[ReportType.Summary],
        },
        {
            value: ReportType.Movement.toString(),
            label: `${ReportType[ReportType.Movement]} Health` ,
        },
    ];

    public static TEAM_MEMBER_TYPES: ListOptions[] = [
        {
            value: ReportType.Compliance.toString(),
            label: ReportType[ReportType.Compliance],
        },
        {
            value: ReportType.Summary.toString(),
            label: ReportType[ReportType.Summary],
        },
    ];
}
