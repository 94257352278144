import { CalculateMovementScore } from "../../../models/interfaces/scores/calculate-movement-score";
import { MskScore } from "../../../models/interfaces/scores/msk-score";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalculateMovementQueryParams {}

export interface CalculateMovementPathParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/calculator`;
const scoreEndpoint = `${baseEndpoint}/movement-score`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CalculateMovementService = {
    /**
     * Calculate a total MSK score based on all responses
     */
    score: ServiceBase.post<MskScore, CalculateMovementScore>(scoreEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CalculateMovementService;

// #endregion Exports
