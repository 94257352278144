/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGlobalState } from "../../utilities/hooks/use-global-state";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { CategoryIds } from "../../models/enumerations/category-ids";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { Question } from "../../models/interfaces/questions/question";
import { LifestyleAssessmentResponse } from "../../models/interfaces/scores/lifestyle-assessment-response";
import { LifestyleScore } from "../../models/interfaces/scores/lifestyle-score";
import { MskScore } from "../../models/interfaces/scores/msk-score";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import usePrevious from "../../utilities/hooks/use-previous";
import UserMskFocusArticlesUtil from "../../utilities/user-msk-focus-articles-util";
import AppModal from "../app-modal/app-modal";
import LoaderAssessments from "../loader-assessments/loader-assessments";
import ProgressBar from "../progress-bar/progress-bar";
import ScreenSlide from "../screen-slide/screen-slide";
import { useStoreAssessments } from "../../store/useStoreAssessments";
import { DateTime } from "luxon";
import UserService from "../../utilities/services/user-service";
import ReportService from "../../utilities/services/report-service-assessments/report-service-assessments";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { User } from "../../models/interfaces/user";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import UserUtilAssessments from "../../utilities/user-util-assessments";
import LinkCreateAccountPage from "../../pages/link-create-account-page/link-create-account-page";

const COMPONENT_CLASS = "c-movement-screen";

export interface LinkLifestyleScreenProps {
    screenSettings: AssessmentSettings;
    setScreenSettings: React.Dispatch<AssessmentSettings>;
    settingsOnBack?: Function;
    user: User;
    mskScore: MskScore;
}

const LinkLifestyleScreen: React.FC<LinkLifestyleScreenProps> = (props) => {
    const { screenSettings, setScreenSettings } = props;
    const { globalState, setGlobalError } = useGlobalState();
    const { state } = useAuthState();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingNextGroup, setLoadingNextGroup] = useState(false);
    const [lifestyleScore, setLifestyleScore] = useState<
        LifestyleScore | undefined | null
    >(undefined);
    const [errors, setErrors] = useState<string[]>([]);
    const [errorIntro, setErrorIntro] = useState<string | undefined>();
    const [mskScore, setMskScore] = useState<MskScore>(props.mskScore);
    const [isCompleted, setIsCompleted] = useState(false);
    // const [today, setToday] = useState<IToday>();

    const previousGroupIndex = usePrevious<number>(
        screenSettings.currentGroupIndex
    );
    const retryMs: number[] = [500, 1000, 1500, 3000, 5000];
    // const setLastActivityCompletedDate = useStoreAssessments(
    //     (state) => state.setLastActivityCompleted
    // );
    const hasSubmitButtton =
        AssessmentUtils.isLastGroup(screenSettings) &&
        AssessmentUtils.isLastSlide(screenSettings);


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const groupId = params.get("groupId");
        AssessmentUtils.fetchQuestions(
            screenSettings,
            CategoryIds.LIFESTYLE,
            groupId
        ).then((settings) => {
            settings.specificGroupId = groupId;
            setScreenSettings(settings);
            setIsLoading(false);
        }).catch((e) => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const shouldScore = AssessmentUtils.shouldScoreGroup(
            screenSettings,
            previousGroupIndex,
            screenSettings.currentGroupIndex
        );
        if (!shouldScore) {
            return;
        }

        setErrors([]);
        setIsLoading(true);
        scoreGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSettings.currentGroupIndex]);

    useEffect(() => {
        if (hasSubmitButtton) {
            submit();
        }
    }, [hasSubmitButtton])

    const scoreGroup = async (attemptIndex: number = 0): Promise<void> => {
        let assessmentResponse: LifestyleAssessmentResponse | undefined =
            undefined;
        try {
            const tempUserInfo = await UserUtilAssessments.getUserInfoForLinks(props.user);
            assessmentResponse = await AssessmentUtils.scoreGroup(
                tempUserInfo || {},
                screenSettings,
                previousGroupIndex,
                mskScore
            );
        } catch (err: any) {
            if (attemptIndex === 0) {
                setGlobalError("Lifestyle: Calculate", err);
            }
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return scoreGroup(attemptIndex + 1);
                }, retryMs[attemptIndex]);
                return;
            } else {
                await handleError([
                    "Your assessment could not be scored. Please submit your response again.",
                ]);
                return;
            }
        }

        // error handling
        if (assessmentResponse == null) {
            await handleError([
                "Your assessment was not calculated properly. Please submit your response again.",
            ]);
            return;
        }
        if (
            assessmentResponse.errors != null &&
            assessmentResponse.errors.length >= 1
        ) {
            await handleError(
                assessmentResponse.errors,
                "Your assessment had errors. Please review the error and submit again."
            );
            return;
        }

        if (assessmentResponse.mskScore) {
            setMskScore(assessmentResponse.mskScore);
        }
        setIsLoading(false);

    };

    const saveGroup = async (
        lifestyleAssessmentResponse: LifestyleAssessmentResponse,
        attemptIndex: number = 0
    ): Promise<void> => {
        let newMskScore: MskScore | undefined = undefined;
        try {
            newMskScore = await AssessmentUtils.saveGroupForLinks(
                lifestyleAssessmentResponse,
            );
        } catch (err: any) {
            if (attemptIndex === 0) {
                setGlobalError("Lifestyle: Save", err);
            }
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return saveGroup(
                        lifestyleAssessmentResponse,
                        attemptIndex + 1
                    );
                }, retryMs[attemptIndex]);
                return;
            } else {
                await handleError([
                    "Your assessment could not be saved. Please submit your response again.",
                ]);
                return;
            }
        }
        // error handling
        if (newMskScore == null) {
            await handleError([
                "Your assessment was not saved properly. Please submit your response again.",
            ]);
            return;
        }
        if (newMskScore.errors != null && newMskScore.errors.length >= 1) {
            await handleError(
                newMskScore.errors,
                "Your assessment had errors. Please try submitting your response again."
            );
            return;
        }

        // handle changes
        if (!state.user || !state.user.id) {
            return
        }
        UserMskFocusArticlesUtil.handleFocusChanges(
            state.user.id,
            newMskScore
        );
        setMskScore(newMskScore);
        setIsLoading(false);
        setErrors([]);
    };

    // NOTE: this throws warnings in the tests for some reason, but works functionally
    const handleError = async (
        errors: string[],
        instructions?: string
    ): Promise<void> => {
        if (errors == null || errors.length === 0) {
            setErrors([]);
            return;
        }

        setScreenSettings(
            await AssessmentUtils.moveToPreviousPage(screenSettings)
        );
        setErrorIntro(instructions);
        setErrors(errors);
        setIsLoading(false);
    };

    const getToggleLoadingNextGroup = (settings: AssessmentSettings) => {
        let toggleLoadingNextGroup = false;
        const isLastSlideInGroup = AssessmentUtils.isLastSlide(settings);
        if (isLastSlideInGroup) {
            const group = settings.slideGroups[settings.currentGroupIndex];
            toggleLoadingNextGroup = group.nextGroupIdDeterminedByApi === true;
        }
        return toggleLoadingNextGroup;
    };

    const getNextPage = async () => {
        const toggleLoadingNextGroup =
            getToggleLoadingNextGroup(screenSettings);
        if (toggleLoadingNextGroup) {
            setLoadingNextGroup(true);
        }
        setScreenSettings(await AssessmentUtils.moveToNextPage(screenSettings));
        if (toggleLoadingNextGroup) {
            setLoadingNextGroup(false);
        }
    };

    const recordAnswer = async (selectedAnswer: string, question: Question) => {
        let settings = AssessmentUtils.recordAnswer(
            screenSettings,
            question,
            selectedAnswer
        );

        if (
            slide?.slideType === SlideType.Question &&
            slide.questions != null
        ) {
            let autoSubmit = false;
            slide.questions.forEach((question) => {
                if (AssessmentUtils.autoSubmitQuestion(question)) {
                    autoSubmit = true;
                }
            });
            if (autoSubmit) {
                const toggleLoadingNextGroup =
                    getToggleLoadingNextGroup(settings);
                if (toggleLoadingNextGroup) {
                    setLoadingNextGroup(true);
                }
                settings = await AssessmentUtils.moveToNextPage(settings);
                if (toggleLoadingNextGroup) {
                    setLoadingNextGroup(false);
                }
            }
        }

        setScreenSettings(settings);
    };

    const submit = async () => {

        setIsLoading(true);
        setLifestyleScore(mskScore?.lifestyleScore);
        // already submitted
        setErrors(mskScore?.errors ?? []);
        if (mskScore?.errors && mskScore.errors.length) {
            setIsLoading(false);
        }

        // Removing until necessary
        // setLastActivityCompletedDate(
        //     new Date(),
        //     ActivityType.LifestyleAssessment
        // );

        setIsCompleted(true);

    };

    const currentGroup =
        screenSettings?.slideGroups == null
            ? null
            : screenSettings.slideGroups[screenSettings.currentGroupIndex];
    const slide = AssessmentUtils.getSlide(screenSettings);
    const actionsModifier =
        slide?.slideType === SlideType.Video ? "-video" : "";

    let hasContinueButton =
        AssessmentUtils.hasData(screenSettings) &&
        (!AssessmentUtils.isLastGroup(screenSettings) ||
            !AssessmentUtils.isLastSlide(screenSettings));

    if (
        hasContinueButton &&
        slide &&
        slide?.slideType === SlideType.Question &&
        slide.questions != null
    ) {
        slide.questions.forEach((question) => {
            if (AssessmentUtils.autoSubmitQuestion(question)) {
                hasContinueButton = false;
            }
        });
    }

    let continueButtonText = "Continue";
    if (hasContinueButton) {
        switch (slide?.slideType) {
            case SlideType.AssessmentIntro:
            case SlideType.Tests:
                continueButtonText = "Let's Do It";
                break;
            case SlideType.GroupIntro:
                continueButtonText = "Start";
                break;
            case SlideType.Video:
                const questions = AssessmentUtils.getSlide(
                    props.screenSettings
                )?.questions;
                if (
                    questions != null &&
                    questions.length >= 1 &&
                    questions[0].questionId === "BREATHING_BREATH"
                ) {
                    continueButtonText = "Go to Timer";
                } else {
                    continueButtonText = "Enter Results";
                }
                break;
        }
    }

    const noProgressBarSlideTypes = [
        SlideType.AssessmentIntro,
        SlideType.Complete,
        SlideType.GroupIntro,
        SlideType.Tests,
        SlideType.Tips,
    ];
    const hasProgressBar =
        slide == null ||
        noProgressBarSlideTypes.indexOf(slide.slideType) === -1;

    const collapseScrollTypes = [
        AnswerFormats.INTEGER,
        AnswerFormats.TIME,
        AnswerFormats.DECIMAL,
    ];
    const shouldCollapseScroll =
        slide?.slideType === SlideType.Question &&
        slide.questions &&
        slide.questions.filter(
            (q) => collapseScrollTypes.indexOf(q.answerFormat) > -1
        ).length > 0;

    const conditionalGroups = [
        GroupIds.BEHAVIORAL_QUESTIONNAIRE,
        GroupIds.SLEEP_PSQI,
        GroupIds.NUTRITION_QUESTIONNAIRE,
    ];
    const questionGroupNames = AssessmentUtils.getAssessmentGroups(
        screenSettings
    )
        .filter(
            (group) =>
                conditionalGroups.indexOf(
                    GroupIds[group.groupId as keyof typeof GroupIds]
                ) === -1
        )
        .map((group) => group.groupName!);

    // const handleGenerateReport = async (emailResults: boolean) => {
    //     if (!globalState.currentIdentity?.id) return;

    //     // we need to get the user from the firebase in case their organization
    //     // was switched (in the portal) and no longer matches the data in the app.
    //     const userInFirebase = await UserService.get(globalState.currentIdentity?.id);

    //     if (!userInFirebase || !userInFirebase.organizationId) return;

    //     const latestOrgId = userInFirebase.organizationId;

    //     //setIsLoading(true);

    //     const date = DateTime.now().toISODate();
    //     const report: Report = {
    //         dateEnd: date,
    //         dateStart: date,
    //         emailResults: emailResults,
    //         organizationId: latestOrgId,
    //         status: "scheduled",
    //         type: ReportType.Assessment,
    //         userId: globalState.currentIdentity!.id!,
    //     };

    //     const identity = globalState.currentIdentity;

    //     if (identity.organizationId !== latestOrgId) {
    //         identity.organizationId = latestOrgId;
    //     }

    //     const scheduledReport = await ReportService.save(
    //         report,
    //         identity
    //     );

    //     if (!scheduledReport.id) {
    //         // setIsLoading(false);
    //         // setReportError(
    //         //     "Report was not saved successfully. Please try again."
    //         // );
    //         return;
    //     }

    //     if (scheduledReport.emailResults === true) {
    //         // setShowReportEmailConfirmation(true);
    //         // setIsLoading(false);
    //         return;
    //     }

    //     // const reportUnsubscribe = ReportService.getSnapshot(
    //     //     scheduledReport.id,
    //     //     (r: Report) => {
    //     //         if (r.status === "complete" && r.url) {
    //     //             setIsLoading(false);
    //     //             reportUnsubscribe();

    //     //             //Loads in the System browser
    //     //             window.open(r.url, "_self");
    //     //         }
    //     //         if (r.status === "error") {
    //     //             setReportError(r.errorMessage);
    //     //             setIsLoading(false);
    //     //             reportUnsubscribe();
    //     //         }
    //     //     }
    //     // );
    // };

    return (
        <>
            {
                // if
                isCompleted && mskScore && (
                    <LinkCreateAccountPage
                        user={props.user}
                        mskScore={mskScore}
                    />
                )
            }
            {!isCompleted &&
                <BaseCardLayout>
                    <div
                        className={`${COMPONENT_CLASS} -lifestyle ${shouldCollapseScroll ? "-collapse-scroll" : ""
                            }`}>
                        <LoaderAssessments
                            isLoading={isLoading || isLoadingNextGroup} />
                        {
                            // if
                            !isLoading && currentGroup != null && (
                                <div
                                    className={`${COMPONENT_CLASS}__wrapper ${slide?.slideType === SlideType.GroupIntro
                                        ? "-intro"
                                        : ""
                                        }`}>
                                    {
                                        // if
                                        AssessmentUtils.hasData(screenSettings) &&
                                        lifestyleScore == null && (
                                            <div
                                                className={`${COMPONENT_CLASS}__content ${hasContinueButton ||
                                                    hasSubmitButtton
                                                    ? "has-button"
                                                    : ""
                                                    }`}>
                                                <AppModal
                                                    isOpen={errors?.length >= 1}
                                                    onDismiss={() => {
                                                        setErrors([]);
                                                    }}>
                                                    <div
                                                        style={{
                                                            padding: "10px",
                                                            color: "#990000",
                                                        }}>
                                                        {
                                                            // if
                                                            errorIntro != null && (
                                                                <div>
                                                                    {errorIntro}
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            )
                                                        }
                                                        <div>{errors.join("; ")}</div>
                                                    </div>
                                                </AppModal>
                                                {
                                                    // if
                                                    slide != null && (
                                                        <ScreenSlide
                                                            questionGroupNames={
                                                                questionGroupNames
                                                            }
                                                            recordedAnswers={AssessmentUtils.getAllResponses(
                                                                screenSettings
                                                            )}
                                                            recordAnswer={recordAnswer}
                                                            page={slide}
                                                            errors={errors}
                                                            currentProgress={AssessmentUtils.getProcessCurrent(
                                                                screenSettings
                                                            )}
                                                            totalProgress={AssessmentUtils.getProcessTotal(
                                                                screenSettings
                                                            )}
                                                            handleOptionalQuestion={() =>
                                                                getNextPage()
                                                            }
                                                            settingsOnBack={props.settingsOnBack}
                                                            isActionsWithContinue={hasContinueButton}
                                                            skipButton={slide?.questions &&
                                                                slide.questions.length >
                                                                0 &&
                                                                slide.questions[0]
                                                                    .optional === true}
                                                            onSkip={getNextPage}
                                                            onBack={props.settingsOnBack}
                                                            onSubmit={getNextPage}
                                                            submitButtonText={continueButtonText}
                                                            hasProgressBar={hasProgressBar}
                                                            screenSettings={screenSettings}
                                                            headerText="Lifestyle Assessment"
                                                        />
                                                    )
                                                }
                                                <div
                                                    className={`${COMPONENT_CLASS}__actions ${actionsModifier}`}>
                                                    {/* {
                                        // if
                                        hasContinueButton && (
                                            <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={getNextPage} submitButtonText={continueButtonText} skipButton={slide?.questions &&
                                                slide.questions.length >
                                                0 &&
                                                slide.questions[0]
                                                    .optional === true}
                                                onSkip={() => {
                                                    getNextPage();
                                                }}></BaseCardLayoutActions>

                                        )
                                    } */}
                                                    {/* {
                                                // if
                                                hasSubmitButtton && (
                                                    <div className={`c-screen-slide__actions`}>
                                                        <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={submit} submitButtonText={errors.length === 0
                                                            ? "Submit Results"
                                                            : "Resubmit"}></BaseCardLayoutActions>
                                                    </div>
                                                    // <IonButton
                                                    //     color="primary"
                                                    //     expand="block"
                                                    //     onClick={() =>
                                                    //         submit()
                                                    //     }>
                                                    //     Submit Results
                                                    // </IonButton>
                                                )
                                            } */}

                                                    {/* {
                                        // if
                                        slide?.questions &&
                                        slide.questions.length >
                                        0 &&
                                        slide.questions[0]
                                            .optional === true && (
                                            <IonButton
                                                size="small"
                                                fill="clear"
                                                className={`${COMPONENT_CLASS}__optional-text`}
                                                onClick={() =>
                                                    getNextPage()
                                                }>
                                                Skip, I'll fill it
                                                in later.
                                            </IonButton>
                                        )
                                    } */}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </BaseCardLayout>
            }
        </>
    );
};

export default LinkLifestyleScreen;
