/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import DataExportUtil from "../../utilities/data-export-util";
import UserService from '../../utilities/services/user-service';
import OrganizationService from '../../utilities/services/organization-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Button } from '../../components/button/button';

const ExportPage: React.FC = () => {
    const { state } = useAuthState();

    const [hubspotPortalUsersCsv, setHubspotPortalUsersCsv] = useState<string>();
    const [hubspotAppUsersCsv, setHubspotAppUsersCsv] = useState<string>();
    const [generatingHubspotUsersCsv, setGeneratingHubspotUsersCsv] = useState<boolean>(false);

    const handleGenerateHubspotUsersClick = async () => {
        setGeneratingHubspotUsersCsv(true);
        const users = await UserService.getAll();
        const orgs = await OrganizationService.getAll();

        const portalRows = DataExportUtil.getHubspotUserDataRows(users, orgs, true);
        const portalCsv = DataExportUtil.generateHubspotUserCsv(portalRows);
        const portalBlob = new Blob([portalCsv], { type: 'text/csv;charset=utf-9,' });
        const portalCsvUrl = URL.createObjectURL(portalBlob);
        setHubspotPortalUsersCsv(portalCsvUrl);

        const appRows = DataExportUtil.getHubspotUserDataRows(users, orgs, false);
        const appCsv = DataExportUtil.generateHubspotUserCsv(appRows);
        const appBlob = new Blob([appCsv], { type: 'text/csv;charset=utf-9,' });
        const appCsvUrl = URL.createObjectURL(appBlob);
        setHubspotAppUsersCsv(appCsvUrl);


        setGeneratingHubspotUsersCsv(false);
    };

    // const handleHasRole = async () => {
    //     const users = UserService.getAll().then((x) => {
    //         x.forEach(y => {
    //             y.symmioAccess = "Web Assessment Only";
    //             UserService.save(y);
    //             console.log("saved");
    //         })
    //     })
    //     // // const user = UserService.getBy([
    //     // //     {
    //     // //         field: "email",
    //     // //         operator: "==",
    //     // //         value: "ytseby97+testcc6@gmail.com",
    //     // //     },
    //     // // ]).then((x) => {
    //     // //     console.log(x);
    //     // //     x.forEach(y => {
    //     // //         console.log(y);
    //     // //         if (!y.roles) {
    //     // //             console.log("roles is undefined");
    //     // //             y.roles = ["client"]
    //     // //             console.log("saving");
    //     // //             UserService.save(y);
    //     // //         }
    //     // //         else if (y.roles && y.roles.length === 0) {
    //     // //             console.log("roles is an empty array");
    //     // //         }
    //     // //         else if (y.roles[0] === null) {
    //     // //             console.log("roles is null");
    //     // //             y.roles = ["client"]
    //     // //             console.log("saving");
    //     // //             UserService.save(y);
    //     // //         }
    //     // //         else {
    //     // //             console.log("user has a role that isn't null");
    //     // //         }
    //     // //     });
    //     // // })
    //     // // console.log(user);
    //     // const users = UserService.getAll().then((x) => {
    //     //     x.forEach(y => {
    //     //         if (!y.roles) {
    //     //             console.log("roles is undefined", y);
    //     //             // y.roles = ["client"]
    //     //             // console.log("saving");
    //     //             // UserService.save(y);
    //     //         }
    //     //         else if (y.roles && y.roles.length === 0) {
    //     //             console.log("roles is an empty array", y);
    //     //             // y.roles = ["client"]
    //     //             // console.log("saving");
    //     //             // UserService.save(y);
    //     //         }
    //     //         else if (y.roles[0] === null) {
    //     //             console.log("roles is null", y);
    //     //             // y.roles = ["client"]
    //     //             // console.log("saving");
    //     //             // UserService.save(y);
    //     //         }
    //     //         // else {
    //     //         //     console.log("user has a role that isn't null");
    //     //         // }
    //     //     });
    //     //     console.log("done");
    //     // })
    // }
    if (!state.user?.isSuperAdmin) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div>
                <h3>Hubspot Users CSV</h3>
                <Button
                    onClick={handleGenerateHubspotUsersClick}
                    disabled={generatingHubspotUsersCsv}
                    buttonText='Generate' />
                <div
                    style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        gap: '1.5rem',
                        maxWidth: '100%',
                    }}>
                    {
                        (hubspotPortalUsersCsv) &&
                        <a
                            style={{
                                background: 'rgb(18, 22, 34)',
                                borderRadius: '900px',
                                padding: '6px 20px',
                                color: 'white',
                            }}
                            href={hubspotPortalUsersCsv}
                            download={'hubspotPortalUsersData.csv'}
                        >
                            Download Portal Users
                        </a>
                    }
                    {
                        (hubspotAppUsersCsv) &&
                        <a
                            style={{
                                background: 'rgb(18, 22, 34)',
                                borderRadius: '900px',
                                padding: '6px 20px',
                                color: 'white',
                            }}
                            href={hubspotAppUsersCsv}
                            download={'hubspotAppUsersData.csv'}
                        >
                            Download App Users
                        </a>
                    }
                </div>
            </div >
        </>
    );
}

export default ExportPage;
