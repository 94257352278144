import React from "react";
import PillChart from "./pill-chart";
import PillChartTitle from "./pill-chart-title";

export interface PillChartArrayProps {
    data: {
        range: string;
        value: number;
    }[];
    title?: string;
    forReport?: boolean;
}

const PillChartArray: React.FC<PillChartArrayProps> = (props) => {
    const {
        data,
        forReport,
        title,
    } = props;

    let maxUsersInRange = 0;

    data.forEach((x) => {
        if (x.value > maxUsersInRange) {
            maxUsersInRange = x.value;
        }
    });

    const pills: JSX.Element[] = data.map((x, i) => {
        return (
            <PillChart
                key={i}
                forReport={forReport}
                index={i}
                percentage={(x.value / maxUsersInRange) * 100}
                range={x.range}
                value={x.value}
            />
        );
    });

    return (
        <>
            <PillChartTitle text={title} forReport={forReport} />
            <svg width="100%" height="100%" viewBox="0 0 800 200">
                {pills}
            </svg>
        </>
    );
};

export default PillChartArray;