import React from "react";
import { UnboundTextField } from "../unbound-fields/unbound-text-field";
import { useFormikContext } from "formik";

interface FormikTextFieldProps {
    disabled?: boolean,
    error?: boolean,
    helperText?: string,
    hidden?: boolean,
    label?: string,
    name: string,
    readOnly?: boolean,
    required?: boolean,
    value?: string | number | undefined,
    rows?: number,
    startAdornment?: string,
    onChange?: Function,
}

/**
 * A text field hooked up to formik context.
 */
export const FormikTextField: React.FC<FormikTextFieldProps> = (props) => {
    const {
        disabled,
        hidden,
        label,
        name,
        readOnly,
        required,
        rows,
        startAdornment,
        value,
        onChange,
    } = props;

    const formik = useFormikContext<any>();

    const {
        errors,
        handleBlur,
        values,
        setFieldValue,
        validateField,
    } = formik;

    return (
        <UnboundTextField
            disabled={disabled}
            hidden={hidden}
            label={label}
            required={required}
            rows={rows}
            error={errors ? Boolean(errors[name]) : undefined}
            name={name}
            onChange={async (e: string | number | undefined) => {
                await setFieldValue(name, e);
                validateField(name);
                onChange && onChange(e);
            }}
            onBlur={handleBlur}
            helperText={errors[name]?.toString()}
            value={values[name] ?? value ?? ''}
            startAdornment={startAdornment}
            readOnly={readOnly}
        />
    );
};
