import React, { useEffect, useState } from "react";
import { TabsObject } from "../../models/interfaces/tabs-object";
import { useIntercom } from "react-use-intercom";

interface PageTabsProps {
    components: TabsObject<any>[],
    initialTab: string;
}

/**
 * A small badge representing the status of an organization.
 */
export const PageTabs: React.FC<PageTabsProps> = (props) => {
    const { trackEvent } = useIntercom();
    const params = new URLSearchParams(window.location.search);
    const active = params.get("active");
    const [activeTab, setActiveTab] = useState(active ? active : props.initialTab ? props.initialTab : props.components[0].tab);
    const labelRefs: { [key: string]: React.RefObject<HTMLLabelElement | null> } = {};
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        // This isn't universal and was used for the first iteration of intercom events
        // When we do more intercom events, we need to compartmentalize these events.
        if (tab === "High-Risk Recommendations") {
            trackEvent('high-risk-rec')
        }
        else if (tab === "Billing") {
            trackEvent('billing-page')
        }
        else if (tab === "Notifications") {
            trackEvent('notifications')
        }
        else if (tab === "Add-Ons") {
            trackEvent('add-ons')
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLineWidth = () => {
        const tabLabel = labelRefs[activeTab]?.current;
        const lineWidth = tabLabel ? tabLabel.getBoundingClientRect().width : 0;
        const leftPosition = tabLabel ? tabLabel.offsetLeft : 0;
        const highlightLine = document.getElementById('highlight-line');
        if (highlightLine) {
            highlightLine.style.width = `${lineWidth}px`;
            highlightLine.style.left = `${leftPosition}px`;
        }
    }


    useEffect(() => {
        console.log("2nd tabs")
        updateLineWidth();
    }, [activeTab, updateLineWidth]);

    return (
        <>
            <div className="c-tabs-container">
                <div className="c-tabs">
                    {props.components.map(({ tab }) => (
                        <div
                            key={tab}
                            className={`c-tab-label ${activeTab === tab ? 'active' : ""}`}
                            onClick={() => handleTabClick(tab)}
                        > <label ref={(ref) => (labelRefs[tab] = { current: ref })} id={tab}>{tab}</label> </div>
                    ))}
                </div>

                <div className="c-highlight-container">
                    <div className="c-subline"></div>
                    <div className="c-highlight-line" id="highlight-line"></div>
                </div>
            </div>
            <div className="c-tab-content mt-4">
                {props.components.map(({ component: Component, tab, props }) => (
                    activeTab === tab && <Component {...props} />
                ))}
            </div>
        </>
    )
};
