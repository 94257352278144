/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import AuthState from "../../models/interfaces/global-state";
import { useStore } from "../../store/useStore";
import getFirebase from "../firebase";
import OrganizationService from "../services/organization-service";
import UserService from "../services/user-service";
import NotificationService from "../services/notification-service";

const { auth } = getFirebase();

interface AuthStateContextProps {
    state: AuthState;
    setState: Dispatch<SetStateAction<AuthState>>;
}

export const AuthStateContext = createContext({} as AuthStateContextProps)

export const AuthStateProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const initialState: AuthState = localStorage.getItem('state')
        ? JSON.parse(localStorage.getItem('state')!)
        : {}

    const [state, setState] = useState(initialState);
    const setOrganization = useStore((state) => state.setOrganization);
    const setUser = useStore((state) => state.setUser);

    if (initialState.organization) {
        setOrganization(initialState.organization);
    }

    if (initialState.user) {
        setUser(initialState.user);
    }

    useEffect(() => {
        auth.onAuthStateChanged(async (firebaseUser) => {
            const idTokenResult = await auth.currentUser?.getIdTokenResult();
            if (idTokenResult == null) {
                setState((state) => ({
                    ...state, ...{
                        authenticated: false,
                        authUser: null,
                        claims: { roles: [], superAdmin: false }
                    }
                }));
                return;
            }
            // if ((idTokenResult.claims.roles?.length || 0) === 0 && !idTokenResult.claims.superAdmin) {
            //     console.error("User is not a portal user");
            //     return;
            // }

            if (state.user && !state.user.roles) {
                setState((state) => ({
                    ...state, ...{
                        authenticated: false,
                        authUser: null,
                        claims: { roles: [], superAdmin: false }
                    }
                }));
                return;
            }

            // idTokenResult.claims.roles does not exist anymore for some reason, we are now using state to get roles/isSuperAdmin
            setState((state) => ({
                ...state, ...{
                    authenticated: firebaseUser != null,
                    authUser: firebaseUser,
                    claims: { roles: state.user?.roles!, superAdmin: state.user?.isSuperAdmin === true }
                }
            }));

            if (firebaseUser == null) {
                return;
            }

            if (state.user?.authenticationId && firebaseUser.uid === state.user?.authenticationId) {
                return;
            }

            const users = await UserService.getBy([{
                field: "email",
                operator: "==",
                value: firebaseUser.email,
            }]);

            if (users == null || users.length !== 1) {
                return;
            }
            const user = users[0];

            await setUser(user);
            setState((state) => ({
                ...state, ...{ user: user }
            }));

            if (user?.organizationId != null) {
                const org = await OrganizationService.get(user.organizationId);
                if (org != null) {
                    await setOrganization(org);
                    setState((state) => ({
                        ...state, ...{ organization: org }
                    }));
                }

                if (user.id) {
                    const notificationsList = await NotificationService.getNotifications(user.id, user?.organizationId);
                    setState((state) => ({
                        ...state, ...{
                            notifications: {
                                data: notificationsList,
                                count: notificationsList.filter((item: any) => item.read === false).length > 99
                                    ? '99+'
                                    : notificationsList?.filter((item: any) => item.read === false).length
                            }
                        }
                    }));
                }
            }
        });
    }, [setOrganization, setState, setUser, state.user?.authenticationId]);

    useEffect(() => {
        localStorage.setItem('state', JSON.stringify(state));

    }, [initialState.organization, setOrganization, state]);

    return (
        <AuthStateContext.Provider value={{ state, setState }}>
            {children}
        </AuthStateContext.Provider>
    );
}

export const useAuthState = () => {
    const context = useContext(AuthStateContext);
    if (!context) {
        throw new Error("useGlobalState must be used within a AuthStateContext");
    }
    return context;
};

export const authLogout = async () => {
    await auth.signOut();
}