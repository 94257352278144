/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useEffect, useState } from "react";

const COMPONENT_CLASS = "c-input-option";

export interface HeightInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
}

const HeightInputOption: React.FC<HeightInputOptionProps> = (props) => {
    const initialInches = props.answer
        ? (parseInt(props.answer) % 12).toFixed(0).toString()
        : "";
    const initialFeet = props.answer
        ? Math.floor(parseInt(props.answer) / 12).toString()
        : "";
    const [inches, setInches] = useState(initialInches);
    const [feet, setFeet] = useState(initialFeet);
    const inchesRef = useRef(null) as any;

    useEffect(() => {
        const newAnswer =
            parseInt(inches.length > 0 ? inches : "0") +
            parseInt(feet.length > 0 ? feet : "0") * 12;
        if (newAnswer <= 0) {
            props.onChange("");
            return;
        }
        props.onChange(newAnswer.toString());
    }, [feet, inches]);

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div className={`${COMPONENT_CLASS}__height-input-container`} >
            <div className={`${COMPONENT_CLASS}__height-input`} style={{ marginLeft: "1rem" }}>
                <input className={`${COMPONENT_CLASS}__input`}
                    autoFocus
                    onChange={(e: any) => {
                        let feetStr = e.target.value;
                        let feetValue = parseInt(feetStr);
                        if (feetValue >= 12 || feetStr.length > 2) {
                            feetStr = feetStr.substring(
                                0,
                                feetStr.length - 1
                            );
                            setFeet(feetStr);
                            props.onChange(feetStr);
                            return;
                        }
                        setFeet(feetStr);
                        if (
                            !isNaN(feetValue) &&
                            feetValue > 0 &&
                            inchesRef?.current
                        ) {
                            inchesRef.current.setFocus();
                        }
                    }}
                    type="number"
                    value={feet}
                    maxLength={2}
                    min="0"
                    onKeyPress={(e: any) => integerOnlyValidation(e)}>

                </input>
            </div>
            <span className={`${COMPONENT_CLASS}__height-span`}>FT</span>
            <div className={`${COMPONENT_CLASS}__height-input`} style={{ marginLeft: "1rem" }}>
                <input className={`${COMPONENT_CLASS}__input`}
                    onChange={(e: any) => {
                        let inchesStr = e.target.value;
                        let inchesValue = parseInt(inchesStr);
                        if (inchesValue >= 12 || inchesStr.length > 2) {
                            inchesStr = inchesStr.substring(
                                0,
                                inchesStr.length - 1
                            );
                            setInches(inchesStr);
                            props.onChange(inchesStr);
                            return;
                        }
                        setInches(inchesStr);
                    }}
                    type="number"
                    value={inches}
                    maxLength={2}
                    min="0"
                    onKeyPress={(e: any) => integerOnlyValidation(e)}>

                </input>
            </div>
            <span className={`${COMPONENT_CLASS}__height-span`}>IN</span>
        </div>
    );
};

export default HeightInputOption;
