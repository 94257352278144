import { useEffect, useState } from 'react';
import { Loader } from '../loader/loader';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import UserService from '../../utilities/services/user-service';
import { enqueueSnackbar } from 'notistack';
import { ToggleButton } from '../forms';
import { Button } from '../button/button';

interface AccountProps {
}
const COMPONENT_CLASS = "c-notifications";

const Notifications: React.FC<AccountProps> = (props: AccountProps) => {
  const { state, setState } = useAuthState();
  const [localNotificationSettings, setLocalNotificationSettings] = useState({
    highRiskUser: false,
    assessments: false,
    streaksToDos: false,
    newAccounts: false,
  })
  const [notificationSettings, setNotificationSettings] = useState({
    inactiveUser: false,
    highRiskUser: false,
    dayStreakMilestone: false,
    newAccount: false,
    completedTasks: false,
    baselineAssessment: false,
  })

  useEffect(() => {
    if (state.user && state.user.notificationSettings) {
      setNotificationSettings({
        inactiveUser: state.user.notificationSettings.inactiveUser ?? false,
        highRiskUser: state.user.notificationSettings.highRiskUser ?? false,
        dayStreakMilestone: state.user.notificationSettings.dayStreakMilestone ?? false,
        newAccount: state.user.notificationSettings.newAccount ?? false,
        completedTasks: state.user.notificationSettings.completedTasks ?? false,
        baselineAssessment: state.user.notificationSettings.baselineAssessment ?? false,
      })

      setLocalNotificationSettings({
        highRiskUser: state.user.notificationSettings.highRiskUser ?? false,
        assessments: state.user.notificationSettings.baselineAssessment ?? false,
        streaksToDos: (state.user.notificationSettings.dayStreakMilestone && state.user.notificationSettings.completedTasks) ?? false,
        newAccounts: (state.user.notificationSettings.newAccount && state.user.notificationSettings.inactiveUser) ?? false,
      })
    }
  }, [state.user]);

  return (
    <div className={COMPONENT_CLASS}>
      <Loader isVisible={false} />
      <div className={`${COMPONENT_CLASS}__container`}>
        <h2>Platform Notifications</h2>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>High Risk Users</p>
            <div className='body'>Show a notification every time one of my users completes an assessment and is flagged as having high-risk musculoskeletal health.</div>
          </div>
          <div>
            <ToggleButton
              id='highRiskUserSetting'
              isChecked={localNotificationSettings.highRiskUser}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ highRiskUser: !localNotificationSettings.highRiskUser }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ highRiskUser: !notificationSettings.highRiskUser }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>Assessments</p>
            <div className='body'>Show a notification every time one of my users completes a lifestyle or movement assessment.</div>
          </div>
          <div>
            <ToggleButton
              id='assessmentsSetting'
              isChecked={localNotificationSettings.assessments}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ assessments: !localNotificationSettings.assessments }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ baselineAssessment: !notificationSettings.baselineAssessment }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>Streaks & To Dos</p>
            <div className='body'>Show a notification every time one of my users completes a session, reads an article, or increases their day streak.</div>
          </div>
          <div>
            <ToggleButton
              id='streaksToDosSettings'
              isChecked={localNotificationSettings.streaksToDos}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ streaksToDos: !localNotificationSettings.streaksToDos }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ dayStreakMilestone: !notificationSettings.dayStreakMilestone, completedTasks: !notificationSettings.completedTasks }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>New Accounts</p>
            <div className='body'>Show a notification every time a new user registers for an account.</div>
          </div>
          <div>
            <ToggleButton
              id='newAccountsSettings'
              isChecked={localNotificationSettings.newAccounts}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ newAccounts: !localNotificationSettings.newAccounts }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ newAccount: !notificationSettings.newAccount, inactiveUser: !notificationSettings.inactiveUser }
                })
              }} />
          </div>
        </div>
        <div className={'c-login-modal__button-area button-gap'}>
          <Button
            type='link'
            buttonText='Cancel'
            onClick={() => {
              setNotificationSettings({
                inactiveUser: state.user?.notificationSettings?.inactiveUser ?? false,
                highRiskUser: state.user?.notificationSettings?.highRiskUser ?? false,
                dayStreakMilestone: state.user?.notificationSettings?.dayStreakMilestone ?? false,
                newAccount: state.user?.notificationSettings?.newAccount ?? false,
                completedTasks: state.user?.notificationSettings?.completedTasks ?? false,
                baselineAssessment: state.user?.notificationSettings?.baselineAssessment ?? false,
              }); setLocalNotificationSettings({
                highRiskUser: state.user?.notificationSettings?.highRiskUser ?? false,
                assessments: state.user?.notificationSettings?.baselineAssessment ?? false,
                streaksToDos: (state.user?.notificationSettings?.dayStreakMilestone && state.user?.notificationSettings?.completedTasks) ?? false,
                newAccounts: (state.user?.notificationSettings?.newAccount && state.user?.notificationSettings?.inactiveUser) ?? false,
              })
            }} />
          <Button
            buttonStyle='primary'
            buttonText='Save Changes'
            onClick={async () => {
              if (state.user && state.user.id) {
                const user = await UserService.get(state.user.id);
                if (user && state.user) {
                  user.notificationSettings = notificationSettings;
                  await UserService.update(user, state.user).then((result: any) => {
                    console.log(result);
                    const stateOrg = state.user;
                    stateOrg!.notificationSettings = notificationSettings;
                    setState((state) => ({
                      ...state, ...{ user: stateOrg }
                    }));
                    enqueueSnackbar("Your information has been updated.", { variant: "toast", width: "450px", autoHideDuration: 5000 });
                  });
                }

              }
            }} />
        </div>
      </div>
    </div >
  );
}
export default Notifications;
