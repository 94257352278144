import { AnswerOption } from "../../models/interfaces/questions/answer-option";
import React from "react";

const COMPONENT_CLASS = "c-custom-input-option";

export interface CustomInputOptionProps {
    id?: string;
    onChange: (newAnswer: string) => void;
    answerOption: AnswerOption;
    isSelected?: boolean;
    isLifestyleOption?: boolean;
}

const CustomInputOption: React.FC<CustomInputOptionProps> = (props) => {
    const hasImage = props.answerOption.answerImageUrl;

    return (
        <div
            id={props.id}
            className={`${COMPONENT_CLASS} ${props.isLifestyleOption ? "lifestyle-option" : ""} ${props.isSelected ? "-selected" : ""
                }`}
            onClick={() => props.onChange(props.answerOption.answerId)}
            onMouseOver={(e) => (e.target as HTMLElement).classList.toggle("hover")}>
            {hasImage && (
                <div className={`${COMPONENT_CLASS}__image`}>
                    <img
                        src={props.answerOption.answerImageUrl}
                        alt={props.answerOption.answerText}
                    />
                </div>
            )}
            <div className={`${COMPONENT_CLASS}__content`}>
                <label className="ion-text-wrap">
                    {props.answerOption.answerText}
                    {props.answerOption.answerDetails == null ? (
                        ""
                    ) : (
                        <div>{props.answerOption.answerDetails}</div>
                    )}
                </label>
            </div>
        </div>
    );
};

export default CustomInputOption;
