import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DropdownSelect } from "../../components/forms/dropdown-select/dropdown-select";
import { MultiSelect, TextTypeInput, ToggleButton } from "../../components/forms";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { SubscriptionLink } from "../../models/interfaces/subscription-link";
import { DropdownSelectOption } from "../../models/interfaces/dropdown-select-option";
import { DatePicker } from "../../components/forms/date-picker";
import { Loader } from "../../components/loader/loader";
import { DateTime } from "luxon";
import { useStore } from "../../store/useStore";
import { enqueueSnackbar } from "notistack";
import { GroupOptions } from "../../models/interfaces/group-options";
import { DeeplinkStatus } from "../../models/enumerations/deeplink-status";
import SubscriptionLinkService from "../../utilities/services/subscription-link-service";

const SUBSCRIPTION_LINK_TYPE = "subscriptionLink";

interface SubscriptionLinkAddEditPageParams {
    id: string;
}

interface AddEditFormData {
    name: string;
    group?: GroupOptions;
    tags?: DropdownSelectOption[];
    startDate?: string;
    endDate?: string;
    Active: boolean;
    videoUrl?: string;
}

const SubscriptionLinkAddEditPage: React.FC<SubscriptionLinkAddEditPageParams> =
    (props: SubscriptionLinkAddEditPageParams) => {
        const { id } = useParams<SubscriptionLinkAddEditPageParams>();
        const { state } = useAuthState();
        const history = useHistory();
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const organization = useStore((state) => state.organization);

        if (!state.user || !state.user?.isSuperAdmin) {
            throw new Error("You must be a super admin");
        }

        if (!organization || organization.id === undefined) {
            throw new Error("An organization is necessary to use the Add or Edit page.");
        }

        const methods = useForm<AddEditFormData>();

        const {
            getGroupsDropDownSelectOptionsByOrg,
            groupsDropdownSelectOption,
            getTagsDropDownSelectOptions,
            tagsDropdownSelectOptions,
            getTagById,
        } = useStore();

        const {
            register,
            handleSubmit,
            formState: { errors },
            setValue,
            reset,
            control,
            setError
        } = methods;

        useEffect(() => {
            const loadDependencies = async () => {
                if (organization.id) {
                    await getGroupsDropDownSelectOptionsByOrg(organization.id);
                    await getTagsDropDownSelectOptions(organization.id);
                }
            };

            const loadSubscriptionLink = async () => {
                const subscriptionLink = await SubscriptionLinkService.get(id);

                if (!subscriptionLink) {
                    return;
                }

                let formData: AddEditFormData = {
                    name: subscriptionLink.name,
                    Active: subscriptionLink.status === DeeplinkStatus.Active,
                    videoUrl: subscriptionLink.videoUrl
                };

                formData = {
                    ...formData, group: subscriptionLink.group,
                }

                if (subscriptionLink.tags) {
                    formData.tags = await Promise.all(subscriptionLink.tags
                        .map(async (tag) => {
                            try {
                                return {
                                    label: tag.name,
                                    value: tag.id
                                } as DropdownSelectOption;
                            } catch (error: any) {
                                console.log(error, tag);
                                throw error;
                            }
                        }));
                }

                reset({
                    name: formData.name,
                    group: formData.group,
                    Active: formData.Active,
                    tags: formData.tags,
                    startDate: subscriptionLink.startDate,
                    endDate: subscriptionLink.endDate,
                    videoUrl: subscriptionLink.videoUrl,
                });

                setIsLoading(false);
            };

            loadDependencies();

            if (id) {
                loadSubscriptionLink();
            } else {
                reset({
                    Active: true
                });
                setIsLoading(false);
            }
        }, [getGroupsDropDownSelectOptionsByOrg, getTagsDropDownSelectOptions, id, reset, getTagById, setValue, organization.id]);

        const isFormValid = (formData: AddEditFormData) => {
            if (!formData.startDate && !formData.endDate) {
                return true;
            }

            const start = DateTime.fromISO(formData.startDate!);
            const end = DateTime.fromISO(formData.endDate!);

            if (end < start) {
                setError("endDate",
                    {
                        type: "custom",
                        message: "The end date cannot be before the start date"
                    });
                return false;
            }

            return true;
        }

        const onSubmit = async (data: any) => {
            setIsLoading(true);

            // typecasting for use
            const formData = data as AddEditFormData;

            if (!isFormValid(formData)) {
                setIsLoading(false);
                return;
            }

            var entity = {
                name: formData.name,
                tags: [],
                status: formData.Active ? DeeplinkStatus.Active : DeeplinkStatus.Inactive,
                organizationId: state.organization.id
            } as SubscriptionLink;

            if (formData.tags) {
                formData.tags.forEach((tag) => {
                    entity?.tags?.push({
                        id: tag.value,
                        name: tag.label
                    })
                });
            }

            entity.group = formData.group;
            entity.startDate = formData.startDate;
            entity.endDate = formData.endDate;
            entity.videoUrl = formData.videoUrl;

            if (id) {
                entity.id = id;

                try {
                    await SubscriptionLinkService.update(entity);
                } catch (err) {
                    console.error('Failed to update the subscription link');
                }
            } else {
                try {
                    const createdSubscriptionLink = await SubscriptionLinkService.add(entity);
                    const baseUrl = process.env.REACT_APP_SYMMIO_PORTAL_URL;

                    if (baseUrl && createdSubscriptionLink.id) {
                        createdSubscriptionLink.url = `${baseUrl}/link-account-creation/${createdSubscriptionLink.id}/${SUBSCRIPTION_LINK_TYPE}`;
                        await SubscriptionLinkService.update(createdSubscriptionLink);
                    }

                } catch (err: any) {
                    console.error('Failed to create a subscription link');
                    enqueueSnackbar(err);
                }
            }
            id ? enqueueSnackbar("Link updated!", { variant: "toast", width: "450px" }) : enqueueSnackbar("Link created!", { variant: "toast", width: "450px" });
            history.goBack();
        };

        return (
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 max-w-screen-md">
                    <h2>{(!id) ? "Add Subscription Link" : "Edit Subscription Link"}</h2>
                    <Loader isVisible={isLoading} />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                                <TextTypeInput id="name"
                                    className="col-span-1"
                                    label="Link Name"
                                    registerHook={register}
                                    registerOptions={{ required: "A link must have a name" }}
                                    errorState={errors.name} />
                            </div>
                            <DropdownSelect id="group"
                                label="Group"
                                optionsArray={groupsDropdownSelectOption}
                                control={control}
                                errorState={errors.group} />
                            <MultiSelect id="tags"
                                label="Tags"
                                control={control}
                                optionsArray={tagsDropdownSelectOptions}
                                setValue={setValue}
                                errorState={errors.tags} />
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                                <TextTypeInput id="videoUrl"
                                    className="col-span-1"
                                    label="Landing Page Video URL"
                                    registerHook={register}
                                    errorState={errors.videoUrl} />
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <DatePicker id="startDate"
                                    className="col-span-1"
                                    label="Start Date"
                                    control={control} />
                                <DatePicker id="endDate"
                                    className="col-span-1"
                                    label="End Date"
                                    control={control} />
                            </div>
                            {id &&
                                <ToggleButton id="active" register={register} mainLabelText="Active" />
                            }
                            <div className={'actions-container'}>
                                <button className={'cancel'} onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    history.goBack();
                                }}>Cancel</button>
                                <button className={'c-button'}
                                    type="submit"
                                >{id ? "Save Changes" : "Add Link"} </button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        );
    };

export default SubscriptionLinkAddEditPage;
