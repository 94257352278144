import { PlanType } from "./enumerations/plan-type";
import { NumericListOptions } from "./interfaces/numeric-list-options";

export const PlanTypeOptions: NumericListOptions[] = [
    {
        value: PlanType.Starter,
        label: PlanType[PlanType.Starter],
    }, {
        value: PlanType.Business,
        label: PlanType[PlanType.Business],
    }, {
        value: PlanType.Enterprise,
        label: PlanType[PlanType.Enterprise],
    },
    {
        value: PlanType.ThirdParty,
        label: "Third-Party Subscription",
    },
]