import { StateCreator } from "zustand";
import { Organization } from "../models/interfaces/organization";
import { User } from "../models/interfaces/user";
import { UnifiedStore } from "./useStore";

export interface GlobalSliceState {
    organization: Organization | null;
    user: User | null;
}

const initialState = {
    organization: null,
    user: null,
};

export interface GlobalSlice extends GlobalSliceState {
    setOrganization: (organization: Organization) => Promise<void>;
    setUser: (user: User) => Promise<void>;
}

export const createGlobalSlice: StateCreator<
    UnifiedStore,
    [],
    [],
    GlobalSlice
> = (set, get) => ({
    ...initialState,

    setOrganization: async (organization: Organization) =>
        set({
            organization: organization,
        }),

    setUser: async (user: User) => set({ user: user }),
});
