import React from 'react';
import { EditOrganizationForm } from '../../components/organization-management/organization-form/edit-organization-form';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import UserUtil from '../../utilities/user-util';
import { EditSubOrganizationForm } from '../../components/organization-management/edit-sub-organization-form/edit-sub-organization-form';

type AddAccountModalProps = {
};

export const AddOrganizationPage: React.FC<AddAccountModalProps> = (props: AddAccountModalProps) => {

    const { state } = useAuthState();
    const isAccountHolder = UserUtil.isAccountHolder(state.user);

    return (
        <div style={{ maxWidth: '1000px' }}>
            {
                isAccountHolder
                    ? (
                        <EditSubOrganizationForm
                            redirect={"/organizations"}
                        />
                    )
                    : (
                        <EditOrganizationForm
                            redirect={"/organizations"}
                        />
                    )
            }

        </div>
    );
}