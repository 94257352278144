import qrCodeImg from "../../assets/images/qr-code-mobile-stores.png";
import googlePlayImg from "../../assets/images/google-play-button.png";
import appStoreImg from "../../assets/images/app-store-button.png";
import symmioLogoImg from "../../assets/images/symmio-logos/symmio-favicon.png";

interface QrCodeCardProps {
    email?: string;
}

const COMPONENT_CLASS = "c-qr-code-card";

const QrCodeCard: React.FC<QrCodeCardProps> = (props) => {
    const {
        email,
    } = props;

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__text`}>
                Scan this code with your device’s camera to download the Symmio app, or go to your device’s app store and search for <strong>Symmio</strong>.
            </div>
            <div className="flex flex-row gap-x-10 gap-y-6 items-center flex-wrap justify-center">
                <div className={`${COMPONENT_CLASS}__qr-code hide-on-mobile`}>
                    <img src={qrCodeImg} alt="QR Code" />
                </div>
                <div className="flex flex-col gap-8 justify-center items-center">
                    <div className="flex gap-2 items-center justify-center flex-wrap">
                        <div className={`${COMPONENT_CLASS}__symmio-logo`}>
                            <img src={symmioLogoImg} alt="Symmio Logo" />
                        </div>

                        <div className={`${COMPONENT_CLASS}__symmio-logo-text`}>
                            Symmio
                        </div>
                    </div>

                    <div className="flex flex-row gap-6">
                        <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStoreImg} alt="App Store" className="app-store-button" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlayImg} alt="Google Play" className="google-play-button" /></a>
                    </div>
                </div>
                {email &&
                    <div className="text-sm">
                        Login with <span className="font-bold">{email}</span>
                    </div>
                }
            </div>
        </div>
    );
}

export default QrCodeCard;