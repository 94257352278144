/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import { Question } from "../../models/interfaces/questions/question";
import { QuestionGroup } from "../../models/interfaces/questions/question-group";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import useAccountSetupQuestions from "../../utilities/hooks/use-account-setup-questions";
import UserService from "../../utilities/services/user-service-assessments";
import ScreenSlide from "../screen-slide/screen-slide";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import getFirebase from "../../utilities/firebase";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-account-setup-screen";

const setupQuestions: QuestionGroup[] = [
    {
        groupId: GroupIds.SETUP,
        groupSort: 0,
        questions: [
            {
                questionTitleId: "biological-sex-question",
                questionId: "biologicalSex",
                questionText: "What is your biological sex?",
                sort: 1,
                answerFormat: AnswerFormats.OPTIONS,
                answerDetailsId: "biological-sex-info-text",
                answerDetails:
                    "We love and respect everyone but we need a baseline for your body’s biological composition, so we can assess where you are on your wellness journey.",
                answerOptions: [
                    {
                        id: "biological-sex-female-button",
                        answerId: "FEMALE",
                        answerText: "Female",
                    },
                    {
                        id: "biological-sex-male-button",
                        answerId: "MALE",
                        answerText: "Male",
                    },
                ],
            },
            {
                questionId: "birthdate",
                questionText: "Your birthdate:",
                sort: 2,
                answerFormat: AnswerFormats.DATE,
            },
        ],
    },
];

export interface AccountSetupScreenProps {
}

const AccountSetupScreen: React.FC<AccountSetupScreenProps> = (props) => {
    const questionGroups = useMemo<QuestionGroup[]>(() => setupQuestions, []);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [questionResponses, setQuestionResponses] = useState<
        QuestionResponse[]
    >([]);
    const slides = useAccountSetupQuestions(questionGroups);
    let history = useHistory();
    const { state } = useAuthState();
    const { auth } = getFirebase();
    // const { currentIdentity } = useStoreAssessments((state) => ({
    //     currentIdentity: state.currentIdentity,
    // }));

    const currentIdentity = state.user;



    if (!currentIdentity) {
        // throw new Error(
        //     "Current Identity cannot be undefined when using Account Setup"
        // );
        console.log("Current Identity cannot be undefined when using Account Setup")

        if (state.authenticated) {
            auth.signOut();
        }

        history.push("/login");
    }



    useEffect(() => {
        document.body.classList.add("-nav-hidden");

        return () => {
            document.body.classList.remove("-nav-hidden");
        };
    }, []);

    useEffect(() => {
        const slide = slides[currentSlideIndex];
        if (!slide?.questions) {
            return;
        }
        const question = slide.questions![0];
        if (question.questionId === "biologicalSex") {
            getNextPage();
        }
    }, [questionResponses]);

    const getNextPage = () => {
        var slide = slides[currentSlideIndex];
        if (slide.slideType === SlideType.Question && slide.questions) {
            for (const question of slide.questions) {
                const questionResponse = questionResponses.find(
                    (x) => x.questionId === question.questionId
                );

                // validate has answer
                if (questionResponse == null) {
                    return;
                }
                if (
                    questionResponse.answerId == null &&
                    questionResponse.answerResponse == null
                ) {
                    return;
                }
                if (
                    questionResponse.answerResponse != null &&
                    questionResponse.answerResponse.length === 0
                ) {
                    return;
                }
            }
        }
        setCurrentSlideIndex(currentSlideIndex + 1);
    };

    const submitProfile = async () => {
        if (!state.user) {
            return;
        }
        // Good regex for checking valid dates including leap years (takes these formats: dd/mm/yyyy, dd-mm-yyyy or dd.mm.yyyy)
        // var dateReg = new RegExp(['^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'].join(''), "g");
        let updatedUser = state.user;
        updatedUser.isAccountSetup = true;

        questionResponses.forEach((response) => {
            switch (response.questionId) {
                case "biologicalSex":
                    updatedUser.biologicalSex = response.answerResponse;
                    break;

                case "birthdate":
                    updatedUser.birthdate = response.answerResponse && response.answerResponse !== "Invalid Date" ? Utils.convertToISO(response.answerResponse) : undefined;
                    break;
            }
        });
        if (updatedUser.birthdate) {
            await UserService.updateByPortalUser(updatedUser);
            history.push("/wellness-assessment");
        }
    };

    const recordAnswer = (selectedAnswer: string, question: Question) => {
        if (
            questionResponses.find((x) => x.questionId === question.questionId)
        ) {
            // if response already exists
            const editingIndex = questionResponses.findIndex(
                (x) => x.questionId === question.questionId
            );
            questionResponses[editingIndex].answerResponse = selectedAnswer;
            setQuestionResponses(questionResponses);
        } else {
            // response does not already exist
            const selectedAnswerValue =
                question.answerOptions?.find(
                    (x) => x.answerId === selectedAnswer
                )!.answerText ?? selectedAnswer;
            const answer = {
                categoryId: questionGroups[0].categoryId!,
                groupId: questionGroups[0].groupId,
                answerId: selectedAnswer,
                questionId: question.questionId,
                answerResponse: selectedAnswerValue,
            };
            setQuestionResponses([...questionResponses, answer]);
        }
    };

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }

    if (state.user && state.user.isAccountSetup) {
        return <Redirect to="/wellness-assessment" />;
    }

    return (
        <div style={{ width: "100%" }}>
            <div className={`${COMPONENT_CLASS} h-full`}>
                {
                    // if
                    questionGroups && (
                        <div
                            className={`${COMPONENT_CLASS}__content h-full ${currentSlideIndex + 1 === slides.length
                                ? "has-button"
                                : ""
                                }`}>
                            {
                                // if
                                slides[currentSlideIndex] && (
                                    <ScreenSlide
                                        recordedAnswers={questionResponses}
                                        recordAnswer={recordAnswer}
                                        page={slides[currentSlideIndex]}
                                        setupSubmit={submitProfile}
                                        settingsOnBack={() => {
                                            setCurrentSlideIndex(currentSlideIndex - 1);
                                            setQuestionResponses([]);
                                        }}
                                    />
                                )
                            }
                            <div className={`${COMPONENT_CLASS}__actions`}>
                                {
                                    // if
                                    // currentSlideIndex + 1 == slides.length && (
                                    //     <Button
                                    //         variant="contained"
                                    //         onClick={() => submitProfile()}
                                    //         disabled={!questionResponses.find(item => item.questionId === "birthdate")?.answerResponse}>
                                    //         Submit
                                    //     </Button>
                                    // )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    // if
                    !questionGroups && (
                        <IonItem>
                            <IonLabel>
                                Loading Onboarding questions....
                            </IonLabel>
                        </IonItem>
                    )
                }
            </div>
        </div>
    );
};

export default AccountSetupScreen;
