import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/outline';
import React, { CSSProperties } from 'react';

const COMPONENT_CLASS = "c-button";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ButtonProps {
    onClick?: (e?: any) => void;
    buttonText?: string;
    disabled?: boolean;
    style?: any;
    type?: "default" | "back" | "plus" | "link" | "table-action";
    buttonStyle?: "primary" | "secondary" | "white" | "blue";
    buttonType?: "button" | "submit" | "reset";
    inputClassName?: string;
    inputStyle?: CSSProperties;
    form?: string;
    customIcon?: React.ReactNode;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    return (
        <button
            className={`${props.type === "link" ? "-link"
                : `${COMPONENT_CLASS} ${props.type === "table-action" ? '-table-action' : ''}
                ${props.buttonStyle === "secondary" ? '-secondary' : ''}
                ${props.buttonStyle === "white" ? '-white' : ''}
                ${props.type === "back" ? props.buttonText ? '-back-text' : '-back' : ''}
                ${props.buttonStyle === "blue" ? '-blue' : ''}`} flex items-center justify-center gap-2 ${props.inputClassName}`}
            {...(props.inputStyle !== undefined ? { style: props.inputStyle } : {})}
            {...(props.form !== undefined ? { form: props.form } : {})}

            disabled={props.disabled}
            type={props.buttonType}
            style={props.style}
            onClick={props.onClick}>
            { // if
                props.type === "back" &&
                <ChevronLeftIcon className={`${props.disabled === true ? "-disabled-icon" : ""}`} />

            }
            { // if
                props.type === "plus" &&
                <PlusIcon className={`${props.disabled === true ? "-disabled-icon" : ""}`} />

            }
            {
                props.customIcon && <>{props.customIcon}</>

            }
            {props.buttonText}

        </button>
    );
}

export { Button };
