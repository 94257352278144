import { IonSpinner, IonSkeletonText } from "@ionic/react";

const COMPONENT_CLASS = "c-card";
interface SkeletonCardProps {
    skeletonCardCount?: number;
    fullImage?: boolean;
}

const SkeletonCardList: React.FC<SkeletonCardProps> = (props) => {
    const skeletonsToReturn = props.skeletonCardCount
        ? props.skeletonCardCount
        : 1;
    const loopArr = Array.from(Array(skeletonsToReturn).keys());
    const width = !props.fullImage ? "80px" : "100%";

    return (
        <>
            {loopArr.map((num, index) => {
                return (
                    <div className={`${COMPONENT_CLASS}`} key={index}>
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div
                                className={`${COMPONENT_CLASS}__content__wrapper`}>
                                <IonSkeletonText
                                    animated={true}
                                    style={{ width: width }}></IonSkeletonText>
                                <div
                                    className={`${COMPONENT_CLASS}__content__loading`}>
                                    <IonSpinner
                                        name="dots"
                                        color={"medium"}></IonSpinner>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SkeletonCardList;
