import React, { useRef, useState } from "react";

const COMPONENT_CLASS = "c-input-option";

export interface TimeInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
    period?: "am" | "pm";
}

class TimeState {
    public hours: string;
    public minutes: string;
    public period: "am" | "pm" | undefined;

    constructor(time?: string, defaultPeriod?: "am" | "pm") {
        if (time) {
            const parts = time.split(":");
            this.minutes = parts[1];
            const hoursInt = parseInt(parts[0]);
            if (hoursInt === 0) {
                this.hours = "12";
            } else if (hoursInt >= 13) {
                this.hours = (hoursInt - 12).toString();
            } else {
                this.hours = hoursInt.toString();
            }
            this.period = hoursInt >= 12 ? "pm" : "am";
        } else {
            this.hours = "";
            this.minutes = "";
            this.period = defaultPeriod || "am";
        }
    }

    public getAnswer(): string {
        if (this.hours.length === 0 || this.minutes.length === 0) {
            return "";
        }
        const militaryHours =
            (parseInt(this.hours) % 12) + (this.period === "am" ? 0 : 12);
        const minutes = parseInt(this.minutes);
        return `${militaryHours >= 10 ? "" : "0"}${militaryHours}:${minutes >= 10 ? "" : "0"
            }${minutes}`;
    }
}

const TimeInputOption: React.FC<TimeInputOptionProps> = (props) => {
    const [time, setTime] = useState(new TimeState(props.answer, props.period));
    const minutesRef = useRef(null) as any;

    const handleHoursChange = (value: string): TimeState => {
        let hours = parseInt(value);
        if (hours > 12) {
            value = value.substring(0, value.length - 1);
            hours = parseInt(value);
        }
        if (hours < 1 && value.length <= 1) {
            time.hours = value;
        } else if (hours < 1 || hours > 12 || isNaN(hours)) {
            time.hours = "";
        } else {
            time.hours = value;
        }

        props.onChange(time.getAnswer());
        setTime(time);
        return time;
    };

    const handleMinutesChange = (value: string): TimeState => {
        let minutes = parseInt(value);
        if (minutes > 59) {
            value = value.substring(0, value.length - 1);
            minutes = parseInt(value);
        }
        if (minutes < 0 || minutes > 59 || isNaN(minutes)) {
            time.minutes = "";
        } else {
            time.minutes = value;
        }

        props.onChange(time.getAnswer());
        setTime(time);
        return time;
    };

    const handlePeriodChange = (value: "am" | "pm"): TimeState => {
        time.period = value;
        props.onChange(time.getAnswer());
        setTime(time);
        return time;
    };

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__sleep-wake-container`}>
                <div className={`${COMPONENT_CLASS}__col`}>
                    <div className={`${COMPONENT_CLASS}__hour-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            value={time.hours}
                            onChange={(e: any) => {
                                const timeState = handleHoursChange(
                                    e.target.value
                                );
                                if (
                                    timeState.hours.length === 2 &&
                                    minutesRef?.current
                                ) {
                                    minutesRef.current.setFocus();
                                }
                            }}
                            type="number"
                            maxLength={2}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>

                        </input>
                    </div>
                    {/* <IonItem lines="none" data-testid="time-options">
                        <IonInput
                            autofocus
                            value={time.hours}
                            maxlength={2}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}
                            onIonChange={(e: any) => {
                                const timeState = handleHoursChange(
                                    e.detail.value
                                );
                                if (
                                    timeState.hours.length == 2 &&
                                    minutesRef?.current
                                ) {
                                    minutesRef.current.setFocus();
                                }
                            }}
                            type="number"
                        />
                    </IonItem> */}
                </div>
                <span className={`${COMPONENT_CLASS}__time`}>:</span>
                <div className={`${COMPONENT_CLASS}__col -condensed`}>
                    <div className={`${COMPONENT_CLASS}__minute-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            ref={minutesRef}
                            value={time.minutes}
                            onChange={(e) =>
                                handleMinutesChange(
                                    e.target.value == null
                                        ? ""
                                        : e.target.value!.toString()
                                )
                            }
                            type="number"
                            maxLength={2}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>

                        </input>
                    </div>
                    {/* <IonItem lines="none">
                        <IonInput
                            ref={minutesRef}
                            value={time.minutes}
                            maxlength={2}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}
                            onIonChange={(e) =>
                                handleMinutesChange(
                                    e.detail.value == null
                                        ? ""
                                        : e.detail.value!.toString()
                                )
                            }
                            type="number"
                        />
                    </IonItem> */}
                </div>
                <div className={`${COMPONENT_CLASS}__time-container`}>
                    <div className={`${COMPONENT_CLASS}__time-types`}>
                        <input
                            name="weight-type"
                            type="radio"
                            id="AM"
                            value="am"
                            checked={time.period === "am"}
                            onClick={() => handlePeriodChange("am")}
                            className={time.period === "am" ? "-active" : ""}
                        />
                        <label className={`am-radio-label __radio ${COMPONENT_CLASS}__weight-button ${time.period === "am" ? "-active" : ""}`} htmlFor="AM" >AM</label>
                        <input
                            name="weight-type"
                            type="radio"
                            id="PM"
                            value="pm"
                            checked={time.period === "pm"}
                            onClick={() => handlePeriodChange("pm")}
                            className={time.period === "pm" ? "-active" : ""}
                        />
                        <label className={`pm-radio-label __radio ${COMPONENT_CLASS}__weight-button ${time.period === "pm" ? "-active" : ""}`} htmlFor="PM">PM</label>
                    </div>
                    {/* <IonButton
                        fill="clear"
                        size="small"
                        onClick={() => handlePeriodChange("am")}
                        className={time.period === "am" ? "-active" : ""}>
                        AM
                    </IonButton>
                    <IonButton
                        fill="clear"
                        size="small"
                        onClick={() => handlePeriodChange("pm")}
                        className={time.period === "pm" ? "-active" : ""}>
                        PM
                    </IonButton> */}
                </div>
            </div>

        </div>
    );
};

export default TimeInputOption;
