export enum NotificationType {
  NewAccount = 0,
  HighRiskUser = 1,
  CompletedTasks = 2,
  InactiveAccount = 3,
  DayStreak = 4,
  CompletedAssessment = 5,
  UpdatedAssessment = 6,
  MovementHeath = 7,
  BehavioralHealth = 8,
  BodyComposition = 9,
  BreathingQuality = 10,
  InjuryHistory = 11,
  NutritionAwareness = 12,
  PhysicalActivity = 13,
  SleepWellness = 14,
  LifestyleAssessment = 15,
}