import React from "react";
import { ToggleButtonProps } from "./toggle-button.props";
import "./toggle-button.styles.scss";

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
    let formRegistration: any;

    if (props.register) {
        formRegistration = {
            ...props.register(props.id, { pattern: `/\\W${props.id}/ig` })
        }
    }


    return (
        <div className="toggle-button-container" style={props.containerStyle}>
            {(props.mainLabelText && !props.isRightSideLabels) &&
                <div className={`label-container`}>
                    <label className="toggle-button-label" htmlFor={props.id}>{props.mainLabelText}</label>
                </div>
            }
            <label className="switch">
                <input type="checkbox"
                    id={props.id}
                    {...formRegistration}
                    name={props.id}
                    placeholder={props.id}
                    onClick={props.onClick}
                    {...(props.isChecked !== undefined ? { checked: props.isChecked } : {})}
                />
                <span className="slider round"></span>
            </label>
            {(props.mainLabelText && props.isRightSideLabels) &&
                <div className={`label-container`}>
                    <label className={`toggle-button-label-small`} htmlFor={props.id}>{props.mainLabelText}</label>
                    {(props.subLabelText) &&
                        <label className={`toggle-button-label-small -light`} htmlFor={props.id}>{props.subLabelText}</label>
                    }
                </div>
            }

        </div>
    )
};
