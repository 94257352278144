import React from "react";

export interface PillChartBottomTextProps {
    forReport?: boolean;
    index: number;
    range: string;
}

const PillChartBottomText: React.FC<PillChartBottomTextProps> = (props) => {
    const {
        forReport,
        index,
        range,
    } = props;

    const x = forReport ? 11.5 : 17

    const reportStyle = {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 700,
        color: '#121622',
        fontFamily: 'sans-serif',
    };

    const dashboardStyle = {
        fontSize: '10px',
        fontFamily: 'sans-serif',
    }

    return (
        <text
            x={x + (index * 85)}
            y={193}
            textAnchor="middle"
            dominantBaseline="middle"
            style={forReport ? reportStyle : dashboardStyle}
        >
            {range}
        </text>
    );
};



export default PillChartBottomText;