import React from "react";
import CountdownTimer from "../countdown-timer/countdown-timer";

export interface InactivityMessageProps {
    onCountdownExpired: Function
}

/**
 * Represents the message that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityMessage: React.FC<InactivityMessageProps> = (props) => {
    const { onCountdownExpired } = props;

    const message = "Your session is about to expire due to inactivity. You will be logged out in ";

    return (
        <div className="modal-message" style={{}}>
            <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message} <CountdownTimer countdownTimestampMs={(Date.now() + 900000)} onCountdownExpired={onCountdownExpired} /></p>
        </div>

    );
};

export default InactivityMessage;

