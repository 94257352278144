import { VideoSize } from "../../../models/enumerations/video-size";
import { VideoDetail } from "../../../models/interfaces/video-detail";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface VideoIdParams {
    videoId: string;
    videoSize?: VideoSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/videos`;
const detailsFromIdEndpoint = `${baseEndpoint}/details-from-id`;
const urlFromIdEndpoint = `${baseEndpoint}/url-from-id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const VideoService = {
    getDetailsFromId: ServiceBase.list<VideoDetail, VideoIdParams>(
        detailsFromIdEndpoint
    ),
    getUrlFromId: ServiceBase.list<string, VideoIdParams>(urlFromIdEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default VideoService;

// #endregion Exports
