/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import ValidationRules from '../../../utilities/yup/validation-rules';
import { PaymentType } from '../../../models/enumerations/payment-type';
import { PlanType } from '../../../models/enumerations/plan-type';
import { Moment } from 'moment';
import { UserLimit } from '../../../models/enumerations/user-limit';

const PaidOrganizationValidationRules = () => {
    return Yup.object().shape({
        organizationName: Yup
            .string()
            .required('Enter an organization name'),

        accountHolderEmail: Yup
            .string()
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.organizationId;
                    if (!editing) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter the email which will be used to create an account holder'
            })
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value?.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g);
                    }
                    return true;
                },
                message: 'Enter a valid email'
            }),

        accountHolderId: Yup
            .object(),

        accountManager: Yup
            .object()
            .nullable(),

        mySymmioYearlyPrice: Yup
            .number()
            .typeError('Must be numeric')
            .max(9999, 'Cannot be above 9999')
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter an amount',
            }),

        mySymmioMonthlyPrice: Yup
            .number()
            .typeError('Must be numeric')
            .max(9999, 'Cannot be above 9999')
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter an amount',
            }),

        subscriptionName: Yup
            .string()
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter a subscription name',
            }),

        plan: Yup
            .object()
            .required('Select a plan'),

        paymentCycle: Yup
            .object()
            .required('Select a payment cycle'),

        price: Yup
            .number()
            .typeError('Must be numeric')
            .test({
                test: (value, context) => {
                    // we only want to validate this field if the plan is not
                    // enterprise (will be the case if there is no paymentType) or
                    // if the plan is enterprise and the payment type is flat fee.
                    const shouldValidate = (
                        !context.parent.paymentType
                        || (
                            context.parent.paymentType
                            && context.parent.paymentType.value === PaymentType.FlatFee
                        )
                    );

                    if (shouldValidate) {
                        return value || value === 0 ? true : false;
                    }
                    return true;
                },
                message: 'Enter an amount',
            }),

        pricePerUser: Yup
            .number()
            .typeError('Must be numeric')
            .max(100, 'Cannot be greater than 100')
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.Enterprise
                        && context.parent.paymentType
                        && context.parent.paymentType.value === PaymentType.PayPerUser
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter an amount',
            }),



        mySymmioLicense: Yup
            .number()
            .typeError('Must be numeric')
            .required('Enter an amount')
            .test({
                test: (value, context) => {
                    if (context.parent.plan?.value === PlanType.Business && (+value < 10 || +value > UserLimit.Business)) {
                        return false;
                    }
                    return true;
                },
                message: `Please enter a number between 10 and ${UserLimit.Business}`,
            }),

        availableOrganizations: Yup
            .number()
            .typeError('Must be numeric')
            .max(9999, 'Cannot be above 9999')
            .test({
                test: (value, context) => {
                    const shouldValidate = context.parent.plan && context.parent.plan.value === PlanType.Enterprise;
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter an amount',
            }),


        userLimit: Yup
            .number()
            .typeError('Must be numeric')
            .required('Enter a user limit')
            .max(1000000, 'Cannot be above 1000000'),

        startDate: ValidationRules
            .requiredDate('Enter a start date'),

        renewDate: ValidationRules
            .requiredDate('Enter a renew date')
            .test({
                test: (value, context) => {
                    const startDate = context.parent.startDate as Moment;
                    const renewDate = value as Moment;
                    if (!startDate) {
                        return true;
                    }
                    return renewDate.isAfter(startDate);
                },
                message: 'Cannot be on or before start date'
            })
    });
}

const TrialOrganizationValidationRules = () => {
    return Yup.object().shape({
        organizationName: Yup
            .string()
            .required('Enter an organization name'),

        accountHolderEmail: Yup
            .string()
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter the email which will be used to create an account holder'
            })
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value?.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g);
                    }
                    return true;
                },
                message: 'Enter a valid email'
            }),
        mySymmioLicense: Yup
            .number()
            .required('Enter an amount')
            .typeError('Must be numeric')
            .max(1000000, 'Cannot be greater than 1000000')
            .test({
                test: (value, context) => {
                    if (+value > 1000000 || +value < 1) {
                        return false;
                    }
                    return true;
                },
                message: 'Please enter a number between 1 and 1000000',
            }),
        accountHolderId: Yup
            .object()
            .nullable(),

        accountManager: Yup
            .object()
            .nullable(),

        userLimit: Yup
            .number()
            .typeError('Must be numeric')
            .required('Enter a user limit')
            .max(1000000, 'Cannot be above 1000000'),

        availableOrganizations: Yup
            .number()
            .typeError('Must be numeric')
            .required('Enter an amount')
            .max(9999, 'Cannot be above 9999'),

        trialLength: Yup
            .object()
            .required('Select a trial length'),

        customTrialLength: Yup
            .number()
            .typeError('Must be numeric')
            .max(365, 'Cannot be greater than 365')
            .test({
                test: (value, context) => {
                    const custom = context.parent.trialLength && context.parent.trialLength.value === 0;
                    if (custom) {
                        return !!value;
                    }
                    return true;
                },
                message: 'Enter a custom trial length'
            }),
    });
}

const EditOrganizationValidationRules = {
    PaidOrganizationValidationRules,
    TrialOrganizationValidationRules,
}

export default EditOrganizationValidationRules;