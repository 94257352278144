import { useState, useEffect } from 'react';
import { getRemainingTimeUntilMsTimestamp } from '../../utilities/countdown-timer-utils';

interface CountdownTimerProps {
    countdownTimestampMs: number,
    onCountdownExpired: Function
}

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00'
}
// TODO: if 00 don't display
const CountdownTimer = (props: CountdownTimerProps) => {
    const { countdownTimestampMs, onCountdownExpired } = props;

    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdownTimestampMs])

    function updateRemainingTime(countdown: any) {
        const remaining = getRemainingTimeUntilMsTimestamp(countdown);
        setRemainingTime(remaining);
        if (remaining.seconds === '00' && remaining.minutes === '00') {
            onCountdownExpired();
            localStorage.removeItem("_expiredTime");
        }
    }

    function padWithZero(number: string) {
        if (number.length === 2) return number;
        return `0${number}`;
    }

    if (remainingTime.minutes === '00' && remainingTime.seconds === '00') {
        return (
            <>
                <span style={{ marginLeft: "0.25ch", width: "2ch", opacity: "0", fontWeight: "700" }}>00</span>
                <span style={{ marginLeft: "0.25ch", opacity: "0", fontWeight: "700" }}>min</span>
                <span style={{ marginLeft: "0.25ch", width: "2ch", opacity: "0", fontWeight: "700" }}>00</span>
                <span style={{ marginLeft: "0.25ch", opacity: "0", fontWeight: "700" }}>sec</span>
            </>
        )
    }

    return (
        <>
            <span style={{ marginLeft: "0.25ch", width: "2ch", fontWeight: "700" }}>{padWithZero(remainingTime.minutes)}</span>
            <span style={{ marginLeft: "0.25ch", fontWeight: "700" }}>min</span>
            <span style={{ marginLeft: "0.25ch", width: "2ch", fontWeight: "700" }}>{padWithZero(remainingTime.seconds)}</span>
            <span style={{ marginLeft: "0.25ch", fontWeight: "700" }}>sec</span>
        </>
    )
}

export default CountdownTimer;