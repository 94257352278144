import React from 'react';
import BaseCardLayout from '../base-card-layout/base-card-layout';
import { AssessmentsUserMenu } from '../assessments-user-menu/assessments-user-menu';
import ManageUserCancellationForm from './manage-cancellation-form';
import { Redirect } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';

const ManageUserCancellation: React.FC = () => {
  const { state } = useAuthState();

  if (!state.authenticated) {
    return <Redirect to="/login" />;
  }
  if (state.user && !state.user.isAccountSetup) {
    return <Redirect to="/setup" />;
  }
  if (state.user && !state.user.subscription?.subscriptionId) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className={`user-menu`} style={{}}>
        <AssessmentsUserMenu profileImage={false} />
      </div>
      <BaseCardLayout>
        <ManageUserCancellationForm />
      </BaseCardLayout></>
  );
};

export default ManageUserCancellation;