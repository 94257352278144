import { CategoryIds } from "../../models/enumerations/category-ids";

const COMPONENT_CLASS = "c-progress-bar";

export interface ProgressBarProps {
    total: number;
    currentProgress: number;
    category?: CategoryIds;
    headerText?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    const STEP_COLOR =
        props.category === CategoryIds.MOVEMENT
            ? "-complete"
            : "-complete-lifestyle";

    const progressBars = [];
    while (progressBars.length < props.total) {
        progressBars.push(progressBars.length);
    }

    return (
        <div className={`${COMPONENT_CLASS}__header`}>
            {
                // if
                props.headerText && (
                    <h4>{props.headerText}</h4>
                )


            }
            {
                !props.headerText && (
                    <h4>Couldn't Retrieve Title</h4>
                )
            }

            <div className={`${COMPONENT_CLASS}`}>

                {progressBars.map((progress) => (
                    <div
                        className={`${COMPONENT_CLASS}__step ${props.currentProgress > progress ? STEP_COLOR : ""
                            }`}
                        key={`progress-${progress}`}></div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;
