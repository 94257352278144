import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import { Utils } from "../../utilities/utils";
import ReportGenericHeader from "./report-generic-header";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportDetailProps {
    data: ReportDetailData;
}

// #endregion Intefaces


// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportDetail: React.FC<ReportDetailProps> = (props: ReportDetailProps) => {
    const { data } = props;

    data.users.sort(function compareFn(a, b): number {
        if (a?.name == null) {
            return -1;
        }
        if (b?.name == null) {
            return 1;
        }
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return (
        <div className={`${COMPONENT_CLASS} -detail`}>
            <ReportGenericHeader companyName={data.organizationName} reportName="Detailed Report" />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>USERS IN REPORT:</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>GROUPS:</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>TAGS:</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>FILTERS:</p>
                    {data.filterNames && data.filterNames.length > 0 &&
                        <p>{data.filterNames.join(", ")}</p>
                    }
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__section`}>
                <table className={`${COMPONENT_CLASS}__table`}>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td className="center">Age</td>
                            <td>Group</td>
                            <td className="center">Score</td>
                            <td>MSK Risk</td>
                            <td className="center">Pain</td>
                            <td>Focus(s)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.users.map((user, index) => (
                            <tr
                                className={`${COMPONENT_CLASS}__table__row ${user.inPain ? "-warning" : ""}`}
                                key={index}>
                                <td>{user.name}</td>
                                <td className="center">{Utils.getAge(user.dob)}</td>
                                <td>{user.groupName}</td>
                                <td className="center">{user.mskScore}</td>
                                <td>{Utils.getHealthRiskName(user.healthCategory)}</td>
                                <td className="center">{user.inPain ? "Y" : "N"}</td>
                                <td>{user.focusAreas?.join(", ")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


export default ReportDetail;
