import { User } from "../../models/interfaces/user";
import getFirebase from "../firebase";
import {
    doc,
    setDoc,
    Timestamp,
} from "firebase/firestore";
import { Notification } from "../../models/interfaces/notification";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "notifications";


const { db } = getFirebase();
// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------'

/**
 * Add a new notification to the notifications collection
 * @param {Notification} notification - The notification that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Notification>} A promise to the newly added notification
 */
const add = async (notification: Notification, currentUser: User | null = null) => {
    return FirestoreService.add<Notification>(COLLECTION_NAME, notification, currentUser);
};


/**
 * Add a new notification to the notifications collection
 * @param {Type} notification - The notification that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Notification>} A promise to the newly added notification
 */
const markAllAsRead = async (receiverId: string, organizationId: string, currentUser: User) => {
    const notifications = await FirestoreService.getBy<Notification>(COLLECTION_NAME, [
        {
            field: "receiverId",
            operator: "==",
            value: receiverId,
        },
        {
            field: "organizationId",
            operator: "==",
            value: organizationId,
        }
    ]);
    notifications.forEach(async notification => {
        notification.updated = Timestamp.now();
        notification.read = true;

    if (currentUser !== null) {
        notification.updatedBy = currentUser.id;
    } else if (notification.updatedBy != null) {
        notification.updatedBy = "";
    }
    await setDoc(doc(db, COLLECTION_NAME, notification.id!), notification, {
        merge: true,
    });
    return notification as Notification;
    });
    
}

const clearAll = async (receiverId: string, organizationId: string, currentUser: User) => {
    const notifications = await FirestoreService.getBy<Notification>(COLLECTION_NAME, [
        {
            field: "receiverId",
            operator: "==",
            value: receiverId,
        },
        {
            field: "organizationId",
            operator: "==",
            value: organizationId,
        }
    ]);
    notifications.forEach(async notification => {
        if(notification.id) {
            await deleteById(notification.id);
        }
    });
    
}

const getNotifications = (receiverId: string, organizationId: string): any => {
    return FirestoreService.getBy<User>(COLLECTION_NAME, [
        {
            field: "receiverId",
            operator: "==",
            value: receiverId,
        },
        {
            field: "organizationId",
            operator: "==",
            value: organizationId,
        }
    ]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
        // const q = query(collection(db, 'users', receiverId, 'notifications'), orderBy('timestamp', 'desc'));
        // const unsub = onSnapshot(q, (snapshot: any) => {
        //     const result : Notification[] = snapshot.docs.map((doc: any) => ({
        //         id: doc.id,
        //         ...(doc.data() as Omit<Notification, 'id'>)
        //     }));

        //     return result;
        //     // snapshot.docChanges().forEach((change: any) => {
        //     //     if(change.type === 'modified' && change.doc.data().firstName) {
        //     //         const userName = change.doc.data().firstName;
        //     //         enqueueSnackbar("Add-on added to plan", { variant: "toast", width: "450px" });
        //     //     }
        //     // })
        // }) 
};

const save = async (notification: Notification, currentUser: User | null = null) => {
    return FirestoreService.save<Notification>(COLLECTION_NAME, notification, currentUser);
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Update the specified user stored in the database
 * @param {User} user - The user that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<User>} A promise for the user that is being updated
 */
const update = async (notification: Notification, currentUser: User | null = null) => {
    return FirestoreService.update<Notification>(COLLECTION_NAME, notification, currentUser);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const NotificationService = {
    getNotifications: getNotifications,
    markAllAsRead: markAllAsRead,
    add: add,
    deleteById: deleteById,
    save: save,
    clearAll: clearAll,
    update: update,
};

export default NotificationService;

// #endregion Exports