import { getValidFileName } from './get-valid-filename';
import { toPng } from 'html-to-image';

/*
    This function takes in an Element Id as a parameter, converts the parameter to a PNG,
    then provides download of the PNG.
*/

/**
     *This function takes in an Element Id as a parameter, converts the parameter to a PNG,
     *then provides download of the PNG.
     * 
     * @param {string} fileName - The desired fileName
     * 
     */

export const elementToImage = async (elementId: string, fileName: string) => {
    const element = document.getElementById(elementId);
    fileName = getValidFileName(`${fileName}.png`);

    if (!element) {
        // TODO : Global exception handling
        // https://app.clickup.com/t/2219993/FMS-1236
        console.error("Couldn't download image");
        return;
    }

    try {
        let dataUrl = await toPng(element, { quality: 1, backgroundColor: 'white' });
        let link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();

    } catch (err) {
        // TODO : Global exception handling
        // https://app.clickup.com/t/2219993/FMS-1236
        console.error("Couldn't download image");
        return;
    }
}
