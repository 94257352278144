import Grid from '@mui/material/Grid/Grid';
import React from 'react';

type ViewOrganizationFieldProps = {
    label?: string;
    value?: string | number | undefined;
    hidden?: boolean;
    startAdornment?: string;
    rowEnd?: boolean,
    firstRow?: boolean,
    userQuantity?: number,
    loading?: boolean,
    expired?: boolean,
    columns?: number
};

export const ViewOrganizationField: React.FC<ViewOrganizationFieldProps> = (props: ViewOrganizationFieldProps) => {
    const {
        label,
        value,
        hidden,
        startAdornment,
        firstRow,
        rowEnd,
        userQuantity,
        loading,
        expired,
        columns
    } = props;

    if (hidden) {
        return (
            <Grid
                item
                xs={6} sm={3}
                sx={{
                    paddingTop: firstRow ? '0px' : '40px',
                    paddingLeft: rowEnd ? '40px' : '0px',
                    borderLeft: rowEnd ? '2px solid rgba(18, 22, 34, 0.05)' : 'none',
                }}
            />
        )
    }

    return (
        <Grid
            item
            xs={columns ? columns * 6 : 6} sm={columns ? columns * 3 : 3}
            sx={{
                paddingTop: firstRow ? '0px' : '40px',
                paddingLeft: rowEnd ? '40px' : '0px',
                borderLeft: rowEnd ? '2px solid rgba(18, 22, 34, 0.05)' : 'none',
            }}
        >
            <p
                style={{
                    fontSize: '12px',
                    fontWeight: '700',
                    paddingBottom: '8px',
                    wordWrap: 'break-word',
                }}
            >
                {label ?? ''}
            </p>
            <p
                style={{
                    fontSize: '16px',
                    fontWeight: '300',
                    color: expired ? 'rgba(231, 81, 79, 1)' : 'black',
                    wordWrap: 'break-word',
                }}
            >
                {startAdornment && startAdornment}<span style={{ fontWeight: 700 }}>{loading ? '' : userQuantity}</span>{value ?? ''}
            </p>
        </Grid>
    )
}
