/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from '../button/button';

import OrganizationSubscriptionService from '../../utilities/services/organization-subscription-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Dialog } from '@headlessui/react';
import PortalProgressBar from '../portal-progress-bar/portal-progress-bar';
import { Organization } from '../../models/interfaces/organization';
import OrganizationService from '../../utilities/services/organization-service';
import UserDeactivationModal from '../manage-users-modals/user-deactivation-modal';
import { AddOns } from '../../models/enumerations/add-ons';
import LicenseRemovalModal from '../manage-users-modals/license-removal-modal';
import { Loader } from '../loader/loader';
import { enqueueSnackbar } from "notistack";
import moment from 'moment';
import FunctionService from '../../utilities/services/function-service';
import { AddOnId } from '../../models/enumerations/add-on-id';
import { CheckboxTypeInput } from '../forms/checkbox-type-input';
import { useForm } from 'react-hook-form';
import { NumberTypeInput } from '../forms/number-type-input/number-type-input';

interface AddOnDialogBoxProps {
  onClose: Function,
  imageUrl?: string,
  serviceName: string,
  addOnName?: string,
  price: number,
  description: string,
  descriptionBullets: string[],
  summaryDescription: string[],
  priceId: string,
  quantity: number,
  setAddOnPurchaseComplete?: Function,
  setAddOnRemoveComplete?: Function,
  removeAddOn: boolean,
  organization: Organization,
  nextPayment?: string,
  setAddOnRemovedModalOpen?: Function,
  setRemovedAddOnInfo?: Function,
  products: any[],
  existingAddOnAmount: number,
}
const AddOnDialogBox: React.FC<AddOnDialogBoxProps> = (props: AddOnDialogBoxProps) => {
  const COMPONENT_CLASS = 'c-add-on-dialog-box';
  const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const [showNextSlide, setShowNextSlide] = useState<boolean>(false);
  const [showManageUserSlide, setShowManageUserSlide] = useState<boolean>(false);
  const [showManageLicenseSlide, setShowManageLicenseSlide] = useState<boolean>(false);
  const { state } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);
  // Hidden limit is for when they have removed an amount of an add-on and we need to track there soon to be limit.
  const [hiddenLimit, setHiddenLimit] = useState<number>();
  const [existingAmountToBeRemoved, setExistingAmountToBeRemoved] = useState<number>(0);
  const {
    onClose,
    serviceName,
    addOnName,
    price,
    description,
    descriptionBullets,
    summaryDescription,
    priceId,
    quantity,
    setAddOnPurchaseComplete,
    setAddOnRemoveComplete,
    nextPayment,
    organization,
    setAddOnRemovedModalOpen,
    setRemovedAddOnInfo,
    products,
    existingAddOnAmount,
  } = props;
  const [prorationDate, setProrationDate] = useState<number>();
  const [proratedRate, setProratedRate] = useState<number>();
  const [fullRate, setFullRate] = useState<number>();
  const [refundedAmount, setRefundedAmount] = useState<number>();
  const [subscriptionItem, setSubscriptionItem] = useState<any>();
  const [subscription, setSubscription] = useState<any>();
  const [orgSub, setOrgSub] = useState<any>();
  const [addOnsToBeRemoved, setAddOnsToBeRemoved] = useState<string>("1");
  const [firstConditionChecked, setFirstConditionChecked] = useState<boolean>(false);
  const [secondConditionChecked, setSecondConditionChecked] = useState<boolean>(false)
  const [thirdConditionChecked, setThirdConditionChecked] = useState<boolean>(false)
  const [amountOfUnusedDays, setAmountOfUnusedDays] = useState<number>(0)
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const {
    register,
  } = useForm<any>();
  // Retrieve base data
  useEffect(() => {
    const retrieveBaseData = async () => {
      // Set Proration Date
      setProrationDate(Math.floor(Date.now() / 1000));

      // Set Organization Subscription
      await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSub) => {
        await setOrgSub(orgSub[0]);

        if (orgSub[0].stripeAddOnSubscription) {
          await stripe.subscriptions.retrieve(orgSub[0].stripeAddOnSubscription).then((subscription: any) => {

            // Set Subscription
            setSubscription(subscription);

            // Set selected SubscriptionItemId
            setSubscriptionItem(subscription.items.data.find((item: any) => item.price.id === priceId))
          })
        }
      });
    }

    retrieveBaseData();
  }, [])

  // Set Count/Limit
  useEffect(() => {
    const setCountLimit = () => {
      switch (addOnName) {
        case AddOns.User:
          // Check if specific addOn has a removal schedule already
          if (orgSub && orgSub.addOnsToBeRemoved && orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)) {
            const addOnRemovalValues = orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User);
            setHiddenLimit(organization.userLimit! - (addOnRemovalValues.amountToBeRemoved - addOnRemovalValues.amountManaged))
            setExistingAmountToBeRemoved(addOnRemovalValues.amountToBeRemoved);
          }
          setCount(organization.userCount!);
          setLimit(organization.userLimit!);
          break;
        case AddOns.License:
          // Check if specific addOn has a removal schedule already
          if (orgSub && orgSub.addOnsToBeRemoved && orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)) {
            const addOnRemovalValues = orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License);
            setHiddenLimit(organization.mySymmioLicense! - (addOnRemovalValues.amountToBeRemoved - addOnRemovalValues.amountManaged))
            setExistingAmountToBeRemoved(addOnRemovalValues.amountToBeRemoved);
          }
          setCount(organization.mySymmioLicenseCount!);
          setLimit(organization.mySymmioLicense!);
          break;
        case AddOns.Organization:
          // TODO FOR V2
          break;

      }
    }

    if (orgSub) {
      setCountLimit();
    }
  }, [orgSub])

  useEffect(() => {
    if (subscriptionItem) {
      calculateProration();
    }
  }, [subscriptionItem])


  const handleNextClick = () => {
    setShowNextSlide(true);
  };

  const handleManageUserSlide = () => {
    setShowManageUserSlide(true);
  }

  const handleManageLicenseSlide = () => {
    setShowManageLicenseSlide(true);
  }

  const handleRemove = async () => {
    setIsLoading(true);
    const sub = await stripe.subscriptions.retrieve(orgSub.stripeAddOnSubscription);

    if (!sub.schedule) {

      // Creating subscription schedule
      const subscriptionSchedule = await stripe.subscriptionSchedules.create({
        from_subscription: sub.id,

      })

      console.log(subscriptionSchedule);
      // Create phaseItems
      let phases: any[] = []

      // Upon schedule creation, there will only be 1 phase
      let phaseOneItems = []
      let phaseTwoItems = []
      for (const item of subscriptionSchedule.phases[0].items) {
        phaseOneItems.push({
          price: item.price,
          quantity: item.quantity,
        })
        phaseTwoItems.push({
          price: item.price,
          quantity: item.quantity,
        })
      }
      phases.push({
        // This might be a potential error upon looking at code. This looks like it was created for the addons of user/license/org [0][1][2], but since 
        // we removed orgs this doesn't work. This logic also isn't universal.
        // we only add a specific number of items. We should loop through the schedule and dynamically add similar to the else down below
        items: phaseOneItems,
        start_date: subscriptionSchedule.phases[0].start_date,
        end_date: subscriptionSchedule.phases[0].end_date
      });

      phases.push({
        items: phaseTwoItems,
        iterations: 1
      })
      console.log(phases);
      // Modify last phase quantity
      console.log(priceId)
      const index = phases.at(-1).items.findIndex((item: any) => item.price === priceId);
      console.log(index);
      phases.at(-1).items[index].quantity = phases.at(-1).items[index].quantity - parseInt(addOnsToBeRemoved)
      console.log(phases);
      // Updating subscription schedule
      await stripe.subscriptionSchedules.update(

        subscriptionSchedule.id,
        {
          phases: phases,
        },
      )
    }
    else {
      console.log("??")
      // Retrieve existing subscription schedule
      const subscriptionSchedule = await stripe.subscriptionSchedules.retrieve(sub.schedule);

      // Check if subscription schedule has already been updated for the future month
      // To do so, take last index of phase array and check if phase.start/end === subSched.current_phase
      // If === then no future phase isn't created
      // If !== then future phase is created and we want to update
      const futurePhaseExists = !((subscriptionSchedule.phases.at(-1).start_date === subscriptionSchedule.current_phase.start_date) &&
        (subscriptionSchedule.phases.at(-1).end_date === subscriptionSchedule.current_phase.end_date));
      if (futurePhaseExists) {
        console.log("???")
        // Update existing phase quantity
        const index = subscriptionSchedule.phases.at(-1).items.findIndex((item: any) => item.price === priceId);
        subscriptionSchedule.phases.at(-1).items[index].quantity = subscriptionSchedule.phases.at(-1).items[index].quantity - parseInt(addOnsToBeRemoved)

        // Create new phases object
        let phases: any[] = [];
        subscriptionSchedule.phases.forEach(function (phase: any, index: any, array: any) {
          let phaseItems: any[] = [];
          phase.items.forEach(function (item: any) {
            phaseItems.push({
              price: item.price,
              quantity: item.quantity,
            })
          })

          // Check if we're on last iteration
          if (index === array.length - 1) {
            phases.push({
              items: phaseItems,
              iterations: 1,
            })
          }
          else {

            phases.push({
              items: phaseItems,
              start_date: phase.start_date,
              end_date: phase.end_date,
            })
          }
        })

        // Update existing phase            
        await stripe.subscriptionSchedules.update(

          sub.schedule,
          {
            phases: phases
          },
        )
      }
      else {
        console.log("?")
        // Create new phases object
        let phases: any[] = [];
        subscriptionSchedule.phases.forEach(function (phase: any, index: any, array: any) {
          let phaseItems: any[] = [];
          phase.items.forEach(function (item: any) {
            phaseItems.push({
              price: item.price,
              quantity: item.quantity,
            })
          })
          phases.push({
            items: phaseItems,
            start_date: phase.start_date,
            end_date: phase.end_date,
          })
          // If we're on last index, last task is to create a new phase that is a replica of the last phase
          // with the new quantity.
          if (index === array.length - 1) {
            let newPhase = structuredClone(phase);
            const index = newPhase.items.findIndex((item: any) => item.price === priceId);

            // Modify specific item quantity
            newPhase.items[index].quantity = newPhase.items[index].quantity - parseInt(addOnsToBeRemoved)

            // Create Correct Item array structure
            let newPhaseItems: any[] = []
            newPhase.items.forEach(function (item: any) {
              newPhaseItems.push({
                price: item.price,
                quantity: item.quantity,
              })
            })

            // Push new phase with iteration set instead of start/end date
            phases.push({
              items: newPhaseItems,
              iterations: 1,
            })
          }
        })

        // Add new phase
        await stripe.subscriptionSchedules.update(

          sub.schedule,
          {
            phases: phases,
          },
        )
      }
    }
    setAddOnRemoveComplete!(true);
    // Checking if we are changing users or licenses
    // Users
    if (priceId === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id) {
      // Send removal email for users
      const removalData = {
        nextPaymentDate: moment(new Date(subscription.current_period_end * 1000)).format('MMMM D Y'),
        removedUserCount: addOnsToBeRemoved,
        currentUserLimit: limit,
        reducedUserLimit: `${existingAmountToBeRemoved ? isNaN(parseInt(addOnsToBeRemoved)) ? '' : limit - parseInt(addOnsToBeRemoved) - existingAmountToBeRemoved : isNaN(parseInt(addOnsToBeRemoved)) ? '' : limit - parseInt(addOnsToBeRemoved)}`
      };
      FunctionService.sendAddOnUserRemovalEmail(state.user!, removalData);
    }
    else if (priceId === products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id) { // Licenses
      // send removal email for licenses
      const removalData = {
        nextPaymentDate: moment(new Date(subscription.current_period_end * 1000)).format('MMMM D Y'),
        removedLicenseCount: addOnsToBeRemoved,
        currentLicenseLimit: limit,
        reducedLicenseLimit: `${existingAmountToBeRemoved ? isNaN(parseInt(addOnsToBeRemoved)) ? '' : limit - parseInt(addOnsToBeRemoved) - existingAmountToBeRemoved : isNaN(parseInt(addOnsToBeRemoved)) ? '' : limit - parseInt(addOnsToBeRemoved)}`
      };
      FunctionService.sendAddOnLicenseRemovalEmail(state.user!, removalData);
    }
    setIsLoading(false);
    onClose();
    return sub.current_period_end as number;
  }

  const handleAddOnRemovalTracker = async (periodEnd: number) => {
    // Update Organization Subscription AddOnsToBeRemoved for Users
    if (priceId === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id) {
      let oldValue;
      // Check if addOnsToBeRemoved exists and has "Users"
      if (orgSub.addOnsToBeRemoved && orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)) {
        oldValue = orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)
        const index = orgSub.addOnsToBeRemoved.findIndex((item: any) => item.name === AddOns.User)
        const newValue = {
          name: oldValue?.name,
          amountToBeRemoved: Number(addOnsToBeRemoved) + Number(oldValue?.amountToBeRemoved!),
          amountManaged: oldValue?.amountManaged!,
        }
        orgSub.addOnsToBeRemoved[index] = newValue;


        await OrganizationSubscriptionService.save(orgSub);

      }
      else {

        // Create the add-ons-to-be-removed object 
        const subOrgAddOnsToBeRemoved = [
          {
            name: AddOns.User,
            amountToBeRemoved: addOnsToBeRemoved,
            amountManaged: 0,
          }
        ];

        orgSub.addOnsToBeRemoved ? orgSub.addOnsToBeRemoved.push(subOrgAddOnsToBeRemoved[0]) : orgSub.addOnsToBeRemoved = subOrgAddOnsToBeRemoved;
        await OrganizationSubscriptionService.save(orgSub);
      }
      // setRemovedAddOnInfo && setAddOnRemovedModalOpen prop must be set if you are handling removing addOns
      if (setRemovedAddOnInfo && setAddOnRemovedModalOpen) {
        setRemovedAddOnInfo({
          name: AddOns.User,
          amountToBeRemoved: Number(addOnsToBeRemoved),
          billingCycleEnd: moment(new Date(periodEnd * 1000)).format('MMMM D Y')
        })
        setAddOnRemovedModalOpen(true);
      }
    }
    else if (priceId === products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id) { // Update Organization Subscription AddOnsToBeRemoved for Licenses
      let oldValue;
      // Check if addOnsToBeRemoved exists and has "Users"
      if (orgSub.addOnsToBeRemoved && orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)) {
        oldValue = orgSub.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)
        const index = orgSub.addOnsToBeRemoved.findIndex((item: any) => item.name === AddOns.License)
        const newValue = {
          name: oldValue?.name,
          amountToBeRemoved: Number(addOnsToBeRemoved) + Number(oldValue?.amountToBeRemoved!),
          amountManaged: oldValue?.amountManaged!,
        }
        orgSub.addOnsToBeRemoved[index] = newValue;

        await OrganizationSubscriptionService.save(orgSub);

      }
      else {
        // Create the add-ons-to-be-removed object 
        const subOrgAddOnsToBeRemoved = [
          {
            name: AddOns.License,
            amountToBeRemoved: addOnsToBeRemoved,
            amountManaged: 0,
          }
        ];

        orgSub.addOnsToBeRemoved ? orgSub.addOnsToBeRemoved.push(subOrgAddOnsToBeRemoved[0]) : orgSub.addOnsToBeRemoved = subOrgAddOnsToBeRemoved;
        await OrganizationSubscriptionService.save(orgSub);
      }
      // setRemovedAddOnInfo && setAddOnRemovedModalOpen prop must be set if you are handling removing addOns
      if (setRemovedAddOnInfo && setAddOnRemovedModalOpen) {
        setRemovedAddOnInfo({
          name: AddOns.License,
          amountToBeRemoved: Number(addOnsToBeRemoved),
          billingCycleEnd: moment(new Date(periodEnd * 1000)).format('MMMM D Y')
        })
        setAddOnRemovedModalOpen(true);
      }
    }

  }

  const handlePayment = async () => {
    setIsLoading(true);
    // Update subscription
    console.log(subscriptionItem)
    console.log(priceId);
    await stripe.subscriptions.update(

      subscription.id,
      {
        items: [
          {
            id: subscriptionItem.id,
            price: priceId,
            quantity: (quantity + subscriptionItem.quantity)
          }
        ],
        payment_behavior: 'error_if_incomplete',
        proration_behavior: 'always_invoice',
        proration_date: prorationDate,
      }
    ).then(async (sub: any) => {

      // If a subscription schedule exists, you must update this as well
      if (sub.schedule) {

        // Retrieve existing subscription schedule
        const subscriptionSchedule = await stripe.subscriptionSchedules.retrieve(sub.schedule);

        // Check if subscription schedule has already been updated for the future month
        // To do so, take last index of phase array and check if phase.start/end === subSched.current_phase
        // If === then no future phase isn't created
        // If !== then future phase is created and we want to update
        const futurePhaseExists = !((subscriptionSchedule.phases.at(-1).start_date === subscriptionSchedule.current_phase.start_date) &&
          (subscriptionSchedule.phases.at(-1).end_date === subscriptionSchedule.current_phase.end_date));

        if (futurePhaseExists) {

          // Update existing phase quantity
          const index = subscriptionSchedule.phases.at(-1).items.findIndex((item: any) => item.price === priceId);
          subscriptionSchedule.phases.at(-1).items[index].quantity = subscriptionSchedule.phases.at(-1).items[index].quantity + quantity

          // Create new phases object
          let phases: any[] = [];
          subscriptionSchedule.phases.forEach(function (phase: any, index: any, array: any) {
            let phaseItems: any[] = [];
            phase.items.forEach(function (item: any) {
              phaseItems.push({
                price: item.price,
                quantity: item.quantity,
              })
            })
            // Check if we're on last iteration
            if (index === array.length - 1) {
              phases.push({
                items: phaseItems,
                iterations: 1,
              })
            }
            else {

              phases.push({
                items: phaseItems,
                start_date: phase.start_date,
                end_date: phase.end_date,
              })
            }
          })

          // Update existing phase            
          await stripe.subscriptionSchedules.update(

            sub.schedule,
            {
              phases: phases
            },
          )
        }
      }
      setAddOnPurchaseComplete!(true);
      // Update Organization User Limit (User price id)
      if (priceId === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id) {
        // Update both OrgSub & Org
        // don't like the structure of these 2 tables, they both contain same info. Should change in future
        await OrganizationService.get(state.organization.id!).then(async (org) => {
          if (!org) {
            return;
          }
          org.userLimit! = org.userLimit! + quantity;
          await OrganizationService.save(org);
        })
        await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSubs) => {
          if (!orgSubs) {
            return
          }
          orgSubs[0].userLimit = orgSubs[0].userLimit + quantity

          await OrganizationSubscriptionService.save(orgSubs[0])
        });
        const confirmationData = {
          nextPaymentDate: moment(new Date(sub.current_period_end as number * 1000)).format('MMMM D Y'),
          userAddonCount: quantity,
          addonAmountCharged: `${fullRate ? USDollar.format(fullRate) : null}`,
          proratedDays: getDifferenceBetweenDate(sub.current_period_start).toString(),
          proratedDiscount: `${refundedAmount ? USDollar.format(refundedAmount) : null}`,
          amountCharged: `${proratedRate ? USDollar.format(proratedRate) : null}`
        };
        FunctionService.sendAddOnUserConfirmationEmail(state.user!, confirmationData);
      }
      else { // Update Organization License Limit
        await OrganizationService.get(state.organization.id!).then(async (org) => {
          if (!org) {
            return;
          }
          org.mySymmioLicense = org.mySymmioLicense! + quantity;
          await OrganizationService.save(org);
        })
        await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSubs) => {
          if (!orgSubs) {
            return
          }
          orgSubs[0].mySymmioLicense = orgSubs[0].mySymmioLicense! + quantity
          await OrganizationSubscriptionService.save(orgSubs[0])
        });
        const confirmationData = {
          nextPaymentDate: moment(new Date(sub.current_period_end as number * 1000)).format('MMMM D Y'),
          licenseAddonCount: quantity,
          addonAmountCharged: `${fullRate ? USDollar.format(fullRate) : null}`,
          proratedDays: getDifferenceBetweenDate(sub.current_period_start).toString(),
          proratedDiscount: `${refundedAmount ? USDollar.format(refundedAmount) : null}`,
          amountCharged: `${proratedRate ? USDollar.format(proratedRate) : null}`
        };
        FunctionService.sendAddOnLicenseConfirmationEmail(state.user!, confirmationData);
      }


      setIsLoading(false);
      enqueueSnackbar("Add-on added to plan", { variant: "toast", width: "450px" });

      onClose();
    })
  };
  // const calculateRefund = (): any | null => {
  //   const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  //   const daysUnused = daysInMonth - new Date().getDate();
  //   return { price: daysUnused !== 0 ? price - (price / daysInMonth) * daysUnused : null, day: daysUnused };
  // }
  // const refund = calculateRefund();

  const calculateProration = async () => {

    const items = [{
      id: subscriptionItem.id,
      price: priceId,
      quantity: (quantity + subscriptionItem.quantity),
    }];

    const invoice = await stripe.invoices.retrieveUpcoming({
      customer: subscription.customer,
      subscription: subscription.id,
      subscription_items: items,
      subscription_proration_date: prorationDate,
    });


    // Get upcoming invoices belonging to specific price
    const proratedInvoices = invoice.lines.data.filter((item: any) => item.price.id === priceId && item.period.start === prorationDate);
    // const fullPriceInvoice = invoice.lines.data.find((item: any) => item.price.id === priceId && item.period.start !== prorationDate);

    // if proratedInvoices.length is greater than 1, we are adding onto an existing quantity
    if (proratedInvoices.length > 1) {
      const refundInvoice = proratedInvoices[0];
      const proratedInvoice = proratedInvoices[1];
      const refundAmount = ((quantity * proratedInvoice.unit_amount_excluding_tax) - (proratedInvoice.amount + refundInvoice.amount)) / 100;
      setProratedRate((quantity * proratedInvoice.unit_amount_excluding_tax / 100) - refundAmount);
      setRefundedAmount(refundAmount)
      setAmountOfUnusedDays(getDifferenceBetweenDate(parseInt(subscription.current_period_start)));
      setFullRate(quantity * proratedInvoice.unit_amount_excluding_tax / 100);
    }
    else {
      const proratedInvoice = proratedInvoices[0];
      setProratedRate(proratedInvoice.amount / 100);
      setRefundedAmount(((proratedInvoice.quantity * proratedInvoice.unit_amount_excluding_tax) - proratedInvoice.amount) / 100)
      setAmountOfUnusedDays(getDifferenceBetweenDate(parseInt(subscription.current_period_start)));
      setFullRate(proratedInvoice.quantity * proratedInvoice.unit_amount_excluding_tax / 100);
    }
  }

  const checkHandler = (num: number) => {
    switch (num) {
      case 1:
        setFirstConditionChecked(!firstConditionChecked);
        break;
      case 2:
        setSecondConditionChecked(!secondConditionChecked);
        break;
      case 3:
        setThirdConditionChecked(!thirdConditionChecked);
        break;
    }
  }

  // Gets the difference between the date passed and now in days.
  const getDifferenceBetweenDate = (dateOne: number) => {
    const subDate = new Date((dateOne * 1000));
    const now = new Date();
    const differenceInTime = now.getTime() - subDate.getTime();
    // Calculating the no. of days between
    // two dates
    const differenceInDays =
      Math.round
        (differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  const preventRemoveOverMax = (e: any) => {
    if (e.target.value > quantity - existingAmountToBeRemoved) {
      setAddOnsToBeRemoved((quantity - existingAmountToBeRemoved).toString());
    }
    else if (e.target.value === "") {
      setAddOnsToBeRemoved("NaN");
    }
    else {
      setAddOnsToBeRemoved(e.target.value);
    }
  };

  // This gets the pricing for the total quantity of an add-on.
  const getTotalPricingByPriceId = (totalQuantity: any, priceId: string) => {
    const price = products.find((item) => item.prices.find((price: any) => price.id === priceId)).prices.find((item: any) => item.data.active === true).data.unit_amount;
    return parseInt(isNaN(totalQuantity) ? 0 : totalQuantity) * price;
  }


  return (
    <>
      <Loader isVisible={isLoading} />
      {
        setAddOnRemoveComplete &&
        <div className={`${COMPONENT_CLASS}__container`}>
          {!showNextSlide && (
            <div>
              <div style={{ marginTop: "0.25rem" }}>
                <Dialog.Title
                  as="h2" className={`${COMPONENT_CLASS}__modal-title`}>
                  Remove {serviceName}
                </Dialog.Title>
              </div>
              <PortalProgressBar total={limit} currentProgress={count} headerText={`${count} of ${limit} ${serviceName} Used`} />
              <div className={`${COMPONENT_CLASS}__remove-input-container`}>
                <p className={`${COMPONENT_CLASS}__remove-input-header`}>Choose the number of {serviceName} you want to remove:</p>
                {/* <input
                  className={`${COMPONENT_CLASS}__remove-input`}
                  type='text'
                  value={addOnsToBeRemoved}

                  onBlur={((event) => {
                    if (event.target.value === '') {
                      setAddOnsToBeRemoved("1");
                    }

                  })
                  } onChange={((event) => {
                    setAddOnsToBeRemoved(event.target.value);
                    if (event.target.value !== '' && parseInt(event.target.value) <= quantity) {
                      setAddOnsToBeRemoved(event.target.value);
                    }
                    else if (event.target.value !== '' && parseInt(event.target.value) > quantity) {
                      setAddOnsToBeRemoved(quantity.toString());
                    }
                  })}></input> */}
                <NumberTypeInput
                  id='removeInput'
                  inputClassName={`${COMPONENT_CLASS}__remove-input`}
                  registerHook={register}
                  registerOptions={{
                    value: addOnsToBeRemoved,
                    min: "0",
                    max: quantity - existingAmountToBeRemoved,
                    onChange: (event) => {
                      preventRemoveOverMax(event)
                    }
                  }} />
              </div>
              <div>
                <p ><span className={`${COMPONENT_CLASS}__light-text-16`}>This change will take effect at the end of your billing cycle on</span> <b className={`${COMPONENT_CLASS}__bold-text-16`}>{nextPayment}.</b> <span className={`${COMPONENT_CLASS}__light-text-16`}>How your account will change:</span></p>
              </div>
              <div className={`${COMPONENT_CLASS}__remove-checkboxes`}>
                <div className={`${COMPONENT_CLASS}__remove-checkbox`}>
                  <CheckboxTypeInput
                    id='firstCondition'
                    checked={firstConditionChecked}
                    registerHook={register}
                    registerOptions={{
                      onChange: (() => { checkHandler(1); }),
                    }} />
                  <label className={`${COMPONENT_CLASS}__light-text`} htmlFor="firstCondition"><span className={`${COMPONENT_CLASS}__light-text`}>Your {serviceName.toLowerCase()} limit will be reduced from</span> <b className={`${COMPONENT_CLASS}__bold-text`}>{limit}</b> <span className={`${COMPONENT_CLASS}__light-text`}>to</span> <b className={`${COMPONENT_CLASS}__bold-text`}>{existingAmountToBeRemoved ? isNaN(parseInt(addOnsToBeRemoved)) ? hiddenLimit ? hiddenLimit : limit : limit - parseInt(addOnsToBeRemoved) - existingAmountToBeRemoved : isNaN(parseInt(addOnsToBeRemoved)) ? '' : limit - parseInt(addOnsToBeRemoved)}</b>.</label>
                </div>
                {
                  ((hiddenLimit && parseInt(addOnsToBeRemoved) > hiddenLimit - count) || (!hiddenLimit && parseInt(addOnsToBeRemoved) > limit - count)) && (
                    <div className={`${COMPONENT_CLASS}__remove-checkbox`}>
                      <CheckboxTypeInput
                        id='secondCondition'
                        checked={secondConditionChecked}
                        registerHook={register}
                        registerOptions={{
                          onChange: (() => { checkHandler(2); }),
                        }} />
                      <label htmlFor="secondCondition"><span className={`${COMPONENT_CLASS}__light-text`}>You're removing more {serviceName.toLowerCase()} than you currently have unused. You'll be required to remove</span> <b className={`${COMPONENT_CLASS}__bold-text`}>{existingAmountToBeRemoved && hiddenLimit ? parseInt(addOnsToBeRemoved) - (hiddenLimit - count) : parseInt(addOnsToBeRemoved) - (limit - count)}</b><span className={`${COMPONENT_CLASS}__light-text`}> {serviceName.toLowerCase()} on the next step to meet your new reduced limit.</span></label>
                    </div>
                  )

                }
                <div className={`${COMPONENT_CLASS}__remove-checkbox`}>
                  <CheckboxTypeInput
                    id='thirdCondition'
                    checked={thirdConditionChecked}
                    registerHook={register}
                    registerOptions={{
                      onChange: (() => { checkHandler(3); }),
                    }} />
                  <label className={`${COMPONENT_CLASS}__light-text`} htmlFor="thirdCondition"><span className={`${COMPONENT_CLASS}__light-text`}>If you use removed {serviceName.toLowerCase()} before the end of your billing cycle they won't be removed from your subscription plan.</span> </label>
                </div>
              </div>
              <hr className={`${COMPONENT_CLASS}__remove-hr`} />
              <div className={`${COMPONENT_CLASS}__remove-information-container ${COMPONENT_CLASS}__light-text`}>
                <div className={`${COMPONENT_CLASS}__remove-information`}>
                  <p>Remove {isNaN(addOnsToBeRemoved as any) ? 0 : addOnsToBeRemoved} Additional {serviceName}</p>
                  <p>-${(getTotalPricingByPriceId(addOnsToBeRemoved, priceId) / 100).toFixed(2)}</p>
                </div>
                {
                  existingAmountToBeRemoved > 0 && (
                    <div className={`${COMPONENT_CLASS}__remove-information`}>
                      <p>Previously Removed {serviceName}</p>
                      <p>{existingAmountToBeRemoved}</p>
                    </div>
                  )
                }

                <div className={`${COMPONENT_CLASS}__remove-information`}>
                  <p>New {serviceName} Limit</p>
                  <p>{isNaN(parseInt(addOnsToBeRemoved)) ? hiddenLimit ? hiddenLimit : limit : hiddenLimit ? hiddenLimit - parseInt(addOnsToBeRemoved) : limit - parseInt(addOnsToBeRemoved)}</p>
                </div>
                <div className={`${COMPONENT_CLASS}__remove-information`}>
                  <p>New Add-On Subscription Cost</p>
                  <p>${(getTotalPricingByPriceId(orgSub && orgSub.addOnsToBeRemoved && orgSub.addOnsToBeRemoved.find((item: any) => item.name === serviceName) ? existingAddOnAmount - orgSub.addOnsToBeRemoved.find((item: any) => item.name === serviceName).amountToBeRemoved - parseInt(addOnsToBeRemoved) : existingAddOnAmount - parseInt(addOnsToBeRemoved), priceId) / 100).toFixed(2)}/Month</p>
                </div>
                <hr className={`${COMPONENT_CLASS}__remove-hr`} />
              </div>
              <div className={`${COMPONENT_CLASS}__button-container`}>
                <Button
                  type='link'
                  buttonText='Keep this add-on'
                  onClick={() => {
                    onClose();
                  }} />
                <Button
                  type="default"
                  disabled={!firstConditionChecked ||
                    !(secondConditionChecked || !((hiddenLimit && parseInt(addOnsToBeRemoved) > hiddenLimit - count) || (!hiddenLimit && parseInt(addOnsToBeRemoved) > limit - count))) ||
                    !thirdConditionChecked ||
                    (parseInt(addOnsToBeRemoved) < 1 || isNaN(parseInt(addOnsToBeRemoved)))}
                  buttonText={`${(hiddenLimit && parseInt(addOnsToBeRemoved) > hiddenLimit - count) || (!hiddenLimit && parseInt(addOnsToBeRemoved) > limit - count) ? `Confirm and Manage ${serviceName}` : "Confirm and Remove Add-on"}`}
                  onClick={(async () => {
                    if ((hiddenLimit && parseInt(addOnsToBeRemoved) > hiddenLimit - count) || (!hiddenLimit && parseInt(addOnsToBeRemoved) > limit - count)) {
                      // User price id
                      if (priceId === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id) {
                        handleManageUserSlide();
                      }
                      // License price id
                      else if (priceId === products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id) {
                        handleManageLicenseSlide();
                      }
                    }
                    else {
                      const periodEnd = await handleRemove();
                      handleAddOnRemovalTracker(periodEnd)
                    }
                  })} />
              </div>
            </div>
          )}
          {showManageUserSlide && setAddOnRemovedModalOpen && (
            <UserDeactivationModal
              openUserDeactivation={showManageUserSlide}
              setOpenUserDeactivation={setShowManageUserSlide}
              isLoading={false}
              organization={organization}
              deactivationAmount={hiddenLimit ? parseInt(addOnsToBeRemoved) - (hiddenLimit - count) : parseInt(addOnsToBeRemoved) - (limit - count)}
              removeAtFutureDate={true}
              addOnAmountToBeRemoved={parseInt(addOnsToBeRemoved)}
              organizationSubscription={orgSub}
              handleAddOnRemove={handleRemove}
              setAddOnRemovedModalOpen={setAddOnRemovedModalOpen}
              setRemovedAddOnInfo={setRemovedAddOnInfo}
            />
          )}
          {showManageLicenseSlide && setAddOnRemovedModalOpen && (
            <LicenseRemovalModal
              openLicenseRemoval={showManageLicenseSlide}
              setOpenLicenseRemoval={setShowManageLicenseSlide}
              isLoading={false}
              organization={organization}
              deactivationAmount={hiddenLimit ? parseInt(addOnsToBeRemoved) - (hiddenLimit - count) : parseInt(addOnsToBeRemoved) - (limit - count)}
              removeAtFutureDate={true}
              addOnAmountToBeRemoved={parseInt(addOnsToBeRemoved)}
              organizationSubscription={orgSub}
              handleAddOnRemove={handleRemove}
              setAddOnRemovedModalOpen={setAddOnRemovedModalOpen}
              setRemovedAddOnInfo={setRemovedAddOnInfo} />
          )}
        </div >
      }
      {
        setAddOnPurchaseComplete &&
        <div className={`${COMPONENT_CLASS}__container`}>
          {!showNextSlide && (
            <div>
              <p className={`${COMPONENT_CLASS}__extra-header`}>ADD-ON</p>
              <Dialog.Title
                as="h2" className={`${COMPONENT_CLASS}__modal-title`}>
                {serviceName}
              </Dialog.Title>
              <div className={`${COMPONENT_CLASS}__description`}>
                <div className={`${COMPONENT_CLASS}__description_container`}>
                  <div className={`${COMPONENT_CLASS}__description_container_description`}>
                    <p className={`${COMPONENT_CLASS}__light-text`} style={{ marginBottom: "0.5rem" }}>{description}</p>
                    <ul className={`${COMPONENT_CLASS}__description-list`} >
                      {descriptionBullets.map((bullet) => {
                        return (<li className={`${COMPONENT_CLASS}__light-text ${COMPONENT_CLASS}__description-list-item`} >{bullet}</li>)
                      })}
                    </ul>
                  </div>
                  <div className={`${COMPONENT_CLASS}__description_container_button`}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
                      <h1 style={{ fontSize: '30px', fontWeight: 'bold', marginRight: '0.5rem' }}>
                        +{USDollar.format(price)}
                      </h1>
                      <p className={`${COMPONENT_CLASS}__interval`} style={{ color: '#82848B' }}>/ Month</p>
                    </div>
                    <Button
                      buttonText="+ Add To Plan"
                      onClick={handleNextClick} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showNextSlide && (
            <div>
              <p className={`${COMPONENT_CLASS}__extra-header`}>ADD-ON</p>
              <Dialog.Title
                as="h2" className={`${COMPONENT_CLASS}__modal-title`}>
                Add {serviceName}
              </Dialog.Title>
              <div className={`${COMPONENT_CLASS}__summary`}>
                <div className={`${COMPONENT_CLASS}__summary_container`}>
                  <div className={`${COMPONENT_CLASS}__summary-description`}>
                    <p>{summaryDescription[0]} <b>{limit + quantity}</b> {summaryDescription[1]} <b>{USDollar.format(price)}</b> {summaryDescription[2]}</p>
                    <br />
                    <p> *The first charge will be prorated based on your current Symmio subscription's billing cycle.</p>
                  </div>
                  <hr />
                  <p className={`${COMPONENT_CLASS}__summary-header`}>Summary</p>
                  <div className={`${COMPONENT_CLASS}__summary_container_pay-line`}>
                    <p>{quantity} {serviceName}(s)</p>
                    <p>{USDollar.format(fullRate!)} / Month</p>
                  </div>
                  {refundedAmount! > 0 && (
                    <div className={`${COMPONENT_CLASS}__summary_container_pay-line`}>
                      <p>Prorated discount for {amountOfUnusedDays} unused days {/* TODO: refund for X unused days: Date.now - period start*/}</p>
                      <p>-{USDollar.format(refundedAmount!)}</p>
                    </div>
                  )}
                  {proratedRate && proratedRate > 0 && (
                    <div className={`${COMPONENT_CLASS}__summary_container_pay-line`}>
                      <p className={`${COMPONENT_CLASS}__summary-total-due`} style={{ fontWeight: 'bold' }}>Total due today</p>
                      <p>{USDollar.format(proratedRate)}</p>
                    </div>
                  )}

                  <hr />
                  <div className={`${COMPONENT_CLASS}__summary_container_button`}>
                    <Button
                      type='link'
                      buttonText='Cancel'
                      onClick={() => {
                        onClose()
                      }} />
                    <Button
                      type="default"
                      buttonText="Purchase"
                      onClick={handlePayment} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div >
      }
    </>
  );
};

export default AddOnDialogBox;