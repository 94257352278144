import { GroupIds } from "../models/enumerations/group-ids";

export default class GroupUtil {

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static getName(groupId?: string): string {
        groupId = GroupUtil.getGroupIdToUse(groupId);
        const group = GroupUtil.groupData.find(x => x.groupId === groupId);
        if (group == null) {
            return `No name for ${groupId}`;
        }
        return group.name;
    }

    public static getDescription(groupId?: string): string | undefined {
        groupId = GroupUtil.getGroupIdToUse(groupId);
        const group = GroupUtil.groupData.find(x => x.groupId === groupId);
        if (group == null) {
            return `No description for ${groupId}`;
        }
        return group.description;
    }

    // #endregion Public Methods


    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private static groupData = [
        // Movements
        { groupId: GroupIds.BALANCE, name: "Balance & Reach", description: undefined },
        { groupId: GroupIds.ROTATION, name: "Rotation", description: undefined },
        { groupId: GroupIds.SHOULDER_CLEARING, name: "Shoulder Clearing", description: undefined },
        { groupId: GroupIds.SHOULDER_MOBILITY, name: "Shoulder Mobility", description: undefined },
        { groupId: GroupIds.SPINE_CLEARING, name: "Spine Clearing", description: undefined },
        { groupId: GroupIds.SQUAT, name: "Squat", description: undefined },
        { groupId: GroupIds.TOE_TOUCH, name: "Toe Touch", description: undefined },

        // Lifestyle
        { groupId: GroupIds.BEHAVIORAL_HEALTH, name: "Behavioral Health", description: "Stress, anxiety, and depression can all negatively impact your MSK health. Integrating care of your mental and physical health can lead to an overall improvement in health and wellbeing." },
        { groupId: GroupIds.BODY_COMPOSITION, name: "Body Composition", description: "Knowing your body composition can help assess and manage your overall health and wellness and help avoid metabolic issues, breathing dysfunction and other potential health problems." },
        { groupId: GroupIds.BREATHING, name: "Breathing Quality", description: "Breathing Assessment makes sure your diaphragm and breathing mechanics are functioning the way they should and that they don't interfere with your core function and the way you move and go about your day." },
        { groupId: GroupIds.INJURY_HISTORY, name: "Injury History", description: "Injuries happen to almost everyone at some point and though you may feel recovered, research suggests you still may be at a higher risk. Understanding your level of risk is crucial to staying healthy." },
        { groupId: GroupIds.NUTRITION, name: "Nutrition Awareness", description: "The quality of what we eat impacts our physical, mental, and emotional wellbeing. Understanding this dynamic more clearly can help improve our overall MSK health and our daily lives." },
        { groupId: GroupIds.PHYSICAL_ACTIVITY, name: "Physical Activity", description: "Physical activity and exercise can have immediate and long-term health benefits. Most importantly, regular activity can improve your quality of life and reduce the risk of developing several life-threatening diseases." },
        { groupId: GroupIds.SLEEP, name: "Sleep Wellness", description: "Despite its relationship to a healthier and more fulfilling life, we often don't get enough of the quality  sleep we need to go about our daily life." },

        // General
        { groupId: GroupIds.GENERAL_HEALTH, name: "General Health", description: undefined },
        { groupId: GroupIds.MOVEMENT_HEALTH, name: "Movement Health", description: "Movement Health Assessment makes sure everything is good under the hood, so you can do everyday things like reach, twist, bend and balance— without pain and limitations." },
    ];

    private static getGroupIdToUse(groupId: string | undefined): string | undefined {
        switch (groupId) {
            case GroupIds.BEHAVIORAL_QUESTIONNAIRE:
                return GroupIds.BEHAVIORAL_HEALTH;
            case GroupIds.NUTRITION_QUESTIONNAIRE:
                return GroupIds.NUTRITION;
            case GroupIds.SLEEP_PSQI:
                return GroupIds.SLEEP;
            default:
                return groupId;
        }
    }

    // #endregion Private Methods

}
