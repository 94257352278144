import { VideoSize } from "../../../models/enumerations/video-size";
import { QuestionGroup } from "../../../models/interfaces/questions/question-group";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MovementQuestionPathParams {}

export interface MovementQuestionsQueryParams {
    videoSize: VideoSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/questions/movement`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const MovementQuestionsService = {
    list: ServiceBase.list<QuestionGroup[], MovementQuestionsQueryParams>(
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MovementQuestionsService;

// #endregion Exports
