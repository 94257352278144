import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";
import { Button } from "../../button/button";

export interface LoginModalButtonsProps {
    error: LoginError,
    onFirstButtonClick: Function,
    onSecondButtonClick: Function,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents buttons on the login modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginModalButtons: React.FC<LoginModalButtonsProps> = (props) => {
    const {
        error,
        onFirstButtonClick,
        // onSecondButtonClick,
    } = props;

    const handleButtonClick = () => {
        onFirstButtonClick();
    };

    // const handleSecondButtonClick = () => {
    //     onSecondButtonClick();
    // };

    let areaClass = `${COMPONENT_CLASS}__button-area -no-lower-message`;
    let buttonText = 'Confirm';

    if (error === LoginError.AdminDeactivatedBilling) {
        buttonText = 'Reactivate Account';
    }

    if (error === LoginError.AdminDeactivated) {
        buttonText = 'Request Reactivation';
    }

    if (error === LoginError.AdminTrialExpired) {
        // buttonText = 'Upgrade Now';
        buttonText = "Request Upgrade";
    }

    if (error === LoginError.SubscriptionPaymentFailed) {
        buttonText = "Reactivate Account";
    }

    return (
        <div className={areaClass}>
            <Button
                buttonStyle="primary"
                buttonText={buttonText}
                onClick={handleButtonClick}
            />
            {/* {
                error === LoginError.AdminTrialExpired && (
                    <>
                        <h2 style={{ margin: '15px 50px 15px 50px' }}>Or</h2>
                        <a
                            href="mailto:support@symmio.com"
                            className={`${COMPONENT_CLASS}__button -second-button`}
                            onClick={handleSecondButtonClick}
                        >
                            Contact Us
                        </a>
                    </>
                )
            } */}
        </div >
    );
};

export default LoginModalButtons;