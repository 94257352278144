/**
 * Represents the types of login errors that can occur.
 */
export enum LoginError {
    DoNotUseThisOne, // for some reason a value of 0 causes issues rendering the login modal
    AdminTrialExpired,
    AdminDeactivated,
    AdminDeactivatedBilling,
    TrialExpired,
    Deactivated,
    SubscriptionPaymentFailed,
}