import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    updateDoc,
    limit,
    onSnapshot,
    orderBy,
    query,
    QueryConstraint,
    setDoc,
    Timestamp,
    where,
    deleteField as fbDeleteField,
    arrayUnion,
} from "firebase/firestore";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { IUser } from "../../models/interfaces/assessment-user";
import getFirebase from "../firebase";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreDocument from "../../models/interfaces/firestore-document";

const { db } = getFirebase();

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------'

/**
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is going to be added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise to the newly added document
 */
const add = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: IUser | null = null
): Promise<Type> => {
    document.id = doc(collection(db, collectionName)).id;
    document.created = Timestamp.now();

    if (currentUser != null) {
        document.createdBy = currentUser.id;
    }

    await setDoc(doc(db, collectionName, document.id), document);

    return document as Type;
};

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------'

/**
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is going to be added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise to the newly added document
 */
const addById = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUserId: string | null = null
): Promise<Type> => {
    document.id = doc(collection(db, collectionName)).id;
    document.created = Timestamp.now();

    if (currentUserId != null) {
        document.createdBy = currentUserId;
    }

    await setDoc(doc(db, collectionName, document.id), document);

    return document as Type;
};

/**
 * Delete a document collection by the Id
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {string} id - The Id of the document being deleted
 */
const deleteById = async (collectionName: string, id: string) => {
    return deleteDoc(doc(db, collectionName, id));
};

/**
 * Find the specific document by the id
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {string} id - The Id of the document that we are retrieving
 * @returns {Promise<Type | null>} A promise for the document
 */
const get = async <Type extends FirestoreDocument>(
    collectionName: string,
    id: string
): Promise<Type | null> => {
    const document = await getDoc(doc(db, collectionName, id));

    if (document.exists()) {
        return document.data() as Type;
    }

    return null;
};

/**
 * Get all of the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getAll = async <Type extends FirestoreDocument>(
    collectionName: string,
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
): Promise<Type[]> => {
    const q: QueryConstraint[] = orderValue.map((o) =>
        orderBy(o.field, o.direction)
    );

    if (limitValue) {
        q.push(limit(limitValue));
    }

    const documents = await getDocs(
        query(collection(db, collectionName), ...q)
    );

    return documents.docs.map((doc) => doc.data() as Type);
};

/**
 * Get all of the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getBy = async <Type extends FirestoreDocument>(
    collectionName: string,
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
): Promise<Type[]> => {
    if (conditions.length === 0) {
        return [] as Type[];
    }
    const q: QueryConstraint[] = conditions.map((c) =>
        where(c.field, c.operator, c.value)
    );
    q.push(...orderValue.map((o) => orderBy(o.field, o.direction)));

    if (limitValue != null && limitValue >= 1) {
        q.push(limit(limitValue));
    }
    try {
        const documents = await getDocs(
            query(collection(db, collectionName), ...q)
        );
        return documents.docs.map((doc) => doc.data() as Type);
    } catch (err) {
        console.log(
            `ERROR querying ${collectionName}:\nError: ${err}\nConditions:`,
            conditions
        );
        return [];
    }
};

/**
 * Finds records from the last 24 hours that matches the user, plus any additional conditions
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {interfaces.User} user - The user related to the record
 * @returns {Promise<Type | null>} A promise for the document
 */
const getRecent = async <Type extends FirestoreDocument>(
    collectionName: string,
    user: IUser,
    additionalConditions: FirestoreCondition[] = []
): Promise<Type[] | null> => {
    const nowMillis = Timestamp.now().toMillis();
    const dayMillis = 24 * 60 * 60 * 1000;
    const recent = Timestamp.fromMillis(nowMillis - dayMillis);

    const conditions = additionalConditions ?? [];
    const existingConditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: user.id,
        },
        {
            field: "created",
            operator: ">",
            value: recent,
        },
    ];

    conditions.push(...existingConditions);

    const records = await FirestoreServiceAssessments.getBy<Type>(
        collectionName,
        conditions
    );
    //console.log(`getRecent("${collectionName}", "${user.id}"): recentDate:`, recent, "recentRecords:", records);
    return records;
};

/**
 * Finds records from the last 24 hours that matches the user, plus any additional conditions
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {interfaces.User} user - The user related to the record
 * @returns {Promise<Type | null>} A promise for the document
 */
const getRecentById = async <Type extends FirestoreDocument>(
    collectionName: string,
    userId: string,
    additionalConditions: FirestoreCondition[] = []
): Promise<Type[] | null> => {
    const nowMillis = Timestamp.now().toMillis();
    const dayMillis = 24 * 60 * 60 * 1000;
    const recent = Timestamp.fromMillis(nowMillis - dayMillis);
    const conditions = additionalConditions ?? [];
    const existingConditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "created",
            operator: ">",
            value: recent,
        },
    ];
    conditions.push(...existingConditions);
    const records = await FirestoreServiceAssessments.getBy<Type>(
        collectionName,
        conditions
    );
    //console.log(`getRecent("${collectionName}", "${user.id}"): recentDate:`, recent, "recentRecords:", records);
    return records;
};

/**
 * Subscribe to the object to get realtime updates
 * @param {string} collectionName - Name of the collection
 * @param {string} id - ID of the document
 * @param {Function} listener - Function for the listener when updates have been received
 */
const getSnapshot = <Type extends FirestoreDocument>(
    collectionName: string,
    id: string,
    listener: Function
) => {
    return onSnapshot(doc(db, collectionName, id), (doc) => {
        listener(doc.data() as Type);
    });
};

/**
 * Subscribe to the query to get realtime updates
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {FirestoreCondition[]} conditions - The condition to query the collection of documents in Firestore
 * @param {Function} listener - Function for the listener when updates have been received
 */
const getSnapshotBy = <Type extends FirestoreDocument>(
    collectionName: string,
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[],
    limitValue: number,
    listener: Function
) => {
    const q: QueryConstraint[] = conditions.map((c) =>
        where(c.field, c.operator, c.value)
    );

    q.push(...orderValue.map((o) => orderBy(o.field, o.direction)));

    if (limitValue != null && limitValue >= 1) {
        q.push(limit(limitValue));
    }

    return onSnapshot(
        query(collection(db, collectionName), ...q),
        (snapshot) => {
            listener(snapshot.docs.map((doc) => doc.data() as Type));
        }
    );
};

/**
 * Save the specified document in the database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the user that is being updated
 */
const save = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: IUser | null = null
): Promise<Type> => {
    if (document.id == null) {
        return add(collectionName, document, currentUser);
    }
    return update(collectionName, document, currentUser);
};

/**
 * Update the specified document stored in the firestore database
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const update = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: IUser | null = null
) => {
    // Make this immutable and NEVER do any mutations; This can cause bugs and performance issues
    document.updated = Timestamp.now();

    if (currentUser !== null) {
        document.updatedBy = currentUser.id;
    } else if (document.updatedBy != null) {
        document.updatedBy = "";
    }
    await setDoc(doc(db, collectionName, document.id!), document, {
        merge: true,
    });
    return document as Type;
};

/**
 * Update the specified document stored in the firestore database
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const updateById = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUserId: string | null = null,
    mskScoreSave?: boolean,
) => {
    // Make this immutable and NEVER do any mutations; This can cause bugs and performance issues
    document.updated = Timestamp.now();

    if (currentUserId !== null) {
        document.updatedBy = currentUserId;
    } else if (document.updatedBy != null) {
        document.updatedBy = "";
    }
    if (mskScoreSave) {
        await setDoc(doc(db, collectionName, document.id!), {
            ...document,
            assessmentResponseIds: arrayUnion(...(document as any).assessmentResponseIds)
        }, {
            merge: true,
        }).catch((e) => {});
        return document as Type;
    }
    await setDoc(doc(db, collectionName, document.id!), document, {
        merge: true,
    }).catch((e) => {});
    return document as Type;
};

/**
 * Update the specified field of a document stored in the firestore database
 * @param {string} collectionName - Name of a collection where the document resides
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @param {string} keyPath - The path to the key within a data structure
 * @param {any} newField - The data of a single field of a document that is being updated
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const updateField = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: IUser | null = null,
    keyPath: string,
    newField: any
) => {
    let updatedBy: string | undefined = "";

    if (currentUser !== null) {
        updatedBy = currentUser.id;
    }

    const docRef = doc(db, collectionName, document.id!);

    updateDoc(docRef, {
        [keyPath]: newField,
        updated: Timestamp.now(),
        updatedBy: updatedBy,
    });

    return document as Type;
};

/**
 * Update the specified field of a document stored in the firestore database
 * @param {string} collectionName - Name of a collection where the document resides
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @param {string} keyPath - The path to the key that's getting deleted
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const deleteField = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: IUser | null = null,
    keyPath: string
) => {
    let updatedBy: string | undefined = "";

    if (currentUser !== null) {
        updatedBy = currentUser.id;
    }

    const docRef = doc(db, collectionName, document.id!);

    updateDoc(docRef, {
        [keyPath]: fbDeleteField(),
        updated: Timestamp.now(),
        updatedBy: updatedBy,
    });

    return document as Type;
};

/**
 * @param {Type} document - The document that is going to be added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise to the newly added document
 */
const logError = async <Type extends FirestoreDocument>(
    collectionName: "errorLogs",
    document: Type,
    currentUser: IUser | null = null
): Promise<Type> => {
    document.id = doc(collection(db, collectionName)).id;
    document.created = Timestamp.now();

    if (currentUser != null) {
        document.createdBy = currentUser.id;
    }

    await setDoc(doc(db, collectionName, document.id), document);

    return document as Type;
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const FirestoreServiceAssessments = {
    add,
    addById,
    deleteById,
    get,
    getAll,
    getBy,
    getRecent,
    getRecentById,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
    updateById,
    updateField,
    deleteField,
    logError,
};

export default FirestoreServiceAssessments;

// #endregion Exports
