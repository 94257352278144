export enum GroupIds {
    // Extra Slides
    ASSESSMENT_INTRO_SLIDE_GROUP_ID = "ASSESSMENT_INTRO_SLIDE_GROUP_ID",
    COMPLETE_SLIDE_GROUP_ID = "COMPLETE_SLIDE_GROUP_ID",
    TESTS_SLIDE_GROUP_ID = "TESTS_SLIDE_GROUP_ID",
    TIPS_SLIDE_GROUP_ID = "TIPS_SLIDE_GROUP_ID",

    // Movements
    BALANCE = "BALANCE",
    ROTATION = "ROTATION",
    SHOULDER_CLEARING = "SHOULDER_CLEARING",
    SHOULDER_MOBILITY = "SHOULDER_MOBILITY",
    SPINE_CLEARING = "SPINE_CLEARING",
    SQUAT = "SQUAT",
    TOE_TOUCH = "TOE_TOUCH",

    // Lifestyle
    BEHAVIORAL_HEALTH = "BEHAVIORAL_HEALTH",
    BEHAVIORAL_QUESTIONNAIRE = "BEHAVIORAL_QUESTIONNAIRE",
    BODY_COMPOSITION = "BODY_COMPOSITION",
    BREATHING = "BREATHING",
    INJURY_HISTORY = "INJURY_HISTORY",
    NUTRITION = "NUTRITION",
    NUTRITION_QUESTIONNAIRE = "NUTRITION_QUESTIONNAIRE",
    PHYSICAL_ACTIVITY = "PHYSICAL_ACTIVITY",
    SLEEP = "SLEEP",
    SLEEP_PSQI = "SLEEP_PSQI",

    // General
    GENERAL_HEALTH = "GENERAL_HEALTH",
    MOVEMENT_HEALTH = "MOVEMENT_HEALTH",
    PAIN = "PAIN",
    SETUP = "SETUP",
    MSK = "MSK",
}
