import { Button } from "../button/button";
import { useState } from "react";
import {
    CheckCircleIcon,
    ClipboardIcon,
} from '@heroicons/react/outline';
import { Modal } from "../modal/modal";
import '../deeplinks-list/deeplink-search-hits.styles.scss';
import { SubscriptionLink } from "../../models/interfaces/subscription-link";
import LinkInfo from "../lead-links/lead-link-view";
import { UserTag } from "../../models/interfaces/user-tag";


interface SubscriptionLinkSearchHitsProps {
    subscriptionLinks: SubscriptionLink[];
    editClickHandler: (subscriptionLink: SubscriptionLink) => void;
    deleteClickHandler: (subscriptionLink: SubscriptionLink) => void;
}

const SubscriptionLinkSearchHits: React.FC<SubscriptionLinkSearchHitsProps> = (props) => {

    const { subscriptionLinks, editClickHandler, deleteClickHandler } = props;
    const [openQRModal, setOpenQRModal] = useState(false);
    const [copiedLinkId, setCopiedLinkId] = useState('');
    const [modalSubscriptionLink, setModalSubscriptionLink] = useState<any>();

    const handleQRClick = (subscriptionLink: any) => {
        setModalSubscriptionLink(subscriptionLink);
        setOpenQRModal(true);
    }

    const handleLinkCopy = (subscriptionLink: SubscriptionLink | undefined) => {

        if (!subscriptionLink?.url || !subscriptionLink?.id) {
            console.error('Copy link failed. No id or URL available');
            return;
        }
        // TODO : Global exception handling
        // https://app.clickup.com/t/2219993/FMS-1236

        navigator.clipboard.writeText(subscriptionLink.url);
        setCopiedLinkId(subscriptionLink.id);
        setTimeout(() => {
            setCopiedLinkId('');
        }, 2000);
    }

    const tagsToMarkup = (tags: UserTag[]) => {
        let tagArray: string[] = [];

        for (var i = 0; i < tags.length; i++) {
            tagArray.push(tags[i].name);
        }

        let tagString = tagArray.join(", ");

        return (
            <>
                {tagString}
            </>
        );
    }

    return subscriptionLinks.length ? (
        <>
            {subscriptionLinks.map((subscriptionLink, index) => {
                const className = (subscriptionLink.status === 'active') ? '' : 'disabled';
                return (
                    <tr className={`${className} table-row-container`} onClick={() => { handleQRClick(subscriptionLink) }} key={`deeplink_${index}`}>
                        <td>
                            <div className={'icon-container'} onClick={(event) => {
                                event.stopPropagation();
                                handleLinkCopy(subscriptionLink);
                            }}>
                                {(copiedLinkId === subscriptionLink.id) ?
                                    <CheckCircleIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                    : <ClipboardIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                }
                            </div>
                        </td>
                        <td><strong>{subscriptionLink.name}</strong></td>
                        <td><strong className="show-on-mobile mr-1">GROUP:</strong>{subscriptionLink.group?.label}</td>
                        <td>{(subscriptionLink.tags && subscriptionLink.tags.length > 0) &&
                            <>
                                <strong className="show-on-mobile mr-1">TAGS:</strong>
                                {tagsToMarkup(subscriptionLink.tags)}
                            </>
                        }
                        </td>
                        <td>
                            <div className='buttons-container'>
                                <Button
                                    type="table-action"
                                    buttonText="Edit"
                                    buttonStyle="white"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        editClickHandler(subscriptionLink);
                                    }}
                                />
                                <Button
                                    type="table-action"
                                    buttonText="Delete"
                                    buttonStyle="white" onClick={(event) => {
                                        event.stopPropagation();
                                        deleteClickHandler(subscriptionLink)
                                    }} />
                            </div>
                        </td>
                    </tr>
                )
            })}
            <Modal
                isOpen={openQRModal}
                isLoading={false}
                onClose={() => { setOpenQRModal(false) }}
                title="Link Information">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ borderBottom: '1px solid #F3F3F4' }}>
                        <LinkInfo linkData={modalSubscriptionLink}></LinkInfo>
                    </div>
                </div>

            </Modal>
        </>
    ) : (
        <td className={`pt-6`} colSpan={8}>
            <div className="flex justify-center items-center h-full">
                <span className="text-center">
                    No subscription links created, to create a link, click "+ Add Subscription Link"
                </span>
            </div>
        </td>
    )
}

export default SubscriptionLinkSearchHits;
