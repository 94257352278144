import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { UniqueUserInviteLink } from "../../models/interfaces/unique-user-invite-link";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "uniqueUserInviteLinks";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new UniqueUserInviteLink to the UniqueUserInviteLinks collection
 * @param {UniqueUserInviteLink} UniqueUserInviteLink - The UniqueUserInviteLink that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<UniqueUserInviteLink>} A promise to the newly added UniqueUserInviteLink
 */
const add = async (UniqueUserInviteLink: UniqueUserInviteLink, currentUser: User | null = null) => {
    return FirestoreService.add<UniqueUserInviteLink>(COLLECTION_NAME, UniqueUserInviteLink, currentUser);
};

/**
 * Delete a UniqueUserInviteLink collection by the Id
 * @param {string} id - The Id of the UniqueUserInviteLink being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific UniqueUserInviteLink by the id
 * @param {string} id - The Id of the UniqueUserInviteLink that we are retrieving
 * @returns {Promise<UniqueUserInviteLink>} A promise for the UniqueUserInviteLink we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<UniqueUserInviteLink>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<UniqueUserInviteLink>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<UniqueUserInviteLink>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the UniqueUserInviteLinks stored in the database
 * @returns {Promise<UniqueUserInviteLink[]>} A promise for the collection of UniqueUserInviteLinks
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<UniqueUserInviteLink>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific UniqueUserInviteLink to see when it changes
 * @param {string} id - Id of the UniqueUserInviteLink document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<UniqueUserInviteLink>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the UniqueUserInviteLinks to see when it changes
 * @param {string} id - Id of the UniqueUserInviteLink document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<UniqueUserInviteLink>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified UniqueUserInviteLink in the database
 * @param {UniqueUserInviteLink} UniqueUserInviteLink - The UniqueUserInviteLink that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<UniqueUserInviteLink>} A promise for the UniqueUserInviteLink that is being updated
 */
const save = async (UniqueUserInviteLink: UniqueUserInviteLink, currentUser: User | null = null) => {
    return FirestoreService.save<UniqueUserInviteLink>(COLLECTION_NAME, UniqueUserInviteLink, currentUser);
};

/**
 * Update the specified UniqueUserInviteLink stored in the database
 * @param {UniqueUserInviteLink} UniqueUserInviteLink - The UniqueUserInviteLink that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<UniqueUserInviteLink>} A promise for the UniqueUserInviteLink that is being updated
 */
const update = async (UniqueUserInviteLink: UniqueUserInviteLink, currentUser: User | null = null) => {
    return FirestoreService.update<UniqueUserInviteLink>(COLLECTION_NAME, UniqueUserInviteLink, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UniqueUserInviteLinksService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default UniqueUserInviteLinksService;

// #endregion Exports