import { StateCreator } from "zustand";
import FirestoreCondition from "../models/interfaces/firestore-condition";
import { Tag } from "../models/interfaces/tag";
import TagService from "../utilities/services/tag-service";
import { UnifiedStore } from "./useStore";
import EntityNotFoundException from "../models/exceptions/entity-not-found";
import { DropdownSelectOption } from "../models/interfaces/dropdown-select-option";

export interface TagSliceState {
    tagIsLoaded: boolean;
    tags: Tag[];
    tagsDropdownSelectOptionsAreLoaded: boolean;
    tagsDropdownSelectOptions: DropdownSelectOption[];
}

const initialState = {
    tagIsLoaded: false,
    tags: [],
    tagsDropdownSelectOptionsAreLoaded: false,
    tagsDropdownSelectOptions: [],
}

export interface TagSlice extends TagSliceState {
    /**
     * Gets all tags that the Organization has created.
     * @returns {Tag[]}
     */
    getTagsByOrganization: (id: string) => Promise<Tag[]>;

    getTagById: (id: string, orgId: string) => Promise<Tag>;

    getTagsDropDownSelectOptions: (id: string) => Promise<DropdownSelectOption[]>;

    /**
     * Marks the internal cache as stale and calls back out to
     * services for fresh data.
     */
    markTagsStale: () => Promise<void>;
}

export const createTagSlice: StateCreator<
    UnifiedStore,
    [],
    [],
    TagSlice
> = (set, get) => ({
    ...initialState,

    getTagById: async (id: string, orgId: string): Promise<Tag> => {
        // const {
        //     tagIsLoaded,
        //     getTagsByOrganization
        // } = get();
        let { tags } = get();

        // if (!tagIsLoaded) {
        //     tags = await getTagsByOrganization(orgId);
        // }

        const entity = tags.find(x => x.id === id);

        if (!entity) {
            throw EntityNotFoundException;
        }

        return entity;
    },

    getTagsByOrganization: async (id: string): Promise<Tag[]> => {
        // const { tagIsLoaded, tags } = get();

        // if (tagIsLoaded) {
        //     return tags;
        // }

        let conditions: FirestoreCondition[] = [
            {
                field: "organizationId",
                operator: "==",
                value: id
            }
        ];

        const firestoreTags = await TagService.getBy(
            conditions,
            [{
                field: "name",
                direction: "asc",
            }]
        );

        set({
            tags: firestoreTags,
            // tagIsLoaded: true
        });

        return firestoreTags;
    },

    getTagsDropDownSelectOptions: async (id: string): Promise<DropdownSelectOption[]> => {
        const {
            // tagsDropdownSelectOptions,
            // tagsDropdownSelectOptionsAreLoaded,
            getTagsByOrganization,
        } = get();

        // if (tagsDropdownSelectOptionsAreLoaded) {
        //     return tagsDropdownSelectOptions;
        // }

        const returnValue = (await getTagsByOrganization(id))
            .map((tag): DropdownSelectOption => ({
                label: tag.name, value: tag.id
            }))

        set({ tagsDropdownSelectOptions: returnValue });

        return returnValue;
    },

    markTagsStale: async (): Promise<void> => set({
        ...initialState
    })
});
