import { ChevronRightIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import React from "react";
import { GroupCustomData } from "../../models/interfaces/group-custom-data";
import { Button } from "../button/button";

const COMPONENT_CLASS = "c-tree__node";

type Props = {
    node: NodeModel<GroupCustomData>;
    canDelete: boolean;
    canManage: boolean;
    depth: number;
    hasChildren: boolean;
    isAdmin: boolean;
    isOpen: boolean;
    isVisible: boolean;
    managedGroupIds: string[];
    onToggle: (id: NodeModel["id"]) => void;
    onEdit: (id: NodeModel["id"]) => void;
    onDelete: (id: NodeModel["id"]) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
    const { id, droppable } = props.node;
    const indent = props.depth * 24;

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onEdit(props.node.id);
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onDelete(props.node.id);
    };

    const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

    let userCount = "";
    if (props.node?.data?.userCount != null) {
        userCount = `${props.node?.data?.userCount} User${props.node?.data?.userCount === 1 ? "" : "s"}`;
    }

    if (!props.isVisible) {
        return null;
    }

    return (
        <div
            className={`${COMPONENT_CLASS}__container`}
            style={{
                marginInlineStart: indent,
                width: `calc(100% - ${indent}px)`
            }}
        >
            <div
                {...dragOverProps}
            ></div>
            <div className={`${COMPONENT_CLASS}__grid-item`}>
                { // if
                    props.canManage &&
                    <div className={`${COMPONENT_CLASS}__grid-item__drag-handle`}>
                        <DotsVerticalIcon />
                        <DotsVerticalIcon />
                    </div>
                }
                {droppable && props.hasChildren && (
                    <div className={`${COMPONENT_CLASS}__expand-container`}>
                        <div className={`${COMPONENT_CLASS}__expand ${props.isOpen ? "-isOpen" : ""}`}>
                            <div
                                className={`${COMPONENT_CLASS}__expand__icon`}
                                onClick={handleToggle}>
                                <ChevronRightIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className={`${COMPONENT_CLASS}__grid-item__data-container`}>
                    <div className={`${COMPONENT_CLASS}__grid-item__title`}>
                        {props.node.text}
                    </div>
                    <div className={`${COMPONENT_CLASS}__grid-item__data`}>
                        {userCount}
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__grid-item__actions`}>
                    { // if
                        props.canManage &&
                        <Button
                            type="table-action"
                            onClick={handleEdit}
                            buttonStyle="white"
                            buttonText="Edit" />
                    }
                    { // if
                        props.canDelete &&
                        <Button
                            type="table-action"
                            onClick={handleDelete}
                            buttonStyle="white"
                            buttonText="Delete" />
                    }
                </div>
            </div>
        </div>
    );
};
