import { ListOptions } from "./interfaces/list-options";

export default class UserRoles {
    public static ADMIN_ID = "admin";
    public static GROUP_MANAGER_ID = "group_manager";
    public static TEAM_MEMBER_ID = "team_member";
    public static ACCOUNT_HOLDER_ID = "account_holder";
    public static CLIENT_ID = "client";

    /**
     * All role options. Sort in the display order (not alphabetical).
     */
    public static ALL_ROLES: ListOptions[] = [
        {
            value: UserRoles.TEAM_MEMBER_ID,
            label: "Team Member",
        },
        {
            value: UserRoles.GROUP_MANAGER_ID,
            label: "Group Manager",
        },
        {
            value: UserRoles.ADMIN_ID,
            label: "Administrator",
        },
        {
            value: UserRoles.ACCOUNT_HOLDER_ID,
            label: "Account Holder",
        },
        {
            value: UserRoles.CLIENT_ID,
            label: "User",
        },
    ];

    /**
     * Role options for managers.
     */
    public static MANAGER_ROLES: ListOptions[] = [
        {
            value: UserRoles.TEAM_MEMBER_ID,
            label: "Team Member",
        },
        {
            value: UserRoles.CLIENT_ID,
            label: "User",
        },
    ];

    public static MANAGER_ROLES_ADDING_USERS: ListOptions[] = [
        {
            value: UserRoles.TEAM_MEMBER_ID,
            label: "Team Member",
        },
        {
            value: UserRoles.GROUP_MANAGER_ID,
            label: "Group Manager",
        },
        // {
        //     value: UserRoles.CLIENT_ID,
        //     label: "User",
        // },
    ]

    public static ADMIN_ROLES: ListOptions[] = [
        {
            value: UserRoles.TEAM_MEMBER_ID,
            label: "Team Member",
        },
        {
            value: UserRoles.GROUP_MANAGER_ID,
            label: "Group Manager",
        },
        {
            value: UserRoles.ADMIN_ID,
            label: "Administrator",
        },
        {
            value: UserRoles.CLIENT_ID,
            label: "User",
        },
    ];

    public static ADMIN_ROLES_ADDING_USERS: ListOptions[] = [
        {
            value: UserRoles.TEAM_MEMBER_ID,
            label: "Team Member",
        },
        {
            value: UserRoles.GROUP_MANAGER_ID,
            label: "Group Manager",
        },
        {
            value: UserRoles.ADMIN_ID,
            label: "Administrator",
        },
    ];
}
