import React from "react";
import { ChevronDownIcon } from '@heroicons/react/outline';
import RiskFactorUtil from "../../utilities/risk-factor-util";

const COMPONENT_CLASS = "c-risk-chart";

export interface RiskChartProps {
    data: number | undefined;
}

const RiskChart: React.FC<RiskChartProps> = (props) => {
    const rightPercent = RiskFactorUtil.convertRiskFactorToDisplayPercent(props.data);

    return (
        <div className={`${COMPONENT_CLASS}`}>
            { // if
                rightPercent != null &&
                <div className={`${COMPONENT_CLASS}__user`} style={{right: `calc(${rightPercent}% - 16px)`}}>
                    User <ChevronDownIcon />
                </div>
            }
            <div className={`${COMPONENT_CLASS}__segment -high`}>
                High Risk
            </div>
            <div className={`${COMPONENT_CLASS}__segment -moderate`}>
                Moderate Risk
            </div>
            <div className={`${COMPONENT_CLASS}__segment -slight`}>
                Slight Risk
            </div>
            <div className={`${COMPONENT_CLASS}__segment -low`}>
                Low Risk
            </div>
        </div>
    );
};

export default RiskChart;
