import { DateTime } from "luxon";

export default class DateUtil {
    public static MINUTE_AS_MS = 60 * 1000;
    public static HOUR_AS_MS = 60 * DateUtil.MINUTE_AS_MS;
    public static DAY_AS_MS = 24 * DateUtil.HOUR_AS_MS;

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    
    // This method will get the current local time as an ISO string. 
    public static getCurrentDateTimeIsoString(): string {
        const time = new Date();
        const timeZoneOffSet = time.getTimezoneOffset() * 60 * 1000;
        const timeLocal = Date.now()-timeZoneOffSet;
        return (new Date(timeLocal).toISOString());
    }

    public static getCurrentMsDate(roundUpToSecond: boolean = true): number {
        if (roundUpToSecond) {
            return Math.ceil(new Date().getTime() / 1000) * 1000;
        }
        return new Date().getTime();
    }

    public static getExpiration(
        minutesFromNow: number,
        roundToSecond: boolean = true
    ): number {
        return (
            DateUtil.getCurrentMsDate(roundToSecond) +
            minutesFromNow * DateUtil.MINUTE_AS_MS
        );
    }

    public static isFutureDate(
        dateInMilliseconds?: number,
        defaultIfNull: boolean = false
    ): boolean {
        if (dateInMilliseconds == null) {
            return defaultIfNull;
        }
        return dateInMilliseconds > new Date().getTime();
    }

    public static isPastDate(
        dateInMilliseconds?: number,
        defaultIfNull: boolean = false
    ): boolean {
        if (dateInMilliseconds == null) {
            return defaultIfNull;
        }
        return dateInMilliseconds < new Date().getTime();
    }

    public static isExpired(
        dateInMilliseconds?: number,
        defaultIfNull: boolean = true
    ): boolean {
        return DateUtil.isPastDate(dateInMilliseconds, defaultIfNull);
    }

    public static isToday(
        dateAsMilliseconds?: number | null,
        defaultIfNull: boolean = false
    ): boolean {
        if (dateAsMilliseconds == null || isNaN(dateAsMilliseconds)) {
            return defaultIfNull;
        }

        const date = new Date(dateAsMilliseconds);
        const now = new Date();

        return now.toLocaleDateString() === date.toLocaleDateString();
    }

    public static isBeforeToday(
        dateAsMilliseconds?: number,
        defaultIfNull: boolean = false
    ): boolean {
        if (dateAsMilliseconds == null || isNaN(dateAsMilliseconds)) {
            return defaultIfNull;
        }
        if (DateUtil.isToday(dateAsMilliseconds)) {
            return false;
        }
        return dateAsMilliseconds < new Date().getTime();
    }

    public static isGreaterThanOneDay(date: DateTime): boolean {
        const yesterday = DateTime.now().minus({ day: 1 });

        return !(date > yesterday.startOf("day"));
    }

    // #endregion Public Methods
}
