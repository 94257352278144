/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { GroupIds } from "../../models/enumerations/group-ids";
import { Assessment } from "../../models/interfaces/assessment";
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import UserService from "../../utilities/services/user-service";
import DonutChart from "../charts/donut-chart";
import LineChart from "../charts/line-chart";
import RiskChart from "../charts/risk-chart";
import { Loader } from "../loader/loader";
import UserProfileHeader from "../user-profile-header/user-profile-header";
import UserProfileSummary from "../user-profile-summary/user-profile-summary";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as ActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { getFocusDescription } from "../../utilities/get-focus-description";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import ReportService from "../../utilities/services/report-service";
import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import { Button } from "../button/button";

const COMPONENT_CLASS = "c-user-profile";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileProps {
    userId: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfile: React.FC<UserProfileProps> = (props: UserProfileProps) => {
    const db = getFirestore();
    const [isLoadingMskData, setIsLoadingMskData] = useState(true);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [percentPoints, setPercentPoints] = useState<LineChartPoint[]>();
    const [products, setProducts] = useState<any>();
    const [latestMskData, setLatestMskData] = useState<MskScore>();
    const [user, setUser] = useState<User | null>(null);
    const [, setIsLoading] = useState(true);
    const [, setReportError] = useState<string | undefined>();
    const [downloadingReportSnackBar, setDownloadingReportSnackBar] = useState<any>()

    useEffect(() => {
        if (!props.userId) {
            return;
        }

        UserService.getSnapshot(props.userId, (u: User) => {
            setUser(u);
            setIsLoadingUser(false);
        });

        MskScoreService.getSnapshotBy([{
            field: "userId",
            operator: "==",
            value: props.userId,
        }], [{
            field: "created",
            direction: "desc",
        }], 12, (mskScores: MskScore[]) => {
            if (mskScores.length > 0) {
                setLatestMskData(mskScores[0]);
            }

            setPercentPoints(mskScores
                .filter(d => d.created)
                .map(d => ({ created: d.created!.toDate(), percentage: d.percentage }))
            );

            setIsLoadingMskData(false);
        });

    }, [props.userId]);
    // Get products/prices
    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, 'products'),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {
                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })
            });
            setProducts(products);
        }
        getProductsAndPrices();
    }, [])

    const formatDate = (date: Date): string => {
        if (date == null || date.getFullYear() <= 1900) {
            return "Not Available";
        }
        return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
    }

    const getFocusAreaIcon = (groupId: string): any => {
        switch (groupId) {
            case GroupIds.MOVEMENT_HEALTH:
                return <MovementIcon className="icon" />
            case GroupIds.NUTRITION:
                return <NutritionIcon className="icon" />
            case GroupIds.BEHAVIORAL_HEALTH:
                return <BehavioralHealthIcon className="icon" />
            case GroupIds.BODY_COMPOSITION:
                return <BodyCompIcon className="icon" />
            case GroupIds.BREATHING:
                return <BreathingIcon className="icon" />
            case GroupIds.PHYSICAL_ACTIVITY:
                return <ActivityIcon className="icon" />
            case GroupIds.SLEEP:
                return <SleepIcon className="icon" />
            case GroupIds.INJURY_HISTORY:
                return <InjuryHistoryIcon className="icon" />
            default:
                return null;
        }
    }

    let mostRecentAssessment = "Not Available";
    if (latestMskData?.created) {
        mostRecentAssessment = formatDate(latestMskData.created.toDate());
    }

    const dayStreak = user?.dayStreak || 0;
    let lastLoggedIn = "Not Available";
    if (user?.lastLoggedIn) {
        lastLoggedIn = formatDate(new Date(user.lastLoggedIn));
    }

    const focusAreas: Assessment[] = latestMskData?.focusAreas || [];

    const handleGreenToast = () => {
        enqueueSnackbar("User profile updated!", { variant: "toast", width: "450px" });
    }

    // const handleDownloadReport = async (emailResults: boolean) => {
    //     if (!props.userId) return;
    //     const userInFirebase = await UserService.get(props!.userId);
    //     if (!userInFirebase || !userInFirebase.organizationId) return;
    //     const latestOrgId = userInFirebase.organizationId;
    //     const userId = userInFirebase.id;

    //     setIsLoading(true);
    //     if (!userId) {
    //         return;
    //     }
    //     const date = DateTime.utc().toISO();
    //     const report = await ReportService.getBy(
    //         [
    //             {
    //                 field: "userId",
    //                 operator: "==",
    //                 value: userId,
    //             },
    //             {
    //                 field: "organizationId",
    //                 operator: "==",
    //                 value: latestOrgId,
    //             },
    //             {
    //                 field: "status",
    //                 operator: "==",
    //                 value: "complete",
    //             },
    //             {
    //                 field: "type",
    //                 operator: "==",
    //                 value: 4,
    //             },
    //             {
    //                 field: "expiration",
    //                 operator: ">",
    //                 value: date,
    //             },
    //         ], [
    //         {
    //             field: "expiration",
    //             direction: "desc",
    //         },
    //         {
    //             field: "updated",
    //             direction: "desc",
    //         }]);
    //     if (report.length <= 0) {
    //         setIsLoading(false);
    //         setDownloadingReportSnackBar(enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "warning" }));
    //         handleGenerateReport(userId, latestOrgId);
    //         return;
    //     }

    //     if (!report[0].id) {
    //         setIsLoading(false);
    //         return;
    //     }

    //     const reportUnsubscribe = ReportService.getSnapshot(
    //         report[0].id,
    //         (r: Report) => {
    //             if (r.status === "complete" && r.url) {
    //                 setIsLoading(false);
    //                 reportUnsubscribe();

    //                 //Loads in the System browser
    //                 window.open(r.url);
    //             }
    //             if (r.status === "error") {
    //                 setReportError(r.errorMessage);
    //                 setIsLoading(false);
    //                 reportUnsubscribe();
    //             }
    //         }
    //     );
    // };

    const handleDownloadReport = async (emailResults: boolean) => {
        if (!props.userId) return;
        const userInFirebase = await UserService.get(props!.userId);
        if (!userInFirebase || !userInFirebase.organizationId) return;
        const latestOrgId = userInFirebase.organizationId;
        const userId = userInFirebase.id;

        setIsLoading(true);
        if (!userId) {
            return;
        }
        const assessmentResponseCheck = await AssessmentUtils.fetchAssessmentResponsesById(userId)
        let type = ReportType.Assessment
        if (assessmentResponseCheck && assessmentResponseCheck.length > 7) {
            type = ReportType.Wellness
        }
        const date = DateTime.utc().toISO();
        const report = await ReportService.getBy(
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
                {
                    field: "organizationId",
                    operator: "==",
                    value: latestOrgId,
                },
                {
                    field: "status",
                    operator: "==",
                    value: "complete",
                },
                {
                    field: "type",
                    operator: "==",
                    value: type,
                },
                {
                    field: "expiration",
                    operator: ">",
                    value: date,
                },
            ], [
            {
                field: "expiration",
                direction: "desc",
            },
            {
                field: "updated",
                direction: "desc",
            }]);

        if (report.length <= 0) {
            setIsLoading(false);
            setDownloadingReportSnackBar(enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "warning", autoHideDuration: 30000 }));
            handleGenerateWellnessReport(userId, latestOrgId, type);
            return;
        }

        if (!report[0].id) {
            setIsLoading(false);
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            report[0].id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    setIsLoading(false);
                    reportUnsubscribe();

                    //Loads in the System browser
                    closeSnackbar(downloadingReportSnackBar);
                    window.open(r.url);
                }
                if (r.status === "error") {
                    closeSnackbar(downloadingReportSnackBar);
                    setReportError(r.errorMessage);
                    setIsLoading(false);
                    reportUnsubscribe();
                }
            }
        );
    };

    // const handleGenerateReport = async (userId: string, organizationId: string) => {
    //     setIsLoading(true);
    //     const date = DateTime.now().toISODate();
    //     const report: Report = {
    //         dateEnd: date,
    //         dateStart: date,
    //         emailResults: false,
    //         organizationId: organizationId,
    //         status: "scheduled",
    //         type: ReportType.Assessment,
    //         userId: userId,
    //     };

    //     const user = await UserService.get(userId);

    //     const scheduledReport = await ReportService.save(
    //         report,
    //         user
    //     );

    //     if (!scheduledReport.id) {
    //         setIsLoading(false);
    //         setReportError(
    //             "Report was not saved successfully. Please try again."
    //         );
    //         return;
    //     }

    //     const reportUnsubscribe = ReportService.getSnapshot(
    //         scheduledReport.id,
    //         (r: Report) => {
    //             if (r.status === "complete" && r.url) {
    //                 setIsLoading(false);
    //                 reportUnsubscribe();

    //                 //Loads in the System browser
    //                 window.open(r.url);
    //             }
    //             if (r.status === "error") {
    //                 setReportError(r.errorMessage);
    //                 setIsLoading(false);
    //                 reportUnsubscribe();
    //             }
    //         }
    //     );
    // };

    const handleGenerateWellnessReport = async (userId: string, organizationId: string, type: number) => {
        setIsLoading(true);
        const date = DateTime.now().toISODate();

        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: type,
            userId: userId,
        };

        const user = await UserService.get(userId);

        const scheduledReport = await ReportService.save(
            report,
            user
        );

        if (!scheduledReport.id) {
            setIsLoading(false);
            setReportError(
                "Report was not saved successfully. Please try again."
            );
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    setIsLoading(false);
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    setReportError(r.errorMessage);
                    setIsLoading(false);
                    reportUnsubscribe();
                }
            }
        );
    };

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoadingMskData && isLoadingUser} />
            {user &&
                <UserProfileHeader user={user} greenToast={handleGreenToast} products={products} />
            }
            <hr className={`${COMPONENT_CLASS}__divider`} />
            <div className={`${COMPONENT_CLASS}__content`}>
                <h2>Platform Activity</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div className={`${COMPONENT_CLASS}__cta`}>
                        <div>{dayStreak}</div>
                        <h6><span>Day Streak</span></h6>
                    </div>
                    <div className={`${COMPONENT_CLASS}__cta`}>
                        <div>{lastLoggedIn}</div>
                        <h6><span>Last App Log In</span></h6>
                    </div>
                    <div className={`${COMPONENT_CLASS}__cta`}>
                        <div>{mostRecentAssessment}</div>
                        <h6><span>Most Recent Assessment</span></h6>
                    </div>
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__content -background`}>
                <div className={`c-user-profile-header__actions`}>
                    <h2><span>Statistics</span></h2>
                    {latestMskData && (
                        <Button
                            onClick={() => handleDownloadReport(false)}
                            buttonText="Download Latest Report" />
                    )}

                </div>
                <div className={`${COMPONENT_CLASS}__content__data`}>
                    <div className={`${COMPONENT_CLASS}__score-summary`}>
                        <h4>Overall Score</h4>
                        <DonutChart percentage={latestMskData?.percentage} title="Wellness Score" />
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-summary__chart`}>
                        <h4>Score History</h4>
                        {percentPoints &&
                            <LineChart data={percentPoints.slice().reverse()} />
                        }
                    </div>
                </div>
                { // if
                    latestMskData?.riskFactor?.percentage != null &&
                    <>
                        <h4 className="-space">Musculoskeletal Health Risk</h4>
                        <div>
                            <RiskChart data={latestMskData?.riskFactor?.percentage} />
                        </div>
                    </>
                }
                { // if
                    focusAreas.length > 0 &&
                    <div>
                        <h4 className="-space">Areas of Focus</h4>
                        <div className={`${COMPONENT_CLASS}__card-list`}>
                            {focusAreas.map((focusArea, index) => (
                                <div
                                    key={`focus-${index}`}
                                    className={`${COMPONENT_CLASS}__card`}>
                                    <div>
                                        <div className={`${COMPONENT_CLASS}__card__title`}>
                                            <div className={`${COMPONENT_CLASS}__icon -${focusArea.groupName?.replace(/\s+/g, '-').toLowerCase()}`}>
                                                {getFocusAreaIcon(focusArea.groupId!)}
                                            </div>
                                            <h4>{focusArea.groupName}</h4>
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__card__content`} dangerouslySetInnerHTML={{ __html: getFocusDescription(latestMskData, focusArea) ?? "" }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            { // if
                latestMskData != null &&
                /* {(state.claims.roles.indexOf("admin") > -1 || state.claims.superAdmin) && */
                <div>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        <h2 className="-space">Assessment Scores</h2>
                        {user &&
                            <UserProfileSummary
                                mskScore={latestMskData}
                                user={user} />
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default UserProfile;
