import * as Yup from 'yup';

export default class ValidationRules {
    static requiredDate(message: string) {
        return Yup
        .object()
        .required(message)
        .test({
            name: 'validDateString',
            test: (value: any) => value.isValid(),
            message: 'Enter a valid date'
        })
    }

    static optionalDate() {
        return Yup
        .object()
        .nullable()
        .test({
            name: 'validDateString',
            test: (value: any) => value.isValid(),
            message: 'Enter a valid date'
        })
    }
}
