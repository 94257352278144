import React from "react";
import { Button } from "../button/button";


export interface BaseCardLayoutActionsProps {
    backButton?: boolean;
    backDisabled?: boolean;
    submitDisabled?: boolean;
    submitButton?: boolean;
    height?: number;
    onSubmit?: Function;
    onBack?: Function;
    submitButtonText?: string;
    skipButton?: boolean;
    onSkip?: Function;
}

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const BaseCardLayoutActions: React.FC<BaseCardLayoutActionsProps> = (props) => {


    return (
        <div style={{ marginTop: "2rem", width: "100%" }}>
            <hr style={{ height: "1px", backgroundColor: "#12162233", border: "none" }} />
            <div className="flex justify-between m-5 items-center">
                {
                    <div style={{ opacity: `${(props.backButton && props.onBack) ? "100" : " 0"}` }}>
                        <Button disabled={props.backButton && props.backDisabled} onClick={() => {
                            if (props.onBack) {
                                props.onBack();
                            }
                        }}
                            type="back" />
                    </div>
                }
                <div style={{ display: "flex", flexWrap: "wrap-reverse", justifyContent: "end", gap: "1rem", marginLeft: "1rem" }} className="items-center">
                    {
                        // if
                        props.skipButton && (
                            <Button
                                type="link"
                                buttonText="Skip, I'll fill it
                                in later."
                                onClick={() => {
                                    if (props.onSkip) {
                                        props.onSkip();
                                    }
                                }} />
                            // <button style={{ paddingRight: "2rem", textDecoration: "underline" }} className={`__skip-button`}
                            //     onClick={() => {
                            //         if (props.onSkip) {
                            //             props.onSkip();
                            //         }
                            //     }}> Skip, I'll fill it
                            //     in later.</button>
                        )
                    }
                    {
                        props.onSubmit && props.submitButton &&
                        <Button
                            onClick={() => {
                                if (props.onSubmit) {
                                    props.onSubmit();
                                }
                            }}
                            disabled={props.submitButton && props.submitDisabled}
                            buttonType="submit"
                            inputClassName={'action-button'}
                            inputStyle={{ marginTop: "0px" }}
                            buttonText={props.submitButtonText}
                        />

                    }
                </div>
            </div>
        </div >
    );
};

export default BaseCardLayoutActions;