/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select from "react-select";
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { Group } from "../../models/interfaces/group";
import { DashboardData } from "../../models/interfaces/reports/dashboard-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import FunctionService from "../../utilities/services/function-service";
import GroupService from "../../utilities/services/group-service";
import UserUtil from "../../utilities/user-util";
import { Utils } from "../../utilities/utils";
import DonutChart from "../charts/donut-chart";
import LineChart from "../charts/line-chart";
import { Loader } from "../loader/loader";
import PillChartArray from "../charts/pill-chart/pill-chart-array";
import UserService from "../../utilities/services/user-service";
import { Modal } from "../modal/modal";
import welcomeImage from "../../assets/images/welcome.png";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import MskScoreService from "../../utilities/services/msk-score-service";
import { useHistory } from "react-router";
import { ToggleButton } from "../forms";

const COMPONENT_CLASS = "c-dashboard";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface DashboardProps {
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
    const [lineChartPoints, setLineChartPoints] = useState<LineChartPoint[]>();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [includeInactiveUsers, setIncludeInactiveUsers] = useState(true);
    const [dashboardData, setDashboardData] = useState<DashboardData | null>();
    const [groups, setGroups] = useState<Group[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>();
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const { state, setState } = useAuthState();
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const isAdmin = UserUtil.isAdmin(state.user);
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);
    const history = useHistory();

    const statusModalContent = {
        showReactivatedPlan: { title: "Account Reactivated", boldText: "Thank you for reactivating your Business Plan subscription!", mainText: "You will receive an email confirmation shortly.\n\nYou can manage your subscription and plan at any time in your account settings." },

        showReactivatedAddOn: { title: "Account Active", boldText: "Thank you for for updating your add-on!", mainText: "You will receive an email confirmation shortly.\n\nYou can manage your subscription and plan at any time in your account settings." },

        showCancelledPlan: { title: "Subscription Cancelled", boldText: "Your Business Plan subscription and add-ons have been cancelled.", mainText: "You will now be downgraded to the starter plan and your users will be deactivated and licenses removed from their accounts.\n\nYou can manage your subscription and plan at any time in your account settings." },

        showCancelledAddOn: { title: "Add-ons Cancelled", boldText: "Your Add-ons have been cancelled.", mainText: "The user and license limit will now be decreased.\n\nYou can manage your subscription and add-ons at any time in your account settings." },
    };

    const params = new URLSearchParams(window.location.search);
    type SubscriptionStatus = keyof typeof statusModalContent;
    const subscriptionStatusModalParam = params.get("subscriptionStatusModal") as SubscriptionStatus;
    const [showSubscriptionStatusModal, setShowSubscriptionStatusModal] = useState(subscriptionStatusModalParam ? true : false);

    useEffect(() => {
        const checkAssessmentResponses = async () => {
            if (state.user && state.user.id) {
                const assessmentResponseCheck = await AssessmentUtils.fetchAssessmentResponsesById(state.user.id ?? "")
                const mskCheck = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: state.user.id ?? ""
                }])
                if (assessmentResponseCheck && assessmentResponseCheck.length < 8 && mskCheck && mskCheck.length > 0 && !state.disableNewReportModal) {
                    history.push("/wellness-assessment?retake=true")
                }
            }
        }
        checkAssessmentResponses();
    }, [])

    useEffect(() => {
        if (state.organization?.id == null) {
            return;
        }

        let allowAllGroups = false;
        let groupId: string | null = null;

        if (isSuperAdmin || isAdmin || isAccountHolder) {
            allowAllGroups = true;
        } else if (UserUtil.isManagerOrTeamMember(state.user)) {
            groupId = UserUtil.getDefaultManageGroupId(state.user);
        }

        setSelectedGroupId(groupId);

        // do not load additional data
        if (groupId == null && allowAllGroups === false) {
            setGroups([]);
            return;
        }

        setIsLoadingGroups(true);
        GroupService.getSnapshotBy([{
            field: "organizationId",
            operator: "==",
            value: state.organization?.id,
        }], [{
            field: "name",
            direction: "asc"
        }], (groups: Group[]) => {
            if (allowAllGroups) {
                groups.unshift({
                    id: "",
                    name: "All",
                    organizationId: state.organization?.id!,
                });
            }
            setGroups(groups);
            setIsLoadingGroups(false);
        });
    }, [state.user, state.user?.managedGroupIds, state.organization?.id, isAccountHolder, isAdmin, isSuperAdmin]);

    useEffect(() => {
        const fetchUser = async () => {
            if (state.user && state.user?.id) {
                const user = await UserService.get(state.user?.id);

                if (user) {
                    setState((state) => ({
                        ...state, ...{ user: user }
                    }));
                }
            }
        }

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowWelcomeModal(state.user?.showWelcomeScreen || false);
    }, [state.user])

    useEffect(() => {
        if (selectedGroupId === undefined || groups == null || groups.length === 0) {
            return;
        }

        setIsLoadingData(true);
        const groupIds = selectedGroupId != null && selectedGroupId !== "" ? [selectedGroupId] :
            isAdmin
                ? null
                : state.user?.managedGroupIds ?? null;

        FunctionService.getDashboardData(groupIds, state.organization?.id!, includeInactiveUsers).then(data => {
            setDashboardData(data);
            setLineChartPoints(data?.history.map((d) => {
                const date = d.date.split("-");
                return {
                    created: new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])),
                    percentage: d.percent
                };
            }));
            setIsLoadingData(false);
        });
    }, [includeInactiveUsers, selectedGroupId, state.organization?.id, state.user, groups, isAdmin]);

    const getGroupOptions = () => {
        if (isAdmin || isAccountHolder) {
            return groups.map((g) => ({ label: g.name, value: g.id }));
        }

        return groups.filter(group => UserUtil.canManageGroup(state.user, group))
            .map((g) => ({ label: g.name, value: g.id }));
    }

    // const handleStartTour = async () => {
    //     const editUser = state.user;

    //     if (editUser) {
    //         editUser.showWelcomeScreen = false;
    //         await UserService.update(editUser);
    //         setShowWelcomeModal(false);
    //         setState((state) => ({
    //             ...state, ...{ user: editUser }
    //         }));
    //     }
    // }

    if (!isLoadingData && !isLoadingGroups && groups.length === 0) {
        return (
            <div className={COMPONENT_CLASS}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>Dashboard</h1>
                </div>
                <h2>Your account has no associated groups. Please contact your administrator to assign groups to your account.</h2>
            </div>
        );
    }

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoadingData || isLoadingGroups} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>Dashboard</h1>
                <div className={`${COMPONENT_CLASS}__status__group`}>
                    <Select
                        name="group"
                        classNamePrefix={COMPONENT_CLASS}
                        placeholder="Group"
                        options={getGroupOptions()}
                        onChange={(newValue: any, actionMeta: any) => setSelectedGroupId(newValue.value)}
                        value={{ label: groups.find(g => g.id === selectedGroupId)?.name || "All", value: selectedGroupId || 0 }}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "1.5rem",
                                lineHeight: "1.5rem",
                            }),
                        }} />
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__status`}>
                { // if
                    dashboardData != null &&
                    <div className={`${COMPONENT_CLASS}__status__container`}>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.activeUsers}
                            </div>
                            <h6>Active</h6>
                        </div>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.inactiveUsers}
                            </div>
                            <h6>Inactive</h6>
                        </div>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.pendingUsers}
                            </div>
                            <h6>Pending Accounts</h6>
                        </div>
                    </div>
                }
            </div>
            <div className={`${COMPONENT_CLASS}__statistics`}>

                <div className="grid grid-cols-3 gap-2 clear-both">
                    { // if
                        dashboardData != null &&
                        dashboardData?.percent !== -1 &&
                        <div className={`${COMPONENT_CLASS}__score-summary`}>
                            <h3>Overall Score</h3>
                            <DonutChart
                                percentage={dashboardData?.percent || 0}
                                title="Average Wellness<br />Score"
                            />
                        </div>
                    }
                    { // if
                        lineChartPoints != null &&
                        <div className={`${COMPONENT_CLASS}__score-line`}>
                            <div className={`${COMPONENT_CLASS}__statistics__container`}>
                                <h3>Score History</h3>
                                <ToggleButton
                                    id="inactiveUsersToggle"
                                    isChecked={includeInactiveUsers}
                                    onClick={() => setIncludeInactiveUsers(!includeInactiveUsers)}
                                    mainLabelText="Include Inactive Users"
                                    isRightSideLabels={true}
                                    containerStyle={{ marginTop: "0", alignItems: "center" }} />
                            </div>
                            <LineChart
                                axisFormat="%b"
                                data={lineChartPoints}
                                title="Score History"
                            />
                        </div>
                    }

                </div>
                {/* {
                    lineChartPoints && <MonthlyScoreChart
                        data={lineChartPoints}
                        title="Score History"
                    />
                } */}
            </div>
            {
                dashboardData?.scoreBreakdown != null &&
                dashboardData.scoreBreakdown.length >= 1 &&
                <div className={`${COMPONENT_CLASS}__score`}>
                    <PillChartArray
                        data={dashboardData?.scoreBreakdown.map((score) => ({ range: `${score.name}`, value: score.count }))}
                    />
                </div>
            }
            { // if
                dashboardData?.riskFactorCodeCounts != null &&
                dashboardData.riskFactorCodeCounts.length >= 1 &&
                <div className={`${COMPONENT_CLASS}__injury`}>
                    <h3 style={{ marginBottom: "30px" }}>Musculoskeletal Health Risk</h3>
                    <p className="opacity-60 mb-8 font-light text-sm">Injury risk is multifactorial, and Symmio’s evidence-based algorithm classifies users into one of four categories based on the number and severity of risk factors. This classification helps to identify the number of users in each risk category, ensuring that you are aware of potential risks.</p>
                    <div className={`${COMPONENT_CLASS}__injury__container`}>
                        {dashboardData?.riskFactorCodeCounts.map((category, index) => {
                            return (
                                <div
                                    className={`${COMPONENT_CLASS}__injury__item__wrapper`}
                                    key={`category-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__injury__item`}>
                                        {category.count}
                                    </div>
                                    <h6>{Utils.getHealthRiskName(category.name)} Risk</h6>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            { // if
                dashboardData?.focusAreas != null &&
                dashboardData.focusAreas.length >= 1 &&
                <>
                    <hr className="line" />
                    <div className={`${COMPONENT_CLASS}__focuses`}>
                        <h3 style={{ marginBottom: "30px" }}>Focus Breakdown</h3>
                        <p className="opacity-60 mb-8 font-light text-sm">Focus areas identified by Symmio to provide the best opportunity to successfully improve your functional health and wellness. Only two areas of focus are identified at a time. Focuses are evaluated and reprioritized based on reoccurring testing within the app experience.</p>
                        <div className={`${COMPONENT_CLASS}__focuses__wrapper`}>
                            {dashboardData.focusAreas.map((area, index) => {
                                return (
                                    <div
                                        className={`${COMPONENT_CLASS}__focuses__item`}
                                        key={index}>
                                        <span>{area.count}</span> {area.name}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            }

            <Modal
                isOpen={showWelcomeModal}
                isLoading={false}
                defaultCenterModal={true}
                onCancel={() => setShowWelcomeModal(false)}
                onSubmit={() => setShowWelcomeModal(false)}
                cancelButtonText="Skip tour"
                submitButtonText="Get Started"

            >
                <div className={`${COMPONENT_CLASS}__welcome-modal-content`}>
                    <div className={`${COMPONENT_CLASS}__welcome-image-container`}>
                        <img src={welcomeImage} alt="Welcome" />
                    </div>
                    <h3>Welcome to Symmio</h3>
                    <p>
                        Let’s start with a quick product tour and we’ll have you running in no time!
                    </p>
                </div>
            </Modal >

            {subscriptionStatusModalParam && statusModalContent[subscriptionStatusModalParam] &&
                <Modal
                    isOpen={showSubscriptionStatusModal}
                    isLoading={false}
                    onClose={setShowSubscriptionStatusModal}
                    defaultCenterModal={true}
                    submitButtonText="Continue"
                    onSubmit={() => setShowSubscriptionStatusModal(false)}
                    title={statusModalContent[subscriptionStatusModalParam].title}
                >
                    <div>
                        <p className="whitespace-pre-line">
                            <strong>{statusModalContent[subscriptionStatusModalParam].boldText}</strong>
                            <br />
                            {statusModalContent[subscriptionStatusModalParam].mainText}
                        </p>
                    </div>
                </Modal >
            }
        </div >
    );
}

export default Dashboard;