/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import UserService from '../../utilities/services/user-service';
import { Modal } from '../modal/modal';
import { User } from "../../models/interfaces/user";
import { Organization } from '../../models/interfaces/organization';
import { SymmioAccessType } from '../../models/enumerations/symmio-access-type';
import PortalProgressBar from '../portal-progress-bar/portal-progress-bar';
import { UserStatus } from '../../models/enumerations/user-status';
import { ReactComponent as UserCircleIcon } from "../../assets/icons/solid/icon_default-profile.svg";
import { OrganizationSubscription } from '../../models/interfaces/organization-subscription';
import AddOnRemovalsService from '../../utilities/services/add-on-removal-service';
import { AddOns } from '../../models/enumerations/add-ons';
import moment from 'moment';
import { AddOnRemoval } from '../../models/interfaces/add-on-removal';
import OrganizationSubscriptionService from '../../utilities/services/organization-subscription-service';
import { Loader } from '../loader/loader';
import { CheckboxTypeInput } from '../forms/checkbox-type-input';
import { useForm } from 'react-hook-form';

const COMPONENT_CLASS = "manage-users-modal";

interface LicenseRemovalModalProps {
    openLicenseRemoval: boolean;
    setOpenLicenseRemoval: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    organization: Organization;
    hideClose?: boolean;
    deactivationAmount?: number;
    removeAtFutureDate: boolean;
    organizationSubscription?: OrganizationSubscription;
    handleAddOnRemove?: Function;
    addOnAmountToBeRemoved?: number;
    setAddOnRemovedModalOpen?: Function;
    setRemovedAddOnInfo?: Function;
    onSubmit?: Function;
}

const LicenseRemovalModal: React.FC<LicenseRemovalModalProps> = (props) => {
    const {
        openLicenseRemoval,
        setOpenLicenseRemoval,
        isLoading,
        organization,
        hideClose,
        deactivationAmount,
        removeAtFutureDate,
        organizationSubscription,
        handleAddOnRemove,
        addOnAmountToBeRemoved,
        setAddOnRemovedModalOpen,
        setRemovedAddOnInfo,
        onSubmit,
    } = props;

    const { state } = useAuthState();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchedUsers, setSearchedUsers] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [userIdsToRemoveLicense, setUserIdsToRemoveLicense] = useState<string[]>([]);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

    const {
        register,
    } = useForm<any>();

    // Modal is closed - reset everything
    useEffect(() => {
        setUserIdsToRemoveLicense([]);
    }, [openLicenseRemoval]);

    useEffect(() => {
        const fetchUsers = async () => {
            if (state.user?.organizationId) {
                const allUsers = await UserService.getAllByOrganizationId(state.user.organizationId);
                let filteredUsers = allUsers.filter((user) => user.id !== organization.accountHolderId && user.symmioAccess === SymmioAccessType.AppLicense);
                // Check if addOnRemovals exists & contains users
                if (organizationSubscription) {
                    await AddOnRemovalsService.getBy([{
                        field: "organizationSubscriptionId",
                        operator: "==",
                        value: organizationSubscription.id,
                    },
                    {
                        field: "removalCompleted",
                        operator: "==",
                        value: false,
                    },
                    {
                        field: "addOn",
                        operator: "==",
                        value: AddOns.License,
                    }]).then((item: any) => {
                        if (item[0] && item[0].userIds && item[0].userIds.length > 0) {
                            filteredUsers = filteredUsers.filter((val) => !item[0].userIds!.includes(val.id!))
                        }
                    })

                }

                setUsers(filteredUsers);
                setSearchedUsers(filteredUsers);
            }
        }

        fetchUsers();
    }, [state.user?.organizationId]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (!value) {
            setSearchedUsers(users);
        }
        else {
            const foundUsers = users.filter((user) =>
                user.email?.includes(value) ||
                user.firstName?.includes(value) ||
                user.lastName?.includes(value));

            setSearchedUsers(foundUsers);
        }
    }
    console.log("yo", userIdsToRemoveLicense)

    const handleRowClick = (userIdFromRow: string | undefined) => {
        if (userIdFromRow) {
            if (userIdsToRemoveLicense.includes(userIdFromRow)) {
                setUserIdsToRemoveLicense(userIdsToRemoveLicense.filter(userId => userId !== userIdFromRow));
            }
            else {
                setUserIdsToRemoveLicense([...userIdsToRemoveLicense, userIdFromRow]);
            }
        }
    };
    const handleRemoveSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (!checked && searchedUsers.length === userIdsToRemoveLicense.length) {
            setIsAllChecked(false);
        }
    }
    const selectAllUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setIsAllChecked(checked);
        if (checked) {
            let userIds: string[] = [];

            searchedUsers.forEach((user) => {
                if (user.id && user.id !== organization.accountHolderId && user.symmioAccess === SymmioAccessType.AppLicense) {
                    userIds.push(user.id);
                }
            });

            setUserIdsToRemoveLicense(userIds);
        }
        else {
            setUserIdsToRemoveLicense([]);
        }
    }

    const handleFutureLicenseDeactivation = async () => {
        setLoading(true);
        if (!organizationSubscription || !handleAddOnRemove || !addOnAmountToBeRemoved) {
            return;
        }
        // TODO: I have OrgSub prop set to ? just to satisfy the error in reactivate-add-on-content. We should make it mandatory @Egor
        const sub = await stripe.subscriptions.retrieve(organizationSubscription.stripeAddOnSubscription);

        // Check if AddOnRemoval exists
        // If removalCompleted false, then this is an active addOnRemoval
        const existingAddOnRemoval = await AddOnRemovalsService.getBy([{
            field: "organizationSubscriptionId",
            operator: "==",
            value: organizationSubscription.id,
        },
        {
            field: "removalCompleted",
            operator: "==",
            value: false,
        },
        {
            field: "addOn",
            operator: "==",
            value: AddOns.License,
        }])

        if (existingAddOnRemoval && existingAddOnRemoval.length > 0) {
            // update the existing addOnRemoval
            existingAddOnRemoval[0].userIds = [...existingAddOnRemoval[0].userIds!, ...userIdsToRemoveLicense]

            await AddOnRemovalsService.save(existingAddOnRemoval[0], state.user!);

        }
        else {

            // Create the future removal object
            const addOnRemoval = {
                addOn: AddOns.License,
                removalCompleted: false,
                userIds: userIdsToRemoveLicense,
                billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).toISOString(),
                organizationSubscriptionId: organizationSubscription.id
            } as AddOnRemoval

            // Save the future removal object
            await AddOnRemovalsService.add(addOnRemoval, state.user!);
        }

        // Check if addOnsToBeRemoved exists and has "Licenses"
        if (organizationSubscription.addOnsToBeRemoved && organizationSubscription.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)) {
            const oldValue = organizationSubscription.addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)
            const index = organizationSubscription.addOnsToBeRemoved.findIndex((item: any) => item.name === AddOns.License)
            const newValue = {
                name: oldValue?.name,
                amountToBeRemoved: Number(addOnAmountToBeRemoved) + Number(oldValue?.amountToBeRemoved!),
                amountManaged: userIdsToRemoveLicense.length + oldValue?.amountManaged!,
            }

            // setRemovedAddOnInfo prop must be set if you are handling future deactivation
            if (setRemovedAddOnInfo) {
                setRemovedAddOnInfo({
                    name: oldValue?.name,
                    amountToBeRemoved: Number(addOnAmountToBeRemoved),
                    amountManaged: userIdsToRemoveLicense.length,
                    billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).format('MMMM D Y')
                })
            }
            organizationSubscription.addOnsToBeRemoved[index] = newValue;

            await OrganizationSubscriptionService.save(organizationSubscription);

        }
        else {
            // Create the add-ons-to-be-removed object 
            const addOnsToBeRemoved = [
                {
                    name: AddOns.License,
                    amountToBeRemoved: addOnAmountToBeRemoved,
                    amountManaged: userIdsToRemoveLicense.length,
                }
            ];

            // setRemovedAddOnInfo prop must be set if you are handling future deactivation
            if (setRemovedAddOnInfo) {
                setRemovedAddOnInfo({
                    name: AddOns.License,
                    amountToBeRemoved: Number(addOnAmountToBeRemoved),
                    amountManaged: userIdsToRemoveLicense.length,
                    billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).format('MMMM D Y')
                })
            }

            organizationSubscription.addOnsToBeRemoved ? organizationSubscription.addOnsToBeRemoved.push(addOnsToBeRemoved[0]) : organizationSubscription.addOnsToBeRemoved = addOnsToBeRemoved;
            await OrganizationSubscriptionService.save(organizationSubscription);
        }
        handleAddOnRemove().then(() => {
            setOpenLicenseRemoval(false);
            // This must be passed as a prop if you are using future deactivation
            if (setAddOnRemovedModalOpen) {
                setAddOnRemovedModalOpen(true);
            }
            setLoading(false);
        });
    }

    const handleLicenseRemoval = async () => {
        await UserService.removeLicenses(userIdsToRemoveLicense, state.user);
        onSubmit && onSubmit();
    }

    useEffect(() => {
        if (organization?.mySymmioLicenseCount &&
            organization?.mySymmioLicense &&
            userIdsToRemoveLicense.length >= (deactivationAmount ? deactivationAmount : (organization.mySymmioLicenseCount - organization.mySymmioLicense))) {
            setIsSubmitButtonDisabled(false);
        } else {
            setIsSubmitButtonDisabled(true);
        }
    }, [organization.mySymmioLicense, organization.mySymmioLicenseCount, userIdsToRemoveLicense]);

    return (
        <Modal
            isOpen={openLicenseRemoval}
            isLoading={isLoading}
            onClose={hideClose ? undefined : setOpenLicenseRemoval}
            title="Manage Account"
            defaultModalActions={true}
            onSubmit={async () => {
                if (removeAtFutureDate) {
                    handleFutureLicenseDeactivation()
                }
                else {
                    handleLicenseRemoval();
                }

            }}
            onCancel={!hideClose ? () => setOpenLicenseRemoval(false) : undefined}
            submitButtonText="Remove Licenses and Continue"
            submitDisabled={isSubmitButtonDisabled}>
            <Loader isVisible={loading} />
            <div className={COMPONENT_CLASS}>
                {organization.mySymmioLicense && organization.mySymmioLicenseCount &&
                    <div className="my-6">
                        <PortalProgressBar total={organization.mySymmioLicense} currentProgress={deactivationAmount ? (deactivationAmount + organization.mySymmioLicense) : organization.mySymmioLicenseCount} headerText={`${deactivationAmount ? (deactivationAmount + organization.mySymmioLicense) : organization.mySymmioLicenseCount} of ${organization.mySymmioLicense} Wellness Programs Used`} />
                    </div>
                }

                {organization.mySymmioLicenseCount && organization.mySymmioLicense &&
                    <h2>Remove at least {deactivationAmount ? deactivationAmount : (organization.mySymmioLicenseCount - organization.mySymmioLicense)} licenses:</h2>
                }

                <input className='my-6' type='search' onChange={handleSearch} placeholder='Search' />

                <div className={`${COMPONENT_CLASS}__user-list`}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th><span>NAME</span></th>
                                <th><span>GROUP</span></th>
                                <th><span>STATUS</span></th>
                                <th>
                                    <label>
                                        <div>
                                            <CheckboxTypeInput
                                                id="selectAllUsers"
                                                registerHook={register}
                                                registerOptions={{
                                                    onChange: selectAllUsers,
                                                }}
                                                checked={isAllChecked} />
                                        </div>
                                        REMOVE LICENSE
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedUsers.map((user) => {
                                return (
                                    <tr key={user.id} className={user.symmioAccess !== SymmioAccessType.AppLicense ? "deactivated" : ""}
                                        onClick={() => {
                                            if (user.id !== organization.accountHolderId && user.symmioAccess === SymmioAccessType.AppLicense) {
                                                handleRowClick(user.id)
                                            }
                                        }}>
                                        <td className={`${COMPONENT_CLASS}__user-photo`}>
                                            {user.profileImage && (
                                                <img
                                                    src={user.profileImage}
                                                    alt={`${user.firstName} ${user.lastName}`}
                                                />
                                            )}
                                            {!user.profileImage && (
                                                <UserCircleIcon aria-hidden="true" />
                                            )}
                                        </td>

                                        <td>
                                            {user.firstName != null && user.lastName != null && (
                                                <span>
                                                    <span className="font-bold">{`${user.firstName} ${user.lastName}`}</span>
                                                    <br />
                                                    <span className="email">{user.email}</span>
                                                </span>
                                            )}

                                            {(user.firstName == null || user.lastName == null) && (
                                                <span>{user.email}</span>
                                            )}
                                        </td>

                                        <td>{user.groupName || "No Group"}</td>

                                        <td>{UserStatus[user.status]}</td>

                                        <td>
                                            {user.symmioAccess !== SymmioAccessType.AppLicense
                                                ?
                                                (<div>
                                                    <CheckboxTypeInput
                                                        id={"user-id"}
                                                        registerHook={register}
                                                        registerOptions={{
                                                            disabled: true,
                                                        }}
                                                    /></div>)
                                                :
                                                (user.id !== organization.accountHolderId
                                                    ?
                                                    <div className='flex flex-row items-center gap-2'>
                                                        <div>
                                                            <CheckboxTypeInput
                                                                id={"user-id"}
                                                                registerHook={register}
                                                                registerOptions={{
                                                                    onChange: handleRemoveSelectAll
                                                                }}
                                                                checked={user.id && userIdsToRemoveLicense.length > 0 ? userIdsToRemoveLicense.includes(user.id) : false} />
                                                        </div>
                                                        Remove License
                                                    </div>
                                                    :
                                                    <div><strong>Account Holder</strong></div>
                                                )

                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='mt-16 mb-6 text-right'><strong>{userIdsToRemoveLicense.length} licenses</strong> selected to be removed.</div>
            </div>
        </Modal>
    );
};

export default LicenseRemovalModal;