/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGlobalState } from "../../utilities/hooks/use-global-state";
import { CategoryIds } from "../../models/enumerations/category-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { Question } from "../../models/interfaces/questions/question";
import { CalculateMovementScore } from "../../models/interfaces/scores/calculate-movement-score";
import { MovementScore } from "../../models/interfaces/scores/movement-score";
import { MskScore } from "../../models/interfaces/scores/msk-score";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import CalculateMovementService from "../../utilities/services/calculate/calculate-movement-service";
import UserUtilAssessments from "../../utilities/user-util-assessments";
import LoaderAssessments from "../loader-assessments/loader-assessments";
import ScreenSlide from "../screen-slide/screen-slide";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import { User } from "../../models/interfaces/user";
import LinkLifestyleScreen from "../link-lifestyle-screen/link-lifestyle-screen";

const COMPONENT_CLASS = "c-movement-screen";

export interface LinkMovementScreenProps {
    screenSettings: AssessmentSettings;
    setScreenSettings: React.Dispatch<AssessmentSettings>;
    settingsOnBack?: Function;
    user: User;
}

const LinkMovementScreen: React.FC<LinkMovementScreenProps> = (props) => {
    const { screenSettings, setScreenSettings } = props;
    const { globalState, setGlobalError } = useGlobalState();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [movementScore, setMovementScore] = useState<
        MovementScore | undefined | null
    >(undefined);
    const [errors, setErrors] = useState<string[]>([]);
    // const setLastActivityCompletedDate = useStoreAssessments(
    //     (state) => state.setLastActivityCompleted
    // );
    const [isCompleted, setIsCompleted] = useState(false);
    const [settings, setSettings] = useState<AssessmentSettings>({
        completedGroupIds: [],
        currentSlideIndex: 0,
        currentGroupIndex: 0,
        questionResponses: [],
        slideGroups: [],
    });
    const [mskScore, setMskScore] = useState<MskScore>();
    const hasSubmitButtton =
        AssessmentUtils.isLastGroup(screenSettings) &&
        AssessmentUtils.isLastSlide(screenSettings);


    useEffect(() => {
        AssessmentUtils.fetchQuestions(
            screenSettings,
            CategoryIds.MOVEMENT
        ).then((settings) => {
            setScreenSettings(settings);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasSubmitButtton) {
            submit();
        }
    }, [hasSubmitButtton])

    const getNextPage = async () => {
        setErrors([]);
        setScreenSettings(await AssessmentUtils.moveToNextPage(screenSettings));
    };

    const recordAnswer = async (selectedAnswer: string, question: Question) => {
        let settings = AssessmentUtils.recordAnswer(
            screenSettings,
            question,
            selectedAnswer
        );
        if (errors.length !== 0) {
            setErrors([]);
        }

        if (
            slide?.slideType === SlideType.Question &&
            slide.questions != null
        ) {
            settings = await AssessmentUtils.moveToNextPage(settings);
        }
        setScreenSettings(settings);
    };

    const submit = async (attemptIndex: number = 0) => {
        setIsLoading(true);
        // const queryParams = new URLSearchParams(window.location.search);

        const tempUserInfo = await UserUtilAssessments.getUserInfoForLinks(props.user);

        if (!tempUserInfo) {
            return;
        }
        const calcScore: CalculateMovementScore = {
            userInfo: tempUserInfo,
            movementResponses: AssessmentUtils.getAllResponses(screenSettings),
        };

        try {
            await CalculateMovementService.score(calcScore).then((a) => {
                if (a == null) {
                    setErrors(["Movement MSK Score could not be calculated"]);
                    setIsLoading(false);
                    return;
                }
                setMskScore(a)
                setMovementScore(a.movementScore);
                setErrors(a.errors);
                // save results

                // Removing until necessary
                // setLastActivityCompletedDate(
                //     new Date(),
                //     ActivityType.MovementAssessment
                // );

                setIsCompleted(true);
            });
        } catch (err) {
            console.log("err", err);
            if (attemptIndex === 0) {
                setGlobalError("Movement: Calculate", err);
            }
            const retryMs: number[] = [500, 1000, 1500, 3000, 5000];
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return submit(attemptIndex + 1);
                }, retryMs[attemptIndex]);
                return;
            }
        }
    };

    // /**
    //  * Record Scores
    //  * @returns True if succeeeded, false if there was an error
    //  */
    // const recordResults = async (newMskScore: MskScore): Promise<boolean> => {
    //     if (
    //         newMskScore.movementScore == null
    //     ) {
    //         return true;
    //     }


    //     const newErrors: GlobalStateError[] = [];

    //     try {
    //         await MskScoreServiceAssessments.addOrUpdateRecentById(
    //             newMskScore,
    //             userId
    //         ).then(() => {
    //             console.log("inside success mskscoreserviceassessments.addOrUpdateRecentById");
    //             if (userId) {
    //                 MskScoreUtil.getLatest(userId).then((response) => {
    //                     if (response?.lifestyleScore?.percentage != null &&
    //                         response.movementScore?.percentage != null) {
    //                         setHasMskScore(true);
    //                     }
    //                 });
    //             }
    //         });
    //     } catch (err) {
    //         console.log("error mskscoreserviceassessments.addOrUpdateRecentById");
    //         newErrors.push(
    //             GlobalStateUtil.createError("Movement MSK was not saved", err)
    //         );
    //     }
    //     try {
    //         console.log("awaiting MovementScoreService.addOrUpdateRecentById");
    //         await MovementScoreService.addOrUpdateRecentById(
    //             newMskScore.movementScore!,
    //             state.user.id
    //         );
    //     } catch (err) {
    //         console.log("error MovementScoreService.addOrUpdateRecentById");
    //         newErrors.push(
    //             GlobalStateUtil.createError("Movement score was not saved", err)
    //         );
    //     }
    //     try {
    //         console.log("awaiting CorrectiveExerciseUtils.handleNewMovementScoreById");
    //         await CorrectiveExerciseUtils.handleNewMovementScoreById(
    //             newMskScore.movementScore,
    //             state.user.id
    //         );
    //     } catch (err) {
    //         console.log("error CorrectiveExerciseUtils.handleNewMovementScoreById");
    //         newErrors.push(
    //             GlobalStateUtil.createError(
    //                 "Movement score could not be handled",
    //                 err
    //             )
    //         );
    //     }
    //     try {
    //         console.log("awaiting UserUtilAssessments.handleNewMskScore");
    //         await UserUtilAssessments.handleNewMskScore(userId, {
    //             mskScore: newMskScore,
    //             wasMovementRetake: true,
    //         });
    //     } catch (err) {
    //         console.log("error UserUtilAssessments.handleNewMskScore");
    //         newErrors.push(
    //             GlobalStateUtil.createError(
    //                 "Movement retake state wasn't updated",
    //                 err
    //             )
    //         );
    //     }
    //     try {
    //         console.log("awaiting UserMskFocusArticlesUtil.handleFocusChanges");
    //         await UserMskFocusArticlesUtil.handleFocusChanges(
    //             globalState.currentIdentity!.id!,
    //             newMskScore
    //         );
    //     } catch (err) {
    //         console.log("error UserMskFocusArticlesUtil.handleFocusChanges");
    //         newErrors.push(
    //             GlobalStateUtil.createError(
    //                 "Movement focus wasn't updated",
    //                 err
    //             )
    //         );
    //     }

    //     if (newErrors.length >= 1) {
    //         console.log("errors >= 1");
    //         // set errors
    //         setErrors(newErrors.map((x) => x.description));

    //         // update global state with errors
    //         setGlobalErrors(...newErrors);
    //         return false;
    //     }
    //     console.log("the end of recordResults");
    //     return true;
    // };

    const groups = AssessmentUtils.getAssessmentGroups(screenSettings);
    const currentGroup =
        screenSettings?.slideGroups == null
            ? null
            : screenSettings.slideGroups[screenSettings.currentGroupIndex];
    const slide = AssessmentUtils.getSlide(screenSettings);
    const actionsModifier =
        slide?.slideType === SlideType.Video ? "-video" : "";

    let hasContinueButton =
        AssessmentUtils.hasData(screenSettings) &&
        movementScore == null &&
        (!AssessmentUtils.isLastGroup(screenSettings) ||
            !AssessmentUtils.isLastSlide(screenSettings));

    if (
        hasContinueButton &&
        slide &&
        slide?.slideType === SlideType.Question &&
        slide.questions != null
    ) {
        hasContinueButton = false;
    }

    let continueButtonText = "Continue";
    if (hasContinueButton && slide != null) {
        switch (slide.slideType) {
            case SlideType.AssessmentIntro:
            case SlideType.Tests:
                continueButtonText = "Let's Do It";
                break;
            case SlideType.Tips:
                continueButtonText = "Got It";
                break;
            case SlideType.GroupIntro:
                continueButtonText = "Start";
                break;
            case SlideType.Video:
                if (slide.video) {
                    continueButtonText = "Enter Results";
                }
                break;
        }
    }

    const noProgressBarSlideTypes = [
        SlideType.AssessmentIntro,
        SlideType.Complete,
        SlideType.GroupIntro,
        SlideType.Tests,
        SlideType.Tips,
    ];
    const hasProgressBar =
        slide == null ||
        noProgressBarSlideTypes.indexOf(slide.slideType) === -1;
    const questionGroupNames = AssessmentUtils.getAssessmentGroups(
        screenSettings
    ).map((group) => group.groupName!);

    // const handleGenerateReport = async (emailResults: boolean) => {
    //     if (!globalState.currentIdentity?.id) return;

    //     // we need to get the user from the firebase in case their organization
    //     // was switched (in the portal) and no longer matches the data in the app.
    //     const userInFirebase = await UserService.get(globalState.currentIdentity?.id);

    //     if (!userInFirebase || !userInFirebase.organizationId) return;

    //     const latestOrgId = userInFirebase.organizationId;

    //     //setIsLoading(true);

    //     const date = DateTime.now().toISODate();
    //     const report: Report = {
    //         dateEnd: date,
    //         dateStart: date,
    //         emailResults: emailResults,
    //         organizationId: latestOrgId,
    //         status: "scheduled",
    //         type: ReportType.Assessment,
    //         userId: globalState.currentIdentity!.id!,
    //     };

    //     const identity = globalState.currentIdentity;

    //     if (identity.organizationId !== latestOrgId) {
    //         identity.organizationId = latestOrgId;
    //     }

    //     const scheduledReport = await ReportService.save(
    //         report,
    //         identity
    //     );

    //     if (!scheduledReport.id) {
    //         // setIsLoading(false);
    //         // setReportError(
    //         //     "Report was not saved successfully. Please try again."
    //         // );
    //         return;
    //     }

    //     if (scheduledReport.emailResults === true) {
    //         // setShowReportEmailConfirmation(true);
    //         // setIsLoading(false);
    //         return;
    //     }

    //     // const reportUnsubscribe = ReportService.getSnapshot(
    //     //     scheduledReport.id,
    //     //     (r: Report) => {
    //     //         if (r.status === "complete" && r.url) {
    //     //             setIsLoading(false);
    //     //             reportUnsubscribe();

    //     //             //Loads in the System browser
    //     //             window.open(r.url, "_self");
    //     //         }
    //     //         if (r.status === "error") {
    //     //             setReportError(r.errorMessage);
    //     //             setIsLoading(false);
    //     //             reportUnsubscribe();
    //     //         }
    //     //     }
    //     // );
    // };

    const onBackButtonClick = () => {
        if (AssessmentUtils.isFirstSlideAndFirstGroup(settings)) {
            setIsCompleted(false);
        }

        setSettings(AssessmentUtils.moveToPreviousPage(settings));
    };

    return (
        <>
            {
                // if
                isCompleted && mskScore && (
                    <LinkLifestyleScreen
                        screenSettings={settings}
                        setScreenSettings={setSettings}
                        settingsOnBack={onBackButtonClick}
                        user={props.user}
                        mskScore={mskScore}
                    />
                )
            }
            {
                // if
                !isCompleted && (
                    <BaseCardLayout>
                        <div className={`${COMPONENT_CLASS} -movement`}>
                            <LoaderAssessments isLoading={isLoading} loaderText="Loading Your Assessment. Please wait..." />
                            {
                                // if
                                !isLoading && currentGroup != null && (
                                    <div
                                        className={`${COMPONENT_CLASS}__wrapper ${slide?.slideType === SlideType.GroupIntro
                                            ? "-intro"
                                            : ""
                                            }`}>

                                        {
                                            // if
                                            AssessmentUtils.hasData(screenSettings) &&
                                            movementScore == null && (
                                                <div
                                                    className={`${COMPONENT_CLASS}__content ${hasContinueButton ||
                                                        hasSubmitButtton
                                                        ? "has-button"
                                                        : ""
                                                        }`}>
                                                    {
                                                        // if
                                                        slide != null && (
                                                            <ScreenSlide
                                                                isLinkAccountCreation={true}
                                                                questionGroupNames={
                                                                    questionGroupNames
                                                                }
                                                                recordedAnswers={AssessmentUtils.getAllResponses(
                                                                    screenSettings
                                                                )}
                                                                recordAnswer={recordAnswer}
                                                                page={slide}
                                                                errors={errors}
                                                                totalProgress={
                                                                    groups.length
                                                                }
                                                                currentProgress={
                                                                    groups.indexOf(
                                                                        currentGroup
                                                                    ) + 1
                                                                }
                                                                hasProgressBar={hasProgressBar}
                                                                screenSettings={screenSettings}
                                                                settingsOnBack={props.settingsOnBack}
                                                                isActionsWithContinue={hasContinueButton}
                                                                skipButton={slide?.questions &&
                                                                    slide.questions.length >
                                                                    0 &&
                                                                    slide.questions[0]
                                                                        .optional === true}
                                                                onSkip={getNextPage}
                                                                onBack={props.settingsOnBack}
                                                                onSubmit={getNextPage}
                                                                submitButtonText={continueButtonText}
                                                            />
                                                        )
                                                    }

                                                    <div
                                                        className={`${COMPONENT_CLASS}__actions ${actionsModifier} ion-margin-horizontal`}>
                                                        {/* {
                                            // if
                                            hasContinueButton && (
                                                <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={getNextPage} submitButtonText={continueButtonText}></BaseCardLayoutActions>
                                            )
                                        } */}
                                                        {/* {
                                                            // if
                                                            hasSubmitButtton && (
                                                                <div className={`c-screen-slide__actions`}>
                                                                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={submit} submitButtonText={errors.length === 0
                                                                        ? "Submit Results"
                                                                        : "Resubmit"}></BaseCardLayoutActions>
                                                                </div>
                                                                // <IonButton
                                                                //     color="primary"
                                                                //     expand="block"
                                                                //     onClick={() =>
                                                                //         submit()
                                                                //     }>
                                                                //     {errors.length === 0
                                                                //         ? "Submit Results"
                                                                //         : "Resubmit"}
                                                                // </IonButton>
                                                            )
                                                        } */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </BaseCardLayout>
                )
            }
        </>
    );
};

export default LinkMovementScreen;
