/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const COMPONENT_CLASS = "c-input-option";

export interface DecimalInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
    label?: string;
    maxValue?: number;
}

const DecimalInputOption: React.FC<DecimalInputOptionProps> = (props) => {
    const [userAnswer, setUserAnswer] = useState(props.answer ?? "");

    useEffect(() => {
        let input = parseFloat(userAnswer);
        if (isNaN(input) || input <= 0) {
            props.onChange("");
            return;
        }
        input = parseFloat(input.toFixed(2));
        props.onChange(input.toString());
    }, [userAnswer]);

    return (
        <div className={`${COMPONENT_CLASS} -weight`}>
            <div className={`${COMPONENT_CLASS}__decimal-container`}>
                <div className={`${COMPONENT_CLASS}__decimal-col`}>
                    <div className={`${COMPONENT_CLASS}__decimal-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus

                            onChange={(e: any) => {
                                if (e.target.value == null) {
                                    props.onChange("");
                                    return;
                                }
                                let answerStr = e.target.value.toString();
                                if (props.maxValue != null) {
                                    const answer = parseFloat(answerStr);
                                    if (answer > props.maxValue) {
                                        answerStr = answerStr.substring(
                                            0,
                                            answerStr.length - 1
                                        );
                                        setUserAnswer(answerStr);
                                        props.onChange(answerStr);
                                        return;
                                    }
                                }
                                setUserAnswer(answerStr);
                            }}
                            type="number"
                            value={userAnswer ?? "0"}>
                        </input>
                        <span className={`${COMPONENT_CLASS}__decimal-span`}>{props.label ?? ""}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DecimalInputOption;
