import { IonItem } from "@ionic/react";
import { useEffect, useState } from "react";
import { Button } from "../button/button";

const COMPONENT_CLASS = "c-timer";

export interface TimerProps {
    answer?: string;
    onChange: (newAnswer: string) => void;
}

enum TimerStatus {
    HasTime,
    NoTime,
    Redirecting,
    Running,
}

const Timer: React.FC<TimerProps> = (props) => {
    const [time, setTime] = useState(
        props.answer == null ? 0 : parseInt(props.answer)
    );
    const [status, setStatus] = useState(
        props.answer == null ? TimerStatus.NoTime : TimerStatus.HasTime
    );

    useEffect(() => {
        let interval: any = null;
        if (status === TimerStatus.Running) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [status]);

    const handleTimerPress = () => {
        if (status === TimerStatus.Running) {
            setStatus(TimerStatus.Redirecting);
            setTimeout(() => {
                props.onChange(time == null ? "" : time.toString());
            }, 1000);
            return;
        }

        setTime(0);
        setStatus(TimerStatus.Running);
    };

    const format = (time: number) => {
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var formattedTime = "";
        if (hrs > 0) {
            formattedTime += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        formattedTime += "" + mins + ":" + (secs < 10 ? "0" : "");
        formattedTime += "" + secs;
        return formattedTime;
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <span className={`${COMPONENT_CLASS}__meta`}>
                BREATHE OUT AND HOLD
            </span>
            <IonItem lines="none">
                <div className={`${COMPONENT_CLASS}__time`}>{format(time)}</div>
            </IonItem>
            {
                // if
                (status === TimerStatus.NoTime ||
                    status === TimerStatus.Running) && (
                    <div className="flex justify-center">
                        <Button
                            onClick={() => handleTimerPress()}
                            buttonType="submit"
                            inputClassName="action-button"
                            inputStyle={{ marginTop: "0px" }}
                            buttonText={status === TimerStatus.Running
                                ? "Stop Timer"
                                : "Start Timer"} />
                    </div>
                )
            }
            {
                // if
                status === TimerStatus.Redirecting && (
                    <div className="flex justify-center">
                        <Button
                            buttonType="submit"
                            inputClassName="action-button"
                            inputStyle={{ marginTop: "0px" }}
                            buttonText="Stop Timer" />
                    </div>
                )
            }
            {
                // if
                status === TimerStatus.HasTime && (
                    <>
                        <div className="flex justify-center">
                            <Button
                                buttonStyle="secondary"
                                buttonType="submit"
                                inputClassName="action-button"
                                onClick={() => handleTimerPress()}
                                buttonText="Retime Breathing"
                                inputStyle={{ marginBottom: "0.5rem", marginTop: "0px" }} />
                        </div>
                        <div className="flex justify-center">
                            <Button
                                onClick={() => props.onChange(time.toString())}
                                buttonType="submit"
                                inputClassName="action-button"
                                inputStyle={{ marginTop: "0px" }}
                                buttonText="Continue" />
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default Timer;
