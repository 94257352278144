import React, { useEffect, useState } from "react";
import InactivityTimer from "../../utilities/inactivity-timer";
import InactivityModal from "./inactivity-modal";
import { useAuthState } from "../../utilities/contexts/auth-state-context";

export interface InactivityProps {
    onRecentlyInactive: Function,
}
/**
 * Represents buttons on the Inactivity modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const Inactivity: React.FC<InactivityProps> = (props) => {
    const {
        onRecentlyInactive,
    } = props;
    const [openModal, setOpenModal] = useState(false);
    const { state } = useAuthState();
    const timeout = 2700;

    useEffect(() => {
        if (state.authenticated) {
            const timer = new InactivityTimer(
                timeout, //expire after 45 minutes
                () => {
                    // The onTimeout function
                    setOpenModal(state.authenticated);
                },
                () => {
                    // The onExpired function
                    setOpenModal(state.authenticated);
                },
            );

            return () => {
                timer.cleanUp();
            };
        }

    }, [state.authenticated, openModal]);

    const handleModalClose = async (buttonClicked: boolean) => {
        setOpenModal(false);
    }

    const handleRecentlyInactive = async () => {
        onRecentlyInactive();
    }

    if (!state.authenticated) {
        return (<></>);
    }

    return (
        <InactivityModal
            open={openModal}
            onClose={handleModalClose}
            onRecentlyInactive={handleRecentlyInactive} />
    );
};

export default Inactivity;