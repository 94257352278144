import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Report } from "../../models/interfaces/report";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "reportData";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Find the specific report by the id
 * @param {string} id - The Id of the report that we are retrieving
 * @returns {Promise<Report>} A promise for the Report we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Report>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<Report>(COLLECTION_NAME, conditions, order, limit);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ReportDataService = {
    get,
    getBy,
};

export default ReportDataService;

// #endregion Exports