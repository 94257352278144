import { VideoSize } from "../../../models/enumerations/video-size";
import { QuestionGroup } from "../../../models/interfaces/questions/question-group";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface LifestyleQuestionsQueryParams {
    groupId?: string | null;
    videoSize: VideoSize;
}

export interface LifestyleQuestionsPathParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/questions/lifestyle`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const LifestyleQuestionsService = {
    list: ServiceBase.list<QuestionGroup[], LifestyleQuestionsQueryParams>(
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default LifestyleQuestionsService;

// #endregion Exports
