import { useState } from 'react';
import { Modal } from '../../modal/modal';
import { Loader } from '../../loader/loader';
import { Button } from '../../button/button';
import PasswordUpdate from './password-update';
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import EditOrganization from './edit-organization';
import EditAccount from './edit-account';
import UserUtil from '../../../utilities/user-util';

interface AccountProps {
}
const COMPONENT_CLASS = "c-account";

const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [openOrganizationChange, setOpenOrganizationChange] = useState(false);
  const [openUserChange, setOpenUserChange] = useState(false);

  const { state } = useAuthState();
  const isAccountHolder = UserUtil.isAccountHolder(state.user);
  return (
    <div className={COMPONENT_CLASS}>
      <Loader isVisible={false} />
      <div className={`${COMPONENT_CLASS}__container`}>
        <h2>Account Information</h2>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className='body-bold'>Organization Name</p>
            <div className='body'>{state.organization.name}</div>
          </div>
          <div>
            <Button
              disabled={!isAccountHolder}
              onClick={() => { setOpenOrganizationChange(true) }} buttonText='Edit Organization' />
          </div>
        </div>
      </div>
      <div className={`${COMPONENT_CLASS}__container`}>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className="body-bold">Name</p>
            <div className='body'>{state.user?.firstName} {state.user?.lastName}</div>
          </div>
          <div>
            <p className="body-bold">Email</p>
            <div className='body'>{state.user?.email}</div>
          </div>
          <div>
            <Button onClick={() => { setOpenUserChange(true) }} buttonText='Edit Personal Info' />
          </div>
        </div>
      </div>
      <div className={`${COMPONENT_CLASS}__container`}>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className="body-bold">Current Password</p>
            <div className='body'>*************</div>
          </div>
          <div>
            <Button onClick={() => { setOpenPasswordChange(true) }} buttonText='Update Password' />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openPasswordChange}
        isLoading={false}
        onClose={() => { setOpenPasswordChange(false) }}
        title="Update Password">
        <PasswordUpdate onClose={() => setOpenPasswordChange(false)} />
      </Modal>
      <Modal
        isOpen={openOrganizationChange}
        isLoading={false}
        onClose={() => { setOpenOrganizationChange(false) }}
        title="Edit Organization">
        <EditOrganization onClose={() => setOpenOrganizationChange(false)} />
      </Modal>
      <Modal
        isOpen={openUserChange}
        isLoading={false}
        onClose={() => { setOpenUserChange(false) }}
        title="Edit Personal Info">
        <EditAccount onClose={() => setOpenUserChange(false)} />
      </Modal>
    </div >
  );
}
export default Account;
