/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import getFirebase from "../../utilities/firebase";
import { Button } from "../button/button";

export interface InactivityModalButtonsProps {
    onFirstButtonClick: Function,
    onSecondButtonClick: Function
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents buttons on the Inactivity modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityModalButtons: React.FC<InactivityModalButtonsProps> = (props) => {
    const {
        onFirstButtonClick,
        onSecondButtonClick
    } = props;

    const { auth } = getFirebase();

    const handleLogoutButtonClick = () => {
        auth.signOut();
        onFirstButtonClick();
    };

    const handleContinueButtonClick = () => {
        onSecondButtonClick();
    }

    let areaClass = `${COMPONENT_CLASS}__button-area -no-lower-message`;

    return (
        <div className={areaClass}>
            <a onClick={handleLogoutButtonClick} style={{
                marginRight: "39px",
                lineHeight: "47px",
                textDecorationLine: "underline",
                fontWeight: "700",
                cursor: "pointer"
            }}>Logout</a>
            <Button
                buttonStyle="primary"
                onClick={handleContinueButtonClick}
                buttonText="Continue Session" />
        </div >
    );
};

export default InactivityModalButtons;

/*<a onClick={handleLogoutButtonClick} style={{
                marginRight: "39px",
                lineHeight: "47px",
                textDecorationLine: "underline",
                fontWeight: "700"
            }}>Logout</a>*/