import React from 'react';
import { Redirect } from 'react-router';
import { ApplicationHeader } from '../../components/application-header/application-header';
import { Sidebar } from '../../components/sidebar/sidebar';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
// import AssessmentsHomeScreenPage from '../../pages/assessments-home-screen-page/assessments-home-screen-page';
import UserRoles from '../../models/user-roles';
import LeadCaptureResultsPage from '../../pages/lead-capture/lead-capture-results-page';
import SubscriptionLinkResultsPage from '../../pages/subscription-link/subscription-link-results-page';
import AssessmentHomePage from '../../pages/assessments-home-screen-page/assessment-home-page';

const COMPONENT_CLASS = "c-authenticated-layout";

const AuthenticatedLayout = ({ children }: any) => {

    const { state } = useAuthState();

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }

    if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return (
            <LeadCaptureResultsPage />
        );
    }

    if (state.user && state.user.subscriptionLinkId) {
        return <SubscriptionLinkResultsPage />
    }

    if (state.user?.roles && state.user?.roles.find((role) => role === UserRoles.CLIENT_ID)) {
        return (
            <AssessmentHomePage />
        );
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Sidebar />
            <div className={`${COMPONENT_CLASS}__window`}>
                <main className={`${COMPONENT_CLASS}__content`}>
                    <ApplicationHeader />
                    <div className={`${COMPONENT_CLASS}__container`}>
                        {React.createElement(children)}
                    </div>
                </main>
            </div>
        </div>
    );
}

export default AuthenticatedLayout;
