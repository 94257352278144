import { Score } from "../../models/interfaces/score";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as ActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import AssessmentBarChart from "../assessment-bar-chart/assessment-bar-chart";

const COMPONENT_CLASS = "c-user-profile-summary";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

export interface UserProfileCardFocusArea {
    image: string;
    text: string;
}

interface UserProfileCardProps {
    averageDescription?: string;
    averagePercent?: number;
    focusAreas: UserProfileCardFocusArea[];
    hasPain: boolean;
    score?: Score;
    title: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileCard: React.FC<UserProfileCardProps> = (props: UserProfileCardProps) => {
    if (props.score == null) {
        return null;
    }

    const getIcon = (type: string) => {
        switch (type) {
            case "Movement Health":
                return <MovementIcon className="icon" />
            case "NUTRITION":
                return <NutritionIcon className="icon" />
            case "BEHAVIORAL_HEALTH":
                return <BehavioralHealthIcon className="icon" />
            case "BODY_COMPOSITION":
                return <BodyCompIcon className="icon" />
            case "BREATHING":
                return <BreathingIcon className="icon" />
            case "PHYSICAL_ACTIVITY":
                return <ActivityIcon className="icon" />
            case "SLEEP":
                return <SleepIcon className="icon" />
            case "INJURY_HISTORY":
                return <InjuryHistoryIcon className="icon" />
        }
    }

    const resultStyle =
        props.score.resultLevel === "Pass" ? "-pass" :
        props.score.resultLevel === "Low" ? "-fail" :
        "";

    return (
        <div className={`${COMPONENT_CLASS} ${props.hasPain ? '-pain' : ''}`}>
            <div className={`${COMPONENT_CLASS}__title`}>
                <div className={`${COMPONENT_CLASS}__title__side`}>
                    <div className={`${COMPONENT_CLASS}__icon -${props.score.groupId.replace(/\s+/g, '-').toLowerCase()}`}>
                        {getIcon(props.score.groupId)}
                    </div>
                    <h4><span>{props.title}</span></h4>
                </div>
                <div className={`${COMPONENT_CLASS}__title__side`}>
                    <div className={`${COMPONENT_CLASS}__status ${resultStyle}`}>
                        {props.score.resultLevel}
                    </div>
                    <h5 className={`${resultStyle}`}>{props.score.percentage}</h5>
                </div>
            </div>
            <AssessmentBarChart percentage={props.score.percentage} />
            {/* <div className={`${COMPONENT_CLASS}__focus-areas`}>
                {props.focusAreas.length > 0 &&
                    props.focusAreas.map((focus, i) => (
                        <div
                            className={`${COMPONENT_CLASS}__focus-areas__focus`}
                            key={`focus-${i}`}>
                            <img src="https://via.placeholder.com/30" alt={focus.text} />
                            {focus.text}
                        </div>
                    ))
                }
            </div> */}
            <div className={`${COMPONENT_CLASS}__avg`}>
                {props.averageDescription}
            </div>
            { // if
                props.hasPain &&
                <div className={`${COMPONENT_CLASS}__pain`}>Pain</div>
            }
        </div>
    );
}

export default UserProfileCard;
