import {
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
} from "@ionic/react";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { AnswerUnits } from "../../models/enumerations/answer-units";
import { Question } from "../../models/interfaces/questions/question";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import { NumberUtils } from "../../utilities/number-utils";
import MultipleChoice from "../question-types/multiple-choice/multiple-choice";
import CustomOption from "../custom-input-option/custom-input-option";
import DateInputOption from "../date-input-option/date-input-option";
import InlineRadio from "../inline-radio-input-option/inline-radio-input-option";
import MinuteInputOption from "../minute-input-option/minute-input-option";
import SliderInputOption from "../slider-input-option/slider-input-option";
import Timer from "../timer/timer";
import TimeInputOption from "../time-input-option/time-input-option";
import DayInputOption from "../day-input-option/day-input-option";
import InchInputOption from "../inch-input-option/inch-input-option";
import WeightInputOption from "../weight-input-option/weight-input-option";
import HeightInputOption from "../height-input-option/height-input-option";
import DecimalInputOption from "../decimal-input-option/decimal-input-option";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { useState } from "react";

const COMPONENT_CLASS = "c-question-screen";

export interface ScreenQuestionProps {
    id?: string;
    question: Question;
    handleAnswerChange: (selected: string, question: Question) => void;
    hideHeader?: boolean;
    answer?: QuestionResponse;
    handleOptionalClick?: () => void;
    setupSubmit?: Function;
    settingsOnBack?: Function;
    useContinueAction?: boolean;
    isLifestyle?: boolean;
}

const ScreenQuestion: React.FC<ScreenQuestionProps> = (props) => {
    const answer = props.answer?.answerId ?? props.answer?.answerResponse;
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (newAnswer: string) => {
        if (newAnswer == null) {
            return;
        }
        props.handleAnswerChange(newAnswer, props.question);
    };

    const getCustomOptions = () => {
        return props.question.answerOptions?.map((answerOption, index) => (
            <CustomOption
                id={answerOption.id}
                answerOption={answerOption}
                key={index}
                onChange={handleChange}
                isSelected={props.answer?.answerId === answerOption.answerId}
                isLifestyleOption={props.isLifestyle}
            />
        ));
    }

    const getIntegerRangeOptions = () =>
        // Radio value must be a string to prevent dispatching multiple IonRadioGroup.onIonChange() errors
        NumberUtils.createRangeList(
            props.question.integerMin!,
            props.question.integerMax!
        )?.map((num: number, index) => (
            <InlineRadio
                onChange={handleChange}
                number={num.toString()}
                key={index}
                name={props.question.questionId}
                selected={answer}
            />
        ));

    const getDaysOptions = () =>
        // Radio value must be a string to prevent dispatching multiple IonRadioGroup.onIonChange() errors
        NumberUtils.createRangeList(
            props.question.integerMin!,
            props.question.integerMax!
        )?.map((num: number, index) => (
            <DayInputOption
                onChange={handleChange}
                number={num.toString()}
                key={index}
                selected={answer}
            />
        ));

    const getIntegerOptions = () => (
        <IonItem
            data-testid="integer-options"
            className={`${COMPONENT_CLASS}__integer-options`}
            lines="none">
            <IonInput
                value={answer}
                onIonChange={(e) =>
                    handleChange(
                        e.detail.value == null ? "" : e.detail.value!.toString()
                    )
                }
                type="number"
            />
        </IonItem>
    );

    const getDecimalOptions = () => (
        <DecimalInputOption answer={answer} onChange={handleChange} />
    );

    const getHour = () => (
        <DecimalInputOption
            answer={answer}
            onChange={handleChange}
            label="HRS"
            maxValue={20}
        />
    );

    const getTimeOptions = (period: "am" | "pm", defaultAnswer?: string) => (
        <TimeInputOption
            answer={defaultAnswer}
            onChange={handleChange}
            period={period}
        />
    );

    const getDateOptions = () => (
        <DateInputOption answer={answer} onChange={handleChange} />
    );
    const getMinuteOptions = () => (
        <MinuteInputOption
            answer={answer || ""}
            onChange={handleChange}
            maxValue={999}
        />
    );

    const getTimer = () => <Timer answer={answer} onChange={handleChange} />;

    const getSliderOptions = () => (
        <SliderInputOption answer={answer} onChange={handleChange} />
    );

    const handleModalClose = () => {
        setOpenModal(false);
    }
    const getMeasurements = () => (
        <InchInputOption
            answer={answer}
            onChange={handleChange}
            questionInfo={props.question.information}
            open={openModal}
            closeParent={handleModalClose}
        />
    );

    const getWeightOptions = () => (
        <WeightInputOption answer={answer} onChange={handleChange} />
    );

    const getHeightOptions = () => (
        <HeightInputOption answer={answer} onChange={handleChange} />
    );

    const getRadioComponent = () => {
        switch (props.question.answerFormat) {
            case AnswerFormats.DATE:
                return getDateOptions();
            case AnswerFormats.DECIMAL:
                return getDecimalComponent();
            case AnswerFormats.INTEGER:
                return getIntegerRadioComponent();
            case AnswerFormats.MULTIPLE_CHOICE:
                return (
                    <MultipleChoice
                        initialAnswer={answer}
                        question={props.question}
                        handleAnswerChange={
                            props.handleAnswerChange
                        }></MultipleChoice>
                );
            case AnswerFormats.OPTIONS:
                return getCustomOptions();
            case AnswerFormats.TIME:
                const period =
                    props.question.questionId === "SLEEP_PSQI_BED_TIME"
                        ? "pm"
                        : "am";
                let ans = answer;
                if (props.question.questionId === "SLEEP_PSQI_BED_TIME" && !props.answer?.answerResponse) {
                    props.handleAnswerChange("23:00", props.question);
                    ans = "23:00";
                }

                if (props.question.questionId === "SLEEP_PSQI_WAKE_TIME" && !props.answer?.answerResponse) {
                    props.handleAnswerChange("07:00", props.question);
                    ans = "07:00"
                }

                return getTimeOptions(period, ans);
            default:
                return <>QUESTION COMPONENT NOT FOUND</>;
        }
    };

    const getDecimalComponent = () => {
        // hour question
        if (props.question.answerUnits === AnswerUnits.HOURS) {
            return getHour();
        }

        // default decimal
        return getDecimalOptions();
    };

    const getIntegerRadioComponent = () => {
        // slider question
        const isPercent =
            props.question.integerMin === 0 &&
            props.question.integerMax === 100 &&
            props.question.answerUnits === AnswerUnits.PERCENTAGE;
        if (isPercent) {
            return getSliderOptions();
        }

        // list of integers
        const showIntegersAsOptions = AssessmentUtils.renderIntegerAsOptions(
            props.question
        );
        if (
            showIntegersAsOptions &&
            props.question.answerUnits !== AnswerUnits.DAYS
        ) {
            return (
                <div className={`${COMPONENT_CLASS}__integer-range-container`}>
                    {getIntegerRangeOptions()}
                </div>
            );
        }

        // days question
        if (
            showIntegersAsOptions &&
            props.question.answerUnits === AnswerUnits.DAYS
        ) {
            return (
                <div className={`${COMPONENT_CLASS}__integer-range-days-container`}>
                    {getDaysOptions()}
                </div>
            );
        }

        // hip and waist measurements (with popup) question
        if (
            props.question.answerUnits === AnswerUnits.INCHES &&
            props.question.information
        ) {
            return getMeasurements();
        }

        // weight question
        if (props.question.answerUnits === AnswerUnits.POUNDS) {
            return getWeightOptions();
        }

        // height question
        if (props.question.questionId === "BODY_COMPOSITION_HEIGHT") {
            return getHeightOptions();
        }

        // minutes question
        if (props.question.answerUnits === AnswerUnits.MINUTES) {
            return getMinuteOptions();
        }

        // timer question
        if (
            props.question.answerFormat === AnswerFormats.INTEGER &&
            props.question.answerUnits === AnswerUnits.SECONDS &&
            props.question.integerMin === 1
        ) {
            return getTimer();
        }

        // default integer
        return getIntegerOptions();
    };
    return (
        <>
            <div className={`${COMPONENT_CLASS} 
            ${(props.question.questionId === "birthdate" ||
                    props.question.questionId === "biologicalSex" ||
                    props.question.questionId === "BODY_COMPOSITION_WEIGHT" ||
                    props.question.questionId === "BODY_COMPOSITION_HEIGHT" ||
                    props.question.questionId === "BODY_COMPOSITION_WAIST" ||
                    props.question.questionId === "BODY_COMPOSITION_HIPS" ||
                    props.question.questionId === "INJURY_HISTORY_SANE_SCORE" ||
                    props.question.questionId === "SLEEP_PSQI_BED_TIME" ||
                    props.question.questionId === "SLEEP_PSQI_WAKE_TIME" ||
                    props.question.questionId === "SLEEP_PSQI_SLEEP_HOURS" ||
                    props.question.questionId === "BREATHING_BREATH" ||
                    props.question.questionId === "PHYSICAL_ACTIVITY_MODERATE" ||
                    props.question.questionId === "PHYSICAL_ACTIVITY_VIGOROUS" ||
                    props.question.questionId === "PHYSICAL_ACTIVITY_STRENGTH_NUM") ? 'question-screen-flex-column' : 'question-screen-flex-row'} 
            ${(props.question.questionId === "BALANCE_REACH_2_LEFT" || props.question.questionId === "BALANCE_REACH_25_LEFT") ? 'reach-left-question' : null} 
            ${(props.question.questionId === "BALANCE_REACH_2_RIGHT" || props.question.questionId === "BALANCE_REACH_25_RIGHT") ? 'reach-right-question' : null}`} data-testid="screen-question">
                {
                    // if
                    !props.hideHeader && (
                        <div className={`${COMPONENT_CLASS}__container ${(props.question.questionId === "BALANCE_REACH_2_LEFT" || props.question.questionId === "BALANCE_REACH_25_LEFT") ? 'reach-left-question-container' : null} 
                        ${(props.question.questionId === "BALANCE_REACH_2_RIGHT" || props.question.questionId === "BALANCE_REACH_25_RIGHT") ? 'reach-right-question-container' : null}`}>
                            {
                                // if
                                props.question.sectionTitle != null && (
                                    <IonListHeader>
                                        <IonLabel
                                            className={`${COMPONENT_CLASS}__section-title`}>
                                            {props.question.sectionTitle}
                                        </IonLabel>
                                    </IonListHeader>
                                )
                            }
                            <div className={`${COMPONENT_CLASS}__header-container`}>
                                <h4
                                    id={props.question.questionId}
                                    className={`${COMPONENT_CLASS}__header ${props.question.sectionTitle ? "-move" : ""
                                        }`}>
                                    {props.question.questionText}
                                </h4>
                                {
                                    // if
                                    props.question.answerUnits === AnswerUnits.INCHES &&
                                    props.question.information && (
                                        <button
                                            style={{
                                                width: '35px',
                                                backgroundColor: '#121622',
                                                height: '35px',
                                                borderRadius: '50%',
                                                color: 'white',
                                                marginRight: '20px',
                                            }}
                                            color="primary"
                                            onClick={() => {
                                                setOpenModal(true);
                                            }}
                                            className={`${COMPONENT_CLASS}__pop-button`}>
                                            ?
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    // if
                    props.question.fieldLabel != null && props.question.questionId !== "NUTRITION_CARBS" && (
                        <div>
                            <h4 className={`${COMPONENT_CLASS}__field-label ${(props.question.questionId === "BALANCE_REACH_2_LEFT" ||
                                props.question.questionId === "BALANCE_REACH_25_LEFT" ||
                                props.question.questionId === "BALANCE_REACH_2_RIGHT" ||
                                props.question.questionId === "BALANCE_REACH_25_RIGHT") ? 'reach-left-right-label' : null} `}>{props.question.fieldLabel}</h4>
                        </div>
                    )
                }
                {<div className={`${COMPONENT_CLASS}__custom-options`}>{getRadioComponent()}</div>}
                {/* <IonRadioGroup value={answer}></IonRadioGroup> */}
                {
                    // if
                    props.question.answerDetails != null && (
                        <div id="biological-sex-info-text" className={`${COMPONENT_CLASS}__disclaimer-text-container`}>
                            <div
                                className={`${COMPONENT_CLASS}__disclaimer-text-content`}>
                                {props.question.answerDetails}
                            </div>
                        </div>
                    )
                }

            </div>
            {/* {
                // if
                !props.setupSubmit && !(props.question.questionId === "BALANCE_REACH_2_LEFT" || props.question.questionId === "BALANCE_REACH_25_LEFT") && props.settingsOnBack !== undefined && (
                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack}></BaseCardLayoutActions>
                )
            } */}
            {
                // if
                props.question.questionId !== "biologicalSex" && props.setupSubmit !== undefined && (
                    <div className={`${COMPONENT_CLASS}__buttons`}>
                        <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.setupSubmit} submitButtonText="Continue"></BaseCardLayoutActions>
                    </div>
                )
            }
        </>
    );
};

export default ScreenQuestion;
