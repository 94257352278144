// @ts-ignore
import HubspotForm from "react-hubspot-form"

interface EnterpriseContactUsProps {
    title?: string;
    isEnterprise?: boolean;
}

const EnterpriseContactUs: React.FC<EnterpriseContactUsProps> = (props) => {
    const COMPONENT_CLASS = 'c-enterprise-contact';
    const params = new URLSearchParams(window.location.search);
    const downgrade = params.get("downgrade");
    const upgrade = params.get("upgrade");

    let hubspotFormId = 'cd8b2aaf-db62-487c-b27d-739274e00524';

    if (upgrade) {
        hubspotFormId = 'fa38ea91-97be-41fe-aff4-7f0542639d32';
    }
    if (props.isEnterprise || downgrade) {
        hubspotFormId = '6ee6f7e0-911d-48fc-9172-a81be4d8bea2';
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {props.title
                ?
                <h3 className={`c-subscription-dialog-box__header`} style={{ marginBottom: '1rem' }}>
                    {props.title}
                </h3>
                :
                <h3 className={`c-subscription-dialog-box__headert`} style={{ marginBottom: '1rem', fontSize: '2.25rem', fontWeight: '300', lineHeight: '1.5rem', textAlign: 'center' }}>
                    Upgrading to Enterprise
                </h3>
            }

            <div className={`${COMPONENT_CLASS}__form-container`}>
                <p>
                    Fill out your information and a Symmio representative will reach out to you shortly.
                </p>

                <div className={`${COMPONENT_CLASS}__form`}>
                    <HubspotForm
                        portalId='5419630'
                        formId={hubspotFormId}
                        loading={<div>Loading...</div>}
                    />
                </div>
            </div>
        </div>
    );
}

export default EnterpriseContactUs