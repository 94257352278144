import { UserMskFocusArticles } from "../models/interfaces/articles/user-msk-focus-articles";
import userMskArticlesService from "./services/article-service/user-msk-articles-service";
import ArticlesGroupService from "./services/article-service/articles-group-service";
import { ArticlesGroup } from "../models/interfaces/articles/articles-group";
import ArticleApiService from "./services/article-service/article-api-service";
import UserArticle from "../models/interfaces/articles/user-article";
import { UserMskFocusStatus } from "../models/interfaces/articles/user-msk-focus-status";
import UserMskFocusArticlesService from "./services/article-service/user-msk-articles-service";
import DateUtil from "./date-util";
import { Assessment } from "../models/interfaces/scores/assessment";
import UserUtil from "./user-util-assessments";
import { GroupIds } from "../models/enumerations/group-ids";
import UserService from "./services/user-service-assessments";
import { UserMskFocusStatusCompleted } from "../models/interfaces/articles/user-msk-focus-status-completed";
// import TodayService from "./services/today-service/today-service";
import { UserArticleCount } from "../models/interfaces/articles/user-article-count";
import MskScoreUtil from "./msk-score-util";
import { IUser } from "../models/interfaces/assessment-user";
import UserMskFocusArticlesUtil from "./user-msk-focus-articles-util";
import GroupUtil from "./group-util";
import GlobalStateUtil from "./global-state-util";
import { MskScore } from "../models/interfaces/scores/msk-score";
import DataAssessmentsUtil from "./data-assesments-util";
import Article from "../models/interfaces/articles/article";
import { FirebaseUtils } from "./firebase-utils";
import { ActivityType } from "../models/enumerations/activity-type";
import ArticleError from "./errors/article-error";
// import NotificationService from "../models/notification-service";

export default class ArticleUtils {
    public static EXPIRATION_HOURS: number = 24;

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns the next articles for the user
     * @param {string} userId - The user to get articles for
     * @returns {Promise<void>}
     */
    public static async finishArticle(
        userId: string,
        groupId: string,
        articleId: number
    ): Promise<void> {
        _log(`finishArticle() groupId=${groupId} articleId=${articleId}`);
        const userMskFocusArticle =
            await UserMskFocusArticlesUtil.getCurrentByUserId(userId).catch(
                (error) => {
                    throw ArticleUtils.logAndCreateError(
                        error,
                        "UserMskFocusArticlesUtil.getCurrentByUserId()",
                        {
                            userId: userId,
                        }
                    );
                }
            );
        if (
            userMskFocusArticle?.focusStatuses == null ||
            userMskFocusArticle.focusStatuses.length === 0
        ) {
            return;
        }

        const focusStatusIndex = userMskFocusArticle.focusStatuses.findIndex(
            (x) => x.mskFocusAreaGroupId === groupId
        );
        if (focusStatusIndex === -1) {
            return;
        }

        // load focus status
        const focusStatus = userMskFocusArticle.focusStatuses[focusStatusIndex];
        let updateRecord = false;

        // add date to array
        if (focusStatus.completedArticles == null) {
            focusStatus.completedArticles = [];
        }
        const articleAlreadyCompleted =
            focusStatus.completedArticles.findIndex(
                (x) => x.articleId === articleId
            ) >= 0;
        if (!articleAlreadyCompleted) {
            // mark completed
            focusStatus.completedArticles.push({
                articleId: articleId,
                completedDateMs: DateUtil.getCurrentMsDate(),
            });
            updateRecord = true;

            // Check if all tasks for the day are completed. if the corrective
            // exercise is completed then the user is done all tasks for the
            // day.
            // TodayService.getToday(userId)
            //     .then((today) => {
            //         MskScoreUtil.getLatest(userId).then((currentMskScore) => {
            //             // Checking if user has completed the initial assessments.
            //             if (today?.correctiveExercise?.isComplete
            //                 && currentMskScore?.lifestyleScore?.percentage != null
            //                 && currentMskScore?.movementScore?.percentage != null) {

            //                 // Clean up the pending notification for current day at 6pm. The
            //                 // repeating notification scheduled for next day at 6pm will
            //                 // still occur.

            //                 NotificationService.ActivityCompletedEvent();
            //             }
            //         });
            //     });
        }

        // mark all records as read
        const allArticlesRead =
            !focusStatus.readAllArticles &&
            ArticleUtils._completedFocusAreaArticles(focusStatus);
        _log(`finishArticle() allArticlesRead=${allArticlesRead}`);

        if (allArticlesRead) {
            focusStatus.readAllArticles = true;
            updateRecord = true;
            userMskFocusArticle.focusStatuses[focusStatusIndex] = focusStatus;
            const shouldRetake = ArticleUtils.shouldRetakeLifestyleAssessments(
                userMskFocusArticle.focusStatuses
            );
            _log(`finishArticle() shouldRetake=${shouldRetake}`);
            if (shouldRetake) {
                ArticleUtils.retakeLifestyleAssessments(
                    userId,
                    userMskFocusArticle.focusStatuses
                );
            }
        }

        // save changes
        _log(`finishArticle() updateRecord=${updateRecord}`);
        if (updateRecord) {
            userMskFocusArticle.focusStatuses[focusStatusIndex] = focusStatus;
            await UserMskFocusArticlesService.update(userMskFocusArticle).catch(
                (error) => {
                    throw ArticleUtils.logAndCreateError(
                        error,
                        "UserMskFocusArticlesService.update()",
                        {
                            userMskFocusArticleId: userMskFocusArticle?.id,
                        }
                    );
                }
            );

            // await TodayService.deleteToday(userId).catch((error) => {
            //     throw ArticleUtils.logAndCreateError(
            //         error,
            //         "TodayService.deleteToday()",
            //         {
            //             userId: userId,
            //         }
            //     );
            // });
        }

        // add user activity
        FirebaseUtils.logUserActivity(userId, ActivityType.ArticleCompletion);
    }

    /**
     * Returns the number of completed & total articles for the user
     * @param {string} userId - The user to get articles for
     * @returns {Promise<UserArticleCount>} A promise to get the number of
     * completed articles and total articles
     */
    public static async getCompletedArticlesCount(
        userId: string
    ): Promise<UserArticleCount[]> {
        const results: UserArticleCount[] = [];

        let userMskArticles = await UserMskFocusArticlesUtil.getCurrentByUserId(
            userId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "UserMskFocusArticlesUtil.getCurrentByUserId()",
                {
                    userId: userId,
                }
            );
        });
        const createNewRecord = userMskArticles == null;

        if (createNewRecord) {
            userMskArticles = await ArticleUtils.createUserMskArticles(
                userId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.createUserMskArticles()",
                    {
                        userId: userId,
                    }
                );
            });
        }
        if (userMskArticles == null) {
            return results;
        }

        for (let i = 0; i < userMskArticles.focusStatuses.length; i++) {
            const focusStatus: UserMskFocusStatus =
                userMskArticles.focusStatuses[i];
            results.push({
                groupId: focusStatus.mskFocusAreaGroupId,
                completedArticles: focusStatus.completedArticles?.length || 0,
                totalArticles: focusStatus.numArticles,
            });
        }

        _log(
            `getCompletedArticlesCount(): results:`,
            results,
            "createNewRecord:",
            createNewRecord ? userMskArticles : false
        );
        return results;
    }

    /**
     * Gets the article tag that should be prioritized after the intro article
     */
    public static getTagPriority(mskScore?: MskScore): string | undefined {
        if (mskScore == null) {
            return undefined;
        }

        const hasMovementPain = DataAssessmentsUtil.hasMovementPain(
            mskScore.movementScore
        );
        const result = hasMovementPain
            ? "PAIN"
            : mskScore.movementScore?.focusArea?.groupId;
        _log(`getTagPriority(): priority is "${result}"`);
        return result;
    }

    /**
     * Returns the next article(s) for the user. Currently only 1 article is
     * returned.
     * @param {string} userId - The user to get articles for
     * @param {string} tagPriority - Case-insensitive tag to prioritize after
     * "intro"
     * @returns {Promise<UserArticle[]>} A promise to get the next user articles
     */
    public static async getTodaysArticles(
        userId: string,
        tagPriority?: string
    ): Promise<UserArticle[]> {
        if (userId == null || userId.length === 0) {
            return [];
        }

        // get or create userMskArticles
        let userMskArticles = await UserMskFocusArticlesUtil.getCurrentByUserId(
            userId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "UserMskFocusArticlesUtil.getCurrentByUserId()",
                {
                    userId: userId,
                }
            );
        });

        if (userMskArticles == null) {
            userMskArticles = await ArticleUtils.createUserMskArticles(
                userId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.createUserMskArticles()",
                    {
                        userId: userId,
                    }
                );
            });
            if (userMskArticles == null) {
                _log("getTodaysArticles(): DID NOT create user articles");
                return [];
            } else {
                _log(
                    "getTodaysArticles(): created userMskArticles:",
                    userMskArticles
                );
            }
        }

        // get articles
        const userArticles = await ArticleUtils.getTodaysArticlesFromRecord(
            userMskArticles,
            tagPriority
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticleUtils.getTodaysArticlesFromRecord()",
                {
                    userMskArticlesId: userMskArticles?.id,
                    tagPriority: tagPriority,
                }
            );
        });

        // mark overall focus as complete
        if (
            userMskArticles?.isCurrent &&
            ArticleUtils._allCompletedBeforeToday(userMskArticles.focusStatuses)
        ) {
            const hasRetakes = await ArticleUtils.doesUserHaveRetakes(
                userId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.doesUserHaveRetakes()",
                    {
                        userId: userId,
                    }
                );
            });
            if (!hasRetakes) {
                userMskArticles.isCurrent = false;
                userMskArticlesService.update(userMskArticles);
            }
        }

        return userArticles!;
    }

    public static wasCompletedToday(
        completedArticles: UserMskFocusStatusCompleted[]
    ): boolean {
        if (completedArticles == null || completedArticles.length === 0) {
            return false;
        }

        const lastCompleted = completedArticles[completedArticles.length - 1];
        return DateUtil.isToday(lastCompleted.completedDateMs);
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private static logAndCreateError(
        error: any,
        methodName: string,
        data: any
    ): ArticleError {
        console.error(
            "ArticleUtil",
            methodName,
            "error:",
            error,
            "data:",
            data
        );
        return new ArticleError(error, methodName, data);
    }

    private static async getTodaysArticlesFromRecord(
        userMskArticles: UserMskFocusArticles,
        tagPriority?: string
    ): Promise<UserArticle[]> {
        const todaysFocusAreas = ArticleUtils.getTodaysFocusAreas(
            userMskArticles!
        );
        if (todaysFocusAreas.length === 0) {
            return [];
        }

        const articlesResult: any = await ArticleUtils.getArticlesForFocuses(
            userMskArticles!,
            todaysFocusAreas,
            tagPriority
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticleUtils.getArticlesForFocuses()",
                {
                    todaysFocusAreas: todaysFocusAreas,
                    tagPriority: tagPriority,
                    userMskArticlesId: userMskArticles?.id,
                }
            );
        });
        userMskArticles = articlesResult.userMskArticles;
        _log(
            "getTodaysArticlesFromRecord(): todaysFocusAreas:",
            todaysFocusAreas,
            "articlesResult:",
            articlesResult
        );
        if (articlesResult.wasChanged) {
            userMskArticlesService.update(userMskArticles!);
        }

        if (articlesResult.userArticles.length === 0) {
            _log("getTodaysArticlesFromRecord(): reload");
            return await ArticleUtils.getTodaysArticlesFromRecord(
                userMskArticles
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.getTodaysArticlesFromRecord()",
                    {
                        userMskArticlesId: userMskArticles?.id,
                    }
                );
                // return [];
            });
        }

        return articlesResult.userArticles;
    }

    private static async getArticlesForFocuses(
        userMskFocusArticles: UserMskFocusArticles,
        todaysFocusAreas: UserMskFocusStatus[],
        tagPriority?: string
    ): Promise<any> {
        let result = {
            userArticles: [] as UserArticle[],
            userMskArticles: userMskFocusArticles as UserMskFocusArticles,
            wasChanged: false as Boolean,
        };

        for (let i = 0; i < todaysFocusAreas.length; i++) {
            const focusStatus: UserMskFocusStatus = todaysFocusAreas[i];

            // load current articles for a group
            const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                focusStatus.mskFocusAreaGroupId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.getArticlesForFocuses()",
                    {
                        mskFocusAreaGroupId: focusStatus.mskFocusAreaGroupId,
                    }
                );
            });
            if (
                articlesGroup?.articles == null ||
                articlesGroup.articles.length === 0
            ) {
                _log("getArticlesForFocuses(): no articles");
                continue;
            }

            // update articles count
            const actualNumArticles = articlesGroup.articles.length;
            if (
                focusStatus.numArticles == null ||
                focusStatus.numArticles !== actualNumArticles
            ) {
                focusStatus.numArticles = actualNumArticles;
                focusStatus.readAllArticles =
                    focusStatus.completedArticles != null &&
                    focusStatus.completedArticles.length >= actualNumArticles;
                const index = result.userMskArticles.focusStatuses.findIndex(
                    (x) => x === focusStatus
                );
                _log(
                    `getArticlesForFocuses(): update article count to ${articlesGroup.articles.length} for index ${index} (readAllArticles=${focusStatus.readAllArticles})`
                );
                if (index >= 0) {
                    result.userMskArticles.focusStatuses[index] = focusStatus;
                    result.wasChanged = true;
                }
            }

            // completed today
            let completedToday = false;
            if (
                focusStatus.completedArticles != null &&
                focusStatus.completedArticles.length >= 1
            ) {
                completedToday = DateUtil.isToday(
                    focusStatus.completedArticles[
                        focusStatus.completedArticles.length - 1
                    ].completedDateMs
                );
            }

            // determine article index
            let nextArticle: Article | null = null;
            if (completedToday) {
                const completedArticleId =
                    focusStatus.completedArticles[
                        focusStatus.completedArticles.length - 1
                    ].articleId;
                const articleIndex = articlesGroup.articles.findIndex(
                    (x) => x.FMS_article_id === completedArticleId
                );
                if (articleIndex >= 0) {
                    nextArticle = articlesGroup.articles[articleIndex];
                }
                _log(
                    `getArticlesForFocuses(): completed today: nextArticle:`,
                    nextArticle
                );
            } else {
                nextArticle = this._getNextArticle(
                    articlesGroup.articles,
                    focusStatus.completedArticles,
                    tagPriority
                );
                _log(`getArticlesForFocuses(): nextArticle:`, nextArticle);
            }

            // add article
            if (nextArticle != null) {
                const numCompleted =
                    focusStatus.completedArticles == null
                        ? 0
                        : focusStatus.completedArticles.length;
                _log(
                    `getArticlesForFocuses(): completedToday=${completedToday} numCompleted=${numCompleted}`
                );
                result.userArticles.push({
                    article: nextArticle,
                    day: completedToday ? numCompleted : numCompleted + 1,
                    groupId: focusStatus.mskFocusAreaGroupId,
                    isComplete: completedToday,
                });
            }
        }

        return result;
    }

    public static _getNextArticle(
        articles?: Article[],
        completedArticles?: UserMskFocusStatusCompleted[],
        tagPriority?: string
    ): Article | null {
        if (articles == null || articles.length === 0) {
            return null;
        }

        // unread articles
        let completedArticleIds: number[] = [];
        if (completedArticles != null && completedArticles.length !== 0) {
            // sometimes we are getting undefined articles in here, being a bit
            // defensive and removing them now before moving on
            completedArticles = completedArticles.filter(
                (x) => x.articleId !== undefined
            );
            completedArticleIds = completedArticles.map((x) => x.articleId!);
        }
        const articleOptions = articles.filter(
            (x) => completedArticleIds.indexOf(x.FMS_article_id) === -1
        );
        if (articleOptions.length === 0) {
            return null;
        }

        // match on tag
        if (tagPriority != null && tagPriority.length !== 0) {
            const tagMatch = articleOptions.filter(
                (x) =>
                    x.tags != null &&
                    x.tags
                        .map((tag) => tag.toLocaleLowerCase())
                        .indexOf(tagPriority.toLocaleLowerCase()) >= 0
            );
            if (tagMatch.length >= 1) {
                return tagMatch[0];
            }
        }

        // match on "intro"
        const introMatch = articleOptions.filter(
            (x) =>
                x.tags != null &&
                x.tags.map((tag) => tag.toLocaleLowerCase()).indexOf("intro") >=
                0
        );
        if (introMatch.length >= 1) {
            return introMatch[0];
        }

        // first article
        return articleOptions[0];
    }

    /**
     * Returns different areas that articles should be loaded for. Currently
     * only 1 focus area is returned per day.
     */
    private static getTodaysFocusAreas(
        userMskFocusArticles: UserMskFocusArticles
    ): UserMskFocusStatus[] {
        if (
            userMskFocusArticles?.focusStatuses == null ||
            userMskFocusArticles.focusStatuses.length === 0
        ) {
            return [];
        }

        // find completed today
        const results: UserMskFocusStatus[] = [];
        let focuses = userMskFocusArticles.focusStatuses;
        for (let i = 0; i < focuses.length; i++) {
            const focus = focuses[i];
            if (
                focus.completedArticles == null ||
                focus.completedArticles.length === 0
            ) {
                continue;
            }
            const lastCompleted =
                focus.completedArticles[focus.completedArticles.length - 1];
            if (DateUtil.isToday(lastCompleted.completedDateMs)) {
                results.push(focus);
            }
        }
        if (results.length >= 1) {
            return results;
        }

        // find first incomplete (complete A, then B, then C, then A....)
        focuses = userMskFocusArticles.focusStatuses.sort((a, b) =>
            (a.completedArticles ?? []).length <
                (b.completedArticles ?? []).length
                ? -1
                : 1
        );
        for (let i = 0; i < focuses.length; i++) {
            const focus = focuses[i];
            if (focus.numArticles <= 0) {
                continue;
            }
            if (focus.completedArticles == null) {
                return [focus];
            }
            if (focus.completedArticles.length < focus.numArticles) {
                return [focus];
            }
        }

        return [];
    }

    private static shouldRetakeLifestyleAssessments(
        focusStatuses: UserMskFocusStatus[]
    ): boolean {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return false;
        }
        for (let i = 0; i < focusStatuses.length; i++) {
            if (!focusStatuses[i].readAllArticles) {
                return false;
            }
        }
        return true;
    }

    private static async retakeLifestyleAssessments(
        userId: string,
        focusStatuses: UserMskFocusStatus[]
    ): Promise<void> {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return;
        }
        focusStatuses.forEach((focusStatus) => {
            if (focusStatus.mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH) {
                UserUtil.modifyRetakeLifestyleAssessments(
                    userId,
                    focusStatus.mskFocusAreaGroupId,
                    true
                );
            }
        });
    }

    private static async doesUserHaveRetakes(userId: string) {
        const user = await UserService.get(userId).catch((error) => {
            throw ArticleUtils.logAndCreateError(error, "UserService.get()", {
                userId: userId,
            });
        });

        if (user == null) {
            return false;
        }

        if (user.retakeMovementAssessment != null) {
            return true;
        }
        return (
            user.retakeLifestyleAssessments != null &&
            user.retakeLifestyleAssessments.length >= 1
        );
    }

    public static _completedAllArticles(
        focusStatuses: UserMskFocusStatus[]
    ): boolean {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return false;
        }

        for (let i = 0; i < focusStatuses.length; i++) {
            const focusStatus = focusStatuses[i];
            const completedFocusArea =
                ArticleUtils._completedFocusAreaArticles(focusStatus);
            if (!completedFocusArea) {
                return false;
            }
        }

        return true;
    }

    public static _completedFocusAreaArticles(
        userMskFocusStatus: UserMskFocusStatus
    ): boolean {
        if (userMskFocusStatus?.completedArticles == null) {
            return false;
        }
        return (
            userMskFocusStatus.completedArticles.length >=
            userMskFocusStatus.numArticles
        );
    }

    public static _allCompletedBeforeToday(
        focusStatuses: UserMskFocusStatus[]
    ): boolean {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return false;
        }

        for (let i = 0; i < focusStatuses.length; i++) {
            const focusStatus = focusStatuses[i];
            if (!focusStatus.readAllArticles) {
                return false;
            }
            if (ArticleUtils.wasCompletedToday(focusStatus.completedArticles)) {
                return false;
            }
        }

        return true;
    }

    public static async getCurrentArticlesGroup(
        mskFocusAreaGroupId: string
    ): Promise<ArticlesGroup | null> {
        let articlesGroup = await ArticlesGroupService.getForGroupId(
            mskFocusAreaGroupId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticlesGroupService.getForGroupId()",
                {
                    mskFocusAreaGroupId: mskFocusAreaGroupId,
                }
            );
        });

        // current existing articles
        const articlesAreCurrent = DateUtil.isFutureDate(
            articlesGroup?.expirationMs
        );
        if (articlesAreCurrent) {
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): current articlesGroup found:`,
                articlesGroup
            );
            return articlesGroup!;
        }

        // get new articles group
        const mskFocusAreaGroup: Assessment = { groupId: mskFocusAreaGroupId };
        let newArticlesGroup = await ArticleApiService.getArticlesGroup(
            mskFocusAreaGroup
        ).catch((error) => {
            const globalError = GlobalStateUtil.createError("", error);
            const errorMessage = `Existing articles were not found for ${GroupUtil.getName(
                mskFocusAreaGroupId
            )}, and articles could not be queried from ${globalError.url ?? "the server"
                }`;
            throw new Error(
                errorMessage +
                ": " +
                JSON.stringify({
                    error: error,
                    mskFocusAreaGroupId: mskFocusAreaGroup.groupId,
                })
            );
        });
        if (newArticlesGroup != null) {
            newArticlesGroup =
                ArticleUtils.setOriginalImageUrls(newArticlesGroup);
        }

        // update references
        let dataUpdated = false;
        if (newArticlesGroup == null) {
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): new articles were not found`
            );
        } else if (articlesGroup == null) {
            articlesGroup = newArticlesGroup;
            dataUpdated = true;
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): new newArticlesGroup:`,
                newArticlesGroup
            );
        } else {
            articlesGroup.articles = newArticlesGroup.articles;
            dataUpdated = true;
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): update articles`,
                newArticlesGroup.articles
            );
        }

        // save articles group
        if (dataUpdated && articlesGroup != null) {
            articlesGroup.expirationMs = DateUtil.getExpiration(
                ArticleUtils.EXPIRATION_HOURS * 60
            );
            ArticlesGroupService.addOrUpdate(articlesGroup);
        }

        return articlesGroup!;
    }

    private static setOriginalImageUrls(
        articlesGroup: ArticlesGroup
    ): ArticlesGroup {
        if (
            articlesGroup?.articles == null ||
            articlesGroup.articles.length === 0
        ) {
            return articlesGroup;
        }

        articlesGroup.articles.forEach((x) => {
            x.originalImageUrl = x.imageUrl;
        });

        return articlesGroup;
    }

    private static async createUserMskArticles(
        userId: string,
        user: IUser | null = null
    ): Promise<UserMskFocusArticles | null> {
        if (user == null && userId != null) {
            user = await UserService.get(userId).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "UserService.get()",
                    {
                        userId: userId,
                    }
                );
            });
        }
        if (user == null) {
            return null;
        }
        if (userId == null) {
            userId = user.id!;
        }

        // delay creating new user articles if there are lifestyles to retake
        const userRetakeLifestyleAssessments =
            user.retakeLifestyleAssessments ?? [];
        const incompleteLifestyleGroupIds = userRetakeLifestyleAssessments
            .filter((x) => x.completedDateMs == null)
            .map((x) => x.assessmentGroupId);
        if (incompleteLifestyleGroupIds.length >= 1) {
            return null;
        }

        const mskScore = await MskScoreUtil.getLatest(userId).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "MskScoreUtil.getLatest()",
                {
                    userId: userId,
                }
            );
        });

        const focusAreas = mskScore?.focusAreas ?? [];

        const focusStatuses: UserMskFocusStatus[] = [];
        for (let i = 0; i < focusAreas.length; i++) {
            const focusArea = focusAreas[i];
            if (focusArea.groupId == null) {
                continue;
            }

            const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                focusArea.groupId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.getCurrentArticlesGroup()",
                    {
                        groupId: focusArea?.groupId,
                    }
                );
            });
            if (
                articlesGroup?.articles == null ||
                articlesGroup.articles.length === 0
            ) {
                console.warn(
                    `createUserMskArticles(): no articles for ${focusArea.groupId}`
                );
                continue;
            }

            focusStatuses.push({
                completedArticles: [],
                readAllArticles: false,
                mskFocusAreaGroupId: focusArea.groupId,
                mskFocusAreaGroupName: focusArea.groupName!,
                numArticles: articlesGroup.articles.length,
            });
        }

        const userMskArticles: UserMskFocusArticles = {
            isCurrent: true,
            focusStatuses: focusStatuses,
            userId: userId,
        };
        return userMskArticlesService.add(userMskArticles);
    }

    // #endregion Private Methods
}

/* eslint-disable */
const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        console.log(message, ...optionalParams);
    }
};
/* eslint-enable */
